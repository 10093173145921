import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  ItemConditionContent,
  PurchaseConditionListContent,
  PurchaseConditionListSearchCriteria,
  PurchaseConditionRequest,
  PurchaseConditionRequestContent,
  PurchaseConditionRequestListSearchCriteria,
  PurchaseConditionRequestWeeklySchedule,
  PurchaseConditionTabResponse
} from '../../models/purchase-condition-request.model';
import { AddUpdateContactAndDCSuccess, PurchaseConditionViewResponse } from '../../models/purchase-condition.model';
import { PurchaseConditionActions, PurchaseConditionActionTypes } from '../actions/purchase-condition.actions';
import { initialCriteriaObject } from '../state/base.state';

export interface ItemConditionState extends EntityState<ItemConditionContent> {
  totalElements: number;
  totalPages: number;
  importId: string;
}

export interface PurchaseConditionListState extends EntityState<PurchaseConditionListContent> {
  totalElements: number;
  totalPages: number;
  searchCriteria: PurchaseConditionListSearchCriteria;
}

export interface PurchaseConditionRequestWeeklyScheduleState
  extends EntityState<PurchaseConditionRequestWeeklySchedule> {
  totalElements: number;
  totalPages: number;
  searchCriteria: PurchaseConditionRequestListSearchCriteria;
  importId: string;
}

export interface PurchaseConditionRequestState extends EntityState<PurchaseConditionRequestContent> {
  criteriaObject: PurchaseConditionRequestListSearchCriteria;
  totalElements: number;
  totalPages: number;
  // TODO:
  purchaseConditionRequestView: PurchaseConditionRequest;
  purchaseConditionView: PurchaseConditionViewResponse;
  isApproveRejectSuccess: boolean;
  isAddUpdateContactAndDCSuccess: AddUpdateContactAndDCSuccess;
  purchaseConditionList: PurchaseConditionListState;
  itemCondition: ItemConditionState;
  purchaseConditionReqMOV?: PurchaseConditionTabResponse[];
  purchaseConditionRequestWeeklyScheduleList?: PurchaseConditionRequestWeeklyScheduleState;
}

export const adapter: EntityAdapter<PurchaseConditionRequestContent> = createEntityAdapter<
  PurchaseConditionRequestContent
>();

export const adapterItemCondition: EntityAdapter<ItemConditionContent> = createEntityAdapter<ItemConditionContent>();

export const adapterPurchaseConditionList: EntityAdapter<PurchaseConditionListContent> = createEntityAdapter<
  PurchaseConditionListContent
>();

export const adaptorPurchaseConditionRequestWeeklySchedule: EntityAdapter<PurchaseConditionRequestWeeklySchedule> = createEntityAdapter<
  PurchaseConditionRequestWeeklySchedule
>({ selectId: (model: PurchaseConditionRequestWeeklySchedule) => model.rowId });

export const initialPurchaseConditionRequestWeeklyScheduleState: PurchaseConditionRequestWeeklyScheduleState = adaptorPurchaseConditionRequestWeeklySchedule.getInitialState(
  {
    importId: null,
    totalElements: 0,
    totalPages: 0,
    searchCriteria: null
  }
);

export const initialPurchaseConditionListState: PurchaseConditionListState = adapterPurchaseConditionList.getInitialState(
  {
    totalElements: 0,
    totalPages: 0,
    searchCriteria: null
  }
);

export const initialItemConditionState: ItemConditionState = adapterItemCondition.getInitialState({
  totalElements: 0,
  totalPages: 0,
  importId: null
});

export const initialPurchaseConditionRequestState: PurchaseConditionRequestState = adapter.getInitialState({
  criteriaObject: initialCriteriaObject,
  totalElements: 0,
  totalPages: 20,
  purchaseConditionRequestView: null,
  purchaseConditionView: null,
  isApproveRejectSuccess: false,
  isAddUpdateContactAndDCSuccess: null,
  purchaseConditionList: initialPurchaseConditionListState,
  itemCondition: initialItemConditionState,
  purchaseConditionReqMOV: [],
  purchaseConditionRequestWeeklyScheduleList: initialPurchaseConditionRequestWeeklyScheduleState
});

export function purchaseConditionRequestReducers(
  state = initialPurchaseConditionRequestState,
  action: PurchaseConditionActions
): PurchaseConditionRequestState {
  switch (action.type) {
    case PurchaseConditionActionTypes.PurchaseConditionRequestListSearchRequested:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case PurchaseConditionActionTypes.PurchaseConditionRequestListLoaded:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case PurchaseConditionActionTypes.PurchaseConditionReqMOVLoaded:
      return {
        ...state,
        purchaseConditionReqMOV: action.payload
      };
    case PurchaseConditionActionTypes.PurchaseConditionRequestWeeklyScheduleLoaded:
      return {
        ...state,
        purchaseConditionRequestWeeklyScheduleList: adaptorPurchaseConditionRequestWeeklySchedule.addAll(
          action.payload.content,
          {
            ...state.purchaseConditionRequestWeeklyScheduleList,
            totalElements: action.payload.totalElements,
            totalPages: action.payload.totalPages,
            importId:
              action.payload.content.length === 0
                ? null
                : action.payload.content[0] && action.payload.content[0].importId
                ? action.payload.content[0].importId
                : state.purchaseConditionRequestWeeklyScheduleList.importId
                ? state.purchaseConditionRequestWeeklyScheduleList.importId
                : null
          }
        )
      };
    case PurchaseConditionActionTypes.ItemConditionListRequested:
      return {
        ...state,
        itemCondition: {
          ...state.itemCondition,
          importId: action.payload.importId
        }
      };
    case PurchaseConditionActionTypes.ItemConditionListLoaded:
      return {
        ...state,
        itemCondition: adapterItemCondition.addAll(action.payload.content, {
          ...state.itemCondition,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages
        })
      };
    case PurchaseConditionActionTypes.PurchaseConditionRequestReset:
      return {
        ...state,
        purchaseConditionRequestView: null,
        itemCondition: initialPurchaseConditionRequestState.itemCondition,
        purchaseConditionReqMOV: [],
        purchaseConditionRequestWeeklyScheduleList: initialPurchaseConditionRequestWeeklyScheduleState
      };
    case PurchaseConditionActionTypes.PurchaseConditionListSearchRequested:
      return {
        ...state,
        purchaseConditionList: {
          ...state.purchaseConditionList,
          searchCriteria: action.payload
        }
      };
    case PurchaseConditionActionTypes.PurchaseConditionListSearchLoaded:
      return {
        ...state,
        purchaseConditionList: adapterPurchaseConditionList.addAll(action.payload.content, {
          ...state.purchaseConditionList,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages
        })
      };
    case PurchaseConditionActionTypes.PurchaseConditionRequestViewLoaded:
      return {
        ...state,
        purchaseConditionRequestView: action.payload,
        purchaseConditionReqMOV: action.payload.purchaseConditionReqMOV,
        itemCondition: {
          ...state.itemCondition,
          importId: action.payload.refDiscountImportId
        },
        purchaseConditionRequestWeeklyScheduleList: {
          ...state.purchaseConditionRequestWeeklyScheduleList,
          importId: action.payload.refScheduleImportId
        }
      };
    case PurchaseConditionActionTypes.PurchaseConditionRequestApproveRejectSuccess:
      return {
        ...state,
        isApproveRejectSuccess: action.payload.isSuccess
      };
    case PurchaseConditionActionTypes.PurchaseConditionViewLoaded:
      return {
        ...state,
        purchaseConditionView: action.payload,
        purchaseConditionReqMOV: action.payload.purchaseConditionMOV,
        itemCondition: {
          ...state.itemCondition,
          importId: action.payload.refDiscountImportId
        },
        purchaseConditionRequestWeeklyScheduleList: {
          ...state.purchaseConditionRequestWeeklyScheduleList,
          importId: action.payload.refScheduleImportId
        }
      };
    case PurchaseConditionActionTypes.PurchaseConditionViewReset:
      return {
        ...state,
        purchaseConditionView: null,
        itemCondition: initialPurchaseConditionRequestState.itemCondition,
        isAddUpdateContactAndDCSuccess: null,
        purchaseConditionReqMOV: [],
        purchaseConditionRequestWeeklyScheduleList: initialPurchaseConditionRequestWeeklyScheduleState
      };
    case PurchaseConditionActionTypes.PurchaseConditionContractLoaded:
      return {
        ...state,
        isAddUpdateContactAndDCSuccess: action.payload.appUpdateContactAndDCSuccess,
        purchaseConditionRequestView: {
          ...(action.payload as any),
          supplierContacts: action.payload.supplierContacts ? action.payload.supplierContacts : []
        }
      };
    case PurchaseConditionActionTypes.PurchaseConditionDcLoaded:
      return {
        ...state,
        isAddUpdateContactAndDCSuccess: action.payload.appUpdateContactAndDCSuccess,
        purchaseConditionRequestView: {
          ...(action.payload as any),
          supplierDistributionCenters: action.payload.supplierDistributionCenters
            ? action.payload.supplierDistributionCenters
            : []
        }
      };
    case PurchaseConditionActionTypes.PurchaseConditionRemoveOrderScheduleImportId:
      return {
        ...state,
        purchaseConditionRequestWeeklyScheduleList: {
          ...state.purchaseConditionRequestWeeklyScheduleList,
          importId: null
        }
      };
    case PurchaseConditionActionTypes.PurchaseConditionContractAndDcUpdateErrorAction:
      return {
        ...state,
        purchaseConditionRequestView: {
          ...(action.payload as any),
          supplierContacts: null,
          supplierDistributionCenters: null,
          contractAndDcResponseError: action.payload
        }
      };
    case PurchaseConditionActionTypes.PurchaseConditionSupplierAddUpdateContractDCStatusReset:
      return {
        ...state,
        isAddUpdateContactAndDCSuccess: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export const { selectAll: selectAllItemCondition } = adapterItemCondition.getSelectors();
export const { selectAll: selectAllPurchaseConditionList } = adapterPurchaseConditionList.getSelectors();
export const {
  selectAll: selectAllPurchaseConditionRequestWeeklySchedule
} = adaptorPurchaseConditionRequestWeeklySchedule.getSelectors();
