import { createSelector } from '@ngrx/store';
import * as fromShelfInventoryListResponseState from '../reducers/shelf-select-inventory.reducers';
import { AppStates } from '../state/app.states';

const selectShelfSelectInventoryListState = (state: AppStates) => state.shelfSelectInventory;

export const selectAllShelfSelectInventoryList = createSelector(
  selectShelfSelectInventoryListState,
  fromShelfInventoryListResponseState.selectAll
);

export const selectShelfInventoryList = createSelector(
  selectShelfSelectInventoryListState,
  (state: fromShelfInventoryListResponseState.ShelfSelectInventoryState) => state
);

export const selectShelfSelectInventoryListCriteria = createSelector(selectShelfSelectInventoryListState, state => {
  return state.criteriaObject;
});
