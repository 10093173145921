<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title pull-left">Failed</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()" id="confirm-modal-closeBtn">
      <span aria-hidden="true"><em class="icon-close"></em></span>
    </button>
  </div>
  <div class="modal-body">
    <table id="prItemErrorDetails" class="table table-striped table-bordered table-hover border-none">
      <thead>
        <tr>
          <th class="text-center">Subject</th>
          <th class="text-center">Description</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isChange">
          <ng-container *ngIf="isChange && changeMessage; else defaultChangeTemplate">
            <td>{{ changeSubject }}</td>
            <td>{{ changeMessage }}</td>
          </ng-container>
          <ng-template #defaultChangeTemplate>
            <td>Data Changed.</td>
            <td>This data has been updated. Please verify data before submit.</td>
          </ng-template>
        </tr>
        <tr *ngIf="isInvalidData">
          <ng-container *ngIf="isInvalidData && invalidMessage; else defaultDataErrorTemplate">
            <td>{{ invalidSubject }}</td>
            <td>{{ invalidMessage }}</td>
          </ng-container>
          <ng-template #defaultDataErrorTemplate>
            <td>Invalid Data.</td>
            <td>Some items has invalid data. Please remove item from list.</td>
          </ng-template>
        </tr>

        <tr *ngIf="isInvalidDate">
          <td>Invalid Delivery Details</td>
          <td>Delivery details is required or delivery date is invalid.</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="decline()" id="okBtn">
      OK
    </button>
  </div>
</div>
