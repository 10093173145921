import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { AlertModalComponent } from '..';
import { ModalButtonResponseEnum } from '../../enum/modal-button-response.enum';
import { LayoutActionErrorUpdate, LayoutForceCloseCurrentModal } from '../../store/actions/layout.action';
import { selectLayoutState } from '../../store/selectors/layout-selector';
import { AppStates } from '../../store/state/app.states';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  url: string;
  isGeneralError: Subscription;
  isAlreadyDisplayError: boolean;

  constructor(
    private router: Router,
    private location: Location,
    public sideBarService: SidebarService,
    private readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService
  ) {
    const pageContent = document.querySelector('#page-content');

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      if (pageContent) {
        pageContent.scrollTop = 0;
        pageContent.scrollLeft = 0;
      }
    });
  }

  ngOnInit() {
    this.isGeneralError = this.store
      .pipe(untilComponentDestroyed(this), select(selectLayoutState))
      .subscribe(layoutError => {
        let initialState = null;
        if (layoutError.isApiError && !this.isAlreadyDisplayError) {
          if (
            layoutError.isUseBackEndMessage &&
            layoutError.errorResponse.error &&
            layoutError.errorResponse.error.message
          ) {
            this.isAlreadyDisplayError = true;
            initialState = {
              title: 'Failed',
              message: layoutError.errorResponse.error.message,
              isRefresh: true
            };
          } else {
            this.isAlreadyDisplayError = true;
            initialState = {
              title: 'Error',
              message: 'There is something went wrong!!! Please try again later.',
              isRefresh: true
            };
          }
        }

        if (initialState) {
          const alertModal = this.modalService.show(AlertModalComponent, {
            initialState
          });

          this.subscribeForCloseModal(alertModal, layoutError);
        }
      });

    this.modalService.onHide.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.isAlreadyDisplayError = false;
      this.store.dispatch(new LayoutActionErrorUpdate(false));
    });
  }

  ngOnDestroy(): void {
    this.isGeneralError.unsubscribe();
    this.store.dispatch(new LayoutActionErrorUpdate(false));
  }

  subscribeForCloseModal(alertModal, layoutError) {
    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        if (layoutError.forceCloseAfterConfirm) {
          this.store.dispatch(new LayoutForceCloseCurrentModal(true));
        }
        alertModal.hide();
      }
    });
  }

  get sideBarState$() {
    return this.sideBarService.sidebarState$;
  }

  get sideBarState() {
    return this.sideBarService.sidebarState;
  }
}
