import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models';
import {
  StoreConsultantModel,
  StoreConsultantResponse,
  StoreConsultantSearchCriteria
} from '../../models/store-consultant.model';

export enum StoreConsultantActionTypes {
  STORE_CONSULTANT_LIST_REQUEST = '[Store Consultant] List Request',
  STORE_CONSULTANT_LIST_RESPONSE = '[Store Consultant] List Response',
  STORE_CONSULTANT_LIST_ERROR = '[Store Consultant] List Error',
  STORE_CONSULTANT_LIST_RESET = '[Store Consultant] List Reset',

  STORE_CONSULTANT_GET_BY_ID_REQUEST = '[Store Consultant] Request by id',
  STORE_CONSULTANT_GET_BY_ID_RESPONSE = '[Store Consultant] Response by id',
  STORE_CONSULTANT_GET_BY_ID_RESET = '[Store Consultant] Response by id Reset',

  STORE_CONSULTANT_SUBMIT_REQUEST = '[Store Consultant] Submit Request',
  STORE_CONSULTANT_SUBMIT_ERROR = '[Store Consultant] Submit Error',
  STORE_CONSULTANT_SUBMIT_RESET = '[Store Consultant] Submit Seset'
}

export class StoreConsultantListRequestAction implements Action {
  readonly type = StoreConsultantActionTypes.STORE_CONSULTANT_LIST_REQUEST;

  constructor(public payload: StoreConsultantSearchCriteria) {}
}

export class StoreConsultantListResponseAction implements Action {
  readonly type = StoreConsultantActionTypes.STORE_CONSULTANT_LIST_RESPONSE;

  constructor(public payload: StoreConsultantResponse) {}
}

export class StoreConsultantListErrorAction implements Action {
  readonly type = StoreConsultantActionTypes.STORE_CONSULTANT_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class StoreConsultantListResetAction implements Action {
  readonly type = StoreConsultantActionTypes.STORE_CONSULTANT_LIST_RESET;
}

export class StoreConsultantGetByIdRequestAction {
  readonly type = StoreConsultantActionTypes.STORE_CONSULTANT_GET_BY_ID_REQUEST;

  constructor(public payload: { userId: string }) {}
}

export class StoreConsultantGetByIdResponseAction {
  readonly type = StoreConsultantActionTypes.STORE_CONSULTANT_GET_BY_ID_RESPONSE;

  constructor(public payload: StoreConsultantModel) {}
}

export class StoreConsultantGetByIdResetAction {
  readonly type = StoreConsultantActionTypes.STORE_CONSULTANT_GET_BY_ID_RESET;
}

export class StoreConsultantSubmitRequestAction implements Action {
  readonly type = StoreConsultantActionTypes.STORE_CONSULTANT_SUBMIT_REQUEST;

  constructor(public payload: StoreConsultantModel) {}
}

export class StoreConsultantSubmitResetAction implements Action {
  readonly type = StoreConsultantActionTypes.STORE_CONSULTANT_SUBMIT_RESET;
}

export type StoreConsultantListActions =
  | StoreConsultantListRequestAction
  | StoreConsultantListResponseAction
  | StoreConsultantListErrorAction
  | StoreConsultantListResetAction
  | StoreConsultantGetByIdRequestAction
  | StoreConsultantGetByIdResponseAction
  | StoreConsultantGetByIdResetAction
  | StoreConsultantSubmitRequestAction;
