import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { VoucherRequestService } from '../../../../shared/services';
import { VoucherRequestListRequestAction } from '../../../../shared/store/actions/voucher-request.actions';
import { selectVoucherRequestListCriteria } from '../../../../shared/store/selectors/voucher-request.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-voucher-import-modal',
  templateUrl: './voucher-import-modal.component.html',
  styleUrls: ['./voucher-import-modal.component.scss']
})
export class VoucherImportModalComponent implements OnInit {
  public form: FormGroup;
  private localStore: Observable<any>;

  public runDate: string;
  public resultUploadValidate;
  public criteriaObject: any;
  public isSubmit: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    private readonly service: VoucherRequestService,
    protected modalService: BsModalService,
    protected readonly logger: NGXLogger
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.createForm();
    this.form.controls.uploadFile.valueChanges.pipe(filter(Boolean), distinctUntilChanged()).subscribe(value => {
      this.resultUploadValidate = value[0];
    });

    this.localStore
      .pipe(select(selectVoucherRequestListCriteria))
      .subscribe(criteriaObject => (this.criteriaObject = criteriaObject));
  }

  refreshList() {
    this.criteriaObject.page = 0;
    this.store.dispatch(new VoucherRequestListRequestAction(this.criteriaObject));
  }

  createForm() {
    this.form = this.fb.group({
      uploadFile: [null]
    });
  }

  downloadTemplate() {
    this.service.downloadFileTemplate().subscribe(
      response => this.saveFile(environment.fileName.exportVoucher.fileTemplate, response),
      error => this.alertErrorModal(error.error.message)
    );
  }

  saveFile(fileName: string, response: Blob) {
    const blob = new Blob([response]);
    saveAs(blob, `${fileName}.xlsx`);
  }

  onSubmit() {
    this.isSubmit = true;
    if (!this.resultUploadValidate && !this.resultUploadValidate.importVoucherRequestDtos) {
      return;
    }

    this.service.submitImportData(this.resultUploadValidate.importVoucherRequestDtos).subscribe(
      () => this.alertSuccessModal('The data have been imported.'),
      error => {
        this.alertErrorModal(error.error.message);
        this.isSubmit = false;
      }
    );
  }

  alertErrorModal(message: string) {
    this.logger.log(message);

    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message: 'There is something went wrong!!! Please try again later.'
      }
    });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.refreshList();
        alertModal.hide();
        this.bsModalRef.hide();
      }
    });
  }

  ngOnDestroy(): void {
    this.resultUploadValidate = null;
  }
}
