import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse } from '../../models';
import { PosAdsSearchCriteria, PosAdvertisementListContent } from '../../models/pos-advertisement.model';
import { PosAdvertisementAction, PosAdvertisementActionTypes } from '../actions/pos-advertisement.actions';

export interface PosAdvertisementState extends EntityState<PosAdvertisementListContent> {
  isLoaded: boolean;
  defaultAds: PosAdvertisementListContent;
  selected: PosAdvertisementListContent;
  criteriaObject: PosAdsSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
  submitError: ErrorResponse;
}

export const adapter: EntityAdapter<PosAdvertisementListContent> = createEntityAdapter<PosAdvertisementListContent>();

export const initialPosAdvertisementState: PosAdvertisementState = adapter.getInitialState({
  isLoaded: false,
  defaultAds: null,
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  auditLogs: null,
  submitError: null
});

export function PosAdvertisementReducers(
  state = initialPosAdvertisementState,
  action: PosAdvertisementAction
): PosAdvertisementState {
  switch (action.type) {
    case PosAdvertisementActionTypes.POS_DEFAULT_ADS_LIST_REQUEST: {
      return {
        ...state
      };
    }
    case PosAdvertisementActionTypes.POS_DEFAULT_ADS_LIST_SUCCESS: {
      return {
        ...state,
        defaultAds: action.payload
      };
    }
    case PosAdvertisementActionTypes.POS_DEFAULT_ADS_RESET: {
      return {
        ...state,
        defaultAds: null
      };
    }
    case PosAdvertisementActionTypes.POS_ADS_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case PosAdvertisementActionTypes.POS_ADS_LIST_SUCCESS:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case PosAdvertisementActionTypes.POS_ADS_RESET:
      return {
        ...state
      };
    case PosAdvertisementActionTypes.POS_ADS_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case PosAdvertisementActionTypes.POS_ADS_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case PosAdvertisementActionTypes.POS_ADS_CREATE_ERROR:
      return {
        ...state,
        submitError: action.payload
      };
    case PosAdvertisementActionTypes.POS_ADS_CREATE_ERROR_RESET:
      return {
        ...state,
        submitError: null
      };
    default: {
      return {
        ...state
      };
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
