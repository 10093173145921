import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { BaseSearchComponent } from '../../../../base/base-search.component';
import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { OrderingMethodEnum } from '../../../../shared/enum/ordering-method.enum';
import { ProductTypeEnum } from '../../../../shared/enum/product-type.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ButtonType, ImportExportButton } from '../../../../shared/models/import-export-button.model';
import { NotificationEmit } from '../../../../shared/models/notification-emit.model';
import { OrderSelectItemSearchCriteria, ProductOrderItem } from '../../../../shared/models/order-request.model';
import { OrderStoreUseExportService } from '../../../../shared/services/order-store-use-export.service';
import { StoreUseSelectItemListRequest } from '../../../../shared/store/actions/order-select-item.actions';
import { OrderStoreUseSelectItemOrderAddItem } from '../../../../shared/store/actions/order-store-use-select-item.actions';
import { OrderStoreUseSelectItemState } from '../../../../shared/store/reducers/order-store-use-select-item.reducers';
import {
  getCountOrderStoreUseSelectItemOrder,
  getOrderStoreUseSelectItemOrderById
} from '../../../../shared/store/selectors/order-store-use-select-item-order.selector';
import {
  selectAllOrderStoreUseSelectItemList,
  selectOrderStoreUseSelectItemList
} from '../../../../shared/store/selectors/order-store-use-select-item.selector';
import { AppStates } from '../../../../shared/store/state/app.states';
import { getPriceFormat, ItemPrice } from '../../../../shared/utils/calculate-price-util';
@Component({
  selector: 'app-select-store-use-modal',
  templateUrl: './select-store-use-modal.component.html',
  styleUrls: ['./select-store-use-modal.component.scss']
})
export class SelectStoreUseModalComponent extends BaseSearchComponent<
  OrderSelectItemSearchCriteria,
  ProductOrderItem,
  OrderStoreUseSelectItemState
> {
  @Input() data: { title: string; orderType: OrderingMethodEnum; requestNo: string };
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  public totalOrder$: Observable<number>;
  public buttons: Array<ImportExportButton>;
  private localStore: Observable<any>;
  public errorExport: string;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    private readonly orderRequestStoreUse: OrderStoreUseExportService,
    private readonly translate: TranslateService,
    protected fb: FormBuilder
  ) {
    super(store, modalService, selectAllOrderStoreUseSelectItemList, selectOrderStoreUseSelectItemList);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.totalOrder$ = this.store.pipe(select(getCountOrderStoreUseSelectItemOrder));
    this.getButton();
  }

  getButton() {
    this.buttons = [
      {
        type: ButtonType.EXPORT,
        name: 'Export'
      }
    ];
  }

  doAfterVersionAlertModal() {}

  doDestroy() {}

  onBack() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });
  }

  goToView() {}

  onAdvanceSubmit() {}

  prepareSearchCriteriaTags() {}

  search(criteriaObj) {
    this.store.dispatch(new StoreUseSelectItemListRequest(criteriaObj));
  }

  setInitialCriteriaObject() {
    let config: any;

    config = {
      searchCriteria: null,
      productType: ProductTypeEnum.FIX_ASSET_STORE_USE,
      page: 0,
      size: 20
    };

    this.criteriaObject = config;
  }

  setRouteTab() {}

  addItem(productOrderItem: ProductOrderItem, qty: number) {
    productOrderItem.isAddItem = true;
    if (qty) {
      let previousQty = 0;
      const selectPreviousQty$ = this.localStore
        .pipe(select(getOrderStoreUseSelectItemOrderById(productOrderItem.articleNo)))
        .pipe(filter(value => Boolean(value)))
        .subscribe(value => {
          previousQty = value.qty;
        });

      productOrderItem.qty = Number(previousQty) + Number(qty);

      const itemPrice = new ItemPrice<ProductOrderItem>(productOrderItem, 'qty');
      const amountExcVat = itemPrice.getTotalFrom('wholesalePriceExcVat');
      const vatAmount = itemPrice.getTotalFrom('vatAmount');
      const amount = getPriceFormat(amountExcVat.amount + vatAmount.amount);

      productOrderItem.amountExcVat = amountExcVat;
      productOrderItem.totalVat = vatAmount;
      productOrderItem.amount = amount;

      // If allowToDelete is undefinded, set to true
      if (typeof productOrderItem.allowToDelete === 'undefined') {
        productOrderItem.allowToDelete = true;
      }

      this.store.dispatch(new OrderStoreUseSelectItemOrderAddItem(productOrderItem));
      selectPreviousQty$.unsubscribe();
    }
  }

  setInitialValue() {
    this.pageSize = 20;
  }

  exportFileName(): string {
    return `Store Equipment ${this.timeToExport}.xlsx`;
  }

  public callServiceExportItem(selected: any, filename: any) {
    this.orderRequestStoreUse.exportItemStoreUse(selected).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, filename);
      },
      errorResponse => {
        this.errorExport = errorResponse.error.message || this.translate.instant(errorResponse.error.translateKey);
        this.showModalError(this.errorExport);
      }
    );
  }

  onExportItem() {
    const selected = {
      productType: 'FIX_ASSET-K0400,STORE_USE',
      excludeStatus: 'DELETE,SELECT'
    };
    this.callServiceExportItem(selected, this.exportFileName());
  }

  showModalError(message: string) {
    this.showModalMessage('Failed', message);
  }

  showModalMessage(title: string, message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title,
        message
      }
    });
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportOrderRequest.timeFormat);
  }
}
