import { Action } from '@ngrx/store';
import { ShelfFixAssetOrderItem } from '../../models/order-request.model';

export enum ShelfSelectFixAssetOrderActionTypes {
  SHELF_SELECT_FIX_ASSET_ORDER_ADD_ITEM = '[Shelf Select Fix Asset Order] Add Item',
  SHELF_SELECT_FIX_ASSET_ORDER_UPDATE_ITEM = '[Shelf Select Fix Asset Order] Update Item',
  SHELF_SELECT_FIX_ASSET_ORDER_UPDATE_ALL_ITEM = '[Shelf Select Fix Asset Order] Update ALL Item',
  SHELF_SELECT_FIX_ASSET_ORDER_REMOVE_ITEM = '[Shelf Select Fix Asset Order] Remove Item',
  SHELF_SELECT_FIX_ASSET_ORDER_RESET = '[Shelf Select Fix Asset Order] Reset',
  SHELF_SELECT_FIX_ASSET_ORDER_ADD_ALL_ITEM = '[Shelf Select Fix Asset Order] Add All Item'
}

export class ShelfSelectFixAssetOrderAddItem implements Action {
  readonly type = ShelfSelectFixAssetOrderActionTypes.SHELF_SELECT_FIX_ASSET_ORDER_ADD_ITEM;

  constructor(public payload: ShelfFixAssetOrderItem) {}
}

export class ShelfSelectFixAssetOrderUpdateItem implements Action {
  readonly type = ShelfSelectFixAssetOrderActionTypes.SHELF_SELECT_FIX_ASSET_ORDER_UPDATE_ITEM;

  constructor(public payload: ShelfFixAssetOrderItem) {}
}

export class ShelfSelectFixAssetOrderUpdateAllItem implements Action {
  readonly type = ShelfSelectFixAssetOrderActionTypes.SHELF_SELECT_FIX_ASSET_ORDER_UPDATE_ALL_ITEM;

  constructor(public payload: ShelfFixAssetOrderItem) {}
}

export class ShelfSelectFixAssetOrderAddAllItem implements Action {
  readonly type = ShelfSelectFixAssetOrderActionTypes.SHELF_SELECT_FIX_ASSET_ORDER_ADD_ALL_ITEM;

  constructor(public payload: { itemList: ShelfFixAssetOrderItem[]; skipUpdated?: boolean }) {}
}

export class ShelfSelectFixAssetOrderRemoveItem implements Action {
  readonly type = ShelfSelectFixAssetOrderActionTypes.SHELF_SELECT_FIX_ASSET_ORDER_REMOVE_ITEM;

  constructor(public payload: string) {}
}

export class ShelfSelectFixAssetOrderReset implements Action {
  readonly type = ShelfSelectFixAssetOrderActionTypes.SHELF_SELECT_FIX_ASSET_ORDER_RESET;

  constructor() {}
}

export type ShelfSelectFixAssetOrderActions =
  | ShelfSelectFixAssetOrderAddItem
  | ShelfSelectFixAssetOrderRemoveItem
  | ShelfSelectFixAssetOrderReset
  | ShelfSelectFixAssetOrderAddAllItem
  | ShelfSelectFixAssetOrderUpdateItem
  | ShelfSelectFixAssetOrderUpdateAllItem;
