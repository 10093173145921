import { Attribute, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ClaimService } from '../services/claim.service';

@Directive({
  selector: 'img[appClaimSecureSrc]'
})
export class AppClaimSecureSrcDirective implements OnInit, OnDestroy {
  @Attribute('img-error') public imgError: string;
  @Input() appClaimSecureSrc: string;

  private readonly nativeElement: HTMLElement;
  private fullSrcPath: string;
  disableClick = e => e.stopPropagation();

  constructor(
    private readonly renderer: Renderer2,
    private readonly el: ElementRef,
    private readonly claimService: ClaimService
  ) {
    this.nativeElement = this.el.nativeElement;
  }

  ngOnInit() {
    this.initAttribute();
  }

  ngOnDestroy() {}

  private initAttribute() {
    this.nativeElement.onerror = () => this.onError();
    this.nativeElement.setAttribute('loading', 'auto');

    this.fullSrcPath = './assets/images/no-image.svg';
    this.nativeElement.setAttribute('src', this.fullSrcPath);
    if (this.appClaimSecureSrc) {
      this.claimService
        .getFileUrl(this.appClaimSecureSrc)
        .pipe(filter(data => Boolean(data)))
        .subscribe(
          signedUrlObject => {
            if (signedUrlObject.signedUrl) {
              this.nativeElement.setAttribute('src', signedUrlObject.signedUrl);
            }
          },
          () => {
            this.nativeElement.setAttribute('src', './assets/images/load-image.svg');
            this.nativeElement.setAttribute('disabled', 'disabled');
          }
        );
    }
  }

  private onError() {
    const fullImagePath = this.imgError || './assets/images/no-image.svg';

    this.nativeElement.setAttribute('src', fullImagePath);
  }

  private onLoad() {
    if (this.nativeElement.getAttribute('src') !== this.appClaimSecureSrc) {
      this.nativeElement.setAttribute('src', this.fullSrcPath);
    }
  }
}
