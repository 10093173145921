import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssortmentProductListComponent } from './pages/assortment-product/assortment-product-list/assortment-product-list.component';
import { AssortmentRequestListComponent } from './pages/assortment-request/assortment-request-list/assortment-request-list.component';
import { AssortmentRequestComponent } from './pages/assortment-request/assortment-request/assortment-request.component';
import { ClaimRequestListComponent } from './pages/claim-request/claim-request-list/claim-request-list.component';
import { ClaimRequestViewComponent } from './pages/claim-request/claim-request-view/claim-request-view.component';
import { MyTaskComponent } from './pages/dashboard/my-task/my-task.component';
import { DeliveryOrderEditComponent } from './pages/delivery-order/delivery-order-edit/delivery-order-edit.component';
import { DeliveryOrderListComponent } from './pages/delivery-order/delivery-order-list/delivery-order-list.component';
import { ForceChangePasswordLeftComponent } from './pages/force-change-password/force-change-password-left/force-change-password-left.component';
import { ForceChangePasswordComponent } from './pages/force-change-password/force-change-password.component';
import { ForgotPasswordLeftComponent } from './pages/forgot-password/forgot-password-left/forgot-password-left.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ForgotUsernameLeftComponent } from './pages/forgot-username/forgot-username-left/forgot-username-left.component';
import { ForgotUsernameComponent } from './pages/forgot-username/forgot-username.component';
import { LoginLeftComponent } from './pages/login/login-left/login-left.component';
import { LoginRightComponent } from './pages/login/login-right.component';
import { ManageStockListComponent } from './pages/manage-stock/manage-stock-list/manage-stock-list.component';
import { MemberCardListComponent } from './pages/member-card/member-card-list/member-card-list.component';
import { MerchantListComponent } from './pages/merchant/merchant-list/merchant-list.component';
import { MerchantRequestListComponent } from './pages/merchant/merchant-request-list/merchant-request-list.component';
import { MerchantRequestComponent } from './pages/merchant/merchant-request/merchant-request.component';
import { ViewMerchantComponent } from './pages/merchant/view-merchant/view-merchant.component';
import { OrderListComponent } from './pages/order/order-list/order-list.component';
import { OrderRequestFixAssetComponent } from './pages/order/order-request-fix-asset/order-request-fix-asset.component';
import { OrderRequestInventoryComponent } from './pages/order/order-request-inventory/order-request-inventory.component';
import { OrderRequestListComponent } from './pages/order/order-request-list/order-request-list.component';
import { OrderRequestComponent } from './pages/order/order-request/order-request.component';
import { OrderViewComponent } from './pages/order/order-view/order-view.component';
import { AdvertisementCreateComponent } from './pages/pos-advertisement/advertisement-create/advertisement-create.component';
import { PosAdvertisementListComponent } from './pages/pos-advertisement/pos-advertisement-list/pos-advertisement-list.component';
import { ClassMarkupComponent } from './pages/price-setting/class-markup/class-markup.component';
import { StackPricingViewComponent } from './pages/price-setting/stack-pricing/stack-pricing-view/stack-pricing-view.component';
import { ProductListComponent } from './pages/products/product-list/product-list.component';
import { ProductViewComponent } from './pages/products/product-view/product-view.component';
import { MonthlyPartnerProfitSharingListComponent } from './pages/profit-sharing/monthly-partner-profit-sharing-list/monthly-partner-profit-sharing-list.component';
import { ProfitSharingListComponent } from './pages/profit-sharing/profit-sharing-list/profit-sharing-list.component';
import { ProfitSharingViewComponent } from './pages/profit-sharing/profit-sharing-view/profit-sharing-view.component';
import { PromotionListComponent } from './pages/promotion/promotion-list/promotion-list.component';
import { PromotionRequestListComponent } from './pages/promotion/promotion-request-list/promotion-request-list.component';
import { PromotionRequestComponent } from './pages/promotion/promotion-request/promotion-request.component';
import { GoodsReceiveListComponent } from './pages/purchase/goods-receive-list/goods-receive-list.component';
import { GoodsReceiveViewComponent } from './pages/purchase/goods-receive-view/goods-receive-view.component';
import { PurchaseOrderListComponent } from './pages/purchase/purchase-order-list/purchase-order-list.component';
import { PurchaseRequestListComponent } from './pages/purchase/purchase-request-list/purchase-request-list.component';
import { PurchaseRequestViewComponent } from './pages/purchase/purchase-request-view/purchase-request-view.component';
import { ReceiveOrderListComponent } from './pages/receive-order/receive-order-list/receive-order-list.component';
import { ReportListComponent } from './pages/reports/report-list.component';
import { RewardActiveListComponent } from './pages/reward/reward-active-list/reward-active-list.component';
import { RewardListComponent } from './pages/reward/reward-list/reward-list.component';
import { RewardRequestListComponent } from './pages/reward/reward-request-list/reward-request-list.component';
import { RewardRequestComponent } from './pages/reward/reward-request/reward-request.component';
import { RewardViewComponent } from './pages/reward/reward-view/reward-view.component';
import { RoleCreateComponent } from './pages/roles/role-create/role-create.component';
import { RoleListComponent } from './pages/roles/role-list/role-list.component';
import { ShelfFixAssetListComponent } from './pages/shelf/shelf-fix-asset/shelf-fix-asset-list/shelf-fix-asset-list.component';
import { ShelfFixAssetRequestListComponent } from './pages/shelf/shelf-fix-asset/shelf-fix-asset-request-list/shelf-fix-asset-request-list.component';
import { ShelfFixAssetRequestComponent } from './pages/shelf/shelf-fix-asset/shelf-fix-asset-request/shelf-fix-asset-request.component';
import { ShelfFixAssetViewComponent } from './pages/shelf/shelf-fix-asset/shelf-fix-asset-view/shelf-fix-asset-view.component';
import { ShelfInventoryListComponent } from './pages/shelf/shelf-inventory/shelf-inventory-list/shelf-inventory-list.component';
import { ShelfInventoryRequestListComponent } from './pages/shelf/shelf-inventory/shelf-inventory-request-list/shelf-inventory-request-list.component';
import { ShelfInventoryRequestComponent } from './pages/shelf/shelf-inventory/shelf-inventory-request/shelf-inventory-request.component';
import { ShelfInventoryViewComponent } from './pages/shelf/shelf-inventory/shelf-inventory-view/shelf-inventory-view.component';
import { ShelfListComponent } from './pages/shelf/shelf-list/shelf-list.component';
import { ShelfRequestListComponent } from './pages/shelf/shelf-request-list/shelf-request-list.component';
import { ShelfRequestComponent } from './pages/shelf/shelf-request/shelf-request.component';
import { ViewShelfComponent } from './pages/shelf/view-shelf/view-shelf.component';
import { ShipmentListComponent } from './pages/shipment/shipment-list/shipment-list.component';
import { StockTransferOrderListComponent } from './pages/stock-transfer/stock-transfer-order-list/stock-transfer-order-list.component';
import { StockTransferRequestListComponent } from './pages/stock-transfer/stock-transfer-request-list/stock-transfer-request-list.component';
import { StockInformationListComponent } from './pages/stock/stock-information-list/stock-information-list.component';
import { StockInformationComponent } from './pages/stock/stock-information/stock-information.component';
import { StoreAssortmentListComponent } from './pages/store-assortment/store-assortment-list/store-assortment-list.component';
import { StoreAssortmentRequestListComponent } from './pages/store-assortment/store-assortment-request-list/store-assortment-request-list.component';
import { StoreAssortmentRequestViewComponent } from './pages/store-assortment/store-assortment-request-view/store-assortment-request-view.component';
import { StoreAssortmentRequestComponent } from './pages/store-assortment/store-assortment-request/store-assortment-request.component';
import { ViewStoreAssortmentComponent } from './pages/store-assortment/view-store-assortment/view-store-assortment.component';
import { StoreConsultantListComponent } from './pages/store-consultant/store-consultant-list/store-consultant-list.component';
import { StoreGroupCreateComponent } from './pages/store-group/store-group-create/store-group-create.component';
import { StoreGroupListComponent } from './pages/store-group/store-group-list/store-group-list.component';
import { ViewParameterRequestComponent } from './pages/store-stock-planning/view-parameter-request/view-parameter-request.component';
import { ViewParameterComponent } from './pages/store-stock-planning/view-parameter/view-parameter.component';
import { Z8ParameterListComponent } from './pages/store-stock-planning/z8-parameter-list/z8-parameter-list.component';
import { Z8ParameterRequestListComponent } from './pages/store-stock-planning/z8-parameter-request-list/z8-parameter-request-list.component';
import { Z8ResultComponent } from './pages/store-stock-planning/z8-result/z8-result.component';
import { StoreListComponent } from './pages/store/store-list/store-list.component';
import { StoreRequestListComponent } from './pages/store/store-request-list/store-request-list.component';
import { StoreRequestComponent } from './pages/store/store-request/store-request.component';
import { ViewStoreComponent } from './pages/store/view-store/view-store.component';
import { SupplierEditComponent } from './pages/supplier/supplier-edit/supplier-edit.component';
import { SupplierListComponent } from './pages/supplier/supplier-list/supplier-list.component';
import { UserCreateComponent } from './pages/user/user-create/user-create.component';
import { UserListComponent } from './pages/user/user-list/user-list.component';
import { VoucherListComponent } from './pages/voucher/voucher-list/voucher-list.component';
import { VoucherRequestListComponent } from './pages/voucher/voucher-request-list/voucher-request-list.component';
import { VoucherRequestComponent } from './pages/voucher/voucher-request/voucher-request.component';
import { LoginComponent } from './shared/layouts';
import { AdminLayoutComponent } from './shared/layouts/admin/admin-layout.component';
import { LoginLayoutComponent } from './shared/layouts/login-layout/login-layout.component';
import {
  grManagePermissions,
  grViewPermissions,
  poManagePermissions,
  poViewPermissions,
  prApprovePermissions,
  prManagePermissions,
  prViewPermissions
} from './shared/permissions/permissions';
import { AuthGuardService } from './shared/services';

export const appRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
    children: [
      {
        path: '',
        component: LoginRightComponent
      },
      {
        path: '',
        outlet: 'leftsection',
        component: LoginLeftComponent
      }
    ],
    pathMatch: 'full'
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'forgot-username',
        children: [
          {
            path: '',
            component: ForgotUsernameComponent
          },
          {
            path: '',
            outlet: 'leftsection',
            component: ForgotUsernameLeftComponent
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'forgot-password',
        children: [
          {
            path: '',
            component: ForgotPasswordComponent
          },
          {
            path: '',
            outlet: 'leftsection',
            component: ForgotPasswordLeftComponent
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'force-change-password',
        canActivate: [AuthGuardService],
        children: [
          {
            path: '',
            component: ForceChangePasswordComponent
          },
          {
            path: '',
            outlet: 'leftsection',
            component: ForceChangePasswordLeftComponent
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard/my-task',
        component: MyTaskComponent,
        canActivate: [AuthGuardService],
        data: {
          title: 'HOME',
          group: 'HOME',
          icon: 'icon-td-home',
          active: true,
          order: 1,
          isShowOnMenu: true
        }
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'merchant-store',
        data: {
          title: 'MERCHANT_STORE',
          group: 'STORE',
          icon: 'icon-td-store',
          active: false,
          order: 2,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'merchant-list',
            component: MerchantListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'MERCHANT',
              isShowOnMenu: true,
              subgroup: 'MERCHANT',
              permissions: [
                'merchant_v',
                'merchant_vs',
                'merchant_key',
                'merchant_new_m',
                'merchant_edit_m',
                'merchant_order_m',
                'merchant_app'
              ]
            }
          },
          {
            path: 'merchant-request-list',
            component: MerchantRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'MERCHANT',
              permissions: ['merchant_new_m', 'merchant_edit_m', 'merchant_order_m', 'merchant_app']
            }
          },
          {
            path: 'merchant-request',
            component: MerchantRequestComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['merchant_new_m', 'merchant_edit_m', 'merchant_order_m', 'merchant_app']
            }
          },
          {
            path: 'view-merchant',
            component: ViewMerchantComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'store-list',
            component: StoreListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'STORE',
              isShowOnMenu: true,
              subgroup: 'STORE',
              permissions: [
                'store_v',
                'store_eq',
                'merchant_new_m',
                'merchant_edit_m',
                'merchant_order_m',
                'merchant_app'
              ]
            }
          },
          {
            path: 'store-request-list',
            component: StoreRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'STORE',
              permissions: ['merchant_new_m', 'merchant_edit_m', 'merchant_order_m', 'merchant_app']
            }
          },
          {
            path: 'view-store',
            component: ViewStoreComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'store-request',
            component: StoreRequestComponent,
            canActivate: [AuthGuardService]
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'store-assortment/store-assortment-list',
        component: StoreAssortmentListComponent,
        data: {
          title: 'STORE_ASSORTMENT',
          group: 'STORE',
          icon: 'icon-td-store-assortment',
          active: false,
          order: 3,
          isShowOnMenu: true,
          permissions: ['store_assortment_v', 'store_assortment_m']
        }
      },
      {
        path: 'store-assortment/view-store-assortment',
        component: ViewStoreAssortmentComponent,
        canActivate: [AuthGuardService],
        data: {
          subgroup: 'STORE',
          permissions: ['store_assortment_v', 'store_assortment_m']
        }
      },
      {
        path: 'store-assortment/store-assortment-request-list',
        component: StoreAssortmentRequestListComponent,
        canActivate: [AuthGuardService],
        data: {
          permissions: ['store_assortment_m']
        }
      },
      {
        path: 'store-assortment/store-assortment-request',
        component: StoreAssortmentRequestComponent,
        canActivate: [AuthGuardService],
        data: {
          permissions: ['store_assortment_m']
        }
      },
      {
        path: 'store-assortment/store-assortment-request-view',
        component: StoreAssortmentRequestViewComponent,
        canActivate: [AuthGuardService],
        data: {
          permissions: ['store_assortment_m']
        }
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'membership',
        data: {
          title: 'MEMBERSHIP',
          group: 'STORE',
          icon: 'icon-member',
          active: false,
          order: 4,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'member-card-list',
            component: MemberCardListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'MEMBER_CARD',
              isShowOnMenu: true,
              subgroup: 'MEMBER_CARD',
              permissions: ['mem_card_m', 'mem_card_v']
            }
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'order',
        data: {
          title: 'ORDER',
          group: 'STORE',
          icon: 'icon-td-order',
          active: false,
          order: 5,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'order-list',
            component: OrderListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'ORDER',
              isShowOnMenu: true,
              subgroup: 'ORDER',
              permissions: [
                'so_v',
                'or_v',
                'so_firstlot_m',
                'so_special_m',
                'so_app',
                'or_fl_ast_m',
                'or_fl_ast_app',
                'or_st_equip_m',
                'or_fl_inv_m',
                'or_fl_inv_app',
                'or_fl_qty_inv_m'
              ]
            }
          },
          {
            path: 'order-request-list',
            component: OrderRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'ORDER',
              permissions: [
                'or_v',
                'so_firstlot_m',
                'so_special_m',
                'so_app',
                'or_fl_ast_m',
                'or_fl_ast_app',
                'or_st_equip_m',
                'or_fl_inv_m',
                'or_fl_inv_app',
                'or_fl_qty_inv_m'
              ]
            }
          },
          {
            path: 'order-view',
            component: OrderViewComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'order-request',
            component: OrderRequestComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'order-request-fix-asset',
            component: OrderRequestFixAssetComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'order-request-inventory',
            component: OrderRequestInventoryComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'delivery-order-list',
            component: DeliveryOrderListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'DELIVERY_ORDER_SHIPMENT',
              isShowOnMenu: true,
              subgroup: 'DELIVERY_ORDER_SHIPMENT',
              permissions: [
                '^do_logis_v_[a-zA-Z]{2}[0-9]{1,3}$',
                '^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$',
                '^do_v_[a-zA-Z]{2}[0-9]{1,3}$',
                '^do_m_[a-zA-Z]{2}[0-9]{1,3}$'
              ],
              isRegex: true
            }
          },
          {
            path: 'delivery-order-edit',
            component: DeliveryOrderEditComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: [
                '^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$',
                '^do_v_[a-zA-Z]{2}[0-9]{1,3}$',
                '^do_m_[a-zA-Z]{2}[0-9]{1,3}$'
              ],
              isRegex: true
            }
          },
          {
            path: 'shipment-list',
            component: ShipmentListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'DELIVERY_ORDER_SHIPMENT',
              permissions: [
                '^do_logis_v_[a-zA-Z]{2}[0-9]{1,3}$',
                '^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$',
                '^do_m_[a-zA-Z]{2}[0-9]{1,3}$'
              ],
              isRegex: true
            }
          },
          {
            path: 'receive-order-list',
            component: ReceiveOrderListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'RECEIVE_ORDER',
              isShowOnMenu: true,
              subgroup: 'RECEIVE_ORDER',
              permissions: ['ro_v', 'cr_m']
            }
          },
          {
            path: 'receive-order-view',
            component: ReceiveOrderListComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['ro_v', 'cr_m']
            }
          },
          {
            path: 'claim-request-list',
            component: ClaimRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'CLAIM',
              isShowOnMenu: true,
              subgroup: 'CLAIM',
              permissions: ['cr_v', 'cr_app', 'cr_m']
            }
          },
          {
            path: 'claim-request-view',
            component: ClaimRequestViewComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['cr_v', 'cr_app']
            }
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'billing',
        data: {
          title: 'BILLING',
          group: 'STORE',
          icon: 'icon-td-bill',
          active: false,
          order: 6,
          isShowOnMenu: true
        },
        children: [
          // {
          //   path: 'bill-payment-list',
          //   component: CompensateListComponent,
          //   canActivate: [AuthGuardService],
          //   data: {
          //     title: 'BILL_PAYMENT',
          //     isShowOnMenu: true,
          //     subgroup: 'BILL_PAYMENT'
          //   }
          // },
          {
            path: 'profit-sharing-list',
            component: ProfitSharingListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'PROFIT_SHARING',
              isShowOnMenu: true,
              subgroup: 'PROFIT_SHARING',
              permissions: ['profitsharing_v', 'profitsharing_m']
            }
          },
          {
            path: 'profit-sharing-view',
            component: ProfitSharingViewComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['profitsharing_v', 'profitsharing_m']
            }
          },
          {
            path: 'monthly-partner-profit-sharing-list',
            component: MonthlyPartnerProfitSharingListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'MONTHLY_PROFIT_SHARING',
              subgroup: 'PROFIT_SHARING',
              permissions: ['profitsharing_m']
            }
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'products',
        data: {
          title: 'PRODUCT',
          group: 'ADMIN',
          icon: 'icon-td-product',
          active: false,
          order: 7,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'master-list',
            component: ProductListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'PRODUCT_MASTER',
              isShowOnMenu: true,
              subgroup: 'PRODUCT_MASTER',
              permissions: ['cjproduct_m_v', 'cjproduct_nm_v', 'tdassort_inv_m']
            }
          },
          {
            path: 'view',
            component: ProductViewComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['cjproduct_m_v', 'cjproduct_nm_v', 'tdassort_inv_m']
            }
          },
          {
            path: 'request-list',
            component: AssortmentRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              // permissions: ['tdassort_m_m', 'tdassort_nm_m', 'tdassort_m_app', 'tdassort_nm_app']
              subgroup: 'PRODUCT_ASSORTMENT',
              permissions: [
                'tdassort_inv_m',
                'tdassort_asset_m',
                'tdassort_sto_m',
                'tdassort_inv_app',
                'tdassort_asset_app',
                'tdassort_sto_app'
              ]
            }
          },
          {
            path: 'request-view',
            component: AssortmentRequestComponent,
            canActivate: [AuthGuardService],
            data: {
              // permissions: ['tdassort_m_m', 'tdassort_nm_m', 'tdassort_m_app', 'tdassort_nm_app']
              permissions: [
                'tdassort_inv_m',
                'tdassort_asset_m',
                'tdassort_sto_m',
                'tdassort_inv_app',
                'tdassort_asset_app',
                'tdassort_sto_app'
              ]
            }
          },
          {
            path: 'product-list',
            component: AssortmentProductListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'PRODUCT_ASSORTMENT',
              isShowOnMenu: true,
              subgroup: 'PRODUCT_ASSORTMENT',
              permissions: [
                'tdassort_inv_m',
                'tdassort_asset_m',
                'tdassort_sto_m',
                'tdassort_inv_app',
                'tdassort_asset_app',
                'tdassort_sto_app',
                'tdassort_inv_v',
                'tdassort_asset_v',
                'tdassort_sto_v'
              ]
            }
          },
          {
            path: 'stack-pricing-edit',
            component: StackPricingViewComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['price_m_v', 'price_m_m', 'price_nm_v', 'price_nm_m']
            }
          },
          {
            path: 'class-markup',
            component: ClassMarkupComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['price_m_v', 'price_m_m', 'price_nm_v', 'price_nm_m']
            }
          },
          {
            path: 'shelf-list',
            component: ShelfListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'SHELF',
              isShowOnMenu: true,
              subgroup: 'SHELF',
              permissions: ['shelf_v', 'shelf_m', 'shelf_app', 'shelf_firstlot_m']
            }
          },
          {
            path: 'shelf-request-list',
            component: ShelfRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'SHELF',
              permissions: ['shelf_m', 'shelf_app', 'shelf_firstlot_m']
            }
          },
          {
            path: 'shelf-request',
            component: ShelfRequestComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'view-shelf',
            component: ViewShelfComponent,
            canActivate: [AuthGuardService]
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'shelf',
        data: {
          title: 'SHELF',
          group: 'ADMIN',
          icon: 'icon-shelf',
          active: false,
          order: 8,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'shelf-inventory-list',
            component: ShelfInventoryListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'SHELF_INVENTORY',
              isShowOnMenu: true,
              subgroup: 'SHELF_INVENTORY',
              permissions: ['sh_inv_v', 'sh_inv_rq_v', 'sh_inv_m', 'sh_inv_app', 'sh_inv_flq_m']
            }
          },
          {
            path: 'shelf-inventory-request-list',
            component: ShelfInventoryRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'SHELF_INVENTORY',
              permissions: ['sh_inv_rq_v', 'sh_inv_m', 'sh_inv_app', 'sh_inv_flq_m']
            }
          },
          {
            path: 'shelf-inventory-request',
            component: ShelfInventoryRequestComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'shelf-inventory-view',
            component: ShelfInventoryViewComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'shelf-fix-asset-list',
            component: ShelfFixAssetListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'SHELF_FIX_ASSET',
              isShowOnMenu: true,
              subgroup: 'SHELF_FIX_ASSET',
              permissions: ['sh_ast_v', 'sh_ast_rq_v', 'sh_ast_m', 'sh_ast_app']
            }
          },
          {
            path: 'shelf-fix-asset-request-list',
            component: ShelfFixAssetRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'SHELF_FIX_ASSET',
              permissions: ['sh_ast_rq_v', 'sh_ast_m', 'sh_ast_app']
            }
          },
          {
            path: 'shelf-fix-asset-request',
            component: ShelfFixAssetRequestComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'shelf-fix-asset-view',
            component: ShelfFixAssetViewComponent,
            canActivate: [AuthGuardService]
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'campaign',
        data: {
          title: 'CAMPAIGN',
          group: 'ADMIN',
          icon: 'icon-td-marketing',
          active: false,
          order: 9,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'promotion-list',
            component: PromotionListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'PROMOTION',
              isShowOnMenu: true,
              subgroup: 'PROMOTION',
              permissions: ['promotion_m', 'promotion_app', 'promotion_v']
            }
          },
          {
            path: 'promotion-request-list',
            component: PromotionRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'PROMOTION',
              permissions: ['promotion_m', 'promotion_app']
            }
          },
          {
            path: 'promotion-request-create',
            component: PromotionRequestComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['promotion_m', 'promotion_app']
            }
          },
          {
            path: 'voucher-list',
            component: VoucherListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'VOUCHER',
              isShowOnMenu: true,
              subgroup: 'VOUCHER',
              permissions: ['voucher_m', 'voucher_v', 'voucher_app']
            }
          },
          {
            path: 'voucher-request-list',
            component: VoucherRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'VOUCHER',
              permissions: ['voucher_m', 'voucher_app']
            }
          },
          {
            path: 'voucher-request-create',
            component: VoucherRequestComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['voucher_m']
            }
          },
          {
            path: 'reward-active-list',
            component: RewardActiveListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'REWARD',
              isShowOnMenu: true,
              subgroup: 'REWARD',
              permissions: ['rw_v', 'rw_m', 'rr_v', 'rw_app']
            }
          },
          {
            path: 'reward-list',
            component: RewardListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'REWARD',
              isShowOnMenu: false,
              subgroup: 'REWARD',
              permissions: ['rw_v', 'rw_m', 'rw_app']
            }
          },
          {
            path: 'reward-request-list',
            component: RewardRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'REWARD',
              permissions: ['rw_m', 'rw_app', 'rr_v']
            }
          },
          {
            path: 'reward-request-create',
            component: RewardRequestComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['rw_m', 'rw_app']
            }
          },
          {
            path: 'reward-view',
            component: RewardViewComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['voucher_m']
            }
          },
          {
            path: 'pos-advertisement-list',
            component: PosAdvertisementListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'POS_ADVERTISEMENT',
              isShowOnMenu: true,
              subgroup: 'POS_ADVERTISEMENT',
              permissions: ['pos_ads_m', 'pos_ads_v']
            }
          },
          {
            path: 'pos-advertisement-crate',
            component: AdvertisementCreateComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['pos_ads_m', 'pos_ads_v']
            }
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'purchase',
        data: {
          title: 'PURCHASE',
          group: 'ADMIN',
          icon: 'icon-td-purchase',
          active: false,
          order: 10,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'purchase-request-list',
            component: PurchaseRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'PR_PO_GR',
              isShowOnMenu: true,
              subgroup: 'PR_PO_GR',
              isRegex: true,
              permissions: [
                ...prViewPermissions,
                ...prManagePermissions,
                ...prApprovePermissions,
                ...poViewPermissions,
                ...poManagePermissions,
                ...grViewPermissions,
                ...grManagePermissions
              ]
            }
          },
          {
            path: 'purchase-request-view',
            component: PurchaseRequestViewComponent,
            canActivate: [AuthGuardService],
            data: {
              isRegex: true,
              permissions: [...prViewPermissions, ...prManagePermissions, ...prApprovePermissions]
            }
          },
          {
            path: 'purchase-order-list',
            component: PurchaseOrderListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'PR_PO_GR',
              isRegex: true,
              permissions: [...poViewPermissions, ...poManagePermissions, ...grManagePermissions]
            }
          },
          {
            path: 'goods-receive-list',
            component: GoodsReceiveListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'PR_PO_GR',
              isRegex: true,
              permissions: [...grViewPermissions, ...grManagePermissions]
            }
          },
          {
            path: 'goods-receive-view',
            component: GoodsReceiveViewComponent,
            canActivate: [AuthGuardService],
            data: {
              isRegex: true,
              permissions: [...grViewPermissions, ...grManagePermissions]
            }
          },
          {
            path: 'purchase-condition',
            canActivate: [AuthGuardService],
            data: {
              title: 'PURCHASE_CONDITION',
              isShowOnMenu: true,
              subgroup: 'PURCHASE_CONDITION',
              isRegex: false,
              permissions: ['pc_req_m', 'pc_req_app', 'pc_req_v', 'pc_v']
            },
            loadChildren: () =>
              import('./pages/purchase-condition/purchase-condition.module').then(m => m.PurchaseConditionModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'stock',
        data: {
          title: 'STOCK',
          group: 'ADMIN',
          icon: 'icon-stock',
          active: false,
          order: 11,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'stock-information-list',
            component: StockInformationListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'STOCK_INFORMATION',
              isShowOnMenu: true,
              subgroup: 'STOCK_INFORMATION',
              permissions: ['^stk_v_[a-zA-Z]{2}[0-9]{1,3}$', 'stk_v_sto', 'stk_v_all'],
              isRegex: true
            }
          },
          {
            path: 'stock-information',
            component: StockInformationComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: []
            }
          },
          {
            path: 'manage-stock',
            component: ManageStockListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'MANAGE_STOCK',
              isShowOnMenu: true,
              subgroup: 'MANAGE_STOCK',
              permissions: [
                'stkadj_m_sto',
                'stkadj_v_sto',
                '^stkadj_v_[a-zA-Z]{2}[0-9]{1,3}$',
                '^stkadj_m_[a-zA-Z]{2}[0-9]{1,3}$'
              ],
              isRegex: true
            }
          },
          {
            path: 'z8-result',
            component: Z8ResultComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'STORE_STOCK_PLANNING',
              isShowOnMenu: true,
              subgroup: 'STORE_STOCK_PLANNING',
              permissions: [
                'stk_plan_v_sto',
                'stk_plan_m_sto',
                '^z8_m_[a-zA-Z]{2}[0-9]{1,3}$',
                '^z8_v_[a-zA-Z]{2}[0-9]{1,3}$'
              ],
              isRegex: true
            }
          },
          {
            path: 'z8-parameter-list',
            component: Z8ParameterListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'STORE_STOCK_PLANNING',
              permissions: ['stk_plan_v_sto', 'stk_plan_m_sto']
            }
          },
          {
            path: 'z8-parameter-request-list',
            component: Z8ParameterRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'STORE_STOCK_PLANNING',
              permissions: ['stk_plan_m_sto']
            }
          },
          {
            path: 'view-parameter',
            component: ViewParameterComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'view-parameter-request',
            component: ViewParameterRequestComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'stock-transfer-list',
            component: StockTransferOrderListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'STOCK_TRANSFER',
              isShowOnMenu: true,
              subgroup: 'STOCK_TRANSFER',
              permissions: ['tr_m', 'to_v', 'tr_v', 'tr_app']
            }
          },
          {
            path: 'stock-transfer-request-list',
            component: StockTransferRequestListComponent,
            canActivate: [AuthGuardService],
            data: {
              subgroup: 'STOCK_TRANSFER',
              permissions: ['tr_m', 'tr_app', 'tr_v']
            }
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'supplier',
        data: {
          title: 'SUPPLIER',
          group: 'ADMIN',
          icon: 'icon-td-supplier',
          active: false,
          order: 11,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'supplier-list',
            component: SupplierListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'SUPPLIER',
              isShowOnMenu: true,
              subgroup: 'SUPPLIER',
              permissions: ['supplier_m', 'supplier_v', 'supplier_set_m']
            }
          },
          {
            path: 'supplier-edit',
            component: SupplierEditComponent,
            canActivate: [AuthGuardService]
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'user-management',
        data: {
          title: 'USER_MANAGEMENT',
          group: 'ADMIN',
          icon: 'icon-td-user',
          active: false,
          order: 12,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'role-list',
            component: RoleListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'ROLES',
              isShowOnMenu: true,
              subgroup: 'ROLES',
              permissions: ['role_m', 'role_v']
            }
          },
          {
            path: 'store-group-list',
            component: StoreGroupListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'STORE_GROUP',
              isShowOnMenu: true,
              subgroup: 'STORE_GROUP',
              permissions: ['store_group_m', 'store_group_v']
            }
          },
          {
            path: 'store-consultant-list',
            component: StoreConsultantListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'STORE_CONSULTANT',
              isShowOnMenu: true,
              subgroup: 'STORE_CONSULTANT',
              permissions: ['store_consult_m', 'store_consult_v']
            }
          },
          {
            path: 'user-list',
            component: UserListComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'USERS',
              isShowOnMenu: true,
              subgroup: 'USERS',
              permissions: ['user_bo_m', 'user_bo_v']
            }
          },
          {
            path: 'role-create',
            component: RoleCreateComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['role_m']
            }
          },
          {
            path: 'user-create',
            component: UserCreateComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['user_bo_m']
            }
          },
          {
            path: 'store-group-create',
            component: StoreGroupCreateComponent,
            canActivate: [AuthGuardService],
            data: {
              permissions: ['store_group_m']
            }
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'reports',
        component: ReportListComponent,
        canActivate: [AuthGuardService],
        data: {
          title: 'REPORT',
          group: 'ADMIN',
          icon: 'icon-td-report',
          active: false,
          order: 13,
          isShowOnMenu: true,
          permissions: [
            'rep_voucher_v',
            'rep_stockcard_v',
            'rep_awating_firstlot_v',
            'rep_redeemtx_v',
            'rep_rewardtx_v',
            'rep_dt_v',
            'rep_dt_tns_v',
            'rep_saletx_v',
            'rep_deletesalesitem_v',
            'rep_sales_summary_v',
            'rep_totetx_v',
            'rep_promotion_compensate_v',
            'rep_inventorycount_tmp_v',
            'rep_tdmoving_avg_v',
            'rep_inventory_count_v',
            'rep_moneyin_v',
            'rep_inventory_adj_req_wp_v',
            'rep_saletxc_v',
            'rep_storec_v',
            'rep_tdassortc_v'
          ]
        }
      }
    ]
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
