import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RequestAssortmentService } from '../../services';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import { ProductAssortmentLoaded, RequestAssortmentActionTypes, RequestAssortmentApproveRejectSuccess, RequestAssortmentCheckCJDuplicateBarcodeResponse, RequestAssortmentCopySuccess, RequestAssortmentDuplicateBarcode, RequestAssortmentLoaded, RequestAssortmentSearchLoaded, RequestAssortmentSearchRequested, RequestAssortmentSubmitError, RequestAssortmentUpdateSuccess } from '../actions/request-assortment.actions';
import { SupplierPriceIsPriceUpdated } from '../actions/supplier-price.actions';
import { selectRequestAssortmentCriteria } from '../selectors/request-assortment.selectors';
export class RequestAssortmentEffects {
    constructor(actions$, requestAssortmentService, store, logger) {
        this.actions$ = actions$;
        this.requestAssortmentService = requestAssortmentService;
        this.store = store;
        this.logger = logger;
        this.searchRequestAssortments$ = this.actions$.pipe(ofType(RequestAssortmentActionTypes.RequestAssortmentSearchRequested), tap(action => this.logger.debug('@Effect Request Assortment Search: ' + JSON.stringify(action.payload))), switchMap(action => this.requestAssortmentService.searchByCriteria(action.payload).pipe(map(requestAssortment => new RequestAssortmentSearchLoaded({ requestAssortment })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.deleteRequestAssortments$ = this.actions$.pipe(ofType(RequestAssortmentActionTypes.RequestAssortmentDeleteRequested), tap(action => this.logger.debug('@Effect Request Assortment Delete: ' + JSON.stringify(action.payload))), mergeMap(action => this.requestAssortmentService.deleteByRequestId(action.payload.requestId).pipe(
        // map(() =>  new RequestAssortmentDeleteSuccess()),
        map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been deleted.'
        })), catchError(err => err.error && err.error.code === '00004'
            ? of(new LayoutActionVersionError(true))
            : of(new LayoutActionLoadError(err))))));
        this.cancelRequestAssortments$ = this.actions$.pipe(ofType(RequestAssortmentActionTypes.RequestAssortmentCancelRequested), tap(action => this.logger.debug('@Effect Request Assortment Cancel: ' + JSON.stringify(action.payload))), switchMap(action => this.requestAssortmentService.cancel(action.payload).pipe(
        // map(() =>  new RequestAssortmentDeleteSuccess()),
        map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been cancelled.'
        })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.loadRequestAssortment$ = this.actions$.pipe(ofType(RequestAssortmentActionTypes.RequestAssortmentLoadRequested), tap(action => this.logger.debug('@Effect Request Assortment Load: ' + JSON.stringify(action.payload))), mergeMap(action => this.requestAssortmentService.getByRequestId(action.payload.requestId).pipe(map(requestAssortment => new RequestAssortmentLoaded({ requestAssortment })), catchError(err => of(new LayoutActionLoadError(err))))));
        this.saveRequestAssortment$ = this.actions$.pipe(ofType(RequestAssortmentActionTypes.RequestAssortmentUpdateRequested), tap(action => this.logger.debug('@Effect Request Assortment Save: ' + JSON.stringify(action.payload))), switchMap(action => this.requestAssortmentService.updateByRequestId(action.payload.assortment).pipe(withLatestFrom(this.store.select(selectRequestAssortmentCriteria)), tap(([, criteria]) => this.searchWithCriteria(criteria)), map(() => new RequestAssortmentUpdateSuccess({ status: true })), catchError(error => {
            if (error.error && ['05013', '05015', '05019'].includes(error.error.code)) {
                return of(new RequestAssortmentSubmitError({ code: error.error.code, message: error.error.message }));
            }
            else {
                of(new LayoutActionLoadError(error));
            }
        }))));
        this.createRequestAssortment$ = this.actions$.pipe(ofType(RequestAssortmentActionTypes.RequestAssortmentCreateRequested), tap(action => this.logger.debug('@Effect Request Assortment Create: ' + JSON.stringify(action.payload))), switchMap(action => this.requestAssortmentService.create(action.payload.assortment).pipe(withLatestFrom(this.store.select(selectRequestAssortmentCriteria)), tap(([, criteria]) => this.searchWithCriteria(criteria)), map(() => new RequestAssortmentUpdateSuccess({ status: true })), catchError(error => {
            if (error.error && ['05013', '05019'].includes(error.error.code)) {
                return of(new RequestAssortmentSubmitError({ code: error.error.code, message: error.error.message }));
            }
            else {
                of(new LayoutActionLoadError(error));
            }
        }))));
        this.submitRequestAssortment$ = this.actions$.pipe(ofType(RequestAssortmentActionTypes.RequestAssortmentSubmitRequested), tap(action => this.logger.debug('@Effect Request Assortment Submit: ' + JSON.stringify(action.payload))), switchMap(action => this.requestAssortmentService.submit(action.payload.assortment).pipe(withLatestFrom(this.store.select(selectRequestAssortmentCriteria)), tap(([, criteria]) => this.searchWithCriteria(criteria)), map(() => new RequestAssortmentUpdateSuccess({ status: true })), catchError(error => {
            if (error.error.code === '05014') {
                return of(new RequestAssortmentDuplicateBarcode(error.error.message));
            }
            else if (error.error && ['05013', '05015', '05019'].includes(error.error.code)) {
                return of(new RequestAssortmentSubmitError({ code: error.error.code, message: error.error.message }));
            }
            else if (error.error.code === '00004') {
                return of(new LayoutActionVersionError(true));
            }
            else {
                return of(new LayoutActionLoadError(error));
            }
        }))));
        this.loadProductAssortment$ = this.actions$.pipe(ofType(RequestAssortmentActionTypes.ProductAssortmentLoadRequested), tap(action => this.logger.debug('@Effect Product Assortment Load: ' + JSON.stringify(action.payload))), mergeMap(action => this.requestAssortmentService.getById(action.payload.data, action.payload.isProduct).pipe(switchMap(requestAssortment => [
            new ProductAssortmentLoaded({ requestAssortment }),
            new SupplierPriceIsPriceUpdated(requestAssortment.isPriceUpdated ? requestAssortment.isPriceUpdated : false)
        ]), catchError(error => of(new LayoutActionLoadError(error))))));
        this.approveRequestAssortments$ = this.actions$.pipe(ofType(RequestAssortmentActionTypes.RequestAssortmentApproveRequested), tap(action => this.logger.debug('@Effect Request Assortment Approve: ' + JSON.stringify(action.payload))), mergeMap(action => this.requestAssortmentService.approve(action.payload).pipe(withLatestFrom(this.store.select(selectRequestAssortmentCriteria)), tap(([, criteria]) => {
            this.store.dispatch(new RequestAssortmentSearchRequested(criteria));
        }), map(() => new RequestAssortmentApproveRejectSuccess({ isSuccess: true })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.rejectRequestAssortments$ = this.actions$.pipe(ofType(RequestAssortmentActionTypes.RequestAssortmentRejectRequested), tap(action => this.logger.debug('@Effect Request Assortment Reject: ' + JSON.stringify(action.payload))), mergeMap(action => this.requestAssortmentService.reject(action.payload).pipe(withLatestFrom(this.store.select(selectRequestAssortmentCriteria)), tap(([, criteria]) => {
            this.store.dispatch(new RequestAssortmentSearchRequested(criteria));
        }), map(() => new RequestAssortmentApproveRejectSuccess({ isSuccess: true })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.copyRequestAssortments$ = this.actions$.pipe(ofType(RequestAssortmentActionTypes.RequestAssortmentCopyRequested), tap(action => this.logger.debug('@Effect Request Assortment Copy: ' + JSON.stringify(action.payload))), switchMap(action => this.requestAssortmentService.copy(action.payload.requestId).pipe(withLatestFrom(this.store.select(selectRequestAssortmentCriteria)), tap(([, criteria]) => {
            this.store.dispatch(new RequestAssortmentSearchRequested(criteria));
        }), map(([response]) => new RequestAssortmentCopySuccess(Object.assign({}, response))), catchError(error => of(new LayoutActionLoadError(error))))));
        this.searchAssortmentFreshLife = this.actions$.pipe(ofType(RequestAssortmentActionTypes.AssortmentFreshLifeListRequest), tap(() => this.logger.debug('@Effect Assortment Fresh Life Search: ')), switchMap(() => this.requestAssortmentService.searchFreshLife().pipe(map(requestAssortment => new RequestAssortmentSearchLoaded({ requestAssortment })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.checkDuplicateCJBarcode$ = this.actions$.pipe(ofType(RequestAssortmentActionTypes.RequestAssortmentCheckCJDuplicateBarcodeRequest), tap(action => this.logger.debug('@Effect Request Assortment Check Duplicate CJ Barcode: ' + JSON.stringify(action.payload))), switchMap(action => this.requestAssortmentService.checkCJDuplicateBarcode(action.payload).pipe(map(result => {
            return new RequestAssortmentCheckCJDuplicateBarcodeResponse({ cjBarcodeList: result });
        }), catchError(error => {
            if (error.error.code === '00004') {
                return of(new RequestAssortmentCheckCJDuplicateBarcodeResponse({ cjBarcodeList: [] }));
            }
            else {
                return of(new LayoutActionLoadError(error));
            }
        }))));
    }
    searchWithCriteria(criteria) {
        if (criteria) {
            this.store.dispatch(new RequestAssortmentSearchRequested(criteria));
        }
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RequestAssortmentEffects.prototype, "searchRequestAssortments$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RequestAssortmentEffects.prototype, "deleteRequestAssortments$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RequestAssortmentEffects.prototype, "cancelRequestAssortments$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RequestAssortmentEffects.prototype, "loadRequestAssortment$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RequestAssortmentEffects.prototype, "saveRequestAssortment$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RequestAssortmentEffects.prototype, "createRequestAssortment$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RequestAssortmentEffects.prototype, "submitRequestAssortment$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RequestAssortmentEffects.prototype, "loadProductAssortment$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RequestAssortmentEffects.prototype, "approveRequestAssortments$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RequestAssortmentEffects.prototype, "rejectRequestAssortments$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RequestAssortmentEffects.prototype, "copyRequestAssortments$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RequestAssortmentEffects.prototype, "searchAssortmentFreshLife", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RequestAssortmentEffects.prototype, "checkDuplicateCJBarcode$", void 0);
