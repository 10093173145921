/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./checkbox-group.component";
var styles_CheckboxGroupComponent = [];
var RenderType_CheckboxGroupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CheckboxGroupComponent, data: {} });
export { RenderType_CheckboxGroupComponent as RenderType_CheckboxGroupComponent };
export function View_CheckboxGroupComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_CheckboxGroupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-checkbox-group", [], null, null, null, View_CheckboxGroupComponent_0, RenderType_CheckboxGroupComponent)), i0.ɵprd(5120, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxGroupComponent]), i0.ɵdid(2, 49152, null, 0, i2.CheckboxGroupComponent, [], null, null)], null, null); }
var CheckboxGroupComponentNgFactory = i0.ɵccf("app-checkbox-group", i2.CheckboxGroupComponent, View_CheckboxGroupComponent_Host_0, {}, {}, ["*"]);
export { CheckboxGroupComponentNgFactory as CheckboxGroupComponentNgFactory };
