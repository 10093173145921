import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  StoreConsultantContent,
  StoreConsultantModel,
  StoreConsultantSearchCriteria
} from '../../models/store-consultant.model';
import { StoreConsultantActionTypes, StoreConsultantListActions } from '../actions/store-consultant.actions';
import { BaseState } from '../state/base.state';

export interface StoreConsultantState extends EntityState<StoreConsultantContent>, BaseState {
  selected: StoreConsultantModel;
  criteriaObject: StoreConsultantSearchCriteria;
  totalElements: number;
  totalPages: number;
  isLoading: boolean;
}

export const adapter: EntityAdapter<StoreConsultantContent> = createEntityAdapter<StoreConsultantContent>();

export const initialStoreConsultantListState: StoreConsultantState = adapter.getInitialState({
  isLoading: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  selected: null
});

export function StoreConsultantReducers(
  state = initialStoreConsultantListState,
  action: StoreConsultantListActions
): StoreConsultantState {
  switch (action.type) {
    case StoreConsultantActionTypes.STORE_CONSULTANT_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case StoreConsultantActionTypes.STORE_CONSULTANT_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case StoreConsultantActionTypes.STORE_CONSULTANT_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: { ...action.payload }
      };
    case StoreConsultantActionTypes.STORE_CONSULTANT_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
