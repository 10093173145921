import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ImportSupplierDto } from '../../../../shared/models/supplier.model';
import { SupplierService } from '../../../../shared/services/supplier.service';
import { SupplierListRequestAction } from '../../../../shared/store/actions/supplier.actions';
import { selectSupplierState } from '../../../../shared/store/selectors/supplier.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-supplier-import-modal',
  templateUrl: './supplier-import-modal.component.html',
  styleUrls: ['./supplier-import-modal.component.scss']
})
export class SupplierImportModalComponent implements OnInit {
  public form: FormGroup;
  private localStore: Observable<any>;

  public runDate: string;
  public supplierObject;
  public criteriaObject: any;
  public isSubmit: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    private readonly supplierService: SupplierService,
    protected modalService: BsModalService
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.createForm();
    this.form.controls.uploadSupplier.valueChanges.pipe(filter(Boolean), distinctUntilChanged()).subscribe(value => {
      this.supplierObject = value[0];
    });

    this.localStore
      .pipe(select(selectSupplierState))
      .subscribe(criteriaObject => (this.criteriaObject = criteriaObject.criteriaObject));
  }

  refreshList() {
    this.criteriaObject.page = 0;
    this.store.dispatch(new SupplierListRequestAction(this.criteriaObject));
  }

  createForm() {
    this.form = this.fb.group({
      uploadSupplier: [null]
    });
  }

  downloadStockAdjustmentTemplate() {
    this.supplierService.downloadFileTemplate().subscribe(
      response => this.saveFile(environment.fileName.exportSupplier.fileTemplate, response),
      error => this.alertErrorModal(error.error.message)
    );
  }

  saveFile(fileName: string, response: Blob) {
    const blob = new Blob([response]);
    saveAs(blob, `${fileName}.xlsx`);
  }

  onSubmit() {
    this.isSubmit = true;
    if (!this.supplierObject && !this.supplierObject.importSupplierDtos) {
      return;
    }

    const importSuppliers: ImportSupplierDto[] = this.supplierObject.importSupplierDtos;

    this.supplierService.submitImportSupplier(importSuppliers).subscribe(
      () => this.alertSuccessModal('The data have been imported.'),
      error => {
        this.alertErrorModal(error.error.message);
        this.isSubmit = false;
      }
    );
  }

  alertErrorModal(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message: message
      }
    });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.refreshList();
        alertModal.hide();
        this.bsModalRef.hide();
      }
    });
  }

  ngOnDestroy(): void {
    this.supplierObject = null;
  }
}
