/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../directives/prevent-double-click.directive";
import * as i4 from "@angular/forms";
import * as i5 from "./file-upload.component";
import * as i6 from "ngx-bootstrap/modal";
import * as i7 from "../../services/common.upload.service";
import * as i8 from "ngx-logger";
var styles_FileUploadComponent = [i0.styles];
var RenderType_FileUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadComponent, data: {} });
export { RenderType_FileUploadComponent as RenderType_FileUploadComponent };
function View_FileUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-warning new badge-position"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Update"]))], null, null); }
function View_FileUploadComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "upload image"], ["class", "upload-image-preview"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.imgSrc; _ck(_v, 0, 0, currVal_0); }); }
function View_FileUploadComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "em", [["class", "icon-close-mini"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_FileUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "upload-preview-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "upload-image-display"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isNew; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.imgSrc; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_v.context.$implicit.imgSrc && !_co.disabled); _ck(_v, 8, 0, currVal_2); }, null); }
function View_FileUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FileUploadComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "upload-container text-center"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { disabled: 0 }), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["uploadInput", 1]], null, 1, "input", [["appPreventDoubleClick", ""], ["class", "upload-input"], ["id", "uploadFile"], ["type", "file"]], [[8, "disabled", 0], [8, "multiple", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i3.PreventDoubleClickDirective, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "d-flex flex-column upload-inner"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "em", [["class", "icon-plus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "label", [["class", "upload-label"], ["for", "uploadFile"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Image"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "upload-container text-center"; var currVal_1 = _ck(_v, 3, 0, _co.disabled); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.disabled; var currVal_3 = _co.multiple; _ck(_v, 4, 0, currVal_2, currVal_3); }); }
export function View_FileUploadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { uploadInput: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noImage", 2]], null, 0, null, View_FileUploadComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.fileList !== null) && (_co.fileList.length > 0)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_FileUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-file-upload", [], null, [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onChange($event.target.files) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_FileUploadComponent_0, RenderType_FileUploadComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.FileUploadComponent]), i1.ɵprd(5120, null, i4.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i5.FileUploadComponent]), i1.ɵdid(3, 770048, null, 0, i5.FileUploadComponent, [i1.Renderer2, [3, i4.ControlContainer], i6.BsModalService, i7.CommonUploadService, i8.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var FileUploadComponentNgFactory = i1.ɵccf("app-file-upload", i5.FileUploadComponent, View_FileUploadComponent_Host_0, { allowedExt: "allowedExt", disabled: "disabled", multiple: "multiple", allowedTypes: "allowedTypes", size: "size", withMeta: "withMeta", maxHeight: "maxHeight", maxWidth: "maxWidth", uploadUrl: "uploadUrl", uploadHeaders: "uploadHeaders", baseStorageUrl: "baseStorageUrl", controlName: "controlName", initialFileList: "initialFileList", isNew: "isNew", errorIncorrectFormat: "errorIncorrectFormat", errorImageSize: "errorImageSize" }, { showImage: "showImage", imageSuccess: "imageSuccess", isDelete: "isDelete" }, []);
export { FileUploadComponentNgFactory as FileUploadComponentNgFactory };
