import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { TDStoreValidatorTypeEnum } from '../enum/merchant-validator-type.enum';
import { productClassesQuery } from '../gql/classes.gql';
import { productGradingQuery } from '../gql/product-grading.gql';
import {
  ApproveShelfInventoryRequest,
  RequestId,
  ShelfInventoryCreateRequest,
  ShelfInventoryRequestListResponse,
  ShelfInventoryRequestListSearchCriteria,
  ShelfInventoryRequestViewResponse
} from '../models/shelf-inventory-request.model';
import { BaseService } from './base.service';
import { MasterService } from './master.service';

@Injectable()
export class ShelfInventoryRequestService extends BaseService {
  public headers: HttpHeaders;

  constructor(
    private readonly http: HttpClient,
    private readonly masterService: MasterService,
    protected readonly logger: NGXLogger
  ) {
    super();
    this.envService = this.env.services.shelfInventoryRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getShelfValidate(
    type: TDStoreValidatorTypeEnum,
    value: string,
    urlParams: { [key: string]: string } = null
  ): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.validate, { type, value });
    const params = urlParams ? this.getParams(urlParams) : null;

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'response',
      params
    });
  }

  public searchByCriteria(
    criteria: ShelfInventoryRequestListSearchCriteria
  ): Observable<ShelfInventoryRequestListResponse> {
    const params = this.getParams(criteria);

    return this.http.get<ShelfInventoryRequestListResponse>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public saveRequest(shelfRequest: ShelfInventoryCreateRequest): Observable<ShelfInventoryRequestViewResponse> {
    const url = this.getFullUrl(this.envService.save);

    return this.http.put<ShelfInventoryRequestViewResponse>(url, shelfRequest, { headers: this.loaderHeaders(2000) });
  }

  public submitRequest(shelfRequest: ShelfInventoryCreateRequest): Observable<ShelfInventoryRequestViewResponse> {
    const url = this.getFullUrl(this.envService.submit);

    return this.http.post<ShelfInventoryRequestViewResponse>(url, shelfRequest, {
      headers: this.loaderHeaders(2000)
    });
  }

  public getShelfInventoryValidate(
    type: TDStoreValidatorTypeEnum,
    value: string,
    urlParams: { [key: string]: string } = null
  ): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.validate, { type, value });
    const params = urlParams ? this.getParams(urlParams) : null;

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'response',
      params
    });
  }

  public getShelfRequestById(requestId: RequestId): Observable<ShelfInventoryRequestViewResponse> {
    const url = this.getFullUrl(this.envService.view, {
      requestId: requestId.requestId
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public deleteByRequestId(requestId: RequestId): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.delete, {
      requestId: requestId.requestId
    });
    return this.http.delete<any>(url, { headers: this.loaderHeaders(2000), observe: 'body' });
  }

  public approveRequest(request: ApproveShelfInventoryRequest): Observable<ShelfInventoryRequestViewResponse> {
    const url = this.getFullUrl(this.envService.approve);

    return this.http.put<ShelfInventoryRequestViewResponse>(url, request, {
      headers: this.loaderHeaders(2000),
      observe: 'body'
    });
  }

  public exportInventoryItem(data: any): Observable<any> {
    const url = this.getFullUrl(this.envService.exportFirstLot);
    const params = this.getParams(data);

    return this.http.get<any>(url, { headers: this.loaderHeaders(), responseType: 'blob' as 'json', params });
  }

  public getHistoryLogs(requestId: RequestId): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      requestId: requestId.requestId
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public getProductGrading(): Observable<ApolloQueryResult<any>> {
    return this.masterService.watchQuery({ query: productGradingQuery });
  }

  public getClasses(): Observable<ApolloQueryResult<any>> {
    return this.masterService.watchQuery({ query: productClassesQuery });
  }

  public exportShelf(selected: Array<string>): Observable<any> {
    const url = this.getFullUrl(this.envService.export);

    return this.http.post<any>(
      url,
      { selected },
      {
        headers: this.loaderHeaders(),
        observe: 'body',
        responseType: 'blob' as 'json'
      }
    );
  }
}
