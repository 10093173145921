import { createSelector } from '@ngrx/store';
import * as fromStockProductState from '../reducers/stock-product.reducers';
import { AppStates } from '../state/app.states';

const selectStockProductState = (state: AppStates) => state.stockProduct;

export const selectAllStockProductList = createSelector(selectStockProductState, fromStockProductState.selectAll);

export const selectStockProductList = createSelector(
  selectStockProductState,
  (state: fromStockProductState.StockProductState) => state
);

export const selectStockProductListCriteria = createSelector(selectStockProductState, state => {
  return state.criteriaObject;
});
