/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/ngx-perfect-scrollbar/dist/ngx-perfect-scrollbar.ngfactory";
import * as i6 from "ngx-perfect-scrollbar";
import * as i7 from "../version-tag/version-tag.component.ngfactory";
import * as i8 from "../version-tag/version-tag.component";
import * as i9 from "./sidebar.component";
import * as i10 from "./sidebar.service";
import * as i11 from "@ngrx/store";
var styles_SidebarComponent = [i0.styles];
var RenderType_SidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarComponent, data: { "animation": [{ type: 7, name: "slide", definitions: [{ type: 0, name: "up", styles: { type: 6, styles: { height: 0 }, offset: null }, options: undefined }, { type: 0, name: "down", styles: { type: 6, styles: { height: "*" }, offset: null }, options: undefined }, { type: 1, expr: "up <=> down", animation: { type: 4, styles: null, timings: 200 }, options: null }], options: {} }] } });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
function View_SidebarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "section-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 2, 0, currVal_0); }); }
function View_SidebarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(("MENU." + _v.parent.context.$implicit.title))); _ck(_v, 1, 0, currVal_0); }); }
function View_SidebarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.toggle(_v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "em", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.link, ""); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icon, ""); _ck(_v, 2, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(("MENU." + _v.parent.context.$implicit.title))); _ck(_v, 4, 0, currVal_4); }); }
function View_SidebarComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "em", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icon, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(("MENU." + _v.parent.context.$implicit.title))); _ck(_v, 3, 0, currVal_1); }); }
function View_SidebarComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "active-link": 0 }), i1.ɵdid(4, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i1.ElementRef, i1.Renderer2, [2, i3.RouterLink], [2, i3.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, [[2, 4]], 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 2), (_l()(), i1.ɵted(10, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.isSubmenuActive(_v.context.$implicit)); _ck(_v, 2, 0, currVal_0); var currVal_1 = "active-link-lazy"; _ck(_v, 4, 0, currVal_1); var currVal_4 = _ck(_v, 9, 0, _v.parent.parent.context.$implicit.link, _v.context.$implicit.link); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 8).target; var currVal_3 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(("MENU." + _v.context.$implicit.title))); _ck(_v, 10, 0, currVal_5); }); }
function View_SidebarComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "sidebar-submenu"]], [[24, "@slide", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["id", "side-submenu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_8)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.submenus; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getState(_v.parent.context.$implicit); _ck(_v, 0, 0, currVal_0); }); }
function View_SidebarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { active: 0, "sidebar-dropdown": 1, "header-menu": 2, "active-link": 3 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_4)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_5)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_6)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_7)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.active, (_v.context.$implicit.type === "dropdown"), (_v.context.$implicit.type === "header"), _co.isMenuActive(_v.context.$implicit.link)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type === "header"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type === "simple"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_v.context.$implicit.type === "dropdown"); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_v.context.$implicit.type === "dropdown"); _ck(_v, 11, 0, currVal_4); }, null); }
function View_SidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "sidebar-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [["id", "side-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_3)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.title && (_co.getMenuByGroup(_v.context.$implicit.group).length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getMenuByGroup(_v.context.$implicit.group); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_SidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "nav", [["class", "sidebar"], ["id", "sidebar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "sidebar-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "perfect-scrollbar", [], [[2, "ps-show-limits", null], [2, "ps-show-active", null]], null, null, i5.View_PerfectScrollbarComponent_0, i5.RenderType_PerfectScrollbarComponent)), i1.ɵdid(3, 507904, null, 0, i6.PerfectScrollbarComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SidebarComponent_1)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-version-tag", [["class", "footer"]], null, null, null, i7.View_VersionTagComponent_0, i7.RenderType_VersionTagComponent)), i1.ɵdid(7, 114688, null, 0, i8.VersionTagComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_2 = _co.menuGroup; _ck(_v, 5, 0, currVal_2); _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).autoPropagation; var currVal_1 = i1.ɵnov(_v, 3).scrollIndicators; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_SidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i9.SidebarComponent, [i10.SidebarService, i11.Store, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i1.ɵccf("app-sidebar", i9.SidebarComponent, View_SidebarComponent_Host_0, {}, {}, []);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };
