import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { StockTransferPageModes } from '../../../shared/enum/stock-transfer-request.enum';
import { StockTransferStatusEnum } from '../../../shared/enum/stock-transfer.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { RouteLinkTab } from '../../../shared/models';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import {
  StockTransferExportCriteria,
  StockTransferList,
  StockTransferListSearchCriteria
} from '../../../shared/models/stock-transfer.model';
import { WarehouseListContent } from '../../../shared/models/warehouse.model';
import { AuthGuardService } from '../../../shared/services';
import { StockTransferService } from '../../../shared/services/stock-trasfer-service';
import {
  StockTransferCloseRequestAction,
  StockTransferListRequestAction
} from '../../../shared/store/actions/stock-transfer.actions';
import { WarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { StockTransferState } from '../../../shared/store/reducers/stock-transfer.reducers';
import {
  selectAllStockTransferList,
  selectStockTransfer,
  selectStockTransferListCriteria
} from '../../../shared/store/selectors/stock-transfer.selectors';
import { selectAllWarehouse } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { StockTransferOrderViewComponent } from '../stock-transfer-order-view/stock-transfer-order-view.component';

@Component({
  selector: 'app-stock-transfer-list',
  templateUrl: './stock-transfer-order-list.component.html',
  styleUrls: ['./stock-transfer-order-list.component.scss']
})
export class StockTransferOrderListComponent extends BaseSearchComponent<
  StockTransferListSearchCriteria,
  StockTransferList,
  StockTransferState
> {
  @ViewChild('exportModal', { static: false }) exportModal: ModalDirective;

  public localStore: Observable<any>;
  public exportForm: FormGroup;
  public orderDateTag: string;
  public orderDateStringTag: string;
  public deliveryDateTag: string;
  public deliveryDateStringTag: string;
  public shipFromTag: string;
  public shipFromStringTag: string;
  public shipToTag: string;
  public shipToStringTag: string;
  public submitted: boolean;
  public bsModalRef: BsModalRef;
  dateFormat = environment.dateFormat;
  orderMinDate: Date;
  orderMaxDate: Date;
  minDeliveryDate: Date;
  maxDeliveryDate: Date;
  public warehouseList: WarehouseListContent[];
  public shipmentStatusExportFilter: NgOption[];
  public submittedExport: boolean;
  public errorExport: string;
  public listRoute: Array<RouteLinkTab>;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export',
      hidden: !this.authGuardService.checkPermission(['tr_m', 'to_v'])
    }
  ];

  public stockTransferStatusList: Array<{ value: string; label: string }> = filterDropdown.stockTransferStatusFilter;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    private stockTransferService: StockTransferService
  ) {
    super(store, modalService, selectAllStockTransferList, selectStockTransfer);
    super.subscribeForSaveSuccess();
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [''],
      orderDateFrom: [null],
      orderDateTo: [null],
      deliveryDateFrom: [null],
      deliveryDateTo: [null],
      shipFrom: [null],
      shipTo: [null]
    });

    this.exportForm = this.fb.group({
      orderDateFrom: [null],
      orderDateTo: [null],
      deliveryDateFrom: [null],
      deliveryDateTo: [null],
      shipFrom: [null],
      shipTo: [null],
      transferStatus: ['']
    });
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  doDestroy() {}

  doInit() {
    this.setInitialData();
  }

  hideModalExportStockTransfer() {
    this.submittedExport = false;
    this.exportForm.reset();
    this.clearError();
    this.exportModal.hide();
  }

  goToView(stockTransfer: StockTransferList) {
    const initialState = {
      childItem: new ChildItem(
        StockTransferOrderViewComponent,
        {
          docNo: stockTransfer.docNo,
          title: StockTransferOrderListComponent.getStockTransferTitle(StockTransferPageModes.VIEW),
          mode: StockTransferPageModes.VIEW
        },
        false
      )
    };
    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;

    if (
      !formValue.orderDateFrom &&
      !formValue.orderDateTo &&
      !formValue.deliveryDateFrom &&
      !formValue.deliveryDateTo &&
      !formValue.shipFrom &&
      !formValue.shipTo
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      orderDateFrom: dateToStringCriteria(formValue.orderDateFrom, true),
      orderDateTo: dateToStringCriteria(formValue.orderDateTo, false),
      deliveryDateFrom: dateToStringCriteria(formValue.deliveryDateFrom, true),
      deliveryDateTo: dateToStringCriteria(formValue.deliveryDateTo, false),
      shipFrom: formValue.shipFrom,
      shipTo: formValue.shipTo,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.prepareOrderDateTags();
    this.prepareDeliveryDateTags();
    this.prepareShipFromTags();
    this.prepareShipToTags();
  }

  prepareOrderDateTags() {
    this.orderDateTag = null;
    this.orderDateStringTag = null;

    const orderDateFrom = dateStringToTagCriteria(this.criteriaObject.orderDateFrom);
    const orderDateTo = dateStringToTagCriteria(this.criteriaObject.orderDateTo);
    const orderDate = generateDateStringTag({
      dateName: 'Order Date',
      dateFrom: orderDateFrom,
      dateTo: orderDateTo
    });

    this.orderDateTag = orderDate.dateTag;
    this.orderDateStringTag = orderDate.dateStringTag;
  }

  prepareDeliveryDateTags() {
    this.deliveryDateTag = null;
    this.deliveryDateStringTag = null;

    const deliveryDateFrom = dateStringToTagCriteria(this.criteriaObject.deliveryDateFrom);
    const deliveryDateTo = dateStringToTagCriteria(this.criteriaObject.deliveryDateTo);
    const deliveryDate = generateDateStringTag({
      dateName: 'Delivery Date',
      dateFrom: deliveryDateFrom,
      dateTo: deliveryDateTo
    });

    this.deliveryDateTag = deliveryDate.dateTag;
    this.deliveryDateStringTag = deliveryDate.dateStringTag;
  }

  prepareShipFromTags() {
    this.shipFromTag = null;
    this.shipFromStringTag = null;

    if (this.criteriaObject.shipFrom && this.criteriaObject.shipFrom.length) {
      this.shipFromStringTag = 'Ship From';
      const shipFrom = this.warehouseList
        .filter(data => this.criteriaObject.shipFrom.includes(data.code))
        .map(data => data.warehouseNameDisplay)
        .join(', ');
      this.shipFromTag = `"${shipFrom}"`;
    }
  }

  prepareShipToTags() {
    this.shipToTag = null;
    this.shipToStringTag = null;

    if (this.criteriaObject.shipTo && this.criteriaObject.shipTo.length) {
      this.shipToStringTag = 'Ship To';
      const shipTo = this.warehouseList
        .filter(data => this.criteriaObject.shipTo.includes(data.code))
        .map(data => data.warehouseNameDisplay)
        .join(', ');
      this.shipToTag = `"${shipTo}"`;
    }
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  search(criteriaObj) {
    this.store.dispatch(new StockTransferListRequestAction(criteriaObj));
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission(['tr_m', 'to_v']);
    const hasRequestPagePermission = this.authGuardService.checkPermission(['tr_m', 'tr_app', 'tr_v']);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Transfer Order', url: '/stock/stock-transfer-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Transfer Request', url: '/stock/stock-transfer-request-list' });
    }
  }

  setInitialData() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore.pipe(select(selectStockTransferListCriteria)).subscribe(criteriaObject => {
      return (this.currentPage = criteriaObject.page + 1);
    });

    this.store.dispatch(new WarehouseListRequestAction('-'));
    this.localStore.pipe(select(selectAllWarehouse('Warehouse'))).subscribe(data => {
      this.warehouseList = data;
    });

    this.minDeliveryDate = new Date(2019, 0, 1);
    this.maxDeliveryDate = new Date();
    this.maxDeliveryDate.setDate(this.maxDeliveryDate.getDate() + 365);
    this.orderMaxDate = new Date();
    this.orderMaxDate.setDate(this.orderMaxDate.getDate() + 365);
    this.shipmentStatusExportFilter = filterDropdown.stockTransferStatusFilter.filter(status => status.value !== '');
  }

  onChangeStatus(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeDeliveryDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDeliveryDate = new Date(value);
    } else {
      this.minDeliveryDate = new Date(2019, 0, 1);
    }
  }

  onChangeDeliveryDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDeliveryDate = new Date(value);
    } else {
      this.maxDeliveryDate = new Date();
      this.maxDeliveryDate.setDate(this.maxDeliveryDate.getDate() + 365);
    }
  }

  onChangeOrderDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.orderMinDate = new Date(value);
      this.orderMaxDate = new Date(value);
      this.orderMaxDate.setDate(this.orderMaxDate.getDate() + 90);
    } else {
      this.orderMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeOrderDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.orderMaxDate = new Date(value);
      this.orderMinDate = new Date(value);
      this.orderMinDate.setDate(this.orderMinDate.getDate() - 90);
    } else {
      this.orderMaxDate = new Date();
      this.orderMaxDate.setDate(this.orderMaxDate.getDate() + 365);
    }
  }

  clearAdvanceFilter() {
    this.setFirstPage();
    this.resetOrderDate();
    this.resetDeliveryDate();
    this.resetShipFrom();
    this.resetShipTo();
    this.prepareSearchCriteriaTags();
    this.doSearch(this.criteriaObject);
  }

  clearOrderDateFilter() {
    this.setFirstPage();
    this.resetOrderDate();
    this.doSearch(this.criteriaObject);
  }

  clearDeliveryDateFilter() {
    this.setFirstPage();
    this.resetDeliveryDate();
    this.doSearch(this.criteriaObject);
  }

  clearShipFromFilter() {
    this.setFirstPage();
    this.resetShipFrom();
    this.doSearch(this.criteriaObject);
  }

  clearShipToFilter() {
    this.setFirstPage();
    this.resetShipTo();
    this.doSearch(this.criteriaObject);
  }

  resetOrderDate() {
    this.searchForm.controls['orderDateFrom'].reset();
    this.searchForm.controls['orderDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      orderDateFrom: null,
      orderDateTo: null,
      page: 0
    };
  }

  resetDeliveryDate() {
    this.searchForm.controls['deliveryDateFrom'].reset();
    this.searchForm.controls['deliveryDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      deliveryDateFrom: null,
      deliveryDateTo: null,
      page: 0
    };
  }

  resetShipFrom() {
    this.searchForm.controls['shipFrom'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      shipFrom: null,
      page: 0
    };
  }

  resetShipTo() {
    this.searchForm.controls['shipTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      shipTo: null,
      page: 0
    };
  }

  getColorStatus(status: string) {
    return status ? status.toLowerCase() : '';
  }

  showHistory(stockTransfer: StockTransferList) {
    const initialState = {
      title: 'History',
      historyHeader: `Transfer Order No.: ${stockTransfer.docNo}`,
      auditLogs: stockTransfer.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  onCloseTo(stockTransfer: StockTransferList) {
    let sumDelivery = 0;
    stockTransfer.items.forEach(item => (sumDelivery += item.deliveredQty));
    if (sumDelivery === 0) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message: 'Not allow to close transfer order which is not dispatched.',
          okText: 'OK'
        }
      });
    } else {
      const confirmModal = this.modalService.show(ConfirmWithMessageModalComponent, {
        initialState: {
          title: 'Confirm',
          message: `Are you sure you want to Close TO Number "${stockTransfer.docNo}"?`,
          label: 'Reason',
          isRequiredConfirmMessage: true,
          okText: 'Yes, Close'
        }
      });

      confirmModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new StockTransferCloseRequestAction({
              id: stockTransfer.id,
              comment: confirmModal.content.confirmMessage
            })
          );
        }
      });
    }
  }

  onExport() {
    this.submittedExport = true;
    if (this.exportFormInValid) {
      return;
    }

    const formData = this.exportForm.getRawValue();
    const request = this.prepareExportRequestData(formData);

    this.clearError();
    this.stockTransferService.exportStockTransfer(request).subscribe(
      response => {
        const blob = new Blob([response]);

        saveAs(blob, this.generatedFileName());
      },
      error => {
        this.alertErrorModal(error.error);
      },
      () => {
        this.doSearch(this.criteriaObject);
        this.resetExportForm();
        this.exportModal.hide();
      }
    );
  }

  prepareExportRequestData(formData): StockTransferExportCriteria {
    const orderDateFrom = dateToStringCriteria(formData.orderDateFrom);
    const orderDateTo = dateToStringCriteria(formData.orderDateTo, false);
    const deliveryDateFrom = dateToStringCriteria(formData.deliveryDateFrom);
    const deliveryDateTo = dateToStringCriteria(formData.deliveryDateTo, false);
    const shipFrom = this.getValue(formData.shipFrom);
    const shipTo = this.getValue(formData.shipTo);
    const statuses = this.getValue(formData.transferStatus);

    return {
      orderDateFrom,
      orderDateTo,
      deliveryDateFrom,
      deliveryDateTo,
      shipFrom,
      shipTo,
      statuses
    };
  }

  clearError() {
    this.exportForm.controls['orderDateFrom'].setErrors(null);
    this.exportForm.controls['orderDateTo'].setErrors(null);
    this.exportForm.controls['deliveryDateFrom'].setErrors(null);
    this.exportForm.controls['deliveryDateTo'].setErrors(null);
    this.errorExport = null;
  }

  generatedFileName() {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.exportTransferOrder.timeFormat);

    return `${environment.fileName.exportTransferOrder.prefix} ${formattedDate}.xlsx`;
  }

  resetExportForm() {
    this.exportForm.controls['orderDateFrom'].reset();
    this.exportForm.controls['orderDateTo'].reset();
    this.exportForm.controls['deliveryDateFrom'].reset();
    this.exportForm.controls['deliveryDateTo'].reset();
    this.exportForm.controls['shipFrom'].reset();
    this.exportForm.controls['shipTo'].reset();
    this.exportForm.controls['transferStatus'].reset();
    this.submittedExport = false;
    this.errorExport = null;
  }

  alertErrorModal(errorResponse) {
    if (errorResponse.code && errorResponse.message) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };
      if (['00001', '00006'].includes(errorResponse.code)) {
        this.errorExport = initialState.message;
      } else {
        const alertModal = this.modalService.show(AlertModalComponent, {
          initialState
        });

        const modal = alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(() => {
          if (modal) {
            modal.unsubscribe();
          }
        });
      }
    }
  }

  hasClosePermission(status: StockTransferStatusEnum) {
    return (
      [StockTransferStatusEnum.PROCESSING, StockTransferStatusEnum.DELIVERED].includes(status) &&
      this.authGuardService.checkPermission(['tr_m'])
    );
  }

  get exportFormInValid() {
    const formValues = this.exportForm.value;
    return !(
      formValues.orderDateFrom ||
      formValues.orderDateTo ||
      formValues.deliveryDateFrom ||
      formValues.deliveryDateTo
    );
  }

  public static getStockTransferTitle(mode: StockTransferPageModes) {
    let title: string;
    switch (mode) {
      case StockTransferPageModes.CREATE:
        title = 'Create Transfer Order';
        break;
      case StockTransferPageModes.VIEW:
        title = 'View Transfer Order';
        break;
      case StockTransferPageModes.EDIT:
        title = 'Edit Transfer Order';
        break;
      default:
    }
    return title;
  }
}
