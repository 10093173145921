import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthsDisplay'
})
export class MonthsDisplayPipe implements PipeTransform {
  private shortMonthsObg = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
  };

  private normalMonthsObg = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  };

  transform(value: number[], shortness = false): string {
    if (value) {
      if (value.length === 12) {
        return 'All Months';
      } else {
        return value
          .map(day => {
            if (shortness) {
              return this.shortMonths(day);
            } else {
              return this.normalMonths(day);
            }
          })
          .join(', ');
      }
    }
  }

  shortMonths(day: number) {
    return this.shortMonthsObg[day];
  }

  normalMonths(day: number) {
    return this.normalMonthsObg[day];
  }
}
