import { ShelfDetailRequest } from '.';
import { OrderSubTypeEnum, OrderTypeEnum } from '../enum/ordering-method.enum';
import { NewRequestStatusEnum } from '../enum/request-status.enum';
import { NewRequestTypeEnum } from '../enum/request-step.enum';
import { DeliveryDetail, DeliveryDetailsByTypeDictionary, OrderStore, ProductOrderItem } from './order-request.model';
import { Price } from './price.model';

export class OrderInventoryCreateRequest {
  id?: string;
  version?: number;
  requestNo?: string;
  type: NewRequestTypeEnum;
  status: NewRequestStatusEnum;
  orderType: OrderTypeEnum;
  orderSubType?: OrderSubTypeEnum;
  store: OrderStore;
  shelfInventoryItems: ProductOrderItem[];
  selectiveItems: ProductOrderItem[];
  amount: Price;
  vatAmount: Price;
  amountExcVat: Price;
  deliveryStatus?: string;
  shelfDetails: ShelfDetailRequest[];
  deliveryDetails: DeliveryDetails;
  warehouseCode: string;
  warehouseName?: string;
  warehouseDisplayName?: string;
  sizeItemShelfInventory: number;
}

export class OrderInventoryRequestViewResponse extends OrderInventoryCreateRequest {
  requestedBy: string;
  requestedByName: string;
  requestedDate: string;
}

export class DeliveryDetails {
  storeOpenDate?: string;
  deliveryDetailsByType: DeliveryDetailsByTypeDictionary<DeliveryDetail>;
  errorStatus?: boolean;
}
