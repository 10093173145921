import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  ShelfFixAssetList,
  ShelfFixAssetListSearchCriteria,
  ShelfFixAssetViewResponse
} from '../../models/shelf-fix-asset.model';
import { ShelfFixAssetActions, ShelfFixAssetActionType } from '../actions/shelf-fix-asset.actions';
import { BaseState } from '../state/base.state';

export interface ShelfFixAssetState extends EntityState<ShelfFixAssetList>, BaseState {
  selected: ShelfFixAssetViewResponse;
  criteriaObject: ShelfFixAssetListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<ShelfFixAssetList> = createEntityAdapter<ShelfFixAssetList>();

export const initialShelfResponseState: ShelfFixAssetState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null
});

export function shelfFixAssetReducers(
  state = initialShelfResponseState,
  action: ShelfFixAssetActions
): ShelfFixAssetState {
  switch (action.type) {
    case ShelfFixAssetActionType.SHELF_FIX_ASSET_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case ShelfFixAssetActionType.SHELF_FIX_ASSET_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case ShelfFixAssetActionType.SHELF_FIX_ASSET_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case ShelfFixAssetActionType.EDIT_SHELF_FIXASSET:
      return {
        ...state,
        selected: {
          ...state.selected,
          type: action.payload.type,
          ...(action.payload.status && { status: action.payload.status })
        }
      };
    case ShelfFixAssetActionType.SHELF_FIX_ASSET_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case ShelfFixAssetActionType.SUBMIT_SHELF_FIX_ASSET_FIRST_LOT_ERROR:
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    case ShelfFixAssetActionType.SHELF_FIX_ASSET_LIST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
