import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap';
import { ImageModalComponent } from '../../../shared/layouts/modals/image-modal/image-modal.component';

@Component({
  selector: 'app-images-panel',
  templateUrl: './images-panel.component.html',
  styleUrls: ['./images-panel.component.scss']
})
export class ImagesPanelComponent implements OnInit {
  @Input() mode: string;
  @Input() title: string;
  @Input() description: string;
  @Input() maxNoOfFiles: number;
  @Input() allowedExt: string; // ex : (.jpg|.jpeg|.png)
  @Input() size: number; // Bytes
  @Input() uploadUrl: string;
  @Input() uploadHeaders: any;
  @Input() baseStorageUrl: string;
  @Input() hasDirectory: boolean;
  @Input() form: FormGroup;
  @Input() arrayName: string;
  @Input() errorImageSize: string;
  @Input() errorIncorrectFormat: string;

  public allowedTypes: string;
  public disableImage: boolean;
  constructor(protected readonly modalService: BsModalService, protected readonly fb: FormBuilder) {}

  ngOnInit() {
    this.initInputValue();
  }

  initInputValue(): void {
    if (!this.description) {
      this.description =
        'Upload max 10 images in format .jpg, .jpeg, .png. Each image size up to 3 MB (Recommended 800 * 1080 pixels image).';
    }
    if (!this.title) {
      this.title = 'Images';
    }

    if (this.allowedExt) {
      this.allowedTypes = this.allowedExt.replace(/\./g, '');
    } else {
      this.allowedExt = '(.jpg|.jpeg|.png)';
      this.allowedTypes = '(jpg|jpeg|png)';
    }

    if (!this.maxNoOfFiles) {
      this.maxNoOfFiles = 10;
    }

    if (!this.size) {
      this.size = 3145728;
    }
    if (!this.mode) {
      this.mode = 'CREATE';
    }

    this.disableImage = this.mode.toUpperCase().includes('VIEW');
  }

  setDisableImage(disable: boolean): void {
    this.disableImage = disable;
  }

  showImage(value) {
    if (value) {
      this.modalService.show(ImageModalComponent, {
        initialState: {
          url: value[0].imgSrc
        },
        class: 'modal-image'
      });
    }
  }

  addNewUpload(image): void {
    if (this.hasDirectory && image) {
      this.uploadUrl = `${this.uploadUrl}/${image[0].directory}`;
      this.hasDirectory = false;
    }
    if (this.formArray.length < this.maxNoOfFiles) {
      this.formArray.push(this.fb.group({ image: [{ value: null, disabled: false }] }));
    }
  }
  deleteImage(i: number): void {
    this.formArray.removeAt(i);

    if (this.formArray.getRawValue().filter(x => x.image !== null).length === this.maxNoOfFiles - 1) {
      this.addNewUpload(null);
    }
  }
  onUploaded(i: number) {
    if (this.formArray.at(i).get('image').invalid) {
      this.formArray
        .at(i)
        .get('image')
        .setValue(null);
      this.formArray
        .at(i)
        .get('image')
        .updateValueAndValidity({ onlySelf: true });
    }
  }
  get formArray() {
    return this.form.get(this.arrayName) as FormArray;
  }
}
