<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-order"></em>
    <span>{{ data.title }}</span>
  </div>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="deliveryOrder" class="admin-form" *ngIf="claimRequestView$ | async as claimResult">
  <div class="info-header-container">
    <div class="info-header-item" *ngIf="claimResult?.claimRequestNo">
      <div class="info-header-label">
        <span>Claim Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ claimResult?.claimRequestNo | dashDisplay }}</span>
        <div *ngIf="claimResult?.createdWithRO" class="info-badge-label">GR</div>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Receive Order No.</span>
      </div>
      <div class="info-header-value">
        <app-reference-document-link
          module="RECEIVE_ORDER"
          [permissions]="['ro_v']"
          [text]="claimResult?.roNo | dashDisplay"
          [referenceNo]="claimResult?.roNo | dashDisplay"
        >
        </app-reference-document-link>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Order No.</span>
      </div>
      <div class="info-header-value">
        <app-reference-document-link
          module="ORDER"
          [permissions]="['so_v']"
          [text]="claimResult?.orderNo | dashDisplay"
          [referenceNo]="claimResult?.orderNo | dashDisplay"
        >
        </app-reference-document-link>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Delivery Order No.</span>
      </div>
      <div class="info-header-value">
        <span>{{ claimResult?.doNo | dashDisplay }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Claim Request Status:</span>
      </div>
      <div class="info-header-value">
        <span class="cr-status" [ngClass]="getColorStatus(claimResult.claimRequestStatus)">
          {{ 'CLAIM_REQUEST.CLAIM_REQUEST_STATUS.' + claimResult?.claimRequestStatus | translate }}
        </span>
      </div>
    </div>
  </div>

  <div class="information-container">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Store</span>
          </div>
          <div class="info-header-value">
            {{ claimResult?.storeCode + '-' + claimResult?.storeName | dashDisplay }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Store Type</span>
          </div>
          <div class="info-header-value">
            {{ claimResult?.storeType ? ('MERCHANT.TYPE.' + claimResult?.storeType | translate) : '-' }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Warehouse</span>
          </div>
          <div class="info-header-value">
            <span>{{ claimResult?.warehouseDisplayName | dashDisplay }}</span>
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Order Type</span>
          </div>
          <div class="info-header-value">
            {{ claimResult?.orderType ? ('ORDER_TYPE.' + claimResult?.orderType | translate) : '-' }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Requested By</span>
          </div>
          <div class="info-header-value">{{ claimResult?.requestedBy | dashDisplay }}</div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Requested Date</span>
          </div>
          <div class="info-header-value">
            {{ claimResult?.requestedDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay }}
          </div>
        </div>
        <div>
          <div class="total-amount">
            <div class="text-right">
              <span class="order-total">
                {{ form.get('totalApprovedAmount').value | numberFormatDisplay }}
              </span>
              <span class="order-total-currency">
                THB
              </span>
            </div>
            <div class="info-total-detail">
              <span>
                (Total Amount before VAT: {{ form.get('totalApprovedExcVatAmount').value | numberFormatDisplay }} |
                <span class="is-link" (click)="showVatDetails()">
                  VAT: {{ form.get('totalApprovedVatAmount').value | numberFormatDisplay }})
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="claim-wrapper">
    <div class="row">
      <div class="col-md">
        <div class="mt-2 table-responsive" [formGroup]="claimForm">
          <div class="d-flex justify-content-end" *ngIf="canApproveReject()">
            <button type="button" class="btn btn-standard" (click)="showApproveAllItem()">
              <em class="icon-approve-all"></em>
              Approve All Items
            </button>
          </div>
          <div class="section-header">
            <span>Item List</span>
          </div>
          <table
            *ngIf="getItemForm.controls?.length"
            id="datatable"
            class="table table-striped table-bordered table-hover"
          >
            <thead>
              <tr>
                <th rowspan="2" class="w-5 text-center align-top">No.</th>
                <th rowspan="2" class="w-10 text-center align-top">Article No.</th>
                <th rowspan="2" class="w-20 text-center align-top">Product Name</th>
                <th rowspan="2" class="w-10 text-center align-top">Barcode</th>
                <th colspan="4" class="w-30 text-center align-top">Claim</th>
                <th colspan="2" class="w-20 text-center align-top">Approved</th>
                <th rowspan="2" class="w-10 text-center align-top">Claim From</th>
              </tr>
              <tr>
                <th class="text-center align-top">Reason</th>
                <th class="text-center align-top">Image</th>
                <th class="text-center align-top">Quantity</th>
                <th class="text-center align-tocalculateAmountp">Amount</th>
                <th class="text-center align-top">Quantity</th>
                <th class="text-center align-top">Amount</th>
              </tr>
            </thead>
            <tbody formArrayName="items">
              <ng-container *ngFor="let claimResultItem of paging.currentPageData; index as indexOfItem">
                <ng-container [formGroupName]="indexOfItem + (currentPage - 1) * pageSize">
                  <tr
                    formArrayName="reasons"
                    *ngFor="let reasonItem of claimResultItem?.get('reasons').controls; index as indexOfReason"
                  >
                    <ng-container [formGroupName]="indexOfReason">
                      <td
                        *ngIf="indexOfReason === 0"
                        [rowSpan]="claimResultItem.get('reasons').length"
                        class="text-center bg-white"
                      >
                        {{ claimResultItem.get('itemNo').value }}
                      </td>
                      <td
                        *ngIf="indexOfReason === 0"
                        [rowSpan]="claimResultItem.get('reasons').length"
                        class="bg-white"
                      >
                        {{ claimResultItem.get('articleNo').value }}
                      </td>
                      <td
                        *ngIf="indexOfReason === 0"
                        [rowSpan]="claimResultItem.get('reasons').length"
                        class="bg-white"
                      >
                        {{ claimResultItem.get('productName').value }}
                      </td>
                      <td
                        *ngIf="indexOfReason === 0"
                        [rowSpan]="claimResultItem.get('reasons').length"
                        class="text-center bg-white"
                      >
                        {{ claimResultItem.get('barcode').value }}
                      </td>

                      <td>{{ reasonItem.get('claimReason').value }}</td>

                      <td class="text-center">
                        <div
                          class="images"
                          *ngIf="getImageFirst(reasonItem.get('claimImageList').value); else imageErr"
                        >
                          <img
                            appClaimSecureSrc="{{ getImageFirst(reasonItem.get('claimImageList').value) }}"
                            width="24"
                            height="24"
                            alt="{{ reasonItem.get('claimReason').value }}"
                          />
                          <span
                            class="image-background"
                            (click)="
                              showImage(reasonItem.get('claimImageList').value, reasonItem.get('claimReason').value)
                            "
                          ></span>
                          <span
                            *ngIf="countImage(reasonItem.get('claimImageList').value) >= 2"
                            (click)="
                              showImage(reasonItem.get('claimImageList').value, reasonItem.get('claimReason').value)
                            "
                            class="image-numbers"
                            >+{{ countImage(reasonItem.get('claimImageList').value) }}</span
                          >
                        </div>
                        <ng-template #imageErr>
                          <img width="24" height="24" src="/assets/images/no-image.svg" alt="not found" />
                        </ng-template>
                      </td>

                      <td class="text-center">
                        {{ reasonItem.get('claimQty').value }}
                        <span> {{ reasonItem.get('unit').value }}</span>
                      </td>
                      <td class="text-right">{{ reasonItem.get('claimAmount').value | numberFormatDisplay }}</td>
                      <td class="text-center w-10 p-10px">
                        <app-numeric-textbox
                          formControlName="approvedQty"
                          [format]="'0,0'"
                          [decimals]="0"
                          [maxValue]="99999"
                          maxlength="5"
                          (valueChange)="calculateAmount($event, indexOfItem, indexOfReason)"
                          class="td-numeric-textbox-center"
                          placeholder="Quantity"
                          [ngClass]="{
                            'is-invalid': reasonItem.get('approvedQty').errors
                          }"
                        >
                        </app-numeric-textbox>
                        <div *ngIf="reasonItem.get('approvedQty').errors" class="invalid-display text-left">
                          <div *ngIf="reasonItem.get('approvedQty').errors?.over">
                            Approved not more than claim request.
                          </div>
                        </div>
                      </td>
                      <td class="text-right">{{ reasonItem.get('approvedAmount').value | numberFormatDisplay }}</td>
                      <td class="p-10px">
                        <ng-select
                          id="claimFrom"
                          placeholder="Please select..."
                          [items]="claimFromStatusList"
                          [searchable]="false"
                          [clearable]="false"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="claimFrom"
                          appendTo=".claim-wrapper"
                          [ngClass]="{
                            'is-invalid': submitted && reasonItem.get('claimFrom').errors
                          }"
                        >
                        </ng-select>
                        <div *ngIf="submitted && reasonItem.get('claimFrom').errors" class="invalid-feedback">
                          <div *ngIf="submitted && reasonItem.get('claimFrom').errors.required">
                            {{ 'ERRORS.REQUIRED' | translate }}
                          </div>
                        </div>
                        <div class="text-center mt-2" *ngIf="canApproveReject()">
                          <a
                            href="javascript:void(0)"
                            *ngIf="indexOfItem === 0 && indexOfReason === 0"
                            class="btn-text-link"
                            (click)="applyAllClaimForm()"
                          >
                            Apply All
                          </a>
                        </div>
                      </td>
                    </ng-container>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>

          <app-ui-pagination
            #paging
            [(currentPage)]="currentPage"
            [(pageSize)]="pageSize"
            [items]="form?.get('items').controls"
            [valueChangeDetector]="form?.get('items').valueChanges | async"
          >
          </app-ui-pagination>

          <div class="fixed-row-bottom form-row" *ngIf="canApproveReject() || canReleaseCN() || canCreateClaim()">
            <div class="float-right">
              <button
                *ngIf="canApproveReject()"
                type="button"
                id="btn-cancel"
                class="btn btn-special-reject mr-3"
                (click)="showConfirmReject()"
                appPreventDoubleClick
              >
                Reject
              </button>

              <button
                *ngIf="canApproveReject()"
                type="button"
                id="btn-submit"
                class="btn btn-special-approve"
                (click)="validateSubmit()"
                appPreventDoubleClick
              >
                Approve
              </button>

              <button
                *ngIf="canReleaseCN()"
                type="button"
                id="btn-release-cn"
                class="btn btn-primary"
                (click)="releaseCN()"
                appPreventDoubleClick
              >
                Release CN
              </button>

              <button
                *ngIf="canCreateClaim()"
                type="button"
                id="btn-create-claim-cancel"
                class="btn btn-standard mr-2"
                (click)="onExit()"
              >
                Cancel
              </button>
              <button
                *ngIf="canCreateClaim()"
                type="button"
                id="btn--create-claim-submit"
                class="btn btn-primary"
                (click)="onCreateClaimSubmit()"
                appPreventDoubleClick
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-image-slider #imageSlider></ng-image-slider>
