<div class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-stock"></em>
    <h4>Stock Transfer</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap d-none d-md-block">
        <app-import-export-button [buttons]="buttons" (export)="exportModal.show()"></app-import-export-button>
      </div>

      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              aria-hidden="true"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="stockTransferStatusList"
            [searchable]="false"
            [clearable]="false"
            (change)="onChangeStatus($event)"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
          >
          </ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        Order Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="orderDateFrom"
                          formControlName="orderDateFrom"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          (bsValueChange)="onChangeOrderDateFrom($event)"
                          [bsConfig]="bsDateConfig"
                          [maxDate]="orderMaxDate"
                          readonly
                        />
                        <label for="orderDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="orderDateTo"
                          formControlName="orderDateTo"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          (bsValueChange)="onChangeOrderDateTo($event)"
                          [minDate]="orderMinDate"
                          readonly
                        />
                        <label for="orderDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        Delivery Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="deliveryDateFrom"
                          formControlName="deliveryDateFrom"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          (bsValueChange)="onChangeDeliveryDateFrom($event)"
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDeliveryDate"
                          readonly
                        />
                        <label for="deliveryDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="deliveryDateTo"
                          formControlName="deliveryDateTo"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          (bsValueChange)="onChangeDeliveryDateTo($event)"
                          [minDate]="minDeliveryDate"
                          readonly
                        />
                        <label for="deliveryDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Ship From
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="warehouseList"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="false"
                          bindLabel="warehouseNameDisplay"
                          bindValue="code"
                          formControlName="shipFrom"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Ship To
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="warehouseList"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="false"
                          bindLabel="warehouseNameDisplay"
                          bindValue="code"
                          formControlName="shipTo"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div
    class="top-search-criteria space-search-criteria"
    *ngIf="orderDateTag || deliveryDateTag || shipToStringTag || shipFromStringTag"
  >
    <div class="search-criteria" *ngIf="orderDateTag">
      <a class="remove-criteria" (click)="clearOrderDateFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ orderDateStringTag }}: {{ orderDateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="deliveryDateTag">
      <a class="remove-criteria" (click)="clearDeliveryDateFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ deliveryDateStringTag }}: {{ deliveryDateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="shipFromStringTag">
      <a class="remove-criteria" (click)="clearShipFromFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ shipFromStringTag }}: {{ shipFromTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="shipToStringTag">
      <a class="remove-criteria" (click)="clearShipToFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ shipToStringTag }}: {{ shipToTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>
  <ng-container *ngIf="(listState$ | async)?.totalElements == 0">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="td-card" *ngFor="let result$ of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 cursor-pointer" (click)="goToView(result$)">
          <div class="row">
            <div class="col-12 d-flex">
              <label class="chk-container">
                {{ result$.docNo }}
              </label>
              <span [ngClass]="getColorStatus(result$.status)" class="card-status stock-transfer-status ml-auto">{{
                'STOCK_TRANSFER.STATUS.' + result$.status | translate
              }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Transfer Request No.:</span>
              <span class="result-value">{{ result$.requestNo | dashDisplay }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Order By:</span>
              <span class="result-value">{{ result$.orderByName | dashDisplay }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Order Date:</span>
              <span class="result-value">{{ result$.lastModifiedDate | amFromUtc | amLocal | dateDisplay }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Delivery Date:</span>
              <span class="result-value">{{ result$.deliveryDate | amFromUtc | amLocal | dateDisplay }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Ship From:</span>
              <span class="result-value">{{ result$.shipFromCodeName | dashDisplay }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Ship To:</span>
              <span class="result-value">{{ result$.shipToCodeName | dashDisplay }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom"
            containerClass="fl"
            [outsideClick]="true"
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <button
              type="button"
              class="btn btn-link d-none d-md-block"
              (click)="onCloseTo(result$)"
              *ngIf="hasClosePermission(result$.status)"
            >
              <em class="icon-close-po"></em>Close
            </button>
            <button type="button" class="btn btn-link" (click)="showHistory(result$)">
              <em class="icon-history"></em>History
            </button>
          </ng-template>
        </div>
      </div>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>

<div class="modal fade" bsModal #exportModal="bs-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Export Transfer Order</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalExportStockTransfer()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body admin-form" [formGroup]="exportForm" (ngSubmit)="onExport()">
        <div class="form-row">
          <div class="col-12">Order Date<span class="text-danger">*</span></div>
          <div class="form-group col-md-6">
            <label>{{ 'FROM' | translate }}</label>
            <input
              id="exportOrderDateFrom"
              formControlName="orderDateFrom"
              type="text"
              placeholder="{{ dateFormat }}"
              autocomplete="off"
              class="form-control calendar"
              bsDatepicker
              [bsConfig]="bsDateConfig"
              [maxDate]="orderMaxDate"
              (bsValueChange)="onChangeOrderDateFrom($event)"
              readonly
            />
            <label for="exportOrderDateFrom" class="icon-calendar btn-calendar"></label>
          </div>
          <div class="form-group col-md-6">
            <label>{{ 'TO' | translate }}</label>
            <input
              id="exportOrderDateTo"
              formControlName="orderDateTo"
              type="text"
              placeholder="{{ dateFormat }}"
              autocomplete="off"
              class="form-control calendar"
              bsDatepicker
              [minDate]="orderMinDate"
              [bsConfig]="{
                containerClass: 'theme-dark-blue',
                dateInputFormat: dateFormat,
                showWeekNumbers: false
              }"
              (bsValueChange)="onChangeOrderDateTo($event)"
              readonly
            />
            <label for="exportOrderDateTo" class="icon-calendar btn-calendar"></label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-12">Delivery Date<span class="text-danger">*</span></div>
          <div class="form-group col-md-6">
            <label>{{ 'FROM' | translate }}</label>
            <input
              id="exportDeliveryDateFrom"
              formControlName="deliveryDateFrom"
              type="text"
              placeholder="{{ dateFormat }}"
              autocomplete="off"
              class="form-control calendar"
              bsDatepicker
              [bsConfig]="bsDateConfig"
              [maxDate]="maxDeliveryDate"
              (bsValueChange)="onChangeDeliveryDateFrom($event)"
              readonly
            />
            <label for="exportDeliveryDateFrom" class="icon-calendar btn-calendar"></label>
          </div>
          <div class="form-group col-md-6">
            <label>{{ 'TO' | translate }}</label>
            <input
              id="exportDeliveryDateTo"
              formControlName="deliveryDateTo"
              type="text"
              placeholder="{{ dateFormat }}"
              autocomplete="off"
              class="form-control calendar"
              bsDatepicker
              [minDate]="minDeliveryDate"
              [bsConfig]="{
                containerClass: 'theme-dark-blue',
                dateInputFormat: dateFormat,
                showWeekNumbers: false
              }"
              (bsValueChange)="onChangeDeliveryDateTo($event)"
              readonly
            />
            <label for="exportDeliveryDateTo" class="icon-calendar btn-calendar"></label>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-6">
            <div class="mb-2">
              Ship From
            </div>
            <ng-select
              placeholder="Please select..."
              [items]="warehouseList"
              [searchable]="true"
              [clearable]="true"
              [multiple]="false"
              bindLabel="warehouseNameDisplay"
              bindValue="code"
              formControlName="shipFrom"
            >
            </ng-select>
          </div>
          <div class="form-group col-6">
            <div class="mb-2">
              Ship To
            </div>
            <ng-select
              placeholder="Please select..."
              [items]="warehouseList"
              [searchable]="true"
              [clearable]="true"
              [multiple]="false"
              bindLabel="warehouseNameDisplay"
              bindValue="code"
              formControlName="shipTo"
            >
            </ng-select>
          </div>
        </div>
        <div class="form-group">
          <div class="mb-2">
            Status
          </div>
          <ng-select
            [items]="shipmentStatusExportFilter"
            [searchable]="false"
            [clearable]="false"
            [multiple]="true"
            class="select-status w-100"
            bindLabel="label"
            bindValue="value"
            formControlName="transferStatus"
            placeholder="Please select..."
          ></ng-select>
        </div>
        <div *ngIf="errorExport" class="alert-danger form-group mb-0 " role="alert">
          {{ errorExport }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          id="btnExport"
          (click)="onExport()"
          [disabled]="exportFormInValid"
        >
          Export
        </button>
      </div>
    </div>
  </div>
</div>
