import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { LocationLevelTypeEnum } from '../../../shared/enum/location-level-type.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { AssortmentPageModes, AssortmentPageTabsEnum, AssortmentRequestTypeEnum } from '../../../shared/models';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import { SupplierPriceContent, SupplierPriceSearchCriteria } from '../../../shared/models/supplier-price.model';
import { ManualSupplierPriceDataService } from '../../../shared/services/manual-supplier-price-data.service';
import { SupplierPriceConfigService } from '../../../shared/services/supplier-price-config.service';
import {
  SupplierPriceListRequest,
  SupplierPriceReset,
  SupplierPriceShowSuccess
} from '../../../shared/store/actions/supplier-price.actions';
import { SupplierPriceState } from '../../../shared/store/reducers/supplier-price.reducers';
import {
  selectSupplierPrice,
  selectSupplierPriceIsUpdated,
  selectSupplierPriceList,
  selectSupplierPriceShowSuccess
} from '../../../shared/store/selectors/supplier-price.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { AssortmentSupplierPriceModalUploadComponent } from '../assortment-supplier-price-modal-upload/assortment-supplier-price-modal-upload.component';
import { ManualAddEditSupplierPriceComponent } from './manual-add-edit-supplier-price/manual-add-edit-supplier-price.component';

@Component({
  selector: 'app-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrls: ['./supplier-details.component.scss']
})
export class SupplierDetailsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() supplierDetailsForm: FormGroup;
  @Input() productDetailsForm: FormGroup;
  @Input() isSubmitted: boolean;
  @Input() isDisplayPriceUpdatedTag: boolean;
  @Input() listOfValue: {};
  @Input() listOfChange: {};
  @Input() mode: AssortmentPageModes;
  @Input() isRequestViewMode: boolean;
  @Input() isExistingUpload: number;
  @Input() errorSupplierPriceDisplay: {
    [key: number]: {
      orderBarcode: boolean;
      unitFactor: number;
      tdPickingUnit: string;
      supplierUnit: string;
      orderBarcodeInactive: boolean;
    };
  } = {};
  @Input() useCJProduct: boolean;
  @Input() type: AssortmentRequestTypeEnum;

  @Output() currentTab: EventEmitter<AssortmentPageTabsEnum> = new EventEmitter<AssortmentPageTabsEnum>();
  @Output() priceUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isPriceUpdated: boolean;
  public isSubmit = false;
  public importId: string;
  public articleNo: string;
  public message: {};
  public assortmentPageMode = AssortmentPageModes;
  public assortmentRequestType = AssortmentRequestTypeEnum;
  public assortmentPageTabs = AssortmentPageTabsEnum;
  public supplierPriceOtherUploadModal: BsModalRef;
  public criteriaObject: SupplierPriceSearchCriteria;
  public locationLevelType = LocationLevelTypeEnum;
  public currentPage: number;
  public pageSize: number;
  public resultList: SupplierPriceContent[];
  public listState$: Observable<SupplierPriceState>;
  public localStore: Observable<any>;
  public listStateTotalElement: number;
  public isUpdatedSupplierPrice = false;

  public buttons: Array<ImportExportButton>;
  public manualSupplierPriceDatas: Array<SupplierPriceContent> = [];
  public isManualSupplierPrice: boolean;

  readonly maxManualSupplierPrice = 1000;

  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected supplierPriceConfig: SupplierPriceConfigService,
    private cdr: ChangeDetectorRef,
    protected manualSupplierPriceDataService: ManualSupplierPriceDataService
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(
        select(selectSupplierPriceShowSuccess),
        filter(res => Boolean(res))
      )
      .subscribe(showSuccess => {
        if (showSuccess) {
          this.showAlertModal('Success', 'The data have been imported.');
          this.criteriaObject = {
            ...this.criteriaObject,
            showSuccess: false
          };

          this.store.dispatch(new SupplierPriceShowSuccess(false));
        }
      });

    this.isManualSupplierPrice = this.manualSupplierPriceDataService.getManualSupplierPrice();
    this.manualSupplierPriceDatas = this.manualSupplierPriceDataService.getSupplierPricesData();
    this.setFirstPage();
    this.initialSupplierPriceOther();
    this.setInitialData();
    this.setInitialCriteriaObject();
    this.search();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.manualSupplierPriceDataService.setManualSupplierPrice(this.isManualSupplierPrice);
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mode) {
      this.getButton();
    }
  }

  getButton() {
    this.buttons = [
      {
        type: ButtonType.EXPORT,
        name: 'Export'
      },
      {
        type: ButtonType.IMPORT,
        name: 'Import',
        hidden: [this.assortmentPageMode.REQUEST_VIEW, this.assortmentPageMode.ASSORTMENT_VIEW].includes(this.mode)
      }
    ];
  }

  initialSupplierPriceOther() {
    this.localStore.pipe(select(selectSupplierPriceList)).subscribe(list => {
      if (list && list.length > 0) {
        this.resultList = list;
      }
    });
    this.localStore.pipe(select(selectSupplierPriceIsUpdated)).subscribe(isPriceUpdate => {
      this.isPriceUpdated = isPriceUpdate;
      if (!!isPriceUpdate) {
        this.isUpdatedSupplierPrice = true;
        this.priceUpdate.emit(true);
      }
    });
    this.listState$ = this.localStore.pipe(
      select(selectSupplierPrice),
      switchMap(supplierPrices => {
        this.listStateTotalElement = supplierPrices.totalElements;
        if (supplierPrices.totalElements === 0) {
          this.manualSupplierPriceDatas = this.manualSupplierPriceDataService.getSupplierPricesData();
          return of(null);
        } else if (
          supplierPrices.totalElements > 0 &&
          supplierPrices.totalElements <= this.maxManualSupplierPrice &&
          !this.manualSupplierPriceDataService.getManualSupplierPrice()
        ) {
          this.isManualSupplierPrice = true;
          this.manualSupplierPriceDatas = this.manualSupplierPriceDataService.setData(
            Object.values(supplierPrices.entities)
          );
          this.cdr.detectChanges();
          return of(null);
        } else if (supplierPrices.ids.length > 0 && supplierPrices.totalElements > this.maxManualSupplierPrice) {
          this.isManualSupplierPrice = false;
          return of(supplierPrices);
        } else {
          return of(null);
        }
      })
    );
  }

  setInitialCriteriaObject() {
    this.pageSize = 20;
    this.criteriaObject = {
      ...({} as any),
      importId: this.importId,
      articleNo: this.articleNo,
      deliveryMethod: this.productDetailsForm.controls.deliveryMethod.value,
      page: 0,
      size: 20,
      showSuccess: false
    };
  }

  setInitialData() {
    this.importId =
      this.productDetailsForm &&
      this.productDetailsForm.controls.importId &&
      this.productDetailsForm.controls.importId.value
        ? this.productDetailsForm.controls.importId.value
        : null;
    this.articleNo =
      this.productDetailsForm &&
      this.productDetailsForm.controls.articleNo &&
      this.productDetailsForm.controls.articleNo.value
        ? this.productDetailsForm.controls.articleNo.value
        : null;
  }

  onChangePage(event: any) {
    this.currentPage = event.page;
    this.criteriaObject = {
      ...this.criteriaObject,
      page: event.page - 1,
      showSuccess: false
    };
    this.search();
  }

  onChangeRowPerPage(value: string) {
    this.setFirstPage();
    this.pageSize = Number(value);
    this.criteriaObject = {
      ...this.criteriaObject,
      size: Number(value),
      page: 0,
      showSuccess: false
    };
    this.search();
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  search() {
    if (
      !this.manualSupplierPriceDataService.getManualSupplierPrice() &&
      ((this.importId && !this.isPriceUpdated) || (this.articleNo && !this.isPriceUpdated))
    ) {
      this.store.dispatch(new SupplierPriceListRequest(this.criteriaObject));
    }
  }

  doAfterVersionAlertModal() {}

  checkExistingItem() {
    if (this.resultList && this.resultList.length > 0) {
      this.alertConfirmUploadModal();
    } else {
      this.showSupplierOtherUploadModal();
    }
  }

  get isExistingDeliveryMethod() {
    if (this.productDetailsForm.controls.deliveryMethod && this.productDetailsForm.controls.deliveryMethod.value) {
      return true;
    }
    this.showAlertModal('Failed', 'Please select Delivery Method.');
    this.currentTab.emit(this.assortmentPageTabs.PRODUCT_TAB);
    return false;
  }

  alertConfirmUploadModal() {
    const initialState = {
      title: 'Confirm',
      message: 'Are you sure you want to import new file? All existing data will be lost.',
      okText: 'OK'
    };
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.showSupplierOtherUploadModal();
        }
      });
  }

  showAlertModal(title, message) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title,
        message
      }
    });
  }

  showSupplierOtherUploadModal() {
    if (!this.isExistingDeliveryMethod) {
      return;
    }
    this.supplierPriceOtherUploadModal = this.modalService.show(AssortmentSupplierPriceModalUploadComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        productName: this.generateFileName(),
        deliveryMethod: this.productDetailsForm.controls.deliveryMethod.value
      }
    });

    this.supplierPriceOtherUploadModal.content.submitUpload.pipe(untilComponentDestroyed(this)).subscribe(result => {
      this.setFirstPage();
      this.isUpdatedSupplierPrice = true;
      this.priceUpdate.emit(true);
      this.manualSupplierPriceDataService.resetData();
      this.store.dispatch(new SupplierPriceReset());
      if (result.file.importId) {
        this.importId = result.file.importId;
        this.criteriaObject = {
          ...this.criteriaObject,
          importId: this.importId,
          page: 0,
          showSuccess: true
        };

        this.isPriceUpdated = false;
        this.productDetailsForm.patchValue({
          importId: result.file.importId
        });
        this.search();
      } else if (result.file.manualSupplierPriceData) {
        delete result.file.manualSupplierPriceData.status;
        this.store.dispatch(new SupplierPriceReset());
        this.manualSupplierPriceDataService.setManualSupplierPrice(true);
        this.isManualSupplierPrice = true;
        this.manualSupplierPriceDatas = this.manualSupplierPriceDataService.setData(
          Object.values(result.file.manualSupplierPriceData) as SupplierPriceContent[]
        );
        this.store.dispatch(new SupplierPriceShowSuccess(true));
        this.cdr.detectChanges();
      }
    });
  }

  onExportSupplierPrice() {
    if (!this.isExistingDeliveryMethod) {
      return;
    }

    const criteria = {
      importId: this.importId,
      articleNo:
        this.importId || [AssortmentPageModes.REQUEST_CREATE].includes(this.mode)
          ? null
          : this.productDetailsForm.controls.articleNo.value,
      deliveryMethod: this.productDetailsForm.controls.deliveryMethod.value
    };

    this.supplierPriceConfig.onExport(criteria).subscribe(response => {
      const blob = new Blob([response]);
      saveAs(blob, `${this.generateFileName()} ${this.timeToExport}.xlsx`);
    });
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportSupplierPriceConfigs.timeFormat);
  }

  generateFileName() {
    const productName = this.productDetailsForm.controls.productName.value;
    const fileConfig = environment.fileName.exportSupplierPriceConfigs.prefix;

    return productName ? `${productName} ${fileConfig}` : `${fileConfig}`;
  }

  validateTotalElement(index: number = null) {
    if (
      this.listStateTotalElement >= this.maxManualSupplierPrice ||
      this.manualSupplierPriceDatas.length >= this.maxManualSupplierPrice
    ) {
      this.showAlertModal(
        'Failed',
        'There are more than 1,000 items, please export and import supplier price instead.'
      );
      return;
    }
    this.showSupplierPriceModal(index);
  }

  showSupplierPriceModal(index: number = null) {
    const addSupplierPriceModal = this.modalService.show(ManualAddEditSupplierPriceComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'supplier-details',
      initialState: {
        isSubmit: this.isSubmit,
        supplierDetailsForm: this.supplierDetailsForm,
        supplierPrice: index !== null ? this.manualSupplierPriceDatas[index] : null,
        manualSupplierPriceDatas: this.manualSupplierPriceDatas,
        isRequestViewMode: this.isRequestViewMode,
        listOfValue: this.listOfValue,
        productDetailsForm: this.productDetailsForm,
        editingSupplierPriceIndex: index,
        mode: this.mode,
        useCJProduct: this.useCJProduct,
        errorSupplierPriceDisplay: this.errorSupplierPriceDisplay,
        type: this.type
      }
    });
    addSupplierPriceModal.content.onSaveSupplierPrice.subscribe(savedSupplierPrices => {
      this.manualSupplierPriceDatas = [...this.manualSupplierPriceDataService.setData(savedSupplierPrices)];
      this.isUpdatedSupplierPrice = true;
      this.priceUpdate.emit(true);
      this.manualSupplierPriceDataService.setManualSupplierPrice(true);
      this.isManualSupplierPrice = true;
      this.cdr.detectChanges();
    });
  }

  deleteSupplierPrice(index: number) {
    const initialState = {
      title: 'Confirm',
      okText: 'Yes, delete',
      cancelText: 'Cancel',
      message: 'Are you sure you want to delete?'
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.manualSupplierPriceDatas = this.manualSupplierPriceDataService.deleteData(index);
          this.cdr.detectChanges();
          this.isUpdatedSupplierPrice = true;
          this.priceUpdate.emit(true);
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }
}
