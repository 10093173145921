import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-product-change-modal',
  templateUrl: './product-change-modal.component.html',
  styleUrls: ['./product-change-modal.component.scss']
})
export class ProductChangeModalComponent implements OnInit {
  public isChange: boolean;
  public changeMessage: string;
  public changeSubject: string;
  public isInvalidData: boolean;
  public invalidMessage: string;
  public invalidSubject: string;
  public isInvalidDate: boolean;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {}

  decline(): void {
    this.bsModalRef.hide();
  }
}
