import { Action } from '@ngrx/store';
import { ShelfSelectedItems } from '../../models/first-lot-order.model';
import { ShelfFixAssetListSearchCriteria } from '../../models/shelf-fix-asset.model';

export enum ShelfSelectFixAssetActionTypes {
  SHELF_SELECT_FIX_ASSET_LIST_REQUEST = '[Shelf Select Fix Asset] Shelf Select Fix Asset List Request',
  SHELF_SELECT_FIX_ASSET_LIST_RESPONSE = '[Shelf Select Fix Asset] Shelf Select Fix Asset List Response',
  SHELF_SELECT_FIX_ASSET_LIST_RESET = '[Shelf Select Fix Asset] Shelf Select Fix Asset List Reset',
  SHELF_SELECT_FIX_ASSET_GENERATE_SHELF_ITEM_REQUEST = '[Shelf Select Fix Asset] Generate Shelf items Request'
}

export class ShelfSelectFixAssetListRequest implements Action {
  readonly type = ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_LIST_REQUEST;

  constructor(public payload: ShelfFixAssetListSearchCriteria) {}
}

export class ShelfSelectFixAssetListResponse implements Action {
  readonly type = ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_LIST_RESPONSE;

  constructor(public payload: any) {}
}

export class ShelfSelectFixAssetListReset implements Action {
  readonly type = ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_LIST_RESET;

  constructor() {}
}

export class ShelfSelectFixAssetGenerateShelfItemsRequestAction implements Action {
  readonly type = ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_GENERATE_SHELF_ITEM_REQUEST;

  constructor(public payload: ShelfSelectedItems[]) {}
}

export type ShelfSelectFixAssetActions =
  | ShelfSelectFixAssetListRequest
  | ShelfSelectFixAssetListResponse
  | ShelfSelectFixAssetListReset
  | ShelfSelectFixAssetGenerateShelfItemsRequestAction;
