/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./voucher-file-upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./voucher-file-upload.component";
import * as i5 from "ngx-bootstrap/modal";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../shared/services/voucher-request.service";
import * as i8 from "ngx-logger";
var styles_VoucherFileUploadComponent = [i0.styles];
var RenderType_VoucherFileUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VoucherFileUploadComponent, data: {} });
export { RenderType_VoucherFileUploadComponent as RenderType_VoucherFileUploadComponent };
function View_VoucherFileUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "em", [["class", "icon-close-mini"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_VoucherFileUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "position-relative image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "input", [["class", "form-control cursor-default"], ["readonly", ""]], [[8, "value", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { enabled: 0, disabled: 1, "bg-h-change": 2 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VoucherFileUploadComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "form-control cursor-default"; var currVal_2 = _ck(_v, 4, 0, !_co.disabled, _co.disabled, _co.hasChange); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = (_v.context.$implicit.name && !_co.disabled); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_VoucherFileUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VoucherFileUploadComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_VoucherFileUploadComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "btn upload-btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 4).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "is-invalid": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 0, "em", [["class", "icon-upload"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn upload-btn"; var currVal_2 = _ck(_v, 4, 0, _co.hasError); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 1, 0, currVal_0); }); }
function View_VoucherFileUploadComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "upload-container text-center form-control"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { disabled: 0, "is-invalid": 1 }), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["uploadInput", 1]], null, 0, "input", [["class", "d-none"], ["id", "uploadFile"], ["type", "file"]], [[8, "disabled", 0], [8, "multiple", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "input-group position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "input", [["class", "form-control upload-input cursor-default"], ["placeholder", "No File Chosen"], ["readonly", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "is-invalid": 0, "bg-h-change": 1, enabled: 2, disabled: 3 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VoucherFileUploadComponent_5)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "upload-container text-center form-control"; var currVal_1 = _ck(_v, 3, 0, _co.disabled, _co.hasError); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_5 = "form-control upload-input cursor-default"; var currVal_6 = _ck(_v, 9, 0, _co.hasError, _co.hasChange, !_co.disabled, _co.disabled); _ck(_v, 8, 0, currVal_5, currVal_6); var currVal_7 = !_co.disabled; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.disabled; var currVal_3 = (_co.multiple || false); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = _co.disabled; _ck(_v, 6, 0, currVal_4); }); }
export function View_VoucherFileUploadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { uploadInput: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VoucherFileUploadComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noFiles", 2]], null, 0, null, View_VoucherFileUploadComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.fileList && (_co.fileList.length > 0)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_VoucherFileUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-voucher-file-upload", [], [[2, "is-invalid", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onChange($event.target.files) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_VoucherFileUploadComponent_0, RenderType_VoucherFileUploadComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.VoucherFileUploadComponent]), i1.ɵprd(5120, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.VoucherFileUploadComponent]), i1.ɵdid(3, 770048, null, 0, i4.VoucherFileUploadComponent, [[3, i3.ControlContainer], i5.BsModalService, i6.TranslateService, i7.VoucherRequestService, i8.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).invalid; _ck(_v, 0, 0, currVal_0); }); }
var VoucherFileUploadComponentNgFactory = i1.ɵccf("app-voucher-file-upload", i4.VoucherFileUploadComponent, View_VoucherFileUploadComponent_Host_0, { allowedExt: "allowedExt", disabled: "disabled", multiple: "multiple", allowedTypes: "allowedTypes", size: "size", hasError: "hasError", hasChange: "hasChange", withMeta: "withMeta", maxHeight: "maxHeight", maxWidth: "maxWidth", controlName: "controlName", initialFileList: "initialFileList", isNew: "isNew", fileTypeErrorTxt: "fileTypeErrorTxt", fileSizeErrorTxt: "fileSizeErrorTxt", descriptionTxt: "descriptionTxt", isAddable: "isAddable", index: "index", maxImages: "maxImages", lastIndex: "lastIndex", fileModule: "fileModule", runDate: "runDate" }, { addImage: "addImage" }, []);
export { VoucherFileUploadComponentNgFactory as VoucherFileUploadComponentNgFactory };
