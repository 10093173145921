<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="page-title-icon icon-td-user"></em>
    {{ data.title }}
  </div>
  <ng-container *ngIf="this.isViewMode && (storeConsultantView$ | async)?.userId">
    <button type="button" class="btn btn-header-button d-none d-md-block" (click)="showHistory()">
      <em class="icon-history"></em>History
    </button>
  </ng-container>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>
<div>
  <div class="admin-form" [formGroup]="form">
    <div class="info-header-container">
      <div class="info-header-item" *ngIf="(storeConsultantView$ | async)?.userId">
        <div class="info-header-label">
          <span>User ID:</span>
        </div>
        <div class="info-header-value">
          <span>{{ (storeConsultantView$ | async)?.userId }}</span>
        </div>
      </div>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Name:</span>
        </div>
        <div class="info-header-value">
          <span>{{ (storeConsultantView$ | async)?.fullName }}</span>
        </div>
      </div>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>User Status:</span>
        </div>
        <div class="info-header-value">
          <span [ngClass]="status | lowercase" class="status"> {{ status }}</span>
        </div>
      </div>
    </div>
    <div class="row section-header">
      <span>Area of responsibility</span>
    </div>
    <div class="row" *ngIf="formStoreList.controls?.length">
      <div class="col-md-12 mb-3">
        <table class="table table-striped table-bordered w-100" *ngIf="formStoreList.controls.length > 0">
          <thead>
            <tr>
              <th class="text-center w-15">Region</th>
              <th class="text-center w-15">Province</th>
              <th class="text-center w-10">Store Code</th>
              <th class="text-center w-35">Store Name</th>
              <th class="text-center w-10">Status</th>
              <th class="text-center w-5" *ngIf="!this.isViewMode">Action</th>
            </tr>
          </thead>
          <tbody formArrayName="stores">
            <ng-container *ngFor="let item of paging.currentPageData; index as indexOfItem">
              <tr>
                <td>{{ item.get('regionName').value }}</td>
                <td>{{ item.get('stateName').value }}</td>
                <td>
                  {{ item.get('code').value }}
                </td>
                <td>
                  {{ item.get('name').value }}
                </td>
                <td>{{ item.get('status').value | titlecase }}</td>
                <td class="text-center align-middle" *ngIf="!this.isViewMode">
                  <a
                    id="delete-store-{{ indexOfItem }}"
                    class="is-link text-muted"
                    (click)="deleteStoreItem(indexOfItem)"
                    title="Delete"
                    appTippy
                    [tippyOptions]="{ arrow: true }"
                  >
                    <em class="icon-delete"></em>
                  </a>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div>
          <app-ui-pagination
            #paging
            [(currentPage)]="currentPage"
            [(pageSize)]="pageSize"
            [items]="form?.get('stores').controls"
            [valueChangeDetector]="form?.get('stores').valueChanges | async"
          >
          </app-ui-pagination>

          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!this.isViewMode">
      <div class="col-md-12 mb-3">
        <a class="btn-text-link d-inline-block mr-1 " (click)="onAddStore()">+Add Store</a>
      </div>
    </div>
    <!--footer-->
    <div class="fixed-row-bottom form-row">
      <div class="float-left"></div>
      <div class="float-right">
        <button
          type="button"
          id="btn-cancel"
          class="btn btn-standard mr-2"
          *ngIf="!this.isViewMode"
          (click)="onExit()"
        >
          Cancel
        </button>
        <button
          type="button"
          id="btn-submit"
          class="btn btn-primary"
          appPreventDoubleClick
          *ngIf="!this.isViewMode"
          (click)="onSubmit()"
        >
          Submit
        </button>
        <button
          type="button"
          id="btn-request-edit"
          class="btn btn-primary"
          *ngIf="this.isViewMode && this.status.toLocaleLowerCase() === 'active' && this.hasPermissionManage"
          (click)="toggleToEditMode()"
        >
          Edit
        </button>
      </div>
    </div>
    <!--End footer-->
  </div>

  <!-- Start Dialog show Store-->
  <div
    class="modal fade"
    bsModal
    #modalSelectStore="bs-modal"
    [config]="{ backdrop: 'static' }"
    [formGroup]="dialogForm"
    name="modalStore"
    id="modalStore"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Select Store</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="onExitStore()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body admin-form">
          <div class="container-fluid">
            <div class="form-group">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <label>Search by: Store Name, Store Code, Store ID<span class="text-danger">*</span></label>
                  <ng-select
                    id="storeName"
                    [items]="storeList | async"
                    placeholder="Please select..."
                    notFoundText="No Data"
                    [virtualScroll]="true"
                    formControlName="storeName"
                    bindLabel="storeCodeName"
                    dropdownPosition="auto"
                    [loading]="storeSearchLoading"
                    [typeahead]="storeSearchInput$"
                    [ngClass]="{
                      'is-invalid': selectStoreAdd && dialogForm.controls.storeName.errors
                    }"
                  >
                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                      <div>
                        <strong>Store Name:</strong>
                        <span [ngOptionHighlight]="search" class="ml-1">
                          {{ item.name }}
                        </span>
                      </div>
                      <small>
                        <strong>Store Code:</strong
                        ><span [ngOptionHighlight]="search" class="ml-1">{{ item.code }}</span
                        >{{ ' | ' }} <strong>Store ID:</strong
                        ><span [ngOptionHighlight]="search" class="ml-1">{{ item.no }}</span>
                      </small>
                    </ng-template>
                  </ng-select>
                  <div *ngIf="selectStoreAdd && dialogForm.controls.storeName.errors" class="invalid-feedback">
                    <div *ngIf="dialogForm.controls.storeName.errors.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            id="btn-select-store-cancel"
            name="btn-select-store-cancel"
            class="btn btn-standard mr-2"
            (click)="onExitStore()"
          >
            Cancel
          </button>
          <button
            type="button"
            id="btn-select-store-ok"
            name="btn-select-store-ok"
            class="btn btn-primary"
            appPreventDoubleClick
            (click)="onStoreAdd()"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Dialog-->
</div>
