import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import {
  StockTransferCancelCloseRequestData,
  StockTransferExportCriteria,
  StockTransferListResponse,
  StockTransferListSearchCriteria,
  StockTransferViewResponse
} from '../models/stock-transfer.model';
import { BaseService, CustomEncoder } from './base.service';

@Injectable()
export class StockTransferService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, protected readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.stockTransfer;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: StockTransferListSearchCriteria): Observable<StockTransferListResponse> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.list);

    return this.http.get<StockTransferListResponse>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getStockTransferByDocNo(docNo: string): Observable<StockTransferViewResponse> {
    const encoder = new CustomEncoder();
    const url = this.getFullUrl(this.envService.get, { docNo: encoder.encodeKey(docNo) });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public closeStockTransfer(_data: StockTransferCancelCloseRequestData): Observable<any> {
    const url = this.getFullUrl(this.envService.close);
    return this.http.post<any>(url, _data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public exportStockTransfer(criteria: StockTransferExportCriteria): Observable<any> {
    const params = this.getParams(criteria, true);
    const url = this.getFullUrl(this.envService.export);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
