import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { SupplierService } from '../../services/supplier.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import { SupplierActionTypes, SupplierBySupplierCodeResponseAction, SupplierListResponseAction, SupplierSubmitErrorAction, SupplierValidateActionResponse } from '../actions/supplier.actions';
export class SupplierEffects {
    constructor(actions$, supplierService, logger) {
        this.actions$ = actions$;
        this.supplierService = supplierService;
        this.logger = logger;
        this.searchSupplier$ = this.actions$.pipe(ofType(SupplierActionTypes.SUPPLIER_LIST_REQUEST), tap(action => this.logger.debug(`@Effect Supplier List Requested:` + JSON.stringify(action.payload))), switchMap(action => {
            return this.supplierService.searchByCriteria(action.payload).pipe(map(response => new SupplierListResponseAction(response)), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getSupplierBySupplierCode$ = this.actions$.pipe(ofType(SupplierActionTypes.SUPPLIER_GET_BY_CODE_REQUEST), tap(action => this.logger.debug('@Effect Supplier By Supplier Code Load: ' + JSON.stringify(action.payload))), mergeMap(action => this.supplierService.getByCode(action.payload.supplierCode).pipe(map(supplierView => {
            return new SupplierBySupplierCodeResponseAction({ supplierView });
        }), catchError(err => of(new LayoutActionLoadError(err))))));
        this.editSupplier$ = this.actions$.pipe(ofType(SupplierActionTypes.SUPPLIER_SUBMIT_EDIT_REQUEST), tap(action => this.logger.debug('@Effect Supplier Edit: ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.supplierService.edit(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'Supplier has been updated.'
            })), catchError(error => {
                return error && error.error && error.error.code === '08024'
                    ? of(new SupplierSubmitErrorAction(error.error))
                    : of(new LayoutActionLoadError(error));
            }));
        }));
        this.validateSupplier$ = this.actions$.pipe(ofType(SupplierActionTypes.SUPPLIER_VALIDATE_REQUEST), tap(action => this.logger.debug("'@Effect Supplier Validate Request: " + JSON.stringify(action.payload))), switchMap(action => {
            return this.supplierService.validateSupplier(action.payload).pipe(map(res => {
                if (!res || res.errorMessage === null) {
                    return new SupplierValidateActionResponse({ isSuccess: true, error: null });
                }
                return new SupplierValidateActionResponse({ isSuccess: false, error: res.errorMessage });
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.activateSupplier$ = this.actions$.pipe(ofType(SupplierActionTypes.SUPPLIER_ACTIVATE_BY_ID_REQUEST), tap(action => this.logger.debug('@Effect Supplier activate Load: ' + JSON.stringify(action.payload))), mergeMap(action => this.supplierService.activate(action.payload).pipe(map(res => {
            const msg = res.status.toLocaleLowerCase() === 'active'
                ? 'The supplier has been activated.'
                : 'The supplier has been deactivated.';
            return new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: msg
            });
        }), catchError(err => of(new LayoutActionLoadError(err))))));
        this.getSupplierActiveBySupplierCode$ = this.actions$.pipe(ofType(SupplierActionTypes.SUPPLIER_GET_BY_CODE_FROM_PURCHASE_REQUEST), tap(action => this.logger.debug('@Effect Supplier By Supplier Code From Purchase Load: ' + JSON.stringify(action.payload))), mergeMap(action => this.supplierService.getBySupplierCodeFromPurchase(action.payload).pipe(map(supplierView => {
            return new SupplierBySupplierCodeResponseAction({ supplierView });
        }), catchError(err => of(new LayoutActionLoadError(err))))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierEffects.prototype, "searchSupplier$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierEffects.prototype, "getSupplierBySupplierCode$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierEffects.prototype, "editSupplier$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierEffects.prototype, "validateSupplier$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierEffects.prototype, "activateSupplier$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierEffects.prototype, "getSupplierActiveBySupplierCode$", void 0);
