import { createSelector } from '@ngrx/store';
import * as fromShelfRequestResponseState from '../reducers/shelf-fix-asset-request.reducers';
import { AppStates } from '../state/app.states';

const selectShelfFixAssetRequestState = (state: AppStates) => state.shelfFixAssetRequest;

export const selectAllShelfRequestList = createSelector(
  selectShelfFixAssetRequestState,
  fromShelfRequestResponseState.selectAll
);

export const selectShelfRequestList = createSelector(
  selectShelfFixAssetRequestState,
  (state: fromShelfRequestResponseState.ShelfFixAssetRequestState) => state
);

export const selectShelfRequestListCriteria = createSelector(selectShelfFixAssetRequestState, state => {
  return state.criteriaObject;
});

export const selectShelfFixAssetRequestById = createSelector(
  selectShelfFixAssetRequestState,
  (state: fromShelfRequestResponseState.ShelfFixAssetRequestState) => state.selected
);

export const selectShelfRequestHistory = createSelector(
  selectShelfFixAssetRequestState,
  (state: fromShelfRequestResponseState.ShelfFixAssetRequestState) => state.auditLogs
);

export const selectShelfDeleteStatus = createSelector(
  selectShelfFixAssetRequestState,
  (state: fromShelfRequestResponseState.ShelfFixAssetRequestState) => {
    return state.isDeleteSuccess;
  }
);
