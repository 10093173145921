import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FileResponse } from '../models/fileResponse';
import { Progress } from '../models/progress.model';
import {
  ApproveRejectStockTransferRequest,
  StockTransfer,
  StockTransferRequestListResponse,
  StockTransferRequestListSearchCriteria
} from '../models/stock-transfer-request.model';
import { BaseService } from './base.service';

type UploadResultType = Progress | FileResponse | null | any;

@Injectable()
export class StockTransferRequestService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, protected readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.stockTransferRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(
    criteria: StockTransferRequestListSearchCriteria
  ): Observable<StockTransferRequestListResponse> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.list);
    return this.http.get<StockTransferRequestListResponse>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public save(stockTransfer: StockTransfer): Observable<StockTransfer> {
    if (stockTransfer.id) {
      return this.http.put<StockTransfer>(this.getFullUrl(this.envService.update), stockTransfer, {
        headers: this.loaderHeaders(),
        observe: 'body'
      });
    } else {
      return this.http.post<StockTransfer>(this.getFullUrl(this.envService.create), stockTransfer, {
        headers: this.loaderHeaders(),
        observe: 'body'
      });
    }
  }

  public onExportTemplate() {
    const url = this.getFullUrl(this.envService.exportTemplate);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public getStockTransferRequestById(id: string): Observable<StockTransfer> {
    const url = this.getFullUrl(this.envService.get, { id });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public deleteStockTransferRequest(id: string): Observable<any> {
    const url = this.getFullUrl(this.envService.delete, { id });

    return this.http.delete<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public cancelStockTransferRequest(data: ApproveRejectStockTransferRequest): Observable<any> {
    return this.http.post<any>(this.getFullUrl(this.envService.cancel), data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public approve(data: ApproveRejectStockTransferRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.approval);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public reject(data: ApproveRejectStockTransferRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.approval);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public importItems(files: Array<File>): Array<Observable<UploadResultType>> {
    return files.map(file => this.importItem(file));
  }

  public importItem(file: File): Observable<UploadResultType> {
    const url = this.getFullUrl(this.envService.import);
    const formData = new FormData();

    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http
      .post<any>(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers: this.loaderHeaders()
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };
            case HttpEventType.Response:
              return { ...event.body, status: 'done' };
            default:
              const msg = `Unhandled event: ${HttpEventType[event.type]}`;
              this.logger.info(msg);
              return null;
          }
        }),
        catchError(err => {
          return of(err);
        })
      );
  }

  public validateStockTransferRequest(stockTransfer: StockTransfer): Observable<any> {
    const url = this.getFullUrl(this.envService.validate);
    return this.http.post<any>(url, stockTransfer, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public submit(stockTransfer: StockTransfer): Observable<any> {
    const url = this.getFullUrl(this.envService.submit);
    return this.http.post<any>(url, stockTransfer, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }
}
