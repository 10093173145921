import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProductOrderItem } from '../../models/order-request.model';
import { getPriceFormat, ItemPrice } from '../../utils/calculate-price-util';
import {
  OrderStoreUseSelectItemOrderActions,
  OrderStoreUseSelectItemOrderActionTypes
} from '../actions/order-store-use-select-item.actions';

export interface OrderStoreUseSelectItemOrderState extends EntityState<ProductOrderItem> {
  isLoading: boolean;
  skipUpdated: boolean;
}

export function sortList(a: ProductOrderItem, b: ProductOrderItem) {
  const aProductType = a.productType;
  const aArticleNo = a.articleNo;
  const bProductType = b.productType;
  const bArticleNo = b.articleNo;

  if (aProductType < bProductType) {
    return -1;
  }
  if (aProductType > bProductType) {
    return 1;
  }
  if (aArticleNo < bArticleNo) {
    return -1;
  }
  if (aArticleNo > bArticleNo) {
    return 1;
  }

  return 0;
}

export const adapter: EntityAdapter<ProductOrderItem> = createEntityAdapter<ProductOrderItem>({
  selectId: (orderSelectItemOrderStoreUseContent: ProductOrderItem) => orderSelectItemOrderStoreUseContent.articleNo,
  sortComparer: sortList
});

export const initialOrderStoreUseSelectItemOrderState: OrderStoreUseSelectItemOrderState = adapter.getInitialState({
  isLoading: false,
  skipUpdated: false
});

export function OrderStoreUseSelectItemOrderReducers(
  state = initialOrderStoreUseSelectItemOrderState,
  action: OrderStoreUseSelectItemOrderActions
): OrderStoreUseSelectItemOrderState {
  switch (action.type) {
    case OrderStoreUseSelectItemOrderActionTypes.ORDER_STORE_USE_SELECT_ITEM_ORDER_ADD_ITEM:
      return adapter.upsertOne(action.payload, {
        ...state,
        skipUpdated: false
      });
    case OrderStoreUseSelectItemOrderActionTypes.ORDER_STORE_USE_SELECT_ITEM_ORDER_UPDATE_ITEM:
      return adapter.updateOne(
        {
          id: action.payload.articleNo,
          changes: { ...action.payload }
        },
        {
          ...state,
          skipUpdated: false
        }
      );
    case OrderStoreUseSelectItemOrderActionTypes.ORDER_STORE_USE_SELECT_ITEM_ORDER_UPDATE_ALL_ITEM:
      return adapter.updateMany(
        action.payload.map(v => {
          const item: ProductOrderItem = state.entities[v.articleNo];
          const itemPrice = new ItemPrice<ProductOrderItem>({ ...item, qty: v.qty }, 'qty');
          const amountExcVat = itemPrice.getTotalFrom('wholesalePriceExcVat');
          const vatAmount = itemPrice.getTotalFrom('vatAmount');
          const amount = getPriceFormat(amountExcVat.amount + vatAmount.amount);
          return {
            id: v.articleNo,
            changes: {
              ...item,
              qty: v.qty,
              amountExcVat,
              amount,
              totalVat: vatAmount,
              isAddItem: true
            }
          };
        }),
        {
          ...state,
          skipUpdated: false
        }
      );
    case OrderStoreUseSelectItemOrderActionTypes.ORDER_STORE_USE_SELECT_ITEM_ORDER_ADD_ALL_ITEM:
      return adapter.upsertMany(action.payload.itemList, {
        ...state,
        skipUpdated: action.payload.skipUpdated
      });
    case OrderStoreUseSelectItemOrderActionTypes.ORDER_STORE_USE_SELECT_ITEM_ORDER_REMOVE_ITEM:
      return adapter.removeOne(action.payload, {
        ...state
      });
    case OrderStoreUseSelectItemOrderActionTypes.ORDER_STORE_USE_SELECT_ITEM_ORDER_RESET:
      return adapter.removeAll({
        ...state
      });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
