import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { NewMasterData } from '../../../shared/gql/common.gql';
import { StoreExportCriteria } from '../../../shared/models';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { MasterService } from '../../../shared/services/master.service';
import { StoreService } from '../../../shared/services/store.service';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-store-confidential',
  templateUrl: './store-confidential.component.html',
  styleUrls: ['./store-confidential.component.scss']
})
export class StoreConfidentialComponent extends BaseReportModalComponent implements OnInit, OnDestroy {
  @ViewChild('storeConfidentialModal', { static: false }) storeConfidentialModal: ModalDirective;

  public exportForm: FormGroup;
  public storeTypeList: Array<NewMasterData> | null;
  public regionList: Array<NewMasterData> | null;
  public stateList: Array<NewMasterData> | null;
  public errorExport: string;
  public exportStatusList = filterDropdown.listStatusFilter.filter(v => Boolean(v.value));

  constructor(
    protected masterService: MasterService,
    protected readonly fb: FormBuilder,
    protected storeService: StoreService
  ) {
    super();
  }

  ngOnInit() {
    this.initControl();
  }

  initControl() {
    this.exportForm = this.fb.group({
      storeType: [null],
      status: [null],
      firstLotOnly: false
    });

    this.masterService
      .getMasterDataByNames([MasterDataEnum.MERCHANT, MasterDataEnum.REGION, MasterDataEnum.STATE])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.storeTypeList = result[MasterDataEnum.MERCHANT];
        this.regionList = result[MasterDataEnum.REGION];
        this.stateList = result[MasterDataEnum.STATE];
      });
  }

  closeExportModal() {
    this.exportForm.reset();
    this.storeConfidentialModal.hide();
  }

  onExport() {
    const fileConfig = environment.fileName.exportStoreConfidential;
    const dateExport = moment().format(fileConfig.timeFormat);
    const formData = this.exportForm.getRawValue();
    const request = this.prepareExportRequestData(formData);

    this.storeService.exportStoreConfidential(request).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${fileConfig.prefix} ${dateExport}.xlsx`);
      },
      error => {
        this.errorExport = error.error.message;
      },
      () => this.storeConfidentialModal.hide()
    );
  }

  prepareExportRequestData(formData): StoreExportCriteria {
    return new StoreExportCriteria({
      storeType: this.convertArrayFilter(formData.storeType),
      status: this.convertArrayFilter(formData.status),
      firstLotOnly: formData.firstLotOnly
    });
  }
  convertArrayFilter(value) {
    return value && value.length > 0 ? value.toString() : null;
  }

  openExportModal() {
    this.exportForm.reset();
    this.errorExport = null;
    this.storeConfidentialModal.show();
  }

  ngOnDestroy(): void {}
}
