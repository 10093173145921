import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService, ModalDirective } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { StoreList } from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  StoreConsultantModel,
  StoreConsultantPageModes,
  StoreConsultantStore
} from '../../../shared/models/store-consultant.model';
import { AuthGuardService } from '../../../shared/services';
import { MerchantService } from '../../../shared/services/merchant.service';
import {
  StoreConsultantGetByIdRequestAction,
  StoreConsultantGetByIdResetAction,
  StoreConsultantSubmitRequestAction
} from '../../../shared/store/actions/store-consultant.actions';
import { selectStoreConsultant } from '../../../shared/store/selectors/store-consultant.selector';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-store-consultant-edit',
  templateUrl: './store-consultant-edit.component.html',
  styleUrls: ['./store-consultant-edit.component.scss']
})
export class StoreConsultantEditComponent implements OnInit, OnDestroy {
  @ViewChild('modalSelectStore', { static: false }) modalSelectStore: ModalDirective;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: StoreConsultantPageModes;
    userId: string;
  };

  public storeList: Observable<StoreList[]>;
  public storeSearchLoading: boolean;
  public storeSearchInput$ = new Subject<string>();
  public selectStoreAdd: boolean;

  isFormDirty: boolean;
  private localStore: Observable<any>;
  public storeConsultantView$: Observable<StoreConsultantModel>;
  public storeConsultantForm: FormGroup;
  public dialogForm: FormGroup;
  public status = '';
  public storeConsultant: StoreConsultantModel;
  public isViewMode: boolean;

  public currentPage = 1;
  public pageSize = 20;
  public version: number;
  public hasPermissionManage = false;

  constructor(
    protected readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    private readonly fb: FormBuilder,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService,
    private readonly merchantService: MerchantService
  ) {
    // super(store, modalService, false);
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.hasPermissionManage = this.authGuardService.checkPermission(['store_consult_m']);
  }

  ngOnInit() {
    this.initData();
    this.initControl();
    this.initState();
  }

  initData() {
    this.isViewMode = this.data.mode === StoreConsultantPageModes.VIEW;
  }

  initControl(): void {
    const initialNull = [{ value: null, disabled: false }];
    this.storeConsultantForm = this.fb.group({
      active: initialNull,
      fullName: initialNull,
      userId: initialNull,
      id: initialNull,
      stores: this.fb.array([])
    });

    this.dialogForm = this.fb.group({
      storeName: [{ value: null, disabled: false }, Validators.required]
    });
  }

  initState(): void {
    if (this.data.userId) {
      this.store.dispatch(new StoreConsultantGetByIdRequestAction({ userId: this.data.userId }));
    }

    this.storeConsultantView$ = this.store.pipe(select(selectStoreConsultant));
    this.storeConsultantView$.subscribe(res => {
      if (res) {
        this.status = res.active ? 'Active' : 'Inactive';
        this.storeConsultant = res;
        this.version = res.version;

        this.storeConsultantForm.patchValue(res);

        res.stores.forEach(v => {
          this.formStoreList.push(this.createStoreFromItem(v));
        });
      }
    });
  }

  createStoreFromItem(store: StoreConsultantStore) {
    const formItem = this.fb.group({
      region: [{ value: null, disabled: false }],
      regionName: [{ value: null, disabled: false }],
      state: [{ value: null, disabled: false }],
      stateName: [{ value: null, disabled: false }],
      name: [{ value: null, disabled: false }],
      code: [{ value: null, disabled: false }],
      status: [{ value: null, disabled: false }]
    });

    formItem.patchValue({
      region: store.region,
      regionName: store.regionName,
      state: store.state,
      stateName: store.stateName,
      name: store.name,
      code: store.code,
      status: store.status
    });
    return formItem;
  }

  onExitStore() {
    this.selectStoreAdd = false;
    this.modalSelectStore.hide();
  }

  onExit(): void {
    this.isFormDirty = this.formStoreList.dirty || this.isFormDirty;

    if (this.isFormDirty) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
    }
  }

  deleteStoreItem(index: number): void {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        okText: 'Yes, Delete',
        message: 'Are you sure you want to delete this store?'
      },
      keyboard: false
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.formStoreList.removeAt(index);
          this.formStoreList.markAsDirty();
        }
      });
  }

  onSubmit() {
    const rawData = this.storeConsultantForm.value;
    rawData.version = this.version;

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new StoreConsultantSubmitRequestAction(rawData));
        }
      });
  }

  onAddStore(): void {
    this.dialogForm.controls['storeName'].setValue(null);
    this.loadStore('');
    this.modalSelectStore.show();
  }

  onStoreAdd(): void {
    this.selectStoreAdd = true;
    if (this.dialogForm.controls['storeName'].invalid) {
      return;
    }
    const store = this.dialogForm.controls['storeName'].value;

    this.onExitStore();

    if (!this.checkHasDuplicated(store)) {
      const formItem = this.createStoreFromItem(store);

      this.formStoreList.push(formItem);
      this.formStoreList.markAsDirty();
    } else {
      this.showAlert('Failed', 'Not allow to add duplicated store.');
    }
  }

  checkHasDuplicated(stores: any) {
    return (
      stores &&
      this.formStoreList.length > 0 &&
      this.formStoreList.controls.some(x => x.get('code').value === stores.code)
    );
  }

  loadStore(initialTerm: string) {
    this.storeList = concat(
      of(null),
      this.storeSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.storeSearchLoading = true)),
        switchMap(term =>
          this.merchantService
            .searchStoreByCriteria({
              searchCriteria: term,
              sortBy: 'name',
              sortOrder: 'asc',
              page: 0,
              size: 20
            })
            .pipe(
              catchError(() => of([])), // empty list on error
              map(data => data.content),
              tap(() => {
                this.storeSearchLoading = false;
              })
            )
        )
      )
    );
  }

  toggleToEditMode(): void {
    this.data.title = 'Edit Store Consultant';
    this.isViewMode = false;
  }

  showHistory() {
    const initialState = {
      title: 'History',
      action: HistoryType.REQUEST,
      historyHeader: `User ID: ${this.storeConsultant.userId}`,
      historyType: HistoryType.STORE_CONSULTANT,
      auditLogs: this.storeConsultant.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  showAlert(title: string, message: string) {
    const initialState = {
      title,
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  doAfterVersionAlertModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  get form(): FormGroup {
    return this.storeConsultantForm;
  }

  get formStoreList(): FormArray {
    return this.storeConsultantForm.get('stores') as FormArray;
  }

  ngOnDestroy(): void {
    this.store.dispatch(new StoreConsultantGetByIdResetAction());
  }
}
