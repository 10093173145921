<div [formGroup]="form">
  <div class="form-group mb-3">
    <label class="input-label">Delivery By: {{ title }}<span class="text-danger">*</span></label>
    <input
      id="deliveryDate_{{ segment }}"
      type="text"
      placeholder="{{ dateFormat }}"
      class="form-control calendar"
      autocomplete="off"
      [ngClass]="{
        'is-invalid': submitted && form.controls.deliveryDate.errors
      }"
      bsDatepicker
      [bsConfig]="bsConfig"
      formControlName="deliveryDate"
      readonly
    />
    <label for="deliveryDate_{{ segment }}" class="icon-calendar btn-calendar"></label>
    <div *ngIf="submitted && form.controls.deliveryDate.errors" class="invalid-feedback">
      <div *ngIf="form.controls.deliveryDate.errors.required; else error">
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
      <ng-template #error>
        <div *ngIf="form.controls.deliveryDate.errors.beforeToday">
          Date is invalid.
        </div>
        <div *ngIf="form.controls.deliveryDate.errors.bsDate?.maxDate">
          Delivery Date after Store Open Date is not allowed.
        </div>
      </ng-template>
    </div>
  </div>
  <div name="deliveryNote">
    <div class="note-label">
      <label for="deliveryDate">Delivery Note</label>
      <a
        name="applytoAll"
        class="btn-text-link"
        href="javascript:void(0)"
        *ngIf="mode !== pageMode.REQUEST_VIEW"
        (click)="applyToAllItems(form.value.deliveryNote)"
      >
        {{ orderSubType ? 'Apply to all' : 'Apply to all items' }}
      </a>
    </div>
    <textarea
      rows="4"
      cols="50"
      class="form-control h-100"
      maxlength="250"
      id="deliveryNote_{{ segment }}"
      formControlName="deliveryNote"
      placeholder="Delivery Note(Receiver Name/Phone)"
    ></textarea>
  </div>
</div>
