/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../components/tier-tag/tier-tag.component.ngfactory";
import * as i4 from "../../components/tier-tag/tier-tag.component";
import * as i5 from "ngx-bootstrap/dropdown";
import * as i6 from "ngx-bootstrap/component-loader";
import * as i7 from "@angular/animations";
import * as i8 from "./navbar.component";
import * as i9 from "../sidebar/sidebar.service";
import * as i10 from "@ngrx/store";
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
function View_NavbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "ul", [["aria-labelledby", "button-menu-dividers"], ["class", "dropdown-menu profile"], ["id", "dropdown-menu-dividers"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "li", [["role", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "a", [["class", "info dropdown-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " "])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "small", [["class", "d-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" User ID: ", " "])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 0, "li", [["class", "divider dropdown-divider my-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "li", [["role", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "a", [["class", "menu-item menu-bottom dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "em", [["aria-hidden", "true"], ["class", "icon-logout pr-md-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Logout "]))], null, function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.userInfoResult$))) == null) ? null : tmp_0_0.displayName); _ck(_v, 3, 0, currVal_0); var tmp_1_0 = null; var currVal_1 = (((tmp_1_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.userInfoResult$))) == null) ? null : tmp_1_0.userNo); _ck(_v, 6, 0, currVal_1); }); }
export function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "nav", [["class", "navbar navbar-default navbar-expand-lg justify-content-between"], ["id", "navbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "nav-toggler burger-pr"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidebar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "navbar-toggler-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "navbar-brand"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "TD Tawandang"], ["class", "logo-image-small"], ["src", "./assets/images/logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-tier-tag", [["class", "ml-4 app-tier-tag"]], null, null, null, i3.View_TierTagComponent_0, i3.RenderType_TierTagComponent)), i1.ɵdid(7, 114688, null, 0, i4.TierTagComponent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 13, "div", [["class", "form-inline d-sm-inline pr-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 12, "ul", [["class", "list-inline align-middle d-sm-inline d-md-inline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 11, "li", [["class", "list-inline-item mx-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 16777216, null, null, 10, "div", [["class", "menu-profile btn-group align-middle"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i1.ɵprd(512, null, i5.BsDropdownState, i5.BsDropdownState, []), i1.ɵdid(13, 212992, null, 0, i5.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i6.ComponentLoaderFactory, i5.BsDropdownState, i5.BsDropdownConfig, i7.AnimationBuilder], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 5, "a", [["aria-controls", "dropdown-menu-dividers"], ["dropdownToggle", ""], ["id", "button-menu-dividers"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 147456, null, 0, i5.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i5.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i5.BsDropdownState], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 0, "em", [["class", "icon-profile align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "span", [["class", "align-middle d-none d-sm-inline pl-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, [" ", " "])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_1)), i1.ɵdid(21, 16384, null, 0, i5.BsDropdownMenuDirective, [i5.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null)], function (_ck, _v) { _ck(_v, 7, 0); _ck(_v, 13, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 13).dropup; var currVal_1 = i1.ɵnov(_v, 13).isOpen; var currVal_2 = (i1.ɵnov(_v, 13).isOpen && i1.ɵnov(_v, 13).isBs4); _ck(_v, 11, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = true; var currVal_4 = i1.ɵnov(_v, 15).isDisabled; var currVal_5 = i1.ɵnov(_v, 15).isOpen; _ck(_v, 14, 0, currVal_3, currVal_4, currVal_5); var tmp_6_0 = null; var currVal_6 = (((tmp_6_0 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform(_co.userInfoResult$))) == null) ? null : tmp_6_0.userName); _ck(_v, 18, 0, currVal_6); }); }
export function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).autoToggleMenu($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 114688, null, 0, i8.NavbarComponent, [i9.SidebarService, i10.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarComponentNgFactory = i1.ɵccf("app-navbar", i8.NavbarComponent, View_NavbarComponent_Host_0, {}, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
