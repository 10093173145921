<div class="modal-content admin-form">
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{ title }}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" [formGroup]="form">
    <div class="form-row">
      <div class="form-group col-md">
        <label [for]="labelInputTextFirst">{{ labelInputTextFirst }}<span class="text-danger">*</span></label>
        <app-validators-message [(submitted)]="isAdded">
          <input
            type="text"
            class="form-control mr-3"
            [id]="labelInputTextFirst"
            [placeholder]="labelInputTextFirst"
            formControlName="name"
            maxlength="100"
          />
        </app-validators-message>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md">
        <label [for]="labelInputTextSecondary">{{ labelInputTextSecondary }}<span class="text-danger">*</span></label>
        <app-validators-message [(submitted)]="isAdded">
          <input
            type="text"
            class="form-control mr-3"
            [id]="labelInputTextSecondary"
            [placeholder]="labelInputTextSecondary"
            formControlName="detail"
            [maxlength]="isContractType ? 50 : 250"
          />
        </app-validators-message>
      </div>
    </div>

    <div class="form-row mb-3">
      <div class="form-group col-md">
        <label for="remark">Remark</label>
        <textarea rows="4" cols="50" class="form-control h-100" maxlength="250" id="remark" formControlName="remark">
        </textarea>
      </div>
    </div>

    <div class="form-row p-1" *ngIf="isDuplicated.isDuplicate">
      <div class="form-group p-2 mb-0 mt-2 col-md duplicate error-background">
        {{ isDuplicated.errMessage }}
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="bsModalRef.hide()" id="btCancel">
      Cancel
    </button>
    <button
      type="button"
      [ngClass]="{ deactivated: isSubmit }"
      class="btn btn-primary"
      id="btnSubmit"
      (click)="addInformation()"
    >
      {{ action === informationActionEnum.ADD ? 'Add' : 'Update' }}
    </button>
  </div>
</div>
