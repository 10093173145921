import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../../../../environments/environment';
import { FileSubmitBeforeUploadComponent } from '../../../../shared/components/file-submit-before-upload/file-submit-before-upload.component';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import * as rewardModel from '../../../../shared/models/reward.model';
import { PurchaseConditionRequestService } from '../../../../shared/services/purchase-condition-request.service';
import { AppStates } from '../../../../shared/store/state/app.states';
import { b64toBlob } from '../../../../shared/utils/b64toBlob-util';

@Component({
  selector: 'app-item-condition-import-modal',
  templateUrl: './item-condition-import-modal.component.html',
  styleUrls: ['./item-condition-import-modal.component.scss']
})
export class ItemConditionImportModalComponent implements OnInit, OnDestroy {
  @ViewChild(FileSubmitBeforeUploadComponent, { static: false })
  fileUpload: FileSubmitBeforeUploadComponent;

  @Output() submitUpload: EventEmitter<any> = new EventEmitter();
  public form: FormGroup;

  @Input() loading: boolean;
  @Input() isUploadSuccess: boolean;
  @Input() conditionPurchase: rewardModel.ConditionPurchase;
  @Input() supplierCode: string;
  @Input() previousImportId: string;
  public errorFile: Blob;
  public errorMessage: string;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected readonly purchaseConditionRequestService: PurchaseConditionRequestService,
    protected readonly logger: NGXLogger
  ) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.form.get('importItems').setValue(null);
  }

  createForm() {
    this.form = this.fb.group({
      importItems: [null]
    });
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportItemCondition.timeFormat);
  }

  onSubmit() {}

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
      }
    });
  }

  onUpload($event) {
    const fileList = $event.files;
    this.errorFile = null;
    this.purchaseConditionRequestService.importFileItemCondition(fileList.value[0]).subscribe(
      res => {
        this.submitUpload.emit(res);
        this.bsModalRef.hide();
      },
      errorResponse => {
        this.form.get('importItems').setErrors({ importFail: true });
        if (errorResponse.error.fileBase64String) {
          this.errorMessage = `${errorResponse.error.rowErrorNo} Error Found. Download this file to edit or delete invalid row then re-import.`;
          this.errorFile = b64toBlob(errorResponse.error.fileBase64String);
        } else {
          this.errorMessage = errorResponse.error.message;
        }
      }
    );
  }

  onDeletePreviousImportFile() {
    this.purchaseConditionRequestService.deletePreviousFile(this.previousImportId).subscribe(
      res => this.logger.debug(res),
      err => this.logger.debug(err)
    );
  }

  downloadTemplate() {
    this.purchaseConditionRequestService.onExportTemplate().subscribe(
      response => {
        this.saveFile(response);
      },
      error => {
        this.modalService.show(AlertModalComponent, {
          initialState: {
            title: 'Failed',
            message: error.error.message
          }
        });
      }
    );
  }

  generateFileName() {
    const supplierCode = this.supplierCode;
    const fileConfig = environment.fileName.exportItemCondition.errorExcel;

    return `${supplierCode} ${fileConfig} ${this.timeToExport}`;
  }

  saveFile(response: Blob) {
    const blob = new Blob([response]);
    saveAs(blob, `${environment.fileName.exportItemCondition.prefix} ${this.timeToExport}.xlsx`);
  }

  onDownloadErrorExcel() {
    saveAs(this.errorFile, this.generateFileName());
  }
}
