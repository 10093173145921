<div [formGroup]="parentForm">
  <div formGroupName="purchaseQtyDetails">
    <div class="row">
      <div class="col-md-3 mb-3">
        <label for="purchaseQty">Purchase Quantity<span class="text-danger">*</span></label>
        <app-numeric-textbox
          [minValue]="1"
          [format]="'0'"
          placeholder="Purchase Quantity"
          formControlName="purchaseQty"
          maxlength="8"
          [negativeSign]="false"
          id="purchaseQty"
          [maxValue]="99999"
          ngDefaultControl
          [ngClass]="{
            'is-invalid':
              submitted && (purchaseQuantityGoods.errors || purchaseQuantityGoods.get('purchaseQty').errors)
          }"
        >
        </app-numeric-textbox>
        <div *ngIf="submitted && purchaseQuantityGoods.controls.purchaseQty.errors" class="invalid-feedback">
          <div *ngIf="purchaseQuantityGoods.controls.purchaseQty.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
          <div *ngIf="purchaseQuantityGoods.controls.purchaseQty.errors.isZero">
            {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="offerPromotion">Offer with Promotion<span class="text-danger">*</span></label>
        <ng-select
          id="offerPromotion"
          [searchable]="false"
          [clearable]="false"
          [items]="offerPromotionSelect"
          formControlName="offerPromotion"
          bindLabel="label"
          bindValue="value"
        >
        </ng-select>
      </div>
    </div>

    <app-purchase-quantity-product-item
      #purchaseQuantityGoodsProductItem
      [parentForm]="parentForm"
      [maximumRow]="10"
      [submitted]="submitted"
      [saved]="saved"
      [mode]="mode"
      [isEditFirst]="isEditFirst"
      [conditionPurchase]="conditionPurchase"
    >
    </app-purchase-quantity-product-item>

    <hr class="section-divider" />
    <div>
      <div class="row section-header">
        <span>Offer Goods</span>
      </div>

      <div class="row">
        <div class="col-md-3 mb-3">
          <label for="wholesalePriceAmount"
            >Wholesale Price (inc VAT)/Base Unit<span class="text-danger">*</span></label
          >
          <app-numeric-textbox
            [minValue]="0"
            [format]="'0,0.00'"
            [decimals]="2"
            [min]="0"
            placeholder="Wholesale Price (inc VAT)/Base Unit"
            formControlName="wholesalePriceAmount"
            maxlength="8"
            [negativeSign]="false"
            id="wholesalePriceAmount"
            [maxValue]="99999.99"
            ngDefaultControl
            [ngClass]="{
              'is-invalid':
                submitted &&
                (purchaseQuantityGoods.errors || purchaseQuantityGoods.get('wholesalePriceAmount').errors)
            }"
          >
          </app-numeric-textbox>
          <div
            *ngIf="submitted && purchaseQuantityGoods.controls.wholesalePriceAmount.errors"
            class="invalid-feedback"
          >
            <div *ngIf="purchaseQuantityGoods.controls.wholesalePriceAmount.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <label for="offerQty">Offer Quantity<span class="text-danger">*</span></label>
          <app-numeric-textbox
            [decimals]="0"
            [minValue]="1"
            [min]="1"
            placeholder="Offer Quantity"
            formControlName="offerQty"
            maxlength="3"
            id="offerQty"
            [format]="'0,0'"
            [negativeSign]="false"
            [maxValue]="999"
            ngDefaultControl
            (blur)="this.calculateRedeemPrice()"
            [ngClass]="{
              'is-invalid':
                submitted && (purchaseQuantityGoods.errors || purchaseQuantityGoods.get('offerQty').errors)
            }"
          >
          </app-numeric-textbox>
          <div *ngIf="submitted && purchaseQuantityGoods.controls.offerQty.errors" class="invalid-feedback">
            <div *ngIf="purchaseQuantityGoods.controls.offerQty.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="purchaseQuantityGoods.controls.offerQty.errors.isZero">
              {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="redeemPriceAmount">Redeem Price (inc VAT)/Unit<span class="text-danger">*</span></label>
          <div class="float-right">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input-label checkbox-modal"
                id="free"
                formControlName="free"
                (click)="onChangeRedeemPriceFree($event)"
              />
            </div>
            <label for="free">
              Free
            </label>
          </div>

          <app-numeric-textbox
            [minValue]="1"
            [format]="'0,0.00'"
            [decimals]="2"
            placeholder="Redeem Price (inc VAT)/Unit"
            formControlName="redeemPriceAmount"
            maxlength="8"
            id="redeemPriceAmount"
            [negativeSign]="false"
            [maxValue]="99999.99"
            ngDefaultControl
            (blur)="this.calculateRedeemPrice()"
            [ngClass]="{
              'is-invalid':
                submitted && (purchaseQuantityGoods.errors || purchaseQuantityGoods.get('redeemPriceAmount').errors)
            }"
          >
          </app-numeric-textbox>
          <div *ngIf="submitted && purchaseQuantityGoods.controls.redeemPriceAmount.errors" class="invalid-feedback">
            <div *ngIf="purchaseQuantityGoods.controls.redeemPriceAmount.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="purchaseQuantityGoods.controls.redeemPriceAmount.errors.isZero">
              {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="wholesalePriceAmount">Redeem Price (inc VAT)</label>
          <app-numeric-textbox
            [format]="'0,0.00'"
            [decimals]="2"
            placeholder="Redeem Price (inc VAT)"
            formControlName="totalRedeemPriceIncVat"
            [negativeSign]="false"
            id="totalRedeemPriceIncVat"
            ngDefaultControl
            [ngClass]="{
              'is-invalid':
                submitted &&
                (purchaseQuantityGoods.errors || purchaseQuantityGoods.get('wholesalePriceAmount').errors)
            }"
          >
          </app-numeric-textbox>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <table id="Table" class="table table-striped table-bordered w-100" *ngIf="getProductOfferGoodsItem">
            <thead>
              <tr>
                <th class="text-center w-5">{{ headerRow[0] }}</th>
                <th class="text-center w-20">{{ headerRow[1] }}</th>
                <th class="text-center w-10">{{ headerRow[2] }}</th>
                <th class="text-center w-10">{{ headerRow[3] }}</th>
                <th class="text-center w-5">{{ headerRow[4] }}</th>
                <th class="text-center w-5">{{ headerRow[5] }}</th>
                <th class="text-center w-5" *ngIf="mode !== pageMode.VIEW">{{ headerRow[6] }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center align-top">{{ 1 }}</td>
                <td class="text-left align-top">
                  {{ getProductOfferGoodsItem.productName }}
                </td>
                <td class="text-left align-top">
                  {{ getProductOfferGoodsItem.articleNo }}
                </td>
                <td class="text-left align-top">
                  {{ getProductOfferGoodsItem.barcode }}
                </td>
                <td class="text-center align-top">
                  {{ getProductOfferGoodsItem.unit }}
                </td>
                <td class="text-center align-top">
                  {{ getProductOfferGoodsItem.unitFactor }}
                </td>
                <td class="text-center align-middle" *ngIf="mode !== pageMode.VIEW">
                  <a
                    *ngIf="mode !== pageMode.REQUEST_VIEW"
                    id="delete-store"
                    class="is-link text-muted"
                    (click)="deleteProductOfferGoodsItem()"
                    title="Delete"
                    appTippy
                    [tippyOptions]="{ arrow: true }"
                  >
                    <em class="icon-delete"></em>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="!getProductOfferGoodsItem && mode !== pageMode.REQUEST_VIEW && mode !== pageMode.VIEW">
            <a class="btn-text-link d-inline-block mr-1 " (click)="addProductOfferGoods()">+ Select Offer Goods </a>
          </div>

          <app-search-product-modal
            #searchProductOfferGoodsModal
            [promotion]="{
              promotionConRetailPrice: promotionConRetailPrice,
              promotionType: promotionType,
              promotionItemType: promotionItemType
            }"
            [productType]="productType"
            (addItem)="onAddProductOfferGoodsItem($event)"
          >
          </app-search-product-modal>
        </div>
      </div>
    </div>
  </div>
</div>
