import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  VoucherRequest,
  VoucherRequestListResponse,
  VoucherRequestSearchCriteria,
  VoucherRequestViewResponse,
  VoucherRequestWithComment
} from '../models';
import { FileResponse } from '../models/fileResponse';
import { Progress } from '../models/progress.model';
import { BaseService } from './base.service';

type UploadResultType = Progress | FileResponse | null | any;

@Injectable()
export class VoucherRequestService extends BaseService {
  public headers: HttpHeaders;
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.vouchersRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteriaRequest(criteria: VoucherRequestSearchCriteria): Observable<VoucherRequestListResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  submit(data: VoucherRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.submit);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  getVoucherRequestById(requestsId: string): Observable<VoucherRequestViewResponse> {
    const url = this.getFullUrl(this.envService.requestsID, {
      requestId: requestsId
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  approve(data: VoucherRequestWithComment) {
    const url = this.getFullUrl(this.envService.approve);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }
  reject(data: VoucherRequestWithComment) {
    const url = this.getFullUrl(this.envService.reject);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  cancel(data: VoucherRequestWithComment) {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  downloadFileTemplate() {
    const url = this.getFullUrl(this.envService.importTemplates);
    return this.http.get<any>(url, { headers: this.headers, responseType: 'blob' as 'json' });
  }

  public validateImportFiles(files: Array<File>): Array<Observable<UploadResultType>> {
    return files.map(file => this.validateImportFile(file));
  }

  public validateImportFile(file: File): Observable<UploadResultType> {
    const url = this.getFullUrl(this.envService.importValidate);
    const formData = new FormData();
    formData.append('file', file, file.name);
    this.headers.append('Content-Type', 'multipart/form-data');
    return this.http
      .post<any>(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers: this.loaderHeaders()
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };
            case HttpEventType.Response:
              return { ...event.body, status: 'done' };
            default:
              return null;
          }
        }),
        catchError(err => of(err))
      );
  }

  submitImportData(items: any[]): Observable<any> {
    const url = this.getFullUrl(this.envService.importSubmit);
    return this.http.post<any>(url, items, {
      headers: this.headers,
      observe: 'body'
    });
  }
}
