import { ArticleTypeEnum } from '../enum/article-type.enum';
import { ProductTypeEnum } from '../enum/product-type.enum';
import { SupplierStatusEnum } from '../enum/supplier.enum';
import {
  PurchaseConditionRequestWeeklySchedule,
  PurchaseConditionTabResponse
} from './purchase-condition-request.model';
import { SupplierGroupSupplier } from './supplier-group-request.model';

export enum SupplierPageModes {
  EDIT = 'EDIT',
  VIEW = 'VIEW'
}

export class DuplicatedSupplier {
  supplierCode: string;
  taxCode: string;
  branchNo: string;
  errorMessage: string;
}

export class SupplierViewResponse {
  id: string;
  supplierCode: string;
  supplierName: string;
  taxCode: string;
  branchNo: string;
  paymentTerm: string;
  paymentTermCode: string;
  paymentTermDesc?: string;
  address: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  contactName: string;
  contactNumber: string;
  active: boolean;
  version: number;
  createdDate?: string;
  createdBy?: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
  status?: string;
  supplierVat: boolean;
  ediSetting?: boolean;
  purchaseConditionReqMOV?: PurchaseConditionTabResponse[];
  purchaseConditionRequestWeeklySchedule?: PurchaseConditionRequestWeeklySchedule[];
}

export class SupplierSearch {
  verdorNo: string;
  supplierName: string;
  branchNo: string;
}

export class SupplierContent {
  id: string;
  supplierCode: string;
  supplierName: string;
  taxCode: string;
  branchNo: string;
  paymentTermCode: string;
  paymentTerm: string;
  address: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  contactName: string;
  contactNumber: string;
  active: boolean;
  supplierVat: boolean;
  version: number;
  auditLogs: AuditLog[];
  createdBy: string;
  createdByName: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedByName: string;
  lastModifiedDate: string;
  status: SupplierStatusEnum;

  paymentTermDesc?: any;
}

export interface AuditLog {
  activity: string;
  editBy: string;
  editByName: string;
  editDate: string;
}

export class SupplierSearchCriteria {
  searchCriteria?: string;
  status?: string;
  completed?: boolean[];
  lastModifiedDateFrom?: string;
  lastModifiedDateTo?: string;
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
  productType?: ProductTypeEnum;
}

export interface Supplier {
  id: string;
  requestNo?: string;
  name: string;
  articleType?: ArticleTypeEnum;
  productType: ProductTypeEnum;
  status: SupplierStatusEnum;
  version: number;
  createdBy: string;
  createdByName: string;
  lastModifiedBy: string;
  requestedBy: string;
  lastModifiedByName: string;
  createdDate: string;
  lastModifiedDate: string;
  requestedDate: string;
  supplier?: SupplierGroupSupplier;
  groupNo?: string;
  numberOfArticles?: string;
  totalSuppliers?: number;
  completed?: boolean;
  pendingSelectSupplier?: number;
}

export class SupplierPagination {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: SupplierContent[];
}

export interface ImportSupplierDto {
  supplierCode: string;
  supplierName: string;
  taxCode: string;
  branchNo: string;
  paymentTermCode: string;
  address: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  contactName: string;
  contactNumber: string;
  supplierVat: boolean;
}
