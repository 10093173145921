import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { PosAdvertisementService } from '../../services/pos-advertisement.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import {
  PosAdvertisementActionTypes,
  PosAdvertisementCancelRequest,
  PosAdvertisementCreateDefaultAction,
  PosAdvertisementCreateErrorAction,
  PosAdvertisementCreateSeasonalAction,
  PosAdvertisementEditDefaultAction,
  PosAdvertisementGetByIdRequestAction,
  PosAdvertisementGetByIdResponseAction,
  PosAdvertisementListRequestAction,
  PosAdvertisementListResponseAction,
  PosDefaultAdvertisementListResponseAction
} from '../actions/pos-advertisement.actions';

@Injectable()
export class PosAdvertisementEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: NGXLogger,
    private readonly posAdvertisementService: PosAdvertisementService
  ) {}

  @Effect()
  getPosDefaultAdvertisement$ = this.actions$.pipe(
    ofType<PosAdvertisementListRequestAction>(PosAdvertisementActionTypes.POS_DEFAULT_ADS_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${PosAdvertisementActionTypes.POS_DEFAULT_ADS_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(() => {
      return this.posAdvertisementService.getPosDefaultAds().pipe(
        map(requests => {
          return new PosDefaultAdvertisementListResponseAction(requests);
        }),
        catchError(err => {
          const isEmptyDefaultAds = err.status === 500;
          return isEmptyDefaultAds ? of() : of(new LayoutActionLoadError(err));
        })
      );
    })
  );

  @Effect()
  searchPosAdvertisement$ = this.actions$.pipe(
    ofType<PosAdvertisementListRequestAction>(PosAdvertisementActionTypes.POS_ADS_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${PosAdvertisementActionTypes.POS_ADS_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.posAdvertisementService.searchByCriteria(payload).pipe(
        map(requests => {
          return new PosAdvertisementListResponseAction(requests);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  saveDefault$ = this.actions$.pipe(
    ofType<PosAdvertisementCreateDefaultAction>(PosAdvertisementActionTypes.POS_ADS_CREATE_DEFAULT),
    tap(action => this.logger.debug('@Effect Save Advertisement: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.posAdvertisementService.submit(action.payload).pipe(
        map(() => {
          return new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'Default Ads has been created.'
          });
        }),
        catchError(err =>
          err.error && err.error.code === '07002'
            ? of(new PosAdvertisementCreateErrorAction(err.error))
            : of(new LayoutActionLoadError(err))
        )
      )
    )
  );
  @Effect()
  saveSeasonal$ = this.actions$.pipe(
    ofType<PosAdvertisementCreateSeasonalAction>(PosAdvertisementActionTypes.POS_ADS_CREATE_SEASONAL),
    tap(action => this.logger.debug('@Effect Save Advertisement: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.posAdvertisementService.submit(action.payload).pipe(
        map(() => {
          return new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'Seasonal Ads has been created.'
          });
        }),
        catchError(err =>
          err.error && err.error.code === '07002'
            ? of(new PosAdvertisementCreateErrorAction(err.error))
            : of(new LayoutActionLoadError(err))
        )
      )
    )
  );

  @Effect()
  cancelAds$ = this.actions$.pipe(
    ofType<PosAdvertisementCancelRequest>(PosAdvertisementActionTypes.POS_ADS_CANCEL_REQUESTED),
    tap(action => this.logger.debug('@Effect Advertisement Cancel: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.posAdvertisementService.cancel(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'Seasonal Ads has been cancelled.'
            })
        ),
        catchError(err => {
          return err.error && err.error.code === '00004'
            ? of(new LayoutActionVersionError(true))
            : of(new LayoutActionLoadError(err));
        })
      )
    )
  );

  @Effect()
  getById$ = this.actions$.pipe(
    ofType<PosAdvertisementGetByIdRequestAction>(PosAdvertisementActionTypes.POS_ADS_GET_BY_ID_REQUEST),
    tap(action => this.logger.debug('@Effect PosAdvertisement By ID Load: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.posAdvertisementService.getById(action.payload.adsNo).pipe(
        map(result => {
          return new PosAdvertisementGetByIdResponseAction(result);
        }),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  updateAds$: Observable<Action> = this.actions$.pipe(
    ofType<PosAdvertisementEditDefaultAction>(PosAdvertisementActionTypes.POS_ADS_EDIT_DEFAULT),
    map(action => {
      this.logger.debug('@Effect Update Ads: ' + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.posAdvertisementService.update(payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'Default Ads has been updated.'
            })
        ),
        catchError(err =>
          err.error && err.error.code === '07002'
            ? of(new PosAdvertisementCreateErrorAction(err.error))
            : of(new LayoutActionLoadError(err))
        )
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
