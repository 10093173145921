import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SalesTransactionExportCriteria } from '../models/report.model';
import { BaseService } from './base.service';

@Injectable()
export class SalesTransactionReportService extends BaseService {
  public headers: HttpHeaders;
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.salesTransactionReport;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  reportExport(reportCriteria: SalesTransactionExportCriteria) {
    const params = this.getParams(reportCriteria, true);

    const url = this.getFullUrl(this.envService.export);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  reportExportConfidential(reportCriteria: SalesTransactionExportCriteria) {
    const params = this.getParams(reportCriteria, true);

    const url = this.getFullUrl(this.envService.exportConfidential);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
