import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { OrderDeliveryScheduleType } from '../enum/purchase-condition.enum';
import {
  ApproveRejectPurchaseConditionRequest,
  ItemConditionListCriteria,
  ItemConditionListResponse,
  PurchaseConditionRequest,
  PurchaseConditionRequestListResponse,
  PurchaseConditionRequestListSearchCriteria,
  PurchaseConditionRequestWeeklyScheduleImportCriteria,
  PurchaseConditionRequestWeeklyScheduleResponse,
  RequestWithComment
} from '../models/purchase-condition-request.model';
import { BaseService } from './base.service';

@Injectable()
export class PurchaseConditionRequestService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, protected readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.purchaseConditionRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(
    criteria: PurchaseConditionRequestListSearchCriteria
  ): Observable<PurchaseConditionRequestListResponse> {
    criteria = { ...criteria, sortBy: 'lastModifiedDate', sortOrder: 'desc' };
    const params = this.getParams(criteria, true);
    return this.http.get<PurchaseConditionRequestListResponse>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getItemConditionList(criteria: ItemConditionListCriteria): Observable<ItemConditionListResponse> {
    const url = this.getFullUrl(this.envService.discount);
    const params = this.getParams(criteria, true);
    return this.http.get<ItemConditionListResponse>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getPurchaseConditionRequestWeeklyScheduleImportList(
    criteria: PurchaseConditionRequestWeeklyScheduleImportCriteria
  ): Observable<PurchaseConditionRequestWeeklyScheduleResponse> {
    let url;
    let params;
    if ([OrderDeliveryScheduleType.WEEKLY, OrderDeliveryScheduleType.WEEKLY_MANUAL].includes(criteria.scheduleMode)) {
      url = this.getFullUrl(this.envService.orderDeliveryScheduleWeekly);
      params = this.getParams(criteria, true);
      return this.http.get<PurchaseConditionRequestWeeklyScheduleResponse>(url, {
        headers: this.headers,
        observe: 'body',
        params
      });
    } else if (criteria.scheduleMode === OrderDeliveryScheduleType.MONTHLY) {
      url = this.getFullUrl(this.envService.orderDeliveryScheduleMonthly);
      params = this.getParams(criteria, true);
      return this.http.get<PurchaseConditionRequestWeeklyScheduleResponse>(url, {
        headers: this.headers,
        observe: 'body',
        params
      });
    }
  }

  public onExportTemplate() {
    const url = this.getFullUrl(this.envService.exportTemplate);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public onExportOrderDeliveryScheduleTemplate(scheduleMode: OrderDeliveryScheduleType) {
    let url;
    if (scheduleMode === OrderDeliveryScheduleType.WEEKLY) {
      url = this.getFullUrl(this.envService.exportOrderDeliveryScheduleWeeklyTemplate);
    } else if (scheduleMode === OrderDeliveryScheduleType.MONTHLY) {
      url = this.getFullUrl(this.envService.exportOrderDeliveryScheduleMonthlyTemplate);
    }

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public importFileItemCondition(file): Observable<any> {
    const url = this.getFullUrl(this.envService.import);
    const formData = new FormData();
    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(url, formData, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public importOrderDeliveryWeeklySchedule(file, scheduleMode: OrderDeliveryScheduleType): Observable<any> {
    let url;
    if (scheduleMode === OrderDeliveryScheduleType.WEEKLY) {
      url = this.getFullUrl(this.envService.importOrderDeliveryScheduleWeeklyTemplate);
    } else if (scheduleMode === OrderDeliveryScheduleType.MONTHLY) {
      url = this.getFullUrl(this.envService.importOrderDeliveryScheduleMonthlyTemplate);
    }

    const formData = new FormData();
    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(url, formData, {
      observe: 'body',
      headers: this.loaderHeaders()
    });
  }

  public importOrderDeliveryMonthlySchedule(file): Observable<any> {
    const url = this.getFullUrl(this.envService.importOrderDeliveryScheduleMonthlyTemplate);
    const formData = new FormData();
    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(url, formData, {
      observe: 'body',
      headers: this.loaderHeaders()
    });
  }

  public deletePreviousFile(previousImportId: string): Observable<any> {
    const url = this.getFullUrl(this.envService.deleteImportFile, { previousImportId });
    return this.http.delete<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public save(request: PurchaseConditionRequest): Observable<any> {
    return this.http.post<any>(this.getUrl(), request, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public edit(request: PurchaseConditionRequest): Observable<any> {
    return this.http.put<any>(this.getUrl(), request, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public submit(request: PurchaseConditionRequest): Observable<any> {
    return this.http.post<any>(this.getFullUrl(this.envService.submit), request, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public approve(request: ApproveRejectPurchaseConditionRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.approve);
    // console.log('approve', request, url);
    return this.http.post<any>(url, request, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public reject(request: ApproveRejectPurchaseConditionRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.reject);
    // console.log('reject', request, url);
    return this.http.post<any>(url, request, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getPurchaseConditionById(id: string): Observable<PurchaseConditionRequest> {
    const url = this.getFullUrl(this.envService.get, {
      id
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  deletePurchaseConditionRequest(id: string) {
    const url = this.getFullUrl(this.envService.get, {
      id
    });
    return this.http.delete<any>(url, {
      headers: this.loaderHeaders()
    });
  }

  cancel(data: RequestWithComment): Observable<boolean> {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  checkPurchaseConditionExists(supplierId: string): Observable<any> {
    const url = this.getFullUrl(this.envService.exists, { supplierId });
    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }
}
