import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ShelfInventoryService } from '../../services/shelf-inventory.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { ShelfInventoryActionType, ShelfInventoryByIdResponseAction, ShelfInventoryListHistoryResponseAction, ShelfInventoryListResponseAction, SubmitShelfInventoryFirstLotErrorAction, SubmitShelfInventoryFirstLotResponseAction } from '../actions/shelf-inventory.actions';
export class ShelfInventoryEffects {
    constructor(actions$, shelfInventoryService, store$, logger) {
        this.actions$ = actions$;
        this.shelfInventoryService = shelfInventoryService;
        this.store$ = store$;
        this.logger = logger;
        this.searchShelf$ = this.actions$.pipe(ofType(ShelfInventoryActionType.SHELF_INVENTORY_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfInventoryActionType.SHELF_INVENTORY_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfInventoryService.searchByCriteria(payload).pipe(map(response => {
                return new ShelfInventoryListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.shelfHistory$ = this.actions$.pipe(ofType(ShelfInventoryActionType.SHELF_INVENTORY_LIST_HISTORY_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfInventoryActionType.SHELF_INVENTORY_LIST_HISTORY_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfInventoryService.getHistoryLogs(payload).pipe(map(response => new ShelfInventoryListHistoryResponseAction({ auditLogs: response.auditLogs })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getMerchantById$ = this.actions$.pipe(ofType(ShelfInventoryActionType.SHELF_INVENTORY_GET_BY_ID_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfInventoryActionType.SHELF_INVENTORY_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfInventoryService.getShelfById(payload).pipe(map(res => {
                return new ShelfInventoryByIdResponseAction(res);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.submitFirstLot$ = this.actions$.pipe(ofType(ShelfInventoryActionType.SUBMIT_SHELF_INVENTORY_FIRST_LOT_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfInventoryActionType.SUBMIT_SHELF_INVENTORY_FIRST_LOT_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfInventoryService.submitFirstLot(payload).pipe(map(res => new SubmitShelfInventoryFirstLotResponseAction(res)), catchError(error => of(new SubmitShelfInventoryFirstLotErrorAction(error.error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfInventoryEffects.prototype, "searchShelf$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfInventoryEffects.prototype, "shelfHistory$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfInventoryEffects.prototype, "getMerchantById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfInventoryEffects.prototype, "submitFirstLot$", void 0);
