<!-- Modal Section -->
<div
  id="destroyReportModal"
  class="modal fade barcodeModal admin-form"
  bsModal
  #destroyReportModal="bs-modal"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <h6 class="modal-title pull-left">
            Export Destroy Document Report
          </h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeExportModal()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body my-2 pt-0">
          <div class="container" [formGroup]="exportForm">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group ">
                    <div class="mb-2">Destroy Document No.<span class="text-danger">*</span></div>
                    <ng-select
                      [items]="destroyDocumentList | async"
                      placeholder="Please select..."
                      notFoundText="No Data"
                      formControlName="destroyDoc"
                      bindLabel="docNo"
                      bindValue="docNo"
                      (change)="onChangeDestroyDocument($event)"
                      [loading]="destroyDocumentSearchLoading"
                      [typeahead]="destroyDocumentSearchInput$"
                    >
                      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                        <div>
                          <span [ngOptionHighlight]="search">{{ item.docNo }}</span>
                        </div>
                        <small>
                          <strong>Store Code:</strong
                          ><span [ngOptionHighlight]="search" class="ml-1">{{ item.storeCode }}</span
                          >{{ ' | ' }} <strong>Created Date:</strong
                          ><span [ngOptionHighlight]="search" class="ml-1">{{
                            item.createdDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay
                          }}</span>
                        </small>
                      </ng-template>
                    </ng-select>
                  </div>

                  <div *ngIf="responseExportError" class="alert-danger form-group mb-0">
                    {{ responseExportError }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            id="export_report_btn"
            [disabled]="exportFormInValid"
            (click)="onExport()"
            appPreventDoubleClick
          >
            Export
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- End Modal Section -->
