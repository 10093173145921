import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { round } from 'lodash';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { BsDatepickerConfig, BsModalRef } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';
import { environment, environment as env } from '../../../../../environments/environment';
import { MasterDataEnum } from '../../../../shared/enum/master-data.enum';
import { AssortmentAllType, AssortmentPageModes, AssortmentRequestTypeEnum } from '../../../../shared/models';
import {
  locationAllStoresLOV,
  locationAllWarehouseLOV,
  locationLevelSupplierLOV,
  locationLevelWarehouseLOV,
  pickingUnitLOV
} from '../../../../shared/models/list-value/list-key-value.model';
import { SupplierPriceContent } from '../../../../shared/models/supplier-price.model';
import { SupplierSearch } from '../../../../shared/models/supplier.model';
import { MasterService } from '../../../../shared/services/master.service';
import { StoresService } from '../../../../shared/services/stores.service';
import { SupplierService } from '../../../../shared/services/supplier.service';
import { selectAllWarehouse } from '../../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';
import {
  convertBkkToUtc,
  convertUtcToBkk,
  formatDateEndOfDay,
  formatDateStartOfDay,
  getDateFromString
} from '../../../../shared/utils/date-util';
import { alwaysRoundUp } from '../../../../shared/utils/number-util';

const moment = extendMoment(Moment);

@Component({
  selector: 'app-manual-add-edit-supplier-price',
  templateUrl: './manual-add-edit-supplier-price.component.html',
  styleUrls: ['./manual-add-edit-supplier-price.component.scss']
})
export class ManualAddEditSupplierPriceComponent implements OnInit, OnDestroy {
  @Input() isSubmit: boolean;
  @Input() supplierDetailsForm: FormGroup;
  @Input() productDetailsForm: FormGroup;
  @Input() listOfValue: {};
  @Input() isRequestViewMode: boolean;
  @Input() supplierPrice: SupplierPriceContent;
  @Input() manualSupplierPriceDatas: SupplierPriceContent[];
  @Input() editingSupplierPriceIndex: number;
  @Input() mode: AssortmentPageModes;
  @Input() errorSupplierPriceDisplay: {
    [key: number]: {
      orderBarcode: boolean;
      unitFactor: number;
      tdPickingUnit: string;
    };
  } = {};
  @Input() type: AssortmentRequestTypeEnum;

  public assortmentAllType = AssortmentAllType;
  public supplierList: Observable<SupplierSearch[]>;
  public addSupplierPriceForm: FormGroup;
  public supplierSearchLoading = false;
  public supplierSearchInput$ = new Subject<string>();
  public storeInput$ = new Subject<string>();
  public dateFormat = environment.dateFormat;
  public listOfOrderUnit: {};
  public isSubmitAddSupplierPrice = false;
  public bsConfig: BsDatepickerConfig;
  public listOfChange = {};
  public locationItems$: Observable<any>;
  public warehouseList$: Observable<any>;
  public localStore: Observable<any>;
  public onSaveSupplierPrice: Subject<Array<SupplierPriceContent>>;
  public assortmentPageMode = AssortmentPageModes;
  public expireDateMinDate = new Date();
  public additionalCharacters: RegExp = new RegExp('[-]', 'gi');

  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected supplierService: SupplierService,
    protected masterService: MasterService,
    protected bsModalRef: BsModalRef,
    protected readonly logger: NGXLogger,
    protected storesService: StoresService
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    // this.store.dispatch(new WarehouseListRequestAction('-'));
    this.listOfOrderUnit = pickingUnitLOV;

    this.setInitialElementValue();
    this.createSupplierPriceForm();
    this.loadSupplier('');
    this.onSaveSupplierPrice = new Subject();
  }

  ngOnDestroy() {
    this.isSubmitAddSupplierPrice = false;
    this.editingSupplierPriceIndex = null;
    this.addSupplierPriceForm.reset();
  }

  setInitialElementValue() {
    this.warehouseList$ = this.localStore.pipe(
      select(selectAllWarehouse('Warehouse')),
      map(warehouse => {
        const warehouseSelectList = [];
        warehouse.forEach(listOfValue => {
          const warehouseList = {
            value: null,
            label: null
          };
          warehouseList.value = listOfValue.wmsCode;
          warehouseList.label = listOfValue.name;
          warehouseSelectList.push(warehouseList);
        });
        return warehouseSelectList;
      })
    );

    this.bsConfig = {
      dateInputFormat: env.dateFormat,
      minDate: new Date(),
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue',
      adaptivePosition: true
    } as BsDatepickerConfig;

    if (this.getDeliveryMethod === 'TD') {
      this.listOfValue['locationLevel'] = locationLevelWarehouseLOV;
    } else if (this.getDeliveryMethod === 'SUPPLIER') {
      this.listOfValue['locationLevel'] = locationLevelSupplierLOV;
    }
  }

  logForm() {
    this.logger.debug('this.addSupplierPriceForm ', this.addSupplierPriceForm);
    this.logger.debug('this.supplierDetailsForm ', this.supplierDetailsForm);
    this.logger.debug('productDetailsForm ', this.productDetailsForm);
  }

  loadSupplier(initialTerm: string) {
    this.supplierList = concat(
      of([]),
      this.supplierSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.supplierSearchLoading = true)),
        switchMap(term =>
          this.supplierService.searchSupplierByName({ searchCriteria: term }).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.supplierSearchLoading = false;
            })
          )
        )
      )
    );
  }

  loadStores(initialTerm: string) {
    this.locationItems$ = concat(
      of([]),
      this.storeInput$.pipe(
        startWith(initialTerm),
        distinctUntilChanged(),
        switchMap(term =>
          this.storesService.searchStores({ searchCriteria: term }).pipe(
            catchError(() => of([])), // empty list on error
            map(res => {
              const storeSelectList = [];
              res.forEach(listOfValue => {
                const storeList = {
                  value: null,
                  label: null
                };
                storeList.value = listOfValue.code;
                storeList.label = listOfValue.name;
                storeSelectList.push(storeList);
              });
              return storeSelectList;
            })
          )
        )
      )
    );
  }

  onChangeSupplierName(value) {
    this.clearError();
    this.addSupplierPriceForm.patchValue({
      supplierCode: value ? value.supplierCode : null
    });
  }

  onBlurSupplierName() {
    if (!this.addSupplierPriceForm.controls.supplier.value) {
      this.loadSupplier('');
    }
  }

  hideModalAddSupplierPrice() {
    this.bsModalRef.hide();
  }

  saveSupplierPrice() {
    this.isSubmitAddSupplierPrice = true;
    if (!this.addSupplierPriceForm.valid) {
      return;
    }
    const addSupplierFormRaw = this.addSupplierPriceForm.getRawValue();
    const supplierPrice = this.prepareSupplierPrice(addSupplierFormRaw);
    this.validateNameAndPrice(supplierPrice);
    this.validateEffectiveDateAdnExpireDate();
    if (this.addSupplierPriceForm.valid) {
      if (this.editingSupplierPriceIndex !== null) {
        this.manualSupplierPriceDatas[this.editingSupplierPriceIndex] = {
          ...this.manualSupplierPriceDatas[this.editingSupplierPriceIndex],
          ...supplierPrice
        };
      } else {
        this.manualSupplierPriceDatas.push(supplierPrice);
      }
      this.onSaveSupplierPrice.next(this.manualSupplierPriceDatas);
      this.hideModalAddSupplierPrice();
    } else {
      return;
    }
  }

  resetErrorSupplierPriceDisplay(i: number = null) {
    if (i !== null) {
      Object.keys(this.errorSupplierPriceDisplay).forEach(key => {
        if (key === String(i)) {
          delete this.errorSupplierPriceDisplay[i];
        }
      });
    } else {
      // Clear all errors
      Object.keys(this.errorSupplierPriceDisplay).forEach(key => delete this.errorSupplierPriceDisplay[key]);
    }
  }

  createSupplierPriceForm() {
    const isDisable = [AssortmentPageModes.ASSORTMENT_VIEW, AssortmentPageModes.REQUEST_VIEW].includes(this.mode);
    // const isAfterEffectiveDateAndAssortmentEdit =
    //   ([AssortmentPageModes.ASSORTMENT_EDIT].includes(this.mode) || this.type === AssortmentRequestTypeEnum.EDIT) &&
    //   (this.supplierPrice
    //     ? moment(this.supplierPrice.effectiveDate)
    //         .startOf('day')
    //         .isSameOrBefore(moment().startOf('day'), 'day')
    //     : false);
    let supplierObject = null;
    let locationObject = null;
    let locationLevelTypeObject = null;

    if (this.supplierPrice) {
      supplierObject = {
        supplierName: this.supplierPrice.supplierName,
        supplierCode: this.supplierPrice.supplierCode,
        branchNo: this.supplierPrice.branchNo
      };
      locationObject = {
        value: this.supplierPrice.locationCode,
        label: this.supplierPrice.locationName
      };
      locationLevelTypeObject = {
        value: this.supplierPrice.locationLevelType,
        label:
          this.supplierPrice.locationLevelType === 'BY_STORE'
            ? 'By Store'
            : this.supplierPrice.locationLevelType === 'BY_PROVINCE'
            ? 'By Province'
            : this.supplierPrice.locationLevelType === 'BY_DC'
            ? 'By DC'
            : this.supplierPrice.location
      };
      this.prepareLocationSelectList(locationLevelTypeObject);
    }

    this.addSupplierPriceForm = this.fb.group({
      locationLevelType: [
        {
          value: locationLevelTypeObject ? locationLevelTypeObject : null,
          disabled: isDisable
        },
        Validators.required
      ],
      location: [
        {
          value: locationObject ? locationObject : null,
          disabled: isDisable
        },
        Validators.required
      ],
      supplier: [
        {
          value: supplierObject ? supplierObject : null,
          disabled: isDisable
        },
        Validators.required
      ],
      barcode: [
        {
          value: this.supplierPrice && this.supplierPrice.barcode !== null ? this.supplierPrice.barcode : null,
          disabled: isDisable
        },
        Validators.required
      ],
      orderUnit: [
        {
          value: this.supplierPrice && this.supplierPrice.orderUnit ? this.supplierPrice.orderUnit : null,
          disabled: isDisable
        },
        Validators.required
      ],
      unitFactor: [
        {
          value: this.supplierPrice && this.supplierPrice.unitFactor ? this.supplierPrice.unitFactor : null,
          disabled: isDisable
        },
        Validators.required
      ],
      supplierPriceExVatAmount: [
        {
          value:
            this.supplierPrice && this.supplierPrice.supplierPriceExVatAmount
              ? this.supplierPrice.supplierPriceExVatAmount
              : null,
          disabled: isDisable
        },
        [Validators.required, this.notAllowZeroValidator()]
      ],
      avgSupplierPriceExVatAmount: [
        {
          value:
            this.supplierPrice && this.supplierPrice.avgSupplierPriceExVatAmount
              ? this.supplierPrice.avgSupplierPriceExVatAmount
              : null,
          disabled: true
        }
      ],
      effectiveDate: [
        {
          value:
            this.supplierPrice && this.supplierPrice.effectiveDate
              ? getDateFromString(convertUtcToBkk(this.supplierPrice.effectiveDate))
              : null,
          disabled: isDisable
        },
        Validators.required
      ],
      expireDate: [
        {
          value:
            this.supplierPrice && this.supplierPrice.expireDate
              ? getDateFromString(convertUtcToBkk(this.supplierPrice.expireDate))
              : null,
          disabled: isDisable
        },
        Validators.required
      ]
    });

    this.addSupplierPriceForm.controls.supplierPriceExVatAmount.valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(supplierPrice => {
        const unitFactor = this.addSupplierPriceForm.controls.unitFactor.value;
        let averageSupplierPrice;
        if (supplierPrice && unitFactor) {
          averageSupplierPrice = supplierPrice / unitFactor;
        } else {
          averageSupplierPrice = 0;
        }
        averageSupplierPrice
          ? this.addSupplierPriceForm.controls.avgSupplierPriceExVatAmount.patchValue(
              round(alwaysRoundUp(averageSupplierPrice, 3), 2)
            )
          : this.addSupplierPriceForm.controls.avgSupplierPriceExVatAmount.patchValue(averageSupplierPrice);
      });

    this.addSupplierPriceForm.controls.unitFactor.valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(unitFactor => {
        const supplierPrice = this.addSupplierPriceForm.controls.supplierPriceExVatAmount.value;
        let averageSupplierPrice;
        if (supplierPrice && unitFactor) {
          averageSupplierPrice = supplierPrice / unitFactor;
        } else {
          averageSupplierPrice = 0;
        }
        averageSupplierPrice
          ? this.addSupplierPriceForm.controls.avgSupplierPriceExVatAmount.patchValue(
              round(alwaysRoundUp(averageSupplierPrice, 3), 2)
            )
          : this.addSupplierPriceForm.controls.avgSupplierPriceExVatAmount.patchValue(averageSupplierPrice);
      });

    this.addSupplierPriceForm.controls.effectiveDate.valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.expireDateMinDate = this.addSupplierPriceForm.controls.effectiveDate.value;
      });

    this.addSupplierPriceForm.controls.locationLevelType.valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(locationLevelType => {
        this.addSupplierPriceForm.controls.location.reset();
        this.prepareLocationSelectList(locationLevelType);
      });
  }

  prepareLocationSelectList(locationLevelType) {
    if (this.getDeliveryMethod === 'TD') {
      if (locationLevelType && locationLevelType.value === 'ALL_DCS') {
        this.locationItems$ = of(locationAllWarehouseLOV);
      } else if (locationLevelType && locationLevelType.value === 'BY_DC') {
        this.locationItems$ = this.warehouseList$;
      }
    } else if (this.getDeliveryMethod === 'SUPPLIER') {
      if (locationLevelType && locationLevelType.value === 'ALL_STORES') {
        this.locationItems$ = of(locationAllStoresLOV);
      } else if (locationLevelType && locationLevelType.value === 'BY_PROVINCE') {
        this.locationItems$ = this.masterService.getMasterDataByNames([MasterDataEnum.STATE]).pipe(
          untilComponentDestroyed(this),
          filter(res => Boolean(res && res.data)),
          map(res => {
            const provinceSelectList = [];
            res.data.states.forEach(listOfValue => {
              const provinceList = {
                value: null,
                label: null
              };
              provinceList.value = listOfValue.code;
              provinceList.label = listOfValue.nameTh;
              provinceSelectList.push(provinceList);
            });
            return provinceSelectList;
          })
        );
      } else if (locationLevelType && locationLevelType.value === 'BY_STORE') {
        this.loadStores('');
      }
    }
  }

  validateNameAndPrice(addSupplierPrice) {
    const supplierPriceList: SupplierPriceContent[] = [];
    this.manualSupplierPriceDatas.forEach(e => {
      supplierPriceList.push(e);
    });
    if (this.productDetailsForm.controls.deliveryMethod.value === 'TD') {
      const sameLocationSupplierPriceList = supplierPriceList.filter((supplierPrice, index) => {
        if (supplierPrice.locationCode) {
          return this.editingSupplierPriceIndex !== null
            ? this.editingSupplierPriceIndex !== index &&
                addSupplierPrice.locationCode === supplierPrice.locationCode &&
                addSupplierPrice.barcode === supplierPrice.barcode
            : addSupplierPrice.locationCode === supplierPrice.locationCode &&
                addSupplierPrice.barcode === supplierPrice.barcode;
        }
      });

      const isDuplicateLocationSamePeriod = sameLocationSupplierPriceList.some(supplierPrice => {
        return (
          supplierPrice.supplierCode === addSupplierPrice.supplierCode &&
          moment
            .range(moment(addSupplierPrice.effectiveDate), moment(addSupplierPrice.expireDate))
            .overlaps(
              moment.range(
                moment(getDateFromString(convertUtcToBkk(supplierPrice.effectiveDate))),
                moment(getDateFromString(convertUtcToBkk(supplierPrice.expireDate)))
              ),
              {
                adjacent: true
              }
            )
        );
      });

      if (isDuplicateLocationSamePeriod) {
        this.addSupplierPriceForm.controls.supplier.setErrors({ duplicateSupplierSamePeriod: true });
      }
    } else if (this.productDetailsForm.controls.deliveryMethod.value === 'SUPPLIER') {
      const samePeriodSupplierPrice = supplierPriceList.filter((supplierPrice, index) => {
        if (supplierPrice.locationCode === addSupplierPrice.locationCode) {
          return this.editingSupplierPriceIndex !== null
            ? this.editingSupplierPriceIndex !== index &&
                moment
                  .range(moment(addSupplierPrice.effectiveDate), moment(addSupplierPrice.expireDate))
                  .overlaps(
                    moment.range(
                      moment(getDateFromString(convertUtcToBkk(supplierPrice.effectiveDate))),
                      moment(getDateFromString(convertUtcToBkk(supplierPrice.expireDate)))
                    ),
                    {
                      adjacent: true
                    }
                  )
            : moment
                .range(moment(addSupplierPrice.effectiveDate), moment(addSupplierPrice.expireDate))
                .overlaps(
                  moment.range(
                    moment(getDateFromString(convertUtcToBkk(supplierPrice.effectiveDate))),
                    moment(getDateFromString(convertUtcToBkk(supplierPrice.expireDate)))
                  ),
                  {
                    adjacent: true
                  }
                );
        }
      });
      if (samePeriodSupplierPrice.length > 0) {
        this.addSupplierPriceForm.controls.location.setErrors({ duplicateLocationSamePeriod: true });
        return;
      }
    }
  }

  validateEffectiveDateAdnExpireDate() {
    const effectiveDate = this.addSupplierPriceForm.controls.effectiveDate.value;
    const expireDate = this.addSupplierPriceForm.controls.expireDate.value;
    if (moment(expireDate).isBefore(moment(effectiveDate))) {
      ///////// set error here
      this.addSupplierPriceForm.controls.effectiveDate.setErrors({ invalidDate: true });
      this.addSupplierPriceForm.controls.expireDate.setErrors({ invalidDate: true });
      return;
    }
  }

  clearDateError(value: Date) {
    if (value) {
      if (this.addSupplierPriceForm.controls.effectiveDate.value) {
        this.addSupplierPriceForm.controls.effectiveDate.setErrors(null);
      }
      if (this.addSupplierPriceForm.controls.expireDate.value) {
        this.addSupplierPriceForm.controls.expireDate.setErrors(null);
      }
      if (this.addSupplierPriceForm.controls.supplier.value) {
        this.addSupplierPriceForm.controls.supplier.setErrors(null);
      }
      if (this.addSupplierPriceForm.controls.supplierPriceExVatAmount.value) {
        this.addSupplierPriceForm.controls.supplierPriceExVatAmount.setErrors(null);
      }
      if (this.addSupplierPriceForm.controls.location.value) {
        this.addSupplierPriceForm.controls.location.setErrors(null);
      }
    }
  }

  clearError() {
    if (this.addSupplierPriceForm.controls.supplier.value) {
      this.addSupplierPriceForm.controls.supplier.setErrors(null);
    }
    if (this.addSupplierPriceForm.controls.supplierPriceExVatAmount.value) {
      this.addSupplierPriceForm.controls.supplierPriceExVatAmount.setErrors(null);
    }
    if (this.addSupplierPriceForm.controls.location.value) {
      this.addSupplierPriceForm.controls.location.setErrors(null);
    }
  }

  notAllowZeroValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return Number(control.value) === 0 ? { isZero: true } : null;
    };
  };

  prepareSupplierPrice(addSupplierPriceValue): SupplierPriceContent {
    const {
      supplier,
      location,
      locationLevelType,
      effectiveDate,
      expireDate,
      ...supplierPrice
    } = addSupplierPriceValue;
    const preparedSupplierPrice = {
      ...supplierPrice,
      locationCode: location.value,
      locationName: location.label,
      locationLevelType: locationLevelType.value,
      location:
        locationLevelType.value === 'BY_PROVINCE'
          ? location.label
          : locationLevelType.value === 'BY_DC'
          ? `${location.value} ${location.label}`
          : locationLevelType.label,
      supplierName: supplier.supplierName,
      supplierCode: supplier.supplierCode,
      branchNo: supplier.branchNo,
      effectiveDate: convertBkkToUtc(formatDateStartOfDay(effectiveDate)),
      expireDate: convertBkkToUtc(formatDateEndOfDay(expireDate))
    };
    return preparedSupplierPrice;
  }

  get getDeliveryMethod() {
    return this.productDetailsForm.controls.deliveryMethod.value;
  }
}
