import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { StockTransferItem } from '../../models/stock-transfer-request.model';
import {
  StockProductSelectedActions,
  StockProductSelectedActionTypes
} from '../actions/stock-product-selected.actions';

export interface StockProductSelectedState extends EntityState<StockTransferItem> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<StockTransferItem> = createEntityAdapter<StockTransferItem>({
  selectId: (stockTransferItem: StockTransferItem) => `${stockTransferItem.articleNo}`
});

export const initialStockProductSelectedState: StockProductSelectedState = adapter.getInitialState({
  isLoading: false
});

export function stockProductSelectedReducers(
  state = initialStockProductSelectedState,
  action: StockProductSelectedActions
): StockProductSelectedState {
  switch (action.type) {
    case StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_ADD_ITEM:
      return adapter.upsertOne(action.payload, {
        ...state
      });
    case StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_UPDATE_ITEM:
      return adapter.updateOne(
        {
          id: action.payload.articleNo,
          changes: { ...action.payload }
        },
        {
          ...state
        }
      );
    case StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_ADD_ALL_ITEM:
    case StockProductSelectedActionTypes.PURCHASE_REQUEST_ITEM_SELECTED_LIST:
      return adapter.upsertMany(action.payload, {
        ...state
      });
    case StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_INSERT_ALL_ITEM:
      return adapter.addAll(action.payload, {
        ...state
      });
    case StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_REMOVE_ITEM:
      return adapter.removeOne(action.payload, {
        ...state
      });
    case StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_RESET:
      return adapter.removeAll({
        ...state
      });

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
