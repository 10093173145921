import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ShelfInventoryRequestService } from '../../services/shelf-inventory-request.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  ShelfInventoryApproveRequestAction,
  ShelfInventoryApproveResponseAction,
  ShelfInventoryCreateSaveErrorAction,
  ShelfInventoryCreateSaveRequestAction,
  ShelfInventoryCreateSaveResponseAction,
  ShelfInventoryCreateSubmitErrorAction,
  ShelfInventoryCreateSubmitRequestAction,
  ShelfInventoryCreateSubmitResponseAction,
  ShelfInventoryDeleteRequestAction,
  ShelfInventoryDeleteResponseAction,
  ShelfInventoryRequestActionType,
  ShelfInventoryRequestByIdRequestAction,
  ShelfInventoryRequestByIdResponseAction,
  ShelfInventoryRequestListHistoryRequestAction,
  ShelfInventoryRequestListHistoryResponseAction,
  ShelfInventoryRequestListRequestAction,
  ShelfInventoryRequestListResponseAction
} from '../actions/shelf-inventory-request.actions';

@Injectable()
export class ShelfInventoryRequestEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly shelfInventoryRequestService: ShelfInventoryRequestService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchShelfRequest$ = this.actions$.pipe(
    ofType<ShelfInventoryRequestListRequestAction>(
      ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_REQUEST
    ),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfInventoryRequestService.searchByCriteria(payload).pipe(
        map(response => {
          return new ShelfInventoryRequestListResponseAction(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  shelfCreateSave$ = this.actions$.pipe(
    ofType<ShelfInventoryCreateSaveRequestAction>(
      ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SAVE_REQUEST
    ),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SAVE_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfInventoryRequestService.saveRequest(payload).pipe(
        map(res => new ShelfInventoryCreateSaveResponseAction(res)),
        catchError(error => of(new ShelfInventoryCreateSaveErrorAction(error)))
      );
    })
  );

  @Effect()
  shelfCreateSubmit$ = this.actions$.pipe(
    ofType<ShelfInventoryCreateSubmitRequestAction>(
      ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SUBMIT_REQUEST
    ),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SUBMIT_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfInventoryRequestService.submitRequest(payload).pipe(
        map(res => new ShelfInventoryCreateSubmitResponseAction(res)),
        catchError(error => of(new ShelfInventoryCreateSubmitErrorAction(error.error)))
      );
    })
  );

  @Effect()
  getShelfRequestById$ = this.actions$.pipe(
    ofType<ShelfInventoryRequestByIdRequestAction>(
      ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_GET_BY_ID_REQUEST
    ),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_GET_BY_ID_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfInventoryRequestService.getShelfRequestById(payload).pipe(
        map(res => {
          return new ShelfInventoryRequestByIdResponseAction(res);
        }),

        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  shelfApprove$ = this.actions$.pipe(
    ofType<ShelfInventoryApproveRequestAction>(
      ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_APPROVE_REQUEST
    ),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_APPROVE_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload =>
      this.shelfInventoryRequestService.approveRequest(payload).pipe(
        map(() => new ShelfInventoryApproveResponseAction({ isSuccess: true })),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  shelfRequestHistory$ = this.actions$.pipe(
    ofType<ShelfInventoryRequestListHistoryRequestAction>(
      ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_HISTORY_REQUEST
    ),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_HISTORY_REQUEST}: ` +
          JSON.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfInventoryRequestService.getHistoryLogs(payload).pipe(
        map(response => new ShelfInventoryRequestListHistoryResponseAction({ auditLogs: response.auditLogs })),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  shelfRequestDelete$ = this.actions$.pipe(
    ofType<ShelfInventoryDeleteRequestAction>(ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_DELETE_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_DELETE_REQUEST}: ` +
          JSON.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload =>
      this.shelfInventoryRequestService.deleteByRequestId(payload).pipe(
        map(() => new ShelfInventoryDeleteResponseAction({ isSuccess: true })),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
