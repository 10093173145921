import { createSelector } from '@ngrx/store';
import * as fromOrderCreateResponseState from '../reducers/order-create.reducers';
import * as fromOrderFixAssetCreateResponseState from '../reducers/order-fix-asset-create.reducers';
import { AppStates } from '../state/app.states';

const selectOrderCreateState = (state: AppStates) => state.orderCreate;
const selectOrderFixAssetCreate = (state: AppStates) => state.orderFixAssetCreate;

export const selectOrderFixAssetRequest = createSelector(
  selectOrderFixAssetCreate,
  (state: fromOrderFixAssetCreateResponseState.OrderFixAssetCreateResponseState | null) => state
);

export const selectOrderFixAssetApproveRejectStatus = createSelector(
  selectOrderFixAssetCreate,
  (state: fromOrderFixAssetCreateResponseState.OrderFixAssetCreateResponseState) => {
    return state.isApproveRejectSuccess;
  }
);

export const selectOrderRequest = createSelector(
  selectOrderCreateState,
  (state: fromOrderCreateResponseState.OrderCreateResponseState | null) => state
);

export const selectOrderApproveRejectStatus = createSelector(
  selectOrderCreateState,
  (state: fromOrderCreateResponseState.OrderCreateResponseState) => {
    return state.isApproveRejectSuccess;
  }
);
