<div id="purchase-order-list" class="admin-form popover-container mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-purchase"></em>
    <h4>{{ 'MENU.PURCHASE' | translate }}</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap d-none d-md-block">
        <app-import-export-button [buttons]="buttons" (export)="exportModal.show()"></app-import-export-button>
      </div>

      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="form-control searchCriteria"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="poStatusFilter"
            [searchable]="false"
            [clearable]="false"
            class="ng-select-width"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
            (change)="onchangeStatus($event)"
          >
          </ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter d-none d-lg-block"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Purchase Type
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="poTypeFilter"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="purchaseType"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        PO Created Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="createdDateFrom"
                          formControlName="createdDateFrom"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="createdDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="createdDateTo"
                          formControlName="createdDateTo"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="createdDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="top-search-criteria space-search-criteria" *ngIf="poTypeTag || dateStringTag">
    <div class="search-criteria" *ngIf="dateStringTag">
      <a class="remove-criteria" (click)="clearDateFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ dateStringTag }}: {{ dateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="poTypeStringTag">
      <a class="remove-criteria" (click)="clearTypeFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ poTypeStringTag }}: {{ poTypeTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="td-card" *ngFor="let result of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 cursor-pointer" (click)="goToView(result)">
          <div class="row">
            <div class="col-12 d-flex">
              <label class="chk-container">
                <app-click-to-copy [text]="result.docNo"></app-click-to-copy>
              </label>
              <span [ngClass]="getColorStatus(result.status)" class="card-status po-status ml-auto">{{
                'PURCHASE_ORDER.PURCHASE_ORDER_STATUS.' + result.status | translate
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">PR Number:</span>
              <span class="result-value">
                <div *ngFor="let prNo of result.prNos; let last = last">
                  <app-click-to-copy [text]="last ? prNo : prNo + ','"></app-click-to-copy>
                </div>
              </span>
            </div>
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Location</span>
              <span class="result-value"
                ><app-click-to-copy [text]="result.shipToCodeName | dashDisplay"></app-click-to-copy
              ></span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Purchase Type:</span>
              <span class="result-value">{{ getPurchaseType(result) }}</span>
            </div>
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Total(THB):</span>
              <span class="result-value">{{ result.totalAmount | numberFormatDisplay }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Supplier Name:</span>
              <span class="result-value">{{ result.supplierName | dashDisplay }}</span>
            </div>
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Requester:</span>
              <span class="result-value">{{ result.requester | dashDisplay }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Created Date:</span>
              <span class="result-value">{{ result.createdDate | amFromUtc | amLocal | dateDisplay }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom"
            containerClass="fl"
            [outsideClick]="true"
            *ngIf="
              permissionsUtil.determinePoPermission(result.shipToType, result.shipToCode, result.productType, [
                permissionAction.VIEW,
                permissionAction.MANAGE
              ]) ||
              permissionsUtil.determineGrPermission(result.shipToType, result.shipToCode, result.productType, [
                permissionAction.MANAGE
              ])
            "
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <div class="form-row">
              <button
                type="button"
                class="btn btn-link"
                *ngIf="
                  [purchaseOrderStatusEnum.AWAITING_GR].includes(result.status) &&
                  permissionsUtil.determineGrPermission(result.shipToType, result.shipToCode, result.productType, [
                    permissionAction.MANAGE
                  ]) &&
                  ![shipToType.STORE].includes(result.shipToType)
                "
                (click)="goToGr(result.docNo)"
              >
                <em class="icon-good-receive"></em>Goods Receive
              </button>
              <button
                type="button"
                class="btn btn-link"
                *ngIf="
                  [purchaseOrderStatusEnum.AWAITING_GR].includes(result.status) &&
                  [
                    purchaseRequestTypeEnum.WAREHOUSE,
                    purchaseRequestTypeEnum.HOT_PRICE,
                    purchaseRequestTypeEnum.SUPPLIER
                  ].includes(result.type) &&
                  permissionsUtil.determinePoPermission(result.shipToType, result.shipToCode, result.productType, [
                    permissionAction.MANAGE
                  ])
                "
                (click)="onCancelPo(result.id, result.docNo)"
              >
                <em class="icon-forbidden"></em>Cancel PO
              </button>
              <button
                type="button"
                class="btn btn-link"
                *ngIf="
                  [purchaseOrderStatusEnum.AWAITING_GR, purchaseOrderStatusEnum.GR_COMPLETED].includes(
                    result.status
                  ) &&
                  ![shipToType.STORE].includes(result.shipToType) &&
                  permissionsUtil.determinePoPermission(result.shipToType, result.shipToCode, result.productType, [
                    permissionAction.MANAGE
                  ])
                "
                (click)="onClosePo(result.id, result.docNo)"
              >
                <em class="icon-close-po"></em>Close PO
              </button>
              <button
                type="button"
                class="btn btn-link d-none d-md-block"
                *ngIf="
                  isCanPrintPDF(result.status) &&
                  (permissionsUtil.determinePoPermission(result.shipToType, result.shipToCode, result.productType, [
                    permissionAction.VIEW,
                    permissionAction.MANAGE
                  ]) ||
                    permissionsUtil.determineGrPermission(result.shipToType, result.shipToCode, result.productType, [
                      permissionAction.MANAGE
                    ]))
                "
                (click)="onPrintPdf(result)"
              >
                <em class="icon-print"></em>Print
              </button>
              <button
                type="button"
                class="btn btn-link"
                *ngIf="
                  permissionsUtil.determinePoPermission(result.shipToType, result.shipToCode, result.productType, [
                    permissionAction.VIEW,
                    permissionAction.MANAGE
                  ]) ||
                  permissionsUtil.determineGrPermission(result.shipToType, result.shipToCode, result.productType, [
                    permissionAction.MANAGE
                  ])
                "
                (click)="showHistory(result)"
              >
                <em class="icon-history"></em>History
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <!-- Modal Export -->
  <div class="modal fade" bsModal #exportModal="bs-modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Export Data</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="exportModal.hide()">
            <em class="icon-close"></em>
          </button>
        </div>

        <div class="modal-body admin-form" [formGroup]="exportForm" (ngSubmit)="onExport()">
          <div class="form-row">
            <div class="col-12 px-0">PO Created Date<span class="text-danger">*</span></div>
            <div class="form-group col-md-6 pl-0">
              <label>{{ 'FROM' | translate }}:</label>
              <input
                id="export_poCreatedDateFrom"
                formControlName="createdDateFrom"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.createdDateFrom.errors
                }"
                [bsConfig]="bsDateConfig"
                [maxDate]="createdMaxDate"
                (bsValueChange)="onChangeCreateDateFrom($event)"
                readonly
              />
              <label for="export_poCreatedDateFrom" class="icon-calendar btn-calendar"></label>
              <div *ngIf="submittedExport && exportForm.controls.createdDateFrom.errors" class="invalid-feedback">
                <div *ngIf="exportForm.controls.createdDateFrom.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group col-md-6 pr-0">
              <label>{{ 'TO' | translate }}:</label>
              <input
                id="export_poCreatedDateTo"
                formControlName="createdDateTo"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.createdDateTo.errors
                }"
                [bsConfig]="bsDateConfig"
                [minDate]="createdMinDate"
                (bsValueChange)="onChangeCreatedDateTo($event)"
                readonly
              />
              <label for="export_poCreatedDateTo" class="icon-calendar btn-calendar"></label>
              <div *ngIf="submittedExport && exportForm.controls.createdDateTo.errors" class="invalid-feedback">
                <div *ngIf="exportForm.controls.createdDateTo.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="mb-2">
              Purchase Type
            </div>
            <div class="form-group">
              <ng-select
                id="export_purchaseType"
                placeholder="Please select..."
                [items]="poTypeFilter"
                [searchable]="false"
                [clearable]="false"
                [multiple]="true"
                bindLabel="label"
                bindValue="value"
                formControlName="purchaseType"
              >
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <div class="mb-2">
              Location
            </div>
            <div class="form-group">
              <app-dropdown-multiple-select
                [parentForm]="exportForm"
                controlName="code"
                [items]="warehouseList$ | async"
                bindLabel="locationDisplay"
                bindValue="code"
              >
              </app-dropdown-multiple-select>
            </div>
          </div>
          <div class="form-group">
            <div class="mb-2">
              Status
            </div>
            <div class="form-group">
              <ng-select
                id="export_status"
                placeholder="Please select..."
                [items]="poStatusExportFilter"
                [searchable]="false"
                [clearable]="false"
                [multiple]="true"
                bindLabel="label"
                bindValue="value"
                formControlName="status"
              >
              </ng-select>
            </div>
          </div>
          <div *ngIf="errorExport" class="alert-danger form-group mb-0" role="alert">
            {{ errorExport }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="onExport()"
            [disabled]="exportFormInValid"
            id="btnExport"
          >
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
