import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { environment } from '../../../../environments/environment';
import { AssortmentAllType } from '../../../shared/models';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService, ProductAssortmentService } from '../../../shared/services';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-td-assortment-confidential',
  templateUrl: './td-assortment-confidential.component.html',
  styleUrls: ['./td-assortment-confidential.component.scss']
})
export class TdAssortmentConfidentialComponent extends BaseReportModalComponent implements OnInit, OnDestroy {
  @ViewChild('tdAssortmentConfidentialModal', { static: false }) tdAssortmentConfidentialModal: ModalDirective;

  assortmentAllType = AssortmentAllType;

  exportForm: FormGroup;
  errorExport: string;
  listOfValue = {};
  productTypeSelect: NgOption[];
  orderingMethodSelect: NgOption[];
  exportProductAssortmentStatus: NgOption[];

  private readonly types = [
    this.assortmentAllType.SEGMENT,
    this.assortmentAllType.FAMILY,
    this.assortmentAllType.CLASS,
    this.assortmentAllType.SUBCLASS
  ];

  constructor(
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    private productAssortmentService: ProductAssortmentService
  ) {
    super();
  }

  ngOnInit() {
    this.createExportForm();
    this.setDropdownDefault();
    this.loadSegmentList();
  }

  createExportForm() {
    this.exportForm = this.fb.group({
      segment: [null],
      family: [null],
      classCode: [null],
      subClass: [null],
      productType: [null],
      orderingMethods: [null],
      productStatuses: [null]
    });
  }

  loadSegmentList() {
    this.productAssortmentService
      .getSegmentListData()
      .pipe(untilComponentDestroyed(this))
      .subscribe(data => {
        this.listOfValue[this.assortmentAllType.SEGMENT] = data.data.segments;
      });
  }

  setDropdownDefault() {
    this.productTypeSelect = filterDropdown.productTypeLOV;
    this.orderingMethodSelect = filterDropdown.orderingMethodFilter;
    this.exportProductAssortmentStatus = filterDropdown.productAssortmentStatus.filter(status => status.value);
  }

  onChangeSelectDropdown($event, assortmentType: AssortmentAllType) {
    if (assortmentType) {
      this.clearFormSelectDropdown(assortmentType);
      if (this.assortmentAllType.SEGMENT === assortmentType) {
        this.listOfValue[this.assortmentAllType.FAMILY] = $event.families;
      } else if (this.assortmentAllType.FAMILY === assortmentType) {
        this.listOfValue[this.assortmentAllType.CLASS] = $event.classCodes;
      } else if (this.assortmentAllType.CLASS === assortmentType) {
        this.listOfValue[this.assortmentAllType.SUBCLASS] = $event.subClasses;
      }
    }
  }

  clearFormSelectDropdown(selectedType) {
    const associateLv = this.types.indexOf(selectedType) + 1;
    this.types
      .filter(val => associateLv <= val.length)
      .slice(associateLv)
      .forEach(typeName => {
        this.exportForm.controls[typeName].reset();
        this.listOfValue[typeName] = [];
      });
  }

  clearErrorText() {
    this.errorExport = null;
  }

  closeExportModal() {
    this.exportForm.reset();
    this.errorExport = null;
    this.tdAssortmentConfidentialModal.hide();
  }

  onExport() {
    const formData = this.exportForm.getRawValue();
    this.productAssortmentService.exportAssortmentConfidential(formData).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, this.getGeneratedFileName());
        this.resetExportForm();
        this.tdAssortmentConfidentialModal.hide();
      },
      error => {
        this.errorExport = error.error.message;
      }
    );
  }

  resetExportForm() {
    this.exportForm.reset();
    this.errorExport = null;
  }

  getGeneratedFileName() {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.exportAssortment.timeFormat);
    return `${environment.fileName.exportAssortmentConfidential.prefix}${formattedDate}.xlsx`;
  }

  openExportModal() {
    this.exportForm.reset();
    this.errorExport = null;
    this.tdAssortmentConfidentialModal.show();
  }

  ngOnDestroy(): void {}
}
