<div
  id="storeConfidentialModal"
  class="modal fade barcodeModal admin-form"
  bsModal
  #storeConfidentialModal="bs-modal"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <h4 class="modal-title pull-left">Export Store Report (Confidential)</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeExportModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body admin-form" [formGroup]="exportForm">
          <div class="form-group">
            <label>Store Type</label>
            <ng-select
              placeholder="Please select..."
              [items]="storeTypeList"
              [searchable]="false"
              [clearable]="false"
              [multiple]="true"
              bindLabel="nameTh"
              bindValue="code"
              formControlName="storeType"
            ></ng-select>
          </div>
          <div class="form-group">
            <label>Status</label>
            <ng-select
              [items]="exportStatusList"
              [searchable]="false"
              [clearable]="false"
              [multiple]="true"
              class="select-status w-100"
              bindLabel="label"
              bindValue="value"
              formControlName="status"
              placeholder="Please select..."
            ></ng-select>
          </div>
          <div class="d-block mb-3">
            <input id="firstLotOnlt" name="firstLotOnly" type="checkbox" formControlName="firstLotOnly" />
            <label for="firstLotOnlt" class="mb-0 label-text">Store Awaiting First Lot Order Only</label>
          </div>
          <div *ngIf="errorExport" class="alert-danger form-group mb-0 " role="alert">
            {{ errorExport }}
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="onExport()" id="btnExport">
            Export
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
