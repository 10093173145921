import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { round } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ShelfFixAssetService } from '../../services/shelf-fix-asset.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { ShelfSelectFixAssetOrderAddAllItem } from '../actions/shelf-select-fix-asset-order.actions';
import { ShelfSelectFixAssetActionTypes, ShelfSelectFixAssetListResponse } from '../actions/shelf-select-fix-asset.actions';
export class ShelfSelectFixAssetEffects {
    constructor(actions$, shelfFixAssetService, store, logger) {
        this.actions$ = actions$;
        this.shelfFixAssetService = shelfFixAssetService;
        this.store = store;
        this.logger = logger;
        this.shelfSelectFixAssetListRequest$ = this.actions$.pipe(ofType(ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_LIST_REQUEST), tap(action => this.logger.debug(`@Effect ${ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_LIST_REQUEST}:` +
            JSON.stringify(action.payload))), switchMap(action => this.shelfFixAssetService.searchByCriteria(action.payload).pipe(map(response => {
            return new ShelfSelectFixAssetListResponse(response);
        }), catchError(err => of(new LayoutActionLoadError(err))))));
        this.shelfSelectFixAssetGenerateShelfItemsRequest$ = this.actions$.pipe(ofType(ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_GENERATE_SHELF_ITEM_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_GENERATE_SHELF_ITEM_REQUEST}: ` +
                JSON.stringify(action));
            return action.payload;
        }), switchMap(payload => {
            const shelfSelected = payload.map(shelf => shelf.shelfNo);
            return this.shelfFixAssetService.generateShelfFixAssetItems(shelfSelected).pipe(map(response => {
                return new ShelfSelectFixAssetOrderAddAllItem({ itemList: this.mappingShelfItemOrder(response, payload) });
            }), catchError(err => of(new LayoutActionLoadError(err))));
        }), catchError(error => of(new LayoutActionLoadError(error))));
    }
    mappingShelfItemOrder(shelfItems, shelfSelectedItems) {
        shelfItems.forEach(shelf => {
            const selected = shelfSelectedItems.find(selectedItem => selectedItem.shelfNo === shelf.shelfNo);
            shelf.qty = selected ? selected.qty : 1;
            shelf.items.forEach(item => {
                item.qty = item.quantity * shelf.qty;
                item.totalVat = {
                    amount: (item.vatAmount && round(item.vatAmount.amount * item.qty, 2)) || 0,
                    currency: (item.vatAmount && item.vatAmount.currency) || 'THB'
                };
                item.amountExcVat = {
                    amount: (item.wholesalePriceExcVat && round(item.wholesalePriceExcVat.amount * item.qty, 2)) || 0,
                    currency: (item.wholesalePriceExcVat && item.wholesalePriceExcVat.currency) || 'THB'
                };
                item.amount = {
                    amount: round(item.amountExcVat.amount + item.totalVat.amount, 2) || 0,
                    currency: (item.amountExcVat && item.amountExcVat.currency) || 'THB'
                };
            });
            shelf.isAddItem = true;
        });
        return shelfItems;
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfSelectFixAssetEffects.prototype, "shelfSelectFixAssetListRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfSelectFixAssetEffects.prototype, "shelfSelectFixAssetGenerateShelfItemsRequest$", void 0);
