<div id="assortment-supplier-detail" class="admin-form" [formGroup]="supplierDetailsForm">
  <div class="row section-header mt-3 mb-2 mx-0">
    <span>Purchasing Price</span>
  </div>

  <div class="mt-3">
    <div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <label for="maximumNormalPrice"
            >Maximum Normal Price (ex VAT)/Base Unit<span class="text-danger">*</span></label
          >
          <app-numeric-textbox
            id="maximumNormalPrice"
            [maxValue]="9999999999.99"
            formControlName="maximumNormalPrice"
            [format]="'0,0.00'"
            [negativeSign]="false"
            placeholder="0.00"
            [ngClass]="{
              'is-invalid': isSubmitted && supplierDetailsForm.controls.maximumNormalPrice.errors,
              'bg-h-change': isRequestViewMode && listOfChange['maximumNormalPrice']
            }"
          ></app-numeric-textbox>
          <div *ngIf="isSubmitted && supplierDetailsForm.controls.maximumNormalPrice.errors" class="invalid-display">
            <div *ngIf="supplierDetailsForm.controls.maximumNormalPrice.errors">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <label for="tdMovingAvg">TD Moving Average Cost (ex VAT)/Base Unit</label>
          <app-numeric-textbox
            id="tdMovingAvg"
            class="input-disabled"
            [maxValue]="9999999999.99"
            formControlName="tdMovingAvg"
            [format]="'0,0.00'"
            [negativeSign]="false"
          ></app-numeric-textbox>
          <div *ngIf="isSubmitted && supplierDetailsForm.controls.tdMovingAvg.errors" class="invalid-display">
            <div *ngIf="supplierDetailsForm.controls.tdMovingAvg.errors">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="section-divider" />

    <div class="row section-header mt-3 mb-2 mx-0">
      <span
        >Supplier Price
        <span
          *ngIf="
            !!isDisplayPriceUpdatedTag &&
            [assortmentPageMode.REQUEST_VIEW].includes(mode) &&
            [assortmentRequestType.EDIT].includes(type)
          "
          class="badge badge-warning badge-position ml-1"
          >Updated</span
        >
      </span>
    </div>
    <button
      *ngIf="![assortmentPageMode.REQUEST_VIEW, assortmentPageMode.ASSORTMENT_VIEW].includes(mode)"
      class="btn btn-standard mr-2 my-3"
      type="button"
      (click)="validateTotalElement()"
    >
      <em class="icon-plus"></em> Add Supplier Price
    </button>
    <app-import-export-button
      [buttons]="buttons"
      (export)="onExportSupplierPrice()"
      (import)="checkExistingItem()"
    ></app-import-export-button>

    <div *ngIf="(listState$ | async)?.totalElements > 0 && !isManualSupplierPrice; else manualSupplierPrice">
      <div class="mt-3 table-responsive">
        <table id="storeListTable" class="table table-striped table-bordered table-hover border-none">
          <thead>
            <tr>
              <th class="text-center">No.</th>
              <th class="text-center">Location Level</th>
              <th class="text-center">Location</th>
              <th class="text-center">Supplier Name</th>
              <th class="text-center">Order Barcode</th>
              <th class="text-center">Order Unit</th>
              <th class="text-center">Unit Factor</th>
              <th class="text-center">Supplier Price (ex VAT)</th>
              <th class="text-center">AVG. Supplier Price (ex VAT)/Base Unit</th>
              <th class="text-center">Effective Date</th>
              <th class="text-center">Expire Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let result of resultList; index as i">
              <td class="text-center">
                {{ i + (currentPage - 1) * pageSize + 1 }}
              </td>
              <td class="text-left">
                {{ 'SUPPLIER_PRICE_CONFIG.LOCATION_LEVEL.' + result.locationLevelType | translate }}
              </td>
              <td class="text-left">
                {{
                  [locationLevelType.ALL_DCS, locationLevelType.BY_PROVINCE, locationLevelType.ALL_STORES].includes(
                    result.locationLevelType
                  )
                    ? result.location
                    : result.locationCode + '-' + result.locationName
                }}
              </td>
              <td class="text-left">
                {{ result.supplierName }}
                <div>
                  <span class="sub-header"
                    >Vendor No: {{ result.supplierCode }} | Branch No: {{ result.branchNo }}</span
                  >
                </div>
              </td>
              <td class="text-center">
                {{ result.barcode }}
              </td>
              <td class="text-center">
                {{ result.orderUnit }}
              </td>
              <td class="text-center">
                {{ result.unitFactor }}
              </td>
              <td class="text-right">
                {{ result.supplierPriceExVatAmount | numberFormatDisplay }}
              </td>
              <td class="text-right">
                {{ result.avgSupplierPriceExVatAmount | numberFormatDisplay }}
              </td>
              <td class="text-center">
                {{ result.effectiveDate | amFromUtc | amLocal | dateDisplay }}
              </td>
              <td class="text-center">
                {{ result.expireDate | amFromUtc | amLocal | dateDisplay }}
              </td>
            </tr>
          </tbody>
        </table>

        <!--pagination-->
        <app-search-pagination
          [currentPage]="currentPage"
          [pageSize]="pageSize"
          [listState$]="listState$"
          (changePage)="onChangePage($event)"
          (changeRowPerPage)="onChangeRowPerPage($event)"
        >
        </app-search-pagination>
      </div>
    </div>

    <ng-template #manualSupplierPrice>
      <div *ngIf="manualSupplierPriceDatas.length > 0 && isManualSupplierPrice as manualSupplierPrices">
        <div class="mt-3 table-responsive">
          <table id="manualSupplierPriceTable" class="table table-striped table-bordered table-hover border-none">
            <thead>
              <tr>
                <th class="text-center">No.</th>
                <th class="text-center">Location Level</th>
                <th class="text-center">Location</th>
                <th class="text-center">Supplier Name</th>
                <th class="text-center">Order Barcode</th>
                <th class="text-center">Order Unit</th>
                <th class="text-center">Unit Factor</th>
                <th class="text-center">Supplier Price (ex VAT)</th>
                <th class="text-center">AVG. Supplier Price (ex VAT)/Base Unit</th>
                <th class="text-center">Effective Date</th>
                <th class="text-center">Expire Date</th>
                <th
                  class="text-center"
                  *ngIf="![assortmentPageMode.REQUEST_VIEW, assortmentPageMode.ASSORTMENT_VIEW].includes(mode)"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let result of paging.currentPageData; index as i">
                <td class="text-center">
                  {{ paging.getItemNo(i) }}
                </td>
                <td class="text-left">
                  {{ 'SUPPLIER_PRICE_CONFIG.LOCATION_LEVEL.' + result.locationLevelType | translate }}
                </td>
                <td class="text-left">
                  {{
                    [locationLevelType.ALL_DCS, locationLevelType.BY_PROVINCE, locationLevelType.ALL_STORES].includes(
                      result.locationLevelType
                    )
                      ? result.location
                      : result.locationCode + '-' + result.locationName
                  }}
                </td>
                <td class="text-left">
                  {{ result.supplierName }}
                  <div>
                    <span class="sub-header"
                      >Vendor No: {{ result.supplierCode }} | Branch No: {{ result.branchNo }}</span
                    >
                  </div>
                </td>
                <td class="text-center">
                  {{ result.barcode }}
                </td>
                <td class="text-center">
                  {{ result.orderUnit }}
                </td>
                <td class="text-center">
                  {{ result.unitFactor }}
                </td>
                <td class="text-right">
                  {{ result.supplierPriceExVatAmount | numberFormatDisplay }}
                </td>
                <td class="text-right">
                  {{ result.avgSupplierPriceExVatAmount | numberFormatDisplay }}
                </td>
                <td class="text-center">
                  {{ result.effectiveDate | amFromUtc | amLocal | dateDisplay }}
                </td>
                <td class="text-center">
                  {{ result.expireDate | amFromUtc | amLocal | dateDisplay }}
                </td>
                <td
                  class="text-center position-relative"
                  *ngIf="![assortmentPageMode.REQUEST_VIEW, assortmentPageMode.ASSORTMENT_VIEW].includes(mode)"
                >
                  <a
                    href="javascript:void(0)"
                    class="btn-more mx-auto"
                    [popover]="moreTemplate"
                    placement="bottom"
                    containerClass="fl"
                    [outsideClick]="true"
                  >
                    <em class="icon-more"></em>
                  </a>
                  <ng-template #moreTemplate>
                    <button
                      type="button"
                      class="btn btn-link"
                      (click)="showSupplierPriceModal(paging.getItemNo(i) - 1)"
                    >
                      <em class="icon-edit"></em>Edit
                    </button>
                    <button type="button" class="btn btn-link" (click)="deleteSupplierPrice(paging.getItemNo(i) - 1)">
                      <em class="icon-delete"></em>Delete
                    </button>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>

          <!--pagination-->
          <app-ui-pagination
            #paging
            [currentPage]="currentPage"
            [pageSize]="pageSize"
            [items]="manualSupplierPriceDatas"
          >
          </app-ui-pagination>
        </div>
      </div>
    </ng-template>
    <!--TODO-->
  </div>
</div>
