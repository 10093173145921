import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { StockTransferPageModes } from '../../../shared/enum/stock-transfer-request.enum';
import { StockTransferStatusEnum } from '../../../shared/enum/stock-transfer.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { StockTransferViewResponse } from '../../../shared/models/stock-transfer.model';
import { AuthGuardService } from '../../../shared/services';
import {
  StockTransferCloseRequestAction,
  StockTransferViewRequestAction
} from '../../../shared/store/actions/stock-transfer.actions';
import { selectStockTransferView } from '../../../shared/store/selectors/stock-transfer.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { getDateFromString } from '../../../shared/utils/date-util';
import { StockTransferDeliveryDetailModalComponent } from '../stock-transfer-delivery-detail-modal/stock-transfer-delivery-detail-modal.component';
@Component({
  selector: 'app-stock-transfer-order-view',
  templateUrl: './stock-transfer-order-view.component.html',
  styleUrls: ['./stock-transfer-order-view.component.scss']
})
export class StockTransferOrderViewComponent extends BaseComponent implements OnInit, OnDestroy, AfterContentChecked {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Input() data: {
    docNo: string;
    title: string;
    mode: StockTransferPageModes;
  };

  public localStore: Observable<any>;
  public stockTransfer: StockTransferViewResponse;
  public currentPage = 1;
  public pageSize = 20;
  public stockTransfer$: Observable<StockTransferViewResponse>;
  public stockTransferForm: FormGroup;
  public dateTimeDisplay = environment.dateTimeDisplay;
  hasClosePermission = false;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected readonly cdr: ChangeDetectorRef,
    protected authGuardService: AuthGuardService,
    protected fb: FormBuilder
  ) {
    super(store, modalService);
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.initialStockTransfer();
  }

  ngOnDestroy(): void {}

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  // Override since list page already subscribe
  subscribeForVersionError() {}

  createForm(stockTransfer: StockTransferViewResponse) {
    this.stockTransferForm = this.fb.group({
      deliveryDate: [
        {
          value: stockTransfer.deliveryDate
            ? getDateFromString(stockTransfer.deliveryDate, 'YYYY-MM-DDTHH:mm:ss')
            : null,
          disabled: true
        }
      ],
      deliveryNote: [
        {
          value: stockTransfer.deliveryNote,
          disabled: true
        }
      ]
    });
  }

  initialStockTransfer() {
    this.store.dispatch(new StockTransferViewRequestAction(this.data.docNo));

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.stockTransfer$ = this.localStore.pipe(
      select(selectStockTransferView),
      filter(data => Boolean(data))
    );

    this.stockTransfer$.subscribe(stockTransfer => {
      this.stockTransfer = stockTransfer;
      this.createForm(stockTransfer);
      this.checkPermission(stockTransfer);
    });
  }

  onCancel() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  getColorStatus(status: string): string {
    return status ? status.toLowerCase() : '';
  }

  get stockTransferStatusEnum() {
    return StockTransferStatusEnum;
  }

  doAfterVersionAlertModal() {}

  get form(): FormGroup {
    return this.stockTransferForm;
  }

  openDeliveryDetails() {
    this.modalService.show(StockTransferDeliveryDetailModalComponent, {
      class: 'modal-md',
      backdrop: 'static',
      keyboard: false,
      initialState: {
        parentForm: this.form
      }
    });
  }

  checkPermission(stockTransfer: StockTransferViewResponse) {
    this.hasClosePermission =
      [StockTransferStatusEnum.PROCESSING, StockTransferStatusEnum.DELIVERED].includes(stockTransfer.status) &&
      this.data.mode === StockTransferPageModes.VIEW &&
      this.authGuardService.checkPermission(['tr_m']);
  }

  onCloseTo() {
    let sumDelivery = 0;
    this.stockTransfer.items.forEach(item => (sumDelivery += item.deliveredQty));
    if (sumDelivery === 0) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message: 'Not allow to close transfer order which is not dispatched.',
          okText: 'OK'
        }
      });
    } else {
      const confirmModal = this.modalService.show(ConfirmWithMessageModalComponent, {
        initialState: {
          title: 'Confirm',
          message: `Are you sure you want to Close TO Number "${this.stockTransfer.docNo}"?`,
          label: 'Reason',
          isRequiredConfirmMessage: true,
          okText: 'Yes, Close'
        }
      });

      confirmModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new StockTransferCloseRequestAction({
              id: this.stockTransfer.id,
              comment: confirmModal.content.confirmMessage
            })
          );
        }

        if (confirmModal.content.actions) {
          confirmModal.content.actions.unsubscribe();
        }
      });
    }
  }
}
