<div class="mt-3" *ngIf="!isViewMode && isShowImportShelfButton">
  <app-upload-document-button
    #importShelf
    id="importShelf"
    [allowedTypes]="'sheet'"
    [withMeta]="false"
    [multiple]="false"
    [size]="50000000"
    [allowedExt]="'xlsx'"
    [uploadUrl]="importBarcodeUrl"
    [uploadHeaders]="importHeaders"
    (itemDetail)="onLoadItemDetail($event)"
    [isExistingUpload]="formControls.length > 0"
    [text]="'Import Shelf'"
    class="d-none"
  >
  </app-upload-document-button>
</div>
<div class="mt-3" *ngIf="!isViewMode && isShowImportItemButton">
  <app-upload-document-button
    #importItem
    id="importItem"
    [allowedTypes]="'sheet'"
    [withMeta]="false"
    [multiple]="false"
    [size]="50000000"
    [allowedExt]="'xlsx'"
    [uploadUrl]="importFirstLot"
    [uploadHeaders]="importHeaders"
    (itemDetail)="onLoadFirstLotItemDetail($event)"
    [isExistingUpload]="formControls.length > 0"
    [text]="'Import Item'"
    class="d-none"
  >
  </app-upload-document-button>
</div>
<app-import-export-button
  [buttons]="buttons"
  (export)="onExport()"
  (import)="onClickImport($event)"
></app-import-export-button>

<div class="row mt-3">
  <div class="col-md-12">
    <table
      id="inventoryTable"
      class="table table-striped table-bordered w-100"
      [formGroup]="parentForm"
      *ngIf="formControls.length > 0"
    >
      <thead>
        <tr>
          <th class="text-center w-5">{{ headerRow[0] }}</th>
          <th class="text-center w-15">{{ headerRow[1] }}</th>
          <th class="text-center w-10">{{ headerRow[2] }}</th>
          <th class="text-center w-5">{{ headerRow[3] }}</th>
          <th class="text-center w-5">{{ headerRow[4] }}</th>
          <th class="text-center w-5">{{ headerRow[5] }}</th>
          <th class="text-center w-10">{{ headerRow[6] }}<span class="text-danger">*</span></th>
          <th class="text-center w-10">{{ headerRow[7] }}<span class="text-danger">*</span></th>
          <th class="text-center w-10">{{ headerRow[8] }}<span class="text-danger">*</span></th>
          <th class="text-center w-10">{{ headerRow[9] }}<span class="text-danger">*</span></th>
          <th class="text-center w-10">{{ headerRow[10] }}<span class="text-danger">*</span></th>
          <th class="text-center w-5" *ngIf="hasEditPermission">{{ headerRow[11] }}</th>
          <th class="text-center w-5" *ngIf="isShowChangesMode">{{ headerRow[12] }}</th>
        </tr>
      </thead>
      <tbody formArrayName="inventoryItem">
        <tr
          *ngFor="let item of paging.currentPageData; index as i"
          [formGroupName]="i + (currentPage - 1) * pageSize"
          [ngClass]="{
            'error-background':
              item.errors?.invalidBarcode ||
              item.errors?.isInactive ||
              item.errors?.isStatusDelisted ||
              item.errors?.isFixAssetItem ||
              item.errors?.isStoreUseItem ||
              (item.errors?.duplicated && mode !== requestPageModesEnum.REQUEST_VIEW)
          }"
        >
          <td class="text-center align-top">{{ paging.getItemNo(i) }}</td>
          <td class="text-left align-top">
            {{ item.get('productName').value }}
            <span class="d-block">{{ item.get('articleNo').value }}</span>

            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isStatusDelisted">
              {{ 'ERRORS.STATUS_IS_DELISTED' | translate }}
            </span>
            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isFixAssetItem">
              {{ 'ERRORS.NOT_ALLOW_FIX_ASSET' | translate }}
            </span>
            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isStoreUseItem">
              {{ 'ERRORS.NOT_ALLOW_STORE_USE' | translate }}
            </span>
          </td>
          <td class="text-left align-top">
            {{ item.get('barcode').value }}

            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.invalidBarcode">
              {{ 'ERRORS.INVALID_BARCODE' | translate }}
            </span>
            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isInactive">
              {{ 'ERRORS.INACTIVE_BARCODE' | translate }}
            </span>
            <span
              class="d-block invalid-feedback text-danger"
              *ngIf="item.errors?.duplicated && mode !== requestPageModesEnum.REQUEST_VIEW"
            >
              {{ 'ERRORS.DUPLICATED_BARCODE_FIELD' | translate }}
            </span>
          </td>

          <td class="text-center align-top">
            {{ item.get('unit').value }}
          </td>
          <td class="text-center align-top">
            {{ item.get('unitFactor').value }}
          </td>
          <td class="text-center align-top">
            {{ item.get('productLocation').value }}
          </td>
          <td class="text-center align-top">
            <app-numeric-textbox
              class="td-numeric-textbox-center"
              [ngClass]="{
                'is-invalid': submitted && validatorFormControls('facingQty', i),
                'd-none':
                  item.errors &&
                  !(item.errors?.required && item.errors?.isZero) &&
                  !(item.errors?.duplicated && mode === requestPageModesEnum.REQUEST_VIEW),
                'bg-h-change': hasChanges(item.get('barcode').value, 'facingQty')
              }"
              formControlName="facingQty"
              [format]="'0,0.[00]'"
              [minValue]="1"
              [maxValue]="99"
              id="facingQty"
              maxlength="2"
              (blur)="calculateGradingQty(item)"
            >
            </app-numeric-textbox>

            <div
              class="invalid-display text-danger"
              *ngIf="submitted && !item.errors && validatorFormControls('facingQty', i)"
            >
              <div *ngIf="validatorFormControls('facingQty', i)?.required">
                <span> {{ 'ERRORS.REQUIRED' | translate }} </span>
              </div>

              <div *ngIf="validatorFormControls('facingQty', i).isZero">
                <span>{{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}</span>
              </div>
            </div>
          </td>
          <td class="text-center align-top">
            <app-numeric-textbox
              class="td-numeric-textbox-center"
              [ngClass]="{
                'is-invalid': submitted && validatorFormControls('stackingQty', i),
                'd-none':
                  item.errors &&
                  !(item.errors?.required && item.errors?.isZero) &&
                  !(item.errors?.duplicated && mode === requestPageModesEnum.REQUEST_VIEW),
                'bg-h-change': hasChanges(item.get('barcode').value, 'stackingQty')
              }"
              formControlName="stackingQty"
              [format]="'0,0.[00]'"
              [minValue]="1"
              [maxValue]="99"
              id="stackingQty"
              maxlength="2"
              (blur)="calculateGradingQty(item)"
            >
            </app-numeric-textbox>

            <div
              class="invalid-display text-danger"
              *ngIf="submitted && !item.errors && validatorFormControls('stackingQty', i)"
            >
              <div *ngIf="validatorFormControls('stackingQty', i)?.required">
                <span> {{ 'ERRORS.REQUIRED' | translate }} </span>
              </div>

              <div *ngIf="validatorFormControls('stackingQty', i).isZero">
                <span>{{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}</span>
              </div>
            </div>
          </td>
          <td class="text-center align-top">
            <app-numeric-textbox
              class="td-numeric-textbox-center"
              [ngClass]="{
                'is-invalid': submitted && validatorFormControls('depthQty', i),
                'd-none':
                  item.errors &&
                  !(item.errors?.required && item.errors?.isZero) &&
                  !(item.errors?.duplicated && mode === requestPageModesEnum.REQUEST_VIEW),
                'bg-h-change': hasChanges(item.get('barcode').value, 'depthQty')
              }"
              formControlName="depthQty"
              [format]="'0,0.[00]'"
              [minValue]="1"
              [maxValue]="99"
              id="depthQty"
              maxlength="2"
              (blur)="calculateGradingQty(item)"
            >
            </app-numeric-textbox>

            <div
              class="invalid-display text-danger"
              *ngIf="submitted && !item.errors && validatorFormControls('depthQty', i)"
            >
              <div *ngIf="validatorFormControls('depthQty', i)?.required">
                <span> {{ 'ERRORS.REQUIRED' | translate }} </span>
              </div>

              <div *ngIf="validatorFormControls('depthQty', i).isZero">
                <span>{{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}</span>
              </div>
            </div>
          </td>
          <td class="text-center align-top">
            <app-numeric-textbox
              class="td-numeric-textbox-center"
              [ngClass]="{
                'is-invalid': submitted && validatorFormControls('minFirstLotQty', i),
                'd-none':
                  item.errors &&
                  !(item.errors?.required && item.errors?.isZero) &&
                  !(item.errors?.duplicated && mode === requestPageModesEnum.REQUEST_VIEW),
                'bg-h-change': hasChanges(item.get('barcode').value, 'minFirstLotQty')
              }"
              formControlName="minFirstLotQty"
              [format]="'0,0.[00]'"
              [minValue]="1"
              [maxValue]="999999"
              id="minFirstLotQty"
              maxlength="6"
            >
            </app-numeric-textbox>

            <div
              class="invalid-display text-danger"
              *ngIf="submitted && !item.errors?.invalidBarcode && validatorFormControls('minFirstLotQty', i)"
            >
              <div *ngIf="validatorFormControls('minFirstLotQty', i)?.required">
                <span> {{ 'ERRORS.REQUIRED' | translate }} </span>
              </div>

              <div *ngIf="validatorFormControls('minFirstLotQty', i).isZero">
                <span>{{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}</span>
              </div>
            </div>
          </td>
          <td class="text-center align-top">
            <app-numeric-textbox
              class="td-numeric-textbox-center"
              [ngClass]="{
                'is-invalid': submitted && validatorFormControls('minBeautyQty', i),
                'd-none':
                  item.errors &&
                  !(item.errors?.required && item.errors?.isZero) &&
                  !(item.errors?.duplicated && mode === requestPageModesEnum.REQUEST_VIEW),
                'bg-h-change': hasChanges(item.get('barcode').value, 'minBeautyQty')
              }"
              formControlName="minBeautyQty"
              [format]="'0,0.[00]'"
              [minValue]="1"
              [maxValue]="999999"
              id="minBeautyQty"
              maxlength="6"
              [allowPaste]="false"
              [negativeSign]="false"
            >
            </app-numeric-textbox>

            <div
              class="invalid-display text-danger"
              *ngIf="submitted && !item.errors?.invalidBarcode && validatorFormControls('minBeautyQty', i)"
            >
              <div *ngIf="validatorFormControls('minBeautyQty', i)?.required">
                <span> {{ 'ERRORS.REQUIRED' | translate }} </span>
              </div>

              <div *ngIf="validatorFormControls('minBeautyQty', i).isZero">
                <span>{{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}</span>
              </div>
            </div>
          </td>
          <td class="text-center align-middle" *ngIf="hasEditPermission">
            <a
              class="is-link text-muted"
              (click)="deleteItem(i)"
              title="Delete"
              appTippy
              [tippyOptions]="{ arrow: true }"
            >
              <em class="icon-delete"></em>
            </a>
          </td>
          <td class="text-center align-top" *ngIf="isShowChangesMode">
            {{ 'CHANGE_STATUS.' + changeStatus(item.get('barcode').value) | translate }}
          </td>
        </tr>
      </tbody>
    </table>

    <div>
      <app-ui-pagination
        #paging
        [(currentPage)]="currentPage"
        [(pageSize)]="pageSize"
        [items]="form.controls"
        [valueChangeDetector]="form.valueChanges | async"
      >
      </app-ui-pagination>

      <div class="clearfix"></div>
    </div>

    <div class="mt-2" *ngIf="isShowDeletedItemsButton">
      <span>
        Remark: <a class="btn-text-link d-inline-block mr-1 " (click)="showDeletedItems()">Deleted Items List</a>
      </span>
    </div>
  </div>
</div>

<div *ngIf="hasEditPermission">
  <a class="btn-text-link d-inline-block mr-1 " (click)="addNewItem()">+Select Item</a> or
  <a class="btn-text-link d-inline-block ml-1 " (click)="addNewBarcode()">+Add Barcode</a>
</div>

<app-search-product-modal #searchProductModal [productType]="productType" (addItem)="onAddItem($event)">
</app-search-product-modal>

<app-search-barcode-modal #searchBarcodeModal [allowProductType]="productType" (addItem)="onAddBarcodeItem($event)">
</app-search-barcode-modal>
