import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { NgImageSliderComponent } from 'ng-image-slider';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { LocationTypeEnum, ManageStockPageModes, MovementType } from '../../../shared/enum/manage-stock.emun';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import {
  AdjustStockItemContent,
  AdjustStockResponse,
  Image,
  ReasonDetail
} from '../../../shared/models/adjust-stock.model';
import { LocationDetail } from '../../../shared/models/manage-stock.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { AuthGuardService } from '../../../shared/services';
import { StoreService } from '../../../shared/services/store.service';
import {
  AdjustStockByIdRequestAction,
  AdjustStockByIdResetAction,
  AdjustStockItemListRequestAction,
  AdjustStockItemListResetAction
} from '../../../shared/store/actions/adjust-stock.actions';
import { AdjustStockItemState } from '../../../shared/store/reducers/adjust-stock-item.reducers';
import {
  selectAdjustItemList,
  selectAdjustItemListAll,
  selectAdjustItemListCriteria,
  selectViewAdjustStockResponse
} from '../../../shared/store/selectors/adjust-stock.selector';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-adjust-stock-view',
  templateUrl: './adjust-stock-view.component.html',
  styleUrls: ['./adjust-stock-view.component.scss']
})
export class AdjustStockViewComponent extends BaseComponent implements OnInit {
  protected localStore: Observable<any>;
  public maxDate: Date;
  public headerRow: string[];
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: ManageStockPageModes;
    docNo?: string;
    movementType: MovementType;
    locationType: LocationTypeEnum;
    location: LocationDetail;
  };

  @ViewChild('imageSlider', { static: false }) slider: NgImageSliderComponent;

  public adjustStockView$: Observable<AdjustStockResponse>;
  public resultAdjustStockItemList$: Observable<AdjustStockItemContent[]>;
  public adjustStockItemState$: Observable<AdjustStockItemState>;
  public dateFormat = environment.dateFormat;
  public dateFormatDisplay = environment.dateTimeDisplay;

  public criteriaObject: any;
  public currentPage: number;
  public pageSize: number;

  images: Array<Image> = [];

  itemList: any;

  constructor(
    protected readonly store: Store<AppStates>,
    protected modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    protected readonly storeService: StoreService
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.maxDate = new Date();
    this.headerRow = ['No.', 'Article No.', 'Product Name', 'Adjust Stock', 'Reason'];

    this.setInitialCriteriaObject();
    this.initState();
    this.search();

    this.initialData();
  }

  initState() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    if (this.data.docNo !== null) {
      this.store.dispatch(new AdjustStockByIdRequestAction(this.data.docNo));
    }

    this.localStore.pipe(select(selectAdjustItemListCriteria)).subscribe(criteriaObject => {
      this.currentPage = criteriaObject.page + 1;
    });
  }

  initialData() {
    this.adjustStockView$ = this.localStore.pipe(select(selectViewAdjustStockResponse));
    this.adjustStockItemState$ = this.localStore.pipe(select(selectAdjustItemList));
    this.resultAdjustStockItemList$ = this.localStore.pipe(select(selectAdjustItemListAll));

    this.adjustStockView$.pipe(filter(v => Boolean(v) && !!v.picturePaths)).subscribe(data => {
      const countImage = data.picturePaths.length;
      data.picturePaths.map((value, index) => {
        const no = index + 1;

        const imageObject = {
          no: no,
          reason: 'Destroy',
          image: '',
          title: `Destroy Photo (${no}/${countImage})`
        } as Image;

        this.imageSignedUrl(value, imageObject);
      });
    });

    this.resultAdjustStockItemList$.pipe(filter(v => Boolean(v))).subscribe(res => {
      res.forEach(value => {
        value.reasonDetails
          .filter(data => Boolean(data) && !!data.picturePaths)
          .forEach(reasonDetail => {
            const countImage = reasonDetail.picturePaths.length;
            reasonDetail.picturePaths.forEach((picturePath, index) => {
              const no = index + 1;
              const imageObject = {
                no: no,
                articleNo: value.articleNo,
                reason: reasonDetail.reason,
                image: '',
                title: `${reasonDetail.reason} (${no}/${countImage})`
              } as Image;

              this.imageSignedUrl(picturePath, imageObject);
            });
          });
      });
    });
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  onChangeRowPerPage(value: string) {
    this.setFirstPage();
    this.pageSize = Number(value);
    this.criteriaObject = {
      ...this.criteriaObject,
      size: Number(value),
      page: 0
    };
    this.search();
  }

  setInitialCriteriaObject() {
    this.pageSize = 20;
    this.criteriaObject = {
      page: 0,
      size: this.pageSize
    };
  }

  onChangePage(event: any) {
    this.criteriaObject = {
      ...this.criteriaObject,
      page: event.page - 1
    };
    this.search();
  }

  search() {
    this.store.dispatch(
      new AdjustStockItemListRequestAction({ docNo: this.data.docNo, adjustStockItemCriteria: this.criteriaObject })
    );
  }

  doAfterVersionAlertModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  doAfterSuccessModal() {
    this.doAfterVersionAlertModal();
  }

  onCancel() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }

  ngOnDestroy(): void {
    if (this.notifyParent) {
      this.notifyParent.unsubscribe();
    }

    this.store.dispatch(new AdjustStockByIdResetAction());
    this.store.dispatch(new AdjustStockItemListResetAction());
  }

  onShowImage(reason: string, reasonDetails: any, articleNo?: string) {
    if (!this.isImageReason(reason, reasonDetails)) {
      return;
    }

    const imageList = this.images
      .filter(value => value.articleNo === articleNo && value.reason === reason)
      .sort((a, b) => a.no - b.no);

    this.slider.setSliderImages(imageList);
    this.slider.imageOnClick(0);
  }

  onShowImageDestroy() {
    const imageList = this.images.filter(value => value.reason === 'Destroy').sort((a, b) => a.no - b.no);
    this.slider.setSliderImages(imageList);
    this.slider.imageOnClick(0);
  }

  imageSignedUrl(path, imageObject: Image): void {
    this.storeService
      .getFileUrl(path)
      .pipe(
        filter(data => Boolean(data) && !!data.signedUrl),
        map(data => data.signedUrl)
      )
      .subscribe(res => {
        imageObject.image = res;
        this.images.push(imageObject);
      });
  }

  isImageReason(reason: string, reasonDetails: ReasonDetail[]) {
    return !!reasonDetails && reasonDetails.filter(v => v.reason === reason).length;
  }

  reasonToArray(reason: string) {
    return reason.split(',');
  }
}
