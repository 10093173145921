import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models';
import {
  PosAdsCreate,
  PosAdsEdit,
  PosAdsListResponse,
  PosAdsSearchCriteria,
  PosAdsWithComment,
  PosAdvertisementListContent
} from '../../models/pos-advertisement.model';

export enum PosAdvertisementActionTypes {
  POS_DEFAULT_ADS_LIST_REQUEST = '[POS Advertisement] Default List Request',
  POS_DEFAULT_ADS_LIST_SUCCESS = '[POS Advertisement] Default List Response',
  POS_DEFAULT_ADS_RESET = '[POS Advertisement] Default Reset',
  POS_ADS_LIST_REQUEST = '[POS Advertisement] List Request',
  POS_ADS_LIST_SUCCESS = '[POS Advertisement] List Response',
  POS_ADS_LIST_ERROR = '[POS Advertisement] List Error',
  POS_ADS_RESET = '[POS Advertisement] List Reset',
  POS_ADS_CREATE_DEFAULT = '[POS Advertisement] Create Default Advertisement',
  POS_ADS_CREATE_SEASONAL = '[POS Advertisement] Create Seasonal Advertisement',
  POS_ADS_CREATE_ERROR = '[POS Advertisement] Create Error',
  POS_ADS_CREATE_ERROR_RESET = '[POS Advertisement] Create Error Reset',
  POS_ADS_EDIT_DEFAULT = '[POS Advertisement] Edit Default Advertisement',

  POS_ADS_CANCEL_REQUESTED = '[POS Advertisement] Cancel Requested',
  POS_ADS_CANCEL_SUCCESS = '[POS Advertisement] Cancel Success',

  POS_ADS_GET_BY_ID_REQUEST = '[POS Advertisement] Request POS Advertisement by id',
  POS_ADS_GET_BY_ID_RESPONSE = '[POS Advertisement] Response POS Advertisement by id',
  POS_ADS_GET_BY_ID_ERROR = '[POS Advertisement] Response POS Advertisement by id ERROR',
  POS_ADS_GET_BY_ID_RESET = '[POS Advertisement] Response POS Advertisement by id Reset'
}

export class PosDefaultAdvertisementListRequestAction implements Action {
  readonly type = PosAdvertisementActionTypes.POS_DEFAULT_ADS_LIST_REQUEST;

  constructor(public payload?: PosAdsSearchCriteria) {}
}

export class PosDefaultAdvertisementListResponseAction implements Action {
  readonly type = PosAdvertisementActionTypes.POS_DEFAULT_ADS_LIST_SUCCESS;

  constructor(public payload: PosAdvertisementListContent) {}
}

export class PosDefaultAdvertisementResetAction implements Action {
  readonly type = PosAdvertisementActionTypes.POS_DEFAULT_ADS_RESET;
}

export class PosAdvertisementListRequestAction implements Action {
  readonly type = PosAdvertisementActionTypes.POS_ADS_LIST_REQUEST;

  constructor(public payload: PosAdsSearchCriteria) {}
}

export class PosAdvertisementListResponseAction implements Action {
  readonly type = PosAdvertisementActionTypes.POS_ADS_LIST_SUCCESS;

  constructor(public payload: PosAdsListResponse) {}
}

export class PosAdvertisementListErrorAction implements Action {
  readonly type = PosAdvertisementActionTypes.POS_ADS_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class PosAdvertisementCreateDefaultAction implements Action {
  readonly type = PosAdvertisementActionTypes.POS_ADS_CREATE_DEFAULT;

  constructor(public payload: PosAdsCreate) {}
}

export class PosAdvertisementEditDefaultAction implements Action {
  readonly type = PosAdvertisementActionTypes.POS_ADS_EDIT_DEFAULT;

  constructor(public payload: PosAdsEdit) {}
}

export class PosAdvertisementCreateSeasonalAction implements Action {
  readonly type = PosAdvertisementActionTypes.POS_ADS_CREATE_SEASONAL;

  constructor(public payload: PosAdsCreate) {}
}

export class PosAdvertisementCreateErrorAction {
  readonly type = PosAdvertisementActionTypes.POS_ADS_CREATE_ERROR;

  constructor(public payload: ErrorResponse) {}
}
export class PosAdvertisementCreateErrorResetAction {
  readonly type = PosAdvertisementActionTypes.POS_ADS_CREATE_ERROR_RESET;
}

export class PosAdvertisementResetAction implements Action {
  readonly type = PosAdvertisementActionTypes.POS_ADS_RESET;

  constructor() {}
}

export class PosAdvertisementCancelRequest implements Action {
  readonly type = PosAdvertisementActionTypes.POS_ADS_CANCEL_REQUESTED;
  constructor(public payload: PosAdsWithComment) {}
}

export class PosAdvertisementCancelSuccess implements Action {
  readonly type = PosAdvertisementActionTypes.POS_ADS_CANCEL_SUCCESS;
  constructor(public payload: { isSuccess: boolean }) {}
}

export class PosAdvertisementGetByIdRequestAction {
  readonly type = PosAdvertisementActionTypes.POS_ADS_GET_BY_ID_REQUEST;

  constructor(public payload: { adsNo: string }) {}
}

export class PosAdvertisementGetByIdResponseAction {
  readonly type = PosAdvertisementActionTypes.POS_ADS_GET_BY_ID_RESPONSE;

  constructor(public payload: PosAdvertisementListContent) {}
}

export class PosAdvertisementGetByIdResetAction {
  readonly type = PosAdvertisementActionTypes.POS_ADS_GET_BY_ID_RESET;
}

export type PosAdvertisementAction =
  | PosAdvertisementGetByIdRequestAction
  | PosAdvertisementGetByIdResponseAction
  | PosAdvertisementGetByIdResetAction
  | PosAdvertisementEditDefaultAction
  | PosDefaultAdvertisementListRequestAction
  | PosDefaultAdvertisementListResponseAction
  | PosAdvertisementListRequestAction
  | PosAdvertisementListResponseAction
  | PosAdvertisementListErrorAction
  | PosAdvertisementResetAction
  | PosAdvertisementCreateDefaultAction
  | PosAdvertisementCreateSeasonalAction
  | PosAdvertisementCreateErrorAction
  | PosAdvertisementCreateErrorResetAction
  | PosAdvertisementCancelRequest
  | PosAdvertisementCancelSuccess
  | PosDefaultAdvertisementResetAction;
