import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { round } from 'lodash';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment as env } from '../../../../../environments/environment';
import { SearchBarcodeModalComponent } from '../../../../shared/components/search-barcode-modal/search-barcode-modal.component';
import { SearchProductModalComponent } from '../../../../shared/components/search-product-modal/search-product-modal.component';
import { UploadDocumentButtonComponent } from '../../../../shared/components/upload-document-button/upload-document-button.component';
import { ProductTypeEnum } from '../../../../shared/enum/product-type.enum';
import { NewRequestStatusEnum } from '../../../../shared/enum/request-status.enum';
import {
  NewRequestTypeEnum,
  RequestPageModesEnum,
  RequestProductErrorEnum
} from '../../../../shared/enum/request-step.enum';
import { ShelfPages } from '../../../../shared/enum/td-store-page.enum';
import { Classes } from '../../../../shared/gql/classes.gql';
import { ProductGradings } from '../../../../shared/gql/product-grading.gql';
import { AlertModalComponent } from '../../../../shared/layouts';
import { SimpleInfoListModalComponent } from '../../../../shared/layouts/modals/simple-info-list-modal/simple-info-list-modal.component';
import { UiPaginationComponent } from '../../../../shared/layouts/ui-pagination/ui-pagination.component';
import { BarcodeResponse } from '../../../../shared/models/barcode.model';
import { ButtonType, ImportExportButton, ImportTypeEnum } from '../../../../shared/models/import-export-button.model';
import { ShelfInventoryRequestViewResponse } from '../../../../shared/models/shelf-inventory-request.model';
import {
  DeleteShelfItems,
  FirstLotImportItem,
  InventoryImportItem,
  ListOfChanges,
  MerchandiseItem,
  ShelfRequestViewResponse
} from '../../../../shared/models/shelf-request.model';
import { AuthGuardService } from '../../../../shared/services';
import { ShelfInventoryRequestService } from '../../../../shared/services/shelf-inventory-request.service';
import { ShelfRequestService } from '../../../../shared/services/shelf-request.service';
import { AppStates } from '../../../../shared/store/state/app.states';
import { getSelectShelf, getSelectShelfPage } from '../../../../shared/utils/get-select-by-page-util';

@Component({
  selector: 'app-shelf-inventory',
  templateUrl: './shelf-inventory.component.html',
  styleUrls: ['./shelf-inventory.component.scss']
})
export class ShelfInventoryComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() parentForm: FormGroup;
  @Input() submitted: boolean;
  @Input() mode: RequestPageModesEnum;
  @Input() page: ShelfPages;
  @ViewChild('searchProductModal', { static: false }) searchProductModal: SearchProductModalComponent;
  @ViewChild('searchBarcodeModal', { static: false }) searchBarcodeModal: SearchBarcodeModalComponent;
  @ViewChild('importShelf', { static: false }) importShelf: UploadDocumentButtonComponent;
  @ViewChild('importItem', { static: false }) importItem: UploadDocumentButtonComponent;
  @ViewChild('paging', { static: false }) paging: UiPaginationComponent;

  public productType: ProductTypeEnum = ProductTypeEnum.INVENTORY;
  public headerRow: string[];
  public localStore: Observable<any>;
  public shelfRequestView$: Observable<ShelfRequestViewResponse>;
  public shelfInventoryRequestView$: Observable<ShelfInventoryRequestViewResponse>;
  public requestNo: string;
  public type: NewRequestTypeEnum;
  public status: NewRequestStatusEnum;
  public productGradings: Array<ProductGradings>;
  public classes: Array<Classes>;
  public shelfCode: string;
  public listOfChange: ListOfChanges[] = [];
  public deleteMerchandiseItem: DeleteShelfItems[];
  public importBarcodeUrl: string;
  public importFirstLot = '';
  public importHeaders;

  public currentPage = 1;
  public pageSize = 20;
  public buttons: Array<ImportExportButton>;

  constructor(
    private readonly fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    private readonly shelfRequestService: ShelfRequestService,
    private readonly shelfInventoryRequestService: ShelfInventoryRequestService,
    private readonly logger: NGXLogger,
    private readonly spinner: NgxSpinnerService,
    protected readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    private readonly authGuardService: AuthGuardService
  ) {}

  ngOnInit() {
    this.headerRow = [
      'No.',
      'Product Name',
      'Barcode',
      'Unit',
      'Unit Factor',
      'PLG',
      'Facing Qty',
      'Stacking Qty',
      'Depth Qty',
      'Min First Lot Qty',
      'Min Beauty Qty',
      'Action',
      'Change Status'
    ];

    this.initData();
    this.initState();
    this.getButton();
  }

  ngAfterViewInit(): void {
    if (this.page === ShelfPages.SHELF_REQUEST_INVENTORY || this.page === ShelfPages.SHELF_INVENTORY_EDIT) {
      this.importBarcodeUrl = `${env.serverUrl}${env.services.shelfInventoryRequest.url}${env.services.shelfInventoryRequest.importBarcodes}`;
      this.importHeaders = env.services.shelfInventoryRequest.headers;
    } else {
      this.importBarcodeUrl = `${env.serverUrl}${env.services.shelfRequest.url}${env.services.shelfRequest.importBarcodes}`;
      this.importHeaders = env.services.shelfRequest.headers;
    }
  }

  ngOnDestroy(): void {}

  getButton() {
    this.buttons = [
      {
        type: ButtonType.EXPORT,
        name: 'Export Item',
        hidden: !this.isViewMode || !this.isShowExportItemButton
      },
      {
        type: ButtonType.IMPORT,
        name: 'Import Shelf',
        by: ImportTypeEnum.SHELF,
        hidden: this.isViewMode || !this.isShowImportShelfButton
      },
      {
        type: ButtonType.IMPORT,
        name: 'Import Item',
        by: ImportTypeEnum.ITEM,
        hidden: this.isViewMode || !this.isShowImportItemButton
      }
    ];
  }

  initState() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    const formArray = this.fb.array([], this.barcodeValidator());

    this.parentForm.addControl('inventoryItem', formArray);

    if (this.mode !== RequestPageModesEnum.REQUEST_CREATE) {
      if (this.page === ShelfPages.SHELF_REQUEST_INVENTORY || this.page === ShelfPages.SHELF_INVENTORY_EDIT) {
        this.shelfInventoryRequestView$ = this.localStore.pipe(select(getSelectShelfPage(this.page)));
        this.setInventoryRequestItemsValue();
      } else {
        this.shelfRequestView$ = this.localStore.pipe(select(getSelectShelf(this.page)));
        this.setInventoryItemsValue();
      }
    }
  }

  initData() {
    this.shelfInventoryRequestService.getProductGrading().subscribe(result => {
      if (result.data) {
        this.productGradings = result.data.productGradings;
      }
    });

    this.shelfInventoryRequestService.getClasses().subscribe(result => {
      if (result.data) {
        this.classes = result.data.classes;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const mode = changes['mode'] && changes['mode'].currentValue;
    if (mode && mode === RequestPageModesEnum.REQUEST_EDIT) {
      this.toggleEditForm();
    }
  }

  get form(): FormArray {
    return this.parentForm.get('inventoryItem') as FormArray;
  }

  get formControls(): AbstractControl[] {
    return (this.parentForm.get('inventoryItem') as FormArray).controls;
  }

  setInventoryRequestItemsValue() {
    this.shelfInventoryRequestView$
      .pipe(
        untilComponentDestroyed(this),
        filter(value => Boolean(value))
      )
      .subscribe(value => {
        const params =
          this.page === ShelfPages.SHELF_REQUEST_INVENTORY
            ? `?shelfRequestId=${value.id}`
            : `?shelfNo=${value.shelfNo}`;
        this.type = value.type;
        this.status = value.status;
        this.requestNo = value.requestNo;
        this.shelfCode = value.shelfCode;
        this.deleteMerchandiseItem = value.deleteItems || [];
        this.importFirstLot = `${env.serverUrl}${env.services.shelfInventoryRequest.url}${env.services.shelfInventoryRequest.importFirstLot}${params}`;

        this.getButton();

        const merchandiseItemLength = value.items && value.items.length;

        if (merchandiseItemLength && !this.form.controls.length) {
          value.items.forEach((item, i) => {
            this.setInventoryItem(item, i);
          });

          this.form.setValidators(this.barcodeValidator());
        }

        if (this.mode === RequestPageModesEnum.REQUEST_EDIT) {
          this.toggleEditForm();
        }
      });
  }

  setInventoryItemsValue() {
    this.shelfRequestView$
      .pipe(
        untilComponentDestroyed(this),
        filter(value => Boolean(value))
      )
      .subscribe(value => {
        const params =
          this.page === ShelfPages.SHELF_REQUEST ? `?shelfRequestId=${value.id}` : `?shelfNo=${value.no}`;
        this.type = value.type;
        this.status = value.status;
        this.requestNo = value.requestNo;
        this.shelfCode = value.shelfCode;
        this.deleteMerchandiseItem = value.deleteMerchandiseItem || [];
        this.importFirstLot = `${env.serverUrl}${env.services.shelfRequest.url}${env.services.shelfRequest.importFirstLot}${params}`;

        this.getButton();

        const merchandiseItemLength = value.merchandiseItem && value.merchandiseItem.length;

        if (merchandiseItemLength && !this.form.controls.length) {
          value.merchandiseItem.forEach((item, i) => {
            this.setInventoryItem(item, i);
          });

          this.form.setValidators(this.barcodeValidator());
        }

        if (this.mode === RequestPageModesEnum.REQUEST_EDIT) {
          this.toggleEditForm();
        }
      });
  }

  setInventoryItem(item: MerchandiseItem, i: number) {
    if (this.form.at(i)) {
      this.form.at(i).patchValue(item, false);
    } else {
      this.form.push(this.createForm(item, false));
    }

    if (item.changeStatus) {
      this.listOfChange.push({
        barcode: item.barcode,
        changeStatus: item.changeStatus,
        changes: item.changes ? item.changes.map(x => x.field) : []
      });
    }
  }

  createForm(item: MerchandiseItem, isCalculateMinQty: boolean, errorMessage?: RequestProductErrorEnum) {
    const isDisabled = this.mode === RequestPageModesEnum.REQUEST_VIEW;
    const isProductError = !!errorMessage;

    const formItem: FormGroup = this.fb.group({
      barcode: item.barcode,
      classCode: item.classCode,
      articleNo: item.articleNo,
      productName: item.productName,
      productLocation: item.productLocation,
      unit: item.unit,
      unitFactor: item.unitFactor,
      productGrading: item.productGrading,
      plg: item.plg,
      facingQty: [
        { value: isProductError ? null : item.facingQty, disabled: isDisabled },
        [Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]
      ],
      stackingQty: [
        { value: isProductError ? null : item.stackingQty, disabled: isDisabled },
        [Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]
      ],
      depthQty: [
        { value: isProductError ? null : item.depthQty, disabled: isDisabled },
        [Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]
      ],
      minFirstLotQty: [
        { value: item.minFirstLotQty, disabled: true },
        [Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]
      ],
      minBeautyQty: [
        { value: item.minBeautyQty, disabled: true },
        [Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]
      ]
    });

    if (isCalculateMinQty) {
      this.calculateGradingQty(formItem);
    }

    if (errorMessage) {
      this.validateForm(formItem, errorMessage);
    }

    return formItem;
  }

  toggleEditForm() {
    this.mode = RequestPageModesEnum.REQUEST_EDIT;
    this.getButton();
    let editableFields = [];

    if (this.status === NewRequestStatusEnum.AWAITING_FIRST_LOT || this.status === NewRequestStatusEnum.ACTIVE) {
      editableFields = ['minFirstLotQty', 'minBeautyQty'];
    } else {
      editableFields = ['facingQty', 'stackingQty', 'depthQty'];
    }

    if (!this.form) {
      const formArray = this.fb.array([], this.barcodeValidator());
      this.parentForm.addControl('inventoryItem', formArray);
    }

    this.form.disable();

    this.formControls.forEach(item => {
      editableFields.forEach(field => {
        item.get(field).enable();
      });
    });
  }

  addNewItem() {
    this.searchProductModal.openSelectProductModal();
  }

  addNewBarcode() {
    this.searchBarcodeModal.selectBarcodeModal.show();
  }

  onAddItem(response: BarcodeResponse[]) {
    response.forEach(item => {
      if (!this.checkDuplicatedBarcode(item.barcode)) {
        this.form.push(
          this.createForm(MerchandiseItem.mappingBarcodeResponseToMerchandiseItem(item), false, item.errorMessage)
        );
        this.form.markAsTouched();
      } else {
        this.alertErrorModal('Not allow to add duplicated barcode.');
        return;
      }
    });
  }

  onAddBarcodeItem(response: BarcodeResponse[]) {
    response.forEach(item => {
      this.form.push(
        this.createForm(MerchandiseItem.mappingBarcodeResponseToMerchandiseItem(item), true, item.errorMessage)
      );
    });

    this.form.markAsTouched();
  }

  onLoadItemDetail(value: InventoryImportItem[]) {
    // Reset form
    this.form.clear();

    const form = new FormArray([], this.barcodeValidator());
    value.forEach(item => {
      form.push(this.createForm(this.prepareInventoryItemFromImport(item), true, item.product.errorMessage));
    });

    this.parentForm.setControl('inventoryItem', form);
    this.form.markAsTouched();
  }

  onLoadFirstLotItemDetail(value: FirstLotImportItem[]) {
    value.forEach(item => {
      const formUpdate = this.formControls.find(x => x.get('barcode').value === item.barcode);

      if (formUpdate) {
        formUpdate.get('minFirstLotQty').patchValue(item.minFirstLotQty);
        formUpdate.get('minBeautyQty').patchValue(item.minBeautyQty);

        formUpdate.updateValueAndValidity();
      }
    });
  }

  prepareInventoryItemFromImport(response: InventoryImportItem) {
    return {
      ...MerchandiseItem.mappingBarcodeResponseToMerchandiseItem(response.product),
      barcode: response.barcode,
      facingQty: response.facingQty,
      stackingQty: response.stackingQty,
      depthQty: response.depthQty,
      minFirstLotQty: null,
      minBeautyQty: null
    };
  }

  validatorFormControls(field: string, i: number) {
    const index = i + (this.currentPage - 1) * this.pageSize;
    return this.formControls[index].get(field).errors;
  }

  deleteItem(i: number) {
    const index = i + (this.currentPage - 1) * this.pageSize;
    this.form.removeAt(index);
    this.form.markAsTouched();
  }

  onExport() {
    const params = this.getParamExport();

    if (this.page === ShelfPages.SHELF_REQUEST_INVENTORY || this.page === ShelfPages.SHELF_INVENTORY_EDIT) {
      this.shelfInventoryRequestService.exportInventoryItem(params).subscribe(
        response => {
          const blob = new Blob([response]);
          saveAs(blob, this.getGeneratedFileName(Object.values(params)[0]));
        },
        error => {
          if (error.error.code === '00004') {
            this.alertErrorModal(error.error.message);
          } else {
            this.alertErrorModal(this.translate.instant(error.error.translateKey));
          }
        }
      );
    } else {
      this.shelfRequestService.exportInventoryItem(params).subscribe(
        response => {
          const blob = new Blob([response]);
          saveAs(blob, this.getGeneratedFileName(Object.values(params)[0]));
        },
        error => {
          if (error.error.code === '00004') {
            this.alertErrorModal(error.error.message);
          } else {
            this.alertErrorModal(this.translate.instant(error.error.translateKey));
          }
        }
      );
    }
  }

  onClickImport(importType: ImportTypeEnum) {
    if (importType === ImportTypeEnum.SHELF) {
      this.importShelf.checkExistingUpload();
    } else if (importType === ImportTypeEnum.ITEM) {
      this.importItem.checkExistingUpload();
    }
  }

  getParamExport() {
    let result;
    if (this.page === ShelfPages.SHELF_REQUEST || this.page === ShelfPages.SHELF_REQUEST_INVENTORY) {
      result = { requestNo: this.requestNo };
    } else {
      result = { shelfCode: this.shelfCode };
    }
    return result;
  }

  getGeneratedFileName(requestNo) {
    const date = new Date();
    const formattedDate = moment(date).format('DDMMYYYYHHmm');

    return `${requestNo}_${formattedDate}.xlsx`;
  }

  checkDuplicatedBarcode(barcode: string): boolean {
    return !!(this.formControls && this.formControls.find(x => x.get('barcode').value === barcode));
  }

  barcodeValidator(): ValidatorFn {
    return (fa: FormArray) => {
      const array = fa.getRawValue();

      const availableBarcodes = [];
      const duplicateIndexes = array
        .map((item, index) => {
          if (!availableBarcodes.includes(item.barcode)) {
            availableBarcodes.push(item.barcode);
          } else {
            return index;
          }
        })
        .filter(duplicate => duplicate);

      duplicateIndexes.forEach(duplicateIndex => {
        if (!fa.controls[duplicateIndex].errors || fa.controls[duplicateIndex].getError('duplicated')) {
          fa.controls[duplicateIndex].setErrors({ duplicated: true });
        }
      });
      return null;
    };
  }

  calculateGradingQty(formItem: AbstractControl) {
    const facingQty = formItem.get('facingQty').value;
    const stackingQty = formItem.get('stackingQty').value;
    const depthQty = formItem.get('depthQty').value;
    const classCode = formItem.get('classCode').value;

    if (
      this.isNoData(facingQty) ||
      this.isNoData(stackingQty) ||
      this.isNoData(depthQty) ||
      this.isNoData(classCode)
    ) {
      this.logger.debug('facingQty, stackingQty, depthQty or classCode is null. Cannot calculate.');

      formItem.get('minFirstLotQty').patchValue(null);
      formItem.get('minBeautyQty').patchValue(null);

      formItem.updateValueAndValidity();

      return;
    }

    const productGrading = formItem.get('productGrading').value;

    const firstLotFromGrading = this.productGradings.find(item => item.code === productGrading).firstLotPct || 0;
    const beautyFromGrading = this.productGradings.find(item => item.code === productGrading).beautyPct || 0;
    const firstLotFromClass = this.classes.find(item => item.code === classCode).firstLotPct || 0;
    const beautyFromClass = this.classes.find(item => item.code === classCode).beautyPct || 0;

    // Calculate First Lot Qty
    const firstLotDepthQty = round((firstLotFromGrading / 100) * depthQty);
    const maxFirstLotQty = firstLotDepthQty >= firstLotFromClass ? firstLotDepthQty : firstLotFromClass;
    const comparedMinFirstLot = maxFirstLotQty <= depthQty ? maxFirstLotQty : depthQty;
    const minFirstLotQty = facingQty * stackingQty * comparedMinFirstLot;

    formItem.get('minFirstLotQty').patchValue(minFirstLotQty);

    // Calculate Beauty Qty
    const beautyDepthQty = round((beautyFromGrading / 100) * depthQty);
    const maxBeautyQty = beautyDepthQty >= beautyFromClass ? beautyDepthQty : beautyFromClass;
    const comparedMinBeauty = maxBeautyQty <= depthQty ? maxBeautyQty : depthQty;
    const minBeautyQty = facingQty * stackingQty * comparedMinBeauty;

    formItem.get('minBeautyQty').patchValue(minBeautyQty);

    formItem.updateValueAndValidity();
  }

  isNoData(value) {
    return !value && value !== 0;
  }

  validateForm(formItem: AbstractControl, errorMessage: RequestProductErrorEnum) {
    switch (errorMessage) {
      case RequestProductErrorEnum.INVALID_BARCODE:
      case RequestProductErrorEnum.INACTIVE_BARCODE:
        formItem.setValidators(this.setValidator(errorMessage));
        formItem.get('barcode').setValidators(this.setValidator(errorMessage));
        break;
      case RequestProductErrorEnum.STATUS_IS_DELISTED:
      case RequestProductErrorEnum.NOT_ALLOW_FIX_ASSET:
      case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
        formItem.setValidators(this.setValidator(errorMessage));
        formItem.get('productName').setValidators(this.setValidator(errorMessage));
        break;
      default:
        break;
    }
  }

  setValidator(errorMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      switch (errorMessage) {
        case RequestProductErrorEnum.INVALID_BARCODE:
          return { invalidBarcode: true };
        case RequestProductErrorEnum.INACTIVE_BARCODE:
          return { isInactive: true };
        case RequestProductErrorEnum.STATUS_IS_DELISTED:
          return { isStatusDelisted: true };
        case RequestProductErrorEnum.NOT_ALLOW_FIX_ASSET:
          return { isFixAssetItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
          return { isStoreUseItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_ZERO:
          if (control.value !== null) {
            return control.value === 0 ? { isZero: true } : null;
          }

          return null;
        default:
          return null;
      }
    };
  }

  alertErrorModal(message: string) {
    const initialState = {
      title: 'Failed',
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  showDeletedItems() {
    const initialState = {
      title: `${this.deleteMerchandiseItem.length} Item Deleted`,
      data: this.deleteMerchandiseItem,
      columns: [
        { field: 'barcode' },
        { field: 'productName' },
        { field: 'unit', align: 'center' },
        { field: 'unitFactor', align: 'center' }
      ]
    };

    this.modalService.show(SimpleInfoListModalComponent, {
      initialState
    });
  }

  get isViewMode() {
    return this.mode === RequestPageModesEnum.REQUEST_VIEW;
  }

  get isShowDeletedItemsButton() {
    return (
      (this.page === ShelfPages.SHELF_REQUEST || this.page === ShelfPages.SHELF_REQUEST_INVENTORY) &&
      this.type === NewRequestTypeEnum.EDIT &&
      this.mode === RequestPageModesEnum.REQUEST_VIEW
    );
  }

  get isShowChangesMode() {
    return (
      (this.page === ShelfPages.SHELF_REQUEST || this.page === ShelfPages.SHELF_REQUEST_INVENTORY) &&
      this.type === NewRequestTypeEnum.EDIT &&
      (this.mode === RequestPageModesEnum.REQUEST_VIEW || this.status === NewRequestStatusEnum.AWAITING_FIRST_LOT)
    );
  }

  changeStatus(barcode: string) {
    const changeStatus = this.listOfChange.find(item => {
      return item.barcode === barcode;
    });

    return changeStatus ? changeStatus.changeStatus : 'NONE';
  }

  hasChanges(barcode: string, field: string) {
    return (
      this.isShowChangesMode &&
      this.listOfChange.find(item => {
        return item.barcode === barcode && item.changes.includes(field);
      })
    );
  }

  get isShowImportShelfButton() {
    return (
      ![NewRequestStatusEnum.AWAITING_FIRST_LOT, NewRequestStatusEnum.ACTIVE].includes(this.status) &&
      this.authGuardService.checkPermission(['shelf_m', 'sh_inv_m'])
    );
  }

  get isShowImportItemButton() {
    return (
      [NewRequestStatusEnum.AWAITING_FIRST_LOT, NewRequestStatusEnum.ACTIVE].includes(this.status) &&
      this.authGuardService.checkPermission(['shelf_firstlot_m', 'sh_inv_flq_m'])
    );
  }

  get isShowExportItemButton() {
    return (
      ((this.page === ShelfPages.SHELF_REQUEST ||
        this.page === ShelfPages.SHELF_REQUEST_INVENTORY ||
        this.page === ShelfPages.SHELF_INVENTORY_EDIT) &&
        this.status === NewRequestStatusEnum.AWAITING_FIRST_LOT) ||
      ((this.page === ShelfPages.SHELF_EDIT || this.page === ShelfPages.SHELF_INVENTORY_EDIT) &&
        this.authGuardService.checkPermission(['shelf_firstlot_m', 'shelf_v', 'sh_inv_flq_m', 'sh_inv_v']))
    );
  }

  get hasEditPermission() {
    return (
      [RequestPageModesEnum.REQUEST_CREATE, RequestPageModesEnum.REQUEST_EDIT].includes(this.mode) &&
      ![NewRequestStatusEnum.AWAITING_FIRST_LOT, NewRequestStatusEnum.APPROVED, NewRequestStatusEnum.ACTIVE].includes(
        this.status
      ) &&
      this.authGuardService.checkPermission(['shelf_m', 'shelf_firstlot_m', 'sh_inv_m', 'sh_inv_flq_m'])
    );
  }

  get requestPageModesEnum() {
    return RequestPageModesEnum;
  }
}
