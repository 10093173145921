import { Action } from '@ngrx/store';
import {
  StockTransferCancelCloseRequestData,
  StockTransferListResponse,
  StockTransferListSearchCriteria,
  StockTransferViewResponse
} from '../../models/stock-transfer.model';

export enum StockTransferActionType {
  STOCK_TRANSFER_LIST_REQUEST = '[StockTransfer] Stock Transfer List Request',
  STOCK_TRANSFER_LIST_RESPONSE = '[StockTransfer] Stock Transfer List Response',
  STOCK_TRANSFER_VIEW_REQUEST = '[StockTransfer] Get Stock Transfer View Request by ID',
  STOCK_TRANSFER_VIEW_RESPONSE = '[StockTransfer] Get Stock Transfer View Response by ID',
  STOCK_TRANSFER_CLOSE_REQUEST = '[StockTransfer] Stock Transfer Order Close Request'
}

export class StockTransferListRequestAction implements Action {
  readonly type = StockTransferActionType.STOCK_TRANSFER_LIST_REQUEST;

  constructor(public payload: StockTransferListSearchCriteria) {}
}

export class StockTransferListResponseAction implements Action {
  readonly type = StockTransferActionType.STOCK_TRANSFER_LIST_RESPONSE;

  constructor(public payload: StockTransferListResponse) {}
}

export class StockTransferViewRequestAction implements Action {
  readonly type = StockTransferActionType.STOCK_TRANSFER_VIEW_REQUEST;

  constructor(public payload: string) {}
}

export class StockTransferViewResponseAction implements Action {
  readonly type = StockTransferActionType.STOCK_TRANSFER_VIEW_RESPONSE;

  constructor(public payload: StockTransferViewResponse) {}
}

export class StockTransferCloseRequestAction implements Action {
  readonly type = StockTransferActionType.STOCK_TRANSFER_CLOSE_REQUEST;

  constructor(public payload: StockTransferCancelCloseRequestData) {}
}

export type StockTransferActions =
  | StockTransferListRequestAction
  | StockTransferListResponseAction
  | StockTransferViewRequestAction
  | StockTransferViewResponseAction
  | StockTransferCloseRequestAction;
