import { Action } from '@ngrx/store';
import { ShelfSelectedItems } from '../../models';

export enum ShelfFixAssetDetailsActionType {
  SHELF_FIX_ASSET_DETAILS_REQUEST = '[Shelf Fix Asset Details] Shelf Fix Asset Details Request',
  SHELF_FIX_ASSET__DETAILS_RESET = '[Shelf Fix Asset Details] Shelf Fix Asset Details Reset'
}

export class ShelfFixAssetDetailsRequestAction implements Action {
  readonly type = ShelfFixAssetDetailsActionType.SHELF_FIX_ASSET_DETAILS_REQUEST;

  constructor(public payload: ShelfSelectedItems[]) {}
}

export class ShelfFixAssetDetailsResetAction implements Action {
  readonly type = ShelfFixAssetDetailsActionType.SHELF_FIX_ASSET__DETAILS_RESET;
}

export type ShelfFixAssetDetailsActions = ShelfFixAssetDetailsRequestAction | ShelfFixAssetDetailsResetAction;
