/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./simple-info-list-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./simple-info-list-modal.component";
import * as i5 from "ngx-bootstrap/modal";
var styles_SimpleInfoListModalComponent = [i0.styles];
var RenderType_SimpleInfoListModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SimpleInfoListModalComponent, data: {} });
export { RenderType_SimpleInfoListModalComponent as RenderType_SimpleInfoListModalComponent };
function View_SimpleInfoListModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "th", [["scope", "col"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (_v.context.$implicit ? ("w-" + _v.context.$implicit.width) : "")); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(("INFO_LIST.FIELD." + _v.context.$implicit.field))); _ck(_v, 4, 0, currVal_1); }); }
function View_SimpleInfoListModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "d-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit["articleNo"]; _ck(_v, 1, 0, currVal_0); }); }
function View_SimpleInfoListModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "td", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleInfoListModalComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (_v.context.$implicit ? ("text-" + _v.context.$implicit.align) : "")); _ck(_v, 2, 0, currVal_0); var currVal_2 = ((_v.context.$implicit.field === "productName") && (_v.parent.context.$implicit["articleNo"] !== null)); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit[_v.context.$implicit.field]; _ck(_v, 4, 0, currVal_1); }); }
function View_SimpleInfoListModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleInfoListModalComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columns; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SimpleInfoListModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "max-height-table table-responsive"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "table", [["class", "table table-striped table-bordered table-hover w-100"], ["id", "simple-info-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "tr", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleInfoListModalComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleInfoListModalComponent_3)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columns; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.data; _ck(_v, 8, 0, currVal_1); }, null); }
function View_SimpleInfoListModalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "no-result-container text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "No data"], ["height", "240"], ["src", "/assets/images/no-item.svg"], ["width", "240"]], null, null, null, null, null))], null, null); }
export function View_SimpleInfoListModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h6", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "modal-body history-modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleInfoListModalComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noData", 2]], null, 0, null, View_SimpleInfoListModalComponent_6)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" OK "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.data.length > 0); var currVal_2 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_SimpleInfoListModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-simple-info-list-modal", [], null, null, null, View_SimpleInfoListModalComponent_0, RenderType_SimpleInfoListModalComponent)), i1.ɵdid(1, 245760, null, 0, i4.SimpleInfoListModalComponent, [i5.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SimpleInfoListModalComponentNgFactory = i1.ɵccf("app-simple-info-list-modal", i4.SimpleInfoListModalComponent, View_SimpleInfoListModalComponent_Host_0, {}, {}, []);
export { SimpleInfoListModalComponentNgFactory as SimpleInfoListModalComponentNgFactory };
