import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  ShelfInventoryList,
  ShelfInventoryListSearchCriteria,
  ShelfInventoryViewResponse
} from '../../models/shelf-inventory.model';
import { ShelfInventoryActions, ShelfInventoryActionType } from '../actions/shelf-inventory.actions';
import { BaseState } from '../state/base.state';

export interface ShelfInventoryState extends EntityState<ShelfInventoryList>, BaseState {
  selected: ShelfInventoryViewResponse;
  criteriaObject: ShelfInventoryListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<ShelfInventoryList> = createEntityAdapter<ShelfInventoryList>();

export const initialShelfResponseState: ShelfInventoryState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null
});

export function shelfInventoryReducers(
  state = initialShelfResponseState,
  action: ShelfInventoryActions
): ShelfInventoryState {
  switch (action.type) {
    case ShelfInventoryActionType.SHELF_INVENTORY_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case ShelfInventoryActionType.SHELF_INVENTORY_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case ShelfInventoryActionType.SHELF_INVENTORY_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case ShelfInventoryActionType.EDIT_SHELF_INVENTORY:
      return {
        ...state,
        selected: {
          ...state.selected,
          type: action.payload.type,
          ...(action.payload.status && { status: action.payload.status })
        }
      };
    case ShelfInventoryActionType.SHELF_INVENTORY_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case ShelfInventoryActionType.SHELF_INVENTORY_LIST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
