<div id="order-details" *ngIf="viewOrder$ | async as order">
  <div class="mt-3 table-responsive">
    <table id="datatable" class="table table-striped table-bordered table-hover border-none w-100">
      <thead>
        <tr>
          <th class="text-center align-top">No.</th>
          <th class="text-center align-top">Product Name</th>
          <th class="text-center align-top">Article</th>
          <th class="text-center align-top w-15">Delivered Qty</th>
          <th class="text-center align-top w-15">Received Qty</th>
          <th class="text-center align-top w-15">
            Remaining Qty
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of paging.currentPageData; index as i">
          <td class="text-center">{{ paging.getItemNo(i) }}</td>
          <td>{{ item.productName }}</td>
          <td class="text-left">{{ item.articleNo }}</td>
          <td class="text-center">
            {{ item.deliveredQty | dashDisplay }}
            <div class="sub-header">({{ item.orderedQty + ' ' + item.unit }})</div>
          </td>
          <td class="text-center">
            {{ item.receivedQty | dashDisplay }}
          </td>
          <td class="text-center">
            {{ order?.deliveryBy === deliveryByEnum.SUPPLIER ? item.remainQty : 0 }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-ui-pagination
    #paging
    [currentPage]="currentPage"
    [pageSize]="pageSize"
    [items]="order?.items"
    [valueChangeDetector]="viewOrder$ | async"
  >
  </app-ui-pagination>
</div>
