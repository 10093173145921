import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { SupplierStatusEnum } from '../../../shared/enum/supplier.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { HistoryType } from '../../../shared/models/audit-log.model';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { SupplierContent, SupplierPageModes, SupplierSearchCriteria } from '../../../shared/models/supplier.model';
import { AuthGuardService } from '../../../shared/services';
import { SupplierService } from '../../../shared/services/supplier.service';
import {
  SupplierActivateRequestAction,
  SupplierListRequestAction,
  SupplierResetAction
} from '../../../shared/store/actions/supplier.actions';
import { SupplierState } from '../../../shared/store/reducers/supplier.reducers';
import { selectSupplierList, selectSupplierState } from '../../../shared/store/selectors/supplier.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { SupplierEditComponent } from '../supplier-edit/supplier-edit.component';
import { SupplierImportModalComponent } from './supplier-import-modal/supplier-import-modal.component';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss']
})
export class SupplierListComponent extends BaseSearchComponent<SupplierSearchCriteria, SupplierContent, SupplierState>
  implements OnInit, OnDestroy {
  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected readonly translate: TranslateService,
    protected spinner: NgxSpinnerService,
    protected authGuardService: AuthGuardService,
    protected supplierService: SupplierService
  ) {
    super(store, modalService, selectSupplierList, selectSupplierState);
    super.subscribeForSaveSuccess();
  }

  public bsModalRef: BsModalRef;
  public localStore: Observable<any>;

  public supplierListStatusFilter: NgOption[] = filterDropdown.supplierListStatusFilter;
  public productTypeFilter = filterDropdown.productTypeLOV;
  public selectedSupplierFilter = filterDropdown.selectedSupplierFilter;

  public allowManageSupplier = false;
  public enableActiveButton = false;
  public currentSupplierContent: SupplierContent;

  public filesSubmitModal: BsModalRef;
  public errorImportList;
  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    },
    {
      type: ButtonType.IMPORT,
      name: 'Import'
    }
  ];
  public importSupplierModal: BsModalRef;

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.initControl();
    this.initState();
  }

  initControl() {
    this.buttons = [
      {
        type: ButtonType.EXPORT,
        name: 'Export'
      }
    ];
    if (this.authGuardService.checkPermission(['supplier_m'])) {
      this.buttons.push({
        type: ButtonType.IMPORT,
        name: 'Import'
      });
      this.allowManageSupplier = true;
    }
  }

  prepareSearchCriteriaTags() {}

  initState(): void {
    this.localStore
      .pipe(
        select(selectSupplierList),
        tap(() => {
          this.currentPage = this.criteriaObject.page + 1;
        })
      )
      .subscribe();
  }

  goToView(viewParams?: any) {
    console.log(viewParams);

    const initialState = {
      title: null,
      childItem: new ChildItem(
        SupplierEditComponent,
        {
          title: 'View Supplier',
          mode: SupplierPageModes.VIEW,
          supplierCode: viewParams.supplierCode
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });

    this.bsModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe(result => {
      if (ModalButtonResponseEnum.CLOSE === result) {
        // this.isShowView = false;
      }
    });
  }

  gotoActivate(item): void {
    console.log(item);

    const msg =
      item.status.toLocaleLowerCase() === 'active'
        ? 'Are you sure you want to deactivate?'
        : 'Are you sure you want to activate?';
    const data = {
      id: item.id,
      status: item.status.toLocaleLowerCase() === 'active' ? 'INACTIVE' : 'ACTIVE',
      version: item.version
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: msg
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new SupplierActivateRequestAction(data));
        }
      });
  }

  gotoEdit(viewParams?: any) {
    console.log(viewParams);

    const initialState = {
      title: null,
      childItem: new ChildItem(
        SupplierEditComponent,
        {
          title: 'Edit Supplier',
          mode: SupplierPageModes.EDIT,
          supplierCode: viewParams.supplierCode
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });

    this.bsModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe(result => {
      if (ModalButtonResponseEnum.CLOSE === result) {
        // this.isShowView = false;
      }
    });
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.supplierListStatusFilter[0].value],
      productType: [null],
      completed: [null],
      lastUpdatedDateFrom: [null],
      lastUpdatedDateTo: [null]
    });
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  onChangeStatus(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  getColorStatus(status: SupplierStatusEnum) {
    return this.getSupplierStatus(status).toLowerCase();
  }

  getSupplierStatus(status: SupplierStatusEnum | undefined) {
    return status || '';
  }

  search(criteriaObj) {
    this.store.dispatch(new SupplierListRequestAction(criteriaObj));
  }

  showHistory(supplier: SupplierContent) {
    const initialState = {
      title: 'History',
      historyHeader: `Vendor No.: ${supplier.supplierCode}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.SUPPLIER,
      auditLogs: supplier.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  setRouteTab(): void {}

  onAdvanceSubmit() {}

  openExportModal() {
    const date = new Date();
    const dateExport = moment(date).format(environment.fileName.exportSupplier.timeFormat);

    this.supplierService.exportSupplier().subscribe(
      response => this.saveFile(`${environment.fileName.exportSupplier.prefix}_${dateExport}`, response),
      error => this.alertErrorModal(error.error.message)
    );
  }

  openImportModal() {
    this.importSupplierModal = this.modalService.show(SupplierImportModalComponent, {
      backdrop: 'static',
      keyboard: false
    });
  }

  saveFile(fileName: string, response: Blob) {
    const blob = new Blob([response]);
    saveAs(blob, `${fileName}.xlsx`);
  }

  alertErrorModal(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message: message
      }
    });
  }

  doDestroy() {
    this.store.dispatch(new SupplierResetAction());
  }
}
