import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { keyBy, merge, round, values } from 'lodash';
import * as moment from 'moment-timezone';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { ArticleTypeEnum } from '../../../shared/enum/article-type.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { PermissionAction } from '../../../shared/enum/permission-action';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import { ShipToType } from '../../../shared/enum/purchase-order.enum';
import {
  PurchaseRequestModeEnum,
  PurchaseRequestStatusEnum,
  PurchaseRequestTypeEnum
} from '../../../shared/enum/purchase-request.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { UiPaginationComponent } from '../../../shared/layouts/ui-pagination/ui-pagination.component';
import { TaskModuleUrl } from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import { dayIndexList, productTypeLOV } from '../../../shared/models/list-value/list-key-value.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  ProductChange,
  PurchaseRequest,
  PurchaseRequestDeliveryDetail,
  PurchaseRequestItem,
  PurchaseRequestItemDetails,
  PurchaseRequestProductError,
  PurchaseRequestSearchCriteria,
  ShipToBillToDetail
} from '../../../shared/models/purchase-request.model';
import { OfficeWarehouse } from '../../../shared/models/warehouse.model';
import { prApprovePermissions } from '../../../shared/permissions/permissions';
import { AuthGuardService } from '../../../shared/services';
import { PurchaseRequestService } from '../../../shared/services/purchase-request.service';
import { TdAssortmentService } from '../../../shared/services/td-assortment-service';
import { TasksByRoleListRequestAction } from '../../../shared/store/actions/dashboard.actions';
import {
  PurchaseRequestApproveRequest,
  PurchaseRequestCancelRequest,
  PurchaseRequestDeleteRequest,
  PurchaseRequestDeliveryDetailReset,
  PurchaseRequestListRequest,
  PurchaseRequestRejectRequest,
  PurchaseRequestReset,
  PurchaseRequestSaveRequest,
  PurchaseRequestSubmitErrorResponse,
  PurchaseRequestSubmitRequest,
  PurchaseRequestViewRequest
} from '../../../shared/store/actions/purchase-request.actions';
import {
  PurchaseRequestItemSelectedList,
  TdAssortmentOrderAddAllItem,
  TdAssortmentOrderRemoveItem,
  TdAssortmentOrderReset,
  TdAssortmentOrderUpdateItem
} from '../../../shared/store/actions/td-assortment-order.actions';
import { TdAssortmentListReset } from '../../../shared/store/actions/td-assortment.actions';
import { selectUserInfoResult } from '../../../shared/store/selectors/auth-user-info.selector';
import {
  selectEditedPurchaseRequest,
  selectPurchaseRequestCriteria,
  selectPurchaseRequestErrorResponse
} from '../../../shared/store/selectors/purchaser-request.selectors';
import { selectAllTdAssortmentOrder } from '../../../shared/store/selectors/td-assortment-order.selectors';
import { selectWarehouseMaster } from '../../../shared/store/selectors/warehouse-master.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  convertBkkToUtc,
  convertUtcToBkk,
  formatDateStartOfDay,
  getDateFromString
} from '../../../shared/utils/date-util';
import { cloneAbstractControl } from '../../../shared/utils/form-util';
import { roundTo2DecimalPlace, roundToDecimalPlace } from '../../../shared/utils/number-util';
import { PermissionsUtil } from '../../../shared/utils/permissions-util';
import { calculateVatAmount } from '../../../shared/utils/vat-util';
import { ProductChangeModalComponent } from '../product-change-modal/product-change-modal.component';
import { PurchaseRequestDeliveryDetailsComponent } from '../purchase-request-delivery-details/purchase-request-delivery-details.component';
import { PurchaseRequestModalUploadComponent } from '../purchase-request-modal-upload/purchase-request-modal-upload.component';
import { TdAssortmentListComponent } from '../td-assortment-list/td-assortment-list.component';
import { VatDetailsModalComponent } from '../vat-details-modal/vat-details-modal.component';

@Component({
  selector: 'app-purchase-request-view',
  templateUrl: './purchase-request-view.component.html',
  styleUrls: ['./purchase-request-view.component.scss']
})
export class PurchaseRequestViewComponent extends BaseComponent implements OnInit, OnDestroy {
  public dateTimeDisplay = environment.dateTimeDisplay;

  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly translate: TranslateService,
    protected purchaseRequestService: PurchaseRequestService,
    protected authGuardService: AuthGuardService,
    protected cdRef: ChangeDetectorRef,
    protected tdAssortmentService: TdAssortmentService,
    public permissionsUtil: PermissionsUtil
  ) {
    super(store, modalService, false);
  }

  get form(): FormGroup {
    return this.purchaseRequestForm;
  }

  get getItemForm(): FormArray {
    return this.form.get('items') as FormArray;
  }

  get isZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null) {
        return control.value === 0 ? { isZero: true } : null;
      }
      return null;
    };
  }

  get unitPriceSubmitValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control.parent && control.value !== null && this.itemDetailsError.length > 0) {
        const errorUnitPrice = this.itemDetailsError.find(
          error => error.itemNo === (control.parent as FormGroup).controls.itemNo.value
        );
        if (errorUnitPrice && (control.parent as FormGroup).controls.submitError.value) {
          const unitPriceErrorObject = errorUnitPrice.errors.find(error => error.field === 'unitPrice');
          const node = this.getFieldNameErrorCodeMapping(unitPriceErrorObject);
          return { [node]: unitPriceErrorObject.messageDisplay };
        }
        return null;
      }
      return null;
    };
  }

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() titleOutput: EventEmitter<string> = new EventEmitter<string>();
  @Output() notifyLeaveFormOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() data: {
    prNo: string;
    status: PurchaseRequestStatusEnum;
    mode: PurchaseRequestModeEnum;
    type: PurchaseRequestTypeEnum;
    shipTo?: OfficeWarehouse;
    shipToType?: string;
    shipToCode: string;
    wmsCode: string;
    title: string;
    originPage?: string;
  };

  searchTerm: string;

  originalPrItem: PurchaseRequestItem[];

  private localStore: Observable<any>;
  purchaseRequest: PurchaseRequest;
  shipTo: ShipToBillToDetail;
  defaultDeliveryDetail: PurchaseRequestDeliveryDetail;
  purchaseRequestForm: FormGroup;
  addFreeItemForm: FormGroup;
  isSubmit: boolean;
  advanceScheduleDayIndex: number;
  advanceScheduleDayList: NgOption[];
  productTypeList: NgOption[];
  purchaseRequestCriteria: PurchaseRequestSearchCriteria;

  purchaseRequest$: Observable<PurchaseRequest>;
  productSearchLoading = false;
  productSearchInput$ = new Subject<string>();
  expandFreeItemArticleNo: string = null;
  public dayIndex: number = null;
  freeItemProductList$: Observable<[]>;
  @ViewChild('modalAdvanceSchedule', { static: false }) modalAdvanceSchedule: ModalDirective;
  @ViewChild('modalProductType', { static: false }) modalProductType: ModalDirective;
  purchaseRequestModeEnum = PurchaseRequestModeEnum;
  purchaseRequestStatusEnum = PurchaseRequestStatusEnum;
  purchaseRequestTypeEnum = PurchaseRequestTypeEnum;
  shipToType = ShipToType;
  hasMangeMerchandisePrPermission = false;
  hasMangeNonMerchandisePrPermission = false;
  hasMangeNonMerchandisePrPermissionFixedAssets = false;
  hasMangeNonMerchandisePrPermissionStoreUsed = false;
  articleTypeEnum = ArticleTypeEnum;
  productTypeEnum = ProductTypeEnum;
  selectedProductType: ProductTypeEnum;
  permissionAction = PermissionAction;

  public allowApprovePr = prApprovePermissions;
  warehouseList: any[] = null;

  public buttons: Array<ImportExportButton>;

  public purchaseRequestModalUpload: BsModalRef;

  importResult: { title: string; table: any[]; message: string };
  @ViewChild('importResultModel', { static: false }) importResultModel: ModalDirective;
  @ViewChild('paging', { static: false }) paging: UiPaginationComponent;

  currentPageDatas: FormGroup[];
  currentPage: number;
  pageSize: number;
  private itemDetailsError: PurchaseRequestItemDetails[] = [];
  public uploadUrl: string;

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.initialPrData();
    this.initialSubscription();
    this.getButton();

    // Current searchCriteria
    this.localStore.pipe(select(selectPurchaseRequestCriteria), distinctUntilChanged()).subscribe(criteria => {
      this.purchaseRequestCriteria = criteria;
    });

    this.addFreeItemForm = this.fb.group({
      refArticleNo: null,
      refBarcode: null,
      articleNo: null,
      barcode: null,
      productName: null,
      orderUnit: null,
      quantity: [null, [this.isZeroValidator, Validators.required]],
      vatPct: null,
      supplierCode: null,
      supplierName: null,
      isAddFreeItem: false,
      supplierArticleNo: null,
      unitFactor: null
    });

    this.currentPage = 1;
    this.pageSize = 20;
  }

  filterFormItem() {
    if (!this.searchTerm) {
      return;
    }
    const assortmentArr = this.originalPrItem.filter(prItem => {
      return (
        prItem['articleNo'].includes(this.searchTerm) ||
        prItem['barcode'].includes(this.searchTerm) ||
        prItem['productName'].includes(this.searchTerm) ||
        prItem['supplierName'].includes(this.searchTerm)
      );
    });
    this.createNewProductForm(assortmentArr);
  }

  clearLastKeyUpSearchText(event) {
    if (!event.target.value) {
      this.createNewProductForm(this.mergeFormItem());
    }
  }

  clearSearchText() {
    this.searchTerm = '';
    this.createNewProductForm(this.mergeFormItem());
  }

  createNewProductForm(productItem) {
    this.currentPage = 1;
    this.clearAllItemForm();
    requestAnimationFrame(() => {
      const newProductFormArray = this.createItemForm(productItem);
      newProductFormArray.controls.forEach(newProductFormGroup => {
        this.getItemForm.push(newProductFormGroup);
      });
      this.createFreeItem(productItem);
    });
  }

  mergeFormItem() {
    return values(merge(keyBy(this.originalPrItem, 'itemNo'), keyBy(this.getItemForm.getRawValue(), 'itemNo')));
  }

  determinePermissions() {
    // Generate product type list by user permissions
    let productTypeInventory = [];
    let productTypeAsset = [];
    let productTypeStore = [];
    if (
      this.purchaseRequest.shipToCode &&
      this.permissionsUtil.checkPermissionByLocation(['pr_inv_m_$1'], this.purchaseRequest.shipToCode)
    ) {
      this.hasMangeMerchandisePrPermission = true;
      productTypeInventory = productTypeLOV.filter(option => option.value === this.productTypeEnum.INVENTORY);
    }
    if (
      this.purchaseRequest.shipToCode &&
      this.permissionsUtil.checkPermissionByLocation(['pr_ast_m_$1'], this.purchaseRequest.shipToCode)
    ) {
      this.hasMangeNonMerchandisePrPermissionFixedAssets = true;
      productTypeAsset = productTypeLOV.filter(option => option.value === this.productTypeEnum.FIX_ASSET);
    }
    if (
      this.purchaseRequest.shipToCode &&
      this.permissionsUtil.checkPermissionByLocation(['pr_sto_m_$1'], this.purchaseRequest.shipToCode)
    ) {
      this.hasMangeNonMerchandisePrPermissionStoreUsed = true;
      productTypeStore = productTypeLOV.filter(option => option.value === this.productTypeEnum.STORE_USE);
    }
    this.hasMangeNonMerchandisePrPermission =
      this.hasMangeNonMerchandisePrPermissionFixedAssets || this.hasMangeNonMerchandisePrPermissionStoreUsed;
    this.productTypeList = [...productTypeInventory, ...productTypeAsset, ...productTypeStore];
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.data.originPage && this.data.originPage === TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new TasksByRoleListRequestAction());
    } else {
      this.store.dispatch(new PurchaseRequestListRequest(this.purchaseRequestCriteria));
    }

    this.store.dispatch(new PurchaseRequestReset());
    this.store.dispatch(new PurchaseRequestDeliveryDetailReset());
    this.store.dispatch(new TdAssortmentOrderReset());
    this.store.dispatch(new TdAssortmentListReset());
    if (this.notifyLeaveFormOutput) {
      this.notifyLeaveFormOutput.unsubscribe();
    }
  }

  initialPrData() {
    this.purchaseRequest = {
      id: null,
      type: this.data.type || null,
      shipToCode: (this.data.shipTo && this.data.shipTo.code) || null,
      shipToName: (this.data.shipTo && this.data.shipTo.nameEn) || null,
      wmsCode: (this.data.shipTo && this.data.shipTo.wmsCode) || null,
      shipToCodeName:
        this.data.shipTo && this.data.shipTo.code && this.data.shipTo.nameEn
          ? `${this.data.shipTo.wmsCode}-${this.data.shipTo.nameEn}`
          : null,
      shipToType: ShipToType.WAREHOUSE,
      advance: false,
      status: PurchaseRequestStatusEnum.DRAFT,
      orderScheduleDayOfWeek: null,
      requestBuyItems: 0,
      requestFreeItems: 0,
      requesterName: null,
      requestedDate: new Date(),
      totalAmount: 0,
      subTotalAmount: 0,
      docNo: null,
      docDate: null,
      vatAmount: 0,
      version: null,
      items: []
    } as PurchaseRequest;

    if ([PurchaseRequestTypeEnum.WAREHOUSE, PurchaseRequestTypeEnum.SUPPLIER].includes(this.data.type)) {
      this.uploadUrl = `${this.tdAssortmentService.getUrlImport()}?purchaseType=${this.data.type}&shipToCode=${
        this.purchaseRequest.shipToCode
      }`;
    }

    if (this.data.mode === PurchaseRequestModeEnum.CREATE) {
      this.store.pipe(untilComponentDestroyed(this), select(selectUserInfoResult), take(1)).subscribe(userInfo => {
        this.purchaseRequest = {
          ...this.purchaseRequest,
          requesterName: userInfo.displayName
        };
      });

      if ([PurchaseRequestTypeEnum.Z9, PurchaseRequestTypeEnum.Z9_EDIT].includes(this.data.type)) {
        this.initialZ9Pr();
      }
      this.initialDataForTdAssortmentPR();
    } else if ([PurchaseRequestModeEnum.EDIT, PurchaseRequestModeEnum.VIEW].includes(this.data.mode)) {
      this.store.dispatch(new PurchaseRequestViewRequest(this.data.prNo));
      this.purchaseRequest$ = this.localStore.pipe(select(selectEditedPurchaseRequest));
      this.purchaseRequest$.subscribe(value => {
        if (value) {
          this.purchaseRequest = { ...this.purchaseRequest, ...value };
          if ([PurchaseRequestTypeEnum.Z9, PurchaseRequestTypeEnum.Z9_EDIT].includes(this.data.type)) {
            this.initialZ9Pr();
          }
          this.store.dispatch(new PurchaseRequestItemSelectedList(this.purchaseRequest.items));
          this.initialDataForTdAssortmentPR();

          if (this.data.mode === PurchaseRequestModeEnum.EDIT) {
            this.doCheckProductChanged(value.items);
          }
          this.selectedProductType = value.productType;
        }
      });
    }
  }

  getButton() {
    this.buttons = [
      {
        type: ButtonType.IMPORT,
        name: 'Import',
        hidden:
          [PurchaseRequestModeEnum.VIEW].includes(this.data.mode) ||
          [PurchaseRequestStatusEnum.AWAITING_APPROVAL].includes(this.purchaseRequest.status) ||
          ![
            PurchaseRequestTypeEnum.Z9,
            PurchaseRequestTypeEnum.Z9_EDIT,
            PurchaseRequestTypeEnum.WAREHOUSE,
            PurchaseRequestTypeEnum.SUPPLIER
          ].includes(this.data.type) ||
          !this.purchaseRequestForm
      }
    ];
  }

  initialDataForTdAssortmentPR() {
    this.localStore
      .pipe(
        select(selectWarehouseMaster),
        filter(warehouseMaster => warehouseMaster !== null)
      )
      .subscribe(warehouseMaster => {
        this.warehouseList = warehouseMaster;
      });

    this.localStore
      .pipe(select(selectAllTdAssortmentOrder))
      .pipe(
        filter(productList => {
          return this.data.mode === PurchaseRequestModeEnum.CREATE || (productList && productList.length > 0);
        })
      )
      .subscribe(productList => {
        if (!this.data.shipTo && this.warehouseList) {
          this.data.shipTo = this.warehouseList.find(warehouse => warehouse.code === this.purchaseRequest.shipToCode);
        }

        if (productList) {
          const shipTo = {
            id: this.data.shipTo && this.data.shipTo.code ? this.data.shipTo.code : null,
            name: this.data.shipTo && this.data.shipTo.fullNameTh ? this.data.shipTo.fullNameTh : null,
            address: this.data.shipTo && this.data.shipTo.address ? this.data.shipTo.address : null,
            contactPersonName: this.data.shipTo && this.data.shipTo.contactName ? this.data.shipTo.contactName : null,
            contactPersonNumber:
              this.data.shipTo && this.data.shipTo.contactNumber ? this.data.shipTo.contactNumber : null,
            fullNameEn: this.data.shipTo && this.data.shipTo.fullNameEn ? this.data.shipTo.fullNameEn : null,
            fullNameTh: this.data.shipTo && this.data.shipTo.fullNameTh ? this.data.shipTo.fullNameTh : null
          } as ShipToBillToDetail;
          const billTo = {
            id: this.data.shipTo && this.data.shipTo.billTo.code ? this.data.shipTo.billTo.code : null,
            name: this.data.shipTo && this.data.shipTo.billTo.nameEn ? this.data.shipTo.billTo.nameEn : null,
            address: this.data.shipTo && this.data.shipTo.billTo.address ? this.data.shipTo.billTo.address : null,
            contactPersonName:
              this.data.shipTo && this.data.shipTo.billTo.contactName ? this.data.shipTo.billTo.contactName : null,
            contactPersonNumber:
              this.data.shipTo && this.data.shipTo.billTo.contactNumber ? this.data.shipTo.billTo.contactNumber : null
          } as ShipToBillToDetail;

          this.defaultDeliveryDetail = {
            ...this.defaultDeliveryDetail,
            deliveryNote: null,
            requestDeliveryDate: null,
            shipTo,
            billTo
          };
        }
        this.purchaseRequest = {
          ...this.purchaseRequest,
          items: productList,
          requestBuyItems: productList.length
        };

        if (!this.form) {
          this.createForm(this.purchaseRequest);
        } else {
          this.form.get('requestBuyItems').patchValue(productList.length);
          const productArray = [];
          productList.forEach(product => {
            const prItemFormGroup = this.getCurrentPRItemFromGroup(
              'supplierCodeAndBarcode',
              `${product.supplierCode}_${product.barcode}`
            );
            if (prItemFormGroup && prItemFormGroup.controls.quantity.value !== product.quantity) {
              prItemFormGroup.patchValue({ quantity: product.quantity });
              this.form.markAsTouched();
            } else if (!prItemFormGroup) {
              productArray.push(product);
            }
          });
          if (productArray.length > 0) {
            const newProductFormArray = this.createItemForm(
              productArray,
              this.purchaseRequest.status === this.purchaseRequestStatusEnum.DRAFT
                ? this.getItemForm.controls.length
                : null
            );
            newProductFormArray.controls.forEach(newProductFormGroup => {
              this.getItemForm.push(newProductFormGroup);
            });
            this.form.markAsTouched();
          }
        }
        this.calculateTotalAmount();
        this.summaryFreeItems();
        this.determinePermissions();

        // }
      });
  }

  summaryFreeItems() {
    let summaryFreeItem = 0;
    this.getItemForm.controls.forEach(prItem => {
      if (!prItem.get('isReject').value) {
        summaryFreeItem += this.getFreeItem(prItem as FormGroup).length;
      }
    });
    this.purchaseRequest = {
      ...this.purchaseRequest,
      requestFreeItems: summaryFreeItem
    };
  }

  initialSubscription() {
    this.localStore.pipe(select(selectPurchaseRequestErrorResponse)).subscribe(error => {
      let isChange = false;
      let isInvalidData = false;
      if (error) {
        if (error.code === '08009') {
          const alertModal = this.modalService.show(AlertModalComponent, {
            initialState: {
              title: 'Failed',
              message: 'Not allow to submit because order schedule is invalid.'
            }
          });

          alertModal.content.action
            .pipe(untilComponentDestroyed(this))
            .subscribe((result: ModalButtonResponseEnum) => {
              if (result === ModalButtonResponseEnum.OK) {
                alertModal.hide();
                this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
              }
            });
        } else if (error.code === '08010') {
          error.items.forEach((itemErrorDetails: PurchaseRequestItemDetails) => {
            this.itemDetailsError.push(itemErrorDetails);
            if (itemErrorDetails.errors && itemErrorDetails.errors.length) {
              isInvalidData = true;
            }

            if (itemErrorDetails.changes && itemErrorDetails.changes.length) {
              isChange = true;
            }

            if (
              (itemErrorDetails.errors && itemErrorDetails.errors.length) ||
              (itemErrorDetails.changes && itemErrorDetails.changes.length)
            ) {
              const prItemFormGroup = this.getCurrentPRItemFromGroup('itemNo', itemErrorDetails.itemNo);
              if (itemErrorDetails.errors && itemErrorDetails.errors.length && prItemFormGroup) {
                itemErrorDetails.errors.forEach((itemError: PurchaseRequestProductError) => {
                  const node = this.getFieldNameErrorCodeMapping(itemError);
                  if (node) {
                    setTimeout(() => {
                      prItemFormGroup.controls[node].setErrors({ [node]: itemError.messageDisplay });
                      prItemFormGroup.controls[node].markAsDirty();
                    });
                    prItemFormGroup.patchValue({
                      submitError: true
                    });
                  }
                });
              }

              if (itemErrorDetails.changes && itemErrorDetails.changes.length && prItemFormGroup) {
                prItemFormGroup.patchValue({
                  productChanges: this.transformProductChanges(itemErrorDetails.changes)
                });

                // Update Change
                itemErrorDetails.changes.forEach((change: ProductChange) => {
                  if (change.field === 'unitPrice') {
                    prItemFormGroup.patchValue({
                      subTotalAmount: roundTo2DecimalPlace(change.after * prItemFormGroup.get('quantity').value),
                      totalVatAmount: calculateVatAmount(
                        change.after,
                        prItemFormGroup.get('quantity').value,
                        prItemFormGroup.get('vatPct').value,
                        prItemFormGroup.get('productVat').value,
                        prItemFormGroup.get('supplierVat').value
                      ),
                      vatAmount: roundTo2DecimalPlace((change.after * prItemFormGroup.get('vatPct').value) / 100),
                      newUnitPrice: round(change.after, 2),
                      unitPrice: round(change.after, 2)
                    });
                  } else if (change.field === 'barcode' || change.field === 'supplierName') {
                    prItemFormGroup.get(change.field).setValue(change.after);
                    const supplierCodeAndBarcode = `${prItemFormGroup.controls.supplierCode.value}_${prItemFormGroup.controls.barcode.value}`;
                    prItemFormGroup.controls.supplierCodeAndBarcode.setValue(supplierCodeAndBarcode);
                  } else {
                    prItemFormGroup.get(change.field).setValue(change.after);
                  }
                });
              }
            }
          });

          this.store.dispatch(new TdAssortmentOrderReset());
          this.calculateTotalAmount();

          this.modalService.show(ProductChangeModalComponent, {
            initialState: {
              isChange,
              isInvalidData
            }
          });
        }

        // Reset Error Response
        this.store.dispatch(new PurchaseRequestSubmitErrorResponse(null));
      }
    });
  }

  getFieldNameErrorCodeMapping(itemError: PurchaseRequestProductError) {
    let fieldName = null;
    switch (itemError.code) {
      case '08011':
        fieldName = 'productName';
        itemError.messageDisplay = `Not allow to buy! (Product Status: ${this.translate.instant(
          'PRODUCT_ASSORTMENT.PRODUCT_STATUS.' + itemError.message
        )})`;
        break;
      case '08012':
        fieldName = 'articleNo';
        itemError.messageDisplay = 'Invalid Article No.';
        break;
      case '08013':
        fieldName = 'supplierName';
        itemError.messageDisplay = 'Invalid Supplier.';
        break;
      case '08014':
        fieldName = 'unitPrice';
        itemError.messageDisplay = 'Invalid Unit Price.';
        break;
      case '08015':
        fieldName = 'barcode';
        itemError.messageDisplay = 'Invalid Barcode.';
        break;
      case '08016':
        fieldName = 'unitPrice';
        itemError.messageDisplay = 'Invalid Unit Price. Price is not in cost range.';
        break;
      default:
        if (itemError.field === 'supplier') {
          itemError.field = 'supplierName';
        }
        fieldName = itemError.field;
        itemError.messageDisplay = itemError.message;
        break;
    }
    return fieldName;
  }

  getCurrentPRItemFromGroup(fieldName: string, currentItemValue: any): FormGroup {
    let prItemFormGroup = null;
    if (this.getItemForm) {
      this.getItemForm.controls.forEach(itemForm => {
        const fieldValue = itemForm.get(fieldName).value;
        if (fieldValue === currentItemValue) {
          prItemFormGroup = itemForm;
        }
      });
    }
    return prItemFormGroup;
  }

  createForm(purchaseRequest: PurchaseRequest) {
    this.purchaseRequestForm = this.fb.group({
      selectedAll: true,
      requestBuyItems: 0,
      totalAmt: 0,
      subTotalAmt: 0,
      totalVatAmt: 0,
      items: this.createItemForm(purchaseRequest.items, null, true)
    });

    this.createFreeItem(purchaseRequest.items);
  }

  createFreeItem(items) {
    items.forEach(item => {
      if (item.freeItems && item.freeItems.length > 0) {
        item.freeItems.forEach(freeItem => {
          /** Change search by article number to supplierCode and barcode **/
          const prItemFormGroup = this.getCurrentPRItemFromGroup(
            'supplierCodeAndBarcode',
            `${item.supplierCode}_${item.barcode}`
          );
          if (prItemFormGroup) {
            this.initFreeItem(freeItem, prItemFormGroup);
          }
        });
      }
    });
  }

  createItemForm(prItems: PurchaseRequestItem[], startIndex: number = null, isItemFromOriginal = false): FormArray {
    const prItemForms = new FormArray([]);
    if (isItemFromOriginal) {
      this.originalPrItem = [...prItems];
    }
    prItems.forEach((item, index) => {
      const prItemFormGroup = this.fb.group({
        itemNo: item.itemNo ? item.itemNo : startIndex ? ++startIndex : ++index,
        articleNo: item.articleNo,
        productName: item.productName,
        barcode: item.barcode,
        supplierName: item.supplierName,
        supplierCode: item.supplierCode,
        orderUnit: item.orderUnit,
        minimumOrder: item.minimumOrder,
        unitPrice: [PurchaseRequestTypeEnum.HOT_PRICE, PurchaseRequestTypeEnum.Z9_EDIT].includes(this.data.type)
          ? [
              {
                value: item.newUnitPrice ? item.newUnitPrice : item.unitPrice,
                disabled: this.data.mode === PurchaseRequestModeEnum.VIEW
              },
              [this.isZeroValidator, Validators.required, this.unitPriceSubmitValidator]
            ]
          : [item.unitPrice, [this.isZeroValidator, Validators.required]],
        newUnitPrice: item.newUnitPrice,
        quantity: [
          {
            value: item.quantity,
            disabled: [PurchaseRequestModeEnum.VIEW, PurchaseRequestModeEnum.PARTIAL_APPROVE].includes(this.data.mode)
          },
          [this.isZeroValidator, Validators.required]
        ],
        totalAmount: item.totalAmount
          ? item.totalAmount
          : round(
              item.quantity * item.unitPrice +
                calculateVatAmount(item.unitPrice, item.quantity, item.vatPct, item.productVat, item.supplierVat),
              2
            ),
        subTotalAmount: item.subTotalAmount === null || item.subTotalAmount === undefined ? 0 : item.subTotalAmount,
        vatAmount: item.vatAmount,
        totalVatAmount: calculateVatAmount(
          item.unitPrice,
          item.quantity,
          item.vatPct,
          item.productVat,
          item.supplierVat
        ),
        vatPct: item.vatPct,
        selected:
          [PurchaseRequestTypeEnum.Z9, PurchaseRequestTypeEnum.Z9_EDIT].includes(this.data.type) &&
          this.data.mode === PurchaseRequestModeEnum.CREATE
            ? true
            : item.selected,
        productChanges: this.transformProductChanges(item.productChanges),
        freeItems: new FormArray([]),
        deliveryDetail: this.createDeliveryDetailForm(item.deliveryDetail, this.defaultDeliveryDetail),
        isReject: (item.status && item.status === 'REJECTED') || item.isReject === true,
        segmentCode: item.segmentCode,
        familyCode: item.familyCode,
        classCode: item.classCode,
        subClassCode: item.subClassCode,
        lotSize: item.lotSize,
        supplierCodeAndBarcode: `${item.supplierCode}_${item.barcode}`,
        submitError: null,
        productVat: item.productVat,
        supplierVat: item.supplierVat,
        supplierArticleNo: item.supplierArticleNo,
        vendorDcCode: item.vendorDcCode,
        vendorDcName: item.vendorDcName,
        vendorDcCodeName:
          item.vendorDcCode && item.vendorDcName
            ? `${item.vendorDcCode}-${item.vendorDcName}`
            : item.vendorDcCode && !item.vendorDcName
            ? item.vendorDcCode
            : !item.vendorDcCode && item.vendorDcName
            ? item.vendorDcName
            : null,
        supplierBranchNo: item.supplierBranchNo ? item.supplierBranchNo : ''
      });
      this.updateValueChangesEvent(prItemFormGroup);

      prItemForms.push(prItemFormGroup);
    });
    return prItemForms;
  }

  createDeliveryDetailForm(deliveryDetail, defaultDeliveryDetail: PurchaseRequestDeliveryDetail): FormGroup {
    const requestDeliveryDate =
      deliveryDetail && deliveryDetail.requestDeliveryDate
        ? deliveryDetail.requestDeliveryDate
        : defaultDeliveryDetail.requestDeliveryDate;
    return this.fb.group({
      requestDeliveryDate: [
        {
          value: requestDeliveryDate ? getDateFromString(convertUtcToBkk(requestDeliveryDate)) : null,
          disabled: [PurchaseRequestTypeEnum.Z9, PurchaseRequestTypeEnum.Z9_EDIT].includes(this.data.type)
        },
        [PurchaseRequestTypeEnum.Z9, PurchaseRequestTypeEnum.Z9_EDIT].includes(this.data.type)
          ? null
          : Validators.required
      ],
      deliveryNote: [
        {
          value:
            deliveryDetail && deliveryDetail.deliveryNote
              ? deliveryDetail.deliveryNote
              : defaultDeliveryDetail.deliveryNote,
          disabled: [PurchaseRequestTypeEnum.Z9, PurchaseRequestTypeEnum.Z9_EDIT].includes(this.data.type)
        }
      ],
      shipToId:
        deliveryDetail && deliveryDetail.shipTo && deliveryDetail.shipTo.id
          ? deliveryDetail.shipTo.id
          : deliveryDetail && deliveryDetail.shipToId
          ? deliveryDetail.shipToId
          : defaultDeliveryDetail.shipTo && defaultDeliveryDetail.shipTo.id,
      shipToName: [
        {
          value:
            deliveryDetail && deliveryDetail.shipTo && deliveryDetail.shipTo.name
              ? deliveryDetail.shipTo.name
              : deliveryDetail && deliveryDetail.shipToName
              ? deliveryDetail.shipToName
              : defaultDeliveryDetail.shipTo && defaultDeliveryDetail.shipTo.name,
          disabled: [
            PurchaseRequestTypeEnum.Z9,
            PurchaseRequestTypeEnum.Z9_EDIT,
            PurchaseRequestTypeEnum.WAREHOUSE,
            PurchaseRequestTypeEnum.HOT_PRICE
          ].includes(this.data.type)
        },
        Validators.required
      ],
      shipToDetail:
        deliveryDetail && deliveryDetail.shipTo && deliveryDetail.shipTo.address
          ? deliveryDetail.shipTo.address
          : deliveryDetail && deliveryDetail.shipToDetail
          ? deliveryDetail.shipToDetail
          : defaultDeliveryDetail.shipTo && defaultDeliveryDetail.shipTo.address,
      shipToContactName: [
        deliveryDetail && deliveryDetail.shipTo && deliveryDetail.shipTo.contactPersonName
          ? deliveryDetail.shipTo.contactPersonName
          : deliveryDetail && deliveryDetail.shipToContactName
          ? deliveryDetail.shipToContactName
          : defaultDeliveryDetail.shipTo && defaultDeliveryDetail.shipTo.contactPersonName,
        Validators.required
      ],
      shipToContactNumber: [
        deliveryDetail && deliveryDetail.shipTo && deliveryDetail.shipTo.contactPersonNumber
          ? deliveryDetail.shipTo.contactPersonNumber
          : deliveryDetail && deliveryDetail.shipToContactNumber
          ? deliveryDetail.shipToContactNumber
          : defaultDeliveryDetail.shipTo && defaultDeliveryDetail.shipTo.contactPersonNumber,
        Validators.required
      ],
      billToId:
        deliveryDetail && deliveryDetail.billTo && deliveryDetail.billTo.id
          ? deliveryDetail.billTo.id
          : deliveryDetail && deliveryDetail.billToId
          ? deliveryDetail.billToId
          : defaultDeliveryDetail.billTo && defaultDeliveryDetail.billTo.id,
      billToName: [
        {
          value:
            deliveryDetail && deliveryDetail.billTo && deliveryDetail.billTo.name
              ? deliveryDetail.billTo.name
              : deliveryDetail && deliveryDetail.billToName
              ? deliveryDetail.billToName
              : defaultDeliveryDetail.billTo.name,
          disabled: [
            PurchaseRequestTypeEnum.Z9,
            PurchaseRequestTypeEnum.Z9_EDIT,
            PurchaseRequestTypeEnum.WAREHOUSE,
            PurchaseRequestTypeEnum.HOT_PRICE
          ].includes(this.data.type)
        },
        Validators.required
      ],
      billToDetail:
        deliveryDetail && deliveryDetail.billTo && deliveryDetail.billTo.address
          ? deliveryDetail.billTo.address
          : deliveryDetail && deliveryDetail.billToDetail
          ? deliveryDetail.billToDetail
          : defaultDeliveryDetail.billTo.address,
      billToContactName: [
        deliveryDetail && deliveryDetail.billTo && deliveryDetail.billTo.contactPersonName
          ? deliveryDetail.billTo.contactPersonName
          : deliveryDetail && deliveryDetail.billToContactName
          ? deliveryDetail.billToContactName
          : defaultDeliveryDetail.billTo.contactPersonName,
        Validators.required
      ],
      billToContactNumber: [
        deliveryDetail && deliveryDetail.billTo && deliveryDetail.billTo.contactPersonNumber
          ? deliveryDetail.billTo.contactPersonNumber
          : deliveryDetail && deliveryDetail.billToContactNumber
          ? deliveryDetail.billToContactNumber
          : defaultDeliveryDetail.billTo.contactPersonNumber,
        Validators.required
      ]
    });
  }

  initFreeItem(value, prItem: AbstractControl) {
    const freeItemFormGroup = this.fb.group({
      refArticleNo: value.refArticleNo,
      refBarcode: value.refBarcode,
      articleNo: value.articleNo,
      barcode: value.barcode,
      productName: value.productName,
      orderUnit: value.orderUnit,
      quantity: [
        {
          value: value.quantity,
          disabled: [PurchaseRequestModeEnum.VIEW, PurchaseRequestModeEnum.PARTIAL_APPROVE].includes(this.data.mode)
        },
        [this.isZeroValidator, Validators.required]
      ],
      vatPct: value.vatPct,
      supplierCode: value.supplierCode,
      supplierName: value.supplierName,
      isAddFreeItem: true,
      supplierArticleNo: value.supplierArticleNo,
      unitFactor: value.unitFactor
    });
    const freeItemsFormArray = prItem.get('freeItems') as FormArray;
    freeItemsFormArray.push(freeItemFormGroup);
  }

  calculateTotalAmount() {
    let subTotalAmt = 0;
    let totalVatAmt = 0;
    let requestBuyItems = 0;

    if (this.data.mode === PurchaseRequestModeEnum.PARTIAL_APPROVE) {
      this.originalPrItem.forEach(item => {
        const subTotalAmount = item.subTotalAmount || 0;
        const unitPrice = item.unitPrice || 0;
        const quantity = item.quantity || 0;
        const vatAmt = item.totalVatAmount || 0;
        const isItemReject = item.isReject || null;

        if (!isItemReject) {
          if ([PurchaseRequestTypeEnum.HOT_PRICE, PurchaseRequestTypeEnum.Z9_EDIT].includes(this.data.type)) {
            subTotalAmt = roundTo2DecimalPlace(subTotalAmt + unitPrice * quantity);
            item.subTotalAmount = roundTo2DecimalPlace(unitPrice * quantity);
          } else {
            subTotalAmt = roundTo2DecimalPlace(subTotalAmt + subTotalAmount);
          }
          totalVatAmt = roundTo2DecimalPlace(totalVatAmt + vatAmt);
          requestBuyItems = requestBuyItems + 1;
        }
      });
    } else {
      this.getItemForm.controls.forEach((itemForm, index) => {
        const subTotalAmount = itemForm.get('subTotalAmount').value || 0;
        const unitPrice = itemForm.get('unitPrice').value || 0;
        const quantity = itemForm.get('quantity').value || 0;
        const vatAmt = itemForm.get('totalVatAmount').value || 0;
        const isItemReject = itemForm.get('isReject').value || null;
        if (!isItemReject) {
          if ([PurchaseRequestTypeEnum.HOT_PRICE, PurchaseRequestTypeEnum.Z9_EDIT].includes(this.data.type)) {
            subTotalAmt = roundTo2DecimalPlace(subTotalAmt + unitPrice * quantity);
            itemForm.get('subTotalAmount').patchValue(unitPrice * quantity);
          } else {
            subTotalAmt = roundTo2DecimalPlace(subTotalAmt + subTotalAmount);
          }
          totalVatAmt = roundToDecimalPlace(roundToDecimalPlace(totalVatAmt + vatAmt, 8), 4);
          requestBuyItems = requestBuyItems + 1;
        }
        if (PurchaseRequestStatusEnum.DRAFT === this.purchaseRequest.status) {
          itemForm.patchValue({ itemNo: ++index });
        }
      });
    }

    totalVatAmt = roundTo2DecimalPlace(totalVatAmt);
    subTotalAmt = roundTo2DecimalPlace(subTotalAmt);

    const totalAmount = roundTo2DecimalPlace(subTotalAmt + totalVatAmt);

    this.purchaseRequestForm.patchValue({
      subTotalAmount: subTotalAmt,
      vatAmount: totalVatAmt
    });

    if (
      this.purchaseRequest.status === PurchaseRequestStatusEnum.DRAFT ||
      this.data.mode === PurchaseRequestModeEnum.CREATE
    ) {
      this.purchaseRequest = {
        ...this.purchaseRequest,
        ...this.purchaseRequestForm.getRawValue(),
        vatAmount: totalVatAmt,
        subTotalAmount: subTotalAmt,
        totalAmount,
        requestBuyItems
      };
    } else {
      this.purchaseRequest = {
        ...this.purchaseRequest,
        vatAmount: totalVatAmt,
        subTotalAmount: subTotalAmt,
        totalAmount,
        requestBuyItems
      };
    }
  }

  onCancel() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  onSave() {
    this.isSubmit = false;
    const pr = this.prepareData(true);
    this.store.dispatch(new PurchaseRequestSaveRequest(pr));
  }

  onSubmit() {
    this.isSubmit = true;
    const currentDate = new Date();
    const currentDateStart = moment(currentDate)
      .startOf('day')
      .toDate();

    if (![PurchaseRequestTypeEnum.Z9, PurchaseRequestTypeEnum.Z9_EDIT].includes(this.data.type)) {
      this.getItemForm.controls.forEach(itemForm => {
        const value = (itemForm.get('deliveryDetail') as FormGroup).getRawValue();
        const currentRequestDeliveryDate = value.requestDeliveryDate;
        if (currentRequestDeliveryDate && currentRequestDeliveryDate < currentDateStart) {
          itemForm
            .get('deliveryDetail')
            .get('requestDeliveryDate')
            .setErrors({ invalidDate: true });
          return;
        }
      });
    }

    if (this.getItemForm.invalid) {
      const invalidIndex = this.getItemForm.controls.findIndex(prItem => prItem.invalid);
      this.paging.navigateToErrorIndex(invalidIndex);
      return;
    }

    if (this.purchaseRequestForm.invalid) {
      return;
    }

    if (this.getItemForm.controls.some(item => (item as FormGroup).controls.submitError.value)) {
      return;
    }

    this.handleConfirm();
  }

  onPartialApproveCancel() {
    this.data.mode = PurchaseRequestModeEnum.VIEW;
    this.clearSearchText();

    setTimeout(() => {
      this.purchaseRequest.items.forEach(item => (item.isReject = false));
      this.getItemForm.controls.forEach(itemForm => {
        itemForm.patchValue({ isReject: false });
      });
      this.calculateTotalAmount();
      this.summaryFreeItems();
    }, 100);
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (ModalButtonResponseEnum.OK === result) {
          const pr = this.prepareData(true);
          this.store.dispatch(new PurchaseRequestSubmitRequest(pr));
        }
      });
  }

  prepareData(isSave = false) {
    let subTotalAmt = 0;
    let totalVatAmt = 0;
    let requestBuyItems = 0;
    const prItems: PurchaseRequestItem[] = [];
    this.getItemForm.controls.forEach(itemForm => {
      const prItemRaw = (itemForm as FormGroup).getRawValue();

      const selected = prItemRaw.selected;
      if (isSave) {
        const subTotalAmount = prItemRaw.subTotalAmount || 0;
        const vatAmt = prItemRaw.vatAmt || 0;
        subTotalAmt = round(subTotalAmt + subTotalAmount, 2);
        totalVatAmt = round(totalVatAmt + vatAmt, 2);
        requestBuyItems = requestBuyItems + 1;

        const prItem = this.transformPrItem(prItemRaw);
        prItems.push(prItem);
      } else {
        if (selected) {
          const subTotalAmount = prItemRaw.subTotalAmount || 0;
          const vatAmt = prItemRaw.vatAmt || 0;
          subTotalAmt = round(subTotalAmt + subTotalAmount, 2);
          totalVatAmt = round(totalVatAmt + vatAmt, 2);
          requestBuyItems = requestBuyItems + 1;

          const prItem = this.transformPrItem(prItemRaw);
          prItems.push(prItem);
        }
      }
    });

    return {
      ...this.purchaseRequest,
      orderSchedule: this.purchaseRequest.orderSchedule,
      items: prItems,
      productType:
        !this.selectedProductType &&
        [PurchaseRequestTypeEnum.Z9, PurchaseRequestTypeEnum.Z9_EDIT, PurchaseRequestTypeEnum.WAREHOUSE].includes(
          this.data.type
        )
          ? ProductTypeEnum.INVENTORY
          : this.selectedProductType
    } as PurchaseRequest;
  }

  transformPrItem(prItemRaw) {
    return {
      ...prItemRaw,
      deliveryDetail: this.transformDeliveryDetail(prItemRaw.deliveryDetail)
    } as PurchaseRequestItem;
  }

  transformDeliveryDetail(prItemDeliveryDetail) {
    return {
      requestDeliveryDate: prItemDeliveryDetail.requestDeliveryDate
        ? convertBkkToUtc(formatDateStartOfDay(prItemDeliveryDetail.requestDeliveryDate))
        : null,
      deliveryNote: prItemDeliveryDetail.deliveryNote,
      shipTo: {
        id: prItemDeliveryDetail.shipToId,
        name: prItemDeliveryDetail.shipToName,
        address: prItemDeliveryDetail.shipToDetail,
        contactPersonName: prItemDeliveryDetail.shipToContactName,
        contactPersonNumber: prItemDeliveryDetail.shipToContactNumber
      },
      billTo: {
        id: prItemDeliveryDetail.billToId,
        name: prItemDeliveryDetail.billToName,
        address: prItemDeliveryDetail.billToDetail,
        contactPersonName: prItemDeliveryDetail.billToContactName,
        contactPersonNumber: prItemDeliveryDetail.billToContactNumber
      }
    } as PurchaseRequestDeliveryDetail;
  }

  getColorStatus(status: PurchaseRequestStatusEnum) {
    return status ? status.toLowerCase() : '';
  }

  openTdAssortmentListModal() {
    if (this.getItemForm) {
      this.store.dispatch(new TdAssortmentOrderAddAllItem(this.getItemForm.getRawValue()));
    }
    const initialState = {
      title: null,
      childItem: new ChildItem(
        TdAssortmentListComponent,
        {
          prType: this.data.type,
          dayIndex: this.dayIndex,
          productType: this.purchaseRequest.productType,
          shipToCode: (this.data.shipTo && this.data.shipTo.code) || this.purchaseRequest.shipToCode
        },
        true
      )
    };

    this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  showVatDetails() {
    const vatDetails = [];
    this.purchaseRequest.items.forEach((item, index) => {
      vatDetails.push({
        itemNo: index + 1,
        productName: item.productName,
        vat: item.vatPct,
        vatAmount: item.totalVatAmount || 0,
        isInvalid: !item.quantity || false,
        isReject: item.isReject || item.status === 'REJECTED',
        productVat: item.productVat,
        supplierVat: item.supplierVat
      });
    });
    const initialState = {
      title: 'VAT',
      vatDetails,
      vatAmountPrecision: 4
    };
    this.modalService.show(VatDetailsModalComponent, {
      initialState
    });
  }

  onApplyAdvanceSchedule() {
    this.purchaseRequest = {
      ...this.purchaseRequest,
      advance: true,
      orderSchedule: this.advanceScheduleDayIndex,
      orderScheduleDayOfWeekDisplay: this.translate.instant('DAY_INDEX.' + this.advanceScheduleDayIndex)
    };
    this.dayIndex = this.advanceScheduleDayIndex;
    this.uploadUrl = `${this.tdAssortmentService.getUrlImport()}?purchaseType=${this.data.type}&orderingCycle=${
      this.dayIndex
    }`;
    this.store.dispatch(new TdAssortmentOrderReset());
    this.clearAllItemForm();
    this.calculateTotalAmount();
    this.advanceScheduleDayIndex = null;
    this.modalAdvanceSchedule.hide();
  }

  clearAllItemForm() {
    while (this.getItemForm.length !== 0) {
      this.getItemForm.removeAt(0);
    }
  }

  showModalConfirmAdvanceSchedule() {
    const initialState = {
      title: 'Confirm',
      message: 'Are you sure you want to select "Advance Order Schedule"?',
      cancelText: 'Cancel',
      okText: 'OK'
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });
    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.showModalAdvanceSchedule();
        }
      });
  }

  showModalAdvanceSchedule() {
    const dayIndex = moment()
      .add(1, 'days')
      .get('days');

    this.advanceScheduleDayList = dayIndexList.filter(day => day.value !== dayIndex);

    this.modalAdvanceSchedule.show();
  }

  hideModalAdvanceSchedule() {
    this.advanceScheduleDayIndex = null;
    this.modalAdvanceSchedule.hide();
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  // Override since list page already subscribe
  subscribeForVersionError() {}

  deleteItem(supplierCodeAndBarcode: string, itemIndex: number) {
    const initialState = {
      title: 'Confirm',
      okText: 'Yes, delete',
      cancelText: 'Cancel',
      message: 'Are you sure you want to delete this item?'
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          if (this.purchaseRequest.status !== 'AWAITING_APPROVAL') {
            const prItemFormGroup = this.getCurrentPRItemFromGroup('supplierCodeAndBarcode', supplierCodeAndBarcode);
            if (prItemFormGroup) {
              this.deleteUnitPriceError(prItemFormGroup.controls.itemNo.value);
              this.itemDetailsError.forEach(error => {
                if (error.itemNo > prItemFormGroup.controls.itemNo.value) {
                  error.itemNo -= 1;
                }
              });

              this.getItemForm.removeAt(itemIndex);
              this.store.dispatch(new TdAssortmentOrderRemoveItem(supplierCodeAndBarcode));
            }
          } else {
            const prItemFormGroup = this.getCurrentPRItemFromGroup('supplierCodeAndBarcode', supplierCodeAndBarcode);
            prItemFormGroup.patchValue({
              isReject: true
            });

            this.purchaseRequest.items.forEach(item => {
              const currentSupplierCodeAndBarcode = `${item.supplierCode}_${item.barcode}`;
              if (currentSupplierCodeAndBarcode === prItemFormGroup.get('supplierCodeAndBarcode').value) {
                item.isReject = true;
              }
            });
          }
          this.calculateTotalAmount();
          this.summaryFreeItems();
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  deleteUnitPriceError(itemNo) {
    const deletingIndex = this.itemDetailsError.findIndex(error => error.itemNo === itemNo);
    if (deletingIndex >= 0) {
      this.itemDetailsError.splice(deletingIndex, 1);
    }
  }

  rebornPurchaseRequestFormGroup() {
    this.purchaseRequestForm = cloneAbstractControl(this.purchaseRequestForm);

    // Update value changes event
    this.getItemForm.controls.forEach(prItemFormGroup => {
      this.updateValueChangesEvent(prItemFormGroup);
    });
  }

  updateValueChangesEvent(prItemFormGroup) {
    prItemFormGroup.controls['quantity'].valueChanges.pipe(untilComponentDestroyed(this)).subscribe(quantity => {
      if (quantity) {
        const totalVatAmount = calculateVatAmount(
          prItemFormGroup.get('unitPrice').value,
          quantity,
          prItemFormGroup.get('vatPct').value,
          prItemFormGroup.get('productVat').value,
          prItemFormGroup.get('supplierVat').value
        );
        const subTotalAmount = roundTo2DecimalPlace(quantity * prItemFormGroup.get('unitPrice').value);
        prItemFormGroup.patchValue({
          subTotalAmount,
          totalVatAmount,
          totalAmount: roundTo2DecimalPlace(subTotalAmount + totalVatAmount)
        });
      } else {
        prItemFormGroup.patchValue({
          totalVatAmount: null,
          subTotalAmount: 0,
          totalAmount: 0
        });
      }

      /** TODO Update Td assortment order state **/
      this.store.dispatch(new TdAssortmentOrderUpdateItem(prItemFormGroup.value));
      this.calculateTotalAmount();
    });

    if ([PurchaseRequestTypeEnum.HOT_PRICE, PurchaseRequestTypeEnum.Z9_EDIT].includes(this.data.type)) {
      prItemFormGroup.controls['unitPrice'].valueChanges.pipe(untilComponentDestroyed(this)).subscribe(unitPrice => {
        const totalVatAmount = calculateVatAmount(
          unitPrice,
          prItemFormGroup.get('quantity').value,
          prItemFormGroup.get('vatPct').value,
          prItemFormGroup.get('productVat').value,
          prItemFormGroup.get('supplierVat').value
        );
        const subTotalAmount = round(unitPrice * prItemFormGroup.get('quantity').value, 2);
        prItemFormGroup.patchValue({
          subTotalAmount,
          totalVatAmount,
          vatAmount: round((unitPrice * prItemFormGroup.get('vatPct').value) / 100, 2),
          newUnitPrice: unitPrice,
          totalAmount: subTotalAmount + totalVatAmount,
          submitError: null
        });
        if (unitPrice) {
          this.deleteUnitPriceError(prItemFormGroup.get('itemNo').value);
          prItemFormGroup.get('unitPrice').setErrors(null);
        }
        this.calculateTotalAmount();
        this.store.dispatch(new TdAssortmentOrderUpdateItem(prItemFormGroup.value));
      });
    }
  }

  isAllItemRejected() {
    return this.getItemForm.controls.every(itemForm => itemForm.get('isReject').value === true);
  }

  toggleToEdit() {
    this.data.mode = PurchaseRequestModeEnum.EDIT;
    this.data.title = 'Edit PR';
    this.createNewProductForm(this.originalPrItem);
    this.getButton();
    if (this.form) {
      this.getItemForm.controls.forEach(itemForm => {
        itemForm.get('quantity').enable({ onlySelf: true, emitEvent: false });
        this.getFreeItem(itemForm as FormGroup).forEach(freeItem => {
          freeItem.get('quantity').enable({ onlySelf: true, emitEvent: false });
        });
        if ([PurchaseRequestTypeEnum.HOT_PRICE, PurchaseRequestTypeEnum.Z9_EDIT].includes(this.data.type)) {
          itemForm.get('unitPrice').enable({ onlySelf: true, emitEvent: false });
          this.getFreeItem(itemForm as FormGroup).forEach(freeItem => {
            freeItem.get('unitPrice').enable({ onlySelf: true, emitEvent: false });
          });
        }
      });
    } else {
      this.createForm(this.purchaseRequest);
    }

    this.doCheckProductChanged(this.purchaseRequest.items);

    this.notifyLeaveFormOutput.emit(false);
  }

  toggleToPartialApprove() {
    this.data.mode = PurchaseRequestModeEnum.PARTIAL_APPROVE;
  }

  doCheckProductChanged(items: PurchaseRequestItem[]) {
    let isProductChanged = false;

    if (items) {
      isProductChanged = items.some(item => {
        if (item.productChanges && Object.keys(item.productChanges) && Object.keys(item.productChanges).length) {
          isProductChanged = true;
          return true;
        }
        return false;
      });
    }

    if (isProductChanged) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Data Changed',
          message: 'This data has been updated. Please verify data before submit.'
        }
      });
    }
    return;
  }

  initialZ9Pr() {
    let tomorrow = null;
    let dayIndex = null;
    let dayOfWeekDisplay = null;
    if (this.data.mode === PurchaseRequestModeEnum.CREATE) {
      if (this.data.type === PurchaseRequestTypeEnum.Z9) {
        tomorrow = moment().add(1, 'days');
        dayIndex = tomorrow.get('days');
        dayOfWeekDisplay = moment(tomorrow).format('ddd');
      } else {
        dayIndex = moment().get('days');
        dayOfWeekDisplay = moment().format('ddd');
      }
    } else {
      dayIndex = this.purchaseRequest.orderSchedule;
      dayOfWeekDisplay = this.translate.instant('DAY_INDEX.' + this.purchaseRequest.orderSchedule);
    }

    this.dayIndex = dayIndex;
    this.purchaseRequest = {
      ...this.purchaseRequest,
      orderSchedule: dayIndex,
      orderScheduleDayOfWeekDisplay: dayOfWeekDisplay
    };

    this.uploadUrl = `${this.tdAssortmentService.getUrlImport()}?purchaseType=${
      this.data.type
    }&orderingCycle=${dayIndex}`;
  }

  transformProductChanges(productChanges: ProductChange[]) {
    const ProductChangeObject = {};
    if (productChanges && productChanges instanceof Array && productChanges.length > 0) {
      productChanges.forEach(productChange => {
        ProductChangeObject[productChange.field] = productChange;
      });
      return ProductChangeObject;
    }
    return productChanges;
  }

  trackIndex(index) {
    return index;
  }

  trackFreeItemIndex(freeItemIndex) {
    return freeItemIndex;
  }

  expandAddFreeItem(prItem: FormGroup) {
    this.addFreeItemForm.reset();
    this.expandFreeItemArticleNo = prItem.controls.supplierCodeAndBarcode.value;
    this.productSearchInput$.next('');
    this.loadFreeItemAssortment(prItem);
  }

  onChangeProductName(value, articleNo, barcode) {
    if (!value) {
      this.addFreeItemForm.reset();
    }
    this.addFreeItemForm.patchValue({
      refArticleNo: articleNo,
      refBarcode: barcode,
      ...value
    });
  }

  onBlurProductName() {}

  loadFreeItemAssortment(prItem: FormGroup) {
    this.freeItemProductList$ = concat(
      of([]),
      this.productSearchInput$.pipe(
        startWith(''),
        debounceTime(300),
        distinctUntilChanged(),
        map(searchCriteria => {
          const searchFreeItemCriteriaObject = {
            purchaseType: this.data.type,
            productType: this.purchaseRequest.productType,
            shipToCode: this.purchaseRequest.shipToCode,
            barcode: prItem.controls.barcode.value,
            searchCriteria,
            supplierCode:
              this.data.type === PurchaseRequestTypeEnum.SUPPLIER ? prItem.controls.supplierCode.value : null
          };
          this.productSearchLoading = true;
          return searchFreeItemCriteriaObject;
        }),
        switchMap(term => {
          return this.purchaseRequestService.searchFreeItems(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.productSearchLoading = false;
            })
          );
        })
      )
    );
  }

  addFreeItem(prItem: AbstractControl) {
    const addedFreeItem = this.getAddedFreeItem(prItem as FormGroup);
    if (addedFreeItem) {
      addedFreeItem.patchValue({
        quantity: addedFreeItem.controls.quantity.value + this.addFreeItemForm.controls.quantity.value
      });
    } else {
      this.initFreeItem(this.addFreeItemForm.value, prItem);
    }

    this.expandFreeItemArticleNo = '';
    this.addFreeItemForm.reset();
    this.summaryFreeItems();
  }

  getAddedFreeItem(prItem: FormGroup): FormGroup {
    let freeItemFormGroup = null;
    if (this.addFreeItemForm) {
      this.getFreeItem(prItem).forEach(freeItem => {
        const barcode = freeItem.get('barcode').value;
        if (barcode === this.addFreeItemForm.controls.barcode.value) {
          freeItemFormGroup = freeItem;
        }
      });
    }
    return freeItemFormGroup;
  }

  deleteAddFreeItemForm() {
    this.addFreeItemForm.reset();
    this.expandFreeItemArticleNo = '';
  }

  deleteFreeItem(prItem: FormGroup, freeItemIndex) {
    (prItem.controls.freeItems as FormArray).removeAt(freeItemIndex);
    this.summaryFreeItems();
  }

  getFreeItem(prItem: FormGroup) {
    const freeItems = prItem.controls.freeItems as FormArray;
    return freeItems.controls;
  }

  showDeliveryDetail(index: number) {
    this.modalService.show(PurchaseRequestDeliveryDetailsComponent, {
      class: 'delivery-details',
      backdrop: 'static',
      keyboard: false,
      initialState: {
        title: 'Delivery Details',
        data: this.data,
        isSubmit: this.isSubmit,
        parentFormItem: this.getItemForm,
        parentFormItemIndex: this.getItemForm.at(index).get('deliveryDetail')
      }
    });
  }

  onCloseFullModal() {
    if (this.form && (this.form.touched || this.form.dirty)) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  onDeletePr() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this PR?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new PurchaseRequestDeleteRequest(this.purchaseRequest.id));
        }
      });
  }

  onCancelPr() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel PR Number "${this.purchaseRequest.docNo}"?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PurchaseRequestCancelRequest({
              id: this.purchaseRequest.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  onApprove() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to "Approve"?`,
        label: 'Comment',
        isRequiredConfirmMessage: false,
        okText: 'Approve',
        okClass: 'btn btn-special-approve'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PurchaseRequestApproveRequest({
              id: this.purchaseRequest.id,
              comment: confirmModalRef.content.confirmMessage,
              approvedPrItem: this.getItemForm
                .getRawValue()
                .filter(prItem => !prItem.isReject)
                .map(prItem => {
                  return {
                    articleNo: prItem.articleNo,
                    supplierCode: prItem.supplierCode,
                    barcode: prItem.barcode
                  };
                }),
              subTotalAmount: this.purchaseRequest.subTotalAmount,
              vatAmount: this.purchaseRequest.vatAmount,
              totalAmount: this.purchaseRequest.totalAmount
            })
          );
        }
      });
  }

  onReject() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to "Reject"?`,
        label: 'Comment',
        isRequiredConfirmMessage: true,
        okText: 'Reject',
        okClass: 'btn btn-special-reject'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PurchaseRequestRejectRequest({
              id: this.purchaseRequest.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  checkPermissionForSelectTdAssortmentList() {
    if (!this.purchaseRequest.productType) {
      if (this.hasMangeMerchandisePrPermission && this.hasMangeNonMerchandisePrPermission) {
        if (PurchaseRequestTypeEnum.SUPPLIER === this.data.type) {
          this.modalProductType.show();
          return;
        } else {
          this.selectedProductType = this.productTypeEnum.INVENTORY;
        }
      } else if (this.hasMangeNonMerchandisePrPermission) {
        if (this.hasMangeNonMerchandisePrPermissionFixedAssets && this.hasMangeNonMerchandisePrPermissionStoreUsed) {
          this.modalProductType.show();
          return;
        } else if (this.hasMangeNonMerchandisePrPermissionStoreUsed) {
          this.selectedProductType = this.productTypeEnum.STORE_USE;
        } else if (this.hasMangeNonMerchandisePrPermissionFixedAssets) {
          this.selectedProductType = this.productTypeEnum.FIX_ASSET;
        }
      } else if (this.hasMangeMerchandisePrPermission && !this.hasMangeNonMerchandisePrPermission) {
        this.selectedProductType = this.productTypeEnum.INVENTORY;
      }
    }

    this.purchaseRequest = {
      ...this.purchaseRequest,
      articleType: [this.productTypeEnum.FIX_ASSET, this.productTypeEnum.STORE_USE].includes(this.selectedProductType)
        ? this.articleTypeEnum.NON_MERCHANDISE
        : this.articleTypeEnum.MERCHANDISE,
      productType: this.selectedProductType
    };
    this.openTdAssortmentListModal();
  }

  hideModalProductType() {
    this.modalProductType.hide();
    this.selectedProductType = null;
  }

  onApplyProductType() {
    this.purchaseRequest = {
      ...this.purchaseRequest,
      articleType: [this.productTypeEnum.FIX_ASSET, this.productTypeEnum.STORE_USE].includes(this.selectedProductType)
        ? this.articleTypeEnum.NON_MERCHANDISE
        : this.articleTypeEnum.MERCHANDISE,
      productType: this.selectedProductType
    };
    this.modalProductType.hide();
    this.openTdAssortmentListModal();
  }

  onLoadPrItemDetail(value: any) {
    if (Array.isArray(value.validations) && value.validations.length > 0) {
      this.importResult = {
        title: `${value.validations.length} Errors Found.`,
        table: value.validations,
        message: null
      };
    } else if (Array.isArray(value.purchaseRequestItemDtos) && value.purchaseRequestItemDtos.length > 0) {
      this.clearAllItemForm();
      this.rebornPurchaseRequestFormGroup();
      /** Hard code M to article type cause Z9 and Z9-Edit allow only Merchandise **/
      this.purchaseRequest = {
        ...this.purchaseRequest,
        articleType: this.articleTypeEnum.MERCHANDISE
      };
      this.store.dispatch(new TdAssortmentOrderReset());
      this.store.dispatch(new TdAssortmentOrderAddAllItem(value.purchaseRequestItemDtos));
      this.importResult = {
        title: 'Success',
        table: null,
        message: `${value.noOfItem} items have been added.`
      };
      this.itemDetailsError = [];
    }

    this.importResultModel.show();
  }

  checkExistingItem() {
    if (this.form.get('items').value.length > 0) {
      this.alertConfirmUploadModal();
    } else {
      this.showPurchaseRequestImportModal();
    }
  }

  alertConfirmUploadModal(
    initialState = {
      title: 'Confirm',
      message: 'Are you sure you want to import new file? All existing data will be lost.',
      okText: 'OK'
    }
  ) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.showPurchaseRequestImportModal();
        }
      });
  }

  showPurchaseRequestImportModal() {
    this.purchaseRequestModalUpload = this.modalService.show(PurchaseRequestModalUploadComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        uploadUrl: this.uploadUrl,
        productType: [PurchaseRequestTypeEnum.WAREHOUSE].includes(this.data.type)
          ? this.productTypeEnum.INVENTORY
          : this.purchaseRequest.productType,
        productTypeList: this.productTypeList
      }
    });

    this.purchaseRequestModalUpload.content.submitUpload.pipe(untilComponentDestroyed(this)).subscribe(result => {
      this.onLoadPrItemDetail(result.file);
      this.selectedProductType = result.productType;
      this.purchaseRequest.productType = result.productType;
    });
  }
}
