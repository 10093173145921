import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaseSearchComponent } from '../../../../base/base-search.component';
import { DeliveryByTypeEnum } from '../../../../shared/enum/delivery-by-type.enum';
import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { OrderingMethodEnum, OrderSubTypeEnum } from '../../../../shared/enum/ordering-method.enum';
import { ProductStatusEnum } from '../../../../shared/models';
import { NotificationEmit } from '../../../../shared/models/notification-emit.model';
import {
  OrderMethodEnum,
  OrderSelectItemSearchCriteria,
  ProductOrderItem
} from '../../../../shared/models/order-request.model';
import { OrderSelectItemOrderAddItem } from '../../../../shared/store/actions/order-select-item-order.actions';
import {
  FirstLotOrderSelectItemListRequest,
  OrderSelectItemListRequest
} from '../../../../shared/store/actions/order-select-item.actions';
import { OrderSelectItemState } from '../../../../shared/store/reducers/order-select-item.reducers';
import {
  getCountOrderSelectItemOrder,
  getOrderSelectItemOrderById
} from '../../../../shared/store/selectors/order-select-item-order.selector';
import {
  selectAllOrderSelectItemList,
  selectOrderSelectItemList
} from '../../../../shared/store/selectors/order-select-item.selector';
import { AppStates } from '../../../../shared/store/state/app.states';
import { getPriceFormat, ItemPrice } from '../../../../shared/utils/calculate-price-util';
import { ProductTypeEnum } from './../../../../shared/enum/product-type.enum';

@Component({
  selector: 'app-select-item-modal',
  templateUrl: './select-item-modal.component.html',
  styleUrls: ['./select-item-modal.component.scss']
})
export class SelectItemModalComponent extends BaseSearchComponent<
  OrderSelectItemSearchCriteria,
  ProductOrderItem,
  OrderSelectItemState
> {
  @Input() data: {
    title: string;
    orderType: OrderingMethodEnum;
    storeNo?: string;
    mode?: OrderingMethodEnum;
    orderSubType?: OrderSubTypeEnum;
  };
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  public totalOrder$: Observable<number>;
  private localStore: Observable<any>;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder
  ) {
    super(store, modalService, selectAllOrderSelectItemList, selectOrderSelectItemList);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.totalOrder$ = this.store.pipe(select(getCountOrderSelectItemOrder));
  }

  doAfterVersionAlertModal() {}

  doDestroy() {}

  onBack() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });
  }

  goToView() {}

  onAdvanceSubmit() {}

  prepareSearchCriteriaTags() {}

  search(criteriaObj) {
    if (this.data.mode === OrderingMethodEnum.FIRST_LOT_ORDER) {
      this.store.dispatch(new FirstLotOrderSelectItemListRequest(criteriaObj));
    } else {
      this.store.dispatch(new OrderSelectItemListRequest(criteriaObj));
    }
  }

  setInitialCriteriaObject() {
    let config: any;
    const allProductType = [ProductTypeEnum.FIX_ASSET, ProductTypeEnum.INVENTORY, ProductTypeEnum.STORE_USE];

    if (this.data.mode === OrderingMethodEnum.FIRST_LOT_ORDER) {
      config = {
        searchCriteria: null,
        productType: ProductTypeEnum.FIX_ASSET,
        orderingMethods: [
          OrderingMethodEnum.FIRST_LOT_ORDER,
          OrderingMethodEnum.SPECIAL_REQUEST,
          OrderMethodEnum.STORE_REPLENISHMENT
        ],
        page: 0,
        size: 20,
        ...(this.data.orderType === OrderingMethodEnum.SPECIAL_REQUEST && {
          orderingMethods: OrderMethodEnum.ALL
        })
      };
    } else {
      if (this.data.orderSubType === OrderSubTypeEnum.REPLENISH) {
        config = {
          searchCriteria: null,
          productType: [ProductTypeEnum.INVENTORY],
          storeNo: this.data.storeNo,
          page: 0,
          size: 20,
          excStatus: [
            ProductStatusEnum.SELECT,
            ProductStatusEnum.RECALL,
            ProductStatusEnum.HOLD_BUY_SELL,
            ProductStatusEnum.DELETE,
            ProductStatusEnum.SEASONAL
          ].toString(),
          deliveryMethod: DeliveryByTypeEnum.TD,
          ...(this.data.orderType === OrderingMethodEnum.SPECIAL_REQUEST && {
            orderingMethods: OrderMethodEnum.ALL
          })
        };
      } else {
        config = {
          searchCriteria: null,
          productType: allProductType,
          storeNo: this.data.storeNo,
          page: 0,
          size: 20,
          excStatus: [
            ProductStatusEnum.SELECT,
            ProductStatusEnum.RECALL,
            ProductStatusEnum.HOLD_BUY_SELL,
            ProductStatusEnum.DELETE
          ].toString(),
          ...(this.data.orderType === OrderingMethodEnum.SPECIAL_REQUEST && {
            orderingMethods: OrderMethodEnum.ALL
          })
        };
      }
    }

    this.criteriaObject = config;
  }

  setRouteTab() {}

  addItem(productOrderItem: ProductOrderItem, qty: number) {
    productOrderItem.isAddItem = true;
    if (qty) {
      let previousQty = 0;
      const selectPreviousQty$ = this.localStore
        .pipe(select(getOrderSelectItemOrderById(productOrderItem.articleNo)))
        .pipe(filter(value => Boolean(value)))
        .subscribe(value => {
          previousQty = value.qty;
        });

      productOrderItem.qty = Number(previousQty) + Number(qty);

      const itemPrice = new ItemPrice<ProductOrderItem>(productOrderItem, 'qty');
      const amountExcVat = itemPrice.getTotalFrom('wholesalePriceExcVat');
      const vatAmount = itemPrice.getTotalFrom('vatAmount');
      const amount = getPriceFormat(amountExcVat.amount + vatAmount.amount);

      productOrderItem.amountExcVat = amountExcVat;
      productOrderItem.totalVat = vatAmount;
      productOrderItem.amount = amount;

      // If allowToDelete is undefinded, set to true
      if (typeof productOrderItem.allowToDelete === 'undefined') {
        productOrderItem.allowToDelete = true;
      }

      this.store.dispatch(new OrderSelectItemOrderAddItem(productOrderItem));
      selectPreviousQty$.unsubscribe();
    }
  }

  setInitialValue() {
    this.pageSize = 20;
  }
}
