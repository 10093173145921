import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysDisplay'
})
export class DaysDisplayPipe implements PipeTransform {
  private shortDaysObg = {
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
    7: 'Sun'
  };

  private normalDaysObg = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday'
  };

  transform(value: number[], shortness = false): string {
    if (value) {
      value = value.length > 1 ? value.sort() : value;
      if (value.length === 7 && !shortness) {
        return 'Everyday';
      } else {
        return value
          .map(day => {
            if (shortness) {
              return this.shortDaysObg[day];
            } else {
              return this.normalDaysObg[day];
            }
          })
          .join(', ');
      }
    }
  }
}
