<div class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-stock"></em>
    <h4>Stock Transfer</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap d-none d-md-block">
        <button
          type="button"
          class="btn btn-primary mr-1"
          *ngIf="hasCreatePermission()"
          (click)="showModalCreateTransfer()"
        >
          <em class="icon-plus"></em>
          Create Transfer Request
        </button>
      </div>

      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              aria-hidden="true"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="stockTransferStatusFilter"
            [searchable]="false"
            [clearable]="false"
            (change)="onChangeStatus($event)"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
          >
          </ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        Requested Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="requestedDateFrom"
                          formControlName="requestedDateFrom"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          (bsValueChange)="onChangeDateFrom($event)"
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          readonly
                        />
                        <label for="requestedDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="requestedDateTo"
                          formControlName="requestedDateTo"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          (bsValueChange)="onChangeDateTo($event)"
                          [minDate]="minDate"
                          readonly
                        />
                        <label for="requestedDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="top-search-criteria space-search-criteria" *ngIf="requestedDateTag">
    <div class="search-criteria" *ngIf="requestedDateTag">
      <a class="remove-criteria" (click)="clearAdvanceFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ requestedDateStringTag }}: {{ requestedDateTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>
  <ng-container *ngIf="(listState$ | async)?.totalElements == 0">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="table-responsive">
      <table id="data-table" class="table table-striped table-bordered table-hover w-100" aria-hidden="true">
        <thead>
          <tr>
            <th class="text-center w-15">Request No.</th>
            <th class="text-center w-15">Ship From</th>
            <th class="text-center w-15">Ship To</th>
            <th class="text-center w-10">Requested By</th>
            <th class="text-center w-10">Requested Date</th>
            <th class="text-center w-10">Status</th>
            <th class="text-center w-5">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of resultList$ | async; index as i">
            <td (click)="goToView(item)">
              {{ item.docNo | dashDisplay: '(None)' }}
            </td>
            <td (click)="goToView(item)">
              {{ item.shipFromCodeName }}
            </td>
            <td (click)="goToView(item)">
              {{ item.shipToCodeName }}
            </td>
            <td (click)="goToView(item)">
              {{ item.requesterName | dashDisplay }}
            </td>
            <td (click)="goToView(item)" class="text-center">
              {{ item.requestedDate | amFromUtc | amLocal | dateDisplay: environment.dateFormat }}
            </td>

            <td (click)="goToView(item)" class="stock-transfer-status" [ngClass]="getColorStatus(item.status)">
              {{ 'STOCK_TRANSFER_REQUEST.STATUS.' + item.status | translate }}
            </td>
            <td class="text-center position-relative">
              <a
                href="javascript:void(0)"
                class="btn-more mx-auto"
                [popover]="moreTemplate"
                placement="bottom"
                containerClass="fl"
                [outsideClick]="true"
                container=".admin-form"
              >
                <em class="icon-more"></em>
              </a>
              <ng-template #moreTemplate>
                <button
                  type="button"
                  class="btn btn-link"
                  (click)="goToEdit(item)"
                  *ngIf="hasEditPermission(item.status)"
                >
                  <em class="icon-edit"></em>Edit
                </button>
                <!--delete button-->
                <button
                  type="button"
                  class="btn btn-link"
                  (click)="deleteStockTransferRequest(item.id)"
                  *ngIf="hasDeletePermission(item.status)"
                >
                  <em class="icon-delete"></em>Delete
                </button>
                <button
                  type="button"
                  class="btn btn-link"
                  (click)="cancelStockTransferRequest(item.id, item.docNo)"
                  *ngIf="hasCancelPermission(item.status)"
                >
                  <em class="icon-forbidden"></em>Cancel Request
                </button>

                <button
                  type="button"
                  class="btn btn-link"
                  (click)="showHistory(item)"
                  *ngIf="![stockTransferRequestStatusEnum.DRAFT].includes(item.status)"
                >
                  <em class="icon-history"></em>History
                </button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>

<div class="modal fade" bsModal #modalCreateStockTransfer="bs-modal" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title pull-left">Select Transfer DC</h6>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalStockCreateTransfer()">
          <em class="icon-close"></em>
        </button>
      </div>
      <div class="modal-body admin-form">
        <div class="container-fluid">
          <div [formGroup]="createStockTransferForm">
            <div class="form-group">
              <label>Ship From<span class="text-danger">*</span></label>
              <ng-select
                bindLabel="warehouseNameDisplay"
                [searchable]="true"
                [clearable]="true"
                [items]="warehouseListFrom"
                placeholder="Please select..."
                formControlName="shipFrom"
                [ngClass]="{ 'is-invalid': submitted && createStockTransferForm.controls.shipFrom.errors }"
              >
              </ng-select>
              <div
                *ngIf="submitted && createStockTransferForm.controls.shipFrom.errors?.required"
                class="invalid-feedback"
              >
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
            <div class="form-group">
              <label>Ship To<span class="text-danger">*</span></label>
              <ng-select
                bindLabel="warehouseNameDisplay"
                [searchable]="true"
                [clearable]="true"
                [items]="warehouseListTo"
                placeholder="Please select..."
                formControlName="shipTo"
                [ngClass]="{ 'is-invalid': submitted && createStockTransferForm.controls.shipTo.errors }"
              >
              </ng-select>
              <div
                *ngIf="submitted && createStockTransferForm.controls.shipTo.errors?.required"
                class="invalid-feedback"
              >
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-standard" (click)="hideModalStockCreateTransfer()" appPreventDoubleClick>
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="goToCreate()" appPreventDoubleClick>
          Continue
        </button>
      </div>
    </div>
  </div>
</div>
