import env from './.env';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  appName: env.AppName,
  version: env.version + '-localhost',
  commit: env.commit,
  branch: env.branch,
  tier: 'develop',
  serverUrl: 'https://api-dev.tdshop.io',
  storageUrl: 'https://storage.googleapis.com',
  imgUrl: 'https://storage.googleapis.com/product-assortments-dev',
  storeResourcesPath: '/store-resources-dev',
  defaultClientId: 'ADMIN_PORTAL',
  defaultCountry: 'Thailand',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'th'],
  dateFormat: 'DD/MM/YYYY',
  monthFormat: 'MM/YYYY',
  dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
  dateTimeNanoSec: 'YYYY-MM-DDTHH:mm:ss.SSS',
  dateTimeDisplay: 'DD/MM/YYYY HH:mm',
  dateISO8601: 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]',
  uploadConcurrent: 4,
  uploadChunkSize: 10000,
  loadExistingChunkSize: 25000,
  period: {
    short: 1,
    medium: 3,
    long: 6
  },
  buyCoPromotionItem: 3,
  cookies: {
    maxSizePerCookies: 3500,
    bufferTimePeriod: 1800000,
    domain: 'localhost',
    path: '/',
    accessToken: 'bo-access-token',
    refreshToken: 'bo-refresh-token'
  },
  currencyOptions: null,
  fileName: {
    exportDo: {
      prefix: 'Delivery Order Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportCompensate: {
      promotion: 'Promotion Compensate Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportVoucher: {
      prefixReport: 'Voucher Report',
      fileTemplate: 'Voucher Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    toteTransactionReport: {
      prefixReport: 'Tote Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    toteTransactionDCReport: {
      prefixReport: 'Tote Transaction Report (From DC)',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    toteTransactionFromStoreReport: {
      prefixReport: 'Tote Transaction Report (From Store)',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    toteAssetLocationReport: {
      prefixReport: 'Tote Asset Location Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportMoneyIn: {
      prefix: 'Money In Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportDestroyDocument: {
      prefix: 'Destroy Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPr: {
      prefix: 'PR Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPo: {
      prefix: 'Purchase Order Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportClaim: {
      prefix: 'Claim Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportReOrder: {
      prefix: 'Receive Order Report_',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    downloadPdf: {
      timeFormat: 'DDMMYYYYHHmm'
    },
    exportStock: {
      prefixLocation: 'Stock by Location Report',
      prefixProduct: 'Stock by Product Report',
      prefixAdjustment: 'Stock Adjustment Report',
      prefixCard: 'Stock Card Report',
      timeFormat: 'DDMMYYYY_HHmm',
      titleStockAdjustmentTemplate: 'Stock Adjustment Template'
    },
    exportAssortment: {
      prefix: 'TD Assortment Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportAssortmentConfidential: {
      prefix: 'TD Assortment Report (Confidential) ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportOrder: {
      prefix: 'Order Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportSalesTransaction: {
      prefixReport: 'Sales Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportSalesTransactionConfidential: {
      prefixReport: 'Sales Transaction Report (Confidential)',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportDeletedBill: {
      prefixReport: 'Deleted Bill Items Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportSalesSummary: {
      prefixReport: 'Sales Summary Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportGr: {
      prefix: 'Goods Receipt Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportShipment: {
      prefix: 'Shipment Report ',
      timeFormat: 'DDMMYYYY_HHmm',
      fileTemplate: 'Shipment Template'
    },
    exportMonthlyProfit: {
      prefix: 'Monthly Profit Sharing Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportReceiveOrderExceed: {
      prefix: 'Receive Order Exceed Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportStoreAssortment: {
      prefix: 'Store Assortment Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportShelf: {
      prefix: 'Shelf Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportSupplierCouponReconcile: {
      prefix: 'Supplier Coupon Reconcile Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportShelfInventory: {
      prefix: 'Shelf Inventory Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportShelfFixAsset: {
      prefix: 'Shelf Fix Asset Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportInventoryCountTransactionTemp: {
      prefix: 'Inventory Count Transaction Report (with price)',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportInventoryAdjustmentRequest: {
      prefix: 'Inventory Adjustment Request Report (with price)',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportProfitSharing: {
      prefix: 'Profit Sharing Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPromotionCompensate: {
      prefix: 'Promotion Compensate Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportMovingAverage: {
      prefix: 'TD Moving Average Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportPromotion: {
      prefix: 'Promotion Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportStore: {
      prefix: 'Store Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportStoreConfidential: {
      prefix: 'Store Report (Confidential)',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportInventoryCountTransaction: {
      prefix: 'Inventory Count Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportOrderRequest: {
      prefix: 'Order Request',
      timeFormat: 'DDMMYYYY_HHmm',
      fileTemplate: 'Special Order Template'
    },
    exportInventoryVarianceReport: {
      prefix: 'Inventory Variance Report ',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    printStoreAssortmentPdf: {
      prefix: 'Price Tag',
      timeFormat: 'DDMMYYYYHHmm'
    },
    exportRewardTransaction: {
      prefix: 'Reward Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportRedeemTransaction: {
      prefix: 'Redeem Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportBillPayment: {
      prefix: 'Bill Payment Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportSupplierPriceConfigs: {
      prefix: 'Supplier Price',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportDcAssortmentConfigs: {
      prefix: 'DC Assortment Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportMemberCard: {
      prefix: '',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportSupplier: {
      prefix: 'Supplier Report',
      fileTemplate: 'Supplier List Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportRewardRequestConfigs: {
      prefix: 'Reward Request',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportReward: {
      prefix: 'Reward Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportStoreConsultant: {
      prefixReport: '',
      prefix: 'Store Consultant',
      prefixError: 'Store Consultant Error',
      fileTemplate: 'Store Consultant Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },

    exportDestroyTransaction: {
      prefix: 'Destroy Transaction Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportTransferRequestTemplate: {
      prefix: 'Transfer Request Template',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportTransferOrder: {
      prefix: 'Transfer Order Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportAwaitingFirstLot: {
      prefix: 'Awaiting First Lot Report',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportItemCondition: {
      prefix: 'Item Discount Template',
      exported: 'Discount',
      errorExcel: 'Discount Error',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportOrderDeliveryScheduleWeeklyTemplate: {
      prefix: 'Order Delivery Schedule Weekly Template',
      exported: 'Order Delivery Schedule Weekly',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportOrderDeliveryScheduleMonthlyTemplate: {
      prefix: 'Order Delivery Schedule Calendar Template',
      exported: 'Order Delivery Schedule Calendar',
      timeFormat: 'DDMMYYYY_HHmm'
    },
    exportOrderProcessingHold: {
      prefix: 'Order Processing (Hold) Report',
      timeFormat: 'DDMMYYYY_HHmm'
    }
  },
  regexp: {
    imageType: '.*.(gif|jpe?g|bmp|png)$'
  },
  services: {
    base: {
      url: '/lorem',
      serviceAppend: '/{param1}/{param2}',
      headers: {
        'x-host': 'api.lorem.ipsum'
      }
    },
    graphql: {
      url: '/graphql',
      headers: {
        'x-host': 'master.api.tdshop.io'
      }
    },
    auth: {
      url: '/oauth/token',
      headers: {
        Authorization: 'Basic QURNSU5fUE9SVEFMOlBAc3N3MHJk',
        'x-host': 'oauth.api.tdshop.io'
      }
    },
    products: {
      url: '/cjproducts',
      select: '/select',
      getCJArticleDetails: '/{articleNo}',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    productMaster: {
      url: '/cjproducts',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    stores: {
      url: '/stores',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    assortment: {
      url: '/tdproducts',
      get: '/assortment',
      productDetail: '/{articleNo}/details',
      catalogs: '/catalogs',
      exportAssortment: '/export',
      exportAssortmentConfidential: '/confidential/export',
      z8Parameter: '/assortment/z8parameters',
      exportDcAssortment: '/dc-assortment/export/{code}',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    barcodes: {
      url: '/barcodes',
      getList: '/list',
      verifyBarcode: '/exists/{barcodeNumber}',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    deletedBillReport: {
      url: '/pos-sync/audit',
      report: '/export',
      headers: {
        'x-host': 'identity.api.tdshop.io'
      }
    },
    article: {
      url: '/articles',
      getList: '/list',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    productAssortment: {
      url: '/tdproducts/requests',
      headers: {
        'x-host': 'product.api.tdshop.io'
      },
      uploadUrl: '/tdproducts/images',
      bucketName: '/product-assortments-dev'
    },
    freshLiteCategory: {
      url: '/fresh-lite-categories',
      findByName: '/exists',
      checkFreshLite: '/{code}',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    masterData: {
      url: '/product-select-values',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    priceSetting: {
      url: '/price-settings',
      request: '/classes',
      get: '/classes/{classCode}',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    pricing: {
      url: '/pricing',
      request: '/stackprices',
      get: '/stackprices/{articleNo}',
      delete: '/stackprices/delete',
      history: '/stackprices/history/{articleNo}',
      cjPrices: '/cjprices',
      tdPrices: '/tdprices',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    shelf: {
      url: '/shelves',
      view: '/view/{shelfNo}',
      history: '/history/{shelfNo}',
      submitFirstLot: '/firstLot',
      activate: '/activate/{shelfNo}',
      generateItems: '/productList',
      headers: {
        'x-host': 'product.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    shelfRequest: {
      url: '/shelves-requests',
      delete: '/{requestId}',
      history: '/history/{requestId}',
      save: '/save',
      submit: '/submit',
      view: '/{requestId}',
      importBarcodes: '/importBarcodes',
      importFirstLot: '/importFirstLot',
      exportFirstLot: '/exportFirstLot',
      validate: '/validate/{type}/{value}',
      approve: '/approveRequest',
      export: '/export',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    shelfFixAssetRequest: {
      url: '/shelf-fix-asset-requests',
      delete: '/{requestId}',
      history: '/history/{requestId}',
      save: '/save',
      submit: '/submit',
      view: '/{requestId}',
      importBarcodes: '/importBarcodes',
      importFirstLot: '/importFirstLot',
      exportFirstLot: '/exportFirstLot',
      validate: '/validate/{type}/{value}',
      approve: '/approveRequest',
      export: '/export',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    shelfInventoryRequest: {
      url: '/shelf-inventory-requests',
      delete: '/{requestId}',
      history: '/history/{requestId}',
      save: '/save',
      submit: '/submit',
      view: '/{requestId}',
      importBarcodes: '/importBarcodes',
      importFirstLot: '/importFirstLot',
      exportFirstLot: '/exportFirstLot',
      validate: '/validate/{type}/{value}',
      approve: '/approveRequest',
      export: '/export',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    shelfInventory: {
      url: '/shelf-inventories',
      view: '/view/{shelfNo}',
      history: '/history/{shelfNo}',
      submitFirstLot: '/firstLot',
      activate: '/activate/{shelfNo}',
      getShelfList: '/firstLot',
      generateItems: '/details',
      export: '/export',
      headers: {
        'x-host': 'product.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    shelfFixAsset: {
      url: '/shelf-fix-assets',
      view: '/view/{shelfNo}',
      history: '/history/{shelfNo}',
      submitFirstLot: '/firstLot',
      activate: '/activate/{shelfNo}',
      generateItems: '/productList',
      generateFixAssetItems: '/details',
      export: '/export',
      headers: {
        'x-host': 'product.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    merchantSelectValue: {
      url: '/merchant-select-values',
      headers: {
        'x-host': 'merchant.api.tdshop.io'
      }
    },
    merchantsRequest: {
      url: '/merchants-requests',
      validate: '/validate/{type}/{value}',
      save: '/save',
      submit: '/submit',
      fileUrl: '/fileUrl',
      view: '/{merchantRequestId}',
      approveRequest: '/approveRequest',
      delete: '/{merchantRequestId}',
      history: '/history/{merchantRequestId}',
      getMerchantVersion: '/getVersion/{merchantRequestId}',
      getShelfDetails: '/getShelfDetails/{storeCode}',
      headers: {
        'x-host': 'merchant.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    merchant: {
      url: '/merchants',
      viewMerchant: '/view/{merchant}',
      allStore: '/all/stores',
      listStore: '/{merchant}/stores',
      generateUser: '/{merchant}/generateOwner',
      addResetOwnerLog: '/{merchant}/addResetOwnerLog',
      history: '/history/{merchant}',
      getStore: '/{merchant}/stores',
      headers: {
        'x-host': 'merchant.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    storeGroup: {
      url: '/merchants',
      submit: '/{merchantId}/groups',
      get: '/{merchantId}/groups/{groupId}',
      groupList: '/groups/list',
      headers: {
        'x-host': 'merchant.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    storeConsultant: {
      url: '/store-consultants',
      get: '/{userId}',
      submit: '/submit',
      downloadFileTemplate: '/import/templates',
      validateFile: '/import/validate',
      importSubmit: '/import/submit',
      export: '/export',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    store: {
      url: '/stores',
      view: '/view/{storeNo}',
      generatePOSRegisCode: '/{storeNo}/generatePos',
      updatePOSRegisCode: '/{storeNo}/updatePos',
      deletePOS: '/{storeNo}/deletePos',
      deactivatePOS: '/{storeNo}/deactivatePos',
      orderSchedule: '/orderSchedule',
      history: '/history/{storeNo}',
      export: '/export',
      exportStoreConfidential: '/confidential/export',
      exportMoneyIn: '/export/moneyIn',
      exportBillPayment: '/export/billPayment',
      printStoreAssortmentPdf: '/print/store-pos-price-tag/{storeNo}/{format}/{locale}',
      activate: '/{storeNo}/status/activate',
      deactivate: '/{storeNo}/status/deactivate',
      exportAdjustment: '/{exportBy}/adjusts/export',
      inventoryCount: '/{exportBy}/export',
      exportNoWSP: '/{exportBy}/no-wsp/export',
      exportInventoryVariance: '/count/exportInventoryVariance/pdf/th',
      redeemTransaction: '/redeem-transaction/export',
      rewardTransaction: '/reward-transaction/export',
      imageSignedUrl: '/imageSignedUrl',
      exportInventoryVarianceNestPDF: '/count/inventoryVariance/nest/pdf/th',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      },
      headersOrder: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    storeRequest: {
      url: '/stores-requests',
      validate: '/validate/{type}/{value}',
      save: '/save',
      submit: '/submit',
      delete: '/{storeId}',
      view: '/{storeId}',
      history: '/history/{storeId}',
      getStoreVersion: '/getVersion/{storeId}',
      approve: '/approveRequest',
      headers: {
        'x-host': 'merchant.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    destroy: {
      url: '/destroys',
      exportDestroyTransaction: '/export',
      allDestroyDocument: '/all/doc',
      exportDestroyDocument: '/{docNo}/print/pdf/th',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    salesSummaryReport: {
      url: '/sales-summary',
      export: '/export',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    users: {
      url: '/users',
      get: '/{no}',
      uploadUserFileAppend: '/{userName}/files',
      deleteUserFileAppend: '/{userName}/files/{fileName}',
      resetPassword: '/{userName}/resetPassword',
      resetPinCode: '/{userName}/resetPinCode',
      activateUser: '/{userName}/activate/{status}',
      createOTP: '/{userName}/otp',
      verifyOTP: '/{userName}/verifyOtp',
      forgotPassword: '/{userName}/forgetPassword',
      forgotUsername: '/{mobileNumber}/forgetUserName',
      forceChangePassword: '/{userName}/forceChangePassword',
      list: '/list',
      headers: {
        'x-host': 'identity.api.tdshop.io'
      }
    },
    dashboard: {
      url: '',
      tasksByRole: '/tasks',
      headers: {
        'x-host': 'identity.api.tdshop.io'
      }
    },
    order: {
      url: '/orders',
      view: '/view/{orderNo}',
      print: '/{id}/print/{format}/{locale}',
      export: '/export',
      exportAwaitingFirstLot: '/reports/awaiting-first-lot',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    orderRequest: {
      url: '/orders-requests',
      save: '/save',
      submit: '/submit',
      delete: '/{orderId}',
      view: '/{orderId}',
      viewReferenceNo: '/view/{orderRequestNo}',
      history: '/history/{orderId}',
      approve: '/approveRequest',
      getStoreFirstLot: '/firstlot/stores',
      getStoreSpecial: '/replenish/stores',
      validateOrderList: '/validate/orderList',
      downloadFileTemplate: '/imports/templates',
      importValidate: '/imports/validates',
      importSubmit: '/imports/submits',
      getEstimatedDelivery: '/estimated/{storeNo}/delivery/{deliveryDate}',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    orderRequestFixAsset: {
      url: '/orders-requests/fix-asset',
      save: '/save',
      submit: '/submit',
      storeUseSubmit: '/storeuse/submit',
      delete: '/{orderId}',
      view: '/{orderId}',
      history: '/history/{orderId}',
      approve: '/approveRequest',
      getStore: '/firstlot/stores',
      validateOrderList: '/validate/orderList',
      downloadFileTemplate: '/imports/templates',
      importValidate: '/imports/validates',
      importSubmit: '/imports/submits',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    orderRequestInventory: {
      url: '/orders-requests/inventory',
      save: '/save',
      submit: '/submit',
      assignQuantitySubmit: '/assign-quantity/submit',
      assignQuantitySave: '/assign-quantity/save',
      delete: '/{orderId}',
      view: '/{orderId}',
      approve: '/approveRequest',
      getStore: '/firstlot/stores',
      downloadFileTemplate: '/imports/templates',
      importUpdateQtyValidate: '/imports/{orderRequestId}',
      importAddSelectiveItem: '/importSelectiveItems/validate',
      importSubmit: '/imports/submits',
      export: '/export/{orderRequestId}',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    exportOrderRequestFixAsset: {
      url: '/orders-requests-fix-asset',
      export: '/export/{selected}',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    orderRequestStoreUse: {
      url: '/tdproducts',
      export: '/exportProductOrderList',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    orderRequestSelective: {
      url: '/tdproducts',
      export: '/catalogs/export',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    receiveOrder: {
      url: '/receive-orders',
      getByNo: '/{receiveOrderNo}',
      export: '/export',
      toteExport: '/tote/export',
      exceedExport: '/exceed/export',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    toteTransaction: {
      url: '/tote-transactions',
      exportDC: '/dc/export',
      exportStore: '/store/export',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    toteAssetLocation: {
      url: '/tote-asset-location',
      export: '/export',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    deliveryOrder: {
      url: '/delivery-orders',
      get: '/{id}',
      edit: '/edit',
      update: '/{id}/split/draft',
      submit: '/{id}/split/submit',
      exportPicking: '/export-picking',
      importPicking: '/import-picked',
      print: '/{id}/print/{format}/{locale}',
      cancel: '/cancel',
      headers: {
        'x-host': 'order.api.tdshop.io'
      }
    },
    shelfType: {
      url: '/shelf-types',
      uploadOrder: '/uploadOrder',
      generateOrder: '/generateOrder',
      getShelfTypes: '/getShelfTypes',
      fileUrl: '/fileUrl',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    shipment: {
      url: '/shipments',
      update: '/{shipmentNo}',
      getByShipmentNumber: '/{shipmentNo}',
      deleteByShipmentNumber: '/{shipmentNo}',
      confirmLoad: '/confirm-load',
      dispatch: '/{shipmentNo}/dispatch',
      exportShipment: '/export',
      downloadFileTemplate: '/import/templates',
      submitImport: '/import/submits',
      importValidate: '/import/validates',
      headers: {
        'x-host': 'order.api.tdshop.io'
      }
    },
    claim: {
      url: '/crs',
      get: '/get/{claimRequestNo}',
      releaseCn: '/{claimRequestNo}/release-cn',
      approve: '/approve',
      reject: '/{claimRequestNo}/reject',
      fileUrl: '/imageSignedUrl',
      exportCrs: '/export',
      draft: '/draft/{receiveOrderNo}',
      headers: {
        'x-host': 'order.api.tdshop.io'
      }
    },
    promotions: {
      url: '/promotions',
      export: '/export',
      get: '/{id}',
      themes: '/themes',
      themesList: '/themes/list',
      cancel: '/cancel',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    promotionsRequest: {
      url: '/promotion-requests',
      get: '/{id}',
      reject: '/reject',
      approve: '/approve',
      requestCancel: '/cancel',
      requestsSubmit: '/submit',
      requestsID: '/{requestId}',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    purchaseConditionRequest: {
      url: '/purchase-condition-request',
      exportTemplate: '/discount/export',
      discount: '/discount',
      import: '/discount/import',
      deleteImportFile: '/discount/import/{previousImportId}',
      exportOrderDeliveryScheduleWeeklyTemplate: '/weekly-schedule/export',
      importOrderDeliveryScheduleWeeklyTemplate: '/weekly-schedule/import',
      exportOrderDeliveryScheduleMonthlyTemplate: '/monthly-schedule/export',
      importOrderDeliveryScheduleMonthlyTemplate: '/monthly-schedule/import',
      DeliveryScheduleWeekly: '/weekly-schedule',
      orderDeliveryScheduleWeekly: '/weekly-schedule',
      orderDeliveryScheduleMonthly: '/monthly-schedule',
      purchaseConditionList: '/purchase-condition',
      exists: '/isPendingForUpdate/{supplierId}',
      get: '/view/{id}',
      submit: '/submit',
      approve: '/approve',
      reject: '/reject',
      cancel: '/cancel',
      headers: {
        'x-host': 'purchase.api.tdshop.io'
      }
    },
    purchaseCondition: {
      url: '/purchase-condition',
      headers: {
        'x-host': 'purchase.api.tdshop.io'
      },
      getViewById: '/view/{supplierCode}',
      discount: '/discount',
      weeklySchedule: '/weekly-schedule',
      monthlySchedule: '/monthly-schedule',
      supplierDc: '/supplier-dc',
      supplierContract: '/supplier-contact',
      deleteContract: '/supplier-contact/{id}',
      deleteDc: '/supplier-dc/{id}',
      exportWeekly: '/weekly-schedule/export',
      exportCalendar: '/monthly-schedule/export',
      exportDiscount: '/discount/export'
    },
    purchaseRequest: {
      url: '/purchase-requests',
      get: '/{id}',
      deliveryDetail: '/deliveryDetail/default',
      contacts: '/contacts',
      deleted: '/{id}',
      cancel: '/cancel',
      submit: '/submit',
      approve: '/approve',
      reject: '/reject',
      supplierPromotions: '/supplier/promotions',
      purchaseProductPrice: '/purchase-product-prices',
      exportPr: '/export',
      import: '/import',
      headers: {
        'x-host': 'purchase.api.tdshop.io'
      }
    },
    purchaseOrder: {
      url: '/purchase-orders',
      get: '/{id}',
      cancel: '/cancel',
      close: '/close',
      print: '/{id}/print/{format}/{locale}',
      exportPo: '/export',
      headers: {
        'x-host': 'purchase.api.tdshop.io'
      }
    },
    goodsReceive: {
      url: '/goods-receives',
      getByPoNo: '/{poNo}',
      getById: '/view/{id}',
      submit: '/submit',
      cancel: '/cancel',
      exportGr: '/export',
      headers: {
        'x-host': 'purchase.api.tdshop.io'
      }
    },
    supplierGroupRequest: {
      url: '/supplier-group-requests',
      get: '/{id}',
      getStoreSupplier: '/{id}/store-suppliers',
      submit: '/submit',
      exportTemplate: '/export-template',
      export: '/export',
      import: '/import',
      approve: '/approve',
      reject: '/reject',
      cancel: '/cancel',
      delete: '/{id}',
      checkExisting: '/{supplierGroupNo}/exists',
      headers: {
        'x-host': 'supplier.api.tdshop.io'
      }
    },
    supplierGroup: {
      url: '/supplier-groups',
      get: '/{id}',
      getStoreSupplier: '/{id}/store-suppliers',
      import: '/{supplierGroupNo}/import',
      export: '/{supplierGroupNo}/export',
      headers: {
        'x-host': 'supplier.api.tdshop.io'
      }
    },
    groupSupplierStores: {
      url: '/group-supplier-stores',
      get: '/{id}',
      getStoreSupplier: '/{id}/store-suppliers',
      headers: {
        'x-host': 'supplier.api.tdshop.io'
      }
    },
    vouchers: {
      url: '/vouchers',
      cancel: '/cancel',
      get: '/{voucherCode}',
      export: '/export',
      report: '/report',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    advertisements: {
      url: '/advertisements',
      defaultAds: '/default',
      get: '/{adsNo}',
      submit: '/submit',
      cancel: '/cancel',
      uploadUrl: '/images',
      imageDirectory: '/images/{directory}',
      bucketName: '/pos-ads-dev',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    vouchersRequest: {
      url: '/voucher-requests',
      requestsID: '/{requestId}',
      submit: '/submit',
      approve: '/approve',
      reject: '/reject',
      cancel: '/cancel',
      importValidate: '/import/validate',
      importTemplates: '/import/templates',
      importSubmit: '/import/submit',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    vouchersReport: {
      url: '/vouchers',
      report: '/report',
      headers: {
        'x-host': 'billing.api.tdshop.io'
      }
    },
    supplierStore: {
      url: '/supplier-stores',
      headers: {
        'x-host': 'supplier.api.tdshop.io'
      }
    },
    supplier: {
      url: '/suppliers',
      importValidate: '/import/validate',
      downloadFileTemplate: '/import/templates',
      importSubmit: '/import/submit',
      export: '/export',
      list: '/list',
      get: '/{supplierCode}',
      activate: '/activate',
      validate: '/validate',
      ediSetting: '/ediSetting',
      headers: {
        'x-host': 'supplier.api.tdshop.io'
      }
    },
    rewardRequest: {
      url: '/reward-requests',
      requestsID: '/{requestId}',
      submit: '/submit',
      approve: '/approve',
      reject: '/reject',
      cancel: '/cancel',
      import: '/import',
      export: '/export',
      validate: '/validate',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    reward: {
      url: '/rewards',
      get: '/{rewardNo}',
      cancel: '/cancel',
      export: '/export',
      active: '/active/list',
      summary: '/active/summary',
      headers: {
        'x-host': 'marketing.api.tdshop.io'
      }
    },
    role: {
      url: '/roles',
      get: '/{roleNo}',
      list: '/list',
      headers: {
        'x-host': 'identity.api.tdshop.io'
      }
    },
    permission: {
      url: '/permissions',
      headers: {
        'x-host': 'identity.api.tdshop.io'
      }
    },
    stocks: {
      url: '/stocks',
      warehouseArticleNo: '/{articleNo}',
      warehouseMovement: '/{warehouse}/{articleNo}/movements',
      stockByLocationType: '/{locationType}/{articleNo}',
      adjusts: '/adjusts',
      adjustsValidate: '/adjusts/validate',
      getAdjustStoreByDocNo: '/adjusts/{docNo}',
      getAdjustStoreItemsByDocNo: '/adjusts/{docNo}/items',
      export: '/{exportBy}/export',
      importAdjustsValidate: '/adjust/import/validate',
      importAdjusts: '/adjust/import',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    stocksLocation: {
      url: '/locations',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    stockTransferRequest: {
      url: '/stocks',
      validate: '/transfer-request/validate',
      list: '/transfer/list',
      create: '/transfer/create',
      exportTemplate: '/transfer-request/export',
      get: '/transfer/view/{id}',
      delete: '/transfer/delete/{id}',
      cancel: '/transfer-request/cancel',
      update: '/transfer/update',
      approval: '/transfer-request/approval',
      import: '/transfer-request/import',
      submit: '/transfer-request/submit',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    stockTransfer: {
      url: '/stocks',
      list: '/transfer-order/list',
      get: '/transfer-order/{docNo}',
      close: '/transfer-order/close',
      export: '/transfer-order/export',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    stockProduct: {
      url: '/tdproducts',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    salesTransactionReport: {
      url: '/sales-transactions',
      export: '/export',
      exportConfidential: '/confidential/export',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    supplierCouponReconcileReport: {
      url: '/supplier-coupon-reconcile',
      export: '/export',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    storeAssortment: {
      url: '/store-assortments',
      view: '/view/{storeNo}',
      history: '/history/{storeNo}',
      list: '/list',
      getAssortment: '/{storeNo}',
      getZ8Factor: '/z8Factor/{id}',
      downloadTemplate: '/store-assortments-template/export',
      importStoreAssorement: '/import',
      exportByProduct: '/exportByProduct',
      exportByStore: '/exportByStore',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    storeAssortmentRequest: {
      url: '/store-assortment-requests',
      view: '/{requestId}',
      validateStoreAssortment: '/validate',
      submit: '/submit',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    monthlyPartnerProfitSharing: {
      url: '/monthly-partner-profit-sharing',
      report: '/reportSignedUrl/{id}',
      uploadMonthlyPartnerProfitSharingFileAppend: '/validate-import/{runDate}',
      submit: '/submit',
      headers: {
        'x-host': 'billing.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    profitSharing: {
      url: '/partner-profit-sharing',
      export: '/export',
      print: '/print/{runDate}/{docNo}',
      view: '/view/{id}',
      headers: {
        'x-host': 'billing.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    promotionCompensateReport: {
      url: '/promotion-compensate',
      export: '/report',
      headers: {
        'x-host': 'billing.api.tdshop.io'
      }
    },
    movingAverageReport: {
      url: '/moving-average',
      export: '/export',
      headers: {
        'x-host': 'stock.api.tdshop.io'
      }
    },
    z8Parameter: {
      url: '/z8parameters',
      export: '/export',
      submit: '/submit',
      history: '/history/{id}',
      headers: {
        'x-host': 'order.api.tdshop.io'
      }
    },
    z8conditions: {
      url: '/z8conditions',
      result: '/result',
      continue: '/continue',
      headers: {
        'x-host': 'order.api.tdshop.io'
      }
    },
    z8ParameterRequest: {
      url: '/z8parameters-requests',
      headers: {
        'x-host': 'order.api.tdshop.io'
      }
    },
    z8Result: {
      url: '/z8-results',
      import: '/import',
      export: '/fileUrl',
      history: '/history/{id}',
      headers: {
        'x-host': 'order.api.tdshop.io'
      }
    },
    supplierPrice: {
      url: '/supplier-price-configs',
      get: '/article-price-configs',
      import: '/{deliveryMethod}/import',
      export: '/export',
      headers: {
        'x-host': 'product.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    warehousesStores: {
      url: '/warehouses-stores',
      headers: {
        'x-host': 'product.api.tdshop.io'
      }
    },
    membership: {
      url: '/membership',
      memberCard: '/member-card',
      memberCardExport: '/member-card/export',
      headers: {
        'x-host': 'membership.api.tdshop.io'
      }
    },
    z8HoldRequest: {
      url: '/z8hold-requests',
      export: '/export',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    }
  }
};
