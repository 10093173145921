import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import * as uuid from 'uuid';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { FilesSubmitComponent } from '../../../shared/components/files-submit/files-submit.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ApproveStatusEnum } from '../../../shared/enum/approve-status.enum';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { OrderingMethodEnum, OrderSubTypeEnum } from '../../../shared/enum/ordering-method.enum';
import { NewRequestStatusEnum } from '../../../shared/enum/request-status.enum';
import { RequestPageModesEnum } from '../../../shared/enum/request-step.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { PortalModule, RouteLinkTab, StoreList, TaskModuleUrl } from '../../../shared/models';
import { AuditLog, HistoryType } from '../../../shared/models/audit-log.model';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { OrderRequestList, OrderRequestListSearchCriteria } from '../../../shared/models/order-request.model';
import { WarehouseListContent } from '../../../shared/models/warehouse.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { MerchantService } from '../../../shared/services/merchant.service';
import { OrderFixAssetRequestService } from '../../../shared/services/order-fix-asset-request.service';
import { OrderInventoryRequestService } from '../../../shared/services/order-inventory-request.service';
import { OrderRequestService } from '../../../shared/services/order-request.service';
import { LayoutActionLoadError } from '../../../shared/store/actions/layout.action';
import { OrderFixAssetRequestByStoreResponseAction } from '../../../shared/store/actions/order-fix-asset-request.actions';
import { OrderInventoryRequestByStoreResponseAction } from '../../../shared/store/actions/order-inventory-request.actions';
import {
  OrderRequestByStoreResponseAction,
  OrderRequestHistoryRequestAction,
  OrderRequestHistoryResponseAction,
  OrderRequestListRequestAction
} from '../../../shared/store/actions/order-request.actions';
import { WarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { OrderRequestState } from '../../../shared/store/reducers/order-request.reducers';
import {
  selectAllOrderRequestList,
  selectOrderRequestHistory,
  selectOrderRequestList,
  selectOrderRequestListCriteria
} from '../../../shared/store/selectors/order-request.selector';
import { selectAllWarehouse } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { b64toBlob } from '../../../shared/utils/b64toBlob-util';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { ModuleUtil } from '../../../shared/utils/module-util';
import { OrderRequestFixAssetComponent } from '../order-request-fix-asset/order-request-fix-asset.component';
import { OrderRequestInventoryComponent } from '../order-request-inventory/order-request-inventory.component';
import { OrderRequestComponent } from '../order-request/order-request.component';

@Component({
  selector: 'app-order-request-list',
  templateUrl: './order-request-list.component.html',
  styleUrls: ['./order-request-list.component.scss']
})
export class OrderRequestListComponent extends BaseSearchComponent<
  OrderRequestListSearchCriteria,
  OrderRequestList,
  OrderRequestState
> {
  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    protected authGuardService: AuthGuardService,
    private readonly orderRequestService: OrderRequestService,
    private readonly orderFixAssetRequestService: OrderFixAssetRequestService,
    private readonly orderInventoryRequestService: OrderInventoryRequestService,
    private readonly merchantService: MerchantService,
    private readonly masterService: MasterService
  ) {
    super(store, modalService, selectAllOrderRequestList, selectOrderRequestList);
    super.subscribeForSaveSuccess();
  }

  get orderingMethodEnum() {
    return OrderingMethodEnum;
  }
  @ViewChild('modalCreateNewOrder', { static: false }) modalCreateNewOrder: ModalDirective;
  @ViewChild('storeSelect', { static: false }) storeSelect: NgSelectComponent;

  private bsModalRef: BsModalRef;
  private localStore: Observable<any>;
  private guid: string;

  public listRoute: Array<RouteLinkTab>;

  public dateFormat = environment.dateFormat;
  public minDate: Date;
  public maxDate: Date;
  public dateTag: string;
  public dateStringTag: string;
  public storeTypeTag: string;
  public storeTypeStringTag: string;
  public orderTypeTag: string;
  public orderTypeStringTag: string;
  public orderSubtypeTag: string;
  public orderSubtypeStringTag: string;
  public warehouseTag: string;
  public warehouseStringTag: string;

  public storeList: Observable<StoreList[]>;
  public storeSearchLoading: boolean;
  public storeSearchInput$ = new Subject<string>();
  public warehouseList$: Observable<WarehouseListContent[]>;
  public warehouseList: WarehouseListContent[];
  public storeForm: FormGroup;
  public submitted: boolean;

  public storeTypeFilter: NgOption;
  public orderRequestStatusFilter = filterDropdown.orderRequestStatusFilter;
  public orderTypeFilter = filterDropdown.orderingMethodFilter;
  public orderSubtypeAdvancedFilter = filterDropdown.orderSubtypeAdvancedFilter;
  public orderSubtypeFilter: NgOption;
  public orderTypeEnum = OrderingMethodEnum;
  public createOrderType: OrderingMethodEnum;
  public selectStoreFormValid = false;

  public auditLogs$: Observable<AuditLog[]>;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.IMPORT,
      name: 'Import',
      hidden: !this.authGuardService.checkPermission(['so_special_m'])
    }
  ];

  public filesSubmitModal: BsModalRef;
  public errorFile: Blob;

  doInit() {
    this.createStoreForm();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectOrderRequestListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.masterService
      .getMasterDataByNames([MasterDataEnum.MERCHANT])
      .pipe(untilComponentDestroyed(this))
      .subscribe(result => {
        if (result.data) {
          this.storeTypeFilter = result.data.merchants;
        }
      });

    this.store.dispatch(new WarehouseListRequestAction('-'));
    this.localStore.pipe(select(selectAllWarehouse('Warehouse'))).subscribe(data => {
      this.warehouseList = data;
    });

    this.auditLogs$ = this.localStore.pipe(select(selectOrderRequestHistory));
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      requestedDateFrom: [null],
      requestedDateTo: [null],
      status: [this.orderRequestStatusFilter[0].value],
      storeType: [null],
      orderType: [null],
      orderSubtype: [null],
      warehouseCode: [null]
    });
  }

  createStoreForm() {
    this.storeForm = this.fb.group({
      orderSubtype: null,
      store: [{ value: null, disabled: false }, Validators.required],
      warehouseCode: [{ value: null, disabled: true }, Validators.required],
      orderType: null
    });
  }

  setRouteTab() {
    const hasOrderListPagePermission = this.authGuardService.checkPermission([
      'so_v',
      'or_fl_ast_m',
      'or_st_equip_m',
      'or_fl_inv_m',
      'or_fl_qty_inv_m'
    ]);
    const hasOrderRequestListPagePermission = this.authGuardService.checkPermission([
      'or_v',
      'so_firstlot_m',
      'so_special_m',
      'so_app',
      'or_fl_ast_m',
      'or_fl_ast_app',
      'or_st_equip_m',
      'or_fl_inv_m',
      'or_fl_inv_app',
      'or_fl_qty_inv_m'
    ]);

    this.listRoute = [];

    if (hasOrderListPagePermission) {
      this.listRoute.push({
        tabName: 'ORDER.ORDER_LIST',
        url: '/order/order-list'
      });
    }

    if (hasOrderRequestListPagePermission) {
      this.listRoute.push({
        tabName: 'ORDER_REQUEST.ORDER_REQUEST',
        url: '/order/order-request-list'
      });
    }
  }

  onchangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterDate() {
    this.setFirstPage();
    this.searchForm.controls['requestedDateFrom'].reset();
    this.searchForm.controls['requestedDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      requestedDateFrom: null,
      requestedDateTo: null
    };
    this.search(this.criteriaObject);
  }

  clearFilterStoreType() {
    this.setFirstPage();
    this.searchForm.controls['storeType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      storeType: null
    };
    this.search(this.criteriaObject);
  }

  clearFilterWarehouse() {
    this.setFirstPage();
    this.searchForm.controls['warehouseCode'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      warehouseCode: null
    };
    this.search(this.criteriaObject);
  }

  clearFilterOrderType() {
    this.setFirstPage();
    this.searchForm.controls['orderType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      orderType: null
    };
    this.search(this.criteriaObject);
  }

  clearFilterOrderSubtype() {
    this.setFirstPage();
    this.searchForm.controls['orderSubtype'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      orderSubType: null
    };
    this.search(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['requestedDateFrom'].reset();
    this.searchForm.controls['requestedDateTo'].reset();
    this.searchForm.controls['storeType'].reset();
    this.searchForm.controls['orderType'].reset();
    this.searchForm.controls['orderSubtype'].reset();
    this.searchForm.controls['warehouseCode'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      requestedDateFrom: null,
      requestedDateTo: null,
      storeType: null,
      orderType: null,
      orderSubType: null,
      warehouseCode: null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;
    this.storeTypeTag = null;
    this.storeTypeStringTag = null;
    this.orderTypeTag = null;
    this.orderTypeStringTag = null;
    this.orderSubtypeTag = null;
    this.orderSubtypeStringTag = null;
    this.warehouseTag = null;
    this.warehouseStringTag = null;

    const requestedDateFrom = dateStringToTagCriteria(this.criteriaObject.requestedDateFrom);
    const requestedDateTo = dateStringToTagCriteria(this.criteriaObject.requestedDateTo);
    const requestedDate = generateDateStringTag({
      dateName: 'Requested Date',
      dateFrom: requestedDateFrom,
      dateTo: requestedDateTo
    });

    this.dateStringTag = requestedDate.dateStringTag;
    this.dateTag = requestedDate.dateTag;

    if (this.criteriaObject.storeType && this.criteriaObject.storeType.length) {
      this.storeTypeStringTag = 'Store Type';
      const types = this.storeTypeFilter
        .filter(data => this.criteriaObject.storeType.includes(data.code))
        .map(data => data.nameTh)
        .join(', ');
      this.storeTypeTag = `"${types}"`;
    }

    if (this.criteriaObject.orderType && this.criteriaObject.orderType.length) {
      this.orderTypeStringTag = 'Order Type';
      const types = this.orderTypeFilter
        .filter(data => this.criteriaObject.orderType.includes(data.value))
        .map(data => data.label)
        .join(', ');
      this.orderTypeTag = `"${types}"`;
    }

    if (this.criteriaObject.orderSubType && this.criteriaObject.orderSubType.length) {
      this.orderSubtypeStringTag = 'Order Subtype';
      const types = this.orderSubtypeAdvancedFilter
        .filter(data => this.criteriaObject.orderSubType.includes(data.value))
        .map(data => data.label)
        .join(', ');
      this.orderSubtypeTag = `"${types}"`;
    }

    if (this.criteriaObject.warehouseCode && this.criteriaObject.warehouseCode.length) {
      this.warehouseStringTag = 'Warehouse';
      const types = this.warehouseList
        .filter(data => this.criteriaObject.warehouseCode.includes(data.code))
        .map(data => data.warehouseNameDisplay)
        .join(', ');
      this.warehouseTag = `"${types}"`;
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;

    if (
      !formValue.requestedDateFrom &&
      !formValue.requestedDateTo &&
      !formValue.storeType &&
      !formValue.orderType &&
      !formValue.orderSubtype &&
      !formValue.warehouseCode
    ) {
      return;
    }

    let dateFrom = this.searchForm.value.requestedDateFrom;
    let dateTo = this.searchForm.value.requestedDateTo;

    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      requestedDateFrom: dateFrom,
      requestedDateTo: dateTo,
      storeType: formValue.storeType && formValue.storeType.length > 0 ? formValue.storeType.toString() : null,
      orderType: formValue.orderType && formValue.orderType.length > 0 ? formValue.orderType.toString() : null,
      orderSubType:
        formValue.orderSubtype && formValue.orderSubtype.length > 0 ? formValue.orderSubtype.toString() : null,
      warehouseCode:
        formValue.warehouseCode && formValue.warehouseCode.length > 0 ? formValue.warehouseCode.toString() : null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new OrderRequestListRequestAction(criteriaObj));
  }

  goToCreate() {
    this.submitted = true;

    if (this.storeForm.invalid) {
      return;
    }

    const mode = RequestPageModesEnum.REQUEST_CREATE;
    const warehouseCode = this.storeForm.controls.warehouseCode.value;
    const orderType = this.storeForm.controls.orderType.value;
    const orderSubType = this.storeForm.controls.orderSubtype.value;
    let component: any;

    if (orderSubType === OrderSubTypeEnum.FIX_ASSET_AND_STORE_USE) {
      component = OrderRequestFixAssetComponent;
    } else if (orderSubType === OrderSubTypeEnum.INVENTORY) {
      component = OrderRequestInventoryComponent;
    } else {
      component = OrderRequestComponent;
    }

    if (orderType === OrderingMethodEnum.FIRST_LOT_ORDER) {
      if (orderSubType === OrderSubTypeEnum.FIX_ASSET_AND_STORE_USE) {
        this.store.dispatch(new OrderFixAssetRequestByStoreResponseAction(this.storeForm.controls.store.value));
      } else if (orderSubType === OrderSubTypeEnum.INVENTORY) {
        this.store.dispatch(new OrderInventoryRequestByStoreResponseAction(this.storeForm.controls.store.value));
      } else {
        this.store.dispatch(new OrderRequestByStoreResponseAction(this.storeForm.controls.store.value));
      }
    } else {
      this.store.dispatch(new OrderRequestByStoreResponseAction(this.storeForm.controls.store.value));
    }

    const initialState = {
      title: null,
      childItem: new ChildItem(
        component,
        {
          title: OrderRequestListComponent.getOrderRequestTitle(mode),
          mode,
          orderSubType: orderSubType,
          orderType: this.storeForm.controls.orderType.value,
          warehouseCode,
          warehouseDisplayName: this.getSelectedWarehouse(warehouseCode)
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    this.hideModalCreateNewOrder();

    return;
  }

  goToView(item) {
    const mode = RequestPageModesEnum.REQUEST_VIEW;
    const orderSubType = item.orderSubType;

    let component: any;
    if (orderSubType === OrderSubTypeEnum.FIX_ASSET_AND_STORE_USE) {
      component = OrderRequestFixAssetComponent;
    } else if (orderSubType === OrderSubTypeEnum.INVENTORY) {
      component = OrderRequestInventoryComponent;
    } else {
      component = OrderRequestComponent;
    }

    const initialState = ModuleUtil.InitialState(
      item,
      component,
      mode,
      OrderRequestListComponent.getOrderRequestTitle(mode),
      TaskModuleUrl.ORDER_REQUEST,
      PortalModule.ORDER
    );

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    return;
  }

  goToEdit(item) {
    const mode = RequestPageModesEnum.REQUEST_EDIT;
    const orderSubType = item.orderSubType;

    let component: any;
    if (orderSubType === OrderSubTypeEnum.FIX_ASSET_AND_STORE_USE) {
      component = OrderRequestFixAssetComponent;
    } else if (orderSubType === OrderSubTypeEnum.INVENTORY) {
      component = OrderRequestInventoryComponent;
    } else {
      component = OrderRequestComponent;
    }

    const initialState = ModuleUtil.InitialState(
      item,
      component,
      mode,
      OrderRequestListComponent.getOrderRequestTitle(mode),
      TaskModuleUrl.ORDER_REQUEST,
      PortalModule.ORDER
    );

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    return;
  }

  deleteOrderRequest(id) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        okText: 'Yes, delete',
        cancelText: 'Cancel',
        message: 'Are you sure you want to delete this request?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.orderRequestService
            .deleteByRequestId({ orderId: id })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been deleted.');
              },
              error => {
                this.store.dispatch(new LayoutActionLoadError(error));
              }
            );
          if (confirmModalRef.content.actions) {
            confirmModalRef.content.actions.unsubscribe();
          }
        }
      });
  }

  cancelOrderRequest(requestNo: string, orderSubType: string) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number <strong>&quot;${requestNo}&quot;</strong>?`,
        label: 'Reason',
        okText: 'Yes, cancel',
        cancelText: 'Cancel',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          let orderRequestServices: any;
          if (orderSubType === OrderSubTypeEnum.FIX_ASSET_AND_STORE_USE) {
            orderRequestServices = this.orderFixAssetRequestService;
          } else if (orderSubType === OrderSubTypeEnum.INVENTORY) {
            orderRequestServices = this.orderInventoryRequestService;
          } else {
            orderRequestServices = this.orderRequestService;
          }

          orderRequestServices
            .approveRequest({
              requestNo,
              status: ApproveStatusEnum.CANCELLED,
              comment: confirmModalRef.content.confirmMessage
            })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been cancelled.');
                this.doSearch(this.criteriaObject);
              },
              error => {
                this.store.dispatch(new LayoutActionLoadError(error));
              }
            );

          if (confirmModalRef.content.actions) {
            confirmModalRef.content.actions.unsubscribe();
          }
        }
      });
  }

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
  }

  getSelectedWarehouse(warehouseCode: string) {
    return this.warehouseList.length > 0 && warehouseCode
      ? this.warehouseList.find(x => x.code === warehouseCode).warehouseNameDisplay
      : '-';
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.store.dispatch(new OrderRequestListRequestAction(this.criteriaObject));
      if (alertModal.content.actions) {
        alertModal.content.actions.unsubscribe();
      }
    });
  }

  showHistory(orderId: string, requestNo: string) {
    this.store.dispatch(new OrderRequestHistoryRequestAction({ orderId }));
    const initialState = {
      title: 'History',
      historyHeader: `Request No.: ${requestNo}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.ORDER_REQUEST,
      auditLogs$: this.auditLogs$
    };

    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new OrderRequestHistoryResponseAction({ auditLogs: null }));
    });
  }

  onSelectStore(orderType: OrderingMethodEnum) {
    this.createOrderType = orderType;
    this.filterDropdownOrderSubType();
    this.modalCreateNewOrder.show();
    this.storeForm.controls.orderSubtype.setValidators(Validators.required);
    this.storeForm.controls.orderSubtype.updateValueAndValidity();
    this.storeForm.controls.store.disable();
    this.storeForm.controls.orderType.patchValue(orderType);
  }

  onSelectWarehouse() {
    const warehouseCode = this.storeForm.controls.store.value && this.storeForm.controls.store.value.warehouse;
    const defaultWarehouseFirstLot = this.warehouseList.find(x => x.defaultFirstLot === true);
    const firstLotWarehouse = defaultWarehouseFirstLot ? defaultWarehouseFirstLot.code : warehouseCode;
    const subType = this.storeForm.controls.orderSubtype.value;

    if ((subType && warehouseCode) || (!subType && warehouseCode)) {
      this.storeForm.controls.warehouseCode.enable();

      if (this.createOrderType === OrderingMethodEnum.FIRST_LOT_ORDER) {
        this.storeForm.controls.warehouseCode.patchValue(firstLotWarehouse);
      }

      if (this.createOrderType === OrderingMethodEnum.SPECIAL_REQUEST) {
        this.storeForm.controls.warehouseCode.patchValue(warehouseCode);
      }
    } else {
      this.storeForm.controls.warehouseCode.disable();
      this.storeForm.controls.warehouseCode.patchValue(null);
    }

    this.storeForm.updateValueAndValidity();
  }

  onSelectOrderSubType() {
    const subType = this.storeForm.controls.orderSubtype.value;

    if (this.createOrderType === OrderingMethodEnum.FIRST_LOT_ORDER) {
      this.loadStoreForFirstLot('', subType);
    }

    if (this.createOrderType === OrderingMethodEnum.SPECIAL_REQUEST) {
      if (subType === OrderSubTypeEnum.AD_HOC) {
        this.loadStoreForSpecialAdhod('');
      } else {
        this.loadStoreForSpecialReplenish('', subType);
      }
    }

    this.storeForm.controls.store.reset();
    this.storeForm.controls.warehouseCode.reset();
    this.storeForm.controls.warehouseCode.disable();
    this.storeForm.updateValueAndValidity();
  }

  avialableWarehouse() {
    let resultWarehouse: WarehouseListContent[];
    const warehouseCode = this.storeForm.controls.store.value ? this.storeForm.controls.store.value.warehouse : null;
    const subType = this.storeForm.controls.orderSubtype.value;

    if (!warehouseCode) {
      resultWarehouse = [];
    } else {
      if (subType === OrderSubTypeEnum.REPLENISH) {
        resultWarehouse = this.warehouseList.filter(data => data.code === warehouseCode);
      } else {
        resultWarehouse = this.warehouseList;
      }
    }

    return resultWarehouse;
  }

  hideModalCreateNewOrder() {
    this.storeForm.reset();
    this.storeSelect.itemsList.unmarkItem();
    this.submitted = false;
    this.storeForm.controls.store.patchValue([]);
    this.storeForm.controls.store.disable();
    this.storeForm.controls.warehouseCode.patchValue(null);
    this.storeForm.controls.warehouseCode.disable();
    this.storeForm.controls.orderSubtype.setValidators(null);
    this.modalCreateNewOrder.hide();
  }

  loadStoreForFirstLot(initialTerm: string, subType: OrderSubTypeEnum) {
    this.storeList = concat(
      of(null),
      this.storeSearchInput$.pipe(
        untilComponentDestroyed(this),
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.storeSearchLoading = true)),
        switchMap(term =>
          this.orderRequestService
            .searchStoreForFirstLotByCriteria({
              searchCriteria: term,
              status: NewRequestStatusEnum.ACTIVE,
              orderSubType:
                subType === OrderSubTypeEnum.INVENTORY || subType === OrderSubTypeEnum.FIX_ASSET_AND_STORE_USE
                  ? subType
                  : '',
              sortBy: 'name',
              sortOrder: 'asc',
              page: 0,
              size: 20
            })
            .pipe(
              catchError(() => of([])), // empty list on error
              map(data => data.content),
              tap(() => {
                this.storeSearchLoading = false;
              })
            )
        )
      )
    );

    this.storeList.pipe(take(1), untilComponentDestroyed(this)).subscribe(data => {
      this.openCreateNewOrder(data);
    });
  }

  loadStoreForSpecialReplenish(initialTerm: string, subType: OrderSubTypeEnum) {
    this.storeList = concat(
      of(null),
      this.storeSearchInput$.pipe(
        untilComponentDestroyed(this),
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.storeSearchLoading = true)),
        switchMap(term =>
          this.orderRequestService
            .searchStoreForSpecialByCriteria({
              searchCriteria: term,
              status: NewRequestStatusEnum.ACTIVE,
              orderSubType: subType,
              sortBy: 'name',
              sortOrder: 'asc',
              page: 0,
              size: 20
            })
            .pipe(
              catchError(() => of([])), // empty list on error
              map(data => data.content),
              tap(() => {
                this.storeSearchLoading = false;
              })
            )
        )
      )
    );

    this.storeList.pipe(take(1), untilComponentDestroyed(this)).subscribe(data => {
      this.openCreateNewOrder(data);
    });
  }

  loadStoreForSpecialAdhod(initialTerm: string) {
    this.storeList = concat(
      of(null),
      this.storeSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.storeSearchLoading = true)),
        switchMap(term =>
          this.merchantService
            .searchStoreByCriteria({
              searchCriteria: term,
              status: NewRequestStatusEnum.ACTIVE,
              sortBy: 'name',
              sortOrder: 'asc',
              page: 0,
              size: 20
            })
            .pipe(
              catchError(() => of([])), // empty list on error
              map(data => data.content),
              tap(() => {
                this.storeSearchLoading = false;
              })
            )
        )
      )
    );

    this.storeList.pipe(take(1), untilComponentDestroyed(this)).subscribe(data => {
      this.openCreateNewOrder(data);
    });
  }

  openCreateNewOrder(data) {
    if (data && data.length === 0) {
      this.storeForm.controls.store.disable();
      this.alertNoStoreDataModal();
    } else {
      this.storeForm.controls.store.enable();
    }
  }

  alertNoStoreDataModal() {
    const initialState = {
      title: 'Alert',
      message: 'Not allow to create first lot order because all store has been created.'
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  getColorStatus(status: string): string {
    return status && status.toLowerCase();
  }

  hasEditPermission(status: NewRequestStatusEnum): boolean {
    let permission: boolean;

    if ([NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(status)) {
      permission = this.authGuardService.checkPermission([
        'so_firstlot_m',
        'so_special_m',
        'or_fl_ast_m',
        'or_fl_inv_m'
      ]);
    } else if (status === NewRequestStatusEnum.ASSIGN_STORE_EQUIPMENT) {
      permission = this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m', 'or_st_equip_m']);
    } else if (status === NewRequestStatusEnum.ASSIGN_QUANTITY) {
      permission = this.authGuardService.checkPermission(['or_fl_qty_inv_m']);
    }
    return permission;
  }

  hasDeletePermission(status: NewRequestStatusEnum): boolean {
    return (
      status === NewRequestStatusEnum.DRAFT &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m', 'or_fl_ast_m', 'or_fl_inv_m'])
    );
  }

  hasCancelPermission(status: NewRequestStatusEnum): boolean {
    return (
      [
        NewRequestStatusEnum.AWAITING_APPROVAL,
        NewRequestStatusEnum.ASSIGN_STORE_EQUIPMENT,
        NewRequestStatusEnum.ASSIGN_QUANTITY,
        NewRequestStatusEnum.AWAITING_SCHEDULE
      ].includes(status) &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m', 'or_fl_ast_m', 'or_fl_inv_m'])
    );
  }

  hasViewHistoryPermission(): boolean {
    return true;
  }

  openImportModal() {
    this.filesSubmitModal = this.modalService.show(FilesSubmitComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        title: 'Submit Data',
        templateName: 'Special Order Template',
        fileTitle: 'Upload Special Order'
      }
    });

    this.filesSubmitModal.content.downloadTemplate.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.downloadTemplate();
    });

    this.filesSubmitModal.content.upload.pipe(untilComponentDestroyed(this)).subscribe(data => {
      this.upload(data);
    });

    this.filesSubmitModal.content.submit.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.submit();
    });
    this.filesSubmitModal.content.downloadErrorExcel.pipe(untilComponentDestroyed(this)).subscribe(fileName => {
      this.downloadErrorExcel(fileName);
    });
  }

  downloadTemplate() {
    this.orderRequestService.downloadFileTemplate().subscribe(
      response => {
        this.saveFile(response);
      },
      error => {
        this.modalService.show(AlertModalComponent, {
          initialState: {
            title: 'Failed',
            message: error.error.message
          }
        });
      }
    );
  }

  saveFile(response: Blob) {
    const blob = new Blob([response]);
    saveAs(blob, `${environment.fileName.exportOrderRequest.fileTemplate}.xlsx`);
  }

  upload(data) {
    const guid = uuid.v4();
    this.orderRequestService.importFileRequest(data, guid).subscribe(
      () => {
        this.guid = guid;
        this.filesSubmitModal.content.loading = false;
        this.filesSubmitModal.content.isUploadSuccess = true;
      },
      errorResponse => {
        this.filesSubmitModal.content.loading = false;
        const errorMessage = errorResponse.error.fileBase64String
          ? `${errorResponse.error.rowErrorNo} Error Found. Download this file to edit or delete invalid row then re-import.`
          : this.translateErrorFromImport(errorResponse);

        this.filesSubmitModal.content.setErrorMessage(errorMessage, Boolean(errorResponse.error.fileBase64String));

        if (errorResponse.error.fileBase64String) {
          this.errorFile = b64toBlob(errorResponse.error.fileBase64String);
        }
      }
    );
  }

  submit() {
    this.orderRequestService.submitFileRequest(this.guid).subscribe(
      () => {
        this.filesSubmitModal.content.closeModal();
        this.alertSuccessModal('The data has been imported.');
      },
      errorResponse => {
        this.filesSubmitModal.content.loading = false;
        this.store.dispatch(new LayoutActionLoadError(errorResponse));
      }
    );
  }

  translateErrorFromImport(errorResponse: any) {
    let result;
    if (errorResponse.error.code === '00006') {
      result = errorResponse.error.message;
    } else {
      result = this.translate.instant(errorResponse.error.translateKey);
    }
    return result;
  }

  downloadErrorExcel(fileName: string) {
    saveAs(this.errorFile, fileName);
  }

  filterDropdownOrderSubType() {
    if (this.createOrderType === OrderingMethodEnum.FIRST_LOT_ORDER) {
      this.orderSubtypeFilter = filterDropdown.orderSubtypeFilter.filter(item =>
        this.authGuardService.checkPermission(item.permission)
      );
    } else {
      this.orderSubtypeFilter = filterDropdown.orderSubtypeSpecialFilter;
    }
  }

  static getOrderRequestTitle(mode: RequestPageModesEnum) {
    let title: string;
    switch (mode) {
      case RequestPageModesEnum.REQUEST_CREATE:
        title = 'Create Order Request';
        break;
      case RequestPageModesEnum.REQUEST_VIEW:
        title = 'View Order Request';
        break;
      case RequestPageModesEnum.REQUEST_EDIT:
        title = 'Edit Order Request';
        break;
      default:
    }
    return title;
  }
}
