<div *ngIf="fileList !== null && fileList.length > 0; else noImage">
  <div class="upload-preview-container" *ngFor="let file of fileList">
    <div class="upload-image-display" (click)="onShowImage()">
      <span *ngIf="isNew" class="badge badge-warning new badge-position">Update</span>
      <img *ngIf="file.imgSrc" [src]="file.imgSrc" class="upload-image-preview" alt="upload image" /><br />
    </div>
    <em *ngIf="file.imgSrc && !disabled" class="icon-close-mini" (click)="onClickDelete()"></em>
  </div>
</div>

<ng-template #noImage>
  <div class="upload-container text-center" [ngClass]="{ disabled: disabled }">
    <input
      id="uploadFile"
      #uploadInput
      type="file"
      class="upload-input"
      [disabled]="disabled"
      [multiple]="multiple"
      appPreventDoubleClick
    />
    <div class="d-flex flex-column upload-inner" (click)="uploadInput.click()">
      <em class="icon-plus"></em>
      <label for="uploadFile" class="upload-label">Add Image</label>
    </div>
  </div>
</ng-template>
