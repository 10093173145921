import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SelectWarehouseList, WarehouseListResponse } from '../../models/warehouse.model';
import { WarehouseService } from '../../services/warehouse.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  SelectWarehouseListRequestAction,
  SelectWarehouseListResponseAction,
  WarehouseActionTypes,
  WarehouseListRequestAction,
  WarehouseListResponseAction
} from '../actions/warehouse.actions';

@Injectable()
export class WarehouseEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: NGXLogger,
    private readonly warehouseService: WarehouseService
  ) {}

  @Effect()
  warehouseList$ = this.actions$.pipe(
    ofType<WarehouseListRequestAction>(WarehouseActionTypes.WAREHOUSE_LIST_REQUEST),
    tap(() => {
      this.logger.debug(`@Effect ${WarehouseActionTypes.WAREHOUSE_LIST_REQUEST}`);
    }),
    switchMap(action => {
      return this.warehouseService.listWarehouse().pipe(
        map(response => {
          const warehouseListResponse = new WarehouseListResponse();
          const warehouseListArray = [];
          Object.keys(response.data).forEach(deliveryLocationType => {
            response.data[deliveryLocationType].forEach(deliveryLocation => {
              const warehouseObject = {
                id: null,
                code: null,
                name: null,
                type: null,
                wmsCode: null,
                warehouseNameDisplay: null,
                defaultFirstLot: null
              };
              warehouseObject.id = deliveryLocation.id;
              warehouseObject.code = deliveryLocation.code;
              warehouseObject.name = deliveryLocation.nameTh;
              warehouseObject.wmsCode = deliveryLocation.wmsCode;
              warehouseObject.type = deliveryLocation.__typename;
              warehouseObject.warehouseNameDisplay = `${deliveryLocation.wmsCode}${action.delimiter}${deliveryLocation.nameTh}`;
              warehouseObject.defaultFirstLot = deliveryLocation.defaultFirstLot;
              warehouseListArray.push(warehouseObject);
            });
          });
          warehouseListResponse.content = warehouseListArray;
          return new WarehouseListResponseAction(warehouseListResponse);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  selectWarehouseList$ = this.actions$.pipe(
    ofType<SelectWarehouseListRequestAction>(WarehouseActionTypes.SELECT_WAREHOUSES_LIST_REQUEST),
    tap(() => {
      this.logger.debug(`@Effect ${WarehouseActionTypes.SELECT_WAREHOUSES_LIST_REQUEST}`);
    }),
    switchMap(action => {
      return this.warehouseService.selectWarehouseList(action.payload).pipe(
        map((warehouses: SelectWarehouseList[]) => {
          return new SelectWarehouseListResponseAction(warehouses);
        }),
        catchError(err => of(new LayoutActionLoadError(err)))
      );
    })
  );
}
