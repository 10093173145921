import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models';
import {
  ApproveShelfInventoryRequest,
  RequestId,
  ShelfInventoryCreateRequest,
  ShelfInventoryRequestListResponse,
  ShelfInventoryRequestListSearchCriteria,
  ShelfInventoryRequestViewResponse
} from '../../models/shelf-inventory-request.model';

export enum ShelfInventoryRequestActionType {
  SHELF_INVENTORY_REQUEST_LIST_REQUEST = '[ShelfRequestInventory] Shelf Request Inventory List Request',
  SHELF_INVENTORY_REQUEST_LIST_RESPONSE = '[ShelfRequestInventory] Shelf Request Inventory List Response',
  SHELF_INVENTORY_CREATE_SAVE_REQUEST = '[ShelfRequestInventory] Create Shelf Inventory Save Request',
  SHELF_INVENTORY_CREATE_SAVE_RESPONSE = '[ShelfRequestInventory] Create Shelf Inventory Save Response',
  SHELF_INVENTORY_CREATE_SAVE_ERROR = '[ShelfRequestInventory] Create Shelf Inventory  Error',
  SHELF_INVENTORY_CREATE_SAVE_REQUEST_FIX_ASSET = '[ShelfRequestInventory] Create Shelf Inventory  Request fix asset',
  SHELF_INVENTORY_CREATE_SAVE_RESPONSE_FIX_ASSET = '[ShelfRequestInventory] Create Shelf Inventory  Response fix asset',
  SHELF_INVENTORY_CREATE_SAVE_ERROR_FIX_ASSET = '[ShelfRequestInventory] Create Shelf Inventory  Error fix asset',
  SHELF_INVENTORY_CREATE_SUBMIT_REQUEST = '[ShelfRequestInventory] Create Shelf Inventory Submit  Request',
  SHELF_INVENTORY_CREATE_SUBMIT_RESPONSE = '[ShelfRequestInventory] Create Shelf Inventory Submit  Response',
  SHELF_INVENTORY_CREATE_SUBMIT_ERROR = '[ShelfRequestInventory] Create Shelf Inventory Submit  Error',
  SHELF_INVENTORY_CREATE_SUBMIT_REQUEST_FIX_ASSET = '[ShelfRequestInventory] Create Shelf Inventory Submit  Request fix asset',
  SHELF_INVENTORY_CREATE_SUBMIT_RESPONSE_FIX_ASSET = '[ShelfRequestInventory] Create Shelf Inventory Submit  Response fix asset',
  SHELF_INVENTORY_CREATE_SUBMIT_ERROR_FIX_ASSET = '[ShelfRequestInventory] Create Shelf Inventory Submit  Error fix asset',
  SHELF_INVENTORY_REQUEST_GET_BY_ID_REQUEST = '[ShelfRequestInventory] Get Shelf Inventory By Id Request',
  SHELF_INVENTORY_REQUEST_GET_BY_ID_RESPONSE = '[ShelfRequestInventory] Get Shelf Inventory By Id Response',
  SHELF_INVENTORY_REQUEST_GET_BY_ID_RESET = '[ShelfRequestInventory] Get Shelf Inventory By Id Reset',
  SHELF_INVENTORY_REQUEST_LIST_HISTORY_REQUEST = '[ShelfRequestInventory] Shelf Request Inventory List History Request',
  SHELF_INVENTORY_REQUEST_LIST_HISTORY_RESPONSE = '[ShelfRequestInventory] Shelf Request Inventory List History Response',
  SHELF_INVENTORY_REQUEST_DELETE_REQUEST = '[ShelfRequestInventory] Request Delete Shelf Inventory Request',
  SHELF_INVENTORY_REQUEST_DELETE_RESPONSE = '[ShelfRequestInventory] Request Delete Shelf Inventory Response',
  SHELF_INVENTORY_REQUEST_DELETE_RESET = '[ShelfRequestInventory] Request Delete Shelf Inventory Reset',
  SHELF_INVENTORY_REQUEST_APPROVE_REQUEST = '[ShelfRequestInventory] Request Approve Shelf Inventory Request',
  SHELF_INVENTORY_REQUEST_APPROVE_RESPONSE = '[ShelfRequestInventory] Request Approve Shelf Inventory Response',
  SHELF_INVENTORY_TYPE_LIST_REQUEST = '[ShelfRequestInventory] Shelf Inventory Type List Request',
  SHELF_INVENTORY_TYPE_LIST_RESPONSE = '[ShelfRequestInventory] Shelf Inventory Type List Response',
  SHELF_INVENTORY_CREATE_RESET = '[ShelfRequestInventory] Create Shelf Inventory Reset'
}

export class ShelfInventoryRequestListRequestAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_REQUEST;

  constructor(public payload: ShelfInventoryRequestListSearchCriteria) {}
}

export class ShelfInventoryRequestListResponseAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_RESPONSE;

  constructor(public payload: ShelfInventoryRequestListResponse) {}
}

export class ShelfInventoryCreateSaveRequestAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SAVE_REQUEST;

  constructor(public payload: ShelfInventoryCreateRequest) {}
}

export class ShelfInventoryCreateSaveResponseAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SAVE_RESPONSE;

  constructor(public payload: ShelfInventoryRequestViewResponse) {}
}

export class ShelfInventoryCreateSaveErrorAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SAVE_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ShelfInventoryCreateSubmitRequestAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SUBMIT_REQUEST;

  constructor(public payload: ShelfInventoryCreateRequest) {}
}

export class ShelfInventoryCreateSubmitResponseAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SUBMIT_RESPONSE;

  constructor(public payload: ShelfInventoryRequestViewResponse) {}
}

export class ShelfInventoryCreateSubmitErrorAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SUBMIT_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ShelfInventoryRequestListHistoryRequestAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_HISTORY_REQUEST;

  constructor(public payload: RequestId) {}
}

export class ShelfInventoryRequestListHistoryResponseAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_HISTORY_RESPONSE;

  constructor(public payload: { auditLogs }) {}
}

export class ShelfInventoryRequestByIdRequestAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: RequestId) {}
}

export class ShelfInventoryRequestByIdResponseAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: ShelfInventoryRequestViewResponse) {}
}

export class ShelfInventoryRequestByIdResetAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_GET_BY_ID_RESET;
}

export class ShelfInventoryDeleteRequestAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_DELETE_REQUEST;

  constructor(public payload: RequestId) {}
}

export class ShelfInventoryDeleteResponseAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_DELETE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}
export class ShelfInventoryDeleteResetAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_DELETE_RESET;
}

export class ShelfInventoryApproveRequestAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_APPROVE_REQUEST;

  constructor(public payload: ApproveShelfInventoryRequest) {}
}

export class ShelfInventoryApproveResponseAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_APPROVE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class ShelfInventoryCreateResetAction implements Action {
  readonly type = ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_RESET;
}

export type ShelfInventoryRequestActions =
  | ShelfInventoryRequestListRequestAction
  | ShelfInventoryRequestListResponseAction
  | ShelfInventoryCreateSaveRequestAction
  | ShelfInventoryCreateSaveResponseAction
  | ShelfInventoryCreateSaveErrorAction
  | ShelfInventoryCreateSubmitRequestAction
  | ShelfInventoryCreateSubmitResponseAction
  | ShelfInventoryCreateSubmitErrorAction
  | ShelfInventoryRequestByIdRequestAction
  | ShelfInventoryRequestByIdResponseAction
  | ShelfInventoryRequestByIdResetAction
  | ShelfInventoryRequestListHistoryRequestAction
  | ShelfInventoryRequestListHistoryResponseAction
  | ShelfInventoryDeleteRequestAction
  | ShelfInventoryDeleteResponseAction
  | ShelfInventoryDeleteResetAction
  | ShelfInventoryApproveRequestAction
  | ShelfInventoryApproveResponseAction
  | ShelfInventoryCreateResetAction;
