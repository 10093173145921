import { createSelector } from '@ngrx/store';
import * as fromShelfSelectFixAssetOrderState from '../reducers/shelf-select-fix-asset-order.reducers';
import { AppStates } from '../state/app.states';

const selectShelfSelectFixAssetOrderState = (state: AppStates) => state.shelfSelectFixAssetOrder;

export const getShelfSelectFixAssetOrderById = shelfNo =>
  createSelector(selectShelfSelectFixAssetOrderState, shelves => shelves.entities[shelfNo]);

export const selectAllShelfSelectFixAssetOrder = createSelector(
  selectShelfSelectFixAssetOrderState,
  fromShelfSelectFixAssetOrderState.selectAll
);

export const selectShelfSelectFixAssetrderUpdated = createSelector(
  selectShelfSelectFixAssetOrderState,
  shelves => shelves.skipUpdated
);

export const selectShelfOrderUpdated = createSelector(
  selectShelfSelectFixAssetOrderState,
  shelf => shelf.skipUpdated
);
