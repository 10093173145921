import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { PurchaseConditionPageModes } from '../../../shared/enum/purchase-condition.enum';
import { NewRequestTypeEnum } from '../../../shared/enum/request-step.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import {
  ItemConditionContent,
  ItemConditionListCriteria
} from '../../../shared/models/purchase-condition-request.model';
import { PurchaseConditionService } from '../../../shared/services/purchase-condition.service';
import {
  ItemConditionListRequested,
  PurchaseConditionDiscountRequested
} from '../../../shared/store/actions/purchase-condition.actions';
import { ItemConditionState } from '../../../shared/store/reducers/purchase-condition.reducer';
import {
  selectAllItemConditionList,
  selectItemCondition
} from '../../../shared/store/selectors/purchase-condition.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { PurchaseConditionPermission } from '../purchase-condition-permission';
import { ItemConditionImportModalComponent } from './item-condition-import-modal/item-condition-import-modal.component';

@Component({
  selector: 'app-item-condition',
  templateUrl: './item-condition.component.html',
  styleUrls: ['./item-condition.component.scss']
})
export class ItemConditionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() supplierInformationParentForm: FormGroup;
  @Input() purchaseConditionParentForm: FormGroup;
  @Input() mode: PurchaseConditionPageModes;
  public buttons: Array<ImportExportButton>;
  public localStore: Observable<any>;
  public itemConditionList: ItemConditionContent[] = [];
  public listState$: Observable<ItemConditionState>;
  public dateFormat = environment.dateFormat;
  public criteriaObject: ItemConditionListCriteria;
  public requestTypeEnum = NewRequestTypeEnum;
  public purchaseConditionPageMode = PurchaseConditionPageModes;

  public currentPage: number;
  public pageSize: number;
  public importId: string;

  public purchaseConditionPermission = new PurchaseConditionPermission();

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    public modalRef: BsModalRef,
    private cdr: ChangeDetectorRef,
    protected purchaseConditionService: PurchaseConditionService
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.setFirstPage();
    this.setImportId(this.purchaseConditionParentForm.get('importId').value);
    this.initialItemCondition();
    this.setInitialCriteriaObject();
    this.search();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mode) {
      this.getButton();
    }
  }

  getButton() {
    this.buttons = [
      {
        type: ButtonType.IMPORT,
        name: 'Import',
        hidden: [PurchaseConditionPageModes.VIEW_REQUEST, PurchaseConditionPageModes.VIEW].includes(this.mode)
      },
      {
        type: ButtonType.EXPORT,
        name: 'Export',
        hidden: ![PurchaseConditionPageModes.VIEW].includes(this.mode) || !this.purchaseConditionPermission.showButton
      }
    ];
  }

  ngOnDestroy(): void {}

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  initialItemCondition() {
    this.localStore
      .pipe(
        select(selectAllItemConditionList),
        filter(res => Boolean(res))
      )
      .subscribe(data => {
        this.itemConditionList = data;
      });

    this.listState$ = this.store.select(selectItemCondition);
  }

  openImportModal() {
    this.modalRef = this.modalService.show(ItemConditionImportModalComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        supplierCode: this.supplierInformationParentForm.get('supplierCode').value,
        previousImportId: this.purchaseConditionParentForm.get('importId').value
      }
    });

    this.modalRef.content.submitUpload.subscribe(data => {
      if (data.importId) {
        this.setImportId(data.importId);
        this.criteriaObject = {
          importId: this.importId,
          page: 0,
          size: 20
        };
        this.purchaseConditionParentForm.patchValue({
          importId: data.importId,
          discountEdit:
            [PurchaseConditionPageModes.EDIT].includes(this.mode) ||
            ([PurchaseConditionPageModes.EDIT_REQUEST].includes(this.mode) &&
              this.purchaseConditionParentForm.controls['requestType'].value === this.requestTypeEnum.EDIT)
              ? true
              : null
        });
        this.purchaseConditionParentForm.markAsDirty();
        this.search();
        this.alertModal('The data have been imported.');
      }
    });
  }

  onExportDiscount() {
    const supplierCode = this.supplierInformationParentForm.controls.supplierCode.value;
    const criteria = {
      supplierCode
    };
    this.purchaseConditionService.exportDiscount(criteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${supplierCode} ${this.generateExportedFileName()} ${this.timeToExport}.xlsx`);
      },
      error => {
        this.alertErrorModal(error.error.message);
      }
    );
  }

  generateExportedFileName() {
    return environment.fileName.exportItemCondition.exported;
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportItemCondition.timeFormat);
  }

  alertErrorModal(message: string) {
    const initialState = {
      title: 'Failed',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
      }
    });
  }

  alertConfirmUploadModal(
    initialState = {
      title: 'Confirm',
      message: 'Are you sure you want to import new file? All existing data will be lost.',
      okText: 'OK'
    }
  ) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.openImportModal();
        }
      });
  }

  alertModal(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message
      }
    });
  }

  checkExistingItem() {
    if (this.itemConditionList && this.itemConditionList.length > 0) {
      this.alertConfirmUploadModal();
    } else {
      this.openImportModal();
    }
  }

  onChangePage(event: any) {
    this.currentPage = event.page;
    this.criteriaObject = {
      ...this.criteriaObject,
      page: event.page - 1
    };
    this.search();
  }

  onChangeRowPerPage(value: string) {
    this.setFirstPage();
    this.pageSize = Number(value);
    this.criteriaObject = {
      ...this.criteriaObject,
      size: Number(value),
      page: 0
    };
    this.search();
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  setImportId(importId: string) {
    this.importId = importId;
  }

  setInitialCriteriaObject() {
    this.pageSize = 20;
    if ([PurchaseConditionPageModes.VIEW, PurchaseConditionPageModes.EDIT].includes(this.mode)) {
      this.criteriaObject = {
        ...({} as any),
        supplierCode: this.supplierCode,
        page: 0,
        size: 20
      };
    } else {
      this.criteriaObject = {
        ...({} as any),
        importId: this.importId,
        page: 0,
        size: 20
      };
    }
  }

  search() {
    if (
      this.purchaseConditionParentForm.controls.importId.value &&
      ([
        PurchaseConditionPageModes.VIEW_REQUEST,
        PurchaseConditionPageModes.CREATE,
        PurchaseConditionPageModes.EDIT_REQUEST
      ].includes(this.mode) ||
        this.purchaseConditionParentForm.controls.discountEdit.value)
    ) {
      this.criteriaObject = {
        ...this.criteriaObject,
        importId: this.purchaseConditionParentForm.controls.importId.value,
        supplierCode: null
      };
      this.store.dispatch(new ItemConditionListRequested(this.criteriaObject));
    } else if ([PurchaseConditionPageModes.VIEW, PurchaseConditionPageModes.EDIT].includes(this.mode)) {
      this.store.dispatch(new PurchaseConditionDiscountRequested(this.criteriaObject));
    }
  }

  get supplierCode(): string {
    return this.supplierInformationParentForm.get('supplierCode').value;
  }
}
