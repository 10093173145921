import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OrderFixAssetRequestService } from '../../services/order-fix-asset-request.service';
import { ShelfTypesService } from '../../services/shelf-types.service';
import { ShelfService } from '../../services/shelf.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  OrderFixAssetApproveRequestAction,
  OrderFixAssetApproveResponseAction,
  OrderFixAssetCreateSaveRequestAction,
  OrderFixAssetCreateSaveResponseAction,
  OrderFixAssetCreateSubmitRequestAction,
  OrderFixAssetCreateSubmitResponseAction,
  OrderFixAssetCreateSubmitResponseErrorAction,
  OrderFixAssetRequestActionType,
  OrderFixAssetRequestByIdRequestAction,
  OrderFixAssetRequestByIdResponseAction
} from '../actions/order-fix-asset-request.actions';
import { AppStates } from '../state/app.states';

@Injectable()
export class OrderFixAssetRequestEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly orderFixAssetRequestService: OrderFixAssetRequestService,
    private readonly shelfService: ShelfService,
    private readonly shelfTypesService: ShelfTypesService,
    private readonly logger: NGXLogger,
    private readonly store: Store<AppStates>
  ) {}

  @Effect()
  orderFixAssetCreateSave$ = this.actions$.pipe(
    ofType<OrderFixAssetCreateSaveRequestAction>(OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SAVE_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SAVE_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.orderFixAssetRequestService.saveRequest(payload).pipe(
        map(res => new OrderFixAssetCreateSaveResponseAction(res)),
        catchError(error => of(new OrderFixAssetCreateSubmitResponseErrorAction(error.error)))
      );
    })
  );

  @Effect()
  OrderFixAssetCreateSubmit$ = this.actions$.pipe(
    ofType<OrderFixAssetCreateSubmitRequestAction>(
      OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SUBMIT_REQUEST
    ),
    map(action => {
      this.logger.debug(
        `@Effect ${OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SUBMIT_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.orderFixAssetRequestService.submitRequest(payload).pipe(
        map(res => new OrderFixAssetCreateSubmitResponseAction(res)),
        catchError(error => of(new OrderFixAssetCreateSubmitResponseErrorAction(error.error)))
      );
    })
  );

  @Effect()
  getOrderFixAssetRequestById$ = this.actions$.pipe(
    ofType<OrderFixAssetRequestByIdRequestAction>(
      OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_GET_BY_ID_REQUEST
    ),
    map(action => {
      this.logger.debug(
        `@Effect ${OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_GET_BY_ID_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.orderFixAssetRequestService.getOrderById(payload).pipe(
        map(res => {
          return new OrderFixAssetRequestByIdResponseAction(res);
        }),

        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  orderFixAssetApprove$ = this.actions$.pipe(
    ofType<OrderFixAssetApproveRequestAction>(OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_APPROVE_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_APPROVE_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload =>
      this.orderFixAssetRequestService.approveRequest(payload).pipe(
        map(() => new OrderFixAssetApproveResponseAction({ isSuccess: true })),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
