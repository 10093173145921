import { createSelector } from '@ngrx/store';
import * as fromSupplier from '../reducers/supplier.reducers';
import { AppStates } from '../state/app.states';

const selectSupplierFromState = (state: AppStates) => state.supplier;

export const selectSupplierList = createSelector(selectSupplierFromState, fromSupplier.selectAll);

export const selectSupplierState = createSelector(
  selectSupplierFromState,
  (state: fromSupplier.SupplierState) => state
);

export const selectSupplierBySupplierCode = createSelector(
  selectSupplierFromState,
  (state: fromSupplier.SupplierState) => state.selected
);

export const selectSupplierActivated = createSelector(
  selectSupplierFromState,
  (state: fromSupplier.SupplierState) => state.activatedStatus
);

export const selectSupplierCheckDuplicated = createSelector(
  selectSupplierFromState,
  (state: fromSupplier.SupplierState) => state.validateSupplier
);

export const selectSupplierListCriteria = createSelector(selectSupplierFromState, state => {
  return state.criteriaObject;
});

export const selectSupplierSubmitError = createSelector(
  selectSupplierFromState,
  (state: fromSupplier.SupplierState) => state.submitError
);
