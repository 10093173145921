import { createSelector } from '@ngrx/store';
import * as fromShelfCreateResponseState from '../reducers/shelf-inventory-create.reducers';
import { AppStates } from '../state/app.states';

const selectShelfInventoryCreateState = (state: AppStates) => state.shelfInventoryCreate;

export const selectShelfInventory = createSelector(
  selectShelfInventoryCreateState,
  (state: fromShelfCreateResponseState.ShelfInventoryCreateResponseState | null) => state
);

export const selectShelfApproveRejectStatus = createSelector(
  selectShelfInventoryCreateState,
  (state: fromShelfCreateResponseState.ShelfInventoryCreateResponseState) => {
    return state.isApproveRejectSuccess;
  }
);
