import { Action } from '@ngrx/store';
import {
  ApproveRejectStockTransferRequest,
  StockTransfer,
  StockTransferRequestErrorResponse,
  StockTransferRequestListResponse,
  StockTransferRequestListSearchCriteria
} from '../../models/stock-transfer-request.model';

export enum StockTransferRequestActionType {
  STOCK_TRANSFER_REQUEST_LIST_REQUEST = '[StockTransferRequest] Stock TransferRequest List Request',
  STOCK_TRANSFER_REQUEST_LIST_RESPONSE = '[StockTransferRequest] Stock TransferRequest List Response',
  STOCK_TRANSFER_REQUEST_SAVE_REQUEST = '[StockTransferRequest] Stock TransferRequest Save Request',
  STOCK_TRANSFER_REQUEST_GET_BY_ID_REQUEST = '[StockTransferRequest] Get Stock TransferRequest By ID Request',
  STOCK_TRANSFER_REQUEST_GET_BY_ID_RESPONSE = '[StockTransferRequest] Get Stock TransferRequest By ID Response',
  STOCK_TRANSFER_REQUEST_DELETE_REQUEST = '[StockTransferRequest] Stock Transfer Request Delete Request',
  STOCK_TRANSFER_REQUEST_CANCEL_REQUEST = '[StockTransferRequest] Stock Transfer Request Cancel Request',
  STOCK_TRANSFER_REQUEST_APPROVE_REQUEST = '[StockTransferRequest] Stock Transfer Request Approve Request',
  STOCK_TRANSFER_REQUEST_REJECT_REQUEST = '[StockTransferRequest] Stock Transfer Request Reject Request',
  STOCK_TRANSFER_REQUEST_RESET = '[StockTransferRequest] Stock Transfer Request Reset',
  STOCK_TRANSFER_REQUEST_SUBMIT_REQUEST = '[StockTransferRequest] Stock Transfer Request Submit',
  STOCK_TRANSFER_REQUEST_ERROR_RESPONSE = '[StockTransferRequest] Stock Transfer Error Response'
}

export class StockTransferRequestListRequestAction implements Action {
  readonly type = StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_LIST_REQUEST;

  constructor(public payload: StockTransferRequestListSearchCriteria) {}
}

export class StockTransferRequestListResponseAction implements Action {
  readonly type = StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_LIST_RESPONSE;

  constructor(public payload: StockTransferRequestListResponse) {}
}

export class StockTransferRequestSaveRequestAction implements Action {
  readonly type = StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_SAVE_REQUEST;

  constructor(public payload: StockTransfer) {}
}

export class StockTransferRequestByIdRequestAction implements Action {
  readonly type = StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: string) {}
}

export class StockTransferRequestByIdResponseAction implements Action {
  readonly type = StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: StockTransfer) {}
}

export class StockTransferDeleteRequestAction implements Action {
  readonly type = StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_DELETE_REQUEST;

  constructor(public payload: string) {}
}

export class StockTransferCancelRequestAction implements Action {
  readonly type = StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_CANCEL_REQUEST;

  constructor(public payload: ApproveRejectStockTransferRequest) {}
}

export class StockTransferRequestResetActions implements Action {
  readonly type = StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_RESET;

  constructor() {}
}

export class StockTransferRequestApproveRequest implements Action {
  readonly type = StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_APPROVE_REQUEST;

  constructor(public payload: ApproveRejectStockTransferRequest) {}
}

export class StockTransferRequestRejectRequest implements Action {
  readonly type = StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_REJECT_REQUEST;

  constructor(public payload: ApproveRejectStockTransferRequest) {}
}

export class StockTransferRequestSubmitRequestAction implements Action {
  readonly type = StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_SUBMIT_REQUEST;

  constructor(public payload: StockTransfer) {}
}

export class StockTransferRequestErrorResponseAction implements Action {
  readonly type = StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_ERROR_RESPONSE;

  constructor(public payload: StockTransferRequestErrorResponse) {}
}

export type StockTransferRequestActions =
  | StockTransferRequestListRequestAction
  | StockTransferRequestListResponseAction
  | StockTransferRequestSaveRequestAction
  | StockTransferRequestByIdRequestAction
  | StockTransferRequestByIdResponseAction
  | StockTransferDeleteRequestAction
  | StockTransferRequestResetActions
  | StockTransferCancelRequestAction
  | StockTransferRequestApproveRequest
  | StockTransferRequestRejectRequest
  | StockTransferRequestSubmitRequestAction
  | StockTransferRequestErrorResponseAction;
