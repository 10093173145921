import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ShelfInventoryService } from '../../services/shelf-inventory.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  ShelfInventoryActionType,
  ShelfInventoryByIdRequestAction,
  ShelfInventoryByIdResponseAction,
  ShelfInventoryListHistoryRequestAction,
  ShelfInventoryListHistoryResponseAction,
  ShelfInventoryListRequestAction,
  ShelfInventoryListResponseAction,
  SubmitShelfInventoryFirstLotErrorAction,
  SubmitShelfInventoryFirstLotRequestAction,
  SubmitShelfInventoryFirstLotResponseAction
} from '../actions/shelf-inventory.actions';
import { AppStates } from '../state/app.states';

@Injectable()
export class ShelfInventoryEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly shelfInventoryService: ShelfInventoryService,
    private readonly store$: Store<AppStates>,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchShelf$ = this.actions$.pipe(
    ofType<ShelfInventoryListRequestAction>(ShelfInventoryActionType.SHELF_INVENTORY_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfInventoryActionType.SHELF_INVENTORY_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfInventoryService.searchByCriteria(payload).pipe(
        map(response => {
          return new ShelfInventoryListResponseAction(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  shelfHistory$ = this.actions$.pipe(
    ofType<ShelfInventoryListHistoryRequestAction>(ShelfInventoryActionType.SHELF_INVENTORY_LIST_HISTORY_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfInventoryActionType.SHELF_INVENTORY_LIST_HISTORY_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfInventoryService.getHistoryLogs(payload).pipe(
        map(response => new ShelfInventoryListHistoryResponseAction({ auditLogs: response.auditLogs })),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getMerchantById$ = this.actions$.pipe(
    ofType<ShelfInventoryByIdRequestAction>(ShelfInventoryActionType.SHELF_INVENTORY_GET_BY_ID_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfInventoryActionType.SHELF_INVENTORY_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfInventoryService.getShelfById(payload).pipe(
        map(res => {
          return new ShelfInventoryByIdResponseAction(res);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  submitFirstLot$ = this.actions$.pipe(
    ofType<SubmitShelfInventoryFirstLotRequestAction>(
      ShelfInventoryActionType.SUBMIT_SHELF_INVENTORY_FIRST_LOT_REQUEST
    ),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfInventoryActionType.SUBMIT_SHELF_INVENTORY_FIRST_LOT_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfInventoryService.submitFirstLot(payload).pipe(
        map(res => new SubmitShelfInventoryFirstLotResponseAction(res)),
        catchError(error => of(new SubmitShelfInventoryFirstLotErrorAction(error.error)))
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
