import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { round } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ShelfSelectedItems } from '../../models/first-lot-order.model';
import { ShelfFixAssetOrderItem } from '../../models/order-request.model';
import { ShelfFixAssetService } from '../../services/shelf-fix-asset.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { ShelfSelectFixAssetOrderAddAllItem } from '../actions/shelf-select-fix-asset-order.actions';
import {
  ShelfSelectFixAssetActionTypes,
  ShelfSelectFixAssetGenerateShelfItemsRequestAction,
  ShelfSelectFixAssetListRequest,
  ShelfSelectFixAssetListResponse
} from '../actions/shelf-select-fix-asset.actions';
import { AppStates } from '../state/app.states';

@Injectable()
export class ShelfSelectFixAssetEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly shelfFixAssetService: ShelfFixAssetService,
    private readonly store: Store<AppStates>,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  shelfSelectFixAssetListRequest$ = this.actions$.pipe(
    ofType<ShelfSelectFixAssetListRequest>(ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_LIST_REQUEST),
    tap(action =>
      this.logger.debug(
        `@Effect ${ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_LIST_REQUEST}:` +
          JSON.stringify(action.payload)
      )
    ),
    switchMap(action =>
      this.shelfFixAssetService.searchByCriteria(action.payload).pipe(
        map(response => {
          return new ShelfSelectFixAssetListResponse(response);
        }),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  shelfSelectFixAssetGenerateShelfItemsRequest$ = this.actions$.pipe(
    ofType<ShelfSelectFixAssetGenerateShelfItemsRequestAction>(
      ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_GENERATE_SHELF_ITEM_REQUEST
    ),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_GENERATE_SHELF_ITEM_REQUEST}: ` +
          JSON.stringify(action)
      );
      return action.payload;
    }),
    switchMap(payload => {
      const shelfSelected = payload.map(shelf => shelf.shelfNo);

      return this.shelfFixAssetService.generateShelfFixAssetItems(shelfSelected).pipe(
        map(response => {
          return new ShelfSelectFixAssetOrderAddAllItem({ itemList: this.mappingShelfItemOrder(response, payload) });
        }),
        catchError(err => of(new LayoutActionLoadError(err)))
      );
    }),
    catchError(error => of(new LayoutActionLoadError(error)))
  );

  mappingShelfItemOrder(
    shelfItems: ShelfFixAssetOrderItem[],
    shelfSelectedItems: ShelfSelectedItems[]
  ): ShelfFixAssetOrderItem[] {
    shelfItems.forEach(shelf => {
      const selected = shelfSelectedItems.find(selectedItem => selectedItem.shelfNo === shelf.shelfNo);
      shelf.qty = selected ? selected.qty : 1;

      shelf.items.forEach(item => {
        item.qty = item.quantity * shelf.qty;

        item.totalVat = {
          amount: (item.vatAmount && round(item.vatAmount.amount * item.qty, 2)) || 0,
          currency: (item.vatAmount && item.vatAmount.currency) || 'THB'
        };
        item.amountExcVat = {
          amount: (item.wholesalePriceExcVat && round(item.wholesalePriceExcVat.amount * item.qty, 2)) || 0,
          currency: (item.wholesalePriceExcVat && item.wholesalePriceExcVat.currency) || 'THB'
        };
        item.amount = {
          amount: round(item.amountExcVat.amount + item.totalVat.amount, 2) || 0,
          currency: (item.amountExcVat && item.amountExcVat.currency) || 'THB'
        };
      });

      shelf.isAddItem = true;
    });

    return shelfItems;
  }
}
