import { createSelector } from '@ngrx/store';
import * as fromOrderStoreUseSelectItemState from '../reducers/order-store-use-select-item.reducers';
import { AppStates } from '../state/app.states';

const selectorderStoreUseSelectItemState = (state: AppStates) => state.orderStoreUseSelectItem;

export const selectAllOrderStoreUseSelectItemList = createSelector(
  selectorderStoreUseSelectItemState,
  fromOrderStoreUseSelectItemState.selectAll
);

export const selectOrderStoreUseSelectItemList = createSelector(
  selectorderStoreUseSelectItemState,
  (state: fromOrderStoreUseSelectItemState.OrderStoreUseSelectItemState) => state
);
