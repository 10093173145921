import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { DefaultStatusEnum } from '../../../shared/enum/default-status.enum';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NewMasterData } from '../../../shared/gql/common.gql';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  PortalModule,
  RouteLinkTab,
  TaskModuleUrl,
  VoucherRequestContent,
  VoucherRequestPageModes,
  VoucherRequestSearchCriteria
} from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import {
  VoucherCancelRequestedAction,
  VoucherRequestListRequestAction
} from '../../../shared/store/actions/voucher-request.actions';
import { VoucherRequestState } from '../../../shared/store/reducers/voucher-request.reducers';
import {
  selectAllVoucherRequestList,
  selectVoucherRequestList,
  selectVoucherRequestListCriteria
} from '../../../shared/store/selectors/voucher-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  formatDate,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { ModuleUtil } from '../../../shared/utils/module-util';
import { VoucherRequestComponent } from '../voucher-request/voucher-request.component';
import { VoucherImportModalComponent } from './voucher-import-modal/voucher-import-modal.component';

@Component({
  selector: 'app-voucher-request-list',
  templateUrl: './voucher-request-list.component.html',
  styleUrls: ['./voucher-request-list.component.scss']
})
export class VoucherRequestListComponent extends BaseSearchComponent<
  VoucherRequestSearchCriteria,
  VoucherRequestContent,
  VoucherRequestState
> {
  private localStore: Observable<any>;
  private bsModalRef: BsModalRef;
  listRoute: Array<RouteLinkTab>;
  public readonly pageMode = VoucherRequestPageModes;

  public minDate: Date;
  public maxDate: Date;
  public expireMaxDate: Date;
  public expireMinDate: Date;
  public requestMaxDate: Date;
  public requestMinDate: Date;

  public campaignTag: string;
  public campaignStringTag: string;
  public requestedDateTag: string;
  public requestedDateStringTag: string;
  public effectiveDateTag: string;
  public effectiveDateStringTag: string;
  public expireDateTag: string;
  public expireDateStringTag: string;
  public statusList: any[];
  public campaignList: any[];
  public dateFormat = environment.dateFormat;
  public hasViewVoucherPermission = false;
  public hasEditVoucherPermission = false;
  public hasApproveVoucherPermission = false;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.IMPORT,
      name: 'Import'
    }
  ];

  public importModal: BsModalRef;

  public regionList: Array<NewMasterData> | null;
  public stateList: Array<NewMasterData> | null;
  public regionTag: string;
  public regionStringTag = 'Region';
  public stateTag: string;
  public stateStringTag = 'Province';

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    protected masterService: MasterService
  ) {
    super(store, modalService, selectAllVoucherRequestList, selectVoucherRequestList);
    super.subscribeForSaveSuccess();
    this.campaignList = filterDropdown.campaignList;
    this.statusList = filterDropdown.voucherRequestStatusFilter;

    this.hasViewVoucherPermission = this.authGuardService.checkPermission(['voucher_v']);
    this.hasEditVoucherPermission = this.authGuardService.checkPermission(['voucher_m']);
    this.hasApproveVoucherPermission = this.authGuardService.checkPermission(['voucher_app']);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectVoucherRequestListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.masterService
      .getMasterDataByNames([MasterDataEnum.REGION, MasterDataEnum.STATE])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.regionList = result[MasterDataEnum.REGION];
        this.stateList = result[MasterDataEnum.STATE];
      });
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  showHistory(request) {
    const initialState = {
      title: 'History',
      action: HistoryType.REQUEST,
      historyHeader: `Request No. ${request.requestNo}`,
      historyType: HistoryType.VOUCHER,
      auditLogs: request.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  setInitialValue() {
    this.pageSize = 20;
    const todayDate = new Date();

    this.maxDate = todayDate;
    this.expireMaxDate = todayDate;
    this.requestMaxDate = todayDate;

    this.maxDate.setDate(todayDate.getDate() + 365);
    this.expireMaxDate.setDate(todayDate.getDate() + 365);
    this.requestMaxDate.setDate(todayDate.getDate() + 365);
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new VoucherRequestListRequestAction(criteriaObj));
  }

  doAfterVersionAlertModal() {
    this.search(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  doAfterSuccessModal() {
    this.doAfterVersionAlertModal();
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;

    if (
      !formValue.effectiveDateFrom &&
      !formValue.effectiveDateTo &&
      !formValue.expireDateFrom &&
      !formValue.expireDateTo &&
      !formValue.requestedDateFrom &&
      !formValue.requestedDateTo &&
      !formValue.campaign &&
      !formValue.state &&
      !formValue.region
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      campaign: formValue.campaign && formValue.campaign.length > 0 ? [formValue.campaign.join(',')] : null,
      effectiveDateFrom: formatDate(formValue.effectiveDateFrom, this.dateFormat),
      effectiveDateTo: formatDate(formValue.effectiveDateTo, this.dateFormat),
      expireDateFrom: formatDate(formValue.expireDateFrom, this.dateFormat),
      expireDateTo: formatDate(formValue.expireDateTo, this.dateFormat),
      requestedDateFrom: dateToStringCriteria(formValue.requestedDateFrom),
      requestedDateTo: dateToStringCriteria(formValue.requestedDateTo, false),
      region: this.convertArrayFilter(formValue.region),
      state: this.convertArrayFilter(formValue.state),
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  convertArrayFilter(value) {
    return value && value.length > 0 ? value.toString() : null;
  }
  prepareSearchCriteriaTags() {
    this.effectiveDateTag = null;
    this.effectiveDateStringTag = null;
    this.expireDateTag = null;
    this.expireDateStringTag = null;
    this.requestedDateTag = null;
    this.requestedDateStringTag = null;
    this.campaignTag = null;
    this.campaignStringTag = null;

    const effectiveDateFrom = dateStringToTagCriteria(this.criteriaObject.effectiveDateFrom);
    const effectiveDateTo = dateStringToTagCriteria(this.criteriaObject.effectiveDateTo);
    const effectiveDate = generateDateStringTag({
      dateName: 'Effective Date',
      dateFrom: effectiveDateFrom,
      dateTo: effectiveDateTo
    });

    this.effectiveDateStringTag = effectiveDate.dateStringTag;
    this.effectiveDateTag = effectiveDate.dateTag;

    const expireDateFrom = dateStringToTagCriteria(this.criteriaObject.expireDateFrom);
    const expireDateTo = dateStringToTagCriteria(this.criteriaObject.expireDateTo);
    const expireDate = generateDateStringTag({
      dateName: 'Expire Date',
      dateFrom: expireDateFrom,
      dateTo: expireDateTo
    });

    this.expireDateStringTag = expireDate.dateStringTag;
    this.expireDateTag = expireDate.dateTag;

    const requestedDateFrom = dateStringToTagCriteria(this.criteriaObject.requestedDateFrom);
    const requestedDateTo = dateStringToTagCriteria(this.criteriaObject.requestedDateTo);
    const requestedDate = generateDateStringTag({
      dateName: 'Requested Date',
      dateFrom: requestedDateFrom,
      dateTo: requestedDateTo
    });

    this.requestedDateStringTag = requestedDate.dateStringTag;
    this.requestedDateTag = requestedDate.dateTag;

    this.prepareSearchCriteriaTagsCampaign();
    this.prepareSearchCriteriaTagsState();
    this.prepareSearchCriteriaTagsRegion();
  }
  prepareSearchCriteriaTagsCampaign() {
    this.campaignTag = null;
    this.campaignStringTag = null;

    if (this.criteriaObject.campaign && this.criteriaObject.campaign.length > 0) {
      this.campaignStringTag = this.translate.instant('VOUCHER.CAMPAIGN');
      const requests = this.criteriaObject.campaign.join(',');
      this.campaignTag = `"${requests}"`;
    }
  }

  prepareSearchCriteriaTagsState() {
    this.stateTag = null;

    if (this.criteriaObject.state && this.criteriaObject.state.length) {
      const states = this.stateList
        .filter(data => this.criteriaObject.state.includes(data.code))
        .map(data => data.nameTh)
        .join(', ');
      this.stateTag = `"${states}"`;
    }
  }

  prepareSearchCriteriaTagsRegion() {
    this.regionTag = null;

    if (this.criteriaObject.region && this.criteriaObject.region.length) {
      const regions = this.regionList
        .filter(data => this.criteriaObject.region.includes(data.code))
        .map(data => data.nameTh)
        .join(', ');
      this.regionTag = `"${regions}"`;
    }
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.statusList[0].value],
      campaign: [],
      effectiveDateFrom: [null],
      effectiveDateTo: [null],
      expireDateFrom: [null],
      expireDateTo: [null],
      requestedDateFrom: [null],
      requestedDateTo: [null],
      region: [null],
      state: [null]
    });
  }

  onChangeExpireDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.expireMinDate = new Date(value);
    } else {
      this.expireMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeExpireDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.expireMaxDate = new Date(value);
    } else {
      this.expireMaxDate = new Date();
      this.expireMaxDate.setDate(this.expireMaxDate.getDate() + 365);
    }
  }

  onChangeRequestedDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.requestMinDate = new Date(value);
    } else {
      this.requestMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeRequestedDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.requestMaxDate = new Date(value);
    } else {
      this.requestMaxDate = new Date();
      this.requestMaxDate.setDate(this.requestMaxDate.getDate() + 365);
    }
  }

  onChangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  clearFilterRegion() {
    this.setFirstPage();
    this.searchForm.controls['region'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      region: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterState() {
    this.setFirstPage();
    this.searchForm.controls['state'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      state: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterCampaign() {
    this.setFirstPage();
    this.searchForm.controls['campaign'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      campaign: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterEffectiveDate() {
    this.setFirstPage();
    this.searchForm.controls['effectiveDateFrom'].reset();
    this.searchForm.controls['effectiveDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      effectiveDateFrom: null,
      effectiveDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterExpireDate() {
    this.setFirstPage();
    this.searchForm.controls['expireDateFrom'].reset();
    this.searchForm.controls['expireDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      expireDateFrom: null,
      expireDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterRequestedDate() {
    this.setFirstPage();
    this.searchForm.controls['requestedDateFrom'].reset();
    this.searchForm.controls['requestedDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      requestedDateFrom: null,
      requestedDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.setFirstPage();
    this.searchForm.controls['effectiveDateFrom'].reset();
    this.searchForm.controls['effectiveDateTo'].reset();
    this.searchForm.controls['expireDateFrom'].reset();
    this.searchForm.controls['expireDateTo'].reset();
    this.searchForm.controls['requestedDateFrom'].reset();
    this.searchForm.controls['requestedDateTo'].reset();
    this.searchForm.controls['campaign'].reset();
    this.searchForm.controls['region'].reset();
    this.searchForm.controls['state'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      effectiveDateFrom: null,
      effectiveDateTo: null,
      expireDateFrom: null,
      expireDateTo: null,
      requestedDateFrom: null,
      requestedDateTo: null,
      campaign: null,
      state: null,
      region: null
    };
    this.doSearch(this.criteriaObject);
  }

  getColorStatus(status: string): string {
    return DefaultStatusEnum[status];
  }

  goToView(viewParams?: any) {
    if (viewParams === null) {
      return;
    }

    const initialState = ModuleUtil.InitialState(
      viewParams,
      VoucherRequestComponent,
      VoucherRequestPageModes.REQUEST_VIEW,
      this.getVoucherRequestTitle(VoucherRequestPageModes.REQUEST_VIEW),
      TaskModuleUrl.VOUCHER_REQUEST,
      PortalModule.VOUCHER
    );

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  getVoucherRequestTitle(mode: VoucherRequestPageModes): string {
    switch (mode) {
      case VoucherRequestPageModes.REQUEST_CREATE:
        return 'Create Voucher Request';
      case VoucherRequestPageModes.REQUEST_VIEW:
      case VoucherRequestPageModes.VIEW:
        return 'View Voucher Request';
      default:
        return '';
    }
  }

  showCancelButton(status: string): boolean {
    if (status.toLocaleLowerCase() === 'w') {
      return true;
    }

    return false;
  }

  cancelVoucherRequest(item: any) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel Voucher Request Number <strong>&quot;${item.requestNo}&quot;</strong>?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new VoucherCancelRequestedAction({
              id: item.requestNo,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  openImportModal() {
    this.importModal = this.modalService.show(VoucherImportModalComponent, {
      backdrop: 'static',
      keyboard: false
    });
  }

  setRouteTab(): void {
    this.listRoute = [];
    if (this.hasEditVoucherPermission) {
      this.listRoute.push({ tabName: 'Voucher List', url: '/campaign/voucher-list' });
    }
    this.listRoute.push({ tabName: 'Voucher Request', url: '/campaign/voucher-request-list' });
  }

  gotoCreateVoucherRequest() {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        VoucherRequestComponent,
        {
          title: this.getVoucherRequestTitle(VoucherRequestPageModes.REQUEST_CREATE),
          mode: VoucherRequestPageModes.REQUEST_CREATE
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  doDestroy() {}
}
