import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ShelfItems } from '../../models';
import {
  OrderFixAssetRequestViewResponse,
  OrderRequestList,
  OrderRequestListSearchCriteria
} from '../../models/order-request-fix-asset.model';
import {
  OrderFixAssetRequestActions,
  OrderFixAssetRequestActionType
} from '../actions/order-fix-asset-request.actions';
import { BaseState, initialCriteriaObject } from '../state/base.state';

export interface OrderFixAssetRequestState extends EntityState<OrderRequestList>, BaseState {
  selected: OrderFixAssetRequestViewResponse;
  criteriaObject: OrderRequestListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
  shelfList: ShelfItems[];
}

export const adapter: EntityAdapter<OrderRequestList> = createEntityAdapter<OrderRequestList>();

export const initialOrderResponseState: OrderFixAssetRequestState = adapter.getInitialState({
  selected: null,
  criteriaObject: initialCriteriaObject,
  totalElements: 0,
  totalPages: 20,
  auditLogs: null,
  shelfList: null
});

export function OrderFixAssetRequestReducers(
  state = initialOrderResponseState,
  action: OrderFixAssetRequestActions
): OrderFixAssetRequestState {
  switch (action.type) {
    case OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_GET_BY_STORE_RESPONSE:
      return {
        ...state,
        selected: {
          ...state.selected,
          store: {
            merchantNo: action.payload.merchant,
            storeCode: action.payload.code,
            storeName: action.payload.name,
            storeCodeName: `${action.payload.code}-${action.payload.name}`,
            storeNo: action.payload.no,
            storeType: action.payload.merchantType,
            minOrder: action.payload.minOrder,
            openDate: action.payload.openDate
          }
        }
      };
    case OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_SAVE_DELIVERY_DETAILS:
      return {
        ...state,
        selected: {
          ...state.selected,
          deliveryDetails: action.payload,
          deliveryStatus: 'success'
        }
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
