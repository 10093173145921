<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-marketing"></em>
    {{ data.title }}
  </div>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="hasManagePermission && showCancelButton()"
    (click)="cancelSeasonalAds()"
  >
    <em class="icon-forbidden"></em>
    Cancel Ads
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close" aria-hidden="true"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div class="admin-form">
  <div class="info-header-container" *ngIf="data.mode === pageMode.VIEW_SEASONAL">
    <div class="info-header-item mr-4" *ngIf="posAdvertisementContent?.adsNo">
      <div class="info-header-label">
        <span>Seasonal Ads No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ posAdvertisementContent?.adsNo }}</span>
      </div>
    </div>

    <div class="info-header-item">
      <div class="info-header-label">
        <span>Seasonal Ads Status:</span>
      </div>
      <div class="info-header-value">
        <span [ngClass]="getColorStatus(posAdvertisementContent?.status)" class="status">
          {{ 'POS_ADVERTISEMENT.STATUS.' + posAdvertisementContent?.status | translate }}</span
        >
      </div>
    </div>
  </div>

  <div class="d-block-flex mb-5" [formGroup]="form">
    <div class="row" *ngIf="data.mode === pageMode.CREATE_SEASONAL || data.mode === pageMode.VIEW_SEASONAL">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label>Effective Date<span class="text-danger">*</span></label>
            <div class="row">
              <div class="col-md-12 mb-3">
                <input
                  id="effectiveDate"
                  type="text"
                  placeholder="{{ dateFormat }}"
                  class="form-control calendar"
                  formControlName="effectiveDate"
                  readonly
                  placement="bottom"
                  (bsValueChange)="onChangeEffectiveDate($event)"
                  bsDatepicker
                  [bsConfig]="{
                    containerClass: 'theme-dark-blue',
                    dateInputFormat: dateFormat,
                    showWeekNumbers: false,
                    useUtc: false,
                    minDate: currentDate,
                    maxDate: effectiveMaxDate
                  }"
                  [ngClass]="{
                    'is-invalid': submitted && form.controls.effectiveDate.errors
                  }"
                />
                <label for="effectiveDate" class="icon-calendar btn-calendar"></label>
                <div *ngIf="submitted && form.controls.effectiveDate.errors" class="invalid-feedback">
                  <div *ngIf="form.controls.effectiveDate.errors.required">
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                  <div *ngIf="form.controls.effectiveDate.errors.invalid">
                    {{ 'ERRORS.INVALID_DATATIME' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label>Expire Date<span class="text-danger">*</span></label>
            <div class="row">
              <div class="col-md-12 mb-3">
                <input
                  id="expireDate"
                  type="text"
                  placeholder="{{ dateFormat }}"
                  readonly
                  class="form-control calendar"
                  formControlName="expireDate"
                  placement="bottom"
                  (bsValueChange)="onChangeExpireDate($event)"
                  bsDatepicker
                  [bsConfig]="{
                    containerClass: 'theme-dark-blue',
                    dateInputFormat: dateFormat,
                    showWeekNumbers: false,
                    useUtc: false,
                    minDate: expireMinDate
                  }"
                  [ngClass]="{
                    'is-invalid': submitted && form.controls.expireDate.errors
                  }"
                />
                <label for="expireDate" class="icon-calendar btn-calendar"></label>
                <div *ngIf="submitted && form.controls.expireDate.errors" class="invalid-feedback">
                  <div *ngIf="form.controls.expireDate.errors.required">
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                  <div *ngIf="form.controls.expireDate.errors.invalid">
                    {{ 'ERRORS.INVALID_DATATIME' | translate }}
                  </div>
                  <div *ngIf="form.controls.expireDate.errors.afterEffective">
                    Expire Date less than Effective Date
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <app-images-panel
        #imagesPanel
        title="Images"
        description="Upload max 10 images in format .jpg, .jpeg, .png. Each image size up to 3 MB (Recommended 800 * 1080 pixels image)."
        [errorIncorrectFormat]="errorIncorrectFormat"
        maxNoOfFiles="10"
        allowedExt="(.jpg|.jpeg|.png)"
        size="3145728"
        [form]="form"
        [mode]="data.mode"
        [arrayName]="'images'"
        [uploadUrl]="uploadUrl"
        [uploadHeaders]="uploadHeaders"
        [baseStorageUrl]="baseStorageUrl"
        [hasDirectory]="true"
        [errorImageSize]="errorImageSize"
      >
      </app-images-panel>
    </div>

    <!--footer-->
    <div class="fixed-row-bottom form-row">
      <div class="float-left"></div>
      <div class="float-right">
        <button
          *ngIf="hasManagePermission && viewMode && showEditMode"
          type="button"
          id="btn-request-edit"
          class="btn btn-primary  mr-3"
          (click)="toggleToEditMode()"
        >
          Edit
        </button>
        <button
          *ngIf="createMode || editMode"
          type="button"
          id="btn-cancel"
          class="btn btn-standard mr-2"
          (click)="onExit()"
        >
          Cancel
        </button>
        <button
          *ngIf="createMode || editMode"
          type="button"
          id="btn-submit"
          class="btn btn-primary"
          (click)="onSubmit()"
          appPreventDoubleClick
        >
          Submit
        </button>
      </div>
    </div>
    <!--End footer-->
  </div>
</div>
