import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { OrderCustomerDetail } from '../../../../shared/models/order.model';

@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.scss']
})
export class StoreInfoComponent implements OnInit, OnDestroy {
  public title: string;
  public historyHeader: string;
  public customerDetail: OrderCustomerDetail;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {}

  ngOnDestroy() {}

  decline(): void {
    this.bsModalRef.hide();
  }
}
