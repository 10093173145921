import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MasterDataEnum } from '../enum/master-data.enum';
import { GraphqlQueryObject, GraphqlQuerySortOptions, OrderByEnum } from '../gql/common.gql';
import { SegmentList } from '../gql/segmentList.gql';
import { StoreListSearchCriteria } from '../models';
import {
  ApproveOrderRequest,
  OrderCreateRequest,
  OrderId,
  OrderRequestListResponse,
  OrderRequestListSearchCriteria,
  OrderRequestNo,
  OrderRequestValidate,
  OrderRequestViewResponse,
  ProductOrderItem
} from '../models/order-request.model';
import { BaseService } from './base.service';
import { MasterService } from './master.service';

@Injectable()
export class OrderRequestService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private readonly masterService: MasterService) {
    super();
    this.envService = this.env.services.orderRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: OrderRequestListSearchCriteria): Observable<OrderRequestListResponse> {
    const params = this.getParams(criteria);

    return this.http.get<OrderRequestListResponse>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getOrderById(orderId: OrderId): Observable<OrderRequestViewResponse> {
    const url = this.getFullUrl(this.envService.view, {
      orderId: orderId.orderId
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getOrderByRefNo(orderRequestNo: OrderRequestNo): Observable<OrderRequestViewResponse> {
    const url = this.getFullUrl(this.envService.viewReferenceNo, {
      orderRequestNo: orderRequestNo.orderRequestNo
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public saveRequest(OrderRequest: OrderCreateRequest): Observable<OrderRequestViewResponse> {
    const url = this.getFullUrl(this.envService.save);

    return this.http.put<any>(url, OrderRequest, { headers: this.loaderHeaders(2000) });
  }

  public submitRequest(OrderRequest: OrderCreateRequest): Observable<OrderRequestViewResponse> {
    const url = this.getFullUrl(this.envService.submit);

    return this.http.post<any>(url, OrderRequest, { headers: this.loaderHeaders(2000) });
  }

  public approveRequest(request: ApproveOrderRequest): Observable<OrderRequestViewResponse> {
    const url = this.getFullUrl(this.envService.approve);

    return this.http.put<OrderRequestViewResponse>(url, request, {
      headers: this.loaderHeaders(3000),
      observe: 'body'
    });
  }

  public deleteByRequestId(orderId: OrderId): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.delete, {
      orderId: orderId.orderId
    });
    return this.http.delete<any>(url, { headers: this.loaderHeaders(3000), observe: 'body' });
  }

  public getHistoryLogs(orderId: OrderId): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      orderId: orderId.orderId
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public validateOrderList(
    storeNo: string,
    orderType: string,
    itemList: Array<OrderRequestValidate>
  ): Observable<ProductOrderItem[]> {
    const params = this.getParams({ storeNo, orderType });
    const url = this.getFullUrl(this.envService.validateOrderList);

    return this.http.post<ProductOrderItem[]>(url, itemList, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public searchStoreForFirstLotByCriteria(criteria: StoreListSearchCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.getStoreFirstLot);
    const params = this.getParams(criteria);
    return this.http
      .get<any>(url, {
        headers: this.headers,
        observe: 'body',
        params
      })
      .pipe(
        map(res => {
          if (res && res.content) {
            res.content.forEach(item => {
              item.storeCodeName = `${item.code}-${item.name}`;
            });
          }
          return res;
        })
      );
  }

  public searchStoreForSpecialByCriteria(criteria: StoreListSearchCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.getStoreSpecial);
    const params = this.getParams(criteria);
    return this.http
      .get<any>(url, {
        headers: this.headers,
        observe: 'body',
        params
      })
      .pipe(
        map(res => {
          if (res && res.content) {
            res.content.forEach(item => {
              item.storeCodeName = `${item.code}-${item.name}`;
            });
          }
          return res;
        })
      );
  }

  public getSegmentListData(): Observable<ApolloQueryResult<SegmentList>> {
    const segmentListQuery = new GraphqlQueryObject();
    segmentListQuery.name = MasterDataEnum.SEGMENT;
    segmentListQuery.sort = { orderBy: OrderByEnum.CODE } as GraphqlQuerySortOptions;

    return this.masterService.getMasterDataByNames([segmentListQuery]);
  }

  public downloadFileTemplate(): Observable<any> {
    const url = this.getFullUrl(this.envService.downloadFileTemplate);
    return this.http.get<any>(url, { headers: this.headers, responseType: 'blob' as 'json' });
  }

  public importFileRequest(file: File, guid: string): Observable<any> {
    const url = this.getFullUrl(this.envService.importValidate);
    let formData = null;
    formData = new FormData();
    formData.append('guid', guid);
    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(url, formData, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public submitFileRequest(guid: string): Observable<any> {
    const url = this.getFullUrl(this.envService.importSubmit);
    const params = this.getParams({ guid });

    return this.http.post<any>(url, null, { headers: this.loaderHeaders(), observe: 'body', params });
  }

  public getEstimatedDelivery(storeNo: string, deliveryDate: string): Observable<any> {
    const url = this.getFullUrl(this.envService.getEstimatedDelivery, {
      storeNo: storeNo,
      deliveryDate: deliveryDate
    });

    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }
}
