import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { filter, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { StoreList } from '../../../shared/models';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { RedeemTransactionReportCriteria } from '../../../shared/models/report.model';
import { MerchantService } from '../../../shared/services/merchant.service';
import { StoreService } from '../../../shared/services/store.service';
import { AppStates } from '../../../shared/store/state/app.states';
import { dateToStringCriteria } from '../../../shared/utils/date-util';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-redeem-transaction-modal',
  templateUrl: './redeem-transaction-modal.component.html',
  styleUrls: ['./redeem-transaction-modal.component.scss']
})
export class RedeemTransactionModalComponent extends BaseReportModalComponent implements OnInit {
  @ViewChild('redeemTransactionModal', { static: false }) redeemTransactionModal: ModalDirective;
  public exportRedeemTransactionForm: FormGroup;
  public submitted: boolean;
  public exportFormInValid: boolean;
  public responseExportError: string;

  public maxDate: Date;
  public minDate: Date;
  public toMaxDate: Date;

  public storeList: StoreList[] = [];
  public exportStatusList = filterDropdown.billPaymentStatusFilter;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly formBuilder: FormBuilder,
    protected readonly translate: TranslateService,
    protected readonly merchantService: MerchantService,
    protected readonly storeService: StoreService
  ) {
    super();
  }

  ngOnInit() {
    this.exportFormInValid = true;
    this.responseExportError = null;

    this.minDate = new Date(2019, 0, 1);

    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() - 1);

    this.toMaxDate = new Date();
    this.toMaxDate.setDate(this.toMaxDate.getDate() - 1);

    this.initControl();
    this.loadStore();
  }

  initControl() {
    this.exportRedeemTransactionForm = this.formBuilder.group({
      dateFrom: [null],
      dateTo: [null],
      no: [null]
    });
  }

  loadStore() {
    this.merchantService
      .searchStoreByCriteria({
        sortBy: 'code',
        sortOrder: 'asc',
        page: 0,
        size: 10000
      })
      .pipe(
        filter(data => Boolean(data)),
        take(1)
      )
      .subscribe(data => {
        this.storeList = data.content;
      });
  }

  onChangeExportRedeemTranDateFrom(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeExportRedeemTranDateTo(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() - 1);
    }
  }

  onExport() {
    this.submitted = true;
    if (this.exportFormInValid) {
      return;
    }
    this.responseExportError = null;
    const rawData = this.exportRedeemTransactionForm.getRawValue();
    const redeemTransactionReportCriteria = new RedeemTransactionReportCriteria({
      transactionDateFrom: dateToStringCriteria(rawData.dateFrom, true),
      transactionDateTo: dateToStringCriteria(rawData.dateTo, false),
      storeNo: rawData.no && rawData.no.length > 0 ? rawData.no.toString() : rawData.no
    });

    this.storeService.exportRedeemTransaction(redeemTransactionReportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportRedeemTransaction.prefix} ${this.timeToExport}.xlsx`);
      },
      error => {
        this.responseExportError = error.error.message || this.translate.instant(error.error.translateKey);
      },
      () => {
        this.submitted = false;
        this.closeExportModal();
      }
    );
  }

  closeExportModal() {
    this.redeemTransactionModal.hide();
  }

  openExportModal() {
    this.exportRedeemTransactionForm.reset();
    this.responseExportError = null;
    this.exportFormInValid = true;
    this.redeemTransactionModal.show();
  }

  ngOnDestroy(): void {
    this.exportRedeemTransactionForm.reset();
  }

  get form() {
    return this.exportRedeemTransactionForm.controls;
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportRewardTransaction.timeFormat);
  }
}
