import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OrderSelectItemSearchCriteria, ProductOrderItem } from '../../models/order-request.model';
import { OrderSelectItemActions, OrderSelectItemActionTypes } from '../actions/order-select-item.actions';

export interface OrderSelectiveSelectItemState extends EntityState<ProductOrderItem> {
  isLoading: boolean;
  saveSuccess: boolean;
  criteriaObject: OrderSelectItemSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<ProductOrderItem> = createEntityAdapter<ProductOrderItem>({
  selectId: (productOrderItem: ProductOrderItem) => productOrderItem.articleNo
});

export const initialOrderSelectItemState: OrderSelectiveSelectItemState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function OrderSelectiveSelectItemReducers(
  state = initialOrderSelectItemState,
  action: OrderSelectItemActions
): OrderSelectiveSelectItemState {
  switch (action.type) {
    case OrderSelectItemActionTypes.SELECTIVE_SELECT_ITEM_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case OrderSelectItemActionTypes.SELECTIVE_SELECT_ITEM_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case OrderSelectItemActionTypes.SELECTIVE_SELECT_ITEM_LIST_RESET:
      return adapter.removeAll({
        ...state,
        totalElements: 0,
        totalPages: 0
      });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
