<div class="modal-content admin-form">
  <div class="modal-header">
    <div class="pull-left text-center">
      <h5 class="modal-title">{{ title }}</h5>
      <div *ngIf="this.subTitle">{{ subTitle }}</div>
    </div>

    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" [formGroup]="form">
    <div class="form-row">
      <div class="form-group col-md">
        <label for="profitReport">{{ templateName }}</label>
        <div class="d-flex">
          <input type="text" class="form-control mr-3" id="profitReport" placeholder="{{ templateName }}" disabled />
          <button type="button" class="btn btn-primary" (click)="onDownloadTemplate()">
            <em class="icon-download"></em>
            {{ 'REPORTS.DOWNLOAD' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md">
        <label for="fileInput">{{ fileTitle }}</label>
        <div class="d-flex">
          <app-files-input
            id="fileInput"
            formControlName="files"
            [size]="50000000"
            [controlName]="'files'"
            [disabled]="loading"
            (clearInput)="reset()"
            (showErrorMessage)="setErrorMessage($event)"
            (submit)="onSubmit($event)"
            class="flex-item"
            #fileInput
          ></app-files-input>
        </div>
      </div>
    </div>
    <div class="form-row" *ngIf="this.errorMessage || isUploadSuccess">
      <div class="form-group col-md">
        <div class="alert-success form-group mb-0" *ngIf="isUploadSuccess">
          <div>
            {{ confirmSubmitText }}
          </div>
        </div>
        <div class="alert-danger form-group mb-0 error-message" *ngIf="errorMessage">
          {{ errorMessage }}
          <a class="btn btn-link upload-error-file" (click)="onDownloadErrorExcel()" *ngIf="isFileError">download</a>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="closeModal()" id="btCancel">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="!form.value.files || loading || errorMessage"
      id="btnSubmit"
      (click)="onSubmit()"
    >
      Submit
    </button>
  </div>
</div>
