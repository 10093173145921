import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as _ from 'lodash';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../base/base.component';
import { isZeroValidator } from '../../../shared/custom-validators/is-zero-validator';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { PurchaseConditionPageModes } from '../../../shared/enum/purchase-condition.enum';
import { NewRequestTypeEnum } from '../../../shared/enum/request-step.enum';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { baseCurrency } from '../../../shared/models/list-value/list-key-value.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { PurchaseConditionTabResponse } from '../../../shared/models/purchase-condition-request.model';
import { SelectWarehouseList } from '../../../shared/models/warehouse.model';
import { PurchaseConditionReqMOVLoaded } from '../../../shared/store/actions/purchase-condition.actions';
import { SelectWarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { selectPurchaseConditionReqMov } from '../../../shared/store/selectors/purchase-condition.selectors';
import { selectWarehouseList } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

interface MinimumOrderValue {
  rowId?: number;
  purchaseAmountExVat: number;
  wmsCode: { wmsCodeName: string; wmsCode: string; warehouseCode: string }[];
}
@Component({
  selector: 'app-supplier-condition-tab',
  templateUrl: './supplier-condition-tab.component.html',
  styleUrls: ['./supplier-condition-tab.component.scss']
})
export class SupplierConditionTabComponent extends BaseComponent implements OnInit, OnDestroy {
  warehouseList: SelectWarehouseList[] = [];

  @Input() mode: PurchaseConditionPageModes;
  @Input() purchaseConditionForm: FormGroup;
  @Output() dirtyMinimumOrderFormChange = new EventEmitter<boolean>();
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  public warehouseList$: Observable<SelectWarehouseList[]> = this.store
    .select(selectWarehouseList)
    .pipe(tap(val => (this.warehouseList = val)));

  public minimumOrderLists$: Observable<PurchaseConditionTabResponse[]> = this.store
    .select(selectPurchaseConditionReqMov)
    .pipe(
      filter(data => Boolean(data)),
      map(val => val)
    );

  public purchaseConditionPageMode = PurchaseConditionPageModes;
  public duplicateWarehouseErrorList = [];
  public listOfValue: {};
  public title = '';
  public buttonLabel = '';
  public purchaseConditionReqMOVFormSave: PurchaseConditionTabResponse[] = [];
  public subscriptions = new Subscription();
  public modalRef: BsModalRef;
  public form: FormGroup;
  public isSubmitted = false;
  public bsModalRef: BsModalRef;
  public minimumOrderList: MinimumOrderValue[] = [];
  public requestTypeEnum = NewRequestTypeEnum;

  constructor(
    protected readonly store: Store<AppStates>,
    private fb: FormBuilder,
    protected readonly translate: TranslateService,
    protected readonly modalService: BsModalService
  ) {
    super(store, modalService);
  }

  ngOnInit() {
    this.store.dispatch(new SelectWarehouseListRequestAction({ isWarehouse: true }));

    this.form = this.fb.group({
      rowId: [null],
      purchaseAmountExVat: [null, [Validators.required, isZeroValidator()]],
      currency: [{ value: 'THB', disabled: true }, Validators.required],
      wmsCode: [null, Validators.required]
    });

    this.listOfValue = { baseCurrency };

    this.initialPurchaseValue();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initialPurchaseValue() {
    const minimumOrderListsObservable = this.minimumOrderLists$.pipe(
      map(initValue => {
        this.purchaseConditionReqMOVFormSave = initValue;
        this.minimumOrderList = [];
        initValue.forEach(val => {
          const rowIdList = this.minimumOrderList.map(minimum => minimum.rowId);
          if (!rowIdList.includes(val.rowId)) {
            rowIdList.push(val.rowId);
            this.minimumOrderList.push({
              rowId: val.rowId,
              purchaseAmountExVat: val.purchaseAmountExVat,
              wmsCode: [{ wmsCodeName: val.wmsCodeName, wmsCode: val.wmsCode, warehouseCode: val.warehouseCode }]
            });
          } else {
            this.minimumOrderList = this.minimumOrderList.map(item => {
              if (item.rowId === val.rowId) {
                item.wmsCode.push({
                  wmsCodeName: val.wmsCodeName,
                  wmsCode: val.wmsCode,
                  warehouseCode: val.warehouseCode
                });
              }

              return item;
            });
          }
        });
        this.minimumOrderList = this.orderByPurchaseAmountExVat(this.minimumOrderList);
        return this.minimumOrderList;
      })
    );

    this.subscriptions.add(minimumOrderListsObservable.subscribe());
  }

  doAfterVersionAlertModal() {}

  subscribeForVersionError() {}

  subscribeForSaveSuccess() {}

  orderByPurchaseAmountExVat(purchaseMinimumOrderList) {
    return _.orderBy(purchaseMinimumOrderList, ['rowNo'], ['asc']);
  }

  isInvalidForm(isSubmitted, form: ValidationErrors) {
    if (isSubmitted && form) {
      return 'is-invalid';
    } else {
      return '';
    }
  }

  onHideModal(templateDialog: ModalDirective) {
    this.hideDialog(templateDialog);
  }

  hideDialog(templateDialog: ModalDirective) {
    this.resetFormValue();
    templateDialog.hide();
  }

  onSubmit(templateDialog: ModalDirective) {
    this.isSubmitted = true;

    const rawValue: {
      rowId: number;
      purchaseAmountExVat: number;
      currency: string;
      wmsCode: string[];
    } = this.form.getRawValue();

    const wmsCodeList = [];
    this.warehouseList.map(val => {
      if (rawValue.wmsCode && rawValue.wmsCode.includes(val.wmsCode)) {
        wmsCodeList.push({
          wmsCodeName: val.locationDisplay,
          wmsCode: val.wmsCode,
          locationDisplay: val.locationDisplay,
          warehouseCode: val.code
        });
      }
    });

    rawValue.wmsCode = wmsCodeList;

    if (this.checkDuplicateWarehouseFn(rawValue) || this.form.invalid) {
      return;
    }

    if (rawValue.rowId) {
      // edit mode
      this.minimumOrderList.forEach(val => {
        if (rawValue.rowId === val.rowId) {
          // add to same row
          val.wmsCode = wmsCodeList;
          val.purchaseAmountExVat = rawValue.purchaseAmountExVat;
        }
      });
    } else {
      const rowIdmax: number =
        this.minimumOrderList.length > 0 ? _.maxBy(this.minimumOrderList, 'rowId').rowId + 1 : 1;
      const newMinimumValueRow = {
        rowId: rowIdmax,
        purchaseAmountExVat: rawValue.purchaseAmountExVat,
        wmsCode: wmsCodeList
      };
      this.minimumOrderList.push(newMinimumValueRow);
    }

    if (
      [PurchaseConditionPageModes.EDIT].includes(this.mode) ||
      ([PurchaseConditionPageModes.EDIT_REQUEST].includes(this.mode) &&
        this.purchaseConditionForm.controls['requestType'].value === this.requestTypeEnum.EDIT)
    ) {
      this.purchaseConditionForm.patchValue({
        movEdit: true
      });
    }

    this.prepareForSave();
    this.resetFormValue();
    templateDialog.hide();
  }

  prepareForSave() {
    this.purchaseConditionReqMOVFormSave = [];
    this.minimumOrderList.forEach(purchaseGroup => {
      purchaseGroup.wmsCode.forEach(wms => {
        this.purchaseConditionReqMOVFormSave.push({
          rowId: purchaseGroup.rowId,
          purchaseAmountExVat: purchaseGroup.purchaseAmountExVat,
          warehouseCode: wms.warehouseCode,
          wmsCode: wms.wmsCode,
          wmsCodeName: wms.wmsCodeName
        });
      });
    });

    this.dirtyMinimumOrderFormChange.emit(true);
    this.store.dispatch(new PurchaseConditionReqMOVLoaded(this.purchaseConditionReqMOVFormSave));
  }

  onDelete(index) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete Minimum Order Value?',
        okText: 'Yes, delete',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this), take(1))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.minimumOrderList.splice(index, 1);

          if (
            [PurchaseConditionPageModes.EDIT].includes(this.mode) ||
            ([PurchaseConditionPageModes.EDIT_REQUEST].includes(this.mode) &&
              this.purchaseConditionForm.controls['requestType'].value === this.requestTypeEnum.EDIT)
          ) {
            this.purchaseConditionForm.patchValue({
              movEdit: true
            });
          }

          this.prepareForSave();
        }
      });
  }

  checkDuplicateWarehouseFn(formDate: {
    rowId: number;
    purchaseAmountExVat: number;
    currency: string;
    wmsCode: SelectWarehouseList[] | string[];
  }): boolean {
    let isDuplicate = false;
    this.duplicateWarehouseErrorList = [];
    this.minimumOrderList.forEach(itemFormTable => {
      // ไม่เอาตัวเอง
      if (!formDate.rowId || itemFormTable.rowId !== formDate.rowId) {
        formDate.wmsCode.forEach(wmsCodeName => {
          this.duplicateWarehouseErrorList.push(
            ...itemFormTable.wmsCode.filter(val => val.wmsCodeName === wmsCodeName.locationDisplay)
          );
          if (this.duplicateWarehouseErrorList.length > 0) {
            isDuplicate = true;
          } else {
            isDuplicate = false;
          }
        });
      }
    });

    return isDuplicate;
  }

  onShow(templateDialog: ModalDirective) {
    this.title = this.getPurchaseMinimumOrderTitle(PurchaseConditionPageModes.CREATE).title;
    this.buttonLabel = this.getPurchaseMinimumOrderTitle(PurchaseConditionPageModes.CREATE).button;
    templateDialog.show();
  }

  onEdit(minimumOrderValue: MinimumOrderValue, templateDialog: ModalDirective) {
    let prepareFormValue;

    prepareFormValue = {
      rowId: minimumOrderValue.rowId,
      purchaseAmountExVat: minimumOrderValue.purchaseAmountExVat,
      wmsCode: minimumOrderValue.wmsCode.map(code => code.wmsCode)
    };

    this.form.patchValue(prepareFormValue);
    this.title = this.getPurchaseMinimumOrderTitle(PurchaseConditionPageModes.EDIT).title;
    this.buttonLabel = this.getPurchaseMinimumOrderTitle(PurchaseConditionPageModes.EDIT).button;
    templateDialog.show();
  }

  resetFormValue() {
    this.duplicateWarehouseErrorList = [];
    this.isSubmitted = false;
    this.form.reset({ currency: { value: 'THB', disabled: true } });
  }

  wmsCodeNameForDisplay(wmsCodeList: { wmsCodeName: string }[]) {
    wmsCodeList = _.orderBy(wmsCodeList, ['wmsCodeName'], ['asc']);
    return wmsCodeList.map(val => val.wmsCodeName).join('\n');
  }

  getPurchaseMinimumOrderTitle(mode: PurchaseConditionPageModes) {
    let title: string;
    let button: string;
    switch (mode) {
      case PurchaseConditionPageModes.CREATE:
        title = 'Add Minimum Order Value';
        button = 'Add';
        break;
      case PurchaseConditionPageModes.EDIT:
        title = 'Edit Minimum Order Value';
        button = 'Update';
        break;
      default:
    }
    return { title, button };
  }
}
