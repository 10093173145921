import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { NewMasterData } from '../../../shared/gql/common.gql';
import { AwaitingFirstLotExportCriteria } from '../../../shared/models/report.model';
import { MasterService } from '../../../shared/services/master.service';
import { OrderService } from '../../../shared/services/order.service';
import { AppStates } from '../../../shared/store/state/app.states';
import { dateToStringCriteria } from '../../../shared/utils/date-util';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-awaiting-first-lot-modal',
  templateUrl: './awaiting-first-lot-modal.component.html',
  styleUrls: ['./awaiting-first-lot-modal.component.scss']
})
export class AwaitingFirstLotModalComponent extends BaseReportModalComponent implements OnDestroy {
  @ViewChild('awaitingFirstLotModal', { static: false }) awaitingFirstLotModal: ModalDirective;
  public exportForm: FormGroup;
  public submitted: boolean;
  public maxDate: Date;
  public minDate: Date;
  public responseExportError: string;
  public storeTypeList: Array<NewMasterData> | null;
  public regionList: Array<NewMasterData> | null;
  public stateList: Array<NewMasterData> | null;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly formBuilder: FormBuilder,
    protected masterService: MasterService,
    protected readonly translate: TranslateService,
    protected readonly orderService: OrderService
  ) {
    super();
  }

  ngOnInit() {
    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.initControl();
    this.initData();
  }

  initData() {
    this.masterService
      .getMasterDataByNames([MasterDataEnum.MERCHANT, MasterDataEnum.REGION, MasterDataEnum.STATE])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.storeTypeList = result[MasterDataEnum.MERCHANT];
        this.regionList = result[MasterDataEnum.REGION];
        this.stateList = result[MasterDataEnum.STATE];
      });
  }

  initControl() {
    this.exportForm = this.formBuilder.group({
      storeOpenDateFrom: [null],
      storeOpenDateTo: [null],
      storeType: [null],
      region: [null],
      state: [null]
    });
  }

  ngOnDestroy(): void {}

  onChangeExportStoreOpenDateTo($event: Date) {
    if ($event && !isNaN($event.getTime())) {
      this.maxDate = new Date($event);
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  onChangeStoreOpenDateFrom($event: Date) {
    if ($event && !isNaN($event.getTime())) {
      this.minDate = new Date($event);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  openExportModal() {
    this.exportForm.reset();
    this.responseExportError = null;
    this.exportFormInValid = true;
    this.awaitingFirstLotModal.show();
  }

  closeExportModal() {
    this.exportForm.reset();
    this.awaitingFirstLotModal.hide();
  }

  get form() {
    return this.exportForm.controls;
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportAwaitingFirstLot.timeFormat);
  }

  onExport() {
    this.responseExportError = null;
    const rawData = this.exportForm.getRawValue();
    const exportCriteria = new AwaitingFirstLotExportCriteria({
      storeOpenDateFrom: dateToStringCriteria(rawData.storeOpenDateFrom, true),
      storeOpenDateTo: dateToStringCriteria(rawData.storeOpenDateTo, false),
      storeType: this.getValue(rawData.storeType),
      region: this.getValue(rawData.region),
      state: this.getValue(rawData.state)
    });

    this.orderService.exportAwaitingFirstLot(exportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportAwaitingFirstLot.prefix} ${this.timeToExport}.xlsx`);
      },
      error => {
        this.responseExportError = error.error.message || this.translate.instant(error.error.translateKey);
      },
      () => {
        this.submitted = false;
        this.closeExportModal();
      }
    );
  }

  getValue(value) {
    return value && value.length > 0 ? value.toString() : value;
  }
}
