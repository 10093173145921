<div class="modal-content admin-form">
  <div class="modal-header">
    <h5 class="modal-title pull-left">Import</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" [formGroup]="form">
    <div class="form-row">
      <div class="form-group col-md">
        <label for="storeUseOrderItems">{{ titleLabel }}</label>
        <app-order-file-upload
          id="storeUseOrderItems"
          formControlName="storeUseOrderItems"
          [allowedTypes]="'sheet'"
          [withMeta]="true"
          [controlName]="'storeUseOrderItems'"
          [allowedExt]="'xlsx'"
          [uploadUrl]="form.get('uploadUrl').value"
          [orderRequestId]="orderRequestId"
          [orderSubTypeEnum]="orderSubTypeEnum"
          [isAddSelectiveItem]="isAddSelectiveItem"
          (submitted)="onSubmit()"
        ></app-order-file-upload>
      </div>
    </div>

    <div *ngIf="errorMessage" class="alert-danger form-group" role="alert">
      {{ errorMessage }}
    </div>

    <div
      class="form-row"
      *ngIf="
        form.controls.storeUseOrderItems.errors ||
        (form.controls.storeUseOrderItems.value &&
          form.controls.storeUseOrderItems.value[0] &&
          form.controls.storeUseOrderItems.value[0].status)
      "
    >
      <div class="form-group col-md">
        <div
          class="alert-danger form-group mb-0"
          *ngIf="
            form.controls.storeUseOrderItems.errors ||
            (form.controls.storeUseOrderItems.value &&
              form.controls.storeUseOrderItems.value[0].status === uploadStatus.FAILED)
          "
        >
          <div *ngIf="form.controls.storeUseOrderItems.errors?.fileExt">
            Incorrect Format (allow only format file .xlsx).
          </div>
          <div
            *ngIf="
              form.controls.storeUseOrderItems.value &&
              form.controls.storeUseOrderItems.value[0].errorMessage &&
              form.controls.storeUseOrderItems.value[0].status === uploadStatus.FAILED
            "
          >
            {{ form.controls.storeUseOrderItems.value[0].errorMessage }}
          </div>

          <div
            *ngIf="
              form.controls.storeUseOrderItems.value &&
              form.controls.storeUseOrderItems.value[0].validations?.length &&
              form.controls.storeUseOrderItems.value[0].status === uploadStatus.FAILED
            "
          >
            {{ form.controls.storeUseOrderItems.value[0].validations.length + ' Error Found.' }}
          </div>
        </div>
      </div>
    </div>

    <ng-container
      *ngIf="form.controls.storeUseOrderItems.value && form.controls.storeUseOrderItems.value[0].validations?.length"
    >
      <table class="table table-striped table-bordered table-hover w-100">
        <thead>
          <tr class="error-background">
            <th class="text-center w-10">Row</th>
            <th class="text-center w-30">Column</th>
            <th class="text-center w-30">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let error of (form.controls.storeUseOrderItems?.value)[0].validations; index as i">
            <td class="text-center">{{ error.row }}</td>
            <td class="text-left">{{ error.column }}</td>
            <td class="text-left">{{ error.description }}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="bsModalRef.hide()" id="btnCancel">
      Cancel
    </button>

    <button
      type="button"
      class="btn btn-primary btn-loader"
      (click)="fileUpload.uploadFile()"
      id="btnSumit"
      [disabled]="
        form.controls.storeUseOrderItems.errors ||
        !form.controls.storeUseOrderItems.value ||
        form.controls.storeUseOrderItems.value?.length === 0 ||
        form.controls.storeUseOrderItems.value[0].status === uploadStatus.FAILED
      "
    >
      OK
    </button>
  </div>
</div>
