import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap';
import { environment } from '../../../../../environments/environment';
import { ProductTypeEnum } from '../../../../shared/enum/product-type.enum';
import * as filterDropdown from '../../../../shared/models/list-value/list-key-value.model';
import { ShelfFixAssetList } from '../../../../shared/models/shelf-fix-asset.model';
import { ShelfInventoryList } from '../../../../shared/models/shelf-inventory.model';
import { ShelfList } from '../../../../shared/models/shelf.model';
import { ShelfFixAssetService } from '../../../../shared/services/shelf-fix-asset.service';
import { ShelfInventoryService } from '../../../../shared/services/shelf-inventory.service';
import { ShelfRequestService } from '../../../../shared/services/shelf-request.service';
import { ShelfService } from '../../../../shared/services/shelf.service';

@Component({
  selector: 'app-export-shelf',
  templateUrl: './export-shelf.component.html',
  styleUrls: ['./export-shelf.component.scss']
})
export class ExportShelfComponent implements OnInit {
  public submitted: boolean;
  public form: FormGroup;
  public shelfList: ShelfList[];
  public shelfListFixAsset: ShelfFixAssetList[];
  public shelfListInventory: ShelfInventoryList[];
  public listShelf: any;
  public errorExport: string;
  public shelfType?: string;
  private fnCallServiceShelfType: Function = null;
  private fnGetFileConfig: Function = null;
  private fnGetTitleModel: Function = null;
  public exportStatusList = filterDropdown.listStatusFilter.filter(v => Boolean(v.value));

  private readonly MAX_SHELF = 100;

  constructor(
    private readonly fb: FormBuilder,
    private readonly bsModalRef: BsModalRef,
    private readonly shelfService: ShelfService,
    private readonly shelfRequestService: ShelfRequestService,
    private readonly shelfInventoryService: ShelfInventoryService,
    private readonly shelfFixAsset: ShelfFixAssetService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      no: [[], Validators.required],
      status: [[]]
    });

    this.fnDelegate();
  }

  fnDelegate() {
    if (this.shelfType === ProductTypeEnum.INVENTORY) {
      this.initDataModal(this.shelfInventoryService);
      this.fnGetTitleModel = this.getResourceInventoryTitle;
      this.fnGetFileConfig = this.getResourceInventoryConfig;
      this.fnCallServiceShelfType = this.callServiceInventory;
    } else if (this.shelfType === ProductTypeEnum.FIX_ASSET) {
      this.initDataModal(this.shelfFixAsset);
      this.fnGetTitleModel = this.getResourceFixAssetTitle;
      this.fnGetFileConfig = this.getResourceFixAssetConfig;
      this.fnCallServiceShelfType = this.callServiceFixAsset;
    } else {
      this.initData();
      this.fnGetTitleModel = this.getResourceShelfTitle;
      this.fnGetFileConfig = this.getResourceShelfConfig;
      this.fnCallServiceShelfType = this.callServiceSehelf;
    }
  }

  initData() {
    this.shelfService
      .searchByCriteria({
        page: 0,
        size: 10000,
        sortBy: 'shelfName',
        sortOrder: 'asc'
      })
      .subscribe(data => {
        this.shelfList = data.content;
      });

    this.form.valueChanges.subscribe(() => {
      if (this.form.get('no').value.length <= this.MAX_SHELF && !this.shelfType) {
        this.errorExport = null;
      }
    });
  }

  initDataModal(shelfService) {
    shelfService
      .searchByCriteria({
        page: 0,
        size: 10000,
        sortBy: 'shelfName.sort',
        sortOrder: 'asc'
      })
      .subscribe(data => {
        this.listShelf = data.content.map(x => ({
          no: x.shelfNo,
          shelfName: x.shelfInfo.shelfName
        }));
      });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  onExport() {
    this.submitted = true;

    const fileConfig = this.fnGetFileConfig();
    const dateExport = moment().format(fileConfig.timeFormat);
    const selected = this.form.get('no').value;
    const status = this.form.get('status').value;

    if (!this.shelfType) {
      if (this.form.invalid) {
        return;
      }
      if (selected.length > this.MAX_SHELF) {
        this.errorExport = `Export limit exceeded (up to ${this.MAX_SHELF} shelves).`;
        return;
      }

      this.fnCallServiceShelfType(dateExport, selected, fileConfig);
    }

    this.fnCallServiceShelfType(dateExport, selected, status, fileConfig);
  }

  get titleModal(): string {
    return this.fnGetTitleModel();
  }

  private callServiceFixAsset(dateExport: string, selected: any, status: any, fileConfig: any) {
    this.shelfFixAsset.exportShelf(selected, status).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${fileConfig.prefix} ${dateExport}.xlsx`);
      },
      errorResponse => {
        this.errorExport = errorResponse.error.message || this.translate.instant(errorResponse.error.translateKey);
      },
      () => this.closeModal()
    );
  }

  private callServiceInventory(dateExport: string, selected: any, status: any, fileConfig: any) {
    this.shelfInventoryService.exportShelf(selected, status).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${fileConfig.prefix} ${dateExport}.xlsx`);
      },
      errorResponse => {
        this.errorExport = errorResponse.error.message || this.translate.instant(errorResponse.error.translateKey);
      },
      () => this.closeModal()
    );
  }

  private callServiceSehelf(dateExport: string, selected: any, fileConfig: any) {
    this.shelfRequestService.exportShelf(selected).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${fileConfig.prefix} ${dateExport}.xlsx`);
      },
      errorResponse => {
        this.errorExport = errorResponse.error.message || this.translate.instant(errorResponse.error.translateKey);
      },
      () => this.closeModal()
    );
  }

  private getResourceShelfConfig() {
    return environment.fileName.exportShelf;
  }

  private getResourceInventoryConfig() {
    return environment.fileName.exportShelfInventory;
  }

  private getResourceFixAssetConfig() {
    return environment.fileName.exportShelfFixAsset;
  }

  private getResourceInventoryTitle(): string {
    return 'Export Shelf Inventory';
  }

  private getResourceFixAssetTitle(): string {
    return 'Export Shelf Fix Asset';
  }

  private getResourceShelfTitle(): string {
    return 'Export Shelf';
  }
}
