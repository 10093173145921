<div
  class="modal fade"
  bsModal
  #templateDialog="bs-modal"
  [config]="{ backdrop: 'static' }"
  role="dialog"
  aria-labelledby="dialog-static-name"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          {{ title }}
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="onHideModal()">
          <em class="icon-close"></em>
        </button>
      </div>

      <div class="modal-body" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="container-fluid">
          <div class="admin-form">
            <div class="row mb-3">
              <div class="col-md-12">
                <label> Lead Time<span class="text-danger">*</span></label>
                <app-validators-message [(submitted)]="isSubmitted">
                  <app-numeric-textbox
                    [minValue]="0"
                    [decimals]="0"
                    [maxValue]="999"
                    [min]="0"
                    maxlength="3"
                    [format]="'0,0'"
                    [negativeSign]="false"
                    class="form-control"
                    placeholder="Lead Time"
                    formControlName="leadTime"
                  ></app-numeric-textbox>
                </app-validators-message>
              </div>
            </div>
            <app-checkbox-group formControlName="orderSchedules">
              <div class="row mb-3">
                <div class="col-md-9 mb-2 pr-0">
                  <label>Order Schedule<span class="text-danger">*</span></label>
                  <div
                    *ngIf="isInvalidForm | pureFn: isSubmitted:form.controls.orderSchedules.errors"
                    class="text-danger invalid-display"
                  >
                    {{ 'ERRORS.REQUIRED_AT_LEAST_ONE_DAY_SCHEDULE' | translate: { value: 'Order' } }}
                  </div>
                </div>
                <div class="col-md-3 text-right mb-2 pl-0">
                  <a class="my-1 btn-text-link" (click)="orderSelectAllDays()">Select All</a
                  ><span class="px-1">/</span
                  ><a class="my-1 btn-text-link" (click)="orderClearAllDays()">Clear All</a>
                </div>
                <div class="col-md-3 mb-2" *ngFor="let day of weeklyList">
                  <app-checkbox [value]="day.value"
                    ><label class="mb-0">{{ day.label }}</label></app-checkbox
                  >
                </div>
              </div>
            </app-checkbox-group>

            <app-checkbox-group formControlName="deliverySchedules">
              <div class="row mb-3">
                <div class="col-md-9 mb-2 pr-0">
                  <label>Delivery Schedule<span class="text-danger">*</span></label>
                  <div
                    *ngIf="isInvalidForm | pureFn: isSubmitted:form.controls.deliverySchedules.errors"
                    class="text-danger invalid-display"
                  >
                    {{ 'ERRORS.REQUIRED_AT_LEAST_ONE_DAY_SCHEDULE' | translate: { value: 'Delivery' } }}
                  </div>
                </div>
                <div class="col-md-3 text-right mb-2 pl-0">
                  <a class="my-1 btn-text-link" (click)="deliverySelectAllDays()">Select All</a
                  ><span class="px-1">/</span
                  ><a class="my-1 btn-text-link" (click)="deliveryClearAllDays()">Clear All</a>
                </div>
                <div class="col-md-3 mb-2" *ngFor="let day of weeklyList">
                  <app-checkbox [value]="day.value"
                    ><label class="mb-0">{{ day.label }}</label></app-checkbox
                  >
                </div>
              </div>
            </app-checkbox-group>
            <!-- 
            Form - {{ form.value | json }}
            {{ form.controls.wmsCode.errors | json }} -->
            <div class="row mb-4">
              <div class="col-md-12">
                <label>Apply to DC<span class="text-danger">*</span></label>
                <app-dropdown-multiple-select
                  [parentForm]="form"
                  [items]="warehouseList$ | async"
                  bindLabel="locationDisplay"
                  bindValue="wmsCode"
                  controlName="wmsCode"
                  [ngClass]="isInvalidFormClass | pureFn: isSubmitted:form.controls.wmsCode.errors"
                >
                </app-dropdown-multiple-select>
                <div *ngIf="isInvalidForm | pureFn: isSubmitted:form.controls.wmsCode.errors" class="invalid-display">
                  <!-- <div *ngIf="form.controls.wmsCode?.errors.required"> -->
                  {{ 'ERRORS.REQUIRED' | translate }}
                  <!-- </div> -->
                </div>
              </div>
            </div>
            <div *ngIf="duplicateWarehouseErrorList?.length > 0">
              <div class="p-2 mb-3 duplicate error-background">
                {{ duplicateWarehouseErrorList?.length }} Errors Found.
              </div>
              <div class="table-responsive mb-3">
                <table id="supplierPriceTable" class="table table-striped table-bordered">
                  <thead>
                    <th class="text-center error-background">
                      #
                    </th>
                    <th class="text-center error-background w-30">
                      DC
                    </th>
                    <th class="text-center error-background">
                      Description
                    </th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let warehouse of duplicateWarehouseErrorList; let i = index">
                      <td class="text-center">{{ i + 1 }}</td>
                      <td class="text-left">{{ warehouse.wmsCode }}-{{ warehouse.name }}</td>
                      <td class="text-left">
                        Duplicated order schedule {{ warehouse.duplicateOrder | daysDisplay: true }} for these DC.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-standard" id="btCancel" (click)="onHideModal()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" id="btnSubmit" (click)="onSubmit()">
          {{ buttonLabel }}
        </button>
      </div>
    </div>
  </div>
</div>
