<div [formGroup]="parentForm">
  <div formGroupName="legalDocument">
    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="idCardFront">ID Card(Front)</label>
        <app-files-upload
          id="idCardFront"
          formControlName="idCardFront"
          class="form-control"
          [hasError]="submitted && legalDocument.controls.idCardFront.errors"
          [allowedTypes]="'(pdf|png|jpe?g)'"
          [withMeta]="true"
          [size]="500000"
          [controlName]="'idCardFront'"
          [allowedExt]="'(.pdf|.png|.jpe?g)'"
          descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
          fileSizeErrorTxt="Size up to 500 KB."
          fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
          [isHiddenFileName]="!hasViewDocumentPermission()"
          [fileModule]="fileModule.MERCHANT_REQUEST"
        >
        </app-files-upload>
      </div>
      <div class="col-md-6 mb-3">
        <label for="idCardBack">ID Card(Back)</label>
        <app-files-upload
          id="idCardBack"
          formControlName="idCardBack"
          class="form-control"
          [hasError]="submitted && legalDocument.controls.idCardBack.errors"
          [allowedTypes]="'(pdf|png|jpe?g)'"
          [withMeta]="true"
          [size]="500000"
          [controlName]="'idCardBack'"
          [allowedExt]="'(.pdf|.png|.jpe?g)'"
          descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
          fileSizeErrorTxt="Size up to 500 KB."
          fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
          [isHiddenFileName]="!hasViewDocumentPermission()"
          [fileModule]="fileModule.MERCHANT_REQUEST"
        >
        </app-files-upload>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="jcNumber">ID Card JC Number</label>
        <input
          id="jcNumber"
          name="jcNumber"
          type="text"
          class="form-control"
          maxlength="20"
          formControlName="jcNumber"
          placeholder="JC Number on the back of ID Card"
          appDisallowSpaces
          [ngClass]="{
            'is-invalid': submitted && legalDocument.controls.jcNumber.errors
          }"
          *ngIf="hasViewDocumentPermission()"
        />
        <input
          name="jcNumber"
          type="text"
          class="form-control text-hidden"
          [value]="setHiddenInfo()"
          disabled="disabled"
          *ngIf="!hasViewDocumentPermission()"
        />
        <div *ngIf="submitted && legalDocument.controls.jcNumber.errors" class="invalid-feedback">
          <div *ngIf="legalDocument.controls.jcNumber.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <label for="dipChip">Dip Chip</label>
        <app-files-upload
          id="dipChip"
          formControlName="dipChip"
          class="form-control"
          [hasError]="submitted && legalDocument.controls.dipChip.errors"
          [allowedTypes]="'(text\/plain)'"
          [withMeta]="true"
          [size]="100000"
          [controlName]="'dipChip'"
          [allowedExt]="'(.txt)'"
          descriptionTxt="Format file .txt (Size up to 100 KB)"
          fileSizeErrorTxt="Size up to 100 KB."
          fileTypeErrorTxt="Incorrect Format (allow only format file .txt)"
          [isHiddenFileName]="!hasViewDocumentPermission()"
          [fileModule]="fileModule.MERCHANT_REQUEST"
        >
        </app-files-upload>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="houseRegistration">House Registration</label>
        <app-files-upload
          id="houseRegistration"
          formControlName="houseRegistration"
          class="form-control"
          [hasError]="submitted && legalDocument.controls.houseRegistration.errors"
          [allowedTypes]="'(pdf|png|jpe?g)'"
          [withMeta]="true"
          [size]="500000"
          [controlName]="'houseRegistration'"
          [allowedExt]="'(.pdf|.png|.jpe?g)'"
          descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
          fileSizeErrorTxt="Size up to 500 KB."
          fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
          [isHiddenFileName]="!hasViewDocumentPermission()"
          [fileModule]="fileModule.MERCHANT_REQUEST"
        >
        </app-files-upload>
      </div>
      <div class="col-md-6 mb-3">
        <label for="contractSignOff">Contract Sign-off</label>
        <app-files-upload
          id="contractSignOff"
          formControlName="contractSignOff"
          class="form-control"
          [hasError]="submitted && legalDocument.controls.contractSignOff.errors"
          [allowedTypes]="'(pdf|png|jpe?g)'"
          [withMeta]="true"
          [size]="500000"
          [controlName]="'contractSignOff'"
          [allowedExt]="'(.pdf|.png|.jpe?g)'"
          descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
          fileSizeErrorTxt="Size up to 500 KB."
          fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
          [isHiddenFileName]="!hasViewDocumentPermission()"
          [fileModule]="fileModule.MERCHANT_REQUEST"
        >
        </app-files-upload>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="creditBureauSignOff">Credit Bureau</label>
        <app-files-upload
          id="creditBureauSignOff"
          formControlName="creditBureauSignOff"
          class="form-control"
          [hasError]="submitted && legalDocument.controls.creditBureauSignOff.errors"
          [allowedTypes]="'(pdf|png|jpe?g)'"
          [withMeta]="true"
          [size]="500000"
          [controlName]="'creditBureauSignOff'"
          [allowedExt]="'(.pdf|.png|.jpe?g)'"
          descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
          fileSizeErrorTxt="Size up to 500 KB."
          fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
          [isHiddenFileName]="!hasViewDocumentPermission()"
          [fileModule]="fileModule.MERCHANT_REQUEST"
        >
        </app-files-upload>
      </div>
      <div class="col-md-6 mb-3">
        <label for="criminalCheck">Criminal Check</label>
        <app-files-upload
          id="criminalCheck"
          formControlName="criminalCheck"
          class="form-control"
          [hasError]="submitted && legalDocument.controls.criminalCheck.errors"
          [allowedTypes]="'(pdf|png|jpe?g)'"
          [withMeta]="true"
          [size]="500000"
          [controlName]="'criminalCheck'"
          [allowedExt]="'(.pdf|.png|.jpe?g)'"
          descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
          fileSizeErrorTxt="Size up to 500 KB."
          fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
          [isHiddenFileName]="!hasViewDocumentPermission()"
          [fileModule]="fileModule.MERCHANT_REQUEST"
        >
        </app-files-upload>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 mb-3">
        <label for="accountNo">Bank Account No.<span class="text-danger">*</span></label>
        <input
          id="accountNo"
          name="accountNo"
          type="text"
          class="form-control"
          maxlength="25"
          formControlName="accountNo"
          placeholder="Account No."
          appDigitOnly
          [ngClass]="{
            'is-invalid': submitted && legalDocument.controls.accountNo.errors
          }"
          *ngIf="hasViewDocumentPermission()"
        />
        <input
          name="accountNo"
          type="text"
          class="form-control text-hidden"
          [value]="setHiddenInfo()"
          disabled="disabled"
          *ngIf="!hasViewDocumentPermission()"
        />
        <div *ngIf="submitted && legalDocument.controls.accountNo.errors" class="invalid-feedback">
          <div *ngIf="legalDocument.controls.accountNo.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="accountName">Bank Account Name<span class="text-danger">*</span></label>
        <input
          id="accountName"
          name="accountName"
          type="text"
          class="form-control"
          maxlength="100"
          formControlName="accountName"
          placeholder="Account Name"
          [ngClass]="{
            'is-invalid': submitted && legalDocument.controls.accountName.errors
          }"
          *ngIf="hasViewDocumentPermission()"
        />
        <input
          name="accountName"
          type="text"
          class="form-control text-hidden"
          [value]="setHiddenInfo()"
          disabled="disabled"
          *ngIf="!hasViewDocumentPermission()"
        />
        <div *ngIf="submitted && legalDocument.controls.accountName.errors" class="invalid-feedback">
          <div *ngIf="legalDocument.controls.accountName.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <label for="bankName">Bank Name<span class="text-danger">*</span></label>
        <ng-select
          id="bankName"
          name="bankName"
          placeholder="Please select..."
          [items]="banks"
          [searchable]="false"
          [clearable]="false"
          bindLabel="nameTh"
          bindValue="code"
          formControlName="bankName"
          [ngClass]="{
            'is-invalid': submitted && legalDocument.controls.bankName.errors
          }"
          *ngIf="hasViewDocumentPermission()"
        >
        </ng-select>
        <input
          name="bankName"
          type="text"
          class="form-control text-hidden"
          [value]="setHiddenInfo()"
          disabled="disabled"
          *ngIf="!hasViewDocumentPermission()"
        />
        <div *ngIf="submitted && legalDocument.controls.bankName.errors" class="invalid-feedback">
          <div *ngIf="legalDocument.controls.bankName.errors">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="bookBank">Book Bank<span class="text-danger">*</span></label>
        <app-files-upload
          id="bookBank"
          formControlName="bookBank"
          class="form-control"
          [hasError]="submitted && legalDocument.controls.bookBank.errors"
          [allowedTypes]="'(pdf|png|jpe?g)'"
          [withMeta]="true"
          [size]="500000"
          [controlName]="'bookBank'"
          [allowedExt]="'(.pdf|.png|.jpe?g)'"
          descriptionTxt="Format file .pdf, .jpg, .jpeg, .png (Size up to 500KB)"
          fileSizeErrorTxt="Size up to 500 KB."
          fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
          [isHiddenFileName]="!hasViewDocumentPermission()"
          [fileModule]="fileModule.MERCHANT_REQUEST"
        >
        </app-files-upload>
      </div>
    </div>
  </div>
</div>
