import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../../base/base.component';
import { TDStoreValidatorTypeEnum } from '../../../../shared/enum/merchant-validator-type.enum';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { ProductTypeEnum } from '../../../../shared/enum/product-type.enum';
import { NewRequestStatusEnum } from '../../../../shared/enum/request-status.enum';
import {
  NewRequestTypeEnum,
  RequestPageModesEnum,
  RequestProductErrorEnum
} from '../../../../shared/enum/request-step.enum';
import { ShelfPages } from '../../../../shared/enum/td-store-page.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ConfirmModalComponent } from '../../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ErrorResponse } from '../../../../shared/models';
import { BarcodeListSearchCriteria, BarcodeResponse } from '../../../../shared/models/barcode.model';
import { ConfirmModal } from '../../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../../shared/models/notification-emit.model';
import { ShelfInventoryCreateRequest } from '../../../../shared/models/shelf-inventory-request.model';
import {
  ShelfInventoryListSearchCriteria,
  ShelfInventoryViewResponse
} from '../../../../shared/models/shelf-inventory.model';
import { MerchandiseItem } from '../../../../shared/models/shelf-request.model';
import { AuthGuardService, BarcodeService } from '../../../../shared/services';
import { ShelfInventoryRequestService } from '../../../../shared/services/shelf-inventory-request.service';
import { ShelfInventoryService } from '../../../../shared/services/shelf-inventory.service';
import {
  ShelfInventoryCreateResetAction,
  ShelfInventoryCreateSubmitRequestAction,
  ShelfInventoryRequestByIdResetAction
} from '../../../../shared/store/actions/shelf-inventory-request.actions';
import {
  EditShelfInventoryAction,
  ResetShelfInventorySelected,
  ShelfInventoryByIdRequestAction,
  ShelfInventoryListRequestAction,
  SubmitShelfInventoryFirstLotRequestAction
} from '../../../../shared/store/actions/shelf-inventory.actions';
import { ShelfInventoryCreateResponseState } from '../../../../shared/store/reducers/shelf-inventory-create.reducers';
import { selectShelfInventory } from '../../../../shared/store/selectors/shelf-inventory-create.selectors';
import {
  selectShelfInventoryById,
  selectShelfInventoryListCriteria
} from '../../../../shared/store/selectors/shelf-inventory.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';
import { getFileId } from '../../../../shared/utils/get-fileId-util';
import { ShelfInventoryComponent } from '../../shelf-components/shelf-inventory/shelf-inventory.component';

@Component({
  selector: 'app-shelf-inventory-view',
  templateUrl: './shelf-inventory-view.component.html',
  styleUrls: ['./shelf-inventory-view.component.scss']
})
export class ShelfInventoryViewComponent extends BaseComponent {
  @ViewChild('inventory', { static: false }) inventory: ShelfInventoryComponent;
  @Input() data: { title: string; mode: RequestPageModesEnum; shelfNo?: string };
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  public shelfInventoryView$: Observable<ShelfInventoryViewResponse>;
  public shelfForm: FormGroup;
  public ownerUsername: string;
  public status: NewRequestStatusEnum | string;
  public submitted: boolean;
  public page: ShelfPages;
  public shelfType: ProductTypeEnum = ProductTypeEnum.INVENTORY;

  private localStore: Observable<any>;
  private readonly type: NewRequestTypeEnum = NewRequestTypeEnum.EDIT;
  private listSearchCriteria: ShelfInventoryListSearchCriteria;

  constructor(
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly store: Store<AppStates>,
    protected readonly translate: TranslateService,
    private readonly barcodeService: BarcodeService,
    protected shelfInventoryRequestService: ShelfInventoryRequestService,
    protected shelfInventoryService: ShelfInventoryService,
    private readonly authGuardService: AuthGuardService,
    protected readonly logger: NGXLogger
  ) {
    super(store, modalService, true);
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetShelfInventorySelected());
    this.store.dispatch(new ShelfInventoryCreateResetAction());
    this.store.dispatch(
      new ShelfInventoryListRequestAction({
        ...this.listSearchCriteria
      })
    );
  }

  ngOnInit() {
    this.page = ShelfPages.SHELF_INVENTORY_EDIT;
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.store.dispatch(new ShelfInventoryByIdRequestAction({ shelfNo: this.data.shelfNo }));

    this.shelfInventoryView$ = this.localStore.pipe(
      select(selectShelfInventoryById),
      filter(data => Boolean(data))
    );

    this.shelfInventoryView$.pipe(take(1)).subscribe(data => {
      this.status = data.status;

      if (this.data.mode === RequestPageModesEnum.REQUEST_EDIT) {
        this.onTriggerEdit();
      } else {
        this.store.dispatch(new EditShelfInventoryAction({ type: NewRequestTypeEnum.EDIT }));
      }
    });

    this.localStore
      .pipe(select(selectShelfInventoryListCriteria))
      .subscribe(criteriaObject => (this.listSearchCriteria = criteriaObject));

    this.initControl();
  }

  get pageModes() {
    return RequestPageModesEnum;
  }

  get requestStatus() {
    return NewRequestStatusEnum;
  }

  getColorStatus(status: string): string {
    return status && status.toLowerCase();
  }

  hasEditPermission(): boolean {
    return this.authGuardService.checkPermission(['sh_inv_m']);
  }

  hasEditFirstLotPermission(): boolean {
    return this.authGuardService.checkPermission(['sh_inv_flq_m']) && this.status !== NewRequestStatusEnum.INACTIVE;
  }

  hasSubmitPermission(): boolean {
    return this.authGuardService.checkPermission(['sh_inv_m', 'sh_inv_flq_m']);
  }

  hasAtLeastOnePermission() {
    return this.hasEditPermission() || this.hasSubmitPermission() || this.hasEditFirstLotPermission();
  }

  initControl() {
    this.shelfForm = this.fb.group({});
  }

  onCancel() {
    if (this.shelfForm.touched) {
      this.store.dispatch(new ShelfInventoryRequestByIdResetAction());
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.status === NewRequestStatusEnum.DRAFT) {
      const inventoryBarcode = this.shelfForm.getRawValue().inventoryItem.map(data => data.barcode);

      this.itemValidator(inventoryBarcode);
    } else if (this.status === NewRequestStatusEnum.ACTIVE) {
      const errorMessage = this.getFormGroupErrorMessage();

      if (errorMessage) {
        this.showModalError(errorMessage);
      } else if (this.inventory.form.invalid) {
        this.validateError(this.inventory);
      } else if (this.shelfForm.valid) {
        this.handleConfirm();
      }
    }
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.submitData();
        }
      });
  }

  submitData() {
    const reqData = this.prepareRequestData();

    if (this.status === NewRequestStatusEnum.ACTIVE) {
      this.store.dispatch(new SubmitShelfInventoryFirstLotRequestAction(reqData));
    } else {
      this.store.dispatch(new ShelfInventoryCreateSubmitRequestAction(reqData));
      this.listSearchCriteria.page = 0;
    }
  }

  itemValidator(inventoryBarcode: string[]) {
    const inventoryList$: Observable<BarcodeResponse[]> = this.barcodeValidator(
      inventoryBarcode,
      ProductTypeEnum.INVENTORY
    );

    inventoryList$
      .pipe(
        tap(inventoryList => {
          this.updateFormGroup(inventoryList, ProductTypeEnum.INVENTORY);
        }),
        filter(() => this.submitted)
      )
      .subscribe(() => {
        const errorMessage = this.getFormGroupErrorMessage();

        if (errorMessage) {
          this.showModalError(errorMessage);
        } else if (this.inventory.form.invalid) {
          this.validateError(this.inventory);
        } else if (this.shelfForm.valid) {
          this.handleConfirm();
        }
      });
  }

  validateError(form) {
    const invalidIndex = form.formControls.findIndex(item => item.invalid);
    form.paging.navigateToErrorIndex(invalidIndex);

    const error = form.formControls.find(item => !!item.errors);
    if (error) {
      this.showModalError('Please delete invalid data before submit.');
    }
  }

  barcodeValidator(barcodes: string[], productType: ProductTypeEnum) {
    const barcodeList = this.barcodeService.searchBarcodeByCriteria(
      barcodes,
      new BarcodeListSearchCriteria({
        allowRestrictItem: true,
        allowProductType: productType,
        size: barcodes.length
      })
    );

    return barcodes.length ? barcodeList : of([]);
  }

  updateFormGroup(barcode: BarcodeResponse[], productType: ProductTypeEnum.INVENTORY | ProductTypeEnum.FIX_ASSET) {
    if (!barcode.length) {
      return;
    }

    let formGroup;
    let mappingBarcodeResponse;

    if (productType === ProductTypeEnum.INVENTORY) {
      formGroup = this.shelfForm.get('inventoryItem');
      mappingBarcodeResponse = MerchandiseItem.mappingBarcodeResponseToMerchandiseItem;
    }

    barcode.forEach((data, i) => {
      formGroup.controls[i].patchValue({
        ...mappingBarcodeResponse(data),
        ...formGroup.getRawValue()[i]
      });

      if (formGroup.controls[i].get('articleNo') && formGroup.controls[i].get('articleNo').value !== data.articleNo) {
        data.errorMessage = RequestProductErrorEnum.INVALID_BARCODE;
      }

      if (data.errorMessage) {
        this.validateForm(formGroup.controls[i], data.errorMessage);
      }
    });
  }

  validateForm(formItem: FormGroup, errorMessage: RequestProductErrorEnum) {
    let childItem;

    const newValidator = this.setValidator(errorMessage);

    switch (errorMessage) {
      case RequestProductErrorEnum.INVALID_BARCODE:
      case RequestProductErrorEnum.INACTIVE_BARCODE:
      case RequestProductErrorEnum.DUPLICATED_BARCODE_FIELD:
        childItem = formItem.get('barcode');
        break;
      case RequestProductErrorEnum.RESTRICT_ITEM:
      case RequestProductErrorEnum.STATUS_IS_DELISTED:
      case RequestProductErrorEnum.NOT_ALLOW_FIX_ASSET:
      case RequestProductErrorEnum.NOT_ALLOW_INVENTORY:
      case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
        childItem = formItem.get('productName');
        break;
      default:
        this.logger.error(`ErrorMessage: ${errorMessage} did not supported in barcode and productName.`);
        return;
    }

    childItem.setValidators(newValidator);
    childItem.updateValueAndValidity();

    formItem.setValidators(newValidator);
    formItem.updateValueAndValidity();
  }

  setValidator(errorMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      switch (errorMessage) {
        case RequestProductErrorEnum.INVALID_BARCODE:
          return { invalidBarcode: true };
        case RequestProductErrorEnum.INACTIVE_BARCODE:
          return { isInactive: true };
        case RequestProductErrorEnum.DUPLICATED_BARCODE_FIELD:
          return { duplicated: true };
        case RequestProductErrorEnum.RESTRICT_ITEM:
          return { isRestrictItem: true };
        case RequestProductErrorEnum.STATUS_IS_DELISTED:
          return { isStatusDelisted: true };
        case RequestProductErrorEnum.NOT_ALLOW_FIX_ASSET:
          return { isFixAssetItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_INVENTORY:
          return { isInventoryItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
          return { isStoreUseItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_ZERO:
          return control.value !== null && control.value === 0 ? { isZero: true } : null;
        default:
          return null;
      }
    };
  }

  getFormGroupErrorMessage(): string | undefined {
    const formGroup = this.shelfForm.get('inventoryItem') as FormArray;
    if (!formGroup.controls.length) {
      this.shelfForm.setErrors({ requiredItem: true });
      return 'Please select at least one item before submit.';
    }
  }

  showModalError(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message
      }
    });
  }

  prepareRequestData() {
    const formData = this.shelfForm.getRawValue();
    const template = new ShelfInventoryCreateRequest();
    template.status = NewRequestStatusEnum.DRAFT;

    this.shelfInventoryView$
      .pipe(
        untilComponentDestroyed(this),
        filter(value => Boolean(value))
      )
      .subscribe((value: ShelfInventoryViewResponse) => {
        template.shelfNo = value.shelfNo;
        template.version = value.version;
        template.type = value.type;
        template.shelfCode = value.shelfCode;
      });

    template.shelfInfo = {
      ...formData.shelfInfo,
      shelfName: formData.shelfInfo.shelfName.trim(),
      shelfImage: getFileId(formData.shelfInfo.shelfImage),
      shelfType: formData.shelfInfo.shelfType
    };

    template.items = formData.inventoryItem;

    return template;
  }

  checkAllowToEdit(callback) {
    this.shelfInventoryRequestService
      .getShelfValidate(TDStoreValidatorTypeEnum.REQUESTED, this.data.shelfNo)
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        () => {
          callback();
        },
        error => {
          const initialState = {
            title: 'Alert',
            message: this.translate.instant('ERROR_CODE.' + error.error.code)
          };
          this.modalService.show(AlertModalComponent, {
            initialState
          });
        }
      );
  }

  onTriggerEdit() {
    this.checkAllowToEdit(() => {
      const inventoryBarcode = this.shelfForm.getRawValue().inventoryItem.map(data => data.barcode);

      this.data.mode = RequestPageModesEnum.REQUEST_EDIT;
      this.data.title = 'Edit Shelf Inventory';
      this.status = NewRequestStatusEnum.DRAFT;
      this.store.dispatch(
        new EditShelfInventoryAction({ type: NewRequestTypeEnum.EDIT, status: NewRequestStatusEnum.DRAFT })
      );
      this.initialAlertMessage();
      this.itemValidator(inventoryBarcode);
    });
  }

  onTriggerEditFirstLot() {
    this.checkAllowToEdit(() => {
      this.data.mode = RequestPageModesEnum.REQUEST_EDIT;
      this.data.title = 'Edit Shelf Beauty & First Lot';
      this.initialAlertMessage();
    });
  }

  initialAlertMessage() {
    this.localStore
      .pipe(
        select(selectShelfInventory),
        filter(value => Boolean(value.result))
      )
      .subscribe((value: ShelfInventoryCreateResponseState) => {
        const result = value.result;

        if (result.response) {
          this.alertSuccessModal(this.getSuccessMessage());
        } else {
          this.alertErrorModal(result.errorResponse);
        }
      });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.modalService.hide(1);
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  getSuccessMessage() {
    return this.status === NewRequestStatusEnum.DRAFT
      ? 'The request has been sent to approver.'
      : 'Min First Lot and Min Beauty Quantity are updated.';
  }

  onActivateShelf(toActivate) {
    const messages = this.getActivatedMessage(toActivate);
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: messages.confirmMessage,
        okText: 'Submit',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.shelfInventoryService
            .activateShelfInventory({ shelfNo: this.data.shelfNo }, toActivate)
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal(messages.successMessage);
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  getActivatedMessage(toActivate) {
    return {
      confirmMessage: !toActivate ? 'Are you sure you want to deactivate?' : 'Are you sure you want to activate?',
      successMessage: !toActivate ? 'The shelf has been deactivated.' : 'The shelf has been activated.'
    };
  }

  doAfterVersionAlertModal() {}

  doAfterSuccessModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }
}
