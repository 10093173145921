<div
  id="storeConfidentialModal"
  class="modal fade barcodeModal admin-form"
  bsModal
  #tdAssortmentConfidentialModal="bs-modal"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <h4 class="modal-title pull-left">TD Assortment Report (Confidential)</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeExportModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body admin-form" [formGroup]="exportForm" (ngSubmit)="onExport()">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="mb-2">
                  Segment
                </div>
                <ng-select
                  placeholder="Please select..."
                  [items]="listOfValue[this.assortmentAllType.SEGMENT]"
                  [searchable]="false"
                  [clearable]="false"
                  [multiple]="false"
                  bindLabel="nameEn"
                  bindValue="code"
                  formControlName="segment"
                  (change)="onChangeSelectDropdown($event, assortmentAllType.SEGMENT); clearErrorText()"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="mb-2">
                  Family
                </div>
                <ng-select
                  placeholder="Please select..."
                  [items]="listOfValue[this.assortmentAllType.FAMILY]"
                  [searchable]="false"
                  [clearable]="false"
                  [multiple]="false"
                  bindLabel="nameEn"
                  bindValue="code"
                  formControlName="family"
                  (change)="onChangeSelectDropdown($event, assortmentAllType.FAMILY); clearErrorText()"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="mb-2">
                  Class
                </div>
                <ng-select
                  placeholder="Please select..."
                  [items]="listOfValue[this.assortmentAllType.CLASS]"
                  [searchable]="false"
                  [clearable]="false"
                  [multiple]="false"
                  bindLabel="nameEn"
                  bindValue="code"
                  formControlName="classCode"
                  (change)="onChangeSelectDropdown($event, assortmentAllType.CLASS); clearErrorText()"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="mb-2">
                  Sub Class
                </div>
                <ng-select
                  placeholder="Please select..."
                  [items]="listOfValue[this.assortmentAllType.SUBCLASS]"
                  [searchable]="false"
                  [clearable]="false"
                  [multiple]="false"
                  bindLabel="nameEn"
                  bindValue="code"
                  formControlName="subClass"
                  (change)="onChangeSelectDropdown($event, assortmentAllType.SUBCLASS); clearErrorText()"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="mb-2">
                  Product Type
                </div>
                <ng-select
                  placeholder="Please select..."
                  [items]="productTypeSelect"
                  [searchable]="false"
                  [clearable]="false"
                  [multiple]="true"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="productType"
                  (change)="clearErrorText()"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="mb-2">
                  Ordering Method
                </div>
                <ng-select
                  placeholder="Please select..."
                  [items]="orderingMethodSelect"
                  [searchable]="false"
                  [clearable]="false"
                  [multiple]="true"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="orderingMethods"
                  (change)="clearErrorText()"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="mb-2">
                  Status
                </div>
                <ng-select
                  placeholder="Please select..."
                  [items]="exportProductAssortmentStatus"
                  [searchable]="false"
                  [clearable]="false"
                  [multiple]="true"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="productStatuses"
                  (change)="clearErrorText()"
                >
                </ng-select>
              </div>
            </div>
          </div>
          <div *ngIf="errorExport" class="alert-danger form-group mb-0 " role="alert">
            {{ errorExport }}
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="onExport()" id="btnExport">
            Export
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
