<div class="select-item-modal full-popup-head">
  <a aria-label="Back" class="clickable" (click)="onBack()">
    <div class="full-popup-close float-left">
      <span aria-label="Close" class="close">
        <em class="icon-prev ml-2"></em>
      </span>
    </div>
    <div class="full-popup-title">Back to Order Request</div>
  </a>
  <div class="full-popup-title ml-auto">
    <em class="icon-cart cursor-pointer" (click)="onBack()"></em>
    <span class="badge badge-pill badge-success">{{ totalOrder$ | async }}</span>
  </div>
</div>

<div class="admin-form modal-table-wrap">
  <div class="row section-header-item">
    <span>{{ data.title }}</span>
  </div>
  <div class="d-flex flex-row">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="ml-auto">
      <div class="d-flex flex-row">
        <app-import-export-button [buttons]="buttons" (export)="onExportItem()"></app-import-export-button>

        <div class="form-group ml-auto filter-search-box">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="form-control searchCriteria"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="align-middle" *ngIf="true">
      <div class="d-flex justify-content-center no-data">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="table-responsive table-scroll mt-3 mb-3">
      <table id="data-table" class="table table-striped table-bordered table-hover w-100">
        <thead>
          <tr>
            <th class="text-center w-5">No.</th>
            <th class="text-center w-30">Product Name</th>
            <th class="text-center w-10">Article</th>
            <th class="text-center w-10">Barcode</th>
            <th class="text-center w-10">Product Type</th>
            <th class="text-center w-5">Unit</th>
            <th class="text-center w-10">Unit Price</th>
            <th class="text-center w-10">Quantity</th>
            <th class="text-center w-10">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of resultList$ | async; index as i">
            <td class="text-center">
              {{ (currentPage - 1) * pageSize + i + 1 }}
            </td>
            <td>{{ result.productName | dashDisplay: '(None)' }}</td>
            <td>{{ result.articleNo | dashDisplay }}</td>
            <td>{{ result.barcode | dashDisplay }}</td>
            <td>{{ 'PRODUCT_ASSORTMENT.PRODUCT_TYPE.' + result.productType | translate }}</td>
            <td class="text-center">
              <div>{{ result.unit | dashDisplay }}</div>
            </td>
            <td class="text-right">{{ result.wholesalePrice.amount | number: '1.2-2' }}</td>
            <td class="text-center p-10px">
              <app-numeric-textbox
                #quantity
                class="form-control td-numeric-textbox-center"
                placeholder="Quantity"
                [format]="'0'"
                maxlength="5"
                value="1"
                [minValue]="1"
                [maxValue]="99999"
                [negativeSign]="false"
                [decimals]="0"
                [allowPaste]="false"
                [ngClass]="{
                  'is-invalid': result.isAddItem && (quantity.value === 0 || quantity.value === null)
                }"
              ></app-numeric-textbox>

              <div class="invalid-feedback text-left">
                <div *ngIf="result.isAddItem && quantity.value === null">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <div *ngIf="result.isAddItem && quantity.value === 0">
                  <span translate="ERRORS.NOT_ALLOW" [translateParams]="{ value: '0' }"></span>
                </div>
              </div>
            </td>

            <td class="text-center">
              <a class="mr-1" (click)="addItem(result, quantity.value)" href="javascript:void(0)">
                <em class="icon-cart md-size" title="Add" appTippy [tippyOptions]="{ arrow: true }"> </em>
              </a>

              <div class="text-success" *ngIf="result.qty > 0">
                <span class="text-success">Added = {{ result.qty | numberFormatDisplay: 0 }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <!--footer-->
  <div id="footer" class="fixed-row-bottom form-row"></div>
  <!--End footer-->
</div>
