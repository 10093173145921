import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StockTransferService } from '../../services/stock-trasfer-service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import { StockTransferActionType, StockTransferListResponseAction, StockTransferViewResponseAction } from '../actions/stock-transfer.actions';
export class StockTransferEffects {
    constructor(actions$, stockTransferService, logger) {
        this.actions$ = actions$;
        this.stockTransferService = stockTransferService;
        this.logger = logger;
        this.searchStockTransferList$ = this.actions$.pipe(ofType(StockTransferActionType.STOCK_TRANSFER_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StockTransferActionType.STOCK_TRANSFER_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action;
        }), switchMap(action => {
            return this.stockTransferService.searchByCriteria(action.payload).pipe(map(response => {
                return new StockTransferListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getStockTransferByDocNo$ = this.actions$.pipe(ofType(StockTransferActionType.STOCK_TRANSFER_VIEW_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StockTransferActionType.STOCK_TRANSFER_VIEW_REQUEST}: ` + this.stringify(action.payload));
            return action;
        }), switchMap(action => {
            return this.stockTransferService.getStockTransferByDocNo(action.payload).pipe(map(response => {
                return new StockTransferViewResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.closeStockTransferOrder$ = this.actions$.pipe(ofType(StockTransferActionType.STOCK_TRANSFER_CLOSE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StockTransferActionType.STOCK_TRANSFER_CLOSE_REQUEST}: ` + this.stringify(action.payload));
            return action;
        }), switchMap(action => this.stockTransferService.closeStockTransfer(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'This transfer order will be closed after WMS process has been done.'
        })), catchError(err => {
            return this.errorHandling(err);
        }))));
    }
    errorHandling(err) {
        return err.error && err.error.code === '00005'
            ? of(new LayoutActionVersionError(true))
            : of(new LayoutActionLoadError(err));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StockTransferEffects.prototype, "searchStockTransferList$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StockTransferEffects.prototype, "getStockTransferByDocNo$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StockTransferEffects.prototype, "closeStockTransferOrder$", void 0);
