export enum MasterDataEnum {
  MASTER_DATA = 'masterData',
  COUNTRY = 'countries',
  STATE = 'states',
  STATE_IN_REGION = 'statesInRegion',
  REGION = 'regions',
  BANK = 'banks',
  SEGMENT = 'segments',
  CLASS = 'classes',
  CATALOG_LV1 = 'catalogsLv1',
  UOM = 'uoms',
  UNIT = 'units',
  DEPARTMENTS = 'departments',
  AREA = 'areas',
  BUILDING = 'building',
  BUILDING_SIZE = 'buildingSizes',
  COMPETITOR = 'competitors',
  CURRENCY = 'currencies',
  DAY = 'days',
  MERCHANT = 'merchants',
  ORDER_SCHEDULE_TIME = 'orderScheduleTime',
  PARKING_CONDITION = 'parkingConditions',
  POI_DISTANCE = 'poiDistances',
  POI = 'poi',
  PROPERTY_OWNERSHIP = 'propertyOwnership',
  RESTRICTED_DELIVERY_TIME = 'restrictedDeliveryTime',
  CUSTOMERS = 'customers',
  TIMEZONE = 'timezones',
  WAREHOUSE = 'warehouses',
  OFFICE = 'offices',
  BILLING = 'billings',
  DISCOUNTCODE = 'discountcodes',
  STOCK_ADJUST_REASONS_BY_TYPE = 'stockAdjustReasonsByType',
  ADJUST_TYPE = 'adjustType',
  STOCK_MOVEMENT_TYPE = 'stockMovementType',
  TOTE = 'totes',
  PAYMENT_TERMS = 'paymentTerms'
}
