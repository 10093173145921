export class PurchaseConditionPermission {
  public showButton = true;
  public hasManagePurchaseCondition = false;
  public hasApproverPurchaseCondition = false;
  public hasViewPurchaseConditionRequest = false;
  public hasViewPurchaseCondition = false;

  checkPermission(authGuardService) {
    this.hasManagePurchaseCondition = authGuardService.checkPermission(['pc_req_m']);
    this.hasApproverPurchaseCondition = authGuardService.checkPermission(['pc_req_app']);
    this.hasViewPurchaseConditionRequest = authGuardService.checkPermission(['pc_req_v']);
    this.hasViewPurchaseCondition = authGuardService.checkPermission(['pc_v']);
  }
}
