import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuditLog } from '../../models/store-assortment.model';

@Component({
  selector: 'app-collapsible-history',
  templateUrl: './collapsible-history.component.html',
  styleUrls: ['./collapsible-history.component.scss'],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0, opacity: 0 })),
      state('down', style({ height: '*', opacity: 1 })),
      transition('up <=> down', animate(200))
    ])
  ]
})
export class CollapsibleHistoryComponent implements OnInit {
  public title: string;
  public historyHeader: string;
  public historyType: string;
  public action: string;
  public dateTimeDisplay = environment.dateTimeDisplay;
  public auditLogs$: Observable<Array<AuditLog>>;
  public activityList: { [key: string]: string };
  public listState: { [key: string]: any } = {};

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.auditLogs$.pipe(filter(data => Boolean(data))).subscribe(data => {
      this.getListState(data);
    });
  }

  ngOnDestroy() {}

  decline(): void {
    this.bsModalRef.hide();
  }

  getListState(data) {
    const collaspes = data.filter(v => Boolean(v.activityList));
    for (const item of collaspes) {
      for (const activity in item.activityList) {
        if (activity) {
          this.listState[item.refNo] = {
            [activity]: false
          };
        }
      }
    }
  }

  toggle(refNo: string, currentList: string) {
    this.listState[refNo][currentList] = !this.listState[refNo][currentList];
  }

  getState(refNo: string, currentList: string) {
    if (this.listState[refNo][currentList]) {
      return 'down';
    } else {
      return 'up';
    }
  }

  showNumberOfItems(noOfItem: number) {
    if (noOfItem) {
      return `(${noOfItem})`;
    }

    return '';
  }

  showRequestedBy(activity: string, requestedBy: string) {
    if (['ADD_SHELF', 'DELETE_SHELF'].includes(activity) && requestedBy) {
      return `by <br> ${requestedBy}`;
    }

    return '';
  }
}
