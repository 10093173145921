import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ShelfSelectedItems } from '../../models';
import { ShelfFixAssetListSearchCriteria, ShelfSelectFixAssetList } from '../../models/shelf-fix-asset.model';
import {
  ShelfSelectFixAssetActions,
  ShelfSelectFixAssetActionTypes
} from '../actions/shelf-select-fix-asset.actions';
import { BaseState } from '../state/base.state';

export interface ShelfSelectFixAssetState extends EntityState<ShelfSelectFixAssetList>, BaseState {
  criteriaObject: ShelfFixAssetListSearchCriteria;
  totalElements: number;
  totalPages: number;
  selected: ShelfSelectedItems[];
}

export const adapter: EntityAdapter<ShelfSelectFixAssetList> = createEntityAdapter<ShelfSelectFixAssetList>();

export const initialShelfSelectResponseState: ShelfSelectFixAssetState = adapter.getInitialState({
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  selected: []
});

export function ShelfSelectFixAssetReducers(
  state = initialShelfSelectResponseState,
  action: ShelfSelectFixAssetActions
): ShelfSelectFixAssetState {
  switch (action.type) {
    case ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case ShelfSelectFixAssetActionTypes.SHELF_SELECT_FIX_ASSET_LIST_RESET:
      return adapter.removeAll({
        ...state
      });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
