export enum StockTransferRequestStatusEnum {
  DRAFT = 'DRAFT',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED'
}

export enum StockTransferPageModes {
  CREATE = 'CREATE',
  VIEW = 'VIEW',
  EDIT = 'EDIT'
}

export enum StockTransferItemImportStatusEnum {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}
