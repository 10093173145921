<div class="row mt-2 mb-2">
  <div class="col-6">
    <app-upload-document-button
      #importItem
      [allowedTypes]="'sheet'"
      [withMeta]="false"
      [multiple]="false"
      [size]="50000000"
      [allowedExt]="'xlsx'"
      [uploadUrl]="importStoreAssortmentUrl"
      [uploadHeaders]="importHeaders"
      (itemDetail)="onLoadItemDetail($event)"
      [isExistingUpload]="formControls.length > 0"
      [text]="'Import Item'"
      class="d-none"
    >
    </app-upload-document-button>
    <app-import-export-button [buttons]="buttons" (import)="onClickImport()"></app-import-export-button>
  </div>

  <div class="col-6 text-right">
    <button type="button" class="btn btn-standard" (click)="onDownloadTemplate()">
      <em class="icon-download"></em>
      Download Template
    </button>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <table
      id="itemListTable"
      class="mt-2 table table-striped table-bordered w-100"
      [formGroup]="formItemList"
      *ngIf="formControls.length > 0"
    >
      <thead>
        <tr>
          <th class="text-center w-5">No.</th>
          <th class="text-center w-5">Barcode</th>
          <th class="text-center w-15">Product Name</th>
          <th class="text-center w-5">Unit</th>
          <th class="text-center w-5">Unit Factor</th>
          <th class="text-center w-5">PLG</th>
          <th class="text-center w-5">Tier</th>
          <th class="text-center w-5">Grading</th>
          <th class="text-center w-10">Edit Type<span class="text-danger">*</span></th>
          <th class="text-center w-10">Facing<span class="text-danger">*</span></th>
          <th class="text-center w-10">Stacking<span class="text-danger">*</span></th>
          <th class="text-center w-10">Depth<span class="text-danger">*</span></th>
          <th class="text-center w-10">Min Beauty</th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody formArrayName="productItems">
        <tr
          *ngFor="let item of paging.currentPageData; index as i"
          [formGroupName]="i + (currentPage - 1) * pageSize"
          [ngClass]="{
            'error-background':
              item.errors?.invalidBarcode ||
              item.errors?.isInactive ||
              item.errors?.isStatusDelisted ||
              item.errors?.isFixAssetItem ||
              item.errors?.isStoreUseItem ||
              item.errors?.duplicated
          }"
        >
          <td class="text-center align-top">{{ paging.getItemNo(i) }}</td>
          <td class="text-left align-top">
            {{ item.get('barcode').value }}

            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.invalidBarcode">
              {{ 'ERRORS.INVALID_BARCODE' | translate }}
            </span>
            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isInactive">
              {{ 'ERRORS.INACTIVE_BARCODE' | translate }}
            </span>
            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.duplicated">
              {{ 'ERRORS.DUPLICATED_BARCODE_FIELD' | translate }}
            </span>
          </td>
          <td class="text-left align-top">
            {{ item.get('productName').value }}
            <span class="d-block">{{ item.get('articleNo').value }}</span>

            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isStatusDelisted">
              {{ 'ERRORS.STATUS_IS_DELISTED' | translate }}
            </span>
            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isFixAssetItem">
              {{ 'ERRORS.NOT_ALLOW_FIX_ASSET' | translate }}
            </span>
            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isStoreUseItem">
              {{ 'ERRORS.NOT_ALLOW_STORE_USE' | translate }}
            </span>
            <span
              class="d-block invalid-feedback text-warning"
              *ngIf="isDeleteCoreProductItem(item.get('productTier').value, item.get('editType').value)"
            >
              {{ 'ERRORS.CORE_PRODUCT_ITEM' | translate }}
            </span>
          </td>
          <td class="text-center align-top">
            {{ item.get('unit').value }}
          </td>
          <td class="text-center align-top">
            {{ item.get('unitFactor').value }}
          </td>
          <td class="text-center align-top">
            {{ item.get('productLocation').value }}
          </td>
          <td class="text-center align-top">
            {{ item.get('productTier').value }}
          </td>
          <td class="text-center align-top">
            {{ item.get('productGrading').value }}
          </td>
          <td class="align-top">
            <ng-select
              [items]="editTypeList"
              [searchable]="false"
              [clearable]="false"
              class="ng-select-width"
              bindLabel="label"
              bindValue="value"
              formControlName="editType"
              (change)="onChangeEditType($event, item)"
              [ngClass]="{
                'is-invalid': submitted && item.get('editType').errors?.required,
                'd-none': item.errors && !(item.errors?.required && item.errors?.isZero)
              }"
            >
            </ng-select>

            <div class="invalid-display text-danger" *ngIf="submitted && item.get('editType').errors?.required">
              <span> {{ 'ERRORS.REQUIRED' | translate }} </span>
            </div>
          </td>
          <td class="text-center align-top">
            <app-numeric-textbox
              class="td-numeric-textbox-center"
              [ngClass]="{
                'is-invalid': submitted && validatorFormControls('facingQty', i),
                'd-none':
                  (item.errors && !(item.errors?.required && item.errors?.isZero)) ||
                  isDeleteItem(item.get('editType').value)
              }"
              formControlName="facingQty"
              [format]="'0'"
              [decimals]="0"
              [minValue]="1"
              [maxValue]="99"
              [allowPaste]="false"
              [negativeSign]="false"
              id="facingQty"
              maxlength="2"
              (blur)="calculateGradingQty(item)"
            >
            </app-numeric-textbox>

            <div
              class="invalid-display text-danger"
              *ngIf="submitted && !item.errors && validatorFormControls('facingQty', i)"
            >
              <div *ngIf="validatorFormControls('facingQty', i)?.required">
                <span> {{ 'ERRORS.REQUIRED' | translate }} </span>
              </div>

              <div *ngIf="validatorFormControls('facingQty', i).isZero">
                <span>{{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}</span>
              </div>
            </div>
          </td>
          <td class="text-center align-top">
            <app-numeric-textbox
              class="td-numeric-textbox-center"
              [ngClass]="{
                'is-invalid': submitted && validatorFormControls('stackingQty', i),
                'd-none':
                  (item.errors && !(item.errors?.required && item.errors?.isZero)) ||
                  isDeleteItem(item.get('editType').value)
              }"
              formControlName="stackingQty"
              [format]="'0'"
              [decimals]="0"
              [minValue]="1"
              [maxValue]="99"
              [allowPaste]="false"
              [negativeSign]="false"
              id="stackingQty"
              maxlength="2"
              (blur)="calculateGradingQty(item)"
            >
            </app-numeric-textbox>

            <div
              class="invalid-display text-danger"
              *ngIf="submitted && !item.errors && validatorFormControls('stackingQty', i)"
            >
              <div *ngIf="validatorFormControls('stackingQty', i)?.required">
                <span> {{ 'ERRORS.REQUIRED' | translate }} </span>
              </div>

              <div *ngIf="validatorFormControls('stackingQty', i).isZero">
                <span>{{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}</span>
              </div>
            </div>
          </td>
          <td class="text-center align-top">
            <app-numeric-textbox
              class="td-numeric-textbox-center"
              [ngClass]="{
                'is-invalid': submitted && validatorFormControls('depthQty', i),
                'd-none':
                  (item.errors && !(item.errors?.required && item.errors?.isZero)) ||
                  isDeleteItem(item.get('editType').value)
              }"
              formControlName="depthQty"
              [format]="'0'"
              [decimals]="0"
              [minValue]="1"
              [maxValue]="99"
              [allowPaste]="false"
              [negativeSign]="false"
              id="depthQty"
              maxlength="2"
              (blur)="calculateGradingQty(item)"
            >
            </app-numeric-textbox>

            <div
              class="invalid-display text-danger"
              *ngIf="submitted && !item.errors && validatorFormControls('depthQty', i)"
            >
              <div *ngIf="validatorFormControls('depthQty', i)?.required">
                <span> {{ 'ERRORS.REQUIRED' | translate }} </span>
              </div>

              <div *ngIf="validatorFormControls('depthQty', i).isZero">
                <span>{{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}</span>
              </div>
            </div>
          </td>
          <td class="text-center align-top">
            <app-numeric-textbox
              class="td-numeric-textbox-center"
              [ngClass]="{
                'is-invalid': submitted && validatorFormControls('minBeautyQty', i),
                'd-none':
                  (item.errors && !(item.errors?.required && item.errors?.isZero)) ||
                  isDeleteItem(item.get('editType').value)
              }"
              formControlName="minBeautyQty"
              [format]="'0,0.[00]'"
              [minValue]="1"
              [maxValue]="999999"
              id="minBeautyQty"
              maxlength="6"
            >
            </app-numeric-textbox>

            <div
              class="invalid-display text-danger"
              *ngIf="submitted && !item.errors?.invalidBarcode && validatorFormControls('minBeautyQty', i)"
            >
              <div *ngIf="validatorFormControls('minBeautyQty', i)?.required">
                <span> {{ 'ERRORS.REQUIRED' | translate }} </span>
              </div>

              <div *ngIf="validatorFormControls('minBeautyQty', i).isZero">
                <span>{{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}</span>
              </div>
            </div>
          </td>
          <td class="text-center align-middle">
            <a
              class="is-link text-muted"
              (click)="deleteItem(i)"
              title="Delete"
              appTippy
              [tippyOptions]="{ arrow: true }"
            >
              <em class="icon-delete"></em>
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <app-ui-pagination
      #paging
      [(currentPage)]="currentPage"
      [(pageSize)]="pageSize"
      [items]="form.controls"
      [valueChangeDetector]="form.valueChanges | async"
    >
    </app-ui-pagination>

    <div class="clearfix"></div>
  </div>
</div>

<div>
  <a class="btn-text-link d-inline-block mr-1 " (click)="addNewItem()">+Select Item</a> or
  <a class="btn-text-link d-inline-block ml-1 " (click)="addNewBarcode()">+Add Barcode</a>
</div>

<app-search-product-modal #searchProductModal [productType]="productType" (addItem)="onAddItem($event)">
</app-search-product-modal>

<app-search-barcode-modal #searchBarcodeModal [allowProductType]="productType" (addItem)="onAddBarcodeItem($event)">
</app-search-barcode-modal>
