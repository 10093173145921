<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-stock"></em>
    {{ data.title }}
  </div>
  <button type="button" class="btn btn-header-button" (click)="onCloseTo()" *ngIf="hasClosePermission">
    <em class="icon-close-po"></em>
    Close TO
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>
<div class="admin-form">
  <div class="info-header-container">
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Transfer Order No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (stockTransfer$ | async)?.docNo }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Transfer Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (stockTransfer$ | async)?.requestNo }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Status:</span>
      </div>
      <div class="info-header-value">
        <span [ngClass]="getColorStatus((stockTransfer$ | async)?.status)" class="stock-transfer-view-status">
          {{ 'STOCK_TRANSFER.STATUS.' + (stockTransfer$ | async)?.status | translate }}
        </span>
      </div>
    </div>
  </div>

  <div class="information-container">
    <div class="row merchant-info">
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Ship From</span>
          <span class="info-header-value">{{ (stockTransfer$ | async)?.shipFromCodeName }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Ship To</span>
          <span class="info-header-value">{{ (stockTransfer$ | async)?.shipToCodeName }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Order By</span>
          <span class="info-header-value">{{ (stockTransfer$ | async)?.orderByName }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Order Date</span>
          <span class="info-header-value">{{
            (stockTransfer$ | async)?.createdDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay
          }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Received Date</span>
          <span class="info-header-value">
            {{
              (stockTransfer$ | async)?.receivedDate
                ? ((stockTransfer$ | async)?.receivedDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay)
                : '(None)'
            }}
          </span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Return Date</span>
          <span class="info-header-value">
            {{
              (stockTransfer$ | async)?.returnDate
                ? ((stockTransfer$ | async)?.returnDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay)
                : '(None)'
            }}</span
          >
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md">
      <div class="table-responsive">
        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-standard" (click)="openDeliveryDetails()">
            <em class="fa nicon icon-truck success"></em>
            Delivery Details
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="stockTransfer$ | async">
    <div class="mt-3 table-responsive">
      <table id="datatable" class="table table-striped table-bordered table-hover w-100">
        <thead>
          <tr>
            <th class="text-center align-top">No.</th>
            <th class="text-center align-top">Product Name</th>
            <th class="text-center align-top">Article No.</th>
            <th class="text-center align-top w-10">Barcode</th>
            <th class="text-center align-top w-10">Unit</th>
            <th class="text-center align-top w-10">Order Qty</th>
            <th class="text-center align-top w-10">Delivered Qty</th>
            <th class="text-center align-top w-10">Received Qty</th>
            <th class="text-center align-top w-10">Remaining Qty</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let stockTransferItem of paging.currentPageData; index as i">
            <td class="text-center">{{ paging.getItemNo(i) }}</td>
            <td>{{ stockTransferItem.productName }}</td>
            <td>{{ stockTransferItem.articleNo }}</td>
            <td>{{ stockTransferItem.barcode }}</td>
            <td class="text-center">{{ stockTransferItem.unit }}</td>
            <td class="text-center">{{ stockTransferItem.quantity | numberFormatDisplay: 0 }}</td>
            <td class="text-center">
              {{ stockTransferItem.deliveredQty | numberFormatDisplay: 0 }}
            </td>
            <td class="text-center">
              {{ stockTransferItem.receivedQty | numberFormatDisplay: 0 }}
            </td>
            <td class="text-center">
              {{ stockTransferItem.remainingQty | numberFormatDisplay: 0 }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <app-ui-pagination
      #paging
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [items]="(stockTransfer$ | async).items"
      [valueChangeDetector]="stockTransfer$ | async"
    >
    </app-ui-pagination>
  </ng-container>
</div>
