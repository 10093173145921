import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  PosAdsCreate,
  PosAdsEdit,
  PosAdsListResponse,
  PosAdsSearchCriteria,
  PosAdsWithComment,
  PosAdvertisementListContent
} from '../models/pos-advertisement.model';
import { BaseService } from './base.service';

@Injectable()
export class PosAdvertisementService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.advertisements;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  getPosDefaultAds(): Observable<PosAdvertisementListContent> {
    const url = this.getFullUrl(this.envService.defaultAds);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  searchByCriteria(criteria: PosAdsSearchCriteria): Observable<PosAdsListResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  submit(data: PosAdsCreate): Observable<any> {
    const url = this.getFullUrl(this.envService.submit);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  cancel(data: PosAdsWithComment) {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  getById(adsNo: string): Observable<any> {
    const url = this.getFullUrl(this.envService.get, { adsNo });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  update(data: PosAdsEdit): Observable<any> {
    const url = this.getFullUrl(this.envService.submit);
    return this.http.put<any>(url, data, { headers: this.loaderHeaders(), observe: 'body' });
  }
}
