<div class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-marketing"></em>
    <h4>POS Advertisement</h4>
  </div>

  <!--#region Filter  -->
  <form id="search-form" class="mt-5" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="d-md-flex flex-fill justify-content-end">
      <div class="flex-item d-md-flex justify-content-between align-items-center">
        <div class="form-group text-nowrap d-none d-md-block">
          <button
            type="button"
            class="btn btn-primary mr-2"
            (click)="goToCreateSeasonal()"
            *ngIf="hasManagePermission"
          >
            <em class="icon-plus"></em>
            Create Seasonal Ads
          </button>
        </div>
      </div>
      <div class="filter-search-box flex-item form-group ml-md-2">
        <div class="search-box position-relative">
          <input
            type="text"
            formControlName="searchCriteria"
            class="searchCriteria form-control"
            placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
            required
            maxlength="100"
            (keyup.backspace)="clearLastKeyUpSearchText($event)"
          />
          <em
            class="icon-close-mini"
            aria-hidden="true"
            [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
            (click)="clearSearchText()"
          ></em>
          <button class="btn-search" type="submit">
            <em class="icon-search"></em>
          </button>
        </div>
      </div>
      <div class="filter-dropdown flex-item form-group ml-md-2">
        <ng-select
          [items]="statusList"
          [searchable]="false"
          [clearable]="false"
          (change)="onChangeStatus($event)"
          class="select-status"
          bindLabel="label"
          bindValue="value"
          formControlName="status"
        >
        </ng-select>
      </div>
      <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
        <button
          class="btn advance-filter-button btn-advance-filter"
          [ngClass]="{ active: isShowAdvanceSearch }"
          type="button"
          (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
        >
          <em class="icon-filter" aria-hidden="true"></em>
          {{ 'ADVANCED_FILTERS' | translate }}
        </button>
        <div
          class="box-dropdown"
          [ngClass]="{ open: isShowAdvanceSearch }"
          (appClickOutside)="onClickedOutside($event)"
          [exclude]="['.btn-advance-filter', '.is-highlighted']"
        >
          <div class="box-dropdown-list advance-filter-dropdown">
            <div class="box-dropdown-body px-4">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-2">
                      {{ 'POS_ADVERTISEMENT.EFFECTIVE_DATE' | translate }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{ 'FROM' | translate }}</label>
                      <input
                        id="effectiveDateFrom"
                        formControlName="effectiveDateFrom"
                        type="text"
                        placeholder="{{ dateFormat }}"
                        autocomplete="off"
                        class="form-control calendar"
                        bsDatepicker
                        [bsConfig]="bsDateConfig"
                        [maxDate]="maxDate"
                        (bsValueChange)="onChangeDateFrom($event)"
                        readonly
                      />
                      <label for="effectiveDateFrom" class="icon-calendar btn-calendar"></label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{ 'TO' | translate }}</label>
                      <input
                        id="effectiveDateTo"
                        formControlName="effectiveDateTo"
                        type="text"
                        placeholder="{{ dateFormat }}"
                        autocomplete="off"
                        class="form-control w-100 calendar"
                        bsDatepicker
                        [bsConfig]="bsDateConfig"
                        [minDate]="minDate"
                        (bsValueChange)="onChangeDateTo($event)"
                        readonly
                      />
                      <label for="effectiveDateTo" class="icon-calendar btn-calendar"></label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-2">
                      {{ 'POS_ADVERTISEMENT.EXPIRE_DATE' | translate }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{ 'FROM' | translate }}</label>
                      <input
                        id="expireDateFrom"
                        formControlName="expireDateFrom"
                        type="text"
                        placeholder="{{ dateFormat }}"
                        autocomplete="off"
                        class="form-control w-100 calendar"
                        bsDatepicker
                        [bsConfig]="bsDateConfig"
                        [maxDate]="expireMaxDate"
                        (bsValueChange)="onChangeExpireDateFrom($event)"
                        readonly
                      />
                      <label for="expireDateFrom" class="icon-calendar btn-calendar"></label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{ 'TO' | translate }}</label>
                      <input
                        id="expireDateTo"
                        formControlName="expireDateTo"
                        type="text"
                        placeholder="{{ dateFormat }}"
                        autocomplete="off"
                        class="form-control w-100 calendar"
                        bsDatepicker
                        [bsConfig]="bsDateConfig"
                        [minDate]="expireMinDate"
                        (bsValueChange)="onChangeExpireDateTo($event)"
                        readonly
                      />
                      <label for="expireDateTo" class="icon-calendar btn-calendar"></label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-2">
                      {{ 'POS_ADVERTISEMENT.VALID_DATE' | translate }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{ 'FROM' | translate }}</label>
                      <input
                        id="validDateFrom"
                        formControlName="validDateFrom"
                        type="text"
                        placeholder="{{ dateFormat }}"
                        autocomplete="off"
                        class="form-control calendar"
                        bsDatepicker
                        [bsConfig]="bsDateConfig"
                        [maxDate]="validMaxDate"
                        (bsValueChange)="onChangeValidDateFrom($event)"
                        readonly
                      />
                      <label for="validDateFrom" class="icon-calendar btn-calendar"></label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{ 'TO' | translate }}</label>
                      <input
                        id="validDateTo"
                        formControlName="validDateTo"
                        type="text"
                        placeholder="{{ dateFormat }}"
                        autocomplete="off"
                        class="form-control calendar"
                        bsDatepicker
                        [bsConfig]="bsDateConfig"
                        [minDate]="validMinDate"
                        (bsValueChange)="onChangeValidDateTo($event)"
                        readonly
                      />
                      <label for="validDateTo" class="icon-calendar btn-calendar"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="advance-filter-footer text-center">
              <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                Cancel
              </button>
              <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                {{ 'APPLY' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div
    class="top-search-criteria space-search-criteria"
    *ngIf="effectiveDateStringTag || expireDateStringTag || validDateStringTag"
  >
    <div class="search-criteria" *ngIf="effectiveDateStringTag">
      <a class="remove-criteria" (click)="clearFilterEffectiveDate()">
        <em class="icon-close" aria-hidden="true"></em>
      </a>
      <span>{{ effectiveDateStringTag }}: {{ effectiveDateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="expireDateStringTag">
      <a class="remove-criteria" (click)="clearFilterExpireDate()">
        <em class="icon-close" aria-hidden="true"></em>
      </a>
      <span>{{ expireDateStringTag }}: {{ expireDateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="validDateStringTag">
      <a class="remove-criteria" (click)="clearFilterValidDate()">
        <em class="icon-close" aria-hidden="true"></em>
      </a>
      <span>{{ validDateStringTag }}: {{ validDateTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>
  <!--#endregion -->

  <!--#region DefaultAds  -->
  <ng-container>
    <div class="td-card">
      <div class="row">
        <div class="px-3 catalog-img-container">
          <div class="catalog-img" (click)="goToView(pageMode.VIEW_DEFAULT, defaultAds)">
            <img
              *ngIf="(posAdsDefault$ | async)?.images.length > 0; else imageErr"
              appAdsSrc="{{ defaultAds.images[0].small }}"
              alt="{{ defaultAds.images[0].fileName }}"
            />
            <ng-template #imageErr>
              <img width="200" height="200" src="/assets/images/no-image.png" alt="no image" />
            </ng-template>
          </div>
        </div>

        <div class="col-12 col-md-10 flex-item d-flex flex-column cursor-pointer">
          <div class="row">
            <div class="col-md-6 d-inline-flex" (click)="goToView(pageMode.VIEW_DEFAULT, defaultAds)">
              <label id="product-name" class="chk-container">
                Default Ads
              </label>
            </div>
          </div>
          <div class="row" (click)="goToView(pageMode.VIEW_DEFAULT, defaultAds)">
            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="product-lb-articleNo">No. of Ads</span>
              <span class="result-value">{{ defaultAds?.images?.length | dashDisplay: '(None)' }}</span>
            </div>
            <div class="col-md-6 d-inline-flex">
              <span class="result-property">Last Updated</span>
              <span *ngIf="defaultAds?.lastModifiedDate; else lastModifiedDateErr" class="result-value">{{
                defaultAds.lastModifiedDate | amFromUtc | amLocal | dateDisplay
              }}</span>
              <ng-template #lastModifiedDateErr>
                <span class="result-value">(None)</span>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom"
            containerClass="fl"
            [outsideClick]="true"
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <button
              type="button"
              (click)="goToCreateDefault()"
              class="btn btn-link"
              *ngIf="hasManagePermission && !defaultAds"
            >
              <em class="icon-plus"></em>Create
            </button>
            <button
              type="button"
              class="btn btn-link"
              *ngIf="hasManagePermission && defaultAds"
              (click)="goToView(pageMode.EDIT_DEFAULT, defaultAds)"
            >
              <em class="icon-edit"></em>Edit
            </button>
            <button type="button" class="btn btn-link d-block" (click)="showHistory(defaultAds)" *ngIf="defaultAds">
              <em class="icon-history"></em>History
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
  <!--#endregion -->

  <!--#region SeasonAds  -->
  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="table-responsive">
      <table id="data-table" class="table table-striped table-bordered table-hover w-100" aria-hidden="true">
        <thead>
          <tr>
            <th class="text-center w-10">Seasonal Ads No.</th>
            <th class="text-center w-10">Effective Date</th>
            <th class="text-center w-10">Expire Date</th>
            <th class="text-center w-10">Created By</th>
            <th class="text-center w-10">Created Date</th>
            <th class="text-center w-15">Status</th>
            <th class="text-center w-5">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of resultList$ | async; index as i">
            <td (click)="goToView(pageMode.VIEW_SEASONAL, item)">
              {{ item.adsNo | dashDisplay: '(None)' }}
            </td>
            <td (click)="goToView(pageMode.VIEW_SEASONAL, item)" class="text-center">
              {{ item?.effectiveDate | amFromUtc | amLocal | dateDisplay: dateFormat }}
            </td>
            <td (click)="goToView(pageMode.VIEW_SEASONAL, item)" class="text-center">
              {{ item?.expireDate | amFromUtc | amLocal | dateDisplay: dateFormat }}
            </td>
            <td (click)="goToView(pageMode.VIEW_SEASONAL, item)">
              {{ item.createdByName | dashDisplay }}
            </td>
            <td (click)="goToView(pageMode.VIEW_SEASONAL, item)" class="text-center">
              {{ item?.createdDate | amFromUtc | amLocal | dateDisplay: dateFormat }}
            </td>
            <td
              (click)="goToView(pageMode.VIEW_SEASONAL, item)"
              [ngClass]="getColorStatus(item.status)"
              class="status"
            >
              {{ 'POS_ADVERTISEMENT.STATUS.' + item.status | translate }}
            </td>
            <td class="text-center">
              <a
                href="javascript:void(0)"
                class="btn-more mx-auto"
                [popover]="moreTemplate"
                placement="bottom"
                containerClass="fl"
                container=".popover-container"
                [outsideClick]="true"
              >
                <em class="icon-more"></em>
              </a>
              <ng-template #moreTemplate>
                <button
                  type="button"
                  class="btn btn-link"
                  (click)="cancelSeasonalAds(item)"
                  *ngIf="hasManagePermission && showCancelButton(item)"
                >
                  <em class="icon-forbidden"></em>Cancel Ads
                </button>
                <button type="button" class="btn btn-link d-block" (click)="showHistory(item)">
                  <em class="icon-history"></em>History
                </button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
  <!--#endregion -->
</div>
