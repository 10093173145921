import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import {
  PurchaseConditionPageModes,
  PurchaseConditionRequestStatusEnum
} from '../../../shared/enum/purchase-condition.enum';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { RouteLinkTab } from '../../../shared/models';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import {
  PurchaseConditionRequestContent,
  PurchaseConditionRequestListSearchCriteria
} from '../../../shared/models/purchase-condition-request.model';
import { SupplierSearch } from '../../../shared/models/supplier.model';
import { AuthGuardService } from '../../../shared/services';
import { SupplierService } from '../../../shared/services/supplier.service';
import { LayoutForceCloseCurrentModal } from '../../../shared/store/actions/layout.action';
import {
  CancelPurchaseConditionRequest,
  DeletePurchaseConditionRequest,
  PurchaseConditionRequestListSearchRequested
} from '../../../shared/store/actions/purchase-condition.actions';
import { PurchaseConditionRequestState } from '../../../shared/store/reducers/purchase-condition.reducer';
import { selectForceCloseCurrentModal } from '../../../shared/store/selectors/layout-selector';
import {
  selectAllPurchaseConditionRequestList,
  selectPurchaseConditionRequest
} from '../../../shared/store/selectors/purchase-condition.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { PurchaseConditionPermission } from '../purchase-condition-permission';
import { PurchaseConditionViewComponent } from '../purchase-condition-view/purchase-condition-view.component';

@Component({
  selector: 'app-purchase-condition-request-list',
  templateUrl: './purchase-condition-request-list.component.html',
  styleUrls: ['./purchase-condition-request-list.component.scss']
})
export class PurchaseConditionRequestListComponent
  extends BaseSearchComponent<
    PurchaseConditionRequestListSearchCriteria,
    PurchaseConditionRequestContent,
    PurchaseConditionRequestState
  >
  implements OnInit {
  @ViewChild('modalCreatePc', { static: false }) modalCreatePc: ModalDirective;

  listRoute: Array<RouteLinkTab>;
  public createPurchaseConditionForm: FormGroup;
  public createdMaxDate: Date;
  public createdMinDate: Date;
  public submitted: boolean;

  public supplierList: Observable<SupplierSearch[]>;
  public supplierSearchLoading = false;
  public supplierSearchInput$ = new Subject<string>();

  public bsModalRef: BsModalRef;
  public statusList: NgOption[] = filterDropdown.purchaseConditionRequestStatusFilter;
  public requestTypeList: Array<{ value: string; label: string }> = filterDropdown.newRequestTypeFilter;
  public dateFormat = environment.dateFormat;

  public dateTag: string;
  public dateStringTag: string;
  public requestTypeTag: string;
  public requestTypeStringTag: string;
  public purchaseConditionRequestStatusEnum = PurchaseConditionRequestStatusEnum;
  private localStore: Observable<any>;
  public purchaseConditionPermission = new PurchaseConditionPermission();

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected readonly translate: TranslateService,
    protected authGuardService: AuthGuardService,
    protected supplierService: SupplierService
  ) {
    super(store, modalService, selectAllPurchaseConditionRequestList, selectPurchaseConditionRequest);
    super.subscribeForSaveSuccess();
  }

  setInitialValue() {
    this.submitted = false;
    this.pageSize = 20;
    this.isShowAdvanceSearch = false;
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.createdMaxDate = new Date();
    this.createdMaxDate.setDate(this.createdMaxDate.getDate() + 365);

    this.purchaseConditionPermission.checkPermission(this.authGuardService);
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      requestType: [null],
      status: [''],
      createdDateFrom: [null],
      createdDateTo: [null]
    });
    this.createPurchaseConditionForm = this.fb.group({
      supplier: [{ value: null, disabled: false }, Validators.required]
    });
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  doDestroy() {}

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.loadSupplier('');
    this.localStore.pipe(select(selectForceCloseCurrentModal)).subscribe(forCloseCurrentModal => {
      if (forCloseCurrentModal) {
        this.doAfterSuccessModal();
      }
      this.store.dispatch(new LayoutForceCloseCurrentModal(false));
    });
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (!formValue.createdDateFrom && !formValue.createdDateTo && !formValue.requestType) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      requestType: formValue.requestType,
      createdDateFrom: dateToStringCriteria(formValue.createdDateFrom),
      createdDateTo: dateToStringCriteria(formValue.createdDateTo, false),
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearDateFilter() {
    this.setFirstPage();
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      createdDateFrom: null,
      createdDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearRequestTypeFilter() {
    this.setFirstPage();
    this.searchForm.controls['requestType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      requestType: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.searchForm.controls['requestType'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      createdDateFrom: null,
      createdDateTo: null,
      requestType: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;
    this.requestTypeTag = null;
    this.requestTypeStringTag = null;

    const createdDateFrom = dateStringToTagCriteria(this.criteriaObject.createdDateFrom);
    const createdDateTo = dateStringToTagCriteria(this.criteriaObject.createdDateTo);
    const createdDate = generateDateStringTag({
      dateName: 'Requested Date',
      dateFrom: createdDateFrom,
      dateTo: createdDateTo
    });

    this.dateStringTag = createdDate.dateStringTag;
    this.dateTag = createdDate.dateTag;

    if (this.criteriaObject.requestType && this.criteriaObject.requestType.length > 0) {
      this.requestTypeStringTag = 'Request Type';
      const requestType = this.requestTypeList
        .filter(data => {
          return this.criteriaObject.requestType.indexOf(data.value) > -1;
        })
        .map(status => status.label);

      const requestTypes = requestType.join(', ');
      this.requestTypeTag = `"${requestTypes}"`;
    }
  }

  search(criteriaObj) {
    this.store.dispatch(new PurchaseConditionRequestListSearchRequested(criteriaObj));
  }

  setRouteTab() {
    /** TODO add route tab according to permission **/
    this.listRoute.push({
      tabName: 'Purchase Condition',
      url: '/purchase/purchase-condition/purchase-condition-list'
    });

    this.listRoute.push({
      tabName: 'Purchase Condition Request',
      url: '/purchase/purchase-condition/purchase-condition-request-list'
    });
  }

  onShowModalCreatePc() {
    this.modalCreatePc.show();
  }

  onHideModalCreatePc() {
    this.submitted = false;
    this.createPurchaseConditionForm.reset();
    this.modalCreatePc.hide();
  }

  goToCreate() {
    this.submitted = true;
    if (this.createPurchaseConditionForm.invalid) {
      return;
    }

    const formValue = this.createPurchaseConditionForm.getRawValue();
    const initialState = {
      title: null,
      childItem: new ChildItem(
        PurchaseConditionViewComponent,
        {
          title: PurchaseConditionRequestListComponent.getPurchaseConditionTitle(PurchaseConditionPageModes.CREATE),
          mode: PurchaseConditionPageModes.CREATE,
          supplierCode: formValue.supplier.supplierCode,
          supplierName: formValue.supplier.supplierName,
          supplierNameDisplay: formValue.supplier.supplierNameDisplay,
          status: PurchaseConditionRequestStatusEnum.DRAFT
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState
    });

    this.onHideModalCreatePc();
  }

  goToView(item: any) {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        PurchaseConditionViewComponent,
        {
          title: PurchaseConditionRequestListComponent.getPurchaseConditionTitle(
            PurchaseConditionPageModes.VIEW_REQUEST
          ),
          mode: PurchaseConditionPageModes.VIEW_REQUEST,
          id: item.id,
          supplierCode: item.supplierCode,
          supplierName: item.supplierName,
          supplierNameDisplay: `${item.supplierCode}: ${item.supplierName}`,
          status: item.requestStatus,
          version: item.version,
          scheduleType: item.scheduleType
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
  }

  goToEdit(item: any) {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        PurchaseConditionViewComponent,
        {
          title: PurchaseConditionRequestListComponent.getPurchaseConditionTitle(
            PurchaseConditionPageModes.EDIT_REQUEST
          ),
          mode: PurchaseConditionPageModes.EDIT_REQUEST,
          id: item.id,
          supplierCode: item.supplierCode,
          supplierName: item.supplierName,
          supplierNameDisplay: `${item.supplierCode}: ${item.supplierName}`,
          status: item.requestStatus,
          version: item.version,
          scheduleType: item.scheduleType
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
  }

  onDelete(purchaseConditionRequestContent: PurchaseConditionRequestContent) {
    if (purchaseConditionRequestContent.requestStatus !== PurchaseConditionRequestStatusEnum.DRAFT) {
      return;
    }
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete the request?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (ModalButtonResponseEnum.OK === result) {
          this.store.dispatch(new DeletePurchaseConditionRequest({ id: purchaseConditionRequestContent.id }));
        }
      });
  }

  loadSupplier(initialTerm: string) {
    this.supplierList = concat(
      of([]),
      this.supplierSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.supplierSearchLoading = true)),
        switchMap(term =>
          this.supplierService
            .searchSupplierByNameFromPurchase({
              searchCriteria: term,
              status: 'active',
              filterOutPurchaseCondition: true
            })
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => {
                this.supplierSearchLoading = false;
              })
            )
        )
      )
    );
  }

  onChangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }

  onCancelRequest(item: any) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number "${item.requestNo}"?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new CancelPurchaseConditionRequest({
              id: item.id,
              comment: confirmModalRef.content.confirmMessage,
              version: item.version
            })
          );
        }
      });
  }

  showHistory(item: any) {
    const initialState = {
      title: 'History',
      historyHeader: `Request No.: ${item.requestNo}`,
      auditLogs: item.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  public static getPurchaseConditionTitle(mode: PurchaseConditionPageModes) {
    let title: string;
    switch (mode) {
      case PurchaseConditionPageModes.CREATE:
        title = 'Create Purchase Condition Request';
        break;
      case PurchaseConditionPageModes.VIEW_REQUEST:
        title = 'View Purchase Condition Request';
        break;
      case PurchaseConditionPageModes.EDIT_REQUEST:
        title = 'Edit Purchase Condition  Request';
        break;
      default:
    }
    return title;
  }
}
