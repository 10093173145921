import { ApproveStatusEnum } from '../enum/approve-status.enum';
import { NewRequestStatusEnum } from '../enum/request-status.enum';
import { NewRequestTypeEnum } from '../enum/request-step.enum';
import { BarcodeResponse } from './barcode.model';

export class ShelfInventoryRequestListSearchCriteria {
  searchCriteria?: string;
  status?: string;
  requestedDateFrom?: string;
  requestedDateTo?: string;
  requestType?: string;
  shelfType?: string;
  page: number;
  size: number;
}

export class ShelfInventoryRequestListResponse {
  content: ShelfInventoryRequestList[];
  empty: boolean;
  first: boolean;
  hasContent: boolean;
  last: boolean;
  numberOfElements: number;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class ShelfInventoryRequestList {
  id: string;
  requestNo: string;
  shelfType: string;
  shelfName: string;
  requestType: string;
  requestBy: string;
  requestedDate: string;
  requestStatus: NewRequestStatusEnum;
}

export interface ShelfItem {
  barcode: string;
  productName: string;
  unit: string;
  unitFactor: number;
  plg: string;
  facingQty: number;
  stackingQty: number;
  depthQty: number;
  minFirstLotQty: number;
  minBeautyQty: number;
  errorMessage: any;
}

export class RequestId {
  requestId: string;
}

export class ShelfType {
  shelfTypeCode: string;
  shelfTypeName: string;
}

export class ApproveShelfInventoryRequest {
  requestNo: string;
  status: ApproveStatusEnum;
  comment: string;
}

export class ShelfInventoryCreateRequest {
  id?: string;
  items: MerchandiseItem[];
  shelfInfo: ShelfInfo;
  shelfNo?: string;
  version = 0;
  requestNo?: string;
  shelfCode?: string;
  type: NewRequestTypeEnum;
  status: NewRequestStatusEnum;
}

export class ShelfInventoryRequestViewResponse extends ShelfInventoryCreateRequest {
  requestedBy: string;
  requestedByName: string;
  requestedDate: string;
  deleteItems?: DeleteShelfItems[];
}

export class ShelfInfo {
  shelfName: string;
  shelfType: string;
  shelfImage: string;
  changes?: ChangesItems[];
}
export class MerchandiseItem {
  articleNo: string;
  barcode: string;
  productName: string;
  productGrading: string;
  productLocation: string;
  unit: string;
  unitFactor: number;
  plg: string;
  facingQty: number;
  stackingQty: number;
  depthQty: number;
  minFirstLotQty: number;
  minBeautyQty: number;
  classCode: string;
  changeStatus?: string;
  changes?: ChangesItems[];

  public static mappingBarcodeResponseToMerchandiseItem(response: BarcodeResponse): MerchandiseItem {
    return {
      articleNo: response.articleNo,
      classCode: response.classCode,
      barcode: response.barcode,
      productName: response.productName,
      productGrading: response.productGrading,
      productLocation: response.productLocation,
      unit: response.unit,
      unitFactor: response.unitFactor,
      plg: response.productLocation,
      facingQty: null,
      stackingQty: null,
      depthQty: null,
      minFirstLotQty: null,
      minBeautyQty: null
    };
  }
}

export class InventoryImportItem {
  barcode: string;
  facingQty: number;
  stackingQty: number;
  depthQty: number;
  product: BarcodeResponse;
}

export class FirstLotImportItem {
  barcode: string;
  minFirstLotQty: number;
  minBeautyQty: number;
}

export class ChangesItems {
  field: string;
  before: string | number;
  after: string | number;
}

export class ListOfChanges {
  barcode: string;
  changeStatus: string;
  changes: string[];
}

export class DeleteShelfItems {
  barcode: string;
  productName: string;
  unit: string;
  unitFactor: number;
}
