import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseComponent } from '../../../base/base.component';
import { isZeroValidator } from '../../../shared/custom-validators/is-zero-validator';
import {
  OrderDeliveryScheduleType,
  PurchaseConditionPageModes,
  PurchaseConditionProductLevelEnum
} from '../../../shared/enum/purchase-condition.enum';
import { NewRequestTypeEnum } from '../../../shared/enum/request-step.enum';
import { PurchaseConditionRequestWeeklySchedule } from '../../../shared/models/purchase-condition-request.model';
import { SelectWarehouseList } from '../../../shared/models/warehouse.model';
import {
  PurchaseConditionRemoveOrderScheduleImportId,
  PurchaseConditionRequestWeeklyScheduleLoaded
} from '../../../shared/store/actions/purchase-condition.actions';
import { SelectWarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { selectWarehouseList } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

export enum WeeklyManualMode {
  CREATE = 'Add Order/Delivery Schedule',
  EDIT = 'Edit Order/Delivery Schedule'
}

interface WeeklyScheduleManualForm {
  scheduleType: OrderDeliveryScheduleType;
  rowId: number;
  leadTime: number;
  orderSchedules: number[];
  deliverySchedules: number[];
  wmsCode: {
    code: string;
    wmsCode: string;
    name: string;
  }[];
  articleNo: string;
  productLevel: PurchaseConditionProductLevelEnum;
}

@Component({
  selector: 'app-schedule-weekly-manual',
  templateUrl: './schedule-weekly-manual.component.html',
  styleUrls: ['./schedule-weekly-manual.component.scss']
})
export class ScheduleWeeklyManualComponent extends BaseComponent implements OnInit, AfterViewInit {
  warehouseList = [];
  public requestTypeEnum = NewRequestTypeEnum;
  public warehouseList$: Observable<SelectWarehouseList[]> = this.store
    .select(selectWarehouseList)
    .pipe(tap(val => (this.warehouseList = val)));
  title = WeeklyManualMode.CREATE;
  buttonLabel = 'Add';
  isSubmitted = false;
  form: FormGroup;
  duplicateWarehouseErrorList: { code: string; wmsCode: string; name: string; duplicateOrder: number[] }[] = [];
  weeklyList = [
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },
    { value: 7, label: 'Sunday' }
  ];

  _displayDialog = false;

  @ViewChild('templateDialog', { static: false }) templateDialog: ModalDirective;
  @Output() displayModelChange = new EventEmitter<boolean>();
  @Output() dirtyOrderSchedule = new EventEmitter<boolean>();
  @Input() purchaseConditionForm: FormGroup;
  @Input() pageMode: PurchaseConditionPageModes;
  @Input() set displayModel(display: boolean) {
    this._displayDialog = display;
    this.hideAndShowDialog();
  }

  get displayModel() {
    return this._displayDialog;
  }

  @Input() weeklyManualValue: {
    table: PurchaseConditionRequestWeeklySchedule[];
    weeklyScheduleManual: PurchaseConditionRequestWeeklySchedule;
  };

  constructor(
    private fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService
  ) {
    super(store, modalService);
  }

  ngAfterViewInit(): void {
    this.hideAndShowDialog();
  }

  doAfterVersionAlertModal() {}

  doAfterSuccessModal() {}

  subscribeForVersionError() {}

  ngOnInit() {
    this.prepareFormGroup();
    this.patchValueToForm();
  }

  prepareFormGroup() {
    this.store.dispatch(new SelectWarehouseListRequestAction({ isWarehouse: true }));
    this.form = this.fb.group({
      scheduleType: [OrderDeliveryScheduleType.WEEKLY_MANUAL],
      rowId: [null],
      leadTime: [null, [Validators.required, isZeroValidator()]],
      orderSchedules: [null, Validators.required],
      deliverySchedules: [null, Validators.required],
      wmsCode: [null, Validators.required],
      articleNo: ['ALL_PRODUCTS'],
      productLevel: ['ALL_PRODUCTS']
    });
  }

  patchValueToForm() {
    if (this.weeklyManualValue.weeklyScheduleManual) {
      this.title = WeeklyManualMode.EDIT;
      this.buttonLabel = 'Update';
      const formData = {
        ..._.cloneDeep(this.weeklyManualValue.weeklyScheduleManual),
        wmsCode: this.weeklyManualValue.weeklyScheduleManual.warehouses.map(val => val.wmsCode)
      };

      this.form.patchValue(formData);
    }
  }

  hideAndShowDialog() {
    if (this.templateDialog && this._displayDialog) {
      this.templateDialog.show();
    }
  }

  onHideModal() {
    this.form.reset();
    this.displayModelChange.emit(false);
    this.templateDialog.hide();
  }

  onSubmit() {
    this.isSubmitted = true;

    const formData: WeeklyScheduleManualForm = this.form.getRawValue();
    const wmsCodeList: {
      code: string;
      wmsCode: string;
      name: string;
    }[] = [];

    this.warehouseList.map(val => {
      if (formData.wmsCode && formData.wmsCode.includes(val.wmsCode)) {
        wmsCodeList.push({
          code: val.code,
          name: val.name,
          wmsCode: val.wmsCode
        });
      }
    });

    formData.wmsCode = wmsCodeList;

    if (
      [PurchaseConditionPageModes.EDIT].includes(this.pageMode) ||
      ([PurchaseConditionPageModes.EDIT_REQUEST].includes(this.pageMode) &&
        this.purchaseConditionForm.controls['requestType'].value === this.requestTypeEnum.EDIT)
    ) {
      this.purchaseConditionForm.patchValue({
        scheduleEdit: true
      });
    }

    if (this.checkDuplicateWarehouseFn(formData) || this.form.invalid) {
      return;
    }

    this.prepareFormSubmit(formData);
    this.dirtyOrderSchedule.emit(true);
    this.store.dispatch(new PurchaseConditionRemoveOrderScheduleImportId());
    this.onHideModal();
  }

  prepareFormSubmit(rawValue: WeeklyScheduleManualForm) {
    if (rawValue.rowId) {
      // edit mode
      this.weeklyManualValue.table = this.weeklyManualValue.table.map(val => {
        if (rawValue.rowId === val['rowId']) {
          // add to same row
          val.rowId = rawValue.rowId;
          val.leadTime = rawValue.leadTime;
          val.orderSchedules = rawValue.orderSchedules;
          val.deliverySchedules = rawValue.deliverySchedules;
          val.warehouses = rawValue.wmsCode;
          val.articleNo = rawValue.articleNo;
          val.productLevel = rawValue.productLevel;
          val.scheduleType = rawValue.scheduleType;
        }

        return val;
      });
    } else {
      const rowIdmax: number =
        this.weeklyManualValue.table.length > 0 ? _.maxBy(this.weeklyManualValue.table, 'rowId').rowId + 1 : 1;
      this.weeklyManualValue.table.push({
        rowId: rowIdmax,
        leadTime: rawValue.leadTime,
        orderSchedules: rawValue.orderSchedules,
        deliverySchedules: rawValue.deliverySchedules,
        warehouses: rawValue.wmsCode,
        articleNo: rawValue.articleNo,
        productLevel: rawValue.productLevel,
        importId: null,
        productName: null,
        scheduleType: rawValue.scheduleType
      });
    }

    this.store.dispatch(new PurchaseConditionRequestWeeklyScheduleLoaded({ content: this.weeklyManualValue.table }));
  }

  orderSelectAllDays() {
    this.form.patchValue({
      orderSchedules: [1, 2, 3, 4, 5, 6, 7]
    });
  }

  isInvalidForm(isSubmitted, form: ValidationErrors) {
    return isSubmitted && form && form.required;
  }

  isInvalidFormClass(isSubmitted, form: ValidationErrors) {
    return isSubmitted && form ? 'is-invalid' : '';
  }

  orderClearAllDays() {
    this.form.get('orderSchedules').reset();
  }

  deliverySelectAllDays() {
    this.form.patchValue({
      deliverySchedules: [1, 2, 3, 4, 5, 6, 7]
    });
  }

  deliveryClearAllDays() {
    this.form.get('deliverySchedules').reset();
  }

  checkDuplicateWarehouseFn(formData: WeeklyScheduleManualForm): boolean {
    let isDuplicate = false;
    this.duplicateWarehouseErrorList = [];

    if (this.weeklyManualValue.table.length === 0) {
      return;
    }

    this.weeklyManualValue.table.forEach(itemFormTable => {
      // ไม่เอาตัวเอง
      if (!formData.rowId || itemFormTable['rowId'] !== formData.rowId) {
        const orderSchedulesFromTable =
          itemFormTable.orderSchedules.length > 1
            ? itemFormTable.orderSchedules.sort()
            : itemFormTable.orderSchedules;
        const orderSchedulesFormData =
          formData.orderSchedules.length > 1 ? formData.orderSchedules.sort() : formData.orderSchedules;
        const duplicateOrder = _.intersection(orderSchedulesFromTable, orderSchedulesFormData);

        formData.wmsCode.forEach(wmsCodeName => {
          this.duplicateWarehouseErrorList.push(
            ...itemFormTable.warehouses.reduce((result, currentValue) => {
              if (currentValue.wmsCode === wmsCodeName.wmsCode && duplicateOrder.length > 0) {
                result.push({ ...currentValue, duplicateOrder });
              }

              return result;
            }, [])
          );

          if (this.duplicateWarehouseErrorList.length > 0) {
            isDuplicate = true;
          } else {
            isDuplicate = false;
          }
        });
      }
    });

    return isDuplicate;
  }
}
