<div class="popover-container">
  <div class="row section-header">
    <span
      >Minimum Order Value (MOV)
      <span
        *ngIf="
          purchaseConditionForm.get('movEdit').value &&
          purchaseConditionForm.get('requestType').value === requestTypeEnum.EDIT &&
          [purchaseConditionPageMode.VIEW_REQUEST].includes(mode)
        "
        class="badge badge-warning text-white"
        >Updated</span
      >
    </span>
  </div>
  <div class="table-responsive" *ngIf="minimumOrderList.length > 0">
    <table id="data-table" class="table table-striped table-bordered table-hover w-100" aria-hidden="true">
      <thead>
        <tr>
          <th class="text-center align-top">No.</th>
          <th class="text-center align-top">Purchase Amount (Exc VAT)</th>
          <th class="text-center align-top">Currency</th>
          <th class="text-center align-top">Apply to DC</th>
          <th
            class="text-center align-top"
            *ngIf="![purchaseConditionPageMode.VIEW_REQUEST, purchaseConditionPageMode.VIEW].includes(mode)"
          >
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let minimumOrderValue of minimumOrderList; let i = index">
          <td class="text-center">{{ i + 1 }}</td>
          <td class="text-right">{{ minimumOrderValue.purchaseAmountExVat | numberFormatDisplay }}</td>
          <td class="text-center">THB</td>
          <td
            class="text-left white-space"
            [innerHTML]="wmsCodeNameForDisplay | pureFn: minimumOrderValue.wmsCode"
          ></td>
          <td
            class="text-center"
            *ngIf="![purchaseConditionPageMode.VIEW_REQUEST, purchaseConditionPageMode.VIEW].includes(mode)"
          >
            <a
              href="javascript:void(0)"
              class="btn-more"
              [popover]="moreTemplate"
              placement="bottom"
              containerClass="fl"
              [outsideClick]="true"
              container=".popover-container"
              [adaptivePosition]="false"
            >
              <em class="icon-more"></em>
            </a>
            <ng-template #moreTemplate>
              <button type="button" class="btn btn-link" (click)="onEdit(minimumOrderValue, templateDialog)">
                <em class="icon-edit"></em>Edit
              </button>
              <button type="button" class="btn btn-link" (click)="onDelete(i)">
                <em class="icon-delete"></em>Delete
              </button>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <a
      class="btn-text-link d-inline-block mr-1"
      (click)="onShow(templateDialog)"
      *ngIf="![purchaseConditionPageMode.VIEW_REQUEST, purchaseConditionPageMode.VIEW].includes(mode)"
      >+Add Minimum Order Value</a
    >
  </div>
</div>

<div
  class="modal fade"
  bsModal
  #templateDialog="bs-modal"
  [config]="{ backdrop: 'static' }"
  role="dialog"
  aria-labelledby="dialog-static-name"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ title }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="onHideModal(templateDialog)">
          <em class="icon-close"></em>
        </button>
      </div>

      <div class="modal-body" [formGroup]="form" (ngSubmit)="onSubmit(templateDialog)">
        <div class="container-fluid">
          <div class="admin-form">
            <div class="row mb-3">
              <div class="col-md-9 pr-0">
                <label>Purchase Amount (Exc VAT)<span class="text-danger">*</span></label>
                <app-validators-message [(submitted)]="isSubmitted">
                  <app-numeric-textbox
                    [autoCorrect]="true"
                    [decimals]="2"
                    [max]="999999999.99"
                    [min]="0"
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    class="form-control"
                    placeholder="Purchase Amount (Exc VAT)"
                    formControlName="purchaseAmountExVat"
                  ></app-numeric-textbox>
                </app-validators-message>
              </div>
              <div class="col-md-3">
                <label>Currency<span class="text-danger">*</span></label>
                <ng-select
                  placeholder="Please select..."
                  [items]="listOfValue['baseCurrency']"
                  [searchable]="false"
                  [clearable]="false"
                  bindLabel="value"
                  bindValue="value"
                  formControlName="currency"
                >
                </ng-select>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-md-12">
                <label>Apply to DC<span class="text-danger">*</span></label>
                <app-dropdown-multiple-select
                  [parentForm]="form"
                  [items]="warehouseList$ | async"
                  bindLabel="locationDisplay"
                  bindValue="wmsCode"
                  controlName="wmsCode"
                  [ngClass]="isInvalidForm | pureFn: isSubmitted:form.controls.wmsCode.errors"
                >
                </app-dropdown-multiple-select>
                <div *ngIf="isSubmitted && form.controls.wmsCode?.errors" class="invalid-display">
                  <div *ngIf="form.controls.wmsCode?.errors.required">
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="duplicateWarehouseErrorList?.length > 0">
              <div class="p-2 mb-3 duplicate error-background">
                {{ duplicateWarehouseErrorList?.length }} Error Found.
              </div>
              <div class="table-responsive mb-3">
                <table id="supplierPriceTable" class="table table-striped table-bordered">
                  <thead>
                    <th class="text-center error-background">
                      #
                    </th>
                    <th class="text-center error-background">
                      DC
                    </th>
                    <th class="text-center error-background">
                      Description
                    </th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let warehouse of duplicateWarehouseErrorList; let i = index">
                      <td class="text-center">{{ i + 1 }}</td>
                      <td class="text-left">{{ warehouse.wmsCodeName }}</td>
                      <td class="text-left">Duplicated minimum order value.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-standard" id="btCancel" (click)="onHideModal(templateDialog)">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" id="btnSubmit" (click)="onSubmit(templateDialog)">
          {{ buttonLabel }}
        </button>
      </div>
    </div>
  </div>
</div>
