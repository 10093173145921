import { createSelector } from '@ngrx/store';
import * as fromPurchaseCondition from '../reducers/purchase-condition.reducer';
import { AppStates } from '../state/app.states';

const selectPurchaseConditionRequestState = (state: AppStates) => state.purchaseConditionRequest;

const selectItemConditionState = (state: AppStates) => state.purchaseConditionRequest.itemCondition;

const selectPurchaseConditionListState = (state: AppStates) => state.purchaseConditionRequest.purchaseConditionList;

const selectOrderDeliveryScheduleState = (state: AppStates) =>
  state.purchaseConditionRequest.purchaseConditionRequestWeeklyScheduleList;

export const selectAllPurchaseConditionRequestList = createSelector(
  selectPurchaseConditionRequestState,
  fromPurchaseCondition.selectAll
);

export const selectAllItemConditionList = createSelector(
  selectItemConditionState,
  fromPurchaseCondition.selectAllItemCondition
);

export const selectItemCondition = createSelector(
  selectItemConditionState,
  (state: fromPurchaseCondition.ItemConditionState) => state
);

export const selectPurchaseConditionRequest = createSelector(
  selectPurchaseConditionRequestState,
  (state: fromPurchaseCondition.PurchaseConditionRequestState) => state
);

export const selectPurchaseConditionRequestListCriteria = createSelector(
  selectPurchaseConditionRequestState,
  state => {
    return state.criteriaObject;
  }
);

export const selectPurchaseConditionRequestView = createSelector(selectPurchaseConditionRequestState, state => {
  return state.purchaseConditionRequestView;
});

export const selectPurchaseConditionView = createSelector(selectPurchaseConditionRequestState, state => {
  return state.purchaseConditionView;
});

export const selectPurchaseConditionReqMov = createSelector(selectPurchaseConditionRequestState, state => {
  return state.purchaseConditionReqMOV;
});

export const selectAllPurchaseConditionRequestWeeklyScheduleList = createSelector(
  selectOrderDeliveryScheduleState,
  fromPurchaseCondition.selectAllPurchaseConditionRequestWeeklySchedule
);

export const selectPurchaseConditionRequestWeeklySchedule = createSelector(
  selectOrderDeliveryScheduleState,
  (state: fromPurchaseCondition.PurchaseConditionRequestWeeklyScheduleState) => state
);

export const selectPurchaseConditionRequestWeeklyScheduleImportId = createSelector(
  selectOrderDeliveryScheduleState,
  (state: fromPurchaseCondition.PurchaseConditionRequestWeeklyScheduleState) => state.importId
);

export const selectImportId = createSelector(selectItemConditionState, state => {
  return state.importId;
});

export const selectAllPurchaseConditionList = createSelector(
  selectPurchaseConditionListState,
  fromPurchaseCondition.selectAllPurchaseConditionList
);

export const selectPurchaseCondition = createSelector(
  selectPurchaseConditionListState,
  (state: fromPurchaseCondition.PurchaseConditionListState) => state
);

export const selectPurchaseConditionRequestApproveRejectStatus = createSelector(
  selectPurchaseConditionRequestState,
  (state: fromPurchaseCondition.PurchaseConditionRequestState) => {
    return state.isApproveRejectSuccess;
  }
);

export const selectPurchaseConditionAddUpdateContactDcStatus = createSelector(
  selectPurchaseConditionRequestState,
  (state: fromPurchaseCondition.PurchaseConditionRequestState) => {
    return state.isAddUpdateContactAndDCSuccess;
  }
);

export const selectPurchaseConditionRequestCriteria = createSelector(selectPurchaseConditionRequestState, state => {
  return state.criteriaObject;
});

export const selectContractAndDcResponseError = createSelector(selectPurchaseConditionRequestState, state => {
  return state.purchaseConditionRequestView.contractAndDcResponseError;
});
