import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { DefaultStatusEnum } from '../../../shared/enum/default-status.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { RewardPageModes } from '../../../shared/enum/reward.enum';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { PortalModule, RouteLinkTab, TaskModuleUrl } from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { RewardRequestContent, RewardRequestSearchCriteria } from '../../../shared/models/reward.model';
import { AuthGuardService } from '../../../shared/services';
import {
  RewardRequestCancelRequestedAction,
  RewardRequestDeleteRequestAction,
  RewardRequestListRequestAction
} from '../../../shared/store/actions/reward-request.actions';
import { RewardRequestState } from '../../../shared/store/reducers/reward-request.reducers';
import {
  selectAllRewardRequestList,
  selectRewardRequestList,
  selectRewardRequestListCriteria
} from '../../../shared/store/selectors/reward-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { ModuleUtil } from '../../../shared/utils/module-util';
import { RewardRequestComponent } from '../reward-request/reward-request.component';

@Component({
  selector: 'app-reward-request-list',
  templateUrl: './reward-request-list.component.html',
  styleUrls: ['./reward-request-list.component.scss']
})
export class RewardRequestListComponent extends BaseSearchComponent<
  RewardRequestSearchCriteria,
  RewardRequestContent,
  RewardRequestState
> {
  private localStore: Observable<any>;
  private bsModalRef: BsModalRef;
  listRoute: Array<RouteLinkTab>;
  public readonly pageMode = RewardPageModes;

  public minDate: Date;
  public maxDate: Date;
  public offerToMaxDate: Date;
  public offerToMinDate: Date;
  public requestMaxDate: Date;
  public requestMinDate: Date;
  public validMaxDate: Date;
  public validMinDate: Date;

  public statusList: any[];
  public dateFormat = environment.dateFormat;
  public offerFromDateTag: string;
  public offerFromDateStringTag: string;
  public offerToDateTag: string;
  public offerToDateStringTag: string;
  public requestedDateTag: string;
  public requestedDateStringTag: string;
  public validDateTag: string;
  public validDateStringTag: string;

  hasViewRewardPermission: boolean;
  hasEditRewardPermission: boolean;
  hasApproveRewardPermission: boolean;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService
  ) {
    super(store, modalService, selectAllRewardRequestList, selectRewardRequestList);
    super.subscribeForSaveSuccess();
    this.statusList = filterDropdown.rewardRequestStatusFilter;
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectRewardRequestListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.hasViewRewardPermission = this.authGuardService.checkPermission(['rr_v']);
    this.hasEditRewardPermission = this.authGuardService.checkPermission(['rw_m']);
    this.hasApproveRewardPermission = this.authGuardService.checkPermission(['rw_app']);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  setRouteTab(): void {
    const hasListPagePermission = this.authGuardService.checkPermission(['rw_m', 'rw_app', 'rw_v']);
    const hasRequestPagePermission = this.authGuardService.checkPermission(['rw_app', 'rw_m', 'rr_v']);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Active Reward', url: '/campaign/reward-active-list' });
      this.listRoute.push({ tabName: 'Reward List', url: '/campaign/reward-list' });
    }
    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Reward Request', url: '/campaign/reward-request-list' });
    }
  }

  gotoCreateRewardRequest(): void {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        RewardRequestComponent,
        {
          title: 'Create Reward',
          mode: RewardPageModes.REQUEST_CREATE
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  setInitialValue() {
    this.pageSize = 20;
    const todayDate = new Date();

    this.maxDate = todayDate;
    this.offerToMaxDate = todayDate;
    this.requestMaxDate = todayDate;
    this.validMaxDate = todayDate;

    this.maxDate.setDate(todayDate.getDate() + 365);
    this.offerToMaxDate.setDate(todayDate.getDate() + 365);
    this.requestMaxDate.setDate(todayDate.getDate() + 365);
    this.validMaxDate.setDate(todayDate.getDate() + 365);
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.statusList[0].value],
      offerFromDateFrom: [null],
      offerFromDateTo: [null],
      offerToDateFrom: [null],
      offerToDateTo: [null],
      requestedDateFrom: [null],
      requestedDateTo: [null],
      validDateFrom: [null],
      validDateTo: [null]
    });
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (
      !formValue.offerFromDateFrom &&
      !formValue.offerFromDateTo &&
      !formValue.offerToDateFrom &&
      !formValue.offerToDateTo &&
      !formValue.requestedDateFrom &&
      !formValue.requestedDateTo &&
      !formValue.validDateFrom &&
      !formValue.validDateTo
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      offerFromDateFrom: dateToStringCriteria(formValue.offerFromDateFrom),
      offerFromDateTo: dateToStringCriteria(formValue.offerFromDateTo, false),
      offerToDateFrom: dateToStringCriteria(formValue.offerToDateFrom),
      offerToDateTo: dateToStringCriteria(formValue.offerToDateTo, false),
      requestedDateFrom: dateToStringCriteria(formValue.requestedDateFrom),
      requestedDateTo: dateToStringCriteria(formValue.requestedDateTo, false),
      validDateFrom: dateToStringCriteria(formValue.validDateFrom),
      validDateTo: dateToStringCriteria(formValue.validDateTo, false),
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.offerFromDateTag = null;
    this.offerFromDateStringTag = null;
    this.offerToDateTag = null;
    this.offerToDateStringTag = null;
    this.requestedDateTag = null;
    this.requestedDateStringTag = null;
    this.validDateStringTag = null;
    this.validDateTag = null;

    const offerFromDateFrom = dateStringToTagCriteria(this.criteriaObject.offerFromDateFrom);
    const offerFromDateTo = dateStringToTagCriteria(this.criteriaObject.offerFromDateTo);
    const offerFromDate = generateDateStringTag({
      dateName: 'Offer from Date',
      dateFrom: offerFromDateFrom,
      dateTo: offerFromDateTo
    });

    this.offerFromDateStringTag = offerFromDate.dateStringTag;
    this.offerFromDateTag = offerFromDate.dateTag;

    const offerToDateFrom = dateStringToTagCriteria(this.criteriaObject.offerToDateFrom);
    const offerToDateTo = dateStringToTagCriteria(this.criteriaObject.offerToDateTo);
    const offerToDate = generateDateStringTag({
      dateName: 'Offer to Date',
      dateFrom: offerToDateFrom,
      dateTo: offerToDateTo
    });

    this.offerToDateStringTag = offerToDate.dateStringTag;
    this.offerToDateTag = offerToDate.dateTag;

    const requestedDateFrom = dateStringToTagCriteria(this.criteriaObject.requestedDateFrom);
    const requestedDateTo = dateStringToTagCriteria(this.criteriaObject.requestedDateTo);
    const requestedDate = generateDateStringTag({
      dateName: 'Requested Date',
      dateFrom: requestedDateFrom,
      dateTo: requestedDateTo
    });

    this.requestedDateStringTag = requestedDate.dateStringTag;
    this.requestedDateTag = requestedDate.dateTag;

    const validDateFrom = dateStringToTagCriteria(this.criteriaObject.validDateFrom);
    const validDateTo = dateStringToTagCriteria(this.criteriaObject.validDateTo);
    const validDate = generateDateStringTag({
      dateName: 'Valid Date',
      dateFrom: validDateFrom,
      dateTo: validDateTo
    });

    this.validDateStringTag = validDate.dateStringTag;
    this.validDateTag = validDate.dateTag;
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new RewardRequestListRequestAction(criteriaObj));
  }

  goToView(viewParams?: any) {
    if (viewParams === null) {
      return;
    }

    const initialState = ModuleUtil.InitialState(
      viewParams,
      RewardRequestComponent,
      RewardPageModes.REQUEST_VIEW,
      this.getRewardRequestTitle(RewardPageModes.REQUEST_VIEW),
      TaskModuleUrl.REWARD_REQUEST,
      PortalModule.REWARD
    );

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  goToEdit(item) {
    const initialState = ModuleUtil.InitialState(
      item,
      RewardRequestComponent,
      RewardPageModes.REQUEST_EDIT,
      this.getRewardRequestTitle(RewardPageModes.REQUEST_EDIT),
      TaskModuleUrl.REWARD_REQUEST,
      PortalModule.REWARD
    );

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
    return;
  }

  getRewardRequestTitle(mode: RewardPageModes): string {
    switch (mode) {
      case RewardPageModes.REQUEST_CREATE:
        return 'Create Reward Request';
      case RewardPageModes.REQUEST_EDIT:
        return 'Edit Reward Request';
      case RewardPageModes.REQUEST_VIEW:
      case RewardPageModes.VIEW:
        return 'View Reward Request';
      default:
        return '';
    }
  }

  clearFilterRequestedDate() {
    this.searchForm.controls['requestedDateFrom'].reset();
    this.searchForm.controls['requestedDateTo'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      requestedDateFrom: null,
      requestedDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterOfferToDate() {
    this.searchForm.controls['offerToDateFrom'].reset();
    this.searchForm.controls['offerToDateTo'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      offerToDateFrom: null,
      offerToDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterOfferFromDate() {
    this.searchForm.controls['offerFromDateFrom'].reset();
    this.searchForm.controls['offerFromDateTo'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      offerFromDateFrom: null,
      offerFromDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterValidDate() {
    this.setFirstPage();
    this.searchForm.controls['validDateFrom'].reset();
    this.searchForm.controls['validDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      validDateFrom: null,
      validDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['requestedDateFrom'].reset();
    this.searchForm.controls['requestedDateTo'].reset();
    this.searchForm.controls['offerToDateFrom'].reset();
    this.searchForm.controls['offerToDateTo'].reset();
    this.searchForm.controls['offerFromDateFrom'].reset();
    this.searchForm.controls['offerFromDateTo'].reset();
    this.searchForm.controls['validDateFrom'].reset();
    this.searchForm.controls['validDateTo'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      requestedDateFrom: null,
      requestedDateTo: null,
      offerToDateFrom: null,
      offerToDateTo: null,
      offerFromDateFrom: null,
      offerFromDateTo: null,
      validDateFrom: null,
      validDateTo: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeStatus(event) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onChangeOfferToDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.offerToMinDate = new Date(value);
    } else {
      this.offerToMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeOfferToDateTo(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.offerToMaxDate = new Date(value);
    } else {
      this.offerToMaxDate = new Date();
      this.offerToMaxDate.setDate(this.offerToMaxDate.getDate() + 365);
    }
  }

  onChangeRequestedDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.requestMinDate = new Date(value);
    } else {
      this.requestMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeRequestedDateTo(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.requestMaxDate = new Date(value);
    } else {
      this.requestMaxDate = new Date();
      this.requestMaxDate.setDate(this.requestMaxDate.getDate() + 365);
    }
  }

  onChangeValidDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.validMinDate = new Date(value);
    } else {
      this.validMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeValidDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.validMaxDate = new Date(value);
    } else {
      this.validMaxDate = new Date();
      this.validMaxDate.setDate(this.validMaxDate.getDate() + 365);
    }
  }

  showHistory(request) {
    const initialState = {
      title: 'History',
      action: HistoryType.REQUEST,
      historyHeader: `Request No.: ${request.requestNo}`,
      historyType: HistoryType.REWARD,
      auditLogs: request.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  onDeleteRewardRequest(id: string) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this request?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new RewardRequestDeleteRequestAction(id));
        }
      });
  }

  onCancelRequest(rewardRequestResponse: any) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel reward request number <strong>&quot;${rewardRequestResponse.requestNo}&quot;</strong>?`,
        okText: 'Yes, cancel',
        label: 'Reason',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new RewardRequestCancelRequestedAction({
              id: rewardRequestResponse.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.search(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }
  doDestroy() {}

  getColorStatus(status: string): string {
    return DefaultStatusEnum[status];
  }
}
