import { createSelector } from '@ngrx/store';
import * as fromOrderFixAssetRequestResponseState from '../reducers/order-fix-asset-request.reducers';
import { AppStates } from '../state/app.states';

const selectOrderFixAssetRequestState = (state: AppStates) => state.orderFixAssetRequest;

export const selectAllOrderFixAssetRequestList = createSelector(
  selectOrderFixAssetRequestState,
  fromOrderFixAssetRequestResponseState.selectAll
);

export const selectOrderFixAssetRequestList = createSelector(
  selectOrderFixAssetRequestState,
  (state: fromOrderFixAssetRequestResponseState.OrderFixAssetRequestState) => state
);

export const selectOrderFixAssetRequestListCriteria = createSelector(selectOrderFixAssetRequestState, state => {
  return state.criteriaObject;
});

export const selectOrderFixAssetRequestById = createSelector(
  selectOrderFixAssetRequestState,
  (state: fromOrderFixAssetRequestResponseState.OrderFixAssetRequestState) => state.selected
);

export const selectOrderFixAssetRequestHistory = createSelector(
  selectOrderFixAssetRequestState,
  (state: fromOrderFixAssetRequestResponseState.OrderFixAssetRequestState) => state.auditLogs
);

export const selectOrderFixAssetRequestShelfList = createSelector(
  selectOrderFixAssetRequestState,
  (state: fromOrderFixAssetRequestResponseState.OrderFixAssetRequestState) => state.shelfList
);

export const selectFixAssetDeliveryDetails = createSelector(
  selectOrderFixAssetRequestState,
  (state: fromOrderFixAssetRequestResponseState.OrderFixAssetRequestState) => {
    return state.selected && state.selected.deliveryDetails;
  }
);
