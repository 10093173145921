/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./checkbox.component";
import * as i2 from "./checkbox-group.component";
var styles_CheckboxComponent = [];
var RenderType_CheckboxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CheckboxComponent, data: {} });
export { RenderType_CheckboxComponent as RenderType_CheckboxComponent };
export function View_CheckboxComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleCheck() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "input", [["class", "form-check-input-label checkbox-modal"], ["type", "checkbox"]], [[8, "checked", 0]], null, null, null, null)), i0.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isChecked(); _ck(_v, 1, 0, currVal_0); }); }
export function View_CheckboxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-checkbox", [], null, null, null, View_CheckboxComponent_0, RenderType_CheckboxComponent)), i0.ɵdid(1, 49152, null, 0, i1.CheckboxComponent, [i2.CheckboxGroupComponent], null, null)], null, null); }
var CheckboxComponentNgFactory = i0.ɵccf("app-checkbox", i1.CheckboxComponent, View_CheckboxComponent_Host_0, { value: "value" }, {}, ["*"]);
export { CheckboxComponentNgFactory as CheckboxComponentNgFactory };
