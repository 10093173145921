<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-order"></em>
    {{ data.title }}
  </div>
  <button type="button" class="btn btn-header-button" *ngIf="hasCancelPermission()" (click)="cancelRequest()">
    <em class="icon-forbidden"></em>
    Cancel Request
  </button>
  <button type="button" class="btn btn-header-button" *ngIf="hasDeletePermission()" (click)="deleteRequest()">
    <em class="icon-delete"></em>
    Delete
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="data.mode === pageModeEnum.REQUEST_VIEW && (orderRequestView$ | async)?.status !== 'DRAFT'"
    (click)="showHistory()"
  >
    <em class="icon-history"></em>History
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="order-request" class="admin-form">
  <div class="info-header-container">
    <div class="info-header-item" *ngIf="(orderRequestView$ | async)?.requestNo">
      <div class="info-header-label">
        <span>Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (orderRequestView$ | async)?.requestNo }}</span>
      </div>
    </div>
    <div class="info-header-item" *ngIf="hasOrderRequestRefNo()">
      <div class="info-header-label">
        <span>Reference No.:</span>
      </div>
      <div class="info-header-value">
        <app-reference-document-link
          module="ORDER_REQUEST"
          [text]="(orderRequestView$ | async)?.z8OrderRequestRefNo | dashDisplay"
          [referenceNo]="(orderRequestView$ | async)?.z8OrderRequestRefNo | dashDisplay"
        >
        </app-reference-document-link>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Request Status:</span>
      </div>
      <div class="info-header-value">
        <span
          *ngIf="pageModeEnum.REQUEST_CREATE === data.mode; else getStatus"
          [ngClass]="getColorStatus('DRAFT')"
          class="request-status"
        >
          {{ 'ORDER_REQUEST.STATUS.DRAFT' | translate }}
        </span>
        <ng-template #getStatus>
          <span [ngClass]="getColorStatus((orderRequestView$ | async)?.status)" class="request-status">
            {{ 'ORDER_REQUEST.STATUS.' + (orderRequestView$ | async)?.status | translate }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="information-container">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="flex-column pl-2">
          <div class="info-store-type">
            <em [ngClass]="getStoreIcon((orderRequestView$ | async)?.store.storeType)"></em>
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Store</span>
          </div>
          <div class="info-header-value">
            {{ (orderRequestView$ | async)?.store.storeCodeName }}
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Order Type</span>
          </div>
          <div class="info-header-value">
            {{ 'ORDER_TYPE.' + orderRequest.orderType | translate }}
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Order Subtype</span>
          </div>
          <div class="info-header-value">
            {{ orderRequest?.orderSubType ? ('ORDER_SUBTYPE.' + orderRequest?.orderSubType | translate) : '(None)' }}
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Warehouse</span>
          </div>
          <div class="info-header-value">
            {{ data.warehouseDisplayName ? data.warehouseDisplayName : '-' }}
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Requested Date</span>
          </div>
          <div class="info-header-value" *ngIf="orderRequest.requestedDate; else none">
            {{ orderRequest.requestedDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay }}
          </div>
          <ng-template #none>
            <div class="info-header-value">
              {{ (orderRequestView$ | async)?.requestedDate | dashDisplay: '(None)' }}
            </div>
          </ng-template>
        </div>

        <div class="col-lg-4">
          <div class="total-amount">
            <div class="text-right">
              <span class="info-total">
                {{ orderRequest.amount?.amount | numberFormatDisplay }}
              </span>
              <span class="info-total-currency">
                THB
              </span>
            </div>
            <div class="info-total-detail">
              <span>(Total Amount before VAT: {{ orderRequest.amountExcVat?.amount | numberFormatDisplay }} | </span>
              <span>VAT: {{ orderRequest.vatAmount?.amount | numberFormatDisplay }})</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="searchForm" (ngSubmit)="onSubmitSearch()">
    <div class="d-flex flex-row">
      <div *ngIf="data.mode !== pageModeEnum.REQUEST_VIEW">
        <button
          type="button"
          id="selectShelf"
          class="btn btn-secondary mr-2 my-3"
          *ngIf="orderRequest.orderType === orderTypeEnum.FIRST_LOT_ORDER"
          (click)="openSelectShelf()"
        >
          <em class="icon-plus"></em>
          Select Shelf
        </button>
        <button type="button" id="selectItem" class="btn btn-secondary mr-2 my-3" (click)="openSelectItem()">
          <em class="icon-plus"></em>
          Select Item
        </button>
      </div>

      <app-import-export-button
        [buttons]="buttons"
        (export)="onExportProductItem()"
        (import)="onImportProductItem()"
        class="d-flex align-items-center"
      ></app-import-export-button>

      <div class="d-flex ml-auto">
        <div class="filter-search-box ml-2 my-3">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="form-control searchCriteria"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-standard ml-2 my-3"
          (click)="showShelfDetails()"
          *ngIf="orderRequest.orderType === orderTypeEnum.FIRST_LOT_ORDER"
        >
          <em class="icon-shelf"></em>
          Shelf Details
        </button>
        <button type="button" id="deliveryDetails" class="btn btn-standard ml-2 my-3" (click)="openDeliveryDetails()">
          <em
            class="fa nicon icon-truck mr-2"
            [ngClass]="{
              success: orderRequest.deliveryStatus === 'success',
              error: orderRequest.deliveryStatus === 'error'
            }"
          ></em>
          Delivery Details
        </button>
      </div>
    </div>
  </form>

  <ng-container *ngIf="!filteredFormArray.controls.length && !searchForm.controls['searchCriteria'].value">
    <div class="align-middle">
      <div class="d-flex justify-content-center no-data">
        <img width="240" height="240" src="/assets/images/no-item.svg" alt="no item" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!filteredFormArray.controls.length && searchForm.controls['searchCriteria'].value">
    <div class="align-middle">
      <div class="d-flex justify-content-center no-data">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="no item" />
      </div>
    </div>
  </ng-container>

  <div
    class="table-responsive table-scroll my-3"
    *ngIf="filteredFormArray.controls.length"
    [formGroup]="filteredFormGroup"
  >
    <table id="itemsTable" class="table table-striped table-bordered w-100">
      <thead>
        <tr>
          <th class="text-center align-top padding-x-0" *ngIf="data.mode !== pageModeEnum.REQUEST_VIEW">
            <a (click)="deleteItem()" class="is-link">
              <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
            </a>
          </th>
          <th class="text-center align-top">No.</th>
          <th class="text-center align-top">Product Name</th>
          <th class="text-center align-top">Article</th>
          <th class="text-center align-top">Barcode</th>
          <th class="text-center align-top">Product Type</th>
          <th class="text-center align-top">Unit</th>
          <th class="text-center align-top min-w-160px">Unit Price</th>
          <th class="text-center align-top max-w-150px">Quantity</th>
          <th class="text-center align-top min-w-160px">Amount (THB)</th>
        </tr>
      </thead>

      <tbody formArrayName="productOrderItems">
        <ng-container *ngFor="let item of paging.currentPageData; index as i">
          <ng-container [formGroupName]="i + (currentPage - 1) * pageSize">
            <tr
              [id]="i + (currentPage - 1) * pageSize"
              [ngClass]="{
                'error-background':
                  (item.errors?.isRecall ||
                    item.errors?.isHoldBuySell ||
                    item.errors?.isDelete ||
                    item.errors?.isFreshLite ||
                    item.errors?.isStoreAssortment ||
                    item.errors?.invalidSupplierPrice ||
                    item.errors?.invalidSupplier ||
                    item.errors?.invalidBarcode ||
                    item.errors?.maximumPurchasingPrice?.hasError) &&
                  data.mode !== pageModeEnum.REQUEST_VIEW
              }"
            >
              <td class="text-center padding-x-0 " *ngIf="data.mode !== pageModeEnum.REQUEST_VIEW">
                <a (click)="deleteItem(item.value)" *ngIf="item.value.allowToDelete && !item.errors">
                  <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
                </a>
                <a (click)="deleteItemWithoutConfirmation(item.value)" *ngIf="item.errors">
                  <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
                </a>
              </td>
              <td class="text-center">{{ paging.getItemNo(i) }}</td>
              <td class="text-left align-top">
                {{ item.value.productName }}

                <span
                  class="d-block invalid-feedback text-danger"
                  *ngIf="item.errors?.isRecall || item.errors?.isHoldBuySell"
                >
                  {{ 'ERRORS.TEMPORARY_SUSPEND' | translate }}
                </span>
                <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isDelete">
                  {{ 'ERRORS.STATUS_IS_DELISTED' | translate }}
                </span>
                <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isFreshLite">
                  {{ 'ERRORS.INVALID_FRESH_LITE' | translate }}
                </span>
                <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isStoreAssortment">
                  {{ 'ERRORS.INVALID_STORE_ASSORTMENT' | translate }}
                </span>
                <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.invalidSupplierPrice">
                  {{ 'ERRORS.INVALID_SUPPLIER_PRICE' | translate }}
                </span>
                <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.invalidSupplier">
                  {{ 'ERRORS.INVALID_SUPPLIER' | translate }}
                </span>
                <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.invalidBarcode">
                  {{ 'ERRORS.INVALID_BARCODE' | translate }}
                </span>
                <span
                  class="d-block invalid-feedback text-danger"
                  *ngIf="item.errors?.maximumPurchasingPrice?.hasError"
                >
                  {{
                    'ERRORS.MAXIMUM_PURCHASING_PRICE'
                      | translate: { price: item.errors?.maximumPurchasingPrice.price }
                  }}
                </span>
              </td>
              <td>{{ item.value.articleNo }}</td>
              <td>{{ item.value.barcode }}</td>
              <td>{{ 'PRODUCT_ASSORTMENT.PRODUCT_TYPE.' + item.value.productType | translate }}</td>
              <td class="text-center">{{ item.value.unit }}</td>
              <td class="text-right">{{ item.value.unitPrice.amount | numberFormatDisplay }}</td>
              <td class="text-center td-input-column-150">
                <app-numeric-textbox
                  id="{{ i + (currentPage - 1) * pageSize }}"
                  class="td-numeric-textbox-center"
                  formControlName="qty"
                  [negativeSign]="false"
                  placeholder="Quantity"
                  [format]="'0'"
                  [decimals]="0"
                  maxlength="5"
                  [minValue]="1"
                  [maxValue]="99999"
                  [ngClass]="{
                    'is-invalid': submitted && (item?.get('qty').errors?.isZero || item?.get('qty').errors?.required)
                  }"
                  [disabled]="
                    (item.errors?.isRecall ||
                      item.errors?.isHoldBuySell ||
                      item.errors?.isDelete ||
                      item.errors?.isFreshLite ||
                      item.errors?.isStoreAssortment ||
                      item.errors?.invalidSupplierPrice ||
                      item.errors?.invalidSupplier ||
                      item.errors?.maximumPurchasingPrice?.hasError) &&
                    data.mode !== pageModeEnum.REQUEST_VIEW
                  "
                  (blur)="updateValueChangesEvent($event, item.value)"
                >
                </app-numeric-textbox>
                <div *ngIf="submitted && item?.get('qty').errors" class="invalid-display text-danger">
                  <div *ngIf="item?.get('qty').errors?.isZero">
                    {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                  </div>
                  <div *ngIf="item?.get('qty').errors.required">
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                </div>
              </td>
              <td class="text-right total-amount-bg">
                {{ item.value.amount.amount | numberFormatDisplay }}
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>

  <!--pagination-->
  <app-ui-pagination
    #paging
    [(currentPage)]="currentPage"
    [(pageSize)]="pageSize"
    [items]="filteredFormArray.controls"
    [valueChangeDetector]="filteredFormArray.valueChanges | async"
  >
  </app-ui-pagination>

  <!--footer-->
  <ng-container *ngIf="hasAtLeastOnePermission()">
    <ng-container *ngIf="data.mode !== pageModeEnum.REQUEST_VIEW; else otherMode">
      <div class="fixed-row-bottom form-row">
        <div class="float-left" *ngIf="hasSavePermission()">
          <button type="button" id="btn-save" class="btn btn-secondary" (click)="onSave()" [disabled]="isLoading">
            Save
          </button>
        </div>
        <div class="float-right" *ngIf="hasSubmitPermission()">
          <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">Cancel</button>
          <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()" [disabled]="isLoading">
            Submit
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #otherMode>
      <ng-container>
        <div class="fixed-row-bottom form-row">
          <div class="float-left"></div>
          <div class="float-right">
            <span *ngIf="hasEditPermission()" class="mr-2">
              <button type="button" id="btn-edit" class="btn btn-primary" (click)="onTriggerEdit()">Edit</button>
            </span>
            <span *ngIf="hasApprovePermission()">
              <button
                type="button"
                id="btn-reject"
                class="btn btn-special-reject mr-2"
                (click)="doApproveRejectRequest(false)"
              >
                Reject
              </button>
              <button
                type="button"
                id="btn-approve"
                class="btn btn-special-approve"
                (click)="doApproveRejectRequest(true)"
              >
                Approve
              </button>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <!--End footer-->
</div>
