import { createSelector } from '@ngrx/store';
import * as fromShelfFixAssetListResponseState from '../reducers/shelf-select-fix-asset.reducers';
import { AppStates } from '../state/app.states';

const selectShelfSelectFixAssetListState = (state: AppStates) => state.shelfSelectFixAsset;

export const selectAllShelfSelectFixAssetList = createSelector(
  selectShelfSelectFixAssetListState,
  fromShelfFixAssetListResponseState.selectAll
);

export const selectShelfFixAssetList = createSelector(
  selectShelfSelectFixAssetListState,
  (state: fromShelfFixAssetListResponseState.ShelfSelectFixAssetState) => state
);

export const selectShelfSelectFixAssetListCriteria = createSelector(selectShelfSelectFixAssetListState, state => {
  return state.criteriaObject;
});
