import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  StockTransferList,
  StockTransferListSearchCriteria,
  StockTransferViewResponse
} from '../../models/stock-transfer.model';
import { StockTransferActions, StockTransferActionType } from '../actions/stock-transfer.actions';
import { BaseState } from '../state/base.state';

export interface StockTransferState extends EntityState<StockTransferList>, BaseState {
  criteriaObject: StockTransferListSearchCriteria;
  totalElements: number;
  totalPages: number;
  stockTransfer: StockTransferViewResponse;
}

export const adapter: EntityAdapter<StockTransferList> = createEntityAdapter<StockTransferList>();

export const initialStockTransferResponseState: StockTransferState = adapter.getInitialState({
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  stockTransfer: null
});

export function stockTransferReducers(
  state = initialStockTransferResponseState,
  action: StockTransferActions
): StockTransferState {
  switch (action.type) {
    case StockTransferActionType.STOCK_TRANSFER_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case StockTransferActionType.STOCK_TRANSFER_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case StockTransferActionType.STOCK_TRANSFER_VIEW_RESPONSE:
      return {
        ...state,
        stockTransfer: action.payload
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
