<div [formGroup]="productDetailsForm" *ngIf="productDetailsForm">
  <div class="row section-header">
    <span>Product</span>
  </div>

  <div class="row mb-3">
    <div class="col-md-3">
      <label for="articleNo">Article Number<span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        maxlength="25"
        id="articleNo"
        placeholder="Article Number"
        trim="blur"
        formControlName="articleNo"
        appAlphanumericOnly
        [ngClass]="{
          'is-invalid': isShowDuplicatedError(productDetailsForm.controls.articleNo)
        }"
      />

      <div
        *ngIf="(isSaveDraft || isSubmitted) && productDetailsForm.controls.articleNo.errors"
        class="invalid-feedback"
      >
        <div *ngIf="productDetailsForm.controls.articleNo.errors?.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>

      <div *ngIf="productDetailsForm.controls.articleNo.errors?.duplicated" class="invalid-feedback">
        <div *ngIf="productDetailsForm.controls.articleNo.errors?.duplicated">
          Article Number is duplicated in the system.
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <label for="supplierRefNumber">Supplier Ref. Number</label>
      <input
        type="text"
        class="form-control"
        maxlength="25"
        id="supplierRefNumber"
        placeholder="Supplier Ref. Number"
        trim="blur"
        formControlName="supplierRefNumber"
        [ngClass]="{ 'bg-h-change': isRequestViewMode && listOfChange['supplierRefNumber'] }"
      />
    </div>

    <div class="col-md-3">
      <label for="productStatus"
        >Product Status<span
          *ngIf="[assortmentPageModes.ASSORTMENT_EDIT, assortmentPageModes.ASSORTMENT_VIEW].includes(mode)"
          class="text-danger"
          >*</span
        ></label
      >
      <ng-select
        id="productStatus"
        placeholder="Please select..."
        [items]="listOfValue['productStatus']"
        [searchable]="false"
        [clearable]="false"
        bindLabel="label"
        bindValue="value"
        formControlName="productStatus"
        [ngClass]="{ 'bg-h-change': isRequestViewMode && listOfChange['productStatus'] }"
      >
      </ng-select>
      <div *ngIf="isSubmitted && productDetailsForm.controls.productStatus.errors" class="invalid-feedback">
        <div *ngIf="productDetailsForm.controls.productStatus.errors?.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <div class="mb-3">
        <label for="productName">Product Name<span class="text-danger">*</span></label>
        <textarea
          rows="4"
          cols="50"
          class="form-control h-auto"
          maxlength="100"
          id="productName"
          trim="blur"
          placeholder="Product Name"
          formControlName="productName"
          [ngClass]="{
            'is-invalid': (isSaveDraft || isSubmitted) && productDetailsForm.controls.productName.errors,
            'bg-h-change': isRequestViewMode && listOfChange['productName']
          }"
        >
        </textarea>
        <div
          *ngIf="(isSaveDraft || isSubmitted) && productDetailsForm.controls.productName.errors"
          class="invalid-feedback"
        >
          <div *ngIf="productDetailsForm.controls.productName.errors?.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="productDisplayName">Product Display Name<span class="text-danger">*</span></label>
        <input
          type="text"
          class="form-control"
          maxlength="25"
          id="productDisplayName"
          trim="blur"
          placeholder="Product Display Name"
          formControlName="productDisplayName"
          [ngClass]="{
            'is-invalid': isSubmitted && productDetailsForm.controls.productDisplayName.errors,
            'bg-h-change': isRequestViewMode && listOfChange['productDisplayName']
          }"
        />
        <div *ngIf="isSubmitted && productDetailsForm.controls.productDisplayName.errors" class="invalid-feedback">
          <div *ngIf="productDetailsForm.controls.productDisplayName.errors?.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6" *ngIf="!isNonMerchandise">
          <label for="brand">Brand<span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            id="brand"
            maxlength="50"
            trim="blur"
            placeholder="Brand"
            formControlName="brand"
            [ngClass]="{
              'is-invalid': isSubmitted && productDetailsForm.controls.brand.errors,
              'bg-h-change': isRequestViewMode && listOfChange['brand']
            }"
          />
          <div *ngIf="isSubmitted && productDetailsForm.controls.brand.errors" class="invalid-feedback">
            <div *ngIf="productDetailsForm.controls.brand.errors?.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>

        <div [ngClass]="{ col: isNonMerchandise, 'col-md-6': !isNonMerchandise }">
          <label for="vatNonMer">VAT<span class="text-danger">*</span></label>
          <ng-select
            id="vatNonMer"
            placeholder="Please select..."
            [items]="listOfValue['trueFalse']"
            [searchable]="false"
            [clearable]="false"
            bindLabel="label"
            bindValue="value"
            formControlName="vat"
            [ngClass]="{
              'is-invalid': isSubmitted && productDetailsForm.controls.vat.errors,
              'bg-h-change': isRequestViewMode && listOfChange['vat']
            }"
          >
          </ng-select>
          <div *ngIf="isSubmitted && productDetailsForm.controls.vat.errors?.required" class="invalid-display">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="row">
        <div id="productImages" class="d-flex flex-row">
          <app-file-upload
            formControlName="productImages"
            [allowedTypes]="'(png|jpe?g)'"
            [withMeta]="true"
            [multiple]="false"
            [size]="500000"
            [controlName]="'productImages'"
            [allowedExt]="'(.png|.jpe?g)'"
            [uploadUrl]="uploadUrl"
            [uploadHeaders]="uploadHeaders"
            [baseStorageUrl]="baseStorageUrl"
            [isNew]="isRequestViewMode && listOfChange['productImages']"
            (showImage)="showImage(productDetailsForm.controls.productImages.value)"
          ></app-file-upload>
          <div class="d-flex align-items-end ml-2">
            <div class="image-description">
              <div>
                Format file .jpg, .jpeg, .png
              </div>
              <div>
                Size up to 500 KB (Recommended upload size of 500 * 500 pixels.)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3" *ngIf="!isNonMerchandise">
    <div class="col-md-3">
      <label for="shelfLife">Shelf Life (Day)<span class="text-danger">*</span></label>
      <app-numeric-textbox
        [format]="'0,0'"
        [decimals]="0"
        maxlength="5"
        id="shelfLife"
        placeholder="Day"
        formControlName="shelfLife"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.shelfLife.errors,
          'bg-h-change': isRequestViewMode && listOfChange['shelfLife']
        }"
      >
      </app-numeric-textbox>
      <div *ngIf="isSubmitted && productDetailsForm.controls.shelfLife.errors?.required" class="invalid-display">
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>

    <div class="col-md-3">
      <label for="minimumShelfLife">Minimum Shelf Life (Day)<span class="text-danger">*</span></label>
      <app-numeric-textbox
        [format]="'0,0'"
        [decimals]="0"
        maxlength="5"
        id="minimumShelfLife"
        placeholder="Day"
        formControlName="minimumShelfLife"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.minimumShelfLife.errors,
          'bg-h-change': isRequestViewMode && listOfChange['minimumShelfLife']
        }"
      >
      </app-numeric-textbox>
      <div
        *ngIf="isSubmitted && productDetailsForm.controls.minimumShelfLife.errors?.required"
        class="invalid-display"
      >
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>

    <div class="col-md-3">
      <div class="row">
        <div class="col-md-6">
          <label for="standardGp">Standard GP(%)<span class="text-danger">*</span></label>
          <app-numeric-textbox
            [format]="'0,0%'"
            [decimals]="0"
            id="standardGp"
            placeholder="%"
            formControlName="standardGp"
            maxlength="3"
            [ngClass]="{
              'is-invalid': isSubmitted && productDetailsForm.controls.standardGp.errors,
              'bg-h-change': isRequestViewMode && listOfChange['standardGp']
            }"
          >
          </app-numeric-textbox>
          <div *ngIf="isSubmitted && productDetailsForm.controls.standardGp.errors?.required" class="invalid-display">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
        <div class="col-md-6">
          <label for="productTier">Product Tier<span class="text-danger">*</span></label>
          <ng-select
            id="productTier"
            placeholder="Please select..."
            [items]="listOfValue['productTier']"
            [searchable]="false"
            [clearable]="false"
            bindLabel="label"
            bindValue="value"
            formControlName="productTier"
            [ngClass]="{
              'is-invalid': isSubmitted && productDetailsForm.controls.productTier.errors,
              'bg-h-change': isRequestViewMode && listOfChange['productTier']
            }"
          >
          </ng-select>
          <div
            *ngIf="isSubmitted && productDetailsForm.controls.productTier.errors?.required"
            class="invalid-display"
          >
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <label for="productStandard">Product Standard<span class="text-danger">*</span></label>
      <ng-select
        id="productStandard"
        placeholder="Please select..."
        [items]="listOfValue['productStandard']"
        [searchable]="false"
        [clearable]="false"
        bindLabel="label"
        bindValue="value"
        formControlName="productStandard"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.productStandard.errors,
          'bg-h-change': isRequestViewMode && listOfChange['productStandard']
        }"
      >
      </ng-select>
      <div
        *ngIf="isSubmitted && productDetailsForm.controls.productStandard.errors?.required"
        class="invalid-display"
      >
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>
  </div>

  <div class="row mb-3" *ngIf="!isNonMerchandise">
    <div class="col-md-3">
      <label for="cbgProduct">CBG Product<span class="text-danger">*</span></label>
      <ng-select
        id="cbgProduct"
        placeholder="Please select..."
        [items]="listOfValue['cbgProduct']"
        [searchable]="false"
        [clearable]="false"
        bindLabel="label"
        bindValue="value"
        formControlName="cbgProduct"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.cbgProduct.errors,
          'bg-h-change': isRequestViewMode && listOfChange['cbgProduct']
        }"
      >
      </ng-select>
      <div *ngIf="isSubmitted && productDetailsForm.controls.cbgProduct.errors?.required" class="invalid-display">
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>

    <div class="col-md-3">
      <label for="exclusiveProduct">Exclusive Product</label>
      <ng-select
        id="exclusiveProduct"
        placeholder="Please select..."
        [items]="listOfValue['trueFalse']"
        [searchable]="false"
        [clearable]="false"
        bindLabel="label"
        bindValue="value"
        formControlName="exclusiveProduct"
        [ngClass]="{ 'bg-h-change': isRequestViewMode && listOfChange['exclusiveProduct'] }"
      >
      </ng-select>
    </div>

    <div class="col-md-3">
      <label for="productGrading">Product Grading<span class="text-danger">*</span></label>
      <ng-select
        id="productGrading"
        placeholder="Please select..."
        [items]="listOfValue['productGrading']"
        [searchable]="false"
        [clearable]="false"
        bindLabel="code"
        bindValue="code"
        formControlName="productGrading"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.productGrading.errors,
          'bg-h-change': isRequestViewMode && listOfChange['productGrading']
        }"
      >
      </ng-select>
      <div *ngIf="isSubmitted && productDetailsForm.controls.productGrading.errors?.required" class="invalid-display">
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>
    <div class="col-md-3">
      <label for="productRecommend">Product Recommend<span class="text-danger">*</span></label>
      <ng-select
        id="productRecommend"
        placeholder="Please select..."
        [items]="listOfValue['trueFalse']"
        [searchable]="false"
        [clearable]="false"
        bindLabel="label"
        bindValue="value"
        formControlName="productRecommend"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.productRecommend.errors,
          'bg-h-change': isRequestViewMode && listOfChange['productRecommend']
        }"
      >
      </ng-select>
      <div
        *ngIf="isSubmitted && productDetailsForm.controls.productRecommend.errors?.required"
        class="invalid-display"
      >
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>
  </div>

  <div class="row mb-3" *ngIf="!isNonMerchandise">
    <div class="col-md-3">
      <label for="estSalesQty">Estimated Sales Quantity (Per Month)</label>
      <app-numeric-textbox
        [format]="'0,0'"
        [decimals]="0"
        id="estSalesQty"
        placeholder="Qty"
        formControlName="estSalesQty"
        maxlength="10"
        [ngClass]="{ 'bg-h-change': isRequestViewMode && listOfChange['estSalesQty'] }"
      >
      </app-numeric-textbox>
    </div>

    <div class="col-md-3">
      <label for="estSalesValue">Estimated Sales Value (Per Month)</label>
      <app-numeric-textbox
        [format]="'0,0.00'"
        [maxValue]="9999999999.99"
        id="estSalesValue"
        placeholder="Value"
        formControlName="estSalesValue"
        [ngClass]="{ 'bg-h-change': isRequestViewMode && listOfChange['estSalesValue'] }"
      >
      </app-numeric-textbox>
    </div>

    <div class="col-md-6">
      <label for="sellingChannel">Other Selling Channel<span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        id="sellingChannel"
        maxlength="50"
        trim="blur"
        formControlName="sellingChannel"
        placeholder="Other Selling Channel"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.sellingChannel.errors,
          'bg-h-change': isRequestViewMode && listOfChange['sellingChannel']
        }"
      />
      <div *ngIf="isSubmitted && productDetailsForm.controls.sellingChannel.errors" class="invalid-feedback">
        <div *ngIf="productDetailsForm.controls.sellingChannel.errors?.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3" *ngIf="!isNonMerchandise">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-6">
          <label for="restrictedItem">Restricted Item</label>
          <ng-select
            id="restrictedItem"
            placeholder="Please select..."
            [items]="listOfValue['trueFalse']"
            [searchable]="false"
            [clearable]="false"
            bindLabel="label"
            bindValue="value"
            formControlName="restrictedItem"
            [ngClass]="{ 'bg-h-change': isRequestViewMode && listOfChange['restrictedItem'] }"
          >
          </ng-select>
        </div>
        <div class="col-md-6">
          <label for="restrictedAlcohol">Restricted Alcohol Sale Times<span class="text-danger">*</span></label>
          <ng-select
            id="restrictedAlcohol"
            placeholder="Please select..."
            [items]="listOfValue['restrictedAlcohol']"
            [searchable]="false"
            [clearable]="false"
            bindLabel="label"
            bindValue="value"
            formControlName="restrictedAlcohol"
            [ngClass]="{
              'is-invalid': isSubmitted && productDetailsForm.controls.restrictedAlcohol.errors,
              'bg-h-change': isRequestViewMode && listOfChange['restrictedAlcoholSaleTime']
            }"
          >
          </ng-select>
          <div *ngIf="isSubmitted && productDetailsForm.controls.restrictedAlcohol.errors" class="invalid-feedback">
            <div *ngIf="productDetailsForm.controls.restrictedAlcohol.errors?.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>

      <label class="image-description">Remark: Restricted item not allow to use promotion and voucher.</label>
    </div>

    <div class="col-md-3">
      <label for="allowToDestroy">Allow To Destroy<span class="text-danger">*</span></label>
      <ng-select
        id="allowToDestroy"
        placeholder="Please select..."
        [items]="listOfValue['trueFalse']"
        [searchable]="false"
        [clearable]="false"
        bindLabel="label"
        bindValue="value"
        formControlName="allowToDestroy"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.allowToDestroy.errors,
          'bg-h-change': isRequestViewMode && listOfChange['allowToDestroy']
        }"
      >
      </ng-select>
      <div *ngIf="isSubmitted && productDetailsForm.controls.allowToDestroy.errors?.required" class="invalid-display">
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>
  </div>

  <hr class="section-divider" />

  <div class="row section-header">
    <span>Category & Attribute</span>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <label for="segment">Segment<span class="text-danger">*</span></label>
      <ng-select
        id="segment"
        placeholder="Please select..."
        [items]="listOfValue[assortmentAllType.SEGMENT]"
        [searchable]="false"
        [clearable]="false"
        bindLabel="nameEn"
        bindValue="code"
        formControlName="segment"
        (change)="onChangeSelectDropdown($event, assortmentAllType.SEGMENT, assortmentGroup.TYPE)"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.segment.errors,
          'bg-h-change': isRequestViewMode && listOfChange['segment']
        }"
      >
      </ng-select>
      <div *ngIf="isSubmitted && productDetailsForm.controls.segment.errors?.required" class="invalid-display">
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>

    <div class="col-md-6">
      <label for="family">Family<span class="text-danger">*</span></label>
      <ng-select
        id="family"
        placeholder="Please select..."
        [items]="listOfValue[assortmentAllType.FAMILY]"
        [searchable]="false"
        [clearable]="false"
        bindLabel="nameEn"
        bindValue="code"
        formControlName="family"
        (change)="onChangeSelectDropdown($event, assortmentAllType.FAMILY, assortmentGroup.TYPE)"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.family.errors,
          'bg-h-change': isRequestViewMode && listOfChange['family']
        }"
      >
      </ng-select>
      <div *ngIf="isSubmitted && productDetailsForm.controls.family.errors?.required" class="invalid-display">
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <label for="classCode">Class<span class="text-danger">*</span></label>
      <ng-select
        id="classCode"
        [items]="listOfValue[assortmentAllType.CLASS]"
        placeholder="Please select..."
        [searchable]="false"
        [clearable]="false"
        bindLabel="nameEn"
        bindValue="code"
        formControlName="classCode"
        (change)="onChangeSelectDropdown($event, assortmentAllType.CLASS, assortmentGroup.TYPE)"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.classCode.errors,
          'bg-h-change': isRequestViewMode && listOfChange['classCode']
        }"
      >
      </ng-select>
      <div *ngIf="isSubmitted && productDetailsForm.controls.classCode.errors?.required" class="invalid-display">
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>
    <div class="col-md-6">
      <label for="subClass">Subclass<span class="text-danger">*</span></label>
      <ng-select
        id="subClass"
        placeholder="Please select..."
        [items]="listOfValue[assortmentAllType.SUBCLASS]"
        [searchable]="false"
        [clearable]="false"
        bindLabel="nameEn"
        bindValue="code"
        formControlName="subClass"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.subClass.errors,
          'bg-h-change': isRequestViewMode && listOfChange['subClass']
        }"
      >
      </ng-select>
      <div *ngIf="isSubmitted && productDetailsForm.controls.subClass.errors?.required" class="invalid-display">
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>
  </div>
  <div class="row mb-3" *ngIf="!isNonMerchandise">
    <div class="col-md-3">
      <div class="row">
        <div class="col-md-6">
          <label for="size">Size</label>
          <app-numeric-textbox
            [format]="'0,0'"
            [decimals]="0"
            [maxValue]="9999999999"
            id="size"
            placeholder="Size"
            formControlName="size"
            [ngClass]="{ 'bg-h-change': isRequestViewMode && listOfChange['size'] }"
          >
          </app-numeric-textbox>
        </div>
        <div class="col-md-6">
          <label for="uom">Unit of Measurement</label>
          <ng-select
            id="uom"
            [items]="listOfValue[assortmentAllType.UOM]"
            placeholder="Please select..."
            [searchable]="false"
            [clearable]="false"
            bindLabel="nameEn"
            bindValue="code"
            formControlName="uom"
            [ngClass]="{ 'bg-h-change': isRequestViewMode && listOfChange['uom'] }"
          >
          </ng-select>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <label for="flavor">Flavor</label>
      <input
        type="text"
        class="form-control"
        formControlName="flavor"
        id="flavor"
        trim="blur"
        maxlength="25"
        placeholder="Flavor"
        [ngClass]="{ 'bg-h-change': isRequestViewMode && listOfChange['flavor'] }"
      />
    </div>

    <div class="col-md-3">
      <label for="gradingGroup">Grading Group</label>
      <ng-select
        id="gradingGroup"
        [items]="listOfValue[assortmentAllType.GRADINGGROUP]"
        [searchable]="false"
        [clearable]="false"
        bindLabel="nameEn"
        bindValue="code"
        formControlName="gradingGroup"
        placeholder="Please select..."
        [ngClass]="{ 'bg-h-change': isRequestViewMode && listOfChange['gradingGroup'] }"
      >
      </ng-select>
    </div>

    <div class="col-md-3">
      <label for="productLocationGuideline">Product Location Guideline<span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        formControlName="productLocationGuideline"
        id="productLocationGuideline"
        maxlength="5"
        trim="blur"
        placeholder="Product Location Guideline"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.productLocationGuideline.errors,
          'bg-h-change': isRequestViewMode && listOfChange['productLocationGuideline']
        }"
      />
      <div
        *ngIf="isSubmitted && productDetailsForm.controls.productLocationGuideline.errors?.required"
        class="invalid-display"
      >
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>
  </div>

  <hr class="section-divider" />

  <div class="row section-header">
    <span>Ordering & Delivery</span>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <label for="productType">Product Type<span class="text-danger">*</span></label>
      <ng-select
        id="productType"
        placeholder="Please select..."
        [items]="listOfValue['productType']"
        [searchable]="false"
        [clearable]="false"
        bindLabel="label"
        bindValue="value"
        formControlName="productType"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.productType.errors,
          'bg-h-change': isRequestViewMode && listOfChange['productType']
        }"
      >
      </ng-select>
      <div *ngIf="submitted && productDetailsForm.controls.productType.errors?.required" class="invalid-display">
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>
    <div class="col-md-6">
      <label for="orderingMethods">Ordering Method<span class="text-danger">*</span></label>
      <ng-select
        id="orderingMethods"
        placeholder="Please select..."
        [items]="listOfValue['orderingMethods']"
        [searchable]="false"
        [clearable]="false"
        bindLabel="label"
        bindValue="value"
        formControlName="orderingMethods"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.orderingMethods.errors,
          'bg-h-change': isRequestViewMode && listOfChange['orderingMethods']
        }"
      >
      </ng-select>
      <div
        *ngIf="isSubmitted && productDetailsForm.controls.orderingMethods.errors?.required"
        class="invalid-display"
      >
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-6">
      <label for="freshLiteCategory">Fresh Lite Category<span class="text-danger">*</span></label>
      <ng-select
        id="freshLiteCategory"
        placeholder="Please select..."
        [items]="listOfValue['freshLiteList']"
        [searchable]="false"
        [clearable]="false"
        bindLabel="name"
        formControlName="freshLiteCategory"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.freshLiteCategory.errors,
          'bg-h-change': isRequestViewMode && listOfChange['freshLiteCategoryCode']
        }"
      >
      </ng-select>
      <div
        *ngIf="isSubmitted && productDetailsForm.controls.freshLiteCategory.errors?.required"
        class="invalid-display"
      >
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
      <div class="input-label" *ngIf="productDetailsForm.controls.freshLiteCategory.enabled">
        <a id="add" href="javascript:void(0)" class="btn-text-link" (click)="showModalFreshLiteCategory()">
          +Add Category
        </a>
      </div>
    </div>

    <div class="col-md-6">
      <label for="freshLiteType">Fresh Lite Type</label>
      <input
        type="text"
        class="form-control"
        id="freshLiteType"
        maxlength="50"
        formControlName="freshLiteType"
        placeholder="Fresh Lite Type"
      />
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-md-3">
      <label for="deliveryMethod">Delivery Method<span class="text-danger">*</span></label>
      <ng-select
        id="deliveryMethod"
        placeholder="Please select..."
        [items]="listOfValue['deliveryMethod']"
        [searchable]="false"
        [clearable]="false"
        bindLabel="label"
        bindValue="value"
        formControlName="deliveryMethod"
        [ngClass]="{
          'is-invalid': isSubmitted && productDetailsForm.controls.deliveryMethod.errors,
          'bg-h-change': isRequestViewMode && listOfChange['deliveryMethod']
        }"
      >
      </ng-select>
      <div *ngIf="isSubmitted && productDetailsForm.controls.deliveryMethod.errors?.required" class="invalid-display">
        {{ 'ERRORS.REQUIRED' | translate }}
      </div>
    </div>
    <div
      class="d-flex align-items-center mt-4"
      *ngIf="
        [
          assortmentPageModes.ASSORTMENT_EDIT,
          assortmentPageModes.REQUEST_CREATE,
          assortmentPageModes.REQUEST_EDIT
        ].includes(mode) && assortmentDeliveryMethodEnum.TD === productDetailsForm.controls.deliveryMethod.value
      "
    >
      <a class="btn-text-link mr-1 " (click)="addSelectWarehouse()">+Select Warehouse</a>
    </div>
  </div>
  <div
    class="table-responsive"
    *ngIf="productDetailsForm.controls.warehouses.value && productDetailsForm.controls.warehouses.value.length > 0"
  >
    <table class="table table-striped table-bordered table-hover w-100">
      <thead>
        <tr class="text-center">
          <th scope="col" class="w-5">No.</th>
          <th scope="col">Warehouse</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let warehouse of productDetailsForm.controls.warehouses.value; index as i"
          [ngClass]="{
            'bg-h-change': isRequestViewMode && isWarehouseChanged(warehouse.wmsCode)
          }"
        >
          <td class="text-center">{{ i + 1 }}</td>
          <td>
            {{ warehouse.warehouseNameDisplay }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
