<!-- Modal Money In Section -->
<div
  id="redeemTransactionModal"
  class="modal fade barcodeModal admin-form"
  bsModal
  #redeemTransactionModal="bs-modal"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <h6 class="modal-title pull-left">
            Redeem Transaction Report
          </h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeExportModal()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body my-2 pt-0">
          <div class="container" [formGroup]="exportRedeemTransactionForm">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group col-md-12">
                    <div class="form-row">
                      <div class="col-12">Transaction date</div>
                      <div class="col-md-6">
                        <label>{{ 'FROM' | translate }}<span class="text-danger">*</span></label>
                        <input
                          id="redeemTransactionDateFrom"
                          formControlName="dateFrom"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeExportRedeemTranDateFrom($event)"
                          readonly
                        />
                        <label for="redeemTransactionDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                      <div class="col-md-6">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="redeemTransactionDateTo"
                          formControlName="dateTo"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [minDate]="minDate"
                          [maxDate]="toMaxDate"
                          [bsConfig]="bsDateConfig"
                          (bsValueChange)="onChangeExportRedeemTranDateTo($event)"
                          readonly
                        />
                        <label for="redeemTransactionDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group  col-md-12">
                    <div class="mb-2">
                      Store
                    </div>
                    <app-dropdown-multiple-select
                      [parentForm]="exportRedeemTransactionForm"
                      controlName="no"
                      [items]="storeList"
                      bindValue="no"
                      bindLabel="storeCodeName"
                    >
                    </app-dropdown-multiple-select>
                  </div>

                  <div *ngIf="responseExportError" class="alert-danger form-group mb-0">
                    {{ responseExportError }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            id="export_redeem_tran_report_btn"
            [disabled]="exportFormInValid"
            (click)="onExport()"
            appPreventDoubleClick
          >
            Export
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- End Modal Product Section -->
