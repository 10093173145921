import { createSelector } from '@ngrx/store';
import * as fromOrderInventorySelectItemOrderState from '../reducers/order-inventory-select-item-order.reducers';
import { AppStates } from '../state/app.states';

const selectOrderInventorySelectItemOrderState = (state: AppStates) => state.orderInventorySelectItemOrder;

export const getOrderInventorySelectItemOrderById = articleNo =>
  createSelector(selectOrderInventorySelectItemOrderState, orders => orders.entities[articleNo]);

export const getCountOrderInventorySelectItemOrder = createSelector(
  selectOrderInventorySelectItemOrderState,
  fromOrderInventorySelectItemOrderState.selectTotal
);

export const selectAllOrderInventorySelectItemOrder = createSelector(
  selectOrderInventorySelectItemOrderState,
  fromOrderInventorySelectItemOrderState.selectAll
);

export const selectOrderInventoryUpdated = createSelector(
  selectOrderInventorySelectItemOrderState,
  orders => orders.skipUpdated
);
