import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { SearchProductModalComponent } from '../../../shared/components/search-product-modal/search-product-modal.component';
import { isZeroValidator } from '../../../shared/custom-validators/is-zero-validator';
import { ProductRestrictEnum, ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import { RewardPageModes } from '../../../shared/enum/reward.enum';
import { BarcodeResponse } from '../../../shared/models/barcode.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { ConditionPurchase, RewardRequestResponse } from '../../../shared/models/reward.model';
import { selectRewardRequest, selectRewardValidate } from '../../../shared/store/selectors/reward-request.selectors';
import { selectReward } from '../../../shared/store/selectors/reward.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { amountToNumber } from '../../../shared/utils/number-util';
import { applyProductTableItemError } from '../../../shared/utils/product-items-util';

@Component({
  selector: 'app-supplier-coupon',
  templateUrl: './supplier-coupon.component.html',
  styleUrls: ['./supplier-coupon.component.scss']
})
export class SupplierCouponComponent implements OnInit, OnDestroy {
  @ViewChild('searchProductModal', { static: false }) searchProductModal: SearchProductModalComponent;
  @Input() parentForm: FormGroup;
  @Input() mode: RewardPageModes;
  @Input() saved: boolean;
  @Input() submitted: boolean;
  @Input() conditionPurchase: ConditionPurchase;
  @Input() maximumRow: number;
  @Input() isEditFirst: boolean;
  public rewardRequestView$: Observable<RewardRequestResponse>;
  private localStore: Observable<any>;
  public applyToSelect = filterDropdown.rewardApplyTo;
  productType?: ProductTypeEnum = ProductTypeEnum.INVENTORY;
  allowRestrict = ProductRestrictEnum.NO;

  public isRequestViewMode = false;
  constructor(public fb: FormBuilder, protected readonly store: Store<AppStates>) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.parentForm.addControl('supplierCouponDetails', this.createForm());
    this.parentForm.addControl('supplierCouponItems', this.fb.array([]));
    this.initSelectLocalStore();
    if ([RewardPageModes.REQUEST_VIEW, RewardPageModes.REQUEST_EDIT, RewardPageModes.VIEW].includes(this.mode)) {
      this.setFormValue();
    }

    if (this.mode === RewardPageModes.REQUEST_VIEW || this.mode === RewardPageModes.VIEW) {
      this.supplierCouponDetails.disable();
      this.isRequestViewMode = true;
    }
  }

  setEditMode() {
    this.supplierCouponDetails.enable();
    this.supplierCouponItems.enable();
    this.onChangedApplyTo(this.supplierCouponDetails.controls['applyTo'].value);
  }

  createForm() {
    return this.fb.group({
      applyTo: [{ value: null, disabled: false }, this.validateControls.applyTo],
      couponAmount: [{ value: null, disabled: false }, this.validateControls.couponAmount],
      minPerBillAmount: [{ value: null, disabled: false }, this.validateControls.minPerBillAmount]
    });
  }

  onChangedApplyTo(value) {
    if (value === 'SELECT_BY_PRODUCT') {
      this.supplierCouponDetails.controls['minPerBillAmount'].setValue(0.0);
      this.supplierCouponDetails.controls['minPerBillAmount'].disable();
    } else if (value === 'ALL_PRODUCT') {
      this.supplierCouponDetails.controls['minPerBillAmount'].enable();
      this.supplierCouponItems.clear();
    }
  }

  addProduct() {
    this.searchProductModal.openSelectProductModal();
  }

  onAddItem(event: BarcodeResponse[]): void {
    if (event && event.length > 0) {
      this.supplierCouponItems.push(this.createFormProductItem(event[0]));
    }
  }

  deleteItem(index: number) {
    this.supplierCouponItems.removeAt(index);
  }

  createFormProductItem(data: BarcodeResponse) {
    return this.fb.group({
      barcode: data.barcode,
      articleNo: data.articleNo,
      productName: data.productName,
      unit: data.unit,
      vat: data.vat,
      purchaseQuantity: [{ value: null, disabled: false }, this.validateControls.purchaseQuantity]
    });
  }

  initSelectLocalStore() {
    this.localStore
      .pipe(
        select(selectRewardValidate),
        filter(data => data !== null && data.items && data.items.length > 0 && (this.submitted || this.isEditFirst)),
        map(data => data.items)
      )
      .subscribe(items => {
        if (this.supplierCouponItems && this.supplierCouponItems.length > 0) {
          this.supplierCouponItems.controls.forEach(ctr => {
            const error = items.find(x => x.barcode === ctr.get('barcode').value);
            if (error && error.errorMessage) {
              applyProductTableItemError(ctr, error.errorMessage, 'barcode', 'productName');
              ctr.get('barcode').updateValueAndValidity();
              ctr.get('productName').updateValueAndValidity();
            }
          });
        }
      });
  }
  setFormValue() {
    if (this.mode === RewardPageModes.VIEW) {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectReward),
        filter(data => data !== null)
      );
    } else {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectRewardRequest),
        filter(data => data !== null)
      );
    }

    this.rewardRequestView$
      .pipe(
        filter(value => Boolean(value)),
        map(response => response),
        take(1)
      )
      .subscribe(res => {
        if (res) {
          if (res.supplierCouponDetails) {
            this.supplierCouponDetails.patchValue({
              ...res.supplierCouponDetails,
              couponAmount: amountToNumber(res.supplierCouponDetails.couponAmount),
              minPerBillAmount: amountToNumber(res.supplierCouponDetails.minPerBillAmount)
            });
          }

          if (res.supplierCouponItems && res.supplierCouponItems.length > 0) {
            res.supplierCouponItems.forEach((value: any, i) => {
              this.supplierCouponItems.push(this.createFormProductItem(value));
              this.supplierCouponItems
                .at(i)
                .get('purchaseQuantity')
                .setValue(value.purchaseQuantity);
            });

            if (this.mode === RewardPageModes.REQUEST_VIEW || this.mode === RewardPageModes.VIEW) {
              this.supplierCouponItems.disable();
            }
          }
        }
      });
  }

  get validateControls() {
    return {
      applyTo: [Validators.required],
      couponAmount: [Validators.required, isZeroValidator()],
      minPerBillAmount: [Validators.required],
      purchaseQuantity: [Validators.required, isZeroValidator()]
    };
  }

  get supplierCouponDetails() {
    return this.parentForm.get('supplierCouponDetails') as FormGroup;
  }

  get supplierCouponItems() {
    return this.parentForm.get('supplierCouponItems') as FormArray;
  }

  get pageMode() {
    return RewardPageModes;
  }

  get applyToValue(): string {
    return this.supplierCouponDetails.controls['applyTo'].value;
  }

  ngOnDestroy(): void {
    this.parentForm.removeControl('supplierCouponDetails');
    this.parentForm.removeControl('supplierCouponItems');
  }
}
