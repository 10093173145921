import { Action } from '@ngrx/store';
import { NewRequestStatusEnum } from '../../enum/request-status.enum';
import { NewRequestTypeEnum } from '../../enum/request-step.enum';
import { ErrorResponse } from '../../models';
import {
  ShelfFixAssetListResponse,
  ShelfFixAssetListSearchCriteria,
  ShelfFixAssetNo,
  ShelfFixAssetViewResponse
} from '../../models/shelf-fix-asset.model';
import { ShelfFixAssetCreateRequest, ShelfFixAssetRequestViewResponse } from '../../models/shelf-request.model';

export enum ShelfFixAssetActionType {
  SHELF_FIX_ASSET_LIST_REQUEST = '[ShelfFixAsset] Shelf FixAsset List Request',
  SHELF_FIX_ASSET_LIST_RESPONSE = '[ShelfFixAsset] Shelf FixAsset List Response',
  SHELF_FIX_ASSET_GET_BY_ID_REQUEST = '[ShelfFixAsset] Get Shelf FixAsset by Id Request',
  SHELF_FIX_ASSET_GET_BY_ID_RESPONSE = '[ShelfFixAsset] Get Shelf FixAsset by Id Response',
  EDIT_SHELF_FIXASSET = '[ShelfFixAsset] Edit Shelf FixAsset',
  SHELF_FIX_ASSET_GET_BY_ID_RESET = '[ShelfFixAsset] Get Shelf FixAsset by Id Reset',
  SHELF_FIX_ASSET_LIST_HISTORY_REQUEST = '[ShelfFixAsset] List History Request',
  SHELF_FIX_ASSET_LIST_HISTORY_RESPONSE = '[ShelfFixAsset] List History Response',
  SUBMIT_SHELF_FIX_ASSET_FIRST_LOT_REQUEST = '[ShelfFixAsset] Submit First Lot Request',
  SUBMIT_SHELF_FIX_ASSET_FIRST_LOT_RESPONSE = '[ShelfFixAsset] Submit First Lot Response',
  SUBMIT_SHELF_FIX_ASSET_FIRST_LOT_ERROR = '[ShelfFixAsset] Submit First Lot Error',
  SHELF_GET_BY_ID_RESET = '[ShelfFixAsset] Get Shelf FixAsset by Id Reset'
}

export class ShelfFixAssetListRequestAction implements Action {
  readonly type = ShelfFixAssetActionType.SHELF_FIX_ASSET_LIST_REQUEST;

  constructor(public payload: ShelfFixAssetListSearchCriteria) {}
}

export class ShelfFixAssetListResponseAction implements Action {
  readonly type = ShelfFixAssetActionType.SHELF_FIX_ASSET_LIST_RESPONSE;

  constructor(public payload: ShelfFixAssetListResponse) {}
}

export class ShelfFixAssetListHistoryRequestAction implements Action {
  readonly type = ShelfFixAssetActionType.SHELF_FIX_ASSET_LIST_HISTORY_REQUEST;
  constructor(public payload: ShelfFixAssetNo) {}
}

export class ShelfFixAssetListHistoryResponseAction implements Action {
  readonly type = ShelfFixAssetActionType.SHELF_FIX_ASSET_LIST_HISTORY_RESPONSE;
  constructor(public payload: { auditLogs }) {}
}

export class ShelfFixAssetByIdRequestAction implements Action {
  readonly type = ShelfFixAssetActionType.SHELF_FIX_ASSET_GET_BY_ID_REQUEST;

  constructor(public payload: ShelfFixAssetNo) {}
}

export class ShelfFixAssetByIdResponseAction implements Action {
  readonly type = ShelfFixAssetActionType.SHELF_FIX_ASSET_GET_BY_ID_RESPONSE;

  constructor(public payload: ShelfFixAssetViewResponse) {}
}

export class EditShelfFixAssetAction implements Action {
  readonly type = ShelfFixAssetActionType.EDIT_SHELF_FIXASSET;

  constructor(public payload: { type: NewRequestTypeEnum; status?: NewRequestStatusEnum }) {}
}

export class SubmitShelfFixAssetFirstLotRequestAction implements Action {
  readonly type = ShelfFixAssetActionType.SUBMIT_SHELF_FIX_ASSET_FIRST_LOT_REQUEST;

  constructor(public payload: ShelfFixAssetCreateRequest) {}
}

export class SubmitShelfFixAssetFirstLotResponseAction implements Action {
  readonly type = ShelfFixAssetActionType.SUBMIT_SHELF_FIX_ASSET_FIRST_LOT_RESPONSE;

  constructor(public payload: ShelfFixAssetRequestViewResponse) {}
}

export class SubmitShelfFixAssetFirstLotErrorAction implements Action {
  readonly type = ShelfFixAssetActionType.SUBMIT_SHELF_FIX_ASSET_FIRST_LOT_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ResetShelfFixAssetSelected implements Action {
  readonly type = ShelfFixAssetActionType.SHELF_GET_BY_ID_RESET;
}

export type ShelfFixAssetActions =
  | ShelfFixAssetListRequestAction
  | ShelfFixAssetListResponseAction
  | ShelfFixAssetListHistoryRequestAction
  | ShelfFixAssetListHistoryResponseAction
  | ShelfFixAssetByIdRequestAction
  | ShelfFixAssetByIdResponseAction
  | EditShelfFixAssetAction
  | SubmitShelfFixAssetFirstLotRequestAction
  | SubmitShelfFixAssetFirstLotResponseAction
  | SubmitShelfFixAssetFirstLotErrorAction
  | ResetShelfFixAssetSelected;
