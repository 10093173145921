import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse } from '../../models';
import { ShelfFixAssetCreateRequest } from '../../models/shelf-request.model';
import { ShelfFixAssetActions, ShelfFixAssetActionType } from '../actions/shelf-fix-asset.actions';
import { ShelfRequestActions, ShelfRequestActionType } from '../actions/shelf-request.actions';
import { BaseState } from '../state/base.state';

export interface ShelfFixAssetCreateResponseState extends EntityState<ShelfFixAssetCreateRequest>, BaseState {
  isLoading: boolean;
  isSave?: boolean | null;
  isApproveRejectSuccess?: boolean | null;
  result?: {
    response: ShelfFixAssetCreateRequest | null;
    errorResponse: ErrorResponse | null;
  };
}

export const adapter: EntityAdapter<ShelfFixAssetCreateRequest> = createEntityAdapter<ShelfFixAssetCreateRequest>();

export const initialShelfCreateResponseState: ShelfFixAssetCreateResponseState = adapter.getInitialState({
  isLoading: false,
  isSave: null,
  isApproveRejectSuccess: null
});

export function shelfFixAssetCreateReducers(
  state = initialShelfCreateResponseState,
  action: ShelfRequestActions | ShelfFixAssetActions
): ShelfFixAssetCreateResponseState {
  switch (action.type) {
    case ShelfRequestActionType.SHELF_CREATE_SAVE_RESPONSE_FIX_ASSET:
    case ShelfRequestActionType.SHELF_CREATE_SUBMIT_RESPONSE_FIX_ASSET:
    case ShelfFixAssetActionType.SUBMIT_SHELF_FIX_ASSET_FIRST_LOT_RESPONSE:
      return {
        ...state,
        isSave: ShelfRequestActionType.SHELF_CREATE_SAVE_RESPONSE_FIX_ASSET === action.type,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    case ShelfRequestActionType.SHELF_CREATE_SAVE_ERROR_FIX_ASSET:
    case ShelfRequestActionType.SHELF_CREATE_SUBMIT_ERROR_FIX_ASSET:
    case ShelfFixAssetActionType.SUBMIT_SHELF_FIX_ASSET_FIRST_LOT_ERROR:
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    case ShelfRequestActionType.SHELF_REQUEST_APPROVE_RESPONSE_FIX_ASSET:
      return {
        ...state,
        isApproveRejectSuccess: action.payload.isSuccess
      };
    case ShelfRequestActionType.SHELF_CREATE_RESET_FIX_ASSET:
      return initialShelfCreateResponseState;
    default: {
      return state;
    }
  }
}
