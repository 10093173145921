<div class="row section-header">
  <span>{{ title }}</span>
</div>
<p>{{ description }}</p>
<div>
  <div [formGroup]="form" class="row">
    <div
      class="p-3"
      [formArrayName]="arrayName"
      *ngFor="let arr of formArray.controls; let i = index; last as LastIndex"
    >
      <p *ngIf="arr.get('image').value; else showUpload">Image #{{ i + 1 }}</p>
      <ng-template #showUpload>
        <p>&nbsp;</p>
      </ng-template>
      <div [formGroupName]="i">
        <app-file-upload
          id="{{ arrayName + i }}"
          formControlName="image"
          [allowedTypes]="allowedExt"
          [withMeta]="true"
          [multiple]="false"
          [size]="size"
          [controlName]="'image'"
          [allowedExt]="allowedExt"
          [uploadUrl]="uploadUrl"
          [uploadHeaders]="uploadHeaders"
          [baseStorageUrl]="baseStorageUrl"
          [isNew]="false"
          [disabled]="disableImage"
          (showImage)="showImage(arr.get('image').value)"
          (imageSuccess)="addNewUpload($event)"
          (isDelete)="deleteImage(i)"
          [errorImageSize]="errorImageSize"
          [errorIncorrectFormat]="errorIncorrectFormat"
          (change)="onUploaded(i)"
        >
          ></app-file-upload
        >
      </div>
    </div>
  </div>
</div>
