import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { merge, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import {
  StockTransferPageModes,
  StockTransferRequestStatusEnum
} from '../../../shared/enum/stock-transfer-request.enum';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import {
  StockTransferRequestList,
  StockTransferRequestListSearchCriteria
} from '../../../shared/models/stock-transfer-request.model';
import { AuthGuardService } from '../../../shared/services';
import {
  StockTransferCancelRequestAction,
  StockTransferDeleteRequestAction,
  StockTransferRequestListRequestAction
} from '../../../shared/store/actions/stock-transfer-request.actions';
import { WarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { StockTransferRequestState } from '../../../shared/store/reducers/stock-transfer-request.reducers';
import {
  selectAllStockTransferRequestList,
  selectStockTransferRequest,
  selectStockTransferRequestListCriteria
} from '../../../shared/store/selectors/stock-transfer-request.selectors';
import { selectAllWarehouse } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { StockTransferRequestComponent } from '../stock-transfer-request/stock-transfer-request.component';

@Component({
  selector: 'app-stock-transfer-request-list',
  templateUrl: './stock-transfer-request-list.component.html',
  styleUrls: ['./stock-transfer-request-list.component.scss']
})
export class StockTransferRequestListComponent extends BaseSearchComponent<
  StockTransferRequestListSearchCriteria,
  StockTransferRequestList,
  StockTransferRequestState
> {
  @ViewChild('modalCreateStockTransfer', { static: false }) modalCreateStockTransfer: ModalDirective;

  public localStore: Observable<any>;
  public createStockTransferForm: FormGroup;
  public stockTransferStatusFilter: NgOption[] = filterDropdown.stockTransferRequestStatusFilter;
  public stockTransferStatusEnum: StockTransferRequestStatusEnum;
  public warehouseList: NgOption[];
  public warehouseListFrom: NgOption[];
  public warehouseListTo: NgOption[];
  public requestedDateTag: string;
  public requestedDateStringTag: string;
  public submitted: boolean;
  public bsModalRef: BsModalRef;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService
  ) {
    super(store, modalService, selectAllStockTransferRequestList, selectStockTransferRequest);
    super.subscribeForSaveSuccess();
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [''],
      requestedDateFrom: [null],
      requestedDateTo: [null]
    });

    this.createStockTransferForm = this.fb.group({
      shipFrom: [null, Validators.required],
      shipTo: [{ value: null, disabled: true }, Validators.required]
    });
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  doDestroy() {}

  doInit() {
    this.setInitialData();
    this.generateWarehouse();
    this.setInitialSubscription();
  }

  goToView(stockTransfer: StockTransferRequestList) {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        StockTransferRequestComponent,
        {
          id: stockTransfer.id,
          title: StockTransferRequestListComponent.getStockTransferTitle(StockTransferPageModes.VIEW),
          status: stockTransfer.status,
          mode: StockTransferPageModes.VIEW,
          shipFrom: stockTransfer.shipFromCodeName,
          shipTo: stockTransfer.shipToCodeName
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  goToEdit(stockTransfer: StockTransferRequestList) {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        StockTransferRequestComponent,
        {
          id: stockTransfer.id,
          title: StockTransferRequestListComponent.getStockTransferTitle(StockTransferPageModes.EDIT),
          status: stockTransfer.status,
          mode: StockTransferPageModes.EDIT,
          shipFrom: stockTransfer.shipFromCodeName,
          shipTo: stockTransfer.shipToCodeName
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  hasCreatePermission() {
    return this.authGuardService.checkPermission(['tr_m']);
  }

  hasEditPermission(status: StockTransferRequestStatusEnum) {
    return (
      [StockTransferRequestStatusEnum.AWAITING_APPROVAL, StockTransferRequestStatusEnum.DRAFT].includes(status) &&
      this.authGuardService.checkPermission(['tr_m'])
    );
  }

  hasDeletePermission(status: StockTransferRequestStatusEnum) {
    return [StockTransferRequestStatusEnum.DRAFT].includes(status) && this.authGuardService.checkPermission(['tr_m']);
  }

  hasCancelPermission(status: StockTransferRequestStatusEnum) {
    return (
      [StockTransferRequestStatusEnum.AWAITING_APPROVAL].includes(status) &&
      this.authGuardService.checkPermission(['tr_m'])
    );
  }

  deleteStockTransferRequest(id: string) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        okText: 'Yes, delete',
        cancelText: 'Cancel',
        message: 'Are you sure you want to delete this request?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new StockTransferDeleteRequestAction(id));
        }
      });
  }

  cancelStockTransferRequest(id: string, requestNo: string): void {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel TR Number <b>"${requestNo}"</b>?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new StockTransferCancelRequestAction({
              id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  generateWarehouse() {
    this.localStore.pipe(select(selectAllWarehouse('Warehouse'))).subscribe(whs => {
      this.warehouseList = whs;
      this.warehouseListFrom = whs;
      this.warehouseListTo = whs;
    });
  }

  setInitialSubscription() {
    merge(
      this.createStockTransferForm.get('shipFrom').valueChanges.pipe(
        tap(val => {
          if (val) {
            this.createStockTransferForm.get('shipTo').enable();
          } else {
            this.createStockTransferForm.get('shipTo').setValue(null);
            this.createStockTransferForm.get('shipTo').disable();
            this.createStockTransferForm.get('shipTo').updateValueAndValidity({ onlySelf: true });
          }
        })
      ),
      this.createStockTransferForm.get('shipTo').valueChanges
    )
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.warehouseListFrom = this.warehouseList.filter(
          result =>
            result.code !==
            (this.createStockTransferForm.get('shipTo').value &&
              this.createStockTransferForm.get('shipTo').value.code)
        );
        this.warehouseListTo = this.warehouseList.filter(
          result =>
            result.code !==
            (this.createStockTransferForm.get('shipFrom').value &&
              this.createStockTransferForm.get('shipFrom').value.code)
        );
      });
  }

  showHistory(transferItem: StockTransferRequestList) {
    const initialState = {
      title: 'History',
      historyHeader: `Request No.: ${transferItem.docNo}`,
      auditLogs: transferItem.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  goToCreate() {
    this.submitted = true;
    if (this.createStockTransferForm.invalid) {
      return;
    }

    const form = this.createStockTransferForm.getRawValue();
    const initialState = {
      title: null,
      childItem: new ChildItem(
        StockTransferRequestComponent,
        {
          title: StockTransferRequestListComponent.getStockTransferTitle(StockTransferPageModes.CREATE),
          mode: StockTransferPageModes.CREATE,
          shipFrom: form.shipFrom,
          shipTo: form.shipTo
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    this.hideModalStockCreateTransfer();
    return;
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;

    if (!formValue.requestedDateFrom && !formValue.requestedDateTo) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      requestedDateFrom: dateToStringCriteria(formValue.requestedDateFrom, true),
      requestedDateTo: dateToStringCriteria(formValue.requestedDateTo, false),
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.prepareRequestedDateTags();
  }

  prepareRequestedDateTags() {
    this.requestedDateTag = null;
    this.requestedDateStringTag = null;

    const requestedDateFrom = dateStringToTagCriteria(this.criteriaObject.requestedDateFrom);
    const requestedDateTo = dateStringToTagCriteria(this.criteriaObject.requestedDateTo);
    const requestedDate = generateDateStringTag({
      dateName: 'Requested Date',
      dateFrom: requestedDateFrom,
      dateTo: requestedDateTo
    });

    this.requestedDateTag = requestedDate.dateTag;
    this.requestedDateStringTag = requestedDate.dateStringTag;
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  search(criteriaObj) {
    this.store.dispatch(new StockTransferRequestListRequestAction(criteriaObj));
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission(['tr_m', 'to_v']);
    const hasRequestPagePermission = this.authGuardService.checkPermission(['tr_m', 'tr_app', 'tr_v']);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Transfer Order', url: '/stock/stock-transfer-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Transfer Request', url: '/stock/stock-transfer-request-list' });
    }
  }

  setInitialData() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore.pipe(select(selectStockTransferRequestListCriteria)).subscribe(criteriaObject => {
      return (this.currentPage = criteriaObject.page + 1);
    });

    this.store.dispatch(new WarehouseListRequestAction('-'));
  }

  onChangeStatus(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.setFirstPage();
    this.resetRequestedDate();
    this.doSearch(this.criteriaObject);
  }

  resetRequestedDate() {
    this.searchForm.controls['requestedDateFrom'].reset();
    this.searchForm.controls['requestedDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      requestedDateFrom: null,
      requestedDateTo: null,
      page: 0
    };
  }

  getColorStatus(status: string) {
    return status ? status.toLowerCase() : '';
  }

  showModalCreateTransfer() {
    this.modalCreateStockTransfer.show();
  }

  hideModalStockCreateTransfer() {
    this.submitted = false;
    this.createStockTransferForm.reset();
    this.modalCreateStockTransfer.hide();
  }

  get stockTransferRequestStatusEnum() {
    return StockTransferRequestStatusEnum;
  }

  public static getStockTransferTitle(mode: StockTransferPageModes) {
    let title: string;
    switch (mode) {
      case StockTransferPageModes.CREATE:
        title = 'Create Transfer Request';
        break;
      case StockTransferPageModes.VIEW:
        title = 'View Transfer Request';
        break;
      case StockTransferPageModes.EDIT:
        title = 'Edit Transfer Request';
        break;
      default:
    }
    return title;
  }
}
