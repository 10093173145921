import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { SupplierStatusEnum } from '../../../shared/enum/supplier.enum';
import { GraphqlQueryObject } from '../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  DuplicatedSupplier,
  SupplierPageModes,
  SupplierSearchCriteria,
  SupplierViewResponse
} from '../../../shared/models/supplier.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { SupplierService } from '../../../shared/services/supplier.service';
import {
  ResetSupplierBySupplierCodeRequestSelected,
  SupplierActivateRequestAction,
  SupplierBySupplierCodeRequestAction,
  SupplierListRequestAction,
  SupplierSubmitEditRequestAction,
  SupplierValidateActionRequest
} from '../../../shared/store/actions/supplier.actions';
import {
  selectSupplierBySupplierCode,
  selectSupplierCheckDuplicated,
  selectSupplierListCriteria,
  selectSupplierSubmitError
} from '../../../shared/store/selectors/supplier.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-supplier-edit',
  templateUrl: './supplier-edit.component.html',
  styleUrls: ['./supplier-edit.component.scss']
})
export class SupplierEditComponent implements OnInit, OnDestroy {
  @ViewChild('modalEdiSetting', { static: false }) modalEdiSetting: ModalDirective;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: SupplierPageModes;
    supplierCode: string;
  };

  public criteriaObj: SupplierSearchCriteria;
  private localStore: Observable<any>;
  public supplierView$: Observable<SupplierViewResponse>;
  public supplier: SupplierViewResponse;
  public supplierForm: FormGroup;
  public ediSettingForm: FormGroup;
  public status: string;
  public environment: any;
  public hasEditSupplierPermission = false;
  public hasUpdateEdiPermission = false;
  public submitted: boolean;
  public supplierVat = filterDropdown.supplierVAT;
  public ediSettingOption = filterDropdown.ediSetting;
  public isRequestEditMode: boolean;
  public isRequestViewMode: boolean;
  public paymentTerms: any;
  isFormDirty: boolean;
  public allowManageSupplier = false;
  public enableActiveButton = false;
  public addEdiSettingSubmit = false;
  public hasBeenUpdateEDI = false;
  public hasEditSupplier = false;

  constructor(
    protected readonly store: Store<AppStates>,
    private readonly fb: FormBuilder,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService,
    private masterService: MasterService,
    private supplierService: SupplierService,
    private readonly translate: TranslateService
  ) {
    this.hasEditSupplierPermission = this.authGuardService.checkPermission(['supplier_m']);
    this.hasUpdateEdiPermission = this.authGuardService.checkPermission(['supplier_set_m']);
    this.environment = environment;
    this.submitted = false;
  }

  ngOnInit() {
    this.isRequestViewMode = this.data.mode === SupplierPageModes.VIEW;
    this.isRequestEditMode = this.data.mode === SupplierPageModes.EDIT;

    this.initData();
    this.initControl();

    this.initState();
  }

  initControl(): void {
    this.supplierForm = this.fb.group({
      supplierCode: [{ value: null, disabled: true }, this.validateControls.defaultRequired],
      supplierName: [{ value: null, disabled: true }, this.validateControls.defaultRequired],
      taxCode: [{ value: null, disabled: true }, this.validateControls.defaultRequired],
      branchNo: [{ value: null, disabled: true }, this.validateControls.branchNo],
      paymentTerm: [{ value: null, disabled: true }],
      address: [{ value: null, disabled: true }, this.validateControls.defaultRequired],
      city: [{ value: null, disabled: true }, this.validateControls.defaultRequired],
      province: [{ value: null, disabled: true }, this.validateControls.defaultRequired],
      country: [{ value: null, disabled: true }, this.validateControls.defaultRequired],
      postalCode: [{ value: null, disabled: true }, this.validateControls.defaultRequired],
      contactName: [{ value: null, disabled: true }],
      contactNumber: [{ value: null, disabled: true }],
      status: [{ value: null, disabled: true }],
      supplierVat: [{ value: false, disabled: true }],
      ediSetting: [{ value: null, disabled: true }]
    });

    this.ediSettingForm = this.fb.group({
      ediSetting: [{ value: null, disabled: false }, this.validateControls.defaultRequired],
      supplierCode: [null]
    });
  }
  initState(): void {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.localStore.pipe(select(selectSupplierListCriteria)).subscribe(criteriaObject => {
      this.criteriaObj = criteriaObject;
    });

    this.localStore.pipe(select(selectSupplierSubmitError)).subscribe(submitError => {
      if (submitError && submitError.message) {
        this.alertModal('Failed', submitError.message);
      }
    });

    this.localStore
      .pipe(
        select(selectSupplierCheckDuplicated),
        filter(() => this.submitted),
        mergeMap(res => this.setValidateDuplicate(res)),
        mergeMap(res2 => this.initValueChange(res2))
      )
      .subscribe(error => {
        console.log(error);
      });

    if (this.data.supplierCode) {
      this.store.dispatch(new SupplierBySupplierCodeRequestAction({ supplierCode: this.data.supplierCode }));
    }

    this.supplierView$ = this.store.pipe(select(selectSupplierBySupplierCode));

    this.supplierView$.pipe(filter(response => response !== null)).subscribe(res => {
      this.setSupplierValue(res);
    });

    if (this.isRequestEditMode) {
      this.toggleToEditMode();
    }
  }

  initData(): void {
    const query = new GraphqlQueryObject();

    query.name = MasterDataEnum.PAYMENT_TERMS;
    query.fields = ['code', 'nameEn'];
    this.masterService.getMasterDataByNames([query]).subscribe(result => {
      this.paymentTerms = result.data.paymentTerms;
    });
  }
  setValidateDuplicate(error) {
    if (this.submitted && error && typeof error.isSuccess === 'boolean' && !error.isSuccess) {
      this.supplierForm.controls['taxCode'].setValidators(this.duplicateValidator);
      this.supplierForm.controls['branchNo'].setValidators(this.duplicateValidator);

      this.supplierForm.controls['taxCode'].updateValueAndValidity();
      this.supplierForm.controls['branchNo'].updateValueAndValidity();
    } else if (this.submitted) {
      this.submitData();
    }

    return of(error);
  }
  initValueChange(res: any) {
    if (this.submitted && res && typeof res.isSuccess === 'boolean' && !res.isSuccess) {
      this.supplierForm.controls['taxCode'].valueChanges
        .pipe(untilComponentDestroyed(this), take(1))
        .subscribe(() => {
          this.removeCheckDuplicate();
        });
      this.supplierForm.controls['branchNo'].valueChanges
        .pipe(untilComponentDestroyed(this), take(1))
        .subscribe(() => {
          this.removeCheckDuplicate();
        });
    }

    return of(res);
  }

  setSupplierValue(data: SupplierViewResponse): void {
    if (data) {
      this.status = data.status as SupplierStatusEnum;
      this.supplier = data;

      this.supplierForm.patchValue(data);

      if (typeof data.ediSetting === 'boolean') {
        this.supplierForm.get('ediSetting').setValue(data.ediSetting ? 'Yes' : 'No');
      }

      if (data.paymentTerm && data.paymentTermCode) {
        const payment = {
          code: data.paymentTermCode,
          nameEn: data.paymentTerm
        };

        this.supplierForm.controls['paymentTerm'].setValue(payment);
      } else {
        this.supplierForm.controls['paymentTerm'].setValue(null);
      }
    }
  }

  goToDeactivate() {
    const data = {
      id: this.supplier.id,
      status: 'INACTIVE',
      version: this.supplier.version
    };

    this.sendToChangedStatus(data, 'Are you sure you want to deactivate?');
  }

  gotoActivate() {
    const data = {
      id: this.supplier.id,
      status: 'ACTIVE',
      version: this.supplier.version
    };
    this.sendToChangedStatus(data, 'Are you sure you want to activate?');
  }

  sendToChangedStatus(data: any, msg: string) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: msg
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new SupplierActivateRequestAction(data));
        }
      });
  }
  alertModal(title: string, message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: title,
        message
      }
    });
  }

  onSubmit(): void {
    this.submitted = true;
    const invalid = this.supplierForm.invalid;

    if (invalid) {
      return;
    }
    const rawValue = this.supplierForm.getRawValue();

    this.validateBeforeSubmit(rawValue);
  }

  submitData(): void {
    const rawValue = this.supplierForm.getRawValue();

    const data = {
      ...this.supplier,
      ...rawValue,
      ediSetting: rawValue.ediSetting === null ? null : rawValue.ediSetting === 'Yes'
    };

    data.paymentTermCode = rawValue.paymentTerm ? rawValue.paymentTerm.code : null;
    data.paymentTerm = rawValue.paymentTerm ? rawValue.paymentTerm.nameEn : null;

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.hasEditSupplier = true;
          this.store.dispatch(new SupplierSubmitEditRequestAction(data));
        }
      });
  }

  validateBeforeSubmit(data: any): void {
    const checkDup = {
      supplierCode: data.supplierCode,
      taxCode: data.taxCode,
      branchNo: data.branchNo
    } as DuplicatedSupplier;

    this.store.dispatch(new SupplierValidateActionRequest(checkDup));
  }

  toggleToEditMode(): void {
    this.supplierForm.enable();
    this.supplierForm.get('ediSetting').disable();
    this.isRequestEditMode = true;
    this.isRequestViewMode = false;
  }

  onExit() {
    this.isFormDirty = this.supplierForm.dirty || this.isFormDirty;

    if (this.isFormDirty) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
    }
  }

  removeCheckDuplicate(): void {
    if (
      this.submitted &&
      this.supplierForm.controls['taxCode'].errors &&
      this.submitted &&
      this.supplierForm.controls['taxCode'].errors['duplicate']
    ) {
      this.supplierForm.controls['taxCode'].clearValidators();
      this.supplierForm.controls['branchNo'].clearValidators();

      this.supplierForm.controls['taxCode'].setValidators(this.validateControls.defaultRequired);
      this.supplierForm.controls['branchNo'].setValidators(this.validateControls.branchNo);

      this.supplierForm.controls['taxCode'].updateValueAndValidity();
      this.supplierForm.controls['branchNo'].updateValueAndValidity();
    }
  }

  onUpdateEDISettingSubmit(): void {
    this.addEdiSettingSubmit = true;
    if (this.ediSettingForm.invalid) {
      return;
    }

    const value = this.ediSettingForm.value;
    this.supplierService
      .ediSetting(value)
      .pipe(filter(res => Boolean(res)))
      .subscribe(v => {
        this.hasBeenUpdateEDI = true;
        const edi = v.ediSetting ? 'Yes' : 'No';
        this.supplierForm.get('ediSetting').setValue(edi);
        this.supplier.version = v.version;
        this.alertSuccessModal('Electronic Data Interchange (EDI) has been updated.');
        this.onExitUpdateEdi();
      });
  }
  ediSetting(): void {
    const value =
      this.supplierForm.get('ediSetting').value === null ? null : this.supplierForm.get('ediSetting').value === 'Yes';

    if (value !== null) {
      this.ediSettingForm.get('ediSetting').setValue(value);
    }

    this.ediSettingForm.get('supplierCode').setValue(this.data.supplierCode);

    this.modalEdiSetting.show();
  }

  onExitUpdateEdi(): void {
    this.addEdiSettingSubmit = false;
    this.modalEdiSetting.hide();
    this.ediSettingForm.reset();
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
      }
    });
  }
  get validateControls() {
    return {
      defaultRequired: [Validators.required],
      branchNo: [this.branchNoValidator]
    };
  }

  get supplierStatus() {
    return this.supplier && this.supplier.status ? this.supplier.status.toLocaleLowerCase() : null;
  }

  get branchNoValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value === null || control.value === '') {
        return null;
      }

      return control.value.length !== 5 ? { isErrorLength: true } : null;
    };
  }

  get duplicateValidator(): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      return { duplicate: true };
    };
  }

  ngOnDestroy(): void {
    this.submitted = false;
    this.store.dispatch(new ResetSupplierBySupplierCodeRequestSelected());
    if (this.hasBeenUpdateEDI && this.data.mode === SupplierPageModes.VIEW) {
      this.store.dispatch(new SupplierListRequestAction(this.criteriaObj));
    } else if (this.hasBeenUpdateEDI && !this.hasEditSupplier && this.data.mode === SupplierPageModes.EDIT) {
      this.store.dispatch(new SupplierListRequestAction(this.criteriaObj));
    }
  }
}
