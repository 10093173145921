import { createSelector } from '@ngrx/store';
import * as fromStockTransferRequest from '../reducers/stock-transfer-request.reducers';
import { AppStates } from '../state/app.states';

const selectStockTransferRequestState = (state: AppStates) => state.stockTransferRequest;

export const selectAllStockTransferRequestList = createSelector(
  selectStockTransferRequestState,
  fromStockTransferRequest.selectAll
);

export const selectStockTransferRequest = createSelector(
  selectStockTransferRequestState,
  (state: fromStockTransferRequest.StockTransferRequestState) => state
);

export const selectStockTransferRequestListCriteria = createSelector(selectStockTransferRequestState, state => {
  return state.criteriaObject;
});

export const selectEditStockTransferRequest = createSelector(
  selectStockTransferRequestState,
  state => state.stockTransfer
);

export const selectStockTransferErrorResponse = createSelector(
  selectStockTransferRequestState,
  state => state.stockTransferErrorResponse
);
