import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { PosAdvertisementPageModes } from '../../../shared/enum/pos-advertisement.enum';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { HistoryType } from '../../../shared/models/audit-log.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import {
  PosAdsSearchCriteria,
  PosAdvertisementListContent,
  PosAdvertisementTypeEnum
} from '../../../shared/models/pos-advertisement.model';
import { AuthGuardService } from '../../../shared/services';
import { PosAdvertisementService } from '../../../shared/services/pos-advertisement.service';
import {
  PosAdvertisementCancelRequest,
  PosAdvertisementListRequestAction,
  PosDefaultAdvertisementListRequestAction
} from '../../../shared/store/actions/pos-advertisement.actions';
import { PosAdvertisementState } from '../../../shared/store/reducers/pos-advertisement.reducers';
import {
  selectAllPosAdvertisementList,
  selectPosAdvertisementDefaultAds,
  selectPosAdvertisementList,
  selectPosAdvertisementListCriteria
} from '../../../shared/store/selectors/pos-advertisement.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { AdvertisementCreateComponent } from '../advertisement-create/advertisement-create.component';

@Component({
  selector: 'app-pos-advertisement-list',
  templateUrl: './pos-advertisement-list.component.html',
  styleUrls: ['./pos-advertisement-list.component.scss']
})
export class PosAdvertisementListComponent
  extends BaseSearchComponent<PosAdsSearchCriteria, PosAdvertisementListContent, PosAdvertisementState>
  implements OnInit, OnDestroy {
  public minDate: Date;
  public maxDate: Date;
  public expireMaxDate: Date;
  public expireMinDate: Date;
  public validMaxDate: Date;
  public validMinDate: Date;
  public isShowAdvanceSearch: any;
  public defaultAds: PosAdvertisementListContent;
  public validDateTag: string;
  public validDateStringTag: string;
  public effectiveDateTag: string;
  public effectiveDateStringTag: string;
  public expireDateTag: string;
  public expireDateStringTag: string;
  public statusList: any[];

  public dateFormat = environment.dateFormat;

  private bsModalRef: BsModalRef;
  private localStore: Observable<any>;
  public pageMode = PosAdvertisementPageModes;

  public hasManagePermission = false;

  public posAdsDefault$: Observable<PosAdvertisementListContent>;

  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService,
    protected posAdsService: PosAdvertisementService
  ) {
    super(store, modalService, selectAllPosAdvertisementList, selectPosAdvertisementList);
    super.subscribeForSaveSuccess();
    this.store.dispatch(new PosDefaultAdvertisementListRequestAction());
    this.statusList = filterDropdown.seasonalAdsStatusFilter;
  }

  doInit() {
    this.hasManagePermission = this.authGuardService.checkPermission(['pos_ads_m']);
    this.initState();
  }

  initState(): void {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore.pipe(select(selectPosAdvertisementListCriteria)).subscribe();

    this.posAdsDefault$ = this.localStore.pipe(select(selectPosAdvertisementDefaultAds));

    this.posAdsDefault$.subscribe(data => {
      this.defaultAds = data;
    });
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.statusList[0].value],
      effectiveDateFrom: [null],
      effectiveDateTo: [null],
      expireDateFrom: [null],
      expireDateTo: [null],
      validDateFrom: [null],
      validDateTo: [null]
    });
  }

  prepareSearchCriteriaTags() {
    this.effectiveDateTag = null;
    this.effectiveDateStringTag = null;
    this.expireDateTag = null;
    this.expireDateStringTag = null;
    this.validDateTag = null;
    this.validDateStringTag = null;

    const effectiveDateFrom = dateStringToTagCriteria(this.criteriaObject.effectiveDateFrom);
    const effectiveDateTo = dateStringToTagCriteria(this.criteriaObject.effectiveDateTo);
    const effectiveDate = generateDateStringTag({
      dateName: 'Effective Date',
      dateFrom: effectiveDateFrom,
      dateTo: effectiveDateTo
    });

    this.effectiveDateStringTag = effectiveDate.dateStringTag;
    this.effectiveDateTag = effectiveDate.dateTag;

    const expireDateFrom = dateStringToTagCriteria(this.criteriaObject.expireDateFrom);
    const expireDateTo = dateStringToTagCriteria(this.criteriaObject.expireDateTo);
    const expireDate = generateDateStringTag({
      dateName: 'Expire Date',
      dateFrom: expireDateFrom,
      dateTo: expireDateTo
    });

    this.expireDateStringTag = expireDate.dateStringTag;
    this.expireDateTag = expireDate.dateTag;

    const validDateFrom = dateStringToTagCriteria(this.criteriaObject.validDateFrom);
    const validDateTo = dateStringToTagCriteria(this.criteriaObject.validDateTo);
    const validDate = generateDateStringTag({
      dateName: 'Valid Date',
      dateFrom: validDateFrom,
      dateTo: validDateTo
    });

    this.validDateStringTag = validDate.dateStringTag;
    this.validDateTag = validDate.dateTag;
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new PosAdvertisementListRequestAction(criteriaObj));
  }

  goToView(mode: PosAdvertisementPageModes, viewParams?: PosAdvertisementListContent) {
    if (viewParams === null) {
      return;
    }

    const initialState = {
      title: null,
      childItem: new ChildItem(
        AdvertisementCreateComponent,
        {
          mode: mode,
          title: this.getTitleHeader(mode),
          adsNo: viewParams.adsNo
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (
      !formValue.effectiveDateFrom &&
      !formValue.effectiveDateTo &&
      !formValue.expireDateFrom &&
      !formValue.expireDateTo &&
      !formValue.validDateFrom &&
      !formValue.validDateTo
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      effectiveDateFrom: dateToStringCriteria(formValue.effectiveDateFrom),
      effectiveDateTo: dateToStringCriteria(formValue.effectiveDateTo, false),
      expireDateFrom: dateToStringCriteria(formValue.expireDateFrom),
      expireDateTo: dateToStringCriteria(formValue.expireDateTo, false),
      validDateFrom: dateToStringCriteria(formValue.validDateFrom),
      validDateTo: dateToStringCriteria(formValue.validDateTo, false),
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  onClickedOutside($event: MouseEvent | any) {
    if (
      $event.target &&
      ($event.target.classList.contains('is-highlighted') ||
        $event.target.classList.contains('ng-option') ||
        $event.target.classList.contains('ng-value-icon') ||
        $event.target.classList.contains('ng-option-label'))
    ) {
      return;
    }
    this.isShowAdvanceSearch = false;
  }

  goToEdit() {}

  goToCreateSeasonal() {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        AdvertisementCreateComponent,
        {
          mode: PosAdvertisementPageModes.CREATE_SEASONAL,
          title: this.getTitleHeader(PosAdvertisementPageModes.CREATE_SEASONAL)
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  goToCreateDefault() {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        AdvertisementCreateComponent,
        {
          mode: PosAdvertisementPageModes.CREATE_DEFAULT,
          title: this.getTitleHeader(PosAdvertisementPageModes.CREATE_DEFAULT)
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  getTitleHeader(mode: PosAdvertisementPageModes) {
    switch (mode) {
      case PosAdvertisementPageModes.CREATE_DEFAULT:
        return 'Create Default Ads';
      case PosAdvertisementPageModes.CREATE_SEASONAL:
        return 'Create Seasonal Ads';
      case PosAdvertisementPageModes.EDIT_DEFAULT:
        return 'Edit Default Ads';
      case PosAdvertisementPageModes.VIEW_DEFAULT:
        return 'View Default Ads';
      case PosAdvertisementPageModes.VIEW_SEASONAL:
        return 'View Seasonal Ads';
      default:
        return '';
    }
  }

  onChangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onChangeExpireDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.expireMinDate = new Date(value);
    } else {
      this.expireMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeExpireDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.expireMaxDate = new Date(value);
    } else {
      this.expireMaxDate = new Date();
      this.expireMaxDate.setDate(this.expireMaxDate.getDate() + 365);
    }
  }

  onChangeValidDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.validMinDate = new Date(value);
    } else {
      this.validMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeValidDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.validMaxDate = new Date(value);
    } else {
      this.validMaxDate = new Date();
      this.validMaxDate.setDate(this.validMaxDate.getDate() + 365);
    }
  }

  clearAdvanceFilter() {
    this.setFirstPage();
    this.searchForm.controls['effectiveDateFrom'].reset();
    this.searchForm.controls['effectiveDateTo'].reset();
    this.searchForm.controls['expireDateFrom'].reset();
    this.searchForm.controls['expireDateTo'].reset();
    this.searchForm.controls['validDateFrom'].reset();
    this.searchForm.controls['validDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      effectiveDateFrom: null,
      effectiveDateTo: null,
      expireDateFrom: null,
      expireDateTo: null,
      validDateFrom: null,
      validDateTo: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterEffectiveDate() {
    this.setFirstPage();
    this.searchForm.controls['effectiveDateFrom'].reset();
    this.searchForm.controls['effectiveDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      effectiveDateFrom: null,
      effectiveDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterExpireDate() {
    this.setFirstPage();
    this.searchForm.controls['expireDateFrom'].reset();
    this.searchForm.controls['expireDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      expireDateFrom: null,
      expireDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterValidDate() {
    this.setFirstPage();
    this.searchForm.controls['validDateFrom'].reset();
    this.searchForm.controls['validDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      validDateFrom: null,
      validDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  getColorStatus(status: string): string {
    return status ? status.toLowerCase() : '';
  }

  cancelSeasonalAds(item: PosAdvertisementListContent) {
    if (item && item.type !== 'SEASONAL') {
      return;
    }

    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel Seasonal Ads No. <strong>&quot;${item.adsNo}&quot;</strong>?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PosAdvertisementCancelRequest({
              id: item.adsNo,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  showCancelButton(item): boolean {
    if (
      item &&
      item.type === 'SEASONAL' &&
      item.status &&
      ['awaiting_schedule', 'active'].includes(item.status.toLocaleLowerCase())
    ) {
      return true;
    }

    return false;
  }

  showHistory(request: PosAdvertisementListContent) {
    let historyHeader = '';

    if (request.type === PosAdvertisementTypeEnum.SEASONAL) {
      historyHeader = `Seasonal Ads No. ${request.adsNo}`;
    } else {
      historyHeader = `Default Ads`;
    }

    const initialState = {
      title: 'History',
      action: HistoryType.REQUEST,
      historyHeader: historyHeader,
      historyType: HistoryType.POS_ADVERTISEMENT,
      auditLogs: request.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  setInitialValue() {
    this.pageSize = 20;
    const todayDate = new Date();

    this.maxDate = todayDate;
    this.expireMaxDate = todayDate;
    this.validMaxDate = todayDate;

    this.maxDate.setDate(todayDate.getDate() + 365);
    this.expireMaxDate.setDate(todayDate.getDate() + 365);
    this.validMaxDate.setDate(todayDate.getDate() + 365);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  doAfterSuccessModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  setRouteTab() {}
}
