import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OrderFixAssetRequestService } from '../../services/order-fix-asset-request.service';
import { ShelfTypesService } from '../../services/shelf-types.service';
import { ShelfService } from '../../services/shelf.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { OrderFixAssetApproveResponseAction, OrderFixAssetCreateSaveResponseAction, OrderFixAssetCreateSubmitResponseAction, OrderFixAssetCreateSubmitResponseErrorAction, OrderFixAssetRequestActionType, OrderFixAssetRequestByIdResponseAction } from '../actions/order-fix-asset-request.actions';
export class OrderFixAssetRequestEffects {
    constructor(actions$, orderFixAssetRequestService, shelfService, shelfTypesService, logger, store) {
        this.actions$ = actions$;
        this.orderFixAssetRequestService = orderFixAssetRequestService;
        this.shelfService = shelfService;
        this.shelfTypesService = shelfTypesService;
        this.logger = logger;
        this.store = store;
        this.orderFixAssetCreateSave$ = this.actions$.pipe(ofType(OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SAVE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SAVE_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.orderFixAssetRequestService.saveRequest(payload).pipe(map(res => new OrderFixAssetCreateSaveResponseAction(res)), catchError(error => of(new OrderFixAssetCreateSubmitResponseErrorAction(error.error))));
        }));
        this.OrderFixAssetCreateSubmit$ = this.actions$.pipe(ofType(OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SUBMIT_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SUBMIT_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.orderFixAssetRequestService.submitRequest(payload).pipe(map(res => new OrderFixAssetCreateSubmitResponseAction(res)), catchError(error => of(new OrderFixAssetCreateSubmitResponseErrorAction(error.error))));
        }));
        this.getOrderFixAssetRequestById$ = this.actions$.pipe(ofType(OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_GET_BY_ID_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_GET_BY_ID_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.orderFixAssetRequestService.getOrderById(payload).pipe(map(res => {
                return new OrderFixAssetRequestByIdResponseAction(res);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.orderFixAssetApprove$ = this.actions$.pipe(ofType(OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_APPROVE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_APPROVE_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.orderFixAssetRequestService.approveRequest(payload).pipe(map(() => new OrderFixAssetApproveResponseAction({ isSuccess: true })), catchError(error => of(new LayoutActionLoadError(error))))));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderFixAssetRequestEffects.prototype, "orderFixAssetCreateSave$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderFixAssetRequestEffects.prototype, "OrderFixAssetCreateSubmit$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderFixAssetRequestEffects.prototype, "getOrderFixAssetRequestById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderFixAssetRequestEffects.prototype, "orderFixAssetApprove$", void 0);
