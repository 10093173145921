<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="page-title-icon icon-td-supplier"></em>
    {{ data.title }}
  </div>
  <ng-container *ngIf="this.enableActiveButton && this.isRequestViewMode">
    <button
      type="button"
      class="btn btn-header-button"
      *ngIf="(hasEditSupplierPermission && supplierView$ | async)?.status.toLocaleLowerCase() === 'active'"
      (click)="goToDeactivate()"
    >
      <em class="icon-deactivate"></em>
      Deactivate
    </button>
    <button
      type="button"
      class="btn btn-header-button"
      *ngIf="(hasEditSupplierPermission && supplierView$ | async)?.status.toLocaleLowerCase() === 'inactive'"
      (click)="gotoActivate()"
    >
      <em class="icon-activate"></em>
      Activate
    </button>
  </ng-container>

  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div>
  <div class="admin-form" [formGroup]="supplierForm">
    <div class="info-header-container">
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Vendor No.:</span>
        </div>
        <div class="info-header-value">
          <span>{{ data.supplierCode }}</span>
        </div>
      </div>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Supplier Status:</span>
        </div>
        <div class="info-header-value">
          <span *ngIf="status" [ngClass]="getColorStatus(status)" class="supplier-status">
            {{ 'SUPPLIER.STATUS.' + status | translate | titlecase }}</span
          >
        </div>
      </div>
    </div>

    <div>
      <div class="row section-header">
        <span>Supplier Profile</span>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="supplierName">Supplier Name<span class="text-danger">*</span></label>
          <input
            id="supplierName"
            name="supplierName"
            class="form-control"
            formControlName="supplierName"
            placeholder="Supplier Name"
            [ngClass]="{
              'is-invalid': submitted && supplierForm.controls.supplierName.errors
            }"
          />
          <div *ngIf="submitted && supplierForm.controls.supplierName.errors" class="invalid-feedback">
            <div *ngIf="supplierForm.controls.supplierName.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="taxCode">Tax ID<span class="text-danger">*</span></label>
          <input
            id="taxCode"
            name="taxCode"
            class="form-control"
            formControlName="taxCode"
            placeholder="Tax ID"
            trim="blur"
            [ngClass]="{
              'is-invalid': submitted && supplierForm.controls.taxCode.errors
            }"
          />
          <div *ngIf="submitted && supplierForm.controls.taxCode.errors" class="invalid-feedback">
            <div *ngIf="supplierForm.controls.taxCode.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="supplierForm.controls.taxCode.errors.duplicate">
              Not allow duplicated Tax ID and Branch No.
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="branchNo">Branch No.</label>
          <input
            id="branchNo"
            name="branchNo"
            class="form-control"
            appDigitOnly
            maxlength="5"
            formControlName="branchNo"
            placeholder="Branch No."
            trim="blur"
            [ngClass]="{
              'is-invalid': submitted && supplierForm.controls.branchNo.errors
            }"
          />
          <div *ngIf="submitted && supplierForm.controls.branchNo.errors" class="invalid-feedback">
            <div *ngIf="supplierForm.controls.branchNo.errors.isErrorLength">
              Incorrect Format. (Require 5 digits)
            </div>
            <div *ngIf="supplierForm.controls.branchNo.errors.duplicate">
              Not allow duplicated Tax ID and Branch No.
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <label for="address">Address<span class="text-danger">*</span></label>
          <input
            id="address"
            name="address"
            class="form-control"
            formControlName="address"
            placeholder="Address"
            [ngClass]="{
              'is-invalid': submitted && supplierForm.controls.address.errors
            }"
          />
          <div *ngIf="submitted && supplierForm.controls.address.errors" class="invalid-feedback">
            <div *ngIf="supplierForm.controls.address.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="city">City<span class="text-danger">*</span></label>
          <input
            id="city"
            name="city"
            class="form-control"
            formControlName="city"
            placeholder="City"
            [ngClass]="{
              'is-invalid': submitted && supplierForm.controls.city.errors
            }"
          />
          <div *ngIf="submitted && supplierForm.controls.city.errors" class="invalid-feedback">
            <div *ngIf="supplierForm.controls.city.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="province">State/Province<span class="text-danger">*</span></label>
          <input
            id="province"
            name="province"
            class="form-control"
            formControlName="province"
            placeholder="State/Province"
            [ngClass]="{
              'is-invalid': submitted && supplierForm.controls.province.errors
            }"
          />
          <div *ngIf="submitted && supplierForm.controls.province.errors" class="invalid-feedback">
            <div *ngIf="supplierForm.controls.province.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="postalCode">PostCode<span class="text-danger">*</span></label>
          <input
            id="postalCode"
            name="postalCode"
            class="form-control"
            formControlName="postalCode"
            placeholder="PostCode"
            [ngClass]="{
              'is-invalid': submitted && supplierForm.controls.postalCode.errors
            }"
          />
          <div *ngIf="submitted && supplierForm.controls.postalCode.errors" class="invalid-feedback">
            <div *ngIf="supplierForm.controls.postalCode.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="country">Country<span class="text-danger">*</span></label>
          <input
            id="country"
            name="country"
            class="form-control"
            formControlName="country"
            placeholder="Country"
            [ngClass]="{
              'is-invalid': submitted && supplierForm.controls.country.errors
            }"
          />
          <div *ngIf="submitted && supplierForm.controls.country.errors" class="invalid-feedback">
            <div *ngIf="supplierForm.controls.country.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="contactName">Contact Name</label>
          <input
            id="contactName"
            name="contactName"
            class="form-control"
            formControlName="contactName"
            placeholder="Contact Name"
          />
        </div>
        <div class="col-md-3 mb-3">
          <label for="contactNumber">Contact Number</label>
          <input
            id="contactNumber"
            name="contactNumber"
            class="form-control"
            formControlName="contactNumber"
            placeholder="Contact Number"
          />
        </div>
        <div class="col-md-3 mb-3">
          <label for="paymentTerm">Payment Term</label>
          <ng-select
            id="paymentTerm"
            placeholder="Please select..."
            [items]="paymentTerms"
            [searchable]="false"
            [clearable]="false"
            formControlName="paymentTerm"
            bindLabel="nameEn"
            [ngClass]="{
              'is-invalid': submitted && supplierForm.controls.paymentTerm.errors
            }"
          >
          </ng-select>
          <div *ngIf="submitted && supplierForm.controls.paymentTerm.errors" class="invalid-feedback">
            <div *ngIf="supplierForm.controls.condition.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="supplierVat">VAT</label>
          <ng-select
            id="supplierVat"
            placeholder="Please select..."
            [searchable]="false"
            [clearable]="false"
            [items]="supplierVat"
            formControlName="supplierVat"
            bindLabel="label"
            bindValue="value"
            [ngClass]="{
              'is-invalid': submitted && supplierForm.controls.supplierVat.errors
            }"
          >
          </ng-select>
          <div *ngIf="submitted && supplierForm.controls.supplierVat.errors" class="invalid-feedback">
            <div *ngIf="supplierForm.controls.supplierVat.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
      <hr class="section-divider" />
      <div class="row section-header">
        <span>Supplier Setting</span>
      </div>
      <div class="row">
        <div class="col-md-3 mb-3">
          <label for="ediSetting">Electronic Data Interchange (EDI)<span class="text-danger">*</span></label>
          <input
            id="ediSetting"
            name="supplierName"
            class="form-control"
            formControlName="ediSetting"
            placeholder="Electronic Data Interchange (EDI)"
          />
        </div>
        <div class="col-md-3 mb-3" *ngIf="hasUpdateEdiPermission">
          <button class="btn btn-standard btn-edi-setting" (click)="ediSetting()">
            <em class="icon-edit"></em>
            Update EDI
          </button>
        </div>
      </div>
    </div>
    <!--footer-->
    <div class="fixed-row-bottom form-row">
      <div class="float-right">
        <button
          *ngIf="isRequestEditMode && hasEditSupplierPermission"
          type="button"
          id="btn-cancel-request"
          class="btn btn-standard mr-2"
          (click)="onExit()"
        >
          Cancel
        </button>
        <button
          *ngIf="isRequestEditMode && hasEditSupplierPermission"
          type="button"
          id="btn-submit"
          class="btn btn-primary"
          appPreventDoubleClick
          (click)="onSubmit()"
        >
          Submit
        </button>
        <button
          *ngIf="isRequestViewMode && hasEditSupplierPermission && supplierStatus === 'active'"
          type="button"
          id="btn-request-edit"
          class="btn btn-primary"
          (click)="toggleToEditMode()"
        >
          Edit
        </button>
      </div>
    </div>
    <!--End footer-->
  </div>

  <div
    class="modal fade"
    bsModal
    #modalEdiSetting="bs-modal"
    [config]="{ backdrop: 'static' }"
    [formGroup]="ediSettingForm"
    name="modalEdiSetting"
    id="modalEdiSetting"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Update EDI</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="onExitUpdateEdi()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body admin-form">
          <div class="container-fluid">
            <div class="form-group">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <label for="ediSetting">Electronic Data Interchange (EDI)<span class="text-danger">*</span></label>
                  <ng-select
                    id="type"
                    placeholder="Please select..."
                    [searchable]="false"
                    [clearable]="false"
                    [items]="ediSettingOption"
                    formControlName="ediSetting"
                    bindLabel="label"
                    bindValue="value"
                    [ngClass]="{
                      'is-invalid': addEdiSettingSubmit && ediSettingForm.controls.ediSetting.errors
                    }"
                  >
                  </ng-select>
                  <div
                    *ngIf="addEdiSettingSubmit && ediSettingForm.controls.ediSetting.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ediSettingForm.controls.ediSetting.errors.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            id="btn-edit-setting-cancel"
            name="btn-edit-setting-cancel"
            class="btn btn-standard mr-2"
            (click)="onExitUpdateEdi()"
          >
            Cancel
          </button>
          <button
            type="button"
            id="btn-edit-setting-submit"
            name="btn-edit-setting-submit"
            class="btn btn-primary"
            appPreventDoubleClick
            (click)="onUpdateEDISettingSubmit()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
