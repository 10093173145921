import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { FilesSubmitComponent } from '../../../shared/components/files-submit/files-submit.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NewMasterData } from '../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { HistoryType } from '../../../shared/models/audit-log.model';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import {
  StoreConsultantContent,
  StoreConsultantExportCriteria,
  StoreConsultantPageModes,
  StoreConsultantSearchCriteria
} from '../../../shared/models/store-consultant.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { StoreConsultantService } from '../../../shared/services/store-consultant.service';
import { LayoutActionLoadError } from '../../../shared/store/actions/layout.action';
import { StoreConsultantListRequestAction } from '../../../shared/store/actions/store-consultant.actions';
import { StoreConsultantState } from '../../../shared/store/reducers/store-consultant.reducers';
import {
  selectAllStoreConsultantList,
  selectStoreConsultantList,
  selectStoreConsultantListCriteria
} from '../../../shared/store/selectors/store-consultant.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { b64toBlob } from '../../../shared/utils/b64toBlob-util';
import { StoreConsultantEditComponent } from '../store-consultant-edit/store-consultant-edit.component';

@Component({
  selector: 'app-store-consultant-list',
  templateUrl: './store-consultant-list.component.html',
  styleUrls: ['./store-consultant-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoreConsultantListComponent
  extends BaseSearchComponent<StoreConsultantSearchCriteria, StoreConsultantContent, StoreConsultantState>
  implements OnInit, OnDestroy {
  @ViewChild('exportModal', { static: false }) exportModal: ModalDirective;
  public dateFormat = environment.dateFormat;
  private localStore: Observable<any>;
  private bsModalRef: BsModalRef;
  public buttons: ImportExportButton[] = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    }
  ];

  public exportForm: FormGroup;
  public filesSubmitModal: BsModalRef;
  public errorFile: Blob;
  public dataFilesImport: any;
  public statusList: any[];

  public regionList: Array<NewMasterData> | null;
  public stateList: Array<NewMasterData> | null;
  public regionTag: string;
  public regionStringTag = 'Region';
  public stateTag: string;
  public stateStringTag = 'Province';
  public submittedExport: boolean;
  public errorExport: string;
  public exportStatusList: Array<{ value: string | boolean; label: string }> = filterDropdown.selectedActive.filter(
    status => status.label !== 'All Status'
  );

  public hasPermissionManage = false;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected masterService: MasterService,
    private readonly service: StoreConsultantService,
    protected authGuardService: AuthGuardService
  ) {
    super(store, modalService, selectAllStoreConsultantList, selectStoreConsultantList);
    super.subscribeForSaveSuccess();

    this.statusList = filterDropdown.selectedActive;
    this.hasPermissionManage = this.authGuardService.checkPermission(['store_consult_m']);

    if (this.hasPermissionManage) {
      this.buttons.push({
        type: ButtonType.IMPORT,
        name: 'Import'
      });
    }
  }

  doInit() {
    this.createExportForm();
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.localStore
      .pipe(select(selectStoreConsultantListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.masterService
      .getMasterDataByNames([MasterDataEnum.REGION, MasterDataEnum.STATE])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.regionList = result[MasterDataEnum.REGION];
        this.stateList = result[MasterDataEnum.STATE];
      });
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.statusList[0].value],
      region: [null],
      state: [null]
    });
  }

  createExportForm() {
    this.exportForm = this.fb.group({
      status: [null],
      region: [null],
      state: [null]
    });
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;

    if (!formValue.state && !formValue.region) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      region: this.convertArrayFilter(formValue.region),
      state: this.convertArrayFilter(formValue.state),
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  convertArrayFilter(value) {
    return value && value.length > 0 ? value.toString() : null;
  }

  prepareSearchCriteriaTags() {
    this.stateTag = null;

    if (this.criteriaObject.state && this.criteriaObject.state.length) {
      const states = this.stateList
        .filter(data => this.criteriaObject.state.includes(data.code))
        .map(data => data.nameTh)
        .join(', ');
      this.stateTag = `"${states}"`;
    }

    this.regionTag = null;

    if (this.criteriaObject.region && this.criteriaObject.region.length) {
      const regions = this.regionList
        .filter(data => this.criteriaObject.region.includes(data.code))
        .map(data => data.nameTh)
        .join(', ');
      this.regionTag = `"${regions}"`;
    }
  }

  goToView(viewParams?: any) {
    if (viewParams === null) {
      return;
    }

    const initialState = {
      title: null,
      childItem: new ChildItem(
        StoreConsultantEditComponent,
        {
          title: 'View Store Consultant',
          mode: StoreConsultantPageModes.VIEW,
          userId: viewParams.userId
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  setRouteTab() {}

  doAfterVersionAlertModal() {
    this.search(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  doAfterSuccessModal() {
    this.doAfterVersionAlertModal();
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  goToEdit(item: any) {
    if (item === null) {
      return;
    }

    const initialState = {
      title: null,
      childItem: new ChildItem(
        StoreConsultantEditComponent,
        {
          title: 'Edit Store Consultant',
          mode: StoreConsultantPageModes.EDIT,
          userId: item.userId
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  showHistory(content?: StoreConsultantContent) {
    if (!content) {
      return;
    }
    const initialState = {
      title: 'History',
      action: HistoryType.REQUEST,
      historyHeader: `User ID: ${content.userId}`,
      historyType: HistoryType.STORE_CONSULTANT,
      auditLogs: content.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  onChangeStatus(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      active: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterRegion() {
    this.setFirstPage();
    this.searchForm.controls['region'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      region: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterState() {
    this.setFirstPage();
    this.searchForm.controls['state'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      state: null
    };
    this.doSearch(this.criteriaObject);
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new StoreConsultantListRequestAction(criteriaObj));
  }

  clearAdvanceFilter() {
    this.setFirstPage();
    this.searchForm.controls['region'].reset();
    this.searchForm.controls['state'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      state: null,
      region: null
    };
    this.doSearch(this.criteriaObject);
  }

  confirmImport() {
    const alertModal = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to import new file? All existing data will be lost.'
      },
      keyboard: false,
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.openImportModal();
      }
    });
  }

  openImportModal() {
    this.filesSubmitModal = this.modalService.show(FilesSubmitComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        title: 'Import',
        templateName: 'Store Consultant Template',
        fileTitle: 'Store Consultant'
      }
    });

    this.filesSubmitModal.content.downloadTemplate.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.downloadTemplate();
    });

    this.filesSubmitModal.content.upload.pipe(untilComponentDestroyed(this)).subscribe(data => {
      this.upload(data);
    });

    this.filesSubmitModal.content.submit.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.onSubmitImport();
    });
    this.filesSubmitModal.content.downloadErrorExcel.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.downloadErrorExcel();
    });
  }

  upload(data) {
    this.errorFile = null;
    this.service.validateFile(data).subscribe(
      res => {
        this.filesSubmitModal.content.loading = false;
        this.filesSubmitModal.content.isUploadSuccess = true;
        this.dataFilesImport = res;
      },
      errorResponse => {
        this.dataFilesImport = null;
        this.filesSubmitModal.content.loading = false;
        const errorMessage = errorResponse.error.fileBase64String
          ? `${errorResponse.error.rowErrorNo} Error Found. Download this file to edit or delete invalid row then re-import.`
          : errorResponse.error.message;

        this.filesSubmitModal.content.setErrorMessage(errorMessage, Boolean(errorResponse.error.fileBase64String));

        if (errorResponse.error.fileBase64String) {
          this.errorFile = b64toBlob(errorResponse.error.fileBase64String);
        }
      }
    );
  }

  saveFile(response: Blob) {
    const blob = new Blob([response]);
    saveAs(blob, `${environment.fileName.exportStoreConsultant.fileTemplate}.xlsx`);
  }

  downloadErrorExcel() {
    const fileConfig = environment.fileName.exportStoreConsultant;
    const dateExport = moment().format(fileConfig.timeFormat);
    saveAs(this.errorFile, `${fileConfig.prefixError} ${dateExport}.xlsx`);
  }

  downloadTemplate() {
    this.service.downloadFileTemplate().subscribe(
      response => {
        this.saveFile(response);
      },
      error => {
        this.modalService.show(AlertModalComponent, {
          initialState: {
            title: 'Failed',
            message: error.error.message
          }
        });
      }
    );
  }

  onSubmitImport() {
    if (!this.dataFilesImport) {
      return;
    }
    this.service.importSubmit(this.dataFilesImport).subscribe(
      () => {
        this.filesSubmitModal.content.closeModal();
        this.dataFilesImport = null;
        this.alertSuccessModal('The data have been imported.');
        this.clearAdvanceFilter();
      },
      errorResponse => {
        this.filesSubmitModal.content.loading = false;
        this.store.dispatch(new LayoutActionLoadError(errorResponse));
      }
    );
  }

  alertSuccessModal(message: string) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message
      },
      keyboard: false,
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
      }
    });
  }

  onOpenExportModal() {
    this.submittedExport = false;
    this.exportForm.reset();
    this.errorExport = null;
    this.exportModal.show();
  }

  onExport() {
    this.submittedExport = true;

    const fileConfig = environment.fileName.exportStoreConsultant;
    const dateExport = moment().format(fileConfig.timeFormat);
    const formData = this.exportForm.getRawValue();
    const request = this.prepareExportRequestData(formData);

    this.service.exportStoreConsultant(request).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${fileConfig.prefix} ${dateExport}.xlsx`);
      },
      error => {
        this.errorExport = error.error.message;
      },
      () => this.exportModal.hide()
    );
  }
  prepareExportRequestData(formData): StoreConsultantExportCriteria {
    return new StoreConsultantExportCriteria({
      active: this.convertArrayFilter(formData.status),
      region: this.convertArrayFilter(formData.region),
      state: this.convertArrayFilter(formData.state)
    });
  }

  getStatus(status: boolean): string {
    return status ? 'ACTIVE' : 'INACTIVE';
  }

  ngOnDestroy(): void {}
}
