import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { ClientIdTypeEnum } from '../enum/client-id.enum';
import { AuthResponse, ErrorResponse } from '../models';
import { AuthService } from '../services';
import { LogInFailureAction, LogInSuccessAction } from '../store/actions/auth.actions';
import { LayoutActionErrorUpdate } from '../store/actions/layout.action';
import { ClientIdAction } from '../store/actions/user-info.action';
import { AppStates } from '../store/state/app.states';
import { CookiesUtil } from '../utils/cookies-util';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    protected readonly authService: AuthService,
    protected cookieService: CookieService,
    protected readonly store: Store<AppStates>,
    protected readonly logger: NGXLogger,
    protected readonly translate: TranslateService,
    protected readonly modalService: BsModalService,
    protected router: Router,
    protected cookiesUtil: CookiesUtil = new CookiesUtil(cookieService)
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    HttpEvent<any> | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any
  > {
    return next.handle(this.addAuthenticationToken(request)).pipe(
      catchError(error => {
        const refreshTokenCookiesName = this.cookiesUtil.generateNameByAmount(env.cookies.refreshToken);
        const refreshToken = this.cookiesUtil.concatCookie(refreshTokenCookiesName);
        const hasToken = !refreshToken && !request.headers.has('Authorization');
        const isIgnoreService = request.url.includes('oauth');

        if (isIgnoreService || hasToken) {
          // We do another check to see if refresh token failed
          // In this case we need to delete all authentication cookies and redirect to login page
          if (!refreshToken) {
            // In case of empty refresh-token
            error.message = this.translate.instant('ERROR_CODE.EMPTY_COOKIES_RF_TOKEN');
            this.logger.info(`Refresh Token in catchError ::`, error);
          }

          this.deleteTokenCookie();
          this.hideAllModal();

          this.store.dispatch(new ClientIdAction(env.defaultClientId as ClientIdTypeEnum));
          this.store.dispatch(new LayoutActionErrorUpdate(false));
          this.store.dispatch(new LogInFailureAction(error as ErrorResponse));
        } else if (error && error instanceof HttpErrorResponse) {
          if (error.status && error.status === 401) {
            const wwwAuthHeader = error.headers.get('WWW-authenticate');

            if (wwwAuthHeader.includes('Jwt expired') || wwwAuthHeader === 'Bearer') {
              if (this.refreshTokenInProgress) {
                return this.refreshTokenSubject.pipe(
                  filter(Boolean),
                  take(1),
                  switchMap(() => next.handle(this.addAuthenticationToken(request)))
                );
              }

              this.refreshTokenInProgress = true;

              // Set the refreshTokenSubject to null so that subsequent API calls will wait
              // until the new token has been retrieved
              this.refreshTokenSubject.next(null);

              return this.authService.refreshToken(refreshToken).pipe(
                switchMap((credential: AuthResponse) => {
                  // After got new refresh token
                  // Then, set new credential to local cookies
                  this.store.dispatch(new LogInSuccessAction(credential));

                  // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                  // for the next time the token needs to be refreshed
                  this.refreshTokenInProgress = false;
                  this.refreshTokenSubject.next(credential.access_token);

                  return next.handle(this.addAuthenticationToken(request));
                }),
                catchError(err => {
                  this.logger.info('Refresh Token :: catchError', err);

                  // Only oauth path will delete cookies and navigate to login page
                  if (err.payload && err.payload.url.includes('oauth')) {
                    this.deleteTokenCookie();
                    this.hideAllModal();

                    this.store.dispatch(new ClientIdAction(env.defaultClientId as ClientIdTypeEnum));
                    this.store.dispatch(new LayoutActionErrorUpdate(false));
                    this.store.dispatch(new LogInFailureAction(err));
                  }
                  this.refreshTokenInProgress = false;

                  return throwError(err);
                })
              );
            }
          }
        }

        return throwError(error);
      })
    );
  }

  addAuthenticationToken(request) {
    const accessTokenCookiesName = this.cookiesUtil.generateNameByAmount(env.cookies.accessToken);
    const accessToken = this.cookiesUtil.concatCookie(accessTokenCookiesName);
    const isEmptyToken = !accessToken;
    const isIgnoreService = request.url.includes('oauth') || request.url.includes('/assets');

    if (isIgnoreService || isEmptyToken) {
      this.logger.info(` Token have not been added in addAuthenticationToken ::`, request);
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }

  deleteTokenCookie() {
    const accessTokenCookiesName = this.cookiesUtil.generateNameByAmount(env.cookies.accessToken);
    const refreshTokenTokenCookiesName = this.cookiesUtil.generateNameByAmount(env.cookies.refreshToken);

    Array.isArray(accessTokenCookiesName)
      ? accessTokenCookiesName.map(value => {
          this.cookieService.delete(value);
        })
      : this.cookieService.delete(accessTokenCookiesName);

    Array.isArray(refreshTokenTokenCookiesName)
      ? refreshTokenTokenCookiesName.map(value => {
          this.cookieService.delete(value);
        })
      : this.cookieService.delete(refreshTokenTokenCookiesName);

    this.cookieService.delete(`${env.cookies.accessToken}_amount`);
    this.cookieService.delete(`${env.cookies.refreshToken}_amount`);
  }

  hideAllModal() {
    const modalCount = this.modalService.getModalsCount();

    if (modalCount > 0) {
      for (let i = modalCount; i > 0; i--) {
        this.modalService.hide(1);
      }
    }
  }
}
