import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsDatepickerConfig, BsModalRef } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment as env } from '../../../../environments/environment';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-stock-transfer-delivery-detail-modal',
  templateUrl: './stock-transfer-delivery-detail-modal.component.html',
  styleUrls: ['./stock-transfer-delivery-detail-modal.component.scss']
})
export class StockTransferDeliveryDetailModalComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;

  public bsConfig: BsDatepickerConfig;
  public deliveryDetailForm: FormGroup;
  public submitted: boolean;
  public dateFormat = env.dateFormat;

  private localStore: Observable<any>;

  constructor(public fb: FormBuilder, public store: Store<AppStates>, public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.bsConfig = {
      dateInputFormat: env.dateFormat,
      minDate: new Date(),
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue'
    } as BsDatepickerConfig;

    this.createForm();
  }

  createForm() {
    this.deliveryDetailForm = this.fb.group({
      deliveryDate: [
        {
          value: this.parentForm.get('deliveryDate').value,
          disabled: this.parentForm.get('deliveryDate').disabled
        },
        this.parentForm.get('deliveryDate').validator ? [Validators.required] : null
      ],
      deliveryNote: [
        {
          value: this.parentForm.get('deliveryNote').value,
          disabled: this.parentForm.get('deliveryNote').disabled
        }
      ]
    });
  }

  ngOnDestroy(): void {}

  decline(): void {
    this.bsModalRef.hide();
  }

  confirm(): void {
    this.submitted = true;
    if (this.deliveryDetailForm.invalid) {
      return;
    }

    const form = this.deliveryDetailForm.getRawValue();
    this.parentForm.patchValue({
      deliveryDate: form.deliveryDate,
      deliveryNote: form.deliveryNote
    });
    this.parentForm.markAsTouched();
    this.bsModalRef.hide();
  }
}
