import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { MasterService } from './master.service';

@Injectable()
export class OrderRequestExportService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private readonly masterService: MasterService) {
    super();
    this.envService = this.env.services.exportOrderRequestFixAsset;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public exportItemFixAsset(selected: string): Observable<any> {
    const url = this.getFullUrl(this.envService.export, {
      selected: selected
    });

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }
}
