import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { environment } from '../../../../environments/environment';
import { ReceiveOrderExceedReportExportCriteria } from '../../../shared/models/report.model';
import { ReceiveOrderService } from '../../../shared/services/receive-order.service';
import { AppStates } from '../../../shared/store/state/app.states';
import { dateToStringCriteria } from '../../../shared/utils/date-util';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-receive-order-exceed-modal',
  templateUrl: './receive-order-exceed-modal.component.html',
  styleUrls: ['./receive-order-exceed-modal.component.scss']
})
export class ReceiveOrderExceedModalComponent extends BaseReportModalComponent implements OnInit {
  @ViewChild('receiveOrderExceedModal', { static: false }) receiveOrderExceedModal: ModalDirective;
  public exportForm: FormGroup;
  public submitted: boolean;
  public exportFormInValid: boolean;
  public responseExportError: string;

  public maxDate: Date;
  public minDate: Date;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly formBuilder: FormBuilder,
    protected readonly translate: TranslateService,
    protected readonly receiveOrderService: ReceiveOrderService
  ) {
    super();
  }

  ngOnInit() {
    this.exportFormInValid = true;
    this.responseExportError = null;

    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.initControl();
  }

  initControl() {
    this.exportForm = this.formBuilder.group({
      receiveOrderDateFrom: [null],
      receiveOrderDateTo: [null]
    });
  }

  get form() {
    return this.exportForm.controls;
  }

  openExportModal() {
    this.exportForm.reset();
    this.responseExportError = null;
    this.exportFormInValid = true;
    this.receiveOrderExceedModal.show();
  }

  closeExportModal() {
    this.exportForm.reset();
    this.receiveOrderExceedModal.hide();
  }

  onExport() {
    this.submitted = true;
    if (this.exportFormInValid) {
      return;
    }
    this.responseExportError = null;
    const rawData = this.exportForm.getRawValue();
    const receiveOrderExceedReportExportCriteria = new ReceiveOrderExceedReportExportCriteria({
      receiveOrderDateFrom: dateToStringCriteria(rawData.receiveOrderDateFrom, true),
      receiveOrderDateTo: dateToStringCriteria(rawData.receiveOrderDateTo, false)
    });

    this.receiveOrderService.exceedExport(receiveOrderExceedReportExportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportReceiveOrderExceed.prefix} ${this.timeToExport}.xlsx`);
      },
      error => {
        this.responseExportError = error.error.message || this.translate.instant(error.error.translateKey);
      },
      () => {
        this.submitted = false;
        this.closeExportModal();
      }
    );
  }

  onChangeReceiveOrderDateFrom(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeReceiveOrderDateTo(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportReceiveOrderExceed.timeFormat);
  }
}
