import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { VoucherViewResponse } from '../models';
import { FileResponse } from '../models/fileResponse';
import { Progress } from '../models/progress.model';
import {
  DuplicatedSupplier,
  ImportSupplierDto,
  SupplierPagination,
  SupplierSearchCriteria,
  SupplierViewResponse
} from '../models/supplier.model';
import { BaseService } from './base.service';

type UploadResultType = Progress | FileResponse | null | any;

@Injectable()
export class SupplierService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.supplier;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  getByCode(supplierCode: string): Observable<SupplierViewResponse> {
    const url = this.getFullUrl(this.envService.get, { supplierCode });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  edit(data: SupplierViewResponse): Observable<SupplierViewResponse> {
    const url = this.getUrl();
    return this.http.put<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  ediSetting(data: { ediSetting: boolean; supplierCode: string }): Observable<SupplierViewResponse> {
    const url = this.getFullUrl(this.envService.ediSetting);
    return this.http.put<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  activate(obj: any): Observable<SupplierViewResponse> {
    const url = this.getFullUrl(this.envService.activate);
    return this.http.put<SupplierViewResponse>(url, obj, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  validateSupplier(data: DuplicatedSupplier): Observable<DuplicatedSupplier> {
    const url = this.getFullUrl(this.envService.validate);
    return this.http.post<DuplicatedSupplier>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  searchSupplierByName(term) {
    const criteria = { ...term, size: 100 };
    const params = this.getParams(criteria);

    return this.http
      .get<any>(this.getUrl(), {
        headers: this.headers,
        observe: 'body',
        params
      })
      .pipe(
        tap(suppliers => {
          if (suppliers) {
            suppliers.forEach(supplier => {
              supplier.supplierNameDisplay = `${supplier.supplierCode} ${supplier.supplierName} (${supplier.branchNo})`;
            });
          }
        })
      );
  }

  public searchByCriteria(criteria: SupplierSearchCriteria): Observable<SupplierPagination> {
    const url = this.getFullUrl(this.envService.list);
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public validateImportFiles(files: Array<File>): Array<Observable<UploadResultType>> {
    return files.map(file => this.validateImportFile(file));
  }

  public validateImportFile(file: File): Observable<UploadResultType> {
    const url = this.getFullUrl(this.envService.importValidate);
    const formData = new FormData();
    formData.append('file', file, file.name);
    this.headers.append('Content-Type', 'multipart/form-data');
    return this.http
      .post<any>(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers: this.loaderHeaders()
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };
            case HttpEventType.Response:
              return { ...event.body, status: 'done' };
            default:
              return null;
          }
        }),
        catchError(err => of(err))
      );
  }

  downloadFileTemplate() {
    const url = this.getFullUrl(this.envService.downloadFileTemplate);
    return this.http.get<any>(url, { headers: this.headers, responseType: 'blob' as 'json' });
  }

  exportSupplier(): Observable<any> {
    const url = this.getFullUrl(this.envService.export);
    const headers = new HttpHeaders(this.envService.headers).set('x-frontend-spinner-loader', '0');
    return this.http.get<any>(url, {
      headers,
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  submitImportSupplier(importSupplier: ImportSupplierDto[]): Observable<VoucherViewResponse> {
    const url = this.getFullUrl(this.envService.importSubmit);
    return this.http.post<any>(url, importSupplier, {
      headers: this.headers,
      observe: 'body'
    });
  }

  getBySupplierCodeFromPurchase(supplierCode: string): Observable<SupplierViewResponse> {
    const url = this.getFullUrl(this.envService.get, { supplierCode });
    const headers: HttpHeaders = new HttpHeaders(this.env.services.purchaseCondition.headers);
    return this.http.get<any>(url, {
      headers,
      observe: 'body'
    });
  }

  searchSupplierByNameFromPurchase(term) {
    const criteria = { ...term, size: 100 };
    const params = this.getParams(criteria);
    const headers: HttpHeaders = new HttpHeaders(this.env.services.purchaseCondition.headers);

    return this.http
      .get<any>(this.getUrl(), {
        headers: headers,
        observe: 'body',
        params
      })
      .pipe(
        tap(suppliers => {
          if (suppliers) {
            suppliers.forEach(supplier => {
              supplier.supplierNameDisplay = `${supplier.supplierCode} ${supplier.supplierName} (${supplier.branchNo})`;
            });
          }
        })
      );
  }
}
