import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsDatepickerConfig, BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { LocationTypeEnum } from '../../../../shared/enum/manage-stock.emun';
import { ProductTypeEnum } from '../../../../shared/enum/product-type.enum';
import { StockMovementTypeEnum } from '../../../../shared/enum/stock-movement-type.enum';
import { SearchLocationCriteria, StockLocation, StockMovementContent } from '../../../../shared/models';
import * as filterDropdown from '../../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService } from '../../../../shared/services';
import { MasterService } from '../../../../shared/services/master.service';
import { StockLocationsService } from '../../../../shared/services/stock-locations.service';
import {
  StockInformationMovementRequestAction,
  StockInformationMovementResetAction
} from '../../../../shared/store/actions/stock-information.actions';
import { StockInformationMovementState } from '../../../../shared/store/reducers/stock-information-movement.reducers';
import {
  selectAllStockWarehouseMovementList,
  selectStockWarehouseMovementList,
  selectStockWarehouseMovementListCriteria
} from '../../../../shared/store/selectors/stock-information.selector';
import { AppStates } from '../../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../../shared/utils/date-util';
@Component({
  selector: 'app-stock-movement',
  templateUrl: './stock-movement.component.html',
  styleUrls: ['./stock-movement.component.scss']
})
export class StockMovementComponent implements OnInit, OnDestroy {
  @Input() warehouse: string;
  @Input() articleNo: string;
  @Input() productType: string;
  @Input() stockLocation: StockLocation;

  public dateFormat = environment.dateFormat;
  public dateTimeDisplay = environment.dateTimeDisplay;
  public productTypeEnum = ProductTypeEnum;
  public stockLocationEnum = StockLocation;
  public isShowAdvanceSearch: boolean;
  public criteriaObject: any;
  public currentPage: number;
  public pageSize: number;
  public searchForm: FormGroup;
  resultMovementList$: Observable<StockMovementContent[]>;
  listMovementState$: Observable<StockInformationMovementState>;
  private localStore: Observable<any>;
  public movementMaxDate: Date = new Date();
  public movementMinDate: Date;
  public movementDateTag: string;
  public movementDateStringTag: string;
  public selectedlocationTag: string;
  public selectedlocationStringTag: string;
  public locationSearchLoading = false;

  public movementTypeTag: string;
  public movementTypeStringTag: string;
  public bsDateConfig = {
    containerClass: 'theme-dark-blue',
    dateInputFormat: this.dateFormat,
    showWeekNumbers: false,
    adaptivePosition: true
  } as BsDatepickerConfig;

  locationList$: Observable<NgOption[]>;
  public locationSearchInput$ = new Subject<string>();
  readonly stockMovementType = filterDropdown.stockMovementType;

  constructor(
    protected fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    protected readonly logger: NGXLogger,
    protected readonly masterService: MasterService,
    protected readonly modalService: BsModalService,
    protected readonly stockLocationService: StockLocationsService,
    protected authGuardService: AuthGuardService
  ) {}

  ngOnInit() {
    this.setInitialCriteriaObject();
    this.initialData();
    this.createSearchForm();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectStockWarehouseMovementListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.search();

    if (this.stockLocation === StockLocation.ALL_STORES) {
      this.loadLocation('');
    }
  }

  initialData() {
    this.resultMovementList$ = this.store.select(selectAllStockWarehouseMovementList);
    this.listMovementState$ = this.store.select(selectStockWarehouseMovementList);
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      searchCriteria: null,
      movementDateFrom: [null],
      movementDateTo: [null],
      location: [null],
      movementType: [null]
    });
  }

  onChangeRowPerPage(value: string) {
    this.setFirstPage();
    this.pageSize = Number(value);
    this.criteriaObject = {
      ...this.criteriaObject,
      size: Number(value),
      page: 0
    };
    this.search();
  }

  setInitialCriteriaObject() {
    this.pageSize = 20;

    this.criteriaObject = {
      page: 0,
      size: 20
    };
  }

  onSubmit() {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.prepareSearchCriteria(),
      page: 0
    };
    this.search();
  }

  search() {
    this.store.dispatch(
      new StockInformationMovementRequestAction({
        criteria: this.criteriaObject,
        warehouse: this.warehouse,
        articleNo: this.articleNo
      })
    );
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;

    if (!formValue.movementDateFrom && !formValue.movementDateTo && !formValue.location && !formValue.movementType) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.prepareSearchCriteria(),
      page: 0
    };

    this.prepareSearchCriteriaTags();
    this.search();
  }

  onClickedOutside(e) {
    if (
      e.target &&
      (e.target.classList.contains('is-highlighted') ||
        e.target.classList.contains('ng-option') ||
        e.target.classList.contains('ng-value-icon') ||
        e.target.classList.contains('ng-option-label'))
    ) {
      return;
    }
    this.isShowAdvanceSearch = false;
  }

  onChangePage(event: any) {
    this.criteriaObject = {
      ...this.criteriaObject,
      page: event.page - 1
    };
    this.search();
  }

  onChangeMovementDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.movementMinDate = value;
    } else {
      this.movementMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeMovementDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.movementMaxDate = value;
    } else {
      this.movementMaxDate = new Date();
      this.movementMaxDate.setDate(this.movementMaxDate.getDate() + 365);
    }
  }

  prepareSearchCriteria() {
    const formValue = this.searchForm.value;

    return {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      location: formValue.location ? formValue.location.map(v => v.value) : null,
      movementType: formValue.movementType ? this.prepareMovementType(formValue) : null,
      movementDateFrom: dateToStringCriteria(formValue.movementDateFrom, true),
      movementDateTo: dateToStringCriteria(formValue.movementDateTo, false)
    };
  }

  prepareMovementType(formValue) {
    return formValue.movementType.flatMap(val => {
      if (val.value === StockMovementTypeEnum.RECEIVED_GR) {
        if (this.stockLocation === StockLocation.DC) {
          return [StockMovementTypeEnum.RECEIVED_GR, StockMovementTypeEnum.RECEIVED_TO];
        } else if (this.stockLocation === StockLocation.ALL_STORES) {
          return [StockMovementTypeEnum.RECEIVED_RO];
        }
      } else {
        return val.value;
      }
    });
  }

  prepareSearchCriteriaTags() {
    this.prepareCreateDateTags();
    this.prepareLocationTags();
    this.prepareMovementTypeTags();
  }

  prepareCreateDateTags() {
    this.movementDateTag = null;
    const movementDateFrom = dateStringToTagCriteria(this.criteriaObject.movementDateFrom);
    const movementDateTo = dateStringToTagCriteria(this.criteriaObject.movementDateTo);
    const movementDate = generateDateStringTag({
      dateName: 'Movement Date',
      dateFrom: movementDateFrom,
      dateTo: movementDateTo
    });

    this.movementDateStringTag = movementDate.dateStringTag;
    this.movementDateTag = movementDate.dateTag;
  }

  prepareLocationTags() {
    this.selectedlocationTag = null;
    this.selectedlocationStringTag = null;

    if (this.criteriaObject.location && this.criteriaObject.location.length > 0) {
      this.selectedlocationStringTag = 'Location';
      this.selectedlocationTag = `"${this.searchForm.controls['location'].value.map(v => v.label)}"`;
    }
  }

  prepareMovementTypeTags() {
    this.movementTypeTag = null;
    this.movementTypeStringTag = null;

    if (this.criteriaObject.movementType && this.criteriaObject.movementType.length > 0) {
      this.movementTypeStringTag = 'Movement Type';
      this.movementTypeTag = `"${this.searchForm.controls['movementType'].value.map(v => v.label)}"`;
    }
  }

  loadLocation(initialTerm: string) {
    this.locationList$ = concat(
      of([]),
      this.locationSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.locationSearchLoading = true)),
        switchMap(term => {
          const param = {
            searchCriteria: term,
            locationType: this.locationType
          } as SearchLocationCriteria;

          return this.stockLocationService.location(param).pipe(
            map(result => {
              const items = [];
              result.content.forEach(item => {
                items.push({ value: item.code, label: `${item.code}-${item.name}` });
              });

              return items;
            }),
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.locationSearchLoading = false;
            })
          );
        })
      )
    );
  }

  clearLastKeyUpSearchText(event) {
    if (!event.target.value) {
      this.setFirstPage();
      this.criteriaObject = {
        ...this.criteriaObject,
        searchCriteria: null,
        page: 0
      };
      this.search();
    }
  }

  clearSearchText() {
    this.setFirstPage();
    this.searchForm.controls['searchCriteria'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: null,
      page: 0
    };
    this.search();
  }

  clearAdvanceFilter() {
    this.setFirstPage();
    this.resetMovementType();
    this.resetPeriodDate();
    this.resetLocation();
    this.prepareSearchCriteriaTags();
    this.search();
  }

  clearMovementDateFilter() {
    this.setFirstPage();
    this.resetPeriodDate();
    this.prepareSearchCriteriaTags();
    this.search();
  }

  clearLocationFilter() {
    this.setFirstPage();
    this.resetLocation();
    this.prepareSearchCriteriaTags();
    this.search();
  }

  clearMovementTypeFilter() {
    this.setFirstPage();
    this.resetMovementType();
    this.prepareSearchCriteriaTags();
    this.search();
  }

  resetLocation() {
    this.searchForm.controls['location'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      location: null,
      page: 0
    };
  }

  resetMovementType() {
    this.searchForm.controls['movementType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      movementType: null,
      page: 0
    };
  }

  resetPeriodDate() {
    this.searchForm.controls['movementDateFrom'].reset();
    this.searchForm.controls['movementDateTo'].reset();

    this.criteriaObject = {
      ...this.criteriaObject,
      movementDateFrom: null,
      movementDateTo: null,
      page: 0
    };
  }

  isShowQuantity(movementType: StockMovementTypeEnum): boolean {
    return [
      StockMovementTypeEnum.GOOD_SOLD_RCPT,
      StockMovementTypeEnum.RECEIVED_GR,
      StockMovementTypeEnum.RECEIVED_RO,
      StockMovementTypeEnum.DELIVERY_DO,
      StockMovementTypeEnum.CANCELLED_GR,
      StockMovementTypeEnum.INITIAL_STOCK,
      StockMovementTypeEnum.RECEIVED_TO
    ].some(v => v === movementType);
  }

  get locationType(): string[] {
    return [LocationTypeEnum.STORE];
  }

  ngOnDestroy(): void {
    this.store.dispatch(new StockInformationMovementResetAction());
  }
}
