<div [formGroup]="parentForm">
  <div formGroupName="purchaseQtyDetails">
    <div class="row">
      <div class="col-md-3 mb-3">
        <label for="purchaseQty">Purchase Quantity<span class="text-danger">*</span></label>
        <app-numeric-textbox
          [minValue]="1"
          [format]="'0,0'"
          [decimals]="0"
          [min]="1"
          placeholder="Purchase Quantity"
          formControlName="purchaseQty"
          maxlength="8"
          id="purchaseQty"
          [negativeSign]="false"
          [maxValue]="999999"
          ngDefaultControl
          [ngClass]="{
            'is-invalid': submitted && purchaseQuantityCoupon.controls.purchaseQty.errors
          }"
        >
        </app-numeric-textbox>
        <div *ngIf="submitted && purchaseQuantityCoupon.controls.purchaseQty.errors" class="invalid-feedback">
          <div *ngIf="purchaseQuantityCoupon.controls.purchaseQty.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
          <div *ngIf="purchaseQuantityCoupon.controls.purchaseQty.errors.isZero">
            {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="offerPromotion">Offer with Promotion<span class="text-danger">*</span></label>
        <ng-select
          id="offerPromotion"
          [searchable]="false"
          [clearable]="false"
          [items]="offerPromotionSelect"
          formControlName="offerPromotion"
          bindLabel="label"
          bindValue="value"
        >
        </ng-select>
      </div>
    </div>

    <app-purchase-quantity-product-item
      #purchaseQuantityCouponProductItem
      [parentForm]="parentForm"
      [maximumRow]="10"
      [submitted]="submitted"
      [saved]="saved"
      [mode]="mode"
      [isEditFirst]="isEditFirst"
      [conditionPurchase]="conditionPurchase"
    >
    </app-purchase-quantity-product-item>

    <hr class="section-divider" />
    <div>
      <div class="row section-header">
        <span>Offer Coupon</span>
      </div>

      <div class="row">
        <div class="col-md-3 mb-3">
          <label for="couponAmount">Coupon Value (THB)<span class="text-danger">*</span></label>
          <app-numeric-textbox
            [minValue]="0"
            [format]="'0,0.00'"
            [decimals]="2"
            placeholder="Coupon Value (THB)"
            formControlName="couponAmount"
            maxlength="9"
            [negativeSign]="false"
            id="couponAmount"
            [maxValue]="999999.99"
            ngDefaultControl
            [ngClass]="{
              'is-invalid': submitted && purchaseQuantityCoupon.controls.couponAmount.errors
            }"
          >
          </app-numeric-textbox>
          <div *ngIf="submitted && purchaseQuantityCoupon.controls.couponAmount.errors" class="invalid-feedback">
            <div *ngIf="purchaseQuantityCoupon.controls.couponAmount.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="purchaseQuantityCoupon.controls.couponAmount.errors.isZero">
              {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="minPerBillAmount">Minimum per bill<span class="text-danger">*</span></label>
          <app-numeric-textbox
            [minValue]="0"
            [format]="'0,0.00'"
            [decimals]="2"
            placeholder="Minimum per bill"
            formControlName="minPerBillAmount"
            maxlength="9"
            id="minPerBillAmount"
            [negativeSign]="false"
            [maxValue]="999999.99"
            ngDefaultControl
            [ngClass]="{
              'is-invalid': submitted && purchaseQuantityCoupon.controls.minPerBillAmount.errors
            }"
          >
          </app-numeric-textbox>
          <div *ngIf="submitted && purchaseQuantityCoupon.controls.minPerBillAmount.errors" class="invalid-feedback">
            <div *ngIf="purchaseQuantityCoupon.controls.minPerBillAmount.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
