import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { RewardCondition, RewardOffer, RewardPageModes } from '../../../shared/enum/reward.enum';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { RouteLinkTab } from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { RewardContent, RewardExportCriteria, RewardSearchCriteria } from '../../../shared/models/reward.model';
import { AuthGuardService } from '../../../shared/services';
import { RewardService } from '../../../shared/services/reward.service';
import { RewardCancelRequest, RewardListRequestAction } from '../../../shared/store/actions/reward.actions';
import { RewardState } from '../../../shared/store/reducers/reward.reducers';
import {
  selectAllRewardList,
  selectRewardList,
  selectRewardListCriteria
} from '../../../shared/store/selectors/reward.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { RewardViewComponent } from '../reward-view/reward-view.component';

@Component({
  selector: 'app-reward-list',
  templateUrl: './reward-list.component.html',
  styleUrls: ['./reward-list.component.scss']
})
export class RewardListComponent extends BaseSearchComponent<RewardSearchCriteria, RewardContent, RewardState> {
  private localStore: Observable<any>;
  private bsModalRef: BsModalRef;
  listRoute: Array<RouteLinkTab>;
  public readonly pageMode = RewardPageModes;

  public conditionSelect = filterDropdown.rewardCondition;
  public offerSelect = filterDropdown.rewardOffer;
  public offerExportSelect = filterDropdown.rewardOffer;

  public minDate: Date;
  public maxDate: Date;
  public offerToMaxDate: Date;
  public offerToMinDate: Date;
  public validMaxDate: Date;
  public validMinDate: Date;

  public exportValidMaxDate: Date;
  public exportValidMinDate: Date;

  public statusList: any[];
  public exportStatusList: any[];
  public dateFormat = environment.dateFormat;
  public offerFromDateTag: string;
  public offerFromDateStringTag: string;
  public offerToDateTag: string;
  public offerToDateStringTag: string;
  public validDateTag: string;
  public validDateStringTag: string;

  public conditionTag: string;
  public offerTag: string;
  isShowView: boolean;

  hasViewRewardPermission: boolean;
  hasEditRewardPermission: boolean;
  hasApproveRewardPermission: boolean;

  public exportOfferFromMaxDate: Date;
  public exportOfferFromMinDate: Date;
  public exportOfferToMaxDate: Date;
  public exportOfferToMinDate: Date;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    }
  ];

  @ViewChild('exportModal', { static: false }) exportModal: ModalDirective;
  public exportForm: FormGroup;
  public submittedExport: boolean;
  errorExport: string;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    private rewardService: RewardService
  ) {
    super(store, modalService, selectAllRewardList, selectRewardList);
    super.subscribeForSaveSuccess();
    this.statusList = filterDropdown.rewardStatusFilter;
    this.exportStatusList = [...filterDropdown.rewardStatusFilter.slice(1)];

    this.hasViewRewardPermission = this.authGuardService.checkPermission(['rw_v']);
    this.hasEditRewardPermission = this.authGuardService.checkPermission(['rw_m']);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectRewardListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.createExportForm();
  }

  ngAfterViewInit(): void {
    if (this.exportModal) {
      this.exportModal.onHidden.pipe(untilComponentDestroyed(this)).subscribe(() => {
        this.resetExportForm();
      });
    }
  }

  setInitialValue() {
    this.pageSize = 20;
    const todayDate = new Date();
    this.maxDate = todayDate;
    this.offerToMaxDate = todayDate;
    this.exportOfferFromMaxDate = todayDate;
    this.exportOfferToMaxDate = todayDate;
    this.exportValidMaxDate = todayDate;
    this.validMaxDate = todayDate;

    this.maxDate.setDate(todayDate.getDate() + 365);
    this.offerToMaxDate.setDate(todayDate.getDate() + 365);

    this.exportOfferFromMaxDate.setDate(todayDate.getDate() + 365);
    this.exportOfferToMaxDate.setDate(todayDate.getDate() + 365);
    this.exportValidMaxDate.setDate(todayDate.getDate() + 365);
    this.validMaxDate.setDate(todayDate.getDate() + 365);
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.statusList[0].value],
      condition: [null],
      offer: [null],
      offerFromDateFrom: [null],
      offerFromDateTo: [null],
      offerToDateFrom: [null],
      offerToDateTo: [null],
      validDateFrom: [null],
      validDateTo: [null]
    });
  }

  createExportForm() {
    this.exportForm = this.fb.group({
      condition: [null],
      offer: [null],
      offerFromDateFrom: [null],
      offerFromDateTo: [null],
      offerToDateFrom: [null],
      offerToDateTo: [null],
      validDateFrom: [null],
      validDateTo: [null],
      status: [null]
    });
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (
      !formValue.offerFromDateFrom &&
      !formValue.offerFromDateTo &&
      !formValue.offerToDateFrom &&
      !formValue.offerToDateTo &&
      !formValue.condition &&
      !formValue.offer &&
      !formValue.validDateFrom &&
      !formValue.validDateTo
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      condition: formValue.condition,
      offer: formValue.offer,
      offerFromDateFrom: dateToStringCriteria(formValue.offerFromDateFrom),
      offerFromDateTo: dateToStringCriteria(formValue.offerFromDateTo, false),
      offerToDateFrom: dateToStringCriteria(formValue.offerToDateFrom),
      offerToDateTo: dateToStringCriteria(formValue.offerToDateTo, false),
      validDateFrom: dateToStringCriteria(formValue.validDateFrom),
      validDateTo: dateToStringCriteria(formValue.validDateTo, false),
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  doDestroy() {}

  clearFilterOfferToDate() {
    this.searchForm.controls['offerToDateFrom'].reset();
    this.searchForm.controls['offerToDateTo'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      offerToDateFrom: null,
      offerToDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterCondition() {
    this.searchForm.controls['condition'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      condition: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterOffer() {
    this.searchForm.controls['offer'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      offer: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterOfferFromDate() {
    this.searchForm.controls['offerFromDateFrom'].reset();
    this.searchForm.controls['offerFromDateTo'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      offerFromDateFrom: null,
      offerFromDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterValidDate() {
    this.setFirstPage();
    this.searchForm.controls['validDateFrom'].reset();
    this.searchForm.controls['validDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      validDateFrom: null,
      validDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['offerToDateFrom'].reset();
    this.searchForm.controls['offerToDateTo'].reset();
    this.searchForm.controls['offerFromDateFrom'].reset();
    this.searchForm.controls['offerFromDateTo'].reset();
    this.searchForm.controls['offer'].reset();
    this.searchForm.controls['condition'].reset();
    this.searchForm.controls['validDateFrom'].reset();
    this.searchForm.controls['validDateTo'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      offerToDateFrom: null,
      offerToDateTo: null,
      offerFromDateFrom: null,
      offerFromDateTo: null,
      offer: null,
      condition: null,
      validDateFrom: null,
      validDateTo: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeStatus(event) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onExportConditionChange(event): void {
    if (event.value === RewardCondition.SUPPLIER_COUPON) {
      this.offerExportSelect = filterDropdown.rewardOffer.filter(v => v.value === RewardOffer.COUPON);
      if (this.exportForm.controls['offer'].value !== RewardOffer.COUPON) {
        this.exportForm.controls['offer'].setValue(null);
      }
    } else {
      this.offerExportSelect = filterDropdown.rewardOffer;
    }
  }

  onChangeOfferToDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.offerToMinDate = new Date(value);
    } else {
      this.offerToMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeOfferToDateTo(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.offerToMaxDate = new Date(value);
    } else {
      this.offerToMaxDate = new Date();
      this.offerToMaxDate.setDate(this.offerToMaxDate.getDate() + 365);
    }
  }

  onChangeValidDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.validMinDate = new Date(value);
    } else {
      this.validMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeValidDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.validMaxDate = new Date(value);
    } else {
      this.validMaxDate = new Date();
      this.validMaxDate.setDate(this.validMaxDate.getDate() + 365);
    }
  }

  showHistory(request) {
    const initialState = {
      title: 'History',
      action: HistoryType.REQUEST,
      historyHeader: `Reward No.: ${request.rewardNo}`,
      historyType: HistoryType.REWARD,
      auditLogs: request.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  prepareSearchCriteriaTags() {
    this.offerFromDateTag = null;
    this.offerFromDateStringTag = null;
    this.offerToDateTag = null;
    this.offerToDateStringTag = null;
    this.offerTag = null;
    this.conditionTag = null;
    this.validDateStringTag = null;
    this.validDateTag = null;

    const offerFromDateFrom = dateStringToTagCriteria(this.criteriaObject.offerFromDateFrom);
    const offerFromDateTo = dateStringToTagCriteria(this.criteriaObject.offerFromDateTo);
    const offerFromDate = generateDateStringTag({
      dateName: 'Offer from Date',
      dateFrom: offerFromDateFrom,
      dateTo: offerFromDateTo
    });

    this.offerFromDateStringTag = offerFromDate.dateStringTag;
    this.offerFromDateTag = offerFromDate.dateTag;

    const offerToDateFrom = dateStringToTagCriteria(this.criteriaObject.offerToDateFrom);
    const offerToDateTo = dateStringToTagCriteria(this.criteriaObject.offerToDateTo);
    const offerToDate = generateDateStringTag({
      dateName: 'Offer to Date',
      dateFrom: offerToDateFrom,
      dateTo: offerToDateTo
    });

    this.offerToDateStringTag = offerToDate.dateStringTag;
    this.offerToDateTag = offerToDate.dateTag;

    if (this.criteriaObject.offer && this.criteriaObject.offer.length > 0) {
      const listTags = this.criteriaObject.offer
        .map(value => filterDropdown.rewardOffer.find(obj => obj.value === value).label)
        .join(', ');
      this.offerTag = `"${listTags}"`;
    }

    if (this.criteriaObject.condition && this.criteriaObject.condition.length > 0) {
      const listTags = this.criteriaObject.condition
        .map(value => filterDropdown.rewardCondition.find(obj => obj.value === value).label)
        .join(', ');
      this.conditionTag = `"${listTags}"`;
    }

    const validDateFrom = dateStringToTagCriteria(this.criteriaObject.validDateFrom);
    const validDateTo = dateStringToTagCriteria(this.criteriaObject.validDateTo);
    const validDate = generateDateStringTag({
      dateName: 'Valid Date',
      dateFrom: validDateFrom,
      dateTo: validDateTo
    });

    this.validDateStringTag = validDate.dateStringTag;
    this.validDateTag = validDate.dateTag;
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new RewardListRequestAction(criteriaObj));
  }

  goToView(viewParams?: any) {
    console.log(viewParams);

    this.isShowView = true;
    const initialState = {
      title: null,
      childItem: new ChildItem(
        RewardViewComponent,
        {
          title: 'View Reward',
          mode: RewardPageModes.VIEW,
          rewardNo: viewParams.rewardNo
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });

    this.bsModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe(result => {
      if (ModalButtonResponseEnum.CLOSE === result) {
        this.isShowView = false;
      }
    });
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission(['rw_m', 'rw_app', 'rw_v']);
    const hasRequestPagePermission = this.authGuardService.checkPermission(['rw_app', 'rw_m', 'rr_v']);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Active Reward', url: '/campaign/reward-active-list' });
      this.listRoute.push({ tabName: 'Reward List', url: '/campaign/reward-list' });
    }
    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Reward Request', url: '/campaign/reward-request-list' });
    }
  }

  showCancelButton(status: string): boolean {
    if (status.toLocaleLowerCase() === 'awaiting_schedule' || status.toLocaleLowerCase() === 'active') {
      return true;
    }

    return false;
  }

  goToCancel(item: any) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel Reward No. <strong>&quot;${item.rewardNo}&quot;</strong>?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new RewardCancelRequest({
              id: item.rewardNo,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  onExport() {
    this.submittedExport = true;

    if (this.exportFormInValid) {
      return;
    }

    const formData = this.exportForm.getRawValue();
    const request = this.prepareExportRequestData(formData);

    this.errorExport = null;
    this.rewardService.exportReward(request).subscribe(
      response => {
        const blob = new Blob([response]);

        saveAs(blob, this.generatedFileName());

        this.resetExportForm();
        this.exportModal.hide();
      },
      error => {
        if (error.error && error.error.message) {
          this.errorExport = error.error.message;
        }
      }
    );
  }

  generatedFileName() {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.exportReward.timeFormat);

    return `${environment.fileName.exportReward.prefix} ${formattedDate}.xlsx`;
  }

  prepareExportRequestData(formData): RewardExportCriteria {
    const validDateFrom = dateToStringCriteria(formData.validDateFrom);
    const validDateTo = dateToStringCriteria(formData.validDateTo, false);
    const offerFromDateFrom = dateToStringCriteria(formData.offerFromDateFrom);
    const offerFromDateTo = dateToStringCriteria(formData.offerFromDateTo, false);
    const offerToDateFrom = dateToStringCriteria(formData.offerToDateFrom);
    const offerToDateTo = dateToStringCriteria(formData.offerToDateTo, false);

    const data = {
      validDateFrom,
      validDateTo,
      condition: formData.condition,
      offer: formData.offer,
      offerFromDateFrom,
      offerFromDateTo,
      offerToDateFrom,
      offerToDateTo,
      status: formData.status
    };

    return new RewardExportCriteria({
      ...data
    });
  }

  onChangeExportOfferFromDateFrom(value): void {
    if (value && !isNaN(value.getTime())) {
      this.exportOfferFromMinDate = new Date(value);
    } else {
      this.exportOfferFromMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeExportOfferFromDateTo(value): void {
    if (value && !isNaN(value.getTime())) {
      this.exportOfferFromMaxDate = new Date(value);
    } else {
      this.exportOfferFromMaxDate = new Date();
      this.exportOfferFromMaxDate.setDate(this.exportOfferFromMaxDate.getDate() + 365);
    }
  }

  onChangeExportOfferToDateFrom(value): void {
    if (value && !isNaN(value.getTime())) {
      this.exportOfferToMinDate = new Date(value);
    } else {
      this.exportOfferToMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeExportOfferToDateTo(value): void {
    if (value && !isNaN(value.getTime())) {
      this.exportOfferToMaxDate = new Date(value);
    } else {
      this.exportOfferToMaxDate = new Date();
      this.exportOfferToMaxDate.setDate(this.exportOfferToMaxDate.getDate() + 365);
    }
  }

  onChangeExportValidDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.exportValidMinDate = new Date(value);
    } else {
      this.exportValidMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeExportValidDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.exportValidMaxDate = new Date(value);
    } else {
      this.exportValidMaxDate = new Date();
      this.exportValidMaxDate.setDate(this.exportValidMaxDate.getDate() + 365);
    }
  }

  getColorStatus(status: string): string {
    return status.toLowerCase();
  }

  getRewardCondition(condition: string): string {
    const rewardCondition = filterDropdown.rewardCondition.find(obj => obj.value === condition);
    return rewardCondition && rewardCondition.label;
  }

  getRewardOffer(offer: string): string {
    const rewardOffer = filterDropdown.rewardOffer.find(obj => obj.value === offer);
    return rewardOffer && rewardOffer.label;
  }

  resetExportForm() {
    this.exportForm.controls['condition'].reset();
    this.exportForm.controls['offer'].reset();
    this.exportForm.controls['offerFromDateFrom'].reset();
    this.exportForm.controls['offerFromDateTo'].reset();
    this.exportForm.controls['offerToDateFrom'].reset();
    this.exportForm.controls['offerToDateTo'].reset();
    this.exportForm.controls['validDateFrom'].reset();
    this.exportForm.controls['validDateTo'].reset();
    this.exportForm.controls['status'].reset();
    this.submittedExport = false;
    this.errorExport = null;
  }

  get exportFormInValid(): boolean {
    const formValues = this.exportForm.value;
    return !(
      formValues.validDateFrom ||
      formValues.validDateTo ||
      formValues.condition ||
      formValues.offer ||
      formValues.offerFromDateFrom ||
      formValues.offerFromDateTo ||
      formValues.offerToDateFrom ||
      formValues.offerToDateTo ||
      (formValues.status && formValues.status.length > 0)
    );
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.search(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }
}
