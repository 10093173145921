<div id="product-assortment" class="admin-form mt-2">
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap d-none d-md-block">
        <button type="button" class="btn btn-standard ml-2 my-3" (click)="showShelfDetails()">
          <em class="icon-shelf"></em>
          Shelf Details
        </button>
      </div>
      <div class="flex-item d-md-flex justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="form-control searchCriteria"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="masterAssortmentStatusList"
            [searchable]="false"
            [clearable]="false"
            class="ng-select-width"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
            (change)="onChangeStatus($event)"
          >
          </ng-select>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="mt-3 table-responsive">
      <table id="transactions" class="table table-striped table-bordered table-hover border-none">
        <thead>
          <tr>
            <th class="text-center">No.</th>
            <th class="text-center">Shelf Name</th>
            <th class="text-center">Barcode</th>
            <th class="text-center">Product</th>
            <th class="text-center">Unit</th>
            <th class="text-center">Unit Factor</th>
            <th class="text-center">PLG</th>
            <th class="text-center">Tier</th>
            <th class="text-center">Grading</th>
            <th class="text-center">Stock</th>
            <th class="text-center">Z8 Factor</th>
            <th class="text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of resultList$ | async; index as i">
            <td class="text-center">{{ getItemNo(i) }}</td>
            <td>{{ result.shelfName }}</td>
            <td>
              {{ result.barcode }}
              <span *ngIf="!result.productName" class="d-block note invalid-feedback"
                >Barcode status is mark deleted.
              </span>
            </td>
            <td>
              {{ result.productName }}
              <span class="d-block">{{ result.articleNo }}</span>
            </td>
            <td class="text-center">{{ result.unit }}</td>
            <td class="text-center">{{ result.unitFactor }}</td>
            <td class="text-center">{{ result.productLocation }}</td>
            <td class="text-center">{{ result.productTier }}</td>
            <td class="text-center">{{ result.productGrading }}</td>
            <td class="text-center">{{ result.stock | numberFormatDisplay: 0 }}</td>
            <td class="text-center">
              <a class="btn-text-link d-inline-block" (click)="showZ8Factor(result)">View</a>
            </td>
            <td class="text-center status" [ngClass]="getColorStatus(result.status)">
              {{ result.status | titlecase }}
            </td>
          </tr>
        </tbody>
      </table>

      <!--pagination-->
      <app-search-pagination
        [currentPage]="currentPage"
        [pageSize]="pageSize"
        [listState$]="listState$"
        (changePage)="onChangePage($event)"
        (changeRowPerPage)="onChangeRowPerPage($event)"
      >
      </app-search-pagination>
    </div>
  </ng-container>
</div>
