import { roundToDecimalPlace } from './number-util';

export const calculateVatAmount = (
  unitPrice: number,
  quantity: number,
  vatPct: number,
  isProductVat: boolean,
  isSupplierVat: boolean
) => {
  if ((isProductVat && isSupplierVat) || (!isProductVat && !isSupplierVat && vatPct)) {
    return roundToDecimalPlace(roundToDecimalPlace(unitPrice * quantity * (vatPct / 100), 8), 4);
  }
  return 0;
};
