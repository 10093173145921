<div class="modal-content admin-form">
  <div class="modal-header">
    <h5 class="modal-title pull-left">Import</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [formGroup]="form">
    <div class="form-row">
      <div class="form-group col-md">
        <label for="profitReport">Supplier List Template</label>
        <div class="d-flex">
          <input
            type="text"
            class="form-control mr-3"
            id="profitReport"
            placeholder="Supplier List Template"
            disabled
          />
          <button type="button" class="btn btn-primary" (click)="downloadStockAdjustmentTemplate()">
            <em class="icon-download"></em>
            {{ 'REPORTS.DOWNLOAD' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md">
        <label for="uploadSupplier">New Supplier List</label>
        <div class="d-flex">
          <app-supplier-file-upload
            id="uploadSupplier"
            formControlName="uploadSupplier"
            class="w-100"
            [hasError]="form.controls.uploadSupplier.errors"
            [allowedTypes]="'sheet'"
            [size]="5000000"
            [controlName]="'uploadSupplier'"
            [allowedExt]="'xlsx'"
            descriptionTxt=""
            fileSizeErrorTxt="Size up to 5 MB."
            [fileModule]=""
            [runDate]="runDate"
          ></app-supplier-file-upload>
        </div>
      </div>
    </div>
    <div
      class="form-row"
      *ngIf="
        form.controls.uploadSupplier.errors ||
        (form.controls.uploadSupplier.value &&
          form.controls.uploadSupplier.value[0] &&
          form.controls.uploadSupplier.value[0].status)
      "
    >
      <div class="form-group col-md">
        <div
          class="alert-success form-group mb-0"
          *ngIf="(form.controls.uploadSupplier.value && form.controls.uploadSupplier.value[0].status) === 'COMPLETED'"
        >
          <div>
            The data have been uploaded. Are you sure you want to submit?
          </div>
        </div>
        <div
          class="alert-danger form-group mb-0"
          *ngIf="
            form.controls.uploadSupplier.errors ||
            (form.controls.uploadSupplier.value && form.controls.uploadSupplier.value[0].status === 'FAILED')
          "
        >
          <div *ngIf="form.controls.uploadSupplier.errors?.fileExt">
            Incorrect Format (allow only format file .xlsx).
          </div>
          <div
            *ngIf="form.controls.uploadSupplier.value && form.controls.uploadSupplier.value[0].status === 'FAILED'"
          >
            {{ form.controls.uploadSupplier.value[0].errorMessage }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="table-responsive max-height-table"
      *ngIf="
        form.controls.uploadSupplier.value &&
        form.controls.uploadSupplier.value[0] &&
        form.controls.uploadSupplier.value[0].validations &&
        form.controls.uploadSupplier.value[0].validations.length > 0
      "
    >
      <table class="table table-striped table-bordered table-hover w-100">
        <thead>
          <tr class="error-background text-center">
            <th scope="col">Row</th>
            <th scope="col">Column</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of form.controls.uploadSupplier.value[0].validations; index as i">
            <td class="text-center">{{ item.row }}</td>
            <td>{{ item.column }}</td>
            <td>{{ item.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="bsModalRef.hide()" id="btCancel">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      appPreventDoubleClick
      [disabled]="
        form.controls.uploadSupplier.errors ||
        !form.controls.uploadSupplier.value ||
        form.controls.uploadSupplier.value.length === 0 ||
        (form.controls.uploadSupplier.value &&
          form.controls.uploadSupplier.value[0] &&
          form.controls.uploadSupplier.value[0].status === 'FAILED') ||
        isSubmit
      "
      id="btnSubmit"
      (click)="onSubmit()"
    >
      Submit
    </button>
  </div>
</div>
