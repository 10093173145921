import { MemoizedSelector } from '@ngrx/store';
import { ShelfPages, TDStorePage } from '../enum/td-store-page.enum';
import { selectMerchantRequest } from '../store/selectors/merchant-request.selector';
import { selectMerchantById } from '../store/selectors/merchant.selectors';
import { selectShelfFixAssetRequestById } from '../store/selectors/shelf-fix-asset-request.selectors';
import { selectShelfFixAssetById } from '../store/selectors/shelf-fix-asset.selectors';
import { selectShelfInventoryRequestById } from '../store/selectors/shelf-inventory-request.selectors';
import { selectShelfInventoryById } from '../store/selectors/shelf-inventory.selectors';
import { selectShelfRequestById } from '../store/selectors/shelf-request.selectors';
import { selectShelfById } from '../store/selectors/shelf.selectors';
import { selectStoreRequestById } from '../store/selectors/store-request.selectors';
import { selectStoreById } from '../store/selectors/store.selectors';
import { AppStates } from '../store/state/app.states';

export const getSelectByPage: any = (page: TDStorePage): MemoizedSelector<AppStates, any> | undefined => {
  switch (page) {
    case TDStorePage.MERCHANT_REQUEST:
      return selectMerchantRequest;
    case TDStorePage.MERCHANT_EDIT:
      return selectMerchantById;
    case TDStorePage.STORE_EDIT:
    case TDStorePage.STORE_EDIT_ORDER_SCHEDULE:
      return selectStoreById;
    case TDStorePage.STORE_REQUEST:
      return selectStoreRequestById;
    default:
      return;
  }
};

export const getSelectShelf: any = (page: ShelfPages): MemoizedSelector<AppStates, any> | undefined => {
  switch (page) {
    case ShelfPages.SHELF_REQUEST:
      return selectShelfRequestById;
    case ShelfPages.SHELF_EDIT:
      return selectShelfById;
    default:
      return;
  }
};

export const getSelectShelfPage: any = (page: ShelfPages): MemoizedSelector<AppStates, any> | undefined => {
  switch (page) {
    case ShelfPages.SHELF_REQUEST:
      return selectShelfFixAssetRequestById;
    case ShelfPages.SHELF_REQUEST_FIX_ASSET:
      return selectShelfFixAssetRequestById;
    case ShelfPages.SHELF_REQUEST_INVENTORY:
      return selectShelfInventoryRequestById;
    case ShelfPages.SHELF_EDIT:
      return selectShelfById;
    case ShelfPages.SHELF_INVENTORY_EDIT:
      return selectShelfInventoryById;
    case ShelfPages.SHELF_FIX_ASSET_EDIT:
      return selectShelfFixAssetById;
    default:
      return;
  }
};
