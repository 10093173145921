import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { PosAdvertisementService } from '../../services/pos-advertisement.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import { PosAdvertisementActionTypes, PosAdvertisementCreateErrorAction, PosAdvertisementGetByIdResponseAction, PosAdvertisementListResponseAction, PosDefaultAdvertisementListResponseAction } from '../actions/pos-advertisement.actions';
export class PosAdvertisementEffects {
    constructor(actions$, logger, posAdvertisementService) {
        this.actions$ = actions$;
        this.logger = logger;
        this.posAdvertisementService = posAdvertisementService;
        this.getPosDefaultAdvertisement$ = this.actions$.pipe(ofType(PosAdvertisementActionTypes.POS_DEFAULT_ADS_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${PosAdvertisementActionTypes.POS_DEFAULT_ADS_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(() => {
            return this.posAdvertisementService.getPosDefaultAds().pipe(map(requests => {
                return new PosDefaultAdvertisementListResponseAction(requests);
            }), catchError(err => {
                const isEmptyDefaultAds = err.status === 500;
                return isEmptyDefaultAds ? of() : of(new LayoutActionLoadError(err));
            }));
        }));
        this.searchPosAdvertisement$ = this.actions$.pipe(ofType(PosAdvertisementActionTypes.POS_ADS_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${PosAdvertisementActionTypes.POS_ADS_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.posAdvertisementService.searchByCriteria(payload).pipe(map(requests => {
                return new PosAdvertisementListResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.saveDefault$ = this.actions$.pipe(ofType(PosAdvertisementActionTypes.POS_ADS_CREATE_DEFAULT), tap(action => this.logger.debug('@Effect Save Advertisement: ' + JSON.stringify(action.payload))), switchMap(action => this.posAdvertisementService.submit(action.payload).pipe(map(() => {
            return new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'Default Ads has been created.'
            });
        }), catchError(err => err.error && err.error.code === '07002'
            ? of(new PosAdvertisementCreateErrorAction(err.error))
            : of(new LayoutActionLoadError(err))))));
        this.saveSeasonal$ = this.actions$.pipe(ofType(PosAdvertisementActionTypes.POS_ADS_CREATE_SEASONAL), tap(action => this.logger.debug('@Effect Save Advertisement: ' + JSON.stringify(action.payload))), switchMap(action => this.posAdvertisementService.submit(action.payload).pipe(map(() => {
            return new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'Seasonal Ads has been created.'
            });
        }), catchError(err => err.error && err.error.code === '07002'
            ? of(new PosAdvertisementCreateErrorAction(err.error))
            : of(new LayoutActionLoadError(err))))));
        this.cancelAds$ = this.actions$.pipe(ofType(PosAdvertisementActionTypes.POS_ADS_CANCEL_REQUESTED), tap(action => this.logger.debug('@Effect Advertisement Cancel: ' + JSON.stringify(action.payload))), switchMap(action => this.posAdvertisementService.cancel(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'Seasonal Ads has been cancelled.'
        })), catchError(err => {
            return err.error && err.error.code === '00004'
                ? of(new LayoutActionVersionError(true))
                : of(new LayoutActionLoadError(err));
        }))));
        this.getById$ = this.actions$.pipe(ofType(PosAdvertisementActionTypes.POS_ADS_GET_BY_ID_REQUEST), tap(action => this.logger.debug('@Effect PosAdvertisement By ID Load: ' + JSON.stringify(action.payload))), mergeMap(action => this.posAdvertisementService.getById(action.payload.adsNo).pipe(map(result => {
            return new PosAdvertisementGetByIdResponseAction(result);
        }), catchError(err => of(new LayoutActionLoadError(err))))));
        this.updateAds$ = this.actions$.pipe(ofType(PosAdvertisementActionTypes.POS_ADS_EDIT_DEFAULT), map(action => {
            this.logger.debug('@Effect Update Ads: ' + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.posAdvertisementService.update(payload).pipe(map(() => new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'Default Ads has been updated.'
            })), catchError(err => err.error && err.error.code === '07002'
                ? of(new PosAdvertisementCreateErrorAction(err.error))
                : of(new LayoutActionLoadError(err))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PosAdvertisementEffects.prototype, "getPosDefaultAdvertisement$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PosAdvertisementEffects.prototype, "searchPosAdvertisement$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PosAdvertisementEffects.prototype, "saveDefault$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PosAdvertisementEffects.prototype, "saveSeasonal$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PosAdvertisementEffects.prototype, "cancelAds$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PosAdvertisementEffects.prototype, "getById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], PosAdvertisementEffects.prototype, "updateAds$", void 0);
