import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { OrderDeliveryScheduleType } from '../enum/purchase-condition.enum';
import {
  ItemConditionListCriteria,
  ItemConditionListResponse,
  PurchaseConditionListResponse,
  PurchaseConditionRequestListSearchCriteria,
  PurchaseConditionRequestWeeklyScheduleImportCriteria,
  PurchaseConditionRequestWeeklyScheduleResponse,
  SupplierContacts,
  SupplierDistributionCenters
} from '../models/purchase-condition-request.model';
import {
  PurchaseConditionSupplierContractModel,
  PurchaseConditionSupplierDcModel,
  PurchaseConditionViewResponse
} from '../models/purchase-condition.model';
import { BaseService } from './base.service';

@Injectable()
export class PurchaseConditionService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, protected readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.purchaseCondition;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(
    criteria: PurchaseConditionRequestListSearchCriteria
  ): Observable<PurchaseConditionListResponse> {
    const params = this.getParams(criteria, true);

    return this.http.get<PurchaseConditionListResponse>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getPurchaseConditionViewRequested(supplierCode: string): Observable<PurchaseConditionViewResponse> {
    const url = this.getFullUrl(this.envService.getViewById, {
      supplierCode
    });

    return this.http.get<PurchaseConditionViewResponse>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getPurchaseConditionDiscountRequested(
    criteria: ItemConditionListCriteria
  ): Observable<ItemConditionListResponse> {
    const url = this.getFullUrl(this.envService.discount);
    const params = this.getParams(criteria, true);

    return this.http.get<ItemConditionListResponse>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getPurchaseConditionWeeklyScheduleRequested(
    criteria: PurchaseConditionRequestWeeklyScheduleImportCriteria
  ): Observable<PurchaseConditionRequestWeeklyScheduleResponse> {
    let url;
    if (criteria.scheduleMode === OrderDeliveryScheduleType.MONTHLY) {
      url = this.getFullUrl(this.envService.monthlySchedule);
    } else {
      url = this.getFullUrl(this.envService.weeklySchedule);
    }

    const params = this.getParams(criteria, true);

    return this.http.get<PurchaseConditionRequestWeeklyScheduleResponse>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getPurchaseConditionSupplierContactRequested(supplierCode: {
    supplierCode: string;
  }): Observable<SupplierContacts[]> {
    const url = this.getFullUrl(this.envService.supplierContract);
    const params = this.getParams(supplierCode, true);

    return this.http.get<SupplierContacts[]>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getPurchaseConditionSupplierDcRequested(supplierCode: {
    supplierCode: string;
  }): Observable<SupplierDistributionCenters[]> {
    const url = this.getFullUrl(this.envService.supplierDc);
    const params = this.getParams(supplierCode, true);

    return this.http.get<SupplierDistributionCenters[]>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public addPurchaseConditionSupplierContract(
    payload: PurchaseConditionSupplierContractModel
  ): Observable<PurchaseConditionSupplierContractModel> {
    const url = this.getFullUrl(this.envService.supplierContract);
    return this.http.post<PurchaseConditionSupplierContractModel>(url, payload, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public editPurchaseConditionSupplierContract(
    payload: PurchaseConditionSupplierContractModel
  ): Observable<PurchaseConditionSupplierContractModel> {
    const url = this.getFullUrl(this.envService.supplierContract);
    return this.http.put<PurchaseConditionSupplierContractModel>(url, payload, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public deletePurchaseConditionSupplierContract(id: string): Observable<any> {
    const url = this.getFullUrl(this.envService.deleteContract, {
      id
    });

    return this.http.delete<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public addPurchaseConditionSupplierDc(
    payload: PurchaseConditionSupplierDcModel
  ): Observable<PurchaseConditionSupplierDcModel> {
    const url = this.getFullUrl(this.envService.supplierDc);
    return this.http.post<PurchaseConditionSupplierDcModel>(url, payload, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public editPurchaseConditionSupplierDc(
    payload: PurchaseConditionSupplierDcModel
  ): Observable<PurchaseConditionSupplierDcModel> {
    const url = this.getFullUrl(this.envService.supplierDc);
    return this.http.put<PurchaseConditionSupplierDcModel>(url, payload, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public deletePurchaseConditionSupplierDc(id: string): Observable<any> {
    const url = this.getFullUrl(this.envService.deleteDc, {
      id
    });

    return this.http.delete<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  onExportWeeklySchedule(supplierCode: { supplierCode: string }) {
    const params = this.getParams(supplierCode, true);

    const url = this.getFullUrl(this.envService.exportWeekly);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  onExportCalendarSchedule(supplierCode: { supplierCode: string }) {
    const params = this.getParams(supplierCode, true);

    const url = this.getFullUrl(this.envService.exportCalendar);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  exportDiscount(supplierCode: { supplierCode: string }) {
    const params = this.getParams(supplierCode, true);

    const url = this.getFullUrl(this.envService.exportDiscount);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
