export enum StockTransferStatusEnum {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  DELIVERED = 'DELIVERED',
  AWAITING_CLOSED = 'AWAITING_CLOSED',
  AWAITING_STOCK_RETURN = 'AWAITING_STOCK_RETURN',
  CLOSED = 'CLOSED',
  REJECTED_BY_ERP = 'REJECTED_BY_ERP',
  CANCELLED = 'CANCELLED'
}
