import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ShelfSelectedItems } from '../../models';
import {
  ShelfFixAssetDetailsActions,
  ShelfFixAssetDetailsActionType
} from '../actions/shelf-fix-asset-details.actions';
import { BaseState } from '../state/base.state';

export interface ShelfFixAssetDetailsState extends EntityState<ShelfSelectedItems>, BaseState {
  isLoading: boolean;
}

export const adapter: EntityAdapter<ShelfSelectedItems> = createEntityAdapter<ShelfSelectedItems>({
  selectId: (shelfFixAssetDetail: ShelfSelectedItems) => shelfFixAssetDetail.shelfNo
});

export const initialShelfFixAssetDetailsResponseState: ShelfFixAssetDetailsState = adapter.getInitialState({
  isLoading: false
});

export function shelfFixAssetDetailsReducers(
  state = initialShelfFixAssetDetailsResponseState,
  action: ShelfFixAssetDetailsActions
): ShelfFixAssetDetailsState {
  switch (action.type) {
    case ShelfFixAssetDetailsActionType.SHELF_FIX_ASSET_DETAILS_REQUEST:
      return adapter.addAll(action.payload, {
        ...state
      });
    case ShelfFixAssetDetailsActionType.SHELF_FIX_ASSET__DETAILS_RESET:
      return adapter.removeAll({
        ...state
      });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
