import { createSelector } from '@ngrx/store';
import * as fromStockTransfer from '../reducers/stock-transfer.reducers';
import { AppStates } from '../state/app.states';

const selectStockTransferState = (state: AppStates) => state.stockTransfer;

export const selectAllStockTransferList = createSelector(selectStockTransferState, fromStockTransfer.selectAll);

export const selectStockTransfer = createSelector(
  selectStockTransferState,
  (state: fromStockTransfer.StockTransferState) => state
);

export const selectStockTransferListCriteria = createSelector(selectStockTransferState, state => {
  return state.criteriaObject;
});

export const selectEditStockTransfer = createSelector(selectStockTransferState, state => state.stockTransfer);

export const selectStockTransferView = createSelector(selectStockTransferState, state => {
  return state.stockTransfer;
});
