import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BaseReport,
  POSRegisteredData,
  SignedUrlObject,
  StoreExportCriteria,
  StoreNo,
  StoreResponse,
  UpdateOrderScheduleTemplate
} from '../models';
import {
  BillPaymentReportCriteria,
  MoneyInReportCriteria,
  RedeemTransactionReportCriteria,
  RewardTransactionReportCriteria
} from '../models/report.model';
import { StoreAssortmentPrintCriteria } from '../models/store-assortment.model';
import { BaseService } from './base.service';

@Injectable()
export class StoreService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.store;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getHistoryLogs(storeNo: StoreNo): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      storeNo: storeNo.storeNo
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public getStoreById(storeNo: StoreNo): Observable<StoreResponse> {
    const url = this.getFullUrl(this.env.services.store.view, {
      storeNo: storeNo.storeNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public generatePOSRegistrationCode(storeNo: StoreNo, data: POSRegisteredData): Observable<any> {
    const url = this.getFullUrl(this.env.services.store.generatePOSRegisCode, {
      storeNo: storeNo.storeNo
    });

    return this.http.post<any>(url, data, {
      headers: this.headers,
      observe: 'body',
      responseType: 'text' as 'json'
    });
  }

  public updatePOSRegistrationCode(storeNo: StoreNo, data: POSRegisteredData): Observable<any> {
    const url = this.getFullUrl(this.env.services.store.updatePOSRegisCode, {
      storeNo: storeNo.storeNo
    });

    return this.http.put<any>(url, data, {
      headers: this.headers,
      observe: 'body',
      responseType: 'text' as 'json'
    });
  }

  public deletePOS(storeNo: StoreNo): Observable<StoreResponse> {
    const url = this.getFullUrl(this.env.services.store.deletePOS, {
      storeNo: storeNo.storeNo
    });
    return this.http.post<any>(
      url,
      {},
      {
        headers: this.headers,
        observe: 'body'
      }
    );
  }

  public deactivatePOS(storeNo: StoreNo, body: string): Observable<StoreResponse> {
    const url = this.getFullUrl(this.env.services.store.deactivatePOS, {
      storeNo: storeNo.storeNo
    });
    return this.http.post<any>(url, body, {
      headers: this.loaderHeaders(500),
      observe: 'body'
    });
  }

  public updateOrderSchedule(
    updateOrderScheduleTemplate: UpdateOrderScheduleTemplate
  ): Observable<UpdateOrderScheduleTemplate> {
    const url = this.getFullUrl(this.env.services.store.orderSchedule);
    return this.http.put<any>(url, updateOrderScheduleTemplate, { headers: this.headers });
  }

  public exportStore(criteria: StoreExportCriteria): Observable<any> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.export);
    const headers = new HttpHeaders(this.envService.headersOrder).set('x-frontend-spinner-loader', '0');

    return this.http.get<any>(url, {
      headers,
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportStoreConfidential(criteria: StoreExportCriteria): Observable<any> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.exportStoreConfidential);
    const headers = new HttpHeaders(this.envService.headersOrder).set('x-frontend-spinner-loader', '0');

    return this.http.get<any>(url, {
      headers,
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportMoneyIn(exportCriteria: MoneyInReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportMoneyIn);

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportBillPayment(exportCriteria: BillPaymentReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportBillPayment);

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public printStoreAssortmentPdf(criteria: StoreAssortmentPrintCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.printStoreAssortmentPdf, { ...criteria });

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public activateStore(storeNo: StoreNo): Observable<any> {
    const url = this.getFullUrl(this.envService.activate, {
      storeNo: storeNo.storeNo
    });

    return this.http.put<any>(url, null, { headers: this.headers, observe: 'body' });
  }

  public deactivateStore(storeNo: StoreNo): Observable<any> {
    const url = this.getFullUrl(this.envService.deactivate, {
      storeNo: storeNo.storeNo
    });

    return this.http.put<any>(url, null, { headers: this.headers, observe: 'body' });
  }

  public exportAdjustmentRequestStore<T1 extends BaseReport>(exportCriteria: T1): Observable<any> {
    const url = this.getFullUrl(this.envService.exportAdjustment, { exportBy: exportCriteria.exportBy });

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportInventoryCountStore<T1 extends BaseReport>(exportCriteria: T1): Observable<any> {
    const url = this.getFullUrl(this.envService.inventoryCount, { exportBy: exportCriteria.exportBy });

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportNoWSPStore<T1 extends BaseReport>(exportCriteria: T1): Observable<any> {
    const url = this.getFullUrl(this.envService.exportNoWSP, { exportBy: exportCriteria.exportBy });

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportInventoryVarianceReport<T1 extends BaseReport>(exportCriteria: T1): Observable<any> {
    const url = this.getFullUrl(this.envService.exportInventoryVarianceNestPDF);
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params
    });
  }

  public exportRewardTransaction(exportCriteria: RewardTransactionReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.rewardTransaction);

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportRedeemTransaction(exportCriteria: RedeemTransactionReportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.redeemTransaction);

    delete exportCriteria.exportBy;
    const params = this.getParams(exportCriteria, true);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public getFileUrl(refId: string): Observable<SignedUrlObject> {
    const url = this.getFullUrl(this.envService.imageSignedUrl);
    return this.http.post<SignedUrlObject>(url, { refId }, { headers: this.headers });
  }
}
