import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { animationFrameScheduler, Observable } from 'rxjs';
import { filter, map, observeOn, shareReplay, startWith, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import {
  OrderDeliveryScheduleType,
  PurchaseConditionPageModes,
  PurchaseConditionProductLevelEnum,
  PurchaseConditionScheduleSettingTitleEnum
} from '../../../shared/enum/purchase-condition.enum';
import { NewRequestTypeEnum } from '../../../shared/enum/request-step.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  PurchaseConditionRequestWeeklySchedule,
  PurchaseConditionRequestWeeklyScheduleImportCriteria,
  Warehouse
} from '../../../shared/models/purchase-condition-request.model';
import { SelectWarehouseList } from '../../../shared/models/warehouse.model';
import { PurchaseConditionService } from '../../../shared/services/purchase-condition.service';
import {
  PurchaseConditionRequestWeeklyScheduleImportRequested,
  PurchaseConditionRequestWeeklyScheduleLoaded,
  PurchaseConditionWeeklyScheduleRequested
} from '../../../shared/store/actions/purchase-condition.actions';
import { PurchaseConditionRequestWeeklyScheduleState } from '../../../shared/store/reducers/purchase-condition.reducer';
import {
  selectAllPurchaseConditionRequestWeeklyScheduleList,
  selectPurchaseConditionRequestWeeklySchedule
} from '../../../shared/store/selectors/purchase-condition.selectors';
import { selectWarehouseList } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { OrderDeliveryScheduleImportModalComponent } from '../order-delivery-schedule-import-modal/order-delivery-schedule-import-modal.component';
import { PurchaseConditionPermission } from '../purchase-condition-permission';

@Component({
  selector: 'app-order-delivery-schedule',
  templateUrl: './order-delivery-schedule.component.html',
  styleUrls: ['./order-delivery-schedule.component.scss']
})
export class OrderDeliveryScheduleComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Input() supplierInformationForm: FormGroup;
  @Input() purchaseConditionForm: FormGroup;
  @Input() scheduleType: OrderDeliveryScheduleType;
  @Output() scheduleTypeChange = new EventEmitter();
  @Input() pageMode: PurchaseConditionPageModes;
  @Output() dirtyOrderSchedule = new EventEmitter<boolean>();

  public warehouseList$: Observable<SelectWarehouseList[]> = this.store.select(selectWarehouseList);
  requestWeeklySchedule$: Observable<PurchaseConditionRequestWeeklySchedule[]>;
  PurchaseConditionScheduleSettingTitleEnum = PurchaseConditionScheduleSettingTitleEnum;
  PurchaseConditionProductLevelEnum = PurchaseConditionProductLevelEnum;
  OrderDeliveryScheduleType = OrderDeliveryScheduleType;
  mode = new FormControl(null);
  displayWeeklyModal = false;
  weeklyManual: {
    table: PurchaseConditionRequestWeeklySchedule[];
    weeklyScheduleManual: PurchaseConditionRequestWeeklySchedule;
  } = { table: [], weeklyScheduleManual: null };

  purchaseConditionPageMode = PurchaseConditionPageModes;
  public purchaseConditionPermission = new PurchaseConditionPermission();

  public purchaseConditionRequestWeeklySchedule$: Observable<PurchaseConditionRequestWeeklyScheduleState>;
  public purchaseConditionRequestWeeklyScheduleList$ = this.store
    .select(selectAllPurchaseConditionRequestWeeklyScheduleList)
    .pipe(
      filter(value => Boolean(value)),
      observeOn(animationFrameScheduler),
      map(value => {
        const requestWeeklyScheduleList = value.map(val => {
          val.orderWeekDays = val.orderSchedules ? [...val.orderSchedules] : null;
          val.deliveryWeekDays = val.deliverySchedules ? [...val.deliverySchedules] : null;
          val.warehouses = val.warehouses;

          if (val.scheduleType === OrderDeliveryScheduleType.WEEKLY_MANUAL) {
            this.setModeAndEmit(OrderDeliveryScheduleType.WEEKLY_MANUAL);
          }

          return val;
        });

        this.setDefaultWeeklyManualComponent(requestWeeklyScheduleList);
        return requestWeeklyScheduleList;
      }),
      shareReplay(),
      startWith([])
    );

  public buttons: Array<ImportExportButton>;
  public requestTypeEnum = NewRequestTypeEnum;

  public localStore: Observable<any>;
  public orderDeliveryScheduleList: PurchaseConditionRequestWeeklySchedule[] = [];
  public criteriaObject: PurchaseConditionRequestWeeklyScheduleImportCriteria;

  public currentPage: number;
  public pageSize: number;
  public totalImportedElements: number;

  constructor(
    protected readonly store: Store<AppStates>,
    private fb: FormBuilder,
    protected readonly translate: TranslateService,
    protected readonly modalService: BsModalService,
    protected readonly purchaseConditionService: PurchaseConditionService
  ) {
    super(store, modalService);
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.setFirstPage();
    this.setInitialCriteriaObject();
    this.purchaseConditionRequestWeeklySchedule$ = this.localStore.pipe(
      select(selectPurchaseConditionRequestWeeklySchedule)
    );
    this.purchaseConditionRequestWeeklySchedule$.subscribe(dataImported => {
      this.totalImportedElements = dataImported.totalElements;
      this.criteriaObject = {
        ...this.criteriaObject,
        searchCriteria: dataImported.importId
      };
    });
    this.search();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageMode || changes.scheduleType) {
      this.getButton();
    }
  }

  doAfterVersionAlertModal() {}

  subscribeForVersionError() {}

  onShowSettingDialog(templateDialog: ModalDirective) {
    if (this.scheduleType) {
      const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
        initialState: {
          title: 'Confirm',
          message: `Are you sure you want to change setting? <br/>
            All existing Order/Delivery Schedule will be deleted.`,
          okText: 'Yes, delete',
          cancelText: 'Cancel'
        }
      });

      confirmModalRef.content.action
        .pipe(untilComponentDestroyed(this))
        .subscribe((result: ModalButtonResponseEnum) => {
          if (result === ModalButtonResponseEnum.OK) {
            this.mode.reset();
            this.setModeAndEmit(null);
            this.nextValueToRequestWeeklyScheduleLoaded([]);
          }
        });
    } else {
      templateDialog.show();
    }
  }

  getButton() {
    this.buttons = [
      {
        type: ButtonType.IMPORT,
        name: 'Import',
        hidden:
          [PurchaseConditionPageModes.VIEW_REQUEST, PurchaseConditionPageModes.VIEW].includes(this.pageMode) ||
          [OrderDeliveryScheduleType.WEEKLY_MANUAL].includes(this.scheduleType) ||
          !this.scheduleType
      },
      {
        type: ButtonType.EXPORT,
        name: 'Export',
        hidden:
          ![PurchaseConditionPageModes.VIEW].includes(this.pageMode) ||
          [OrderDeliveryScheduleType.WEEKLY_MANUAL].includes(this.scheduleType) ||
          !this.purchaseConditionPermission.showButton
      }
    ];
  }

  nextValueToRequestWeeklyScheduleLoaded(val: PurchaseConditionRequestWeeklySchedule[] | []) {
    if (
      ([PurchaseConditionPageModes.EDIT].includes(this.pageMode) ||
        ([PurchaseConditionPageModes.EDIT_REQUEST].includes(this.pageMode) &&
          this.purchaseConditionForm.controls['requestType'].value === this.requestTypeEnum.EDIT)) &&
      this.criteriaObject.searchCriteria
    ) {
      this.purchaseConditionForm.patchValue({
        scheduleEdit: true
      });
    }
    this.store.dispatch(new PurchaseConditionRequestWeeklyScheduleLoaded({ content: val }));
  }

  onHideModal(templateDialog: ModalDirective) {
    templateDialog.hide();
  }

  onSubmit(templateDialog) {
    this.setModeAndEmit(this.mode.value);
    this.getButton();
    if (
      ([PurchaseConditionPageModes.EDIT].includes(this.pageMode) ||
        ([PurchaseConditionPageModes.EDIT_REQUEST].includes(this.pageMode) &&
          this.purchaseConditionForm.controls['requestType'].value === this.requestTypeEnum.EDIT)) &&
      this.criteriaObject.searchCriteria
    ) {
      this.purchaseConditionForm.patchValue({
        scheduleEdit: true
      });
    }
    this.dirtyOrderSchedule.emit(true);
    templateDialog.hide();
  }

  setModeAndEmit(mode: OrderDeliveryScheduleType) {
    this.scheduleType = mode;
    this.scheduleTypeChange.emit(mode);
  }

  checkExistingItem() {
    if (this.totalImportedElements) {
      this.alertConfirmUploadModal();
    } else {
      this.onOpenImportItemsModal();
    }
  }

  alertConfirmUploadModal(
    initialState = {
      title: 'Confirm',
      message: 'Are you sure you want to import new file? All existing data will be lost.',
      okText: 'OK'
    }
  ) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.onOpenImportItemsModal();
        }
      });
  }

  onOpenImportItemsModal() {
    const stocksItemImport = this.modalService.show(OrderDeliveryScheduleImportModalComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        supplierName: this.supplierInformationForm.controls.supplierName.value,
        supplierCode: this.supplierInformationForm.controls.supplierCode.value,
        scheduleMode: this.scheduleType
      }
    });
    stocksItemImport.content.submitUpload.subscribe(orderDeliveryItems => {
      if (orderDeliveryItems && orderDeliveryItems.importId) {
        this.alertImportSuccessModal('The data have been imported.');
        this.setFirstPage();
        this.criteriaObject = {
          ...({} as any),
          searchCriteria: orderDeliveryItems.importId,
          page: 0,
          size: 20
        };
        if (
          [PurchaseConditionPageModes.EDIT].includes(this.pageMode) ||
          ([PurchaseConditionPageModes.EDIT_REQUEST].includes(this.pageMode) &&
            this.purchaseConditionForm.controls['requestType'].value === this.requestTypeEnum.EDIT)
        ) {
          this.purchaseConditionForm.patchValue({
            scheduleEdit: true
          });
        }
        this.dirtyOrderSchedule.emit(true);
        this.search();
      }
    });
  }

  onExportOrderDeliverySchedule() {
    const supplierCode = this.supplierInformationForm.controls.supplierCode.value;
    const criteria = {
      supplierCode
    };

    if (!this.scheduleType) {
      /** Consider as no item to be export **/
      this.alertErrorModal('No item to be exported.');
    }
    if ([OrderDeliveryScheduleType.WEEKLY, OrderDeliveryScheduleType.WEEKLY_MANUAL].includes(this.scheduleType)) {
      this.purchaseConditionService.onExportWeeklySchedule(criteria).subscribe(
        response => {
          const blob = new Blob([response]);
          saveAs(blob, `${supplierCode} ${this.generateExportedFileName()} ${this.timeToExport}.xlsx`);
        },
        error => {
          this.alertErrorModal(error.error.message);
        }
      );
    } else if (OrderDeliveryScheduleType.MONTHLY === this.scheduleType) {
      this.purchaseConditionService.onExportCalendarSchedule(criteria).subscribe(
        response => {
          const blob = new Blob([response]);
          saveAs(blob, `${supplierCode} ${this.generateExportedFileName()} ${this.timeToExport}.xlsx`);
        },
        error => {
          this.alertErrorModal(error.error.message);
        }
      );
    }
  }

  generateExportedFileName() {
    if ([OrderDeliveryScheduleType.WEEKLY, OrderDeliveryScheduleType.WEEKLY_MANUAL].includes(this.scheduleType)) {
      return environment.fileName.exportOrderDeliveryScheduleWeeklyTemplate.exported;
    } else if (this.scheduleType === OrderDeliveryScheduleType.MONTHLY) {
      return environment.fileName.exportOrderDeliveryScheduleMonthlyTemplate.exported;
    }
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportOrderDeliveryScheduleWeeklyTemplate.timeFormat);
  }

  alertImportSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
      }
    });
  }

  alertErrorModal(message: string) {
    const initialState = {
      title: 'Failed',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
      }
    });
  }

  wmsCodeNameForDisplay(warehouse: Warehouse[]) {
    warehouse = _.orderBy(warehouse, ['wmsCode'], ['asc']);
    return warehouse.map(val => `${val.wmsCode}-${val.name}`).join('\n');
  }

  onDelete(index: number, weeklyScheduleList: PurchaseConditionRequestWeeklySchedule[]) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete Order/Delivery Schedule?',
        okText: 'Yes, delete',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this), take(1))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          weeklyScheduleList.splice(index, 1);

          this.nextValueToRequestWeeklyScheduleLoaded([...weeklyScheduleList]);
        }
      });
  }

  onEdit(
    weeklyManual: PurchaseConditionRequestWeeklySchedule,
    weeklyScheduleManual: PurchaseConditionRequestWeeklySchedule[]
  ) {
    this.weeklyManual = { table: weeklyScheduleManual, weeklyScheduleManual: weeklyManual };
    this.displayWeeklyModal = true;
  }

  setDefaultWeeklyManualComponent(purchaseConditionRequestWeeklySchedule) {
    this.weeklyManual = { table: purchaseConditionRequestWeeklySchedule, weeklyScheduleManual: null };
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  setInitialCriteriaObject() {
    this.pageSize = 20;
    this.criteriaObject = {
      ...({} as any),
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  search() {
    this.criteriaObject = {
      ...this.criteriaObject,
      scheduleMode: this.scheduleType
    };
    if (
      this.scheduleType &&
      [this.purchaseConditionPageMode.VIEW, this.purchaseConditionPageMode.EDIT].includes(this.pageMode) &&
      !this.purchaseConditionForm.controls.scheduleEdit.value
    ) {
      this.criteriaObject = {
        ...this.criteriaObject,
        supplierCode: this.supplierInformationForm.controls.supplierCode.value,
        searchCriteria: null
      };

      this.store.dispatch(new PurchaseConditionWeeklyScheduleRequested(this.criteriaObject));
    } else if (
      this.criteriaObject.searchCriteria &&
      (this.purchaseConditionForm.controls.scheduleEdit.value ||
        [
          this.purchaseConditionPageMode.VIEW_REQUEST,
          this.purchaseConditionPageMode.CREATE,
          this.purchaseConditionPageMode.EDIT_REQUEST
        ].includes(this.pageMode))
    ) {
      this.store.dispatch(new PurchaseConditionRequestWeeklyScheduleImportRequested(this.criteriaObject));
    }
  }

  onChangePage(event: any) {
    this.currentPage = event.page;
    this.criteriaObject = {
      ...this.criteriaObject,
      page: event.page - 1
    };
    this.search();
  }

  onChangeRowPerPage(value: string) {
    this.setFirstPage();
    this.pageSize = Number(value);
    this.criteriaObject = {
      ...this.criteriaObject,
      size: Number(value),
      page: 0
    };
    this.search();
  }

  addNewWeeklyManual() {
    this.displayWeeklyModal = true;
    this.weeklyManual = { ...this.weeklyManual, weeklyScheduleManual: null };
  }

  doAfterSuccessModal() {}

  setDirtyOrderSchedule(event) {
    this.dirtyOrderSchedule.emit(event);
  }
}
