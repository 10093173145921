import { AuditLog } from './audit-log.model';
import { Sort } from './pagination.model';

export enum VoucherRequestPageModes {
  REQUEST_VIEW = 'REQUEST_VIEW',
  REQUEST_CREATE = 'REQUEST_CREATE',
  VIEW = 'VIEW'
}

export class VoucherRequestSearchCriteria {
  searchCriteria?: string;
  state?: string;
  region?: string;
  status?: string;
  campaign?: string[];
  requestedDateFrom?: string;
  requestedDateTo?: string;
  effectiveDateFrom?: string;
  effectiveDateTo?: string;
  expireDateFrom?: string;
  expireDateTo?: string;
  page: number;
  size: number;
}

export class VoucherRequestListResponse {
  id: number;
  content: VoucherRequestContent[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface VoucherRequestContent {
  id: string;
  requestNo: string;
  campaign: string;
  store: string;
  voucherValue: VoucherMoney;
  effectiveDate: string;
  expireDate: string;
  status: string;
  createdDate: string;
  createdBy: number;
  auditLogs: AuditLog[];
}

export class VoucherListSearchCriteria {
  searchCriteria?: string;
  status?: string;
  campaign?: string[];
  state?: string;
  region?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  effectiveDateFrom?: string;
  effectiveDateTo?: string;
  expireDateFrom?: string;
  expireDateTo?: string;
  page: number;
  size: number;
}

export class VoucherListResponse {
  id: number;
  content: VoucherListContent[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface VoucherListContent {
  id: string;
  voucherCode: string;
  campaign: string;
  store: string;
  voucherValue: VoucherMoney;
  voucherAmount: number;
  effectiveDate: string;
  expireDate: string;
  status: string;
  createdDate: string;
  createdBy: number;
  auditLogs: AuditLog[];
}
export class VoucherRequest {
  details: VoucherRequestDetails;
}

export interface VoucherRequestResponse {
  id: string;
  requestNo: string;
  details: VoucherRequestDetails;
  type: string;
  status: string;
  version: number;
  requestedBy: string;
  requestedByName: string;
  requestedDate: string;
}

export interface VoucherRequestDetails {
  campaign: string;
  store: Store;
  effectiveDate: string;
  expireDate: string;
  voucherValue: VoucherMoney;
  minimumBuyPerBill: VoucherMoney;
  voucherAmount: number;
}

export interface Store {
  code: string;
  name: string;
  no: string;
}

export interface VoucherMoney {
  amount: number;
  currency?: string;
}

export interface VoucherRequestViewResponse {
  id: string;
  requestNo: string;
  details: VoucherRequestViewDetails;
  type: string;
  status: string;
  version: number;
  requestedBy: string;
  requestedByName: string;
  requestedDate: string;
}

export interface VoucherRequestViewDetails {
  campaign: string;
  store: Store;
  effectiveDate: string;
  expireDate: string;
  voucherValue: VoucherMoney;
  minimumBuyPerBill: VoucherMoney;
  compensate: VoucherMoney;
  limitPerBill: number;
  voucherAmount: number;
  voucherNo?: string;
}

export interface VoucherRequestWithComment {
  id: string;
  comment: string;
}

export interface VoucherViewResponse {
  id: string;
  requestNo: string;
  details: VoucherRequestViewDetails;
  type: string;
  status: string;
  version: number;
  requestedBy: string;
  requestedByName: string;
  requestedDate: string;
  voucherNo?: string;
  voucherCode: string;
}
