import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ApproveStatusEnum } from '../../../../shared/enum/approve-status.enum';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { ProductTypeEnum } from '../../../../shared/enum/product-type.enum';
import { NewRequestStatusEnum } from '../../../../shared/enum/request-status.enum';
import {
  NewRequestTypeEnum,
  RequestPageModesEnum,
  RequestProductErrorEnum
} from '../../../../shared/enum/request-step.enum';
import { ShelfPages } from '../../../../shared/enum/td-store-page.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ConfirmModalComponent } from '../../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ErrorResponse, TaskModuleUrl } from '../../../../shared/models';
import { BarcodeListSearchCriteria, BarcodeResponse } from '../../../../shared/models/barcode.model';
import { ConfirmModal } from '../../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../../shared/models/notification-emit.model';
import {
  NonMerchandiseItem,
  ShelfFixAssetCreateRequest,
  ShelfFixAssetRequestListSearchCriteria,
  ShelfFixAssetRequestViewResponse
} from '../../../../shared/models/shelf-request.model';
import { AuthGuardService, BarcodeService } from '../../../../shared/services';
import { ShelfFixAssetRequestService } from '../../../../shared/services/shelf-fix-asset-request.service';
import { TasksByRoleListRequestAction } from '../../../../shared/store/actions/dashboard.actions';
import {
  ShelfFixAssetApproveRequestAction,
  ShelfFixAssetCreateResetAction,
  ShelfFixAssetCreateSaveRequestAction,
  ShelfFixAssetCreateSubmitRequestAction,
  ShelfFixAssetRequestByIdRequestAction,
  ShelfFixAssetRequestByIdResetAction,
  ShelfFixAssetRequestListRequestAction
} from '../../../../shared/store/actions/shelf-request.actions';
import { ShelfFixAssetCreateResponseState } from '../../../../shared/store/reducers/shelf-fix-asset-create.reducers';
import {
  selectShelfApproveRejectStatus,
  selectShelfFixAsset
} from '../../../../shared/store/selectors/shelf-fix-asset-create.selectors';
import {
  selectShelfFixAssetRequestById,
  selectShelfRequestListCriteria
} from '../../../../shared/store/selectors/shelf-fix-asset-request.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';
import { getFileId } from '../../../../shared/utils/get-fileId-util';
import { ShelfFixAssetComponent } from '../../shelf-components/shelf-fix-asset/shelf-fix-asset.component';
import { ShelfInformationComponent } from '../../shelf-components/shelf-information/shelf-information.component';
@Component({
  selector: 'app-shelf-fix-asset-request',
  templateUrl: './shelf-fix-asset-request.component.html',
  styleUrls: ['./shelf-fix-asset-request.component.scss']
})
export class ShelfFixAssetRequestComponent implements OnInit, OnDestroy {
  constructor(
    private readonly fb: FormBuilder,
    protected readonly modalService: BsModalService,
    private readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    private readonly shelfFixAssetRequestService: ShelfFixAssetRequestService,
    private readonly barcodeService: BarcodeService,
    private readonly authGuardService: AuthGuardService,
    protected readonly logger: NGXLogger
  ) {}

  get requestMode() {
    return RequestPageModesEnum;
  }

  @ViewChild('shelfInfo', { static: false }) shelfInfo: ShelfInformationComponent;
  @ViewChild('fixAsset', { static: false }) fixAsset: ShelfFixAssetComponent;
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: RequestPageModesEnum;
    requestId?: string;
    originPage?: string;
  };

  public page: ShelfPages;
  public type: NewRequestTypeEnum;
  public saved: boolean;
  public shelfRequestForm: FormGroup;
  public shelfRequestView$: Observable<ShelfFixAssetRequestViewResponse>;
  public status: NewRequestStatusEnum;
  public submitted: boolean;
  public shelfType: ProductTypeEnum = ProductTypeEnum.FIX_ASSET;

  private requestNo: string;
  private localStore: Observable<any>;
  private listSearchCriteria: ShelfFixAssetRequestListSearchCriteria;

  ngOnDestroy(): void {
    this.store.dispatch(new ShelfFixAssetCreateResetAction());
    this.store.dispatch(new ShelfFixAssetRequestByIdResetAction());

    if (this.data.originPage && this.data.originPage === TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new TasksByRoleListRequestAction());
    } else {
      this.store.dispatch(new ShelfFixAssetRequestListRequestAction(this.listSearchCriteria));
    }
  }

  ngOnInit() {
    this.page = ShelfPages.SHELF_REQUEST_FIX_ASSET;
    this.type = NewRequestTypeEnum.NEW;
    this.status = NewRequestStatusEnum.DRAFT;

    this.shelfRequestForm = this.createForm();
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.shelfRequestView$ = this.localStore.pipe(select(selectShelfFixAssetRequestById));
    this.shelfRequestView$.pipe(filter(data => Boolean(data))).subscribe(data => {
      this.requestNo = data.requestNo;
      this.type = data.type;
      this.status = data.status;

      if (this.data.mode === RequestPageModesEnum.REQUEST_EDIT) {
        const fixAssetBarcode = data.items.map(item => item.barcode);

        this.itemValidator(fixAssetBarcode);
      }
    });

    this.localStore
      .pipe(select(selectShelfRequestListCriteria))
      .subscribe(criteriaObject => (this.listSearchCriteria = criteriaObject));

    this.localStore
      .pipe(
        select(selectShelfFixAsset),
        filter(value => Boolean(value && value.result))
      )
      .subscribe((value: ShelfFixAssetCreateResponseState) => {
        const result = value.result;

        if (result.response) {
          this.alertSuccessModal(this.getSubmitMessage(value.isSave));
        } else {
          this.alertErrorModal(result.errorResponse);
        }
      });

    if (this.data.mode !== RequestPageModesEnum.REQUEST_CREATE) {
      this.store.dispatch(new ShelfFixAssetRequestByIdRequestAction({ requestId: this.data.requestId }));
    }
  }

  getColorStatus(status) {
    return status && status.toLocaleLowerCase();
  }

  createForm() {
    return this.fb.group({});
  }

  onSave() {
    this.saved = true;
    const form = this.shelfRequestForm.getRawValue();

    if (!form.shelfInfo.shelfName) {
      return;
    }

    this.store.dispatch(new ShelfFixAssetCreateSaveRequestAction(this.prepareRequestData()));
  }

  onSubmit() {
    this.submitted = true;

    if (this.status !== NewRequestStatusEnum.AWAITING_FIRST_LOT) {
      const fixAssetBarcode = this.shelfRequestForm.getRawValue().fixAssetItem.map(data => data.barcode);

      this.itemValidator(fixAssetBarcode);
    } else {
      const errorMessage = this.getFormGroupErrorMessage();

      if (errorMessage) {
        this.showModalError(errorMessage);
      } else if (this.fixAsset.form.invalid) {
        this.validateError(this.fixAsset);
      } else if (this.shelfRequestForm.valid) {
        this.handleConfirm();
      }
    }
  }

  itemValidator(fixAssetBarcode: string[]) {
    const fixAssetList$: Observable<BarcodeResponse[]> = this.barcodeValidator(
      fixAssetBarcode,
      ProductTypeEnum.FIX_ASSET
    );

    fixAssetList$
      .pipe(
        tap(fixAssetList => {
          this.updateFormGroup(fixAssetList, ProductTypeEnum.FIX_ASSET);
        }),
        filter(() => this.submitted)
      )
      .subscribe(() => {
        const errorMessage = this.getFormGroupErrorMessage();

        if (errorMessage) {
          this.showModalError(errorMessage);
        } else if (this.fixAsset.form.invalid) {
          this.validateError(this.fixAsset);
        } else if (this.shelfRequestForm.valid) {
          this.handleConfirm();
        }
      });
  }

  validateError(form) {
    const invalidIndex = form.formControls.findIndex(item => item.invalid);
    form.paging.navigateToErrorIndex(invalidIndex);

    const error = form.formControls.find(item => !!item.errors);
    if (error) {
      this.showModalError('Please delete invalid data before submit.');
    }
  }

  barcodeValidator(barcodes: string[], productType: ProductTypeEnum) {
    const barcodeList = this.barcodeService.searchBarcodeByCriteria(
      barcodes,
      new BarcodeListSearchCriteria({
        allowRestrictItem: true,
        allowProductType: productType,
        size: barcodes.length
      })
    );

    return barcodes.length ? barcodeList : of([]);
  }

  updateFormGroup(barcode: BarcodeResponse[], productType: ProductTypeEnum.FIX_ASSET) {
    if (!barcode.length) {
      return;
    }

    let formGroup;
    let mappingBarcodeResponse;

    if (productType === ProductTypeEnum.FIX_ASSET) {
      formGroup = this.shelfRequestForm.get('fixAssetItem');
      mappingBarcodeResponse = NonMerchandiseItem.mappingBarcodeResponseToNonMerchandiseItem;
    }

    barcode.forEach((data, i) => {
      formGroup.controls[i].patchValue({
        ...mappingBarcodeResponse(data),
        ...formGroup.getRawValue()[i]
      });

      if (formGroup.controls[i].get('articleNo') && formGroup.controls[i].get('articleNo').value !== data.articleNo) {
        data.errorMessage = RequestProductErrorEnum.INVALID_BARCODE;
      }

      if (data.errorMessage) {
        this.validateForm(formGroup.controls[i], data.errorMessage);
      }
    });
  }

  validateForm(formItem: FormGroup, errorMessage: RequestProductErrorEnum) {
    let childItem;

    const newValidator = this.setValidator(errorMessage);

    switch (errorMessage) {
      case RequestProductErrorEnum.INVALID_BARCODE:
      case RequestProductErrorEnum.INACTIVE_BARCODE:
      case RequestProductErrorEnum.DUPLICATED_BARCODE_FIELD:
        childItem = formItem.get('barcode');
        break;
      case RequestProductErrorEnum.RESTRICT_ITEM:
      case RequestProductErrorEnum.STATUS_IS_DELISTED:
      case RequestProductErrorEnum.NOT_ALLOW_FIX_ASSET:
      case RequestProductErrorEnum.NOT_ALLOW_INVENTORY:
      case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
        childItem = formItem.get('productName');
        break;
      default:
        this.logger.error(`ErrorMessage: ${errorMessage} did not supported in barcode and productName.`);
        return;
    }

    childItem.setValidators(newValidator);
    childItem.updateValueAndValidity();

    formItem.setValidators(newValidator);
    formItem.updateValueAndValidity();
  }

  setValidator(errorMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      switch (errorMessage) {
        case RequestProductErrorEnum.INVALID_BARCODE:
          return { invalidBarcode: true };
        case RequestProductErrorEnum.INACTIVE_BARCODE:
          return { isInactive: true };
        case RequestProductErrorEnum.DUPLICATED_BARCODE_FIELD:
          return { duplicated: true };
        case RequestProductErrorEnum.RESTRICT_ITEM:
          return { isRestrictItem: true };
        case RequestProductErrorEnum.STATUS_IS_DELISTED:
          return { isStatusDelisted: true };
        case RequestProductErrorEnum.NOT_ALLOW_FIX_ASSET:
          return { isFixAssetItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_INVENTORY:
          return { isInventoryItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
          return { isStoreUseItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_ZERO:
          return control.value !== null && control.value === 0 ? { isZero: true } : null;
        default:
          return null;
      }
    };
  }

  getFormGroupErrorMessage(): string | undefined {
    const formGroup = this.shelfRequestForm.get('fixAssetItem') as FormArray;

    if (!formGroup.controls.length) {
      this.shelfRequestForm.setErrors({ requiredItem: true });
      return 'Please select at least one item before submit.';
    }
  }

  showModalError(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message
      }
    });
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe(
      (result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const reqData = this.prepareRequestData();
          this.store.dispatch(new ShelfFixAssetCreateSubmitRequestAction(reqData));
        }
      },
      error => {
        const initialState = {
          title: 'Alert',
          message: this.translate.instant('ERROR_CODE.' + error.error.code)
        };
        this.modalService.show(AlertModalComponent, {
          initialState
        });
      }
    );
  }

  prepareRequestData() {
    const formData = this.shelfRequestForm.getRawValue();
    const template = new ShelfFixAssetCreateRequest();
    template.type = this.type;
    template.status = this.status;

    this.shelfRequestView$
      .pipe(
        untilComponentDestroyed(this),
        filter(value => Boolean(value))
      )
      .subscribe((value: ShelfFixAssetRequestViewResponse) => {
        template.id = value.id;
        template.version = value.version;
        template.requestNo = value.requestNo;
        template.type = value.type;
        template.status = value.status;
        template.shelfNo = value.shelfNo;
      });

    template.shelfInfo = {
      ...formData.shelfInfo,
      shelfName: formData.shelfInfo.shelfName.trim(),
      shelfImage: getFileId(formData.shelfInfo.shelfImage)
    };

    template.items = formData.fixAssetItem;

    return template;
  }

  onCancel() {
    if (this.shelfRequestForm.touched) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  onTriggerEdit() {
    this.data.title = 'Edit Shelf Request';
    this.data.mode = RequestPageModesEnum.REQUEST_EDIT;

    if (this.status !== NewRequestStatusEnum.AWAITING_FIRST_LOT) {
      const fixAssetBarcode = this.shelfRequestForm.getRawValue().fixAssetItem.map(data => data.barcode);

      this.itemValidator(fixAssetBarcode);
    }
  }

  doApproveRejectRequest(isApprove: boolean) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: isApprove ? 'Are you sure you want to "Approve"?' : 'Are you sure you want to "Reject"?',
        label: 'Comment',
        okText: isApprove ? 'Approve' : 'Reject',
        okClass: isApprove ? 'btn btn-special-approve' : 'btn btn-special-reject',
        isRequiredConfirmMessage: !isApprove
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.localStore
            .pipe(
              select(selectShelfApproveRejectStatus),
              filter(isApproveRejectSuccess => isApproveRejectSuccess)
            )
            .subscribe(() => {
              this.alertApproveRejectModalSuccess(
                {
                  result: {
                    response: 'success',
                    errorResponse: null
                  }
                },
                isApprove
              );
            });
          this.store.dispatch(
            new ShelfFixAssetApproveRequestAction({
              requestNo: this.requestNo,
              status: isApprove ? ApproveStatusEnum.APPROVED : ApproveStatusEnum.REJECTED,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  hasEditPermission(): boolean {
    return (
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW &&
      [NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status) &&
      this.authGuardService.checkPermission(['sh_ast_m'])
    );
  }

  hasSubmitPermission(): boolean {
    return (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      [NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status) &&
      this.authGuardService.checkPermission(['sh_ast_m'])
    );
  }

  hasApprovePermission(): boolean {
    return (
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW &&
      this.status === NewRequestStatusEnum.AWAITING_APPROVAL &&
      this.authGuardService.checkPermission(['sh_ast_app'])
    );
  }

  hasCancelPermission(): boolean {
    return (
      this.authGuardService.checkPermission(['sh_ast_m']) &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW &&
      this.status === NewRequestStatusEnum.AWAITING_APPROVAL
    );
  }

  hasSavePermission(): boolean {
    return (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      this.status === NewRequestStatusEnum.DRAFT &&
      this.authGuardService.checkPermission(['sh_ast_m'])
    );
  }

  hasDeletePermission(): boolean {
    return (
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW &&
      this.status === NewRequestStatusEnum.DRAFT &&
      this.authGuardService.checkPermission(['sh_ast_m'])
    );
  }

  hasAtLeastOnePermission() {
    return (
      this.status !== NewRequestStatusEnum.APPROVED ||
      this.hasSavePermission() ||
      this.hasEditPermission() ||
      this.hasApprovePermission()
    );
  }

  alertApproveRejectModalSuccess(resp, isApprove) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: isApprove ? 'The request has been approved.' : 'The request has been rejected.'
      },
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK || result === ModalButtonResponseEnum.CANCEL) {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.NEXT, result: resp });
      }
      if (alertModal.content.actions) {
        alertModal.content.actions.unsubscribe();
      }
    });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.modalService.hide(1);
        if (this.status === NewRequestStatusEnum.DRAFT) {
          this.listSearchCriteria.page = 0;
        }
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  cancelShelfRequest() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number <strong>&quot;${this.requestNo}&quot;</strong>?`,
        label: 'Reason',
        okText: 'Yes, cancel',
        cancelText: 'Cancel',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.shelfFixAssetRequestService
            .approveRequest({
              requestNo: this.requestNo,
              status: ApproveStatusEnum.CANCELLED,
              comment: confirmModalRef.content.confirmMessage
            })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been cancelled.');
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  deleteShelfRequest() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this request?',
        okText: 'Yes, delete',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.shelfFixAssetRequestService
            .deleteByRequestId({ requestId: this.data.requestId })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been deleted.');
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  getSubmitMessage(isSave): string | undefined {
    if (isSave) {
      return 'The request has been saved.';
    } else if (this.status === NewRequestStatusEnum.DRAFT) {
      return 'The request has been sent to approver.';
    } else if (this.status === NewRequestStatusEnum.AWAITING_APPROVAL) {
      return 'The request has been updated.';
    } else if (this.status === NewRequestStatusEnum.AWAITING_FIRST_LOT && this.type === NewRequestTypeEnum.NEW) {
      return 'The shelf has been created.';
    } else if (this.status === NewRequestStatusEnum.AWAITING_FIRST_LOT && this.type === NewRequestTypeEnum.EDIT) {
      return 'The shelf has been updated.';
    }
  }
}
