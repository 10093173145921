import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  SkipSelf,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ControlContainer,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, fromEventPattern, ReplaySubject } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { ModalButtonResponseEnum } from '../../enum/modal-button-response.enum';
import { AlertModalComponent } from '../../layouts';
import { ConfirmModalComponent } from '../../layouts/modals/confirm-modal/confirm-modal.component';
import { CommonUploadService } from '../../services/common.upload.service';

class ICustomFile extends File {
  errors?: { [key: string]: any };
  imgSrc?: string;
  filePath?: string;
  id?: number;
  imgHeight?: number;
  imgWidth?: number;
  isImg?: boolean;
  imgLoadReplay?: ReplaySubject<[Event, ProgressEvent]>;
  textContent?: string;
  textLoadReplay?: ReplaySubject<ProgressEvent>;
}

type AllowedExtType = RegExp | string | string[];

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ],
  exportAs: 'FileUploadComponent'
})
export class FileUploadComponent implements OnChanges, OnInit, ControlValueAccessor, OnDestroy {
  @Input()
  set allowedExt(value: AllowedExtType) {
    if (typeof value === 'string') {
      value = value + '$';
    }
    if (value instanceof Array) {
      value = value.join('|') + '$';
    }
    this._allowedExt = value;
  }

  constructor(
    renderer2: Renderer2,
    @Optional()
    @Host()
    @SkipSelf()
    private readonly controlContainer: ControlContainer,
    protected readonly modalService: BsModalService,
    private readonly uploadService: CommonUploadService,
    private readonly logger: NGXLogger
  ) {
    this.renderer2 = renderer2;
    this.disabled = false;
    this.ngChange = () => {};
    this.ngTouched = () => {};
  }

  get allowedExt(): RegExp | string | string[] {
    return this._allowedExt;
  }

  private readonly renderer2;
  private _allowedExt: RegExp | string | string[];
  url = '';
  inputValue;
  isLoad = true;
  ngChange;
  ngTouched;
  value;
  validator: AsyncValidatorFn;
  fileList: ICustomFile[] = [];
  progress;

  @ViewChild('uploadInput', { static: false }) uploadInput: ElementRef;

  @Input() disabled: boolean;
  @Input() multiple: boolean;
  @Input() allowedTypes: RegExp | string | string[];
  @Input() size: number;
  @Input() withMeta: boolean;
  @Input() maxHeight: number;
  @Input() maxWidth: number;
  @Input() uploadUrl: string;
  @Input() uploadHeaders: any;
  @Input() baseStorageUrl: string;
  @Input() controlName: string;
  @Input() initialFileList: ICustomFile[];
  @Input() isNew: boolean;
  @Input() errorIncorrectFormat: string;
  @Input() errorImageSize: string;

  @Output() showImage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() imageSuccess: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() isDelete: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnDestroy(): void {}

  @HostListener('change', ['$event.target.files']) onChange = _value => {};
  @HostListener('blur') onTouched = () => {};

  propagateChange: any = () => {};

  ngOnChanges(): void {
    this.ngChange(this.value);
  }

  ngOnInit(): void {
    this.fileList = this.initialFileList || [];

    if (!this.errorImageSize) {
      this.errorImageSize =
        'File size limit exceeded. <br/><br/> Size up to 500 KB (Recommended upload size of 500 * 500 pixels.)';
    }
    if (!this.errorIncorrectFormat) {
      this.errorIncorrectFormat = 'Incorrect Format (allow only format file .jpg, .jpeg, .png)';
    }
  }

  writeValue(value): void {
    this.value = value;
    this.inputValue = value;
    this.fileList = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = this.onChangeGenerator(fn);
  }

  registerOnTouched(fn: any): void {
    this.ngTouched = fn;
    this.propagateChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private onChangeGenerator(fn: (_: any) => {}): (_: ICustomFile[]) => void {
    this.ngChange(this.value);
    this.ngTouched();

    return (files: ICustomFile[]) => {
      this.isLoad = false;
      const fileArr: File[] = [];
      for (const f of files) {
        if (this.withMeta && FileReader) {
          const fr = new FileReader();
          this.generateFileMeta(f, fr);
        }
        f.errors = {};

        fileArr.push(f);
      }

      fn(fileArr);
    };
  }

  private generateFileMeta(f: ICustomFile, fr: FileReader) {
    if (f.type.match(/text.*/)) {
      f.textLoadReplay = this.setText(f, fr);
    } else if (f.type.match(/image.*/)) {
      f.imgLoadReplay = this.setImage(f, fr);
    }
  }

  private setText(f: ICustomFile, fr: FileReader): ReplaySubject<ProgressEvent> {
    const frLoadObs = fromEventPattern<ProgressEvent>(
      (handler: any) => fr.addEventListener('load', handler),
      (handler: any) => fr.removeEventListener('load', handler)
    ).pipe(take(1), shareReplay());

    const onloadReplay = new ReplaySubject<ProgressEvent>(1);
    frLoadObs.subscribe(onloadReplay);

    frLoadObs.pipe(take(1)).subscribe(() => {
      f.textContent = fr.result + '';
    });

    fr.readAsText(f);

    return onloadReplay;
  }

  private setImage(f: ICustomFile, fr: FileReader): ReplaySubject<[Event, ProgressEvent]> {
    f.isImg = true;

    const img = new Image();

    const imgLoadObs = fromEventPattern<Event>(
      (handler: any) => img.addEventListener('load', handler),
      (handler: any) => img.removeEventListener('load', handler)
    ).pipe(take(1), shareReplay());

    const frLoadObs = fromEventPattern<ProgressEvent>(
      (handler: any) => fr.addEventListener('load', handler),
      (handler: any) => fr.removeEventListener('load', handler)
    ).pipe(take(1), shareReplay());

    const onloadReplay = new ReplaySubject<[Event, ProgressEvent]>(1);
    const observables = [imgLoadObs, frLoadObs];

    forkJoin(observables)
      .pipe(take(1))
      .subscribe(onloadReplay);

    imgLoadObs.pipe(take(1)).subscribe(() => {
      f.imgHeight = img.height;
      f.imgWidth = img.width;
    });

    fr.readAsDataURL(f);

    return onloadReplay;
  }

  private generateRegExp(pattern: RegExp | string | string[]): RegExp | null {
    if (!pattern) {
      return null;
    }

    if (pattern instanceof RegExp) {
      return new RegExp(pattern);
    } else if (typeof pattern === 'string') {
      return new RegExp(pattern, 'ig');
    } else if (pattern instanceof Array) {
      return new RegExp(`(${pattern.join('|')})`, 'ig');
    }
    return null;
  }

  validate(c: AbstractControl) {
    if (!c.value || !c.value.length || c.disabled) {
      return null;
    }

    let errors: ValidationErrors = {};

    for (const f of c.value) {
      if (this.size && this.size < f.size) {
        f.errors['fileSize'] = true;
        errors['fileSize'] = true;
      }

      if (!this.allowedExt && !this.allowedTypes) {
        return errors;
      }

      const extP = this.generateRegExp(this.allowedExt);
      const typeP = this.generateRegExp(this.allowedTypes);

      const fileErrors = {
        ...(extP &&
          !extP.test(f.name) && {
            fileExt: true
          }),
        ...(typeP &&
          f.type &&
          !typeP.test(f.type) && {
            fileType: true
          })
      };

      errors = {
        ...errors,
        ...fileErrors
      };

      f.errors = {
        ...f.errors,
        ...fileErrors
      };
    }

    if (!this.isLoad) {
      if (Object.keys(errors).length === 0) {
        this.fileList = c.value;

        const response = {};

        this.progress = this.uploadService.upload(c.value, this.uploadUrl, this.uploadHeaders);
        Object.keys(this.progress).forEach(key => {
          this.progress[key].response.subscribe(val => {
            response[key] = val;
            c = this.handleSuccess(c, response);
          });
        });
        return null;
      } else {
        this.controlContainer.control.get(this.controlName).setValue([]);
        this.uploadInput.nativeElement.value = '';
        this.alertFailModal(errors);
        return errors;
      }
    }
  }

  handleSuccess(c, response) {
    if (c.value && c.value.length > 0) {
      c.value.forEach((_file, index) => {
        c.value[index].imgSrc = `${this.baseStorageUrl}/${response[index].original}`;
        c.value[index].id = `${response[index].id}`;
        c.value[index].filePath = `${response[index].original}`;
        c.value[index].directory = response[index].directory ? `${response[index].directory}` : '';
        c.value[index].medium = response[index].medium ? `${response[index].medium}` : '';
        c.value[index].small = response[index].small ? `${response[index].small}` : '';
        c.value[index].fileName = response[index].fileName ? `${response[index].fileName}` : '';
      });

      this.imageSuccess.emit(c.value);
    }

    return c;
  }

  onClickDelete() {
    this.ngChange(this.value);

    const initialState = {
      title: 'Confirm',
      okText: 'Yes, delete',
      cancelText: 'Cancel',
      message: 'Are you sure you want to delete image?'
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.fileList = [];
          this.controlContainer.control.get(this.controlName).setValue([]);
          this.isDelete.emit(true);
        }
        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  alertFailModal(errors) {
    const isFileTypeError = errors['fileType'] || errors['fileExt'];

    const initialState = {
      title: 'Failed',
      message: `${isFileTypeError ? this.errorIncorrectFormat : this.errorImageSize}`
    };
    this.logger.debug('alertFailModal->isFileSizeError', errors['fileSize']);
    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  onShowImage() {
    this.showImage.emit();
  }
}
