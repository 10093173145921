import { HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SignedUrlObject } from '../models';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {
  public env = { ...environment };
  public envService;
  public headers: HttpHeaders;

  protected constructor() {}

  get timezone(): string {
    return moment().format('Z');
  }

  loaderHeaders(ms = 0): HttpHeaders {
    return new HttpHeaders(this.envService.headers).set('x-frontend-spinner-loader', ms.toString());
  }

  /**
   * Encode an object to HttpParams
   * eg. this.getParams({key:value})
   * @param query  Object for encoding to HttpParams.
   * @param noBracket  Object for no include bracket.
   * @returns HttpParams with a query.
   */
  protected getParams(query, noBracket = false): HttpParams {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(query)) {
      if (query[key]) {
        if (query[key] instanceof Array) {
          query[key].forEach(item => {
            params = params.append(noBracket ? `${key.toString()}` : `${key.toString()}[]`, item);
          });
        } else {
          params = params.append(key.toString(), query[key]);
        }
      } else if (query[key] === 0 || query[key] === false) {
        params = params.append(key.toString(), query[key]);
      }
    }

    return params;
  }

  /**
   * Returns the full url of the service.
   * eg. this.getUrl('serviceAppend',{'param1':'Param1','param2':'Param2'}
   * @param serviceUrl  serviceUrl.
   * @param urlParams  Object for replacing all matches in serviceAppend.
   * @returns Full service url
   */
  protected getFullUrl(serviceUrl, urlParams: { [key: string]: string } = null): string {
    for (const urlParamsKey in urlParams) {
      if (urlParams[urlParamsKey]) {
        serviceUrl = serviceUrl.replace(new RegExp(`{${urlParamsKey}}`, 'g'), urlParams[urlParamsKey]);
      }
    }

    return this.env.serverUrl + this.envService.url + serviceUrl;
  }

  public getUrl() {
    return this.env.serverUrl + this.envService.url;
  }

  public getFileUrl(refId: string): Observable<SignedUrlObject> {
    return of({ refId, signedUrl: null } as SignedUrlObject);
  }
}

export class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
