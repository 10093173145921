import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import {
  InformationActionEnum,
  InformationTypeEnum,
  PurchaseConditionPageModes
} from '../../../shared/enum/purchase-condition.enum';
import { NewRequestTypeEnum } from '../../../shared/enum/request-step.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import {
  PurchaseConditionSupplierContractModel,
  PurchaseConditionSupplierDcModel
} from '../../../shared/models/purchase-condition.model';
import { AuthGuardService } from '../../../shared/services';
import { LayoutForceCloseCurrentModal } from '../../../shared/store/actions/layout.action';
import {
  PurchaseConditionContractRequested,
  PurchaseConditionDcRequested,
  PurchaseConditionSupplierAddContract,
  PurchaseConditionSupplierAddDc,
  PurchaseConditionSupplierDeletedContract,
  PurchaseConditionSupplierDeletedDc,
  PurchaseConditionSupplierUpdatedContract,
  PurchaseConditionSupplierUpdatedDc
} from '../../../shared/store/actions/purchase-condition.actions';
import { selectForceCloseCurrentModal } from '../../../shared/store/selectors/layout-selector';
import { selectPurchaseConditionRequestView } from '../../../shared/store/selectors/purchase-condition.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { PurchaseConditionPermission } from '../purchase-condition-permission';
import { ModalAddInformationComponent } from './modal-add-information/modal-add-information.component';

@Component({
  selector: 'app-supplier-information',
  templateUrl: './supplier-information.component.html',
  styleUrls: ['./supplier-information.component.scss']
})
export class SupplierInformationComponent implements OnInit, OnDestroy {
  protected subscriptions: import('rxjs').Subscription;
  public bsDateConfig: import('ngx-bootstrap').BsDatepickerConfig;
  @Input() supplierInformationForm: FormGroup;
  @Input() purchaseConditionForm: FormGroup;
  @Input() mode: PurchaseConditionPageModes;
  @Input() requestType: NewRequestTypeEnum;
  @Output() dirtyInformation = new EventEmitter<boolean>();

  public vatList: NgOption[] = filterDropdown.vatLOV;
  public informationType = InformationTypeEnum;
  private localStore: Observable<any>;
  public isContactType: boolean;
  public purchaseConditionPageMode = PurchaseConditionPageModes;
  public requestTypeEnum = NewRequestTypeEnum;
  public purchaseConditionPermission = new PurchaseConditionPermission();
  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.initialInformationForViewEdit();
    this.purchaseConditionPermission.checkPermission(this.authGuardService);
    if ([PurchaseConditionPageModes.VIEW, PurchaseConditionPageModes.EDIT].includes(this.mode)) {
      this.localStore
        .pipe(
          select(selectForceCloseCurrentModal),
          switchMap(forCloseCurrentModal => {
            if (forCloseCurrentModal) {
              return this.subscribeForCloseModal(forCloseCurrentModal);
            } else {
              return of(forCloseCurrentModal);
            }
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {}

  get contactInformationItems() {
    return this.purchaseConditionForm.get('contactInformation') as FormArray;
  }

  get dcInformationItems() {
    return this.purchaseConditionForm.get('dcInformation') as FormArray;
  }

  initialInformationForViewEdit() {
    this.localStore
      .pipe(
        select(selectPurchaseConditionRequestView),
        filter(data => Boolean(data))
      )
      .subscribe(purchaseCondition => {
        if (purchaseCondition.supplierContacts) {
          this.resetContactInformationItems();
          if (purchaseCondition.supplierContacts.length) {
            purchaseCondition.supplierContacts.forEach(item => this.addContactItem(item));
          }
        }

        if (purchaseCondition.supplierDistributionCenters) {
          this.resetDcInformationItems();
          if (purchaseCondition.supplierDistributionCenters.length) {
            purchaseCondition.supplierDistributionCenters.forEach(item => this.addDcItem(item));
          }
        }
      });
  }

  resetContactInformationItems() {
    if (this.contactInformationItems.length) {
      this.contactInformationItems.clear();
    }
  }
  resetDcInformationItems() {
    if (this.dcInformationItems.length) {
      this.dcInformationItems.clear();
    }
  }

  showModalInformation(initialState) {
    const modal = this.modalService.show(ModalAddInformationComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState
    });

    modal.content.addInformationForm
      .pipe(debounceTime(500), untilComponentDestroyed(this))
      .subscribe((information: { form: any; action: InformationActionEnum }) => {
        if ([this.purchaseConditionPageMode.VIEW, this.purchaseConditionPageMode.EDIT].includes(this.mode)) {
          if (this.isContactType) {
            this.addOrUpdateContractForPurchaseCondition(information.form, information.action);
          } else {
            this.addOrUpdateDcForPurchaseCondition(information.form, information.action);
          }
        } else {
          if (this.isContactType) {
            this.addContactItem(information.form);
          } else {
            this.addDcItem(information.form);
          }
          this.dirtyInformation.emit(true);
        }
      });
  }

  addContactItem(information) {
    return this.contactInformationItems.push(this.createNewContactInformation(information));
  }

  createNewContactInformation(information): FormGroup {
    return this.fb.group({
      id: [information.id],
      name: [information.name],
      detail: [information.detail ? information.detail : information.phoneNo],
      remark: [information.remark],
      version: [information.version]
    });
  }

  addDcItem(information) {
    return this.dcInformationItems.push(this.createNewDCInformation(information));
  }

  createNewDCInformation(information): FormGroup {
    return this.fb.group({
      id: [information.id],
      name: [information.name],
      detail: [information.detail ? information.detail : information.address],
      remark: [information.remark],
      version: [information.version]
    });
  }

  alertModalConfirmDelete(type: InformationTypeEnum, index: number, item: AbstractControl): void {
    this.isContactType = this.getContactType(type);
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to delete ${this.isContactType ? 'contact' : 'DC'} Information?`,
        okText: 'Yes, delete'
      }
    });

    confirmModalRef.content.action
      .pipe(debounceTime(500), untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK && this.isContactType) {
          if ([this.purchaseConditionPageMode.VIEW, this.purchaseConditionPageMode.EDIT].includes(this.mode)) {
            this.store.dispatch(
              new PurchaseConditionSupplierDeletedContract({
                id: item.get('id').value,
                supplierCode: this.getSupplierCode
              })
            );
          }

          this.contactInformationItems.removeAt(index);
        } else if (result === ModalButtonResponseEnum.OK && !this.isContactType) {
          if ([this.purchaseConditionPageMode.VIEW, this.purchaseConditionPageMode.EDIT].includes(this.mode)) {
            this.store.dispatch(
              new PurchaseConditionSupplierDeletedDc({ id: item.get('id').value, supplierCode: this.getSupplierCode })
            );
          }

          this.dcInformationItems.removeAt(index);
        }
      });
  }

  onClickAddInformation(type: InformationTypeEnum) {
    this.isContactType = this.getContactType(type);
    const initialState = {
      isContractType: this.isContactType,
      title: this.isContactType ? 'Add Contact' : 'Add DC',
      labelInputTextFirst: this.isContactType ? 'Contact Name' : 'DC Name',
      labelInputTextSecondary: this.isContactType ? 'Contact Number' : 'DC Address',
      labelInputTextThird: 'Remark',
      action: InformationActionEnum.ADD,
      itemList: this.isContactType ? this.contactInformationItems : this.dcInformationItems,
      pageMode: this.mode,
      supplierCode: this.getSupplierCode
    };

    this.showModalInformation(initialState);
  }

  onClickEditInformation(type: InformationTypeEnum, item: AbstractControl, index: number) {
    this.isContactType = this.getContactType(type);
    const initialState = {
      isContractType: this.isContactType,
      title: this.isContactType ? 'Edit Contact' : 'Edit DC',
      labelInputTextFirst: this.isContactType ? 'Contact Name' : 'DC Name',
      labelInputTextSecondary: this.isContactType ? 'Contact Number' : 'DC Address',
      labelInputTextThird: 'Remark',
      action: InformationActionEnum.EDIT,
      informationFormIndex: item,
      rowId: index + 1,
      itemList: this.isContactType ? this.contactInformationItems : this.dcInformationItems,
      pageMode: this.mode,
      supplierCode: this.getSupplierCode
    };
    this.showModalInformation(initialState);
  }

  getContactType(type: InformationTypeEnum) {
    return type === InformationTypeEnum.CONTACT || false;
  }

  get getSupplierCode() {
    return this.supplierInformationForm.get('supplierCode').value;
  }

  addOrUpdateContractForPurchaseCondition(information, action: InformationActionEnum) {
    const payload: PurchaseConditionSupplierContractModel = {
      id: information.id ? information.id : null,
      version: information.version ? information.version : 0,
      supplierCode: this.getSupplierCode,
      name: information.name,
      phoneNo: information.detail,
      remark: information.remark
    };

    if (action === InformationActionEnum.ADD) {
      this.store.dispatch(new PurchaseConditionSupplierAddContract(payload));
    } else if (action === InformationActionEnum.EDIT) {
      this.store.dispatch(new PurchaseConditionSupplierUpdatedContract(payload));
    }
  }

  addOrUpdateDcForPurchaseCondition(information, action: InformationActionEnum) {
    const payload: PurchaseConditionSupplierDcModel = {
      id: information.id ? information.id : null,
      version: information.version ? information.version : 0,
      supplierCode: this.getSupplierCode,
      name: information.name,
      address: information.detail,
      remark: information.remark
    };

    if (action === InformationActionEnum.ADD) {
      this.store.dispatch(new PurchaseConditionSupplierAddDc(payload));
    } else if (action === InformationActionEnum.EDIT) {
      this.store.dispatch(new PurchaseConditionSupplierUpdatedDc(payload));
    }
  }

  subscribeForCloseModal(layoutError) {
    const initialState = {
      title: 'Failed',
      message: 'The current session is no longer because data has been changed.',
      isRefresh: false
    };
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    return alertModal.content.action.pipe(
      untilComponentDestroyed(this),
      tap((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          if (layoutError) {
            if (this.isContactType) {
              this.store.dispatch(new PurchaseConditionContractRequested({ supplierCode: this.getSupplierCode }));
            } else {
              this.store.dispatch(new PurchaseConditionDcRequested({ supplierCode: this.getSupplierCode }));
            }

            this.store.dispatch(new LayoutForceCloseCurrentModal(false));
          }
          alertModal.hide();
        }
      })
    );
  }
}
