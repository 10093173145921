import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaseSearchComponent } from '../../../../base/base-search.component';
import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { ShelfSelectedItems } from '../../../../shared/models/first-lot-order.model';
import { NotificationEmit } from '../../../../shared/models/notification-emit.model';
import { ShelfSelectFixAssetList } from '../../../../shared/models/shelf-fix-asset.model';
import { ShelfSelectFixAssetSearchCriteria } from '../../../../shared/models/shelf-request.model';
import { ShelfFixAssetDetailsRequestAction } from '../../../../shared/store/actions/shelf-fix-asset-details.actions';
import {
  ShelfSelectFixAssetGenerateShelfItemsRequestAction,
  ShelfSelectFixAssetListRequest,
  ShelfSelectFixAssetListReset
} from '../../../../shared/store/actions/shelf-select-fix-asset.actions';
import { ShelfSelectFixAssetState } from '../../../../shared/store/reducers/shelf-select-fix-asset.reducers';
import { selectShelfFixAssetDetails } from '../../../../shared/store/selectors/shelf-fix-asset-details.selectors';
import {
  selectAllShelfSelectFixAssetList,
  selectShelfFixAssetList
} from '../../../../shared/store/selectors/shelf-select-fix-asset.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-select-shelf-fix-asset-modal',
  templateUrl: './select-shelf-fix-asset-modal.component.html',
  styleUrls: ['./select-shelf-fix-asset-modal.component.scss']
})
export class SelectShelfFixAssetModalComponent extends BaseSearchComponent<
  ShelfSelectFixAssetSearchCriteria,
  ShelfSelectFixAssetList,
  ShelfSelectFixAssetState
> {
  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder
  ) {
    super(store, modalService, selectAllShelfSelectFixAssetList, selectShelfFixAssetList);
  }
  @Input() data: { title: string };
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  public localStore: Observable<any>;
  public selectedShelf: ShelfSelectedItems[] = [];

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.localStore
      .pipe(
        select(selectShelfFixAssetDetails),
        filter(response => Boolean(response))
      )
      .subscribe(selected => {
        this.selectedShelf = [...selected];
      });
  }

  doAfterVersionAlertModal() {}

  doDestroy() {
    this.store.dispatch(new ShelfSelectFixAssetListReset());
  }

  onBack() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });
  }

  goToView() {}

  onAdvanceSubmit() {}

  prepareSearchCriteriaTags() {}

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      shelfName: formValue.searchCriteria,
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  search(criteriaObj) {
    this.store.dispatch(new ShelfSelectFixAssetListRequest(criteriaObj));
  }

  clearSearchText() {
    this.clearSearch();
  }

  clearLastKeyUpSearchText(_event) {
    const formValue = this.searchForm.value;
    if (formValue && formValue.searchCriteria.length > 0) {
      return;
    }

    this.clearSearch();
  }

  clearSearch() {
    this.setFirstPage();

    this.searchForm.get('searchCriteria').setValue(null);

    this.criteriaObject = {
      ...this.criteriaObject,
      shelfName: null,
      page: 0
    };

    this.search(this.criteriaObject);
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      shelfName: null,
      status: 'ACTIVE',
      page: 0,
      size: 20,
      sortBy: 'shelfName.sort',
      sortOrder: 'asc'
    };
  }

  setRouteTab() {}

  addItem(shelfNo: string, qty: number) {
    if (qty && qty > 0) {
      const existShelf = this.selectedShelf.find(x => x.shelfNo === shelfNo);
      if (existShelf) {
        existShelf.qty = existShelf.qty + Number(qty);
      } else {
        this.selectedShelf.push({ shelfNo, qty: Number(qty) });
      }
    }
  }

  getAddedItem(shelfNo: string) {
    const existShelf = this.selectedShelf.find(x => x.shelfNo === shelfNo);

    if (existShelf) {
      return existShelf.qty;
    }

    return 0;
  }

  onGenerateShelfFixAsset() {
    if (this.selectedShelf && this.selectedShelf.length > 0) {
      const result = { response: true, errorResponse: null };
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.NEXT, result: result });

      this.store.dispatch(new ShelfFixAssetDetailsRequestAction(this.selectedShelf));
      this.store.dispatch(new ShelfSelectFixAssetGenerateShelfItemsRequestAction(this.selectedShelf));
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  setInitialValue() {
    this.pageSize = 20;
  }

  setFirstPage() {
    this.currentPage = 1;
  }
}
