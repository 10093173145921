import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { FilesInputComponent } from '../files-input/files-input.component';

@Component({
  selector: 'app-files-submit',
  templateUrl: './files-submit.component.html',
  styleUrls: ['./files-submit.component.scss']
})
export class FilesSubmitComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput: FilesInputComponent;
  @Output() downloadTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadErrorExcel: EventEmitter<any> = new EventEmitter<any>();
  @Output() upload: EventEmitter<any> = new EventEmitter<any>();
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Input() title: string;
  @Input() subTitle: string;
  @Input() templateName: string;
  @Input() fileTitle: string;
  @Input() loading: boolean;
  @Input() isUploadSuccess: boolean;
  @Input() confirmSubmitText = 'The data have been uploaded. Are you sure you want to submit?';

  public form: FormGroup;
  public errorMessage: string;
  public isFileError: boolean;

  constructor(
    private readonly fb: FormBuilder,
    private readonly bsModalRef: BsModalRef,
    protected readonly logger: NGXLogger
  ) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {}

  createForm() {
    this.form = this.fb.group({
      files: [null, Validators.required]
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  onSubmit(files?: Array<File>) {
    this.loading = true;
    if (this.isUploadSuccess) {
      this.submit.emit();
    } else {
      this.upload.emit(files[0]);
    }
  }

  reset() {
    this.errorMessage = null;
    this.isUploadSuccess = false;
    this.isFileError = false;
  }

  onDownloadTemplate() {
    this.downloadTemplate.emit();
  }

  setErrorMessage(message: string, isFileError = false) {
    this.errorMessage = message;
    this.isFileError = isFileError;
  }

  onDownloadErrorExcel() {
    this.downloadErrorExcel.emit(this.form.value.files[0].name);
  }
}
