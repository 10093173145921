<ng-content></ng-content>

<div *ngIf="isSubmitted && contentChild.errors" class="invalid-feedback">
  <div *ngIf="contentChild.hasError('required'); else anyCase">
    {{ 'ERRORS.REQUIRED' | translate }}
  </div>
  <ng-template #anyCase>
    <div *ngIf="contentChild.hasError('email')">
      {{ 'ERRORS.INVALID_EMAIL' | translate }}
    </div>
    <div *ngIf="contentChild.hasError('isZero')">
      {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
    </div>
    <div *ngIf="contentChild.hasError('pattern')">
      {{ 'ERRORS.INVALID_LATITUDE' | translate }}
    </div>
    <div *ngIf="contentChild.hasError('deliveryDateTo')">
      {{ 'ERRORS.REQUIRED_AT_LEAST_ONE_DAY_SCHEDULE' | translate }}
    </div>
  </ng-template>
</div>
