import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { RewardPageModes } from '../../../shared/enum/reward.enum';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { ConditionPurchase, RewardRequestResponse } from '../../../shared/models/reward.model';
import { selectRewardRequest } from '../../../shared/store/selectors/reward-request.selectors';
import { selectReward } from '../../../shared/store/selectors/reward.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { PurchaseQuantityProductItemComponent } from '../purchase-quantity-product-item/purchase-quantity-product-item.component';

@Component({
  selector: 'app-purchase-quantity-coupon',
  templateUrl: './purchase-quantity-coupon.component.html',
  styleUrls: ['./purchase-quantity-coupon.component.scss']
})
export class PurchaseQuantityCouponComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() mode: RewardPageModes;
  @Input() saved: boolean;
  @Input() submitted: boolean;
  @Input() conditionPurchase: ConditionPurchase;
  @Input() maximumRow: number;
  @Input() isEditFirst: boolean;
  @ViewChild('purchaseQuantityCouponProductItem', { static: false })
  purchaseQuantityProductItemComponent: PurchaseQuantityProductItemComponent;
  public rewardRequestView$: Observable<RewardRequestResponse>;
  private localStore: Observable<any>;
  public offerPromotionSelect = filterDropdown.yesNoOption;

  constructor(public fb: FormBuilder, protected readonly store: Store<AppStates>) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.createFormPurchaseQuantityCoupon();

    if ([RewardPageModes.REQUEST_VIEW, RewardPageModes.REQUEST_EDIT, RewardPageModes.VIEW].includes(this.mode)) {
      this.setValue();
    }

    if (this.mode === RewardPageModes.REQUEST_VIEW || this.mode === RewardPageModes.VIEW) {
      this.purchaseQuantityCoupon.disable();
    }
  }

  createFormPurchaseQuantityCoupon() {
    const setForm = {
      purchaseQty: this.validateRequired,
      offerPromotion: [{ value: false, disabled: false }],
      couponAmount: this.validateRequired,
      minPerBillAmount: [{ value: null, disabled: false }, Validators.required]
    };

    this.parentForm.addControl('purchaseQtyDetails', this.fb.group(setForm));
  }
  setValue() {
    if (this.mode === RewardPageModes.VIEW) {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectReward),
        filter(data => data !== null)
      );
    } else {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectRewardRequest),
        filter(data => data !== null)
      );
    }

    this.rewardRequestView$
      .pipe(
        filter(value => Boolean(value)),
        map(response => response),
        take(1)
      )
      .subscribe(req => {
        if (req && req.purchaseQtyDetails) {
          this.purchaseQuantityCoupon.patchValue({
            ...req.purchaseQtyDetails,
            couponAmount: req.purchaseQtyDetails.couponAmount ? req.purchaseQtyDetails.couponAmount.amount : null,
            minPerBillAmount: req.purchaseQtyDetails.minPerBillAmount
              ? req.purchaseQtyDetails.minPerBillAmount.amount
              : null
          });
        }

        if (req && req.details) {
          const promotion = req.details.offerPromotion !== undefined ? req.details.offerPromotion : false;
          this.purchaseQuantityCoupon.controls['offerPromotion'].setValue(promotion);
        }

        if ([RewardPageModes.REQUEST_EDIT].includes(this.mode)) {
          this.setEditMode();
        }
      });

    if (this.mode === RewardPageModes.REQUEST_VIEW || this.mode === RewardPageModes.VIEW) {
      this.purchaseQuantityCoupon.disable();
    }
  }

  setEditMode() {
    this.purchaseQuantityCoupon.enable();
  }

  get purchaseQuantityCoupon() {
    return this.parentForm.get('purchaseQtyDetails') as FormGroup;
  }
  get validateRequired() {
    return [{ value: null, disabled: false }, [Validators.required, this.notAllowZeroValidator]];
  }

  get notAllowZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null) {
        return control.value === 0 ? { isZero: true } : null;
      }
      return null;
    };
  }

  ngOnDestroy(): void {
    this.parentForm.removeControl('purchaseQtyDetails');
  }
}
