import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsDatepickerConfig, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { environment, environment as env } from '../../../../../environments/environment';
import { emailValidator } from '../../../custom-validators/email-validator';
import { isZeroValidator } from '../../../custom-validators/is-zero-validator';
import { merchantDuplicatedValidator } from '../../../custom-validators/merchant-duplicated-validator';
import { FileModuleEnum } from '../../../enum/file-url.enum';
import { MasterDataEnum } from '../../../enum/master-data.enum';
import { TDStoreValidatorTypeEnum } from '../../../enum/merchant-validator-type.enum';
import { RequestSectionEnum } from '../../../enum/request-section.enum';
import { RequestStatusEnum } from '../../../enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../enum/request-step.enum';
import { TDStorePage } from '../../../enum/td-store-page.enum';
import { MerchantRequestViewResponse, StoreProfile } from '../../../models';
import { deliveryBy4Wheels } from '../../../models/list-value/list-key-value.model';
import { MasterService } from '../../../services/master.service';
import { MerchantRequestService } from '../../../services/merchant-request.service';
import { AppStates } from '../../../store/state/app.states';
import { getFileUploadObj } from '../../../utils/get-file-name-from-ref-id-util';
import { getSelectByPage } from '../../../utils/get-select-by-page-util';
import { TDStoreWorkflowUtil } from '../../../utils/td-store-workflow-util';
import { DeliveryTimesComponent } from './delivery-times/delivery-times.component';

@Component({
  selector: 'app-store-profile',
  templateUrl: './store-profile.component.html',
  styleUrls: ['./store-profile.component.scss']
})
export class StoreProfileComponent implements OnInit, OnDestroy {
  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    private readonly merchantService: MerchantRequestService,
    private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil,
    private readonly masterService: MasterService
  ) {}

  public merchantRequestView$: Observable<MerchantRequestViewResponse>;
  @ViewChild('deliveryTimes', { static: false }) deliveryTimes: DeliveryTimesComponent;
  @Input() parentForm: FormGroup;
  @Input() submitted: boolean;
  @Input() saved: boolean;
  @Input() requestId: string;
  @Input() mode: RequestPageModesEnum;
  @Input() page: TDStorePage;

  private localStore: Observable<any>;
  public listOfValue: {};
  public dateFormat = environment.dateFormat;
  public bsConfig: BsDatepickerConfig;

  public buildingSelectValue: NgOption[];
  public buildingSizeSelectValue: NgOption[];
  public stateSelectValue: NgOption[];
  public regionSelectValue: NgOption[];
  public timeZoneSelectValue: NgOption[];
  public propertyOwnerhipSelectValue: NgOption[];
  public countrySelectValue: NgOption[];
  public parkingSelectValue: NgOption[];
  public competitorSelectValue: NgOption[];
  public poiSelectValue: NgOption[];
  public poiDistanceSelectValue: NgOption[];
  public restrictDeliveryTimeSelectValue: NgOption[];

  public merchantPage = TDStorePage;
  public fileModule = FileModuleEnum;

  private type: RequestTypeEnum;
  private step: RequestStepEnum;
  private status: RequestStatusEnum;

  ngOnDestroy(): void {}

  ngOnInit() {
    this.type = RequestTypeEnum.NEW;
    this.step = RequestStepEnum.PROFILE;
    this.status = RequestStatusEnum.DRAFT;

    this.bsConfig = ({
      dateInputFormat: this.dateFormat,
      minDate: new Date(),
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue',
      useUtc: true
    } as unknown) as BsDatepickerConfig;

    this.parentForm.addControl('storeProfile', this.fb.array([]));

    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.masterService
      .getMasterDataByNames([
        MasterDataEnum.BUILDING,
        MasterDataEnum.BUILDING_SIZE,
        MasterDataEnum.STATE,
        MasterDataEnum.REGION,
        MasterDataEnum.TIMEZONE,
        MasterDataEnum.PROPERTY_OWNERSHIP,
        MasterDataEnum.COUNTRY,
        MasterDataEnum.PARKING_CONDITION,
        MasterDataEnum.COMPETITOR,
        MasterDataEnum.POI,
        MasterDataEnum.POI_DISTANCE,
        MasterDataEnum.RESTRICTED_DELIVERY_TIME
      ])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.buildingSelectValue = result.building;
        this.buildingSizeSelectValue = result.buildingSizes;
        this.stateSelectValue = result.states;
        this.regionSelectValue = result.regions;
        this.timeZoneSelectValue = result.timezones;
        this.propertyOwnerhipSelectValue = result.propertyOwnership;
        this.countrySelectValue = result.countries;
        this.parkingSelectValue = result.parkingConditions;
        this.competitorSelectValue = result.competitors;
        this.poiSelectValue = result.poi;
        this.poiDistanceSelectValue = result.poiDistances;
        this.restrictDeliveryTimeSelectValue = result.restrictedDeliveryTime;

        this.storeProfile.push(this.createStoreProfile());

        if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.mode)) {
          this.setStoreProfileValue();
        }
      });

    this.listOfValue = { deliveryBy4Wheels };
  }

  disableRentalField(store: FormGroup) {
    if (store.controls['propertyOwnership'].value === 'Own') {
      store.controls['rentalFee'].disable();
      store.controls['rentalFee'].patchValue(null);
    } else if (store.controls['propertyOwnership'].value === 'Rent') {
      store.controls['rentalFee'].clearValidators();
      store.controls['rentalFee'].enable();
    } else {
      store.controls['rentalFee'].setValidators(Validators.required);
      store.controls['rentalFee'].disable();
    }

    store.updateValueAndValidity();
  }

  isShowDuplicatedError(control: AbstractControl): string {
    const isDuplicated = control.errors ? control.errors.duplicated : false;
    return ((this.saved || this.submitted) && control.errors) || isDuplicated ? 'is-invalid' : '';
  }

  isUniqueValidator(controlName): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null && this.storeProfile.controls.length > 1) {
        const isDuplicated = this.storeProfile.controls
          .map((formGroup: AbstractControl | any) => formGroup.controls[controlName])
          .filter(formControl => formControl !== control)
          .every((formControl: FormControl) => formControl.value === control.value);

        return isDuplicated ? { duplicated: isDuplicated } : null;
      }
      return null;
    };
  }

  setStoreProfileValue() {
    this.storeProfile.disable();

    this.merchantRequestView$ = this.localStore.pipe(
      select(getSelectByPage(this.page)),
      filter(data => data !== null)
    );
    this.merchantRequestView$
      .pipe(
        map(response => response),
        filter(value => value !== null),
        take(1)
      )
      .subscribe(value => {
        this.type = value.type || RequestTypeEnum.EDIT;
        this.step = value.step || RequestStepEnum.EDIT_PROFILE;
        this.status = value.status || RequestStatusEnum.DRAFT;

        if (
          value.merchantInfo &&
          this.tdStoreWorkflowUtil.isShowSection(this.type, this.page, this.step, RequestSectionEnum.STORE_PROFILE) &&
          value.merchantInfo.storeProfile.length > 0
        ) {
          value.merchantInfo.storeProfile.forEach((store, i) => {
            if (!this.storeProfile.at(i)) {
              this.storeProfile.push(this.createStoreProfile());
            }
            const storeProfilePatched = this.createStoreProfileValue(store);

            this.storeProfile
              .at(i)
              .get('storeCode')
              .clearAsyncValidators();

            this.storeProfile
              .at(i)
              .get('storeCode')
              .updateValueAndValidity({ onlySelf: true });

            this.storeProfile
              .at(i)
              .get('storeName')
              .clearAsyncValidators();

            this.storeProfile
              .at(i)
              .get('storeName')
              .updateValueAndValidity({ onlySelf: true });

            this.storeProfile.controls[i].patchValue(storeProfilePatched);
          });
        }

        this.setStoreProfileCtrl(this.type, this.page, this.step, this.mode);
      });
  }

  createStoreProfileValue(store: StoreProfile) {
    return {
      ...store,
      openDate: store.openDate ? moment(store.openDate, [env.dateFormat, moment.ISO_8601]).toDate() : null,
      storePicFront: this.getStoreMultipleImage(store.storeFrontPicture, 'storePicFront'),
      phone: store.countryCode && store.phone ? store.countryCode + store.phone : null,
      rentalFee: (store.rentalFee && store.rentalFee.amount) || null,
      storeVDOFront: getFileUploadObj(store.storeFrontVideo),
      titleDeed: getFileUploadObj(store.titleDeed),
      houseRegistrationPicture: getFileUploadObj(store.houseRegistrationPicture),
      idCardPicture: getFileUploadObj(store.idCardPicture),
      consentLetterPicture: getFileUploadObj(store.consentLetterPicture),
      attachmentPicture: this.getStoreMultipleImage(store.attachmentPicture, 'attachmentPicture')
    };
  }
  getStoreMultipleImage(storeImages, fields) {
    if (!storeImages || storeImages.length === 0) {
      return [{ image: [] }];
    }

    const images = [];
    const storeProfileImages = this.storeProfile.at(0).get(fields) as FormArray;

    storeImages.forEach((image, i) => {
      if (!storeProfileImages.at(i)) {
        storeProfileImages.push(this.fb.group({ image: [{ value: null, disabled: false }] }));
      }

      const imageArray = { image: [] };
      imageArray.image = getFileUploadObj(image);
      images.push(imageArray);
    });
    return images;
  }

  createStoreProfile() {
    const regExpLatitude = /^([+\-])?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    const regExpLongitude = /^([+\-])?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    const regExpBranchNo = /^\d{5}$/;
    const initialNull = [{ value: null, disabled: false }];
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];
    const initialNullRequiredNotZero = [{ value: null, disabled: false }, [Validators.required, isZeroValidator()]];
    const timezone = 'Asia/Bangkok';

    const storeForm: FormGroup = this.fb.group({
      openDate: initialNullRequired,
      storeCode: [
        { value: null, disabled: false },
        {
          validators: [Validators.required, this.isUniqueValidator('storeCode')],
          asyncValidators: [merchantDuplicatedValidator(TDStoreValidatorTypeEnum.STORE_CODE, this.merchantService)],
          updateOn: 'blur'
        }
      ],
      storeName: [
        { value: null, disabled: false },
        {
          validators: [Validators.required],
          updateOn: 'blur'
        }
      ],
      contactName: initialNullRequired,
      email: [{ value: null, disabled: false }, [Validators.required, Validators.maxLength(100), emailValidator()]],
      address: initialNullRequired,
      postCode: initialNullRequired,
      country: initialNullRequired,
      buildingType: initialNullRequired,
      unitSize: initialNullRequired,
      saleSpace: initialNullRequiredNotZero,
      stockSpace: initialNullRequiredNotZero,
      latitude: [{ value: null, disabled: false }, [Validators.required, Validators.pattern(regExpLatitude)]],
      longitude: [{ value: null, disabled: false }, [Validators.required, Validators.pattern(regExpLongitude)]],
      taxNo: [{ value: null, disabled: false }, [Validators.required, Validators.minLength(13)]],
      branchNo: [{ value: null, disabled: false }, [Validators.pattern(regExpBranchNo)]],
      state: initialNullRequired,
      region: initialNullRequired,
      timezone: [{ value: timezone, disabled: false }, Validators.required],
      phone: initialNullRequired,
      unitWLH: initialNull,
      propertyOwnership: [{ value: null, disabled: false }, Validators.required],
      rentalFee: [{ value: null, disabled: false }, [Validators.required]],
      parking: initialNullRequired,
      deliveryByFourWheelsTruck: initialNullRequired,
      storePicFront: this.fb.array([this.fb.group({ image: initialNull })]),
      storeVDOFront: initialNull,
      titleDeed: initialNull,
      houseRegistrationPicture: initialNull,
      idCardPicture: initialNull,
      consentLetterPicture: initialNull,
      attachmentPicture: this.fb.array([this.fb.group({ image: initialNull })])
    });

    if (this.countrySelectValue && this.countrySelectValue.length > 0) {
      this.countrySelectValue
        .filter(value => value && value.code !== undefined)
        .filter(() => this.storeProfile && this.storeProfile.get('country') === null)
        .forEach(value => {
          const defaultCountryCode = value.nameEn === 'Thailand' || value.code === 'TH' ? value.code : null;

          storeForm.controls.country.setValue(defaultCountryCode);
        });
    }

    return storeForm;
  }

  toggleEditStoreProfile() {
    this.mode = RequestPageModesEnum.REQUEST_EDIT;
    this.setStoreProfileCtrl(this.type, this.page, this.step, this.mode);
  }

  setStoreProfileCtrl(
    localType: RequestTypeEnum,
    localPage: TDStorePage,
    localStep: RequestStepEnum,
    localMode: RequestPageModesEnum
  ) {
    const editSection = [TDStorePage.MERCHANT_EDIT, TDStorePage.MERCHANT_REQUEST].includes(localPage)
      ? RequestSectionEnum.PROFILE
      : RequestSectionEnum.STORE_PROFILE;

    const canEditByWorkflow = this.tdStoreWorkflowUtil.canEditSection(localType, localPage, localStep, editSection);

    if (localMode === RequestPageModesEnum.REQUEST_EDIT && canEditByWorkflow) {
      this.storeProfile.enable();

      this.storeProfile.controls.forEach(store => {
        if (store instanceof FormGroup) {
          if (this.type !== RequestTypeEnum.EDIT) {
            store.controls.storeCode.setAsyncValidators([
              merchantDuplicatedValidator(
                TDStoreValidatorTypeEnum.STORE_CODE,
                this.merchantService,
                this.requestId,
                this.page
              )
            ]);
            store.controls.storeCode.updateValueAndValidity();
          }

          this.disableRentalField(store);
          this.disableFields();
        }
      });
    } else {
      this.storeProfile.disable();
    }
  }

  disableFields() {
    const storeProfileCtrl = this.storeProfile.at(0);
    if (this.step === RequestStepEnum.EDIT_PROFILE) {
      storeProfileCtrl.get('storeCode').disable();
      storeProfileCtrl.get('storeName').disable();
      storeProfileCtrl.get('taxNo').disable();
      storeProfileCtrl.get('state').disable();
      storeProfileCtrl.get('region').disable();

      storeProfileCtrl.updateValueAndValidity();
    }
  }

  isPhoneError(control: AbstractControl): string {
    return this.submitted && control.errors ? ' is-invalid' : '';
  }

  get storeProfile() {
    return this.parentForm.get('storeProfile') as FormArray;
  }
}
