<div id="supplier-group-list" class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-supplier"></em>
    <h4>{{ 'MENU.SUPPLIER' | translate }}</h4>
  </div>

  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <app-import-export-button [buttons]="buttons" (export)="openExportModal()" (import)="openImportModal()">
      </app-import-export-button>
      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="form-control searchCriteria"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="100"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="supplierListStatusFilter"
            [searchable]="false"
            [clearable]="false"
            (change)="onChangeStatus($event)"
            class="select-status"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
          ></ng-select>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="(listState$ | async)?.totalElements > 0">
    <div class="td-card" *ngFor="let supplier$ of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 cursor-pointer" (click)="goToView(supplier$)">
          <div class="row">
            <div class="col-12 d-flex">
              <label class="chk-container">
                {{ supplier$.supplierName }}
              </label>
              <span
                *ngIf="supplier$.status"
                [ngClass]="getColorStatus(supplier$.status)"
                class="card-status supplier-status ml-auto"
              >
                {{ 'SUPPLIER.SUPPLIER_STATUS.' + getSupplierStatus(supplier$.status) | translate }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Vendor No.:</span>
              <span class="result-value">{{ supplier$.supplierCode }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Tax ID:</span>
              <span class="result-value">{{ supplier$.taxCode }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Province:</span>
              <span class="result-value">{{ supplier$.province }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Last Updated:</span>
              <span class="result-value">{{ supplier$.lastModifiedDate | amFromUtc | amLocal | dateDisplay }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom"
            containerClass="fl"
            [outsideClick]="true"
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <div class="form-row">
              <button
                *ngIf="this.allowManageSupplier && this.enableActiveButton"
                type="button"
                class="btn btn-link"
                (click)="gotoActivate(supplier$)"
              >
                <span *ngIf="supplier$ && supplier$.status.toUpperCase() === 'ACTIVE'; else isDeactivate">
                  <em class="icon-deactivate"></em> <span>Deactivate</span>
                </span>
                <ng-template #isDeactivate>
                  <span> <em class="icon-activate"></em> <span>Activate</span> </span>
                </ng-template>
              </button>
              <button
                *ngIf="supplier$ && supplier$.status.toUpperCase() === 'ACTIVE' && this.allowManageSupplier"
                type="button"
                class="btn btn-link"
                (click)="gotoEdit(supplier$)"
              >
                <em class="icon-edit"></em>Edit
              </button>
              <button type="button" class="btn btn-link" (click)="showHistory(supplier$)">
                <em class="icon-history"></em>History
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
    <!--pagination-->
  </ng-container>

  <ng-container *ngIf="(listState$ | async)?.totalElements <= 0">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
        </div>
      </div>
    </div>
  </ng-container>
</div>
