import { createEntityAdapter } from '@ngrx/entity';
import { OrderSelectItemActionTypes } from '../actions/order-select-item.actions';
const ɵ0 = (productOrderItem) => productOrderItem.articleNo;
export const adapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialOrderSelectItemState = adapter.getInitialState({
    isLoading: false,
    saveSuccess: false,
    criteriaObject: {
        page: 0,
        size: 20
    },
    totalElements: 0,
    totalPages: 0
});
export function OrderSelectItemReducers(state = initialOrderSelectItemState, action) {
    switch (action.type) {
        case OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_REQUEST:
        case OrderSelectItemActionTypes.FIRST_LOT_ORDER_SELECT_ITEM_LIST_REQUEST:
            return Object.assign({}, state, { criteriaObject: action.payload });
        case OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_RESPONSE:
        case OrderSelectItemActionTypes.FIRST_LOT_ORDER_SELECT_ITEM_LIST_RESPONSE:
            return adapter.addAll(action.payload.content, Object.assign({}, state, { totalElements: action.payload.totalElements, totalPages: action.payload.totalPages }));
        case OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_RESET:
        case OrderSelectItemActionTypes.FIRST_LOT_ORDER_SELECT_ITEM_LIST_RESET:
            return adapter.removeAll(Object.assign({}, state, { totalElements: 0, totalPages: 0 }));
        default: {
            return state;
        }
    }
}
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export { ɵ0 };
