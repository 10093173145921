import { createEntityAdapter } from '@ngrx/entity';
import { ReceiveOrderActionType } from '../actions/receive-order.actions';
import { initialCriteriaObject } from '../state/base.state';
const ɵ0 = (payload) => payload.receiveOrderNo;
export const adapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialOrderResponseState = adapter.getInitialState({
    selected: null,
    criteriaObject: initialCriteriaObject,
    totalElements: 0,
    totalPages: 20,
    auditLogs: null,
    shelfList: null,
    shelfDetails: []
});
export function receiveOrderReducers(state = initialOrderResponseState, action) {
    switch (action.type) {
        case ReceiveOrderActionType.RECEIVE_ORDER_LIST_REQUEST:
            return Object.assign({}, state, { criteriaObject: action.payload });
        case ReceiveOrderActionType.RECEIVE_ORDER_LIST_RESPONSE:
            return adapter.addAll(action.payload.content, Object.assign({}, state, { criteriaObject: Object.assign({}, state.criteriaObject, { page: action.payload.page, size: action.payload.size }), totalElements: action.payload.totalElements, totalPages: action.payload.totalPages }));
        case ReceiveOrderActionType.RECEIVE_ORDER_GET_BY_NO_RESPONSE:
            return Object.assign({}, state, { selected: action.payload });
        case ReceiveOrderActionType.RECEIVE_ORDER_GET_BY_NO_RESET:
            return Object.assign({}, state, { selected: null });
        default: {
            return state;
        }
    }
}
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export { ɵ0 };
