<div class="modal-content admin-form">
  <div class="modal-header">
    <h5 class="modal-title pull-left">Import</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" [formGroup]="form">
    <div class="form-row">
      <div class="form-group col-md">
        <label for="transferRequestTemplate">Transfer Request Template</label>
        <div class="d-flex">
          <input
            type="text"
            class="form-control mr-3"
            id="transferRequestTemplate"
            placeholder="Transfer Request Template"
            disabled
          />
          <button type="button" class="btn btn-primary" (click)="downloadTransferRequestTemplate()">
            <em class="icon-download"></em>
            {{ 'REPORTS.DOWNLOAD' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md">
        <label for="importItems">Transfer Items</label>
        <div class="d-flex">
          <app-file-submit-before-upload
            id="importItems"
            formControlName="importItems"
            class="form-control"
            [hasError]="form.controls.importItems.errors"
            [allowedTypes]="'sheet'"
            [withMeta]="true"
            [controlName]="'importItems'"
            [allowedExt]="'xlsx'"
            [fileModule]=""
            (uploadFileContent)="onUpload($event)"
          ></app-file-submit-before-upload>
        </div>
      </div>
    </div>
    <div
      class="form-row"
      *ngIf="
        form.controls.importItems.errors ||
        (form.controls.importItems.value &&
          form.controls.importItems.value[0] &&
          form.controls.importItems.value[0].status)
      "
    >
      <div class="form-group col-md">
        <div
          class="alert-danger form-group mb-0"
          *ngIf="
            form.controls.importItems.errors ||
            (form.controls.importItems.value &&
              form.controls.importItems.value[0].status === stockTransferItemImportStatusEnum.FAILED)
          "
        >
          <div *ngIf="form.controls.importItems.errors?.fileExt">
            Incorrect Format (allow only format file .xlsx).
          </div>
          <div
            *ngIf="
              form.controls.importItems.value &&
              form.controls.importItems.value[0].status === stockTransferItemImportStatusEnum.FAILED
            "
          >
            {{ form.controls.importItems.value[0].errorMessage }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="table-responsive max-height-table"
      *ngIf="
        form.controls.importItems.value &&
        form.controls.importItems.value[0] &&
        form.controls.importItems.value[0]['validateList'] &&
        form.controls.importItems.value[0]['validateList'].length > 0
      "
    >
      <table class="table table-striped table-bordered table-hover w-100">
        <thead>
          <tr class="error-background text-center">
            <th scope="col">Row</th>
            <th scope="col">Column</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of form.controls.importItems.value[0].validateList; index as i">
            <td class="text-center">{{ item.row }}</td>
            <td>{{ item.column }}</td>
            <td>{{ item.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="bsModalRef.hide()" id="btCancel">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="
        form.controls.importItems.errors ||
        !form.controls.importItems.value ||
        form.controls.importItems.value.length === 0 ||
        (form.controls.importItems.value &&
          form.controls.importItems.value[0] &&
          form.controls.importItems.value[0].status === stockTransferItemImportStatusEnum.FAILED)
      "
      id="btnSubmit"
      (click)="fileUpload.uploadFile()"
    >
      OK
    </button>
  </div>
</div>
