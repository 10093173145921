import { LayoutActions, LayoutActionTypes } from '../actions/layout.action';
import { LayoutState } from '../state/layout.state';

export const initialLayoutState = {
  isApiError: false,
  is401Error: false,
  isVersionError: false,
  errorResponse: null,
  saveSuccess: null,
  isUseBackEndMessage: false,
  forceCloseCurrentModal: false,
  forceCloseAfterConfirm: false
};

export function layoutReducers(state = initialLayoutState, action: LayoutActions): LayoutState {
  switch (action.type) {
    case LayoutActionTypes.UPDATE_LAYOUT_ERROR: {
      return {
        ...state,
        isApiError: action.payload
      };
    }
    case LayoutActionTypes.UPDATE_VERSION_ERROR: {
      return {
        ...state,
        isVersionError: action.payload
      };
    }
    case LayoutActionTypes.SAVE_SUCCESS: {
      return {
        ...state,
        saveSuccess: action.payload
      };
    }
    case LayoutActionTypes.LOAD_ERROR: {
      if (action.payload.status === 401) {
        return state;
      } else {
        return {
          ...state,
          errorResponse: action.payload,
          isUseBackEndMessage: action.isUseBackendMessage,
          forceCloseAfterConfirm: action.forceCloseAfterConfirm
        };
      }
    }
    case LayoutActionTypes.FORCE_CLOSE_CURRENT_MODAL: {
      return {
        ...state,
        forceCloseCurrentModal: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
