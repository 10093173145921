import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MasterDataEnum } from '../../../../shared/enum/master-data.enum';
import { NewMasterData } from '../../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../../shared/layouts/modals/alert-modal/alert-modal.component';
import { ErrorResponse } from '../../../../shared/models';
import * as filterDropdown from '../../../../shared/models/list-value/list-key-value.model';
import { StoreAssortmentRequestStoreListSearchCriteria } from '../../../../shared/models/store-assortment-request-store.model';
import { MasterService } from '../../../../shared/services/master.service';
import { StoreAssortmentRequestStoreListRequestAction } from '../../../../shared/store/actions/store-assortment-request-store.actions';
import { selectStoreAssortmentRequestStoreError } from '../../../../shared/store/selectors/store-assortment-request.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-store-assortment-find-store',
  templateUrl: './find-store.component.html',
  styleUrls: ['./find-store.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoreAssortmentFindStoreComponent implements OnInit, OnDestroy {
  private localStore: Observable<any>;
  public criteriaObject: StoreAssortmentRequestStoreListSearchCriteria;
  public poiList: NgOption[];
  public regionList: Array<NewMasterData> | null;
  public searchForm: FormGroup;
  public stateList: Array<NewMasterData> | null;
  public storeTypeList: Array<NewMasterData> | null;
  public keywordList: Array<{ value: string; label: string }> = filterDropdown.keywordList;

  constructor(
    protected readonly store: Store<AppStates>,
    private fb: FormBuilder,
    protected masterService: MasterService,
    private readonly translate: TranslateService,
    protected readonly modalService: BsModalService
  ) {}

  clearAllFilter() {
    this.searchForm.controls['keyword'].reset();
    this.searchForm.controls['searchCriteria'].reset();
    this.searchForm.controls['storeType'].reset();
    this.searchForm.controls['region'].reset();
    this.searchForm.controls['province'].reset();
    this.searchForm.controls['poiType'].reset();

    this.criteriaObject = {
      ...this.criteriaObject,
      keyword: null,
      searchCriteria: null,
      storeType: null,
      region: null,
      province: null,
      poiType: null,
      page: 0
    };

    this.searchForm.controls['searchCriteria'].disable();
    this.findStore();
  }

  clearLastKeyUpSearchText(event) {
    if (!event.target.value) {
      this.criteriaObject = {
        ...this.criteriaObject,
        searchCriteria: null,
        page: 0
      };
    }
  }

  clearSearchText() {
    this.searchForm.controls['searchCriteria'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: null,
      page: 0
    };
  }

  findStore() {
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      keyword: formValue.keyword,
      searchCriteria: formValue.searchCriteria,
      storeType: this.convertArrayFilter(formValue.storeType),
      region: this.convertArrayFilter(formValue.region),
      province: this.convertArrayFilter(formValue.province),
      poiType: this.convertArrayFilter(formValue.poiType),
      page: 0
    };

    this.store.dispatch(new StoreAssortmentRequestStoreListRequestAction(this.criteriaObject));
  }

  initDropdown() {
    this.masterService
      .getMasterDataByNames([MasterDataEnum.MERCHANT])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.storeTypeList = result[MasterDataEnum.MERCHANT];
      });

    this.masterService
      .getMasterDataByNames([MasterDataEnum.REGION, MasterDataEnum.STATE, MasterDataEnum.POI])
      .pipe(untilComponentDestroyed(this))
      .subscribe(result => {
        if (result.data) {
          if (result.data.regions) {
            this.regionList = result.data.regions;
          }
          if (result.data.states) {
            this.stateList = result.data.states;
          }
          if (result.data.poi) {
            this.poiList = result.data.poi;
          }
        }
      });
  }

  initForm() {
    this.searchForm = this.fb.group({
      keyword: [null],
      searchCriteria: [{ value: null, disabled: true }],
      storeType: [null],
      region: [null],
      province: [null],
      poiType: [null]
    });
  }

  ngOnDestroy(): void {}

  ngOnInit() {
    this.initForm();
    this.initDropdown();
    this.setInitialCriteriaObject();

    this.findStore();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(
        select(selectStoreAssortmentRequestStoreError),
        filter(value => Boolean(value))
      )
      .subscribe((error: ErrorResponse) => {
        this.alertErrorModal(error);
      });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    if (errorResponse.translateKey) {
      const initialState = {
        title: 'Failed',
        message: this.translate.instant(errorResponse.translateKey)
      };

      this.modalService.show(AlertModalComponent, {
        initialState
      });
    }
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      page: 0,
      size: 20
    };

    this.store.dispatch(new StoreAssortmentRequestStoreListRequestAction(this.criteriaObject));
  }

  convertArrayFilter(value) {
    return value && value.length > 0 ? value.toString() : null;
  }

  onChangeKeyword() {
    if (this.searchForm.value.keyword && this.searchForm.get('searchCriteria').disabled) {
      this.searchForm.get('searchCriteria').enable();
    }
  }
}
