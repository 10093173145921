import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, fromEventPattern, ReplaySubject } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { environment as env } from '../../../../../environments/environment';
import { OrderSubTypeEnum } from '../../../../shared/enum/ordering-method.enum';
import { UploadStatusEnum } from '../../../../shared/enum/upload-status.enum';
import { OrderInventoryRequestService } from '../../../../shared/services/order-inventory-request.service';
import { OrderStoreUseService } from '../../../../shared/services/order-store-use.service';

export class ICustomFile extends File {
  errors?: { [key: string]: any };
  imgSrc?: string;
  filePath?: string | ArrayBuffer | null;
  id?: number;
  imgHeight?: number;
  imgWidth?: number;
  isImg?: boolean;
  imgLoadReplay?: ReplaySubject<[Event, ProgressEvent]>;
  textContent?: string;
  textLoadReplay?: ReplaySubject<ProgressEvent>;
}
type allowedType = RegExp | string | string[];

@Component({
  selector: 'app-order-file-upload',
  templateUrl: './order-file-upload.component.html',
  styleUrls: ['./order-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrderFileUploadComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OrderFileUploadComponent),
      multi: true
    }
  ]
})
export class OrderFileUploadComponent implements OnChanges, OnInit, OnDestroy, ControlValueAccessor {
  constructor(
    @Optional() @Host() @SkipSelf() private readonly controlContainer: ControlContainer,
    private readonly logger: NGXLogger,
    private readonly orderStoreUseService: OrderStoreUseService,
    private readonly orderInventoryRequestService: OrderInventoryRequestService
  ) {
    this.disabled = false;
    this.hasError = false;
    this.hasChange = false;
    this.ngChange = () => {};
    this.ngTouched = () => {};
  }

  @Input()
  set allowedExt(value: allowedType) {
    if (typeof value === 'string') {
      value = value + '$';
    }
    if (value instanceof Array) {
      value = value.join('|') + '$';
    }
    this._allowedExt = value;
  }

  get allowedExt(): allowedType {
    return this._allowedExt;
  }

  private _allowedExt: allowedType;
  url = '';
  inputValue;
  isLoad = true;
  ngChange;
  ngTouched;
  value;
  fileList: ICustomFile[] = [];
  progress;
  fileService;

  @ViewChild('uploadInput', { static: false }) uploadInput: ElementRef;
  @Output() submitted: EventEmitter<string> = new EventEmitter<string>();
  @Input() disabled: boolean;
  @Input() multiple: boolean;
  @Input() allowedTypes: allowedType;
  @Input() size: number;
  @Input() hasError: boolean;
  @Input() hasChange: boolean;
  @Input() withMeta: boolean;
  @Input() maxHeight: number;
  @Input() maxWidth: number;
  @Input() controlName: string;
  @Input() initialFileList: ICustomFile[];
  @Input() isNew: boolean;
  @Input() fileTypeErrorTxt: string;
  @Input() fileSizeErrorTxt: string;
  @Input() descriptionTxt: string | null;
  @Input() isAddable: boolean;
  @Input() index: number;
  @Input() maxImages: number;
  @Input() lastIndex: number;
  @Input() uploadUrl: string;
  @Input() orderRequestId?: string;
  @Input() isAddSelectiveItem = false;
  @Input() orderSubTypeEnum: OrderSubTypeEnum;

  @HostListener('change', ['$event.target.files']) onChange = (_value: any) => {};
  @HostListener('blur') onTouched = () => {};

  ngOnInit(): void {
    this.fileList = [];
  }

  ngOnDestroy(): void {}

  ngOnChanges(): void {
    this.ngChange(this.value);
  }

  propagateChange: any = () => {};

  writeValue(fileList): void {
    this.value = this.fileList = this.inputValue = fileList;
  }

  registerOnChange(fn: any): void {
    this.onChange = this.onChangeGenerator(fn);
  }

  registerOnTouched(fn: any): void {
    this.ngTouched = fn;
    this.propagateChange = fn;
  }

  private onChangeGenerator(fn: (_: any) => {}): (_: ICustomFile[]) => void {
    if (this.value) {
      this.value.forEach(imgObj => {
        const regexpImageType = new RegExp(env.regexp.imageType, 'ig');
        imgObj.isImg = regexpImageType.test(imgObj.name);
      });
    }
    this.ngChange(this.value);
    this.ngTouched();

    return (customFiles: ICustomFile[]) => {
      const fileArr: File[] = [];
      this.isLoad = false;

      for (const file of customFiles) {
        if (this.withMeta && FileReader) {
          const fileReader = new FileReader();
          this.generateFileMeta(file, fileReader);
        }
        file.errors = {};
        fileArr.push(file);
      }

      fn(fileArr);
    };
  }

  private generateFileMeta(customFile: ICustomFile, fileReader: FileReader) {
    if (customFile.type.match(/text.*/)) {
      customFile.textLoadReplay = this.setText(customFile, fileReader);
    } else if (customFile.type.match(/image.*/)) {
      customFile.imgLoadReplay = this.setImage(customFile, fileReader);
    }
  }

  private setText(customFile: ICustomFile, fileReader: FileReader): ReplaySubject<ProgressEvent> {
    const onloadReplay = new ReplaySubject<ProgressEvent>(1);
    const frLoadObs = fromEventPattern<ProgressEvent>(
      (handler: any) => fileReader.addEventListener('load', handler),
      (handler: any) => fileReader.removeEventListener('load', handler)
    ).pipe(take(1), shareReplay());

    frLoadObs.subscribe(onloadReplay);
    frLoadObs.pipe(take(1)).subscribe(() => {
      customFile.textContent = fileReader.result + '';
    });

    fileReader.readAsText(customFile);

    return onloadReplay;
  }

  private setImage(customFile: ICustomFile, fileReader: FileReader): ReplaySubject<[Event, ProgressEvent]> {
    customFile.isImg = true;

    const img = new Image();

    const imgLoadObs = fromEventPattern<Event>(
      (handler: any) => img.addEventListener('load', handler),
      (handler: any) => img.removeEventListener('load', handler)
    ).pipe(take(1), shareReplay());

    const frLoadObs = fromEventPattern<ProgressEvent>(
      (handler: any) => fileReader.addEventListener('load', handler),
      (handler: any) => fileReader.removeEventListener('load', handler)
    ).pipe(take(1), shareReplay());

    const onloadReplay = new ReplaySubject<[Event, ProgressEvent]>(1);
    const observables = [imgLoadObs, frLoadObs];

    forkJoin(observables)
      .pipe(take(1))
      .subscribe(onloadReplay);

    imgLoadObs.pipe(take(1)).subscribe(() => {
      customFile.imgHeight = img.height;
      customFile.imgWidth = img.width;
    });

    frLoadObs.pipe(take(1)).subscribe(() => {});

    fileReader.readAsDataURL(customFile);

    return onloadReplay;
  }

  private generateRegExp(pattern: allowedType): RegExp | null {
    if (!pattern) {
      return null;
    }

    if (pattern instanceof RegExp) {
      return new RegExp(pattern);
    } else if (typeof pattern === 'string') {
      return new RegExp(pattern, 'ig');
    } else if (pattern instanceof Array) {
      return new RegExp(`(${pattern.join('|')})`, 'ig');
    }

    return null;
  }

  validate(files: AbstractControl) {
    if (!files.value || !files.value.length || files.disabled) {
      return null;
    }
    this.fileList = files.value;
  }

  onClickDelete() {
    this.ngChange(this.value);
    this.fileList = [];
    this.controlContainer.control.get(this.controlName).setValue(null);
  }

  uploadFile() {
    const files = this.controlContainer.control.get(this.controlName);

    if (!files.value || !files.value.length || files.disabled) {
      return null;
    }

    const errors: ValidationErrors = {};
    const loaders: ReplaySubject<ProgressEvent>[] = [];

    for (const file of files.value) {
      if (file.isImg && (this.maxWidth || this.maxHeight)) {
        loaders.push(
          file.imgLoadReplay.pipe(
            take(1),
            map((event: ProgressEvent) => {
              if (this.maxWidth && file.imgWidth > this.maxWidth) {
                file.errors['imageWidth'] = true;
                errors['imageWidth'] = true;
              }
              if (this.maxHeight && file.imgHeight > this.maxHeight) {
                file.errors['imageHeight'] = true;
                errors['imageHeight'] = true;
              }
              return event;
            })
          )
        );
      }

      if (!this.allowedExt && !this.allowedTypes) {
        continue;
      }

      const extP = this.generateRegExp(this.allowedExt);
      const typeP = this.generateRegExp(this.allowedTypes);

      if (extP && !extP.test(file.name)) {
        file.errors['fileExt'] = true;
        errors['fileExt'] = true;
      }

      if (typeP && file.type && !typeP.test(file.type)) {
        file.errors['fileType'] = true;
        errors['fileType'] = true;
      }
    }

    if (this.isLoad) {
      return;
    }

    this.fileList = files.value;
    if (Object.keys(errors).length) {
      this.controlContainer.control.get(this.controlName).setErrors(errors);
      return;
    }

    if (this.orderSubTypeEnum === OrderSubTypeEnum.INVENTORY) {
      if (this.isAddSelectiveItem) {
        this.callOrderSelectiveItemService(files);
      } else {
        this.callOrderInventoryRequestService(files);
      }
    }

    if (this.orderSubTypeEnum === OrderSubTypeEnum.FIX_ASSET_AND_STORE_USE) {
      this.callOrderStoreUseService(files);
    }
  }

  public callOrderInventoryRequestService(files: AbstractControl) {
    this.orderInventoryRequestService
      .uploadOrderSelectiveItemUpdateQtyFiles(files.value, this.uploadUrl, this.orderRequestId)
      .forEach((allRes, index: number) =>
        allRes
          .pipe(
            filter(Boolean),
            filter(res => res['status'])
          )
          .subscribe(
            res => {
              if (!this.fileList[index].filePath) {
                const reader = new FileReader();

                reader.readAsDataURL(files.value[index]);
                reader.onload = () => (this.fileList[index].filePath = reader.result);
              }

              if (res['status'] === 'progress') {
                return JSON.stringify(res);
              } else if (res['importValidations'] && res['importValidations'].length) {
                files.value[index].status = UploadStatusEnum.FAILED;
                files.value[index].validations = res['importValidations'];
                files.markAsTouched();
              } else if (res['error'] && res['error'].code && res['error'].message) {
                files.value[index].status = UploadStatusEnum.FAILED;
                files.value[index].errorMessage = `${res['error'].message}`;
              } else {
                files.value[index].status = UploadStatusEnum.COMPLETED;
                files.value[index].orderRequestInventory = res['orderRequestInventory']
                  ? res['orderRequestInventory']
                  : null;
                files.value[index].noOfItem = res['orderRequestInventory'] ? res['noOfItem'] : 0;
                this.submitted.emit();
              }
            },
            err => err
          )
      );
  }

  public callOrderStoreUseService(files: AbstractControl) {
    this.orderStoreUseService.uploadOrderStoreUseFiles(files.value, this.uploadUrl).forEach((allRes, index: number) =>
      allRes
        .pipe(
          filter(Boolean),
          filter(res => res['status'])
        )
        .subscribe(
          res => {
            if (!this.fileList[index].filePath) {
              const reader = new FileReader();

              reader.readAsDataURL(files.value[index]);
              reader.onload = () => (this.fileList[index].filePath = reader.result);
            }

            if (res['status'] === 'progress') {
              return JSON.stringify(res);
            } else if (res['importValidations'] && res['importValidations'].length) {
              files.value[index].status = UploadStatusEnum.FAILED;
              files.value[index].validations = res['importValidations'];
              files.markAsTouched();
            } else if (res['error'] && res['error'].code && res['error'].message) {
              files.value[index].status = UploadStatusEnum.FAILED;
              files.value[index].errorMessage = `${res['error'].message}`;
            } else {
              files.value[index].status = UploadStatusEnum.COMPLETED;
              files.value[index].tdCatalogs = res['tdCatalogs'] ? res['tdCatalogs'] : null;
              files.value[index].noOfItem = res['tdCatalogs'] ? res['noOfItem'] : 0;
              this.submitted.emit();
            }
          },
          err => err
        )
    );
  }

  public callOrderSelectiveItemService(files: AbstractControl) {
    this.orderInventoryRequestService
      .uploadOrderSelectiveItemFiles(files.value, this.uploadUrl)
      .forEach((allRes, index: number) =>
        allRes
          .pipe(
            filter(Boolean),
            filter(res => res['status'])
          )
          .subscribe(
            res => {
              if (!this.fileList[index].filePath) {
                const reader = new FileReader();

                reader.readAsDataURL(files.value[index]);
                reader.onload = () => (this.fileList[index].filePath = reader.result);
              }

              if (res['status'] === 'progress') {
                return JSON.stringify(res);
              } else if (res['importValidations'] && res['importValidations'].length) {
                files.value[index].status = UploadStatusEnum.FAILED;
                files.value[index].validations = res['importValidations'];
                files.markAsTouched();
              } else if (res['error'] && res['error'].code && res['error'].message) {
                files.value[index].status = UploadStatusEnum.FAILED;
                files.value[index].errorMessage = `${res['error'].message}`;
              } else {
                files.value[index].status = UploadStatusEnum.COMPLETED;
                files.value[index].tdCatalogs = res['items'] ? res['items'] : null;
                files.value[index].noOfItem = res['items'] ? res['noOfItem'] : 0;
                this.submitted.emit();
              }
            },
            err => err
          )
      );
  }
}
