import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  StockTransfer,
  StockTransferRequestErrorResponse,
  StockTransferRequestList,
  StockTransferRequestListSearchCriteria
} from '../../models/stock-transfer-request.model';
import {
  StockTransferRequestActions,
  StockTransferRequestActionType
} from '../actions/stock-transfer-request.actions';
import { BaseState } from '../state/base.state';

export interface StockTransferRequestState extends EntityState<StockTransferRequestList>, BaseState {
  criteriaObject: StockTransferRequestListSearchCriteria;
  totalElements: number;
  totalPages: number;
  stockTransfer: StockTransfer;
  stockTransferErrorResponse: StockTransferRequestErrorResponse;
}

export const adapter: EntityAdapter<StockTransferRequestList> = createEntityAdapter<StockTransferRequestList>();

export const initialStockTransferRequestResponseState: StockTransferRequestState = adapter.getInitialState({
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  stockTransfer: null,
  stockTransferErrorResponse: null
});

export function stockTransferRequestReducers(
  state = initialStockTransferRequestResponseState,
  action: StockTransferRequestActions
): StockTransferRequestState {
  switch (action.type) {
    case StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_GET_BY_ID_RESPONSE:
      return {
        ...state,
        stockTransfer: action.payload
      };
    case StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_RESET:
      return {
        ...state,
        stockTransfer: initialStockTransferRequestResponseState.stockTransfer
      };
    case StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_ERROR_RESPONSE:
      return {
        ...state,
        stockTransferErrorResponse: action.payload
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
