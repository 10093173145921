<div class="modal-content admin-form">
  <div class="modal-header">
    <h6 class="modal-title pull-left">Delivery Details</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()" id="closeBtn">
      <em class="icon-close"></em>
    </button>
  </div>
  <div class="modal-body" [formGroup]="tmpForm">
    <div class="row">
      <div class="col-md-6 mb-3">
        <div class="form-group">
          <label for="requestDeliveryDate">Request Delivery Date<span class="text-danger">*</span></label>
          <input
            id="requestDeliveryDate"
            type="text"
            placeholder="{{ dateFormat }}"
            class="form-control calendar"
            autocomplete="off"
            (bsValueChange)="onChangeDate($event)"
            [ngClass]="{
              'is-invalid':
                (isSubmit || updated) &&
                (tmpForm.controls.requestDeliveryDate.errors ||
                  parentFormItemIndex.controls.requestDeliveryDate.errors?.invalidDate)
            }"
            bsDatepicker
            [bsConfig]="bsConfig"
            formControlName="requestDeliveryDate"
          />
          <label for="requestDeliveryDate" class="icon-calendar btn-calendar"></label>
          <div
            *ngIf="
              (isSubmit || updated) &&
              (tmpForm.controls.requestDeliveryDate.errors || parentFormItemIndex.controls.requestDeliveryDate.errors)
            "
            class="invalid-feedback"
          >
            <div *ngIf="(isSubmit || updated) && tmpForm.controls.requestDeliveryDate.errors?.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div
              *ngIf="
                tmpForm.controls.requestDeliveryDate.errors?.invalidDate ||
                parentFormItemIndex.controls.requestDeliveryDate.errors?.invalidDate
              "
            >
              Invalid Date
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3">
        <div class="form-group">
          <label for="deliveryNote">Delivery Note to Supplier</label>
          <textarea
            rows="4"
            cols="50"
            class="form-control h-100"
            placeholder="Enter Note"
            maxlength="100"
            id="deliveryNote"
            formControlName="deliveryNote"
          >
          </textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <div class="form-group">
          <h6 for="shipToName" class="input-label">Shipping Details</h6>
          <hr />
          <div class="form-group">
            <label for="shipToName" class="input-label">Ship To</label><span class="text-danger">*</span>
            <ng-select
              id="shipToName"
              bindLabel="fullNameTh"
              formControlName="shipToName"
              placeholder="Please select..."
              dropdownPosition="auto"
              [loading]="shipToSearchLoading"
              [typeahead]="shipToSearchInput$"
              [items]="shipToList | async"
              [virtualScroll]="true"
              [searchable]="false"
              [clearable]="false"
              [ngClass]="{ 'is-invalid': (isSubmit || updated) && tmpForm.controls.shipToName.errors }"
            >
            </ng-select>
            <div
              *ngIf="(isSubmit || updated) && tmpForm.controls.shipToName.errors?.required"
              class="invalid-display"
            >
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>

          <textarea
            rows="4"
            cols="50"
            class="form-control h-100"
            maxlength="1000"
            id="shipToDetail"
            formControlName="shipToDetail"
            readonly
          >
          </textarea>
        </div>
      </div>

      <div class="col-md-6 mb-3">
        <div class="form-group">
          <h6 for="billToName">Billing Details</h6>
          <hr />

          <div class="form-group">
            <label for="billToName">Bill To</label><span class="text-danger">*</span>
            <ng-select
              id="billToName"
              bindLabel="nameEn"
              formControlName="billToName"
              placeholder="Please select..."
              dropdownPosition="auto"
              [items]="billToList"
              [virtualScroll]="true"
              [searchable]="false"
              [clearable]="false"
              (change)="onChangeBillToName($event)"
              [ngClass]="{ 'is-invalid': (isSubmit || updated) && tmpForm.controls.billToName.errors }"
            >
            </ng-select>
            <div
              *ngIf="(isSubmit || updated) && tmpForm.controls.billToName.errors?.required"
              class="invalid-display"
            >
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
          <textarea
            rows="4"
            cols="50"
            class="form-control h-100"
            maxlength="1000"
            id="billToDetail"
            formControlName="billToDetail"
            readonly
          >
          </textarea>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 mb-3">
        <div class="form-group">
          <label>Shipping Contact</label><span class="text-danger">*</span>
          <input
            type="text"
            class="form-control"
            maxlength="50"
            placeholder="Contact Name"
            id="shipToContactName"
            formControlName="shipToContactName"
            [ngClass]="{
              'is-invalid': (isSubmit || updated) && tmpForm.controls.shipToContactName.errors
            }"
          />
          <div
            *ngIf="(isSubmit || updated) && tmpForm.controls.shipToContactName.errors?.required"
            class="invalid-display"
          >
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            maxlength="25"
            placeholder="Contact Number"
            id="shipToContactNumber"
            formControlName="shipToContactNumber"
            [ngClass]="{
              'is-invalid': (isSubmit || updated) && tmpForm.controls.shipToContactNumber.errors
            }"
          />
          <div
            *ngIf="(isSubmit || updated) && tmpForm.controls.shipToContactNumber.errors?.required"
            class="invalid-display"
          >
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3">
        <div class="form-group">
          <label>Billing Contact</label><span class="text-danger">*</span>
          <input
            type="text"
            class="form-control"
            maxlength="50"
            placeholder="Contact Name"
            id="billToContactName"
            formControlName="billToContactName"
            [ngClass]="{
              'is-invalid': (isSubmit || updated) && tmpForm.controls.billToContactName.errors
            }"
          />
          <div
            *ngIf="(isSubmit || updated) && tmpForm.controls.billToContactName.errors?.required"
            class="invalid-display"
          >
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            maxlength="25"
            placeholder="Contact Number"
            id="billToContactNumber"
            formControlName="billToContactNumber"
            [ngClass]="{
              'is-invalid': (isSubmit || updated) && tmpForm.controls.billToContactNumber.errors
            }"
          />
          <div
            *ngIf="(isSubmit || updated) && tmpForm.controls.billToContactNumber.errors?.required"
            class="invalid-display"
          >
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" *ngIf="!isViewMode">
    <div>
      <input class="mr-2" type="checkbox" [checked]="applyAll" [disabled]="isTypeZ9" (change)="onChangeApplyAll()" />
      <span>Apply to all items</span>
    </div>
    <div>
      <button type="button" class="btn btn-standard mr-2" (click)="decline()" id="cancelBtn">
        Cancel
      </button>
      <button type="button" class="btn btn-primary" (click)="confirm()" appPreventDoubleClick id="updateBtn">
        Update
      </button>
    </div>
  </div>
</div>
