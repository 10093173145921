import { Component, ContentChild, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-validators-message',
  templateUrl: './validators-message.component.html',
  styleUrls: ['./validators-message.component.scss']
})
export class ValidatorsMessageComponent implements OnInit, OnDestroy {
  isSubmitted: boolean;
  subscription = new Subscription();
  @Input() set submitted(isSubmit: boolean) {
    this.isSubmitted = isSubmit;
    if (isSubmit && this.contentChild.invalid) {
      this.addClass();
    } else if (!isSubmit) {
      this.removeClass();
    }
  }

  @ContentChild(NgControl, { static: true }) contentChild: NgControl;
  @ContentChild(NgControl, { static: true, read: ElementRef }) elementRef: ElementRef<HTMLElement>;

  constructor(private renderer2: Renderer2) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {}

  ngAfterContentInit() {
    const contentChild = this.contentChild.statusChanges.pipe(
      tap(status => {
        if (this.isSubmitted && status === 'VALID') {
          this.removeClass();
        } else if (this.isSubmitted && status === 'INVALID') {
          this.addClass();
        }
      })
    );

    this.subscription.add(contentChild.subscribe());
  }

  addClass() {
    this.renderer2.addClass(this.elementRef.nativeElement, 'is-invalid');
  }

  removeClass() {
    this.renderer2.removeClass(this.elementRef.nativeElement, 'is-invalid');
  }
}
