import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { StockTransferRequestStatusEnum } from '../../enum/stock-transfer-request.enum';
import { StockTransferRequestService } from '../../services/stock-trasfer-request-service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import {
  StockTransferCancelRequestAction,
  StockTransferDeleteRequestAction,
  StockTransferRequestActionType,
  StockTransferRequestApproveRequest,
  StockTransferRequestByIdRequestAction,
  StockTransferRequestByIdResponseAction,
  StockTransferRequestErrorResponseAction,
  StockTransferRequestListRequestAction,
  StockTransferRequestListResponseAction,
  StockTransferRequestRejectRequest,
  StockTransferRequestSaveRequestAction,
  StockTransferRequestSubmitRequestAction
} from '../actions/stock-transfer-request.actions';

@Injectable()
export class StockTransferRequestEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly stockTransferRequestService: StockTransferRequestService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchStockTransferRequestList$ = this.actions$.pipe(
    ofType<StockTransferRequestListRequestAction>(StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_LIST_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action;
    }),
    switchMap(action => {
      return this.stockTransferRequestService.searchByCriteria(action.payload).pipe(
        map(response => {
          return new StockTransferRequestListResponseAction(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  saveStockRequest = this.actions$.pipe(
    ofType<StockTransferRequestSaveRequestAction>(StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_SAVE_REQUEST),
    tap(action =>
      this.logger.debug(
        `@Effect ${StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_SAVE_REQUEST}: ` +
          JSON.stringify(action.payload)
      )
    ),
    switchMap(action =>
      this.stockTransferRequestService.save(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been saved.'
            })
        ),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  getStockTransferById$ = this.actions$.pipe(
    ofType<StockTransferRequestByIdRequestAction>(
      StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_GET_BY_ID_REQUEST
    ),
    map(action => {
      this.logger.debug(
        `@Effect ${StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_GET_BY_ID_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.stockTransferRequestService.getStockTransferRequestById(payload).pipe(
        map(res => {
          return new StockTransferRequestByIdResponseAction(res);
        }),

        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  deleteStockTransferRequest$ = this.actions$.pipe(
    ofType<StockTransferDeleteRequestAction>(StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_DELETE_REQUEST),
    tap(action =>
      this.logger.debug(
        `@Effect ${StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_DELETE_REQUEST}: ` +
          JSON.stringify(action.payload)
      )
    ),
    switchMap(action =>
      this.stockTransferRequestService.deleteStockTransferRequest(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been deleted.'
            })
        ),
        catchError(error => {
          console.log(error);
          return error.error && error.error.code === '00004'
            ? of(new LayoutActionVersionError(true))
            : of(new LayoutActionLoadError(error));
        })
      )
    )
  );

  @Effect()
  cancelStockTransferRequest$ = this.actions$.pipe(
    ofType<StockTransferCancelRequestAction>(StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_CANCEL_REQUEST),
    tap(action =>
      this.logger.debug(
        `@Effect ${StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_CANCEL_REQUEST}: ` +
          JSON.stringify(action.payload)
      )
    ),
    switchMap(action =>
      this.stockTransferRequestService.cancelStockTransferRequest(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been cancelled.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  approveStockTransferRequest$ = this.actions$.pipe(
    ofType<StockTransferRequestApproveRequest>(StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_APPROVE_REQUEST),
    tap(action => this.logger.debug('@Effect TransferRequest Approve Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.stockTransferRequestService.approve(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been approved.'
            })
        ),
        catchError(err => {
          return this.errorHandling(err);
        })
      )
    )
  );

  @Effect()
  rejectStockTransferRequest$ = this.actions$.pipe(
    ofType<StockTransferRequestRejectRequest>(StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_REJECT_REQUEST),
    tap(action => this.logger.debug('@Effect TransferRequest Reject Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.stockTransferRequestService.reject(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been rejected.'
            })
        ),
        catchError(err => {
          return this.errorHandling(err);
        })
      )
    )
  );

  @Effect()
  submitPrRequest$ = this.actions$.pipe(
    ofType<StockTransferRequestSubmitRequestAction>(
      StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_SUBMIT_REQUEST
    ),
    tap(action =>
      this.logger.debug(
        `${StockTransferRequestActionType.STOCK_TRANSFER_REQUEST_SUBMIT_REQUEST}: ` + JSON.stringify(action.payload)
      )
    ),
    switchMap(action =>
      this.stockTransferRequestService.submit(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message:
                action.payload.status === StockTransferRequestStatusEnum.AWAITING_APPROVAL
                  ? 'The request has been sent to approver.'
                  : 'The request has been created.'
            })
        ),
        catchError(err => {
          return err && err.error && !err.error.code
            ? of(new StockTransferRequestErrorResponseAction(err.error))
            : err && err.error && err.error.code === '00004'
            ? of(new LayoutActionVersionError(true))
            : of(new LayoutActionLoadError(err));
        })
      )
    )
  );

  errorHandling(err: any) {
    return err.error && err.error.code === '00005'
      ? of(new LayoutActionVersionError(true))
      : of(new LayoutActionLoadError(err));
  }

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
