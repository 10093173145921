import { Component, Host, Input } from '@angular/core';
import { CheckboxGroupComponent } from './checkbox-group.component';

@Component({
  selector: 'app-checkbox',
  template: `
    <div (click)="toggleCheck()">
      <input type="checkbox" [checked]="isChecked()" class="form-check-input-label checkbox-modal" />
      <ng-content></ng-content>
    </div>
  `
})
export class CheckboxComponent {
  @Input() value: any;

  constructor(@Host() private checkboxGroup: CheckboxGroupComponent) {}

  toggleCheck() {
    this.checkboxGroup.addOrRemove(this.value);
  }

  isChecked() {
    return this.checkboxGroup.contains(this.value);
  }
}
