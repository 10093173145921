import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { DeliveryByTypeEnum } from '../../../shared/enum/delivery-by-type.enum';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { NewMasterData } from '../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { ErrorResponse } from '../../../shared/models';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import {
  OrderContent,
  OrderExportCriteria,
  OrderPrintCriteria,
  OrderSearchCriteria
} from '../../../shared/models/order.model';
import { WarehouseListContent } from '../../../shared/models/warehouse.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { OrderService } from '../../../shared/services/order.service';
import { OrderListSearchRequested } from '../../../shared/store/actions/order.actions';
import { WarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { OrderState } from '../../../shared/store/reducers/order.reducers';
import { selectOrder, selectOrderList } from '../../../shared/store/selectors/order.selectors';
import { selectAllWarehouse } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  formatDate,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { OrderViewComponent } from '../order-view/order-view.component';

enum OrderColorStatusEnum {
  NEW_ORDER = 'new',
  PROCESSING = 'processing',
  PARTIAL_DELIVERED = 'partial',
  AWAITING_ALLOCATION = 'awaiting-allocation',
  DELIVERED = 'deliver',
  CANCELED = 'cancel',
  CANCELED_BY_SUPPLIER = 'cancel',
  REJECTED_BY_ERP = 'cancel',
  CLOSED = 'closed'
}

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent extends BaseSearchComponent<OrderSearchCriteria, OrderContent, OrderState> {
  @ViewChild('exportModal', { static: false }) exportModal: ModalDirective;

  private localStore: Observable<any>;
  dateFormat = environment.dateFormat;
  storeTypeTag: string;
  storeTypeStringTag = 'Store Type';
  orderTypeTag: string;
  orderTypeStringTag = 'Order Type';
  deliveryTag: string;
  deliveryStringTag = 'Delivery By';
  orderDateTag: string;
  orderDateStringTag: string;
  deliveryDateTag: string;
  deliveryDateStringTag: string;
  warehouseTag: string;
  warehouseStringTag: string;
  minDeliveryDate: Date;
  maxDeliveryDate: Date;

  public exportForm: FormGroup;
  orderMinDate: Date;
  orderMaxDate: Date;
  errorExport: string;
  submittedExport: boolean;

  bsModalRef: BsModalRef;

  public orderStatusList: Array<{ value: string; label: string }> = filterDropdown.orderStatusFilter;
  public exportOrderStatusList: Array<{ value: string; label: string }> = filterDropdown.orderStatusFilter.filter(
    status => status.label !== 'All Status'
  );
  public orderDeliveryList: Array<{ value: string; label: string }> = filterDropdown.orderDeliveryFilter;
  public orderTypeList: Array<{ value: string; label: string }> = filterDropdown.orderingMethodFilter;
  public storeTypeList: Array<NewMasterData> | null;
  public warehouseList: WarehouseListContent[];

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    }
  ];

  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected orderService: OrderService,
    protected masterService: MasterService,
    private readonly translate: TranslateService,
    protected authGuardService: AuthGuardService
  ) {
    super(store, modalService, selectOrderList, selectOrder);
  }

  doInit() {
    this.createExportForm();

    this.masterService
      .getMasterDataByNames([MasterDataEnum.MERCHANT])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.storeTypeList = result[MasterDataEnum.MERCHANT];
      });

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.store.dispatch(new WarehouseListRequestAction('-'));
    this.localStore.pipe(select(selectAllWarehouse('Warehouse'))).subscribe(data => {
      this.warehouseList = data;
    });

    this.minDeliveryDate = new Date(2019, 0, 1);
    this.maxDeliveryDate = new Date();
    this.maxDeliveryDate.setDate(this.maxDeliveryDate.getDate() + 365);
    this.orderMaxDate = new Date();
    this.orderMaxDate.setDate(this.orderMaxDate.getDate() + 365);
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      orderStatus: [this.orderStatusList[0].value],
      storeType: [null],
      orderType: [null],
      deliveryBy: [null],
      orderDateFrom: [null],
      orderDateTo: [null],
      deliveryDateFrom: [null],
      deliveryDateTo: [null],
      warehouseCode: [null]
    });
  }

  createExportForm() {
    this.exportForm = this.fb.group({
      orderStatus: [null],
      orderDateFrom: [null],
      orderDateTo: [null],
      orderType: [null]
    });
  }

  onChangeOrderStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      orderStatus: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeDeliveryDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDeliveryDate = new Date(value);
    } else {
      this.minDeliveryDate = new Date(2019, 0, 1);
    }
  }

  onChangeDeliveryDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDeliveryDate = new Date(value);
    } else {
      this.maxDeliveryDate = new Date();
      this.maxDeliveryDate.setDate(this.maxDeliveryDate.getDate() + 365);
    }
  }

  onChangeOrderDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.orderMinDate = new Date(value);
      this.orderMaxDate = new Date(value);
      this.orderMaxDate.setDate(this.orderMaxDate.getDate() + 90);
    } else {
      this.orderMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeOrderDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.orderMaxDate = new Date(value);
      this.orderMinDate = new Date(value);
      this.orderMinDate.setDate(this.orderMinDate.getDate() - 90);
    } else {
      this.orderMaxDate = new Date();
      this.orderMaxDate.setDate(this.orderMaxDate.getDate() + 365);
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (
      !formValue.orderDateFrom &&
      !formValue.orderDateTo &&
      !formValue.deliveryDateFrom &&
      !formValue.deliveryDateTo &&
      !formValue.storeType &&
      !formValue.orderType &&
      !formValue.deliveryBy &&
      !formValue.warehouseCode
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      orderDateFrom: dateToStringCriteria(formValue.orderDateFrom),
      orderDateTo: dateToStringCriteria(formValue.orderDateTo, false),
      deliveryDateFrom: formatDate(formValue.deliveryDateFrom, this.dateFormat),
      deliveryDateTo: formatDate(formValue.deliveryDateTo, this.dateFormat),
      storeType: this.convertArrayFilter(formValue.storeType),
      orderType: this.convertArrayFilter(formValue.orderType),
      deliveryBy: this.convertArrayFilter(formValue.deliveryBy),
      warehouseCode:
        formValue.warehouseCode && formValue.warehouseCode.length > 0 ? formValue.warehouseCode.toString() : null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.storeTypeTag = null;
    this.orderTypeTag = null;
    this.deliveryTag = null;
    this.orderDateTag = null;
    this.orderDateStringTag = null;
    this.deliveryDateTag = null;
    this.deliveryDateStringTag = null;
    this.warehouseTag = null;
    this.warehouseStringTag = null;

    const orderDateFrom = dateStringToTagCriteria(this.criteriaObject.orderDateFrom);
    const orderDateTo = dateStringToTagCriteria(this.criteriaObject.orderDateTo);
    const orderDate = generateDateStringTag({
      dateName: 'Order Date',
      dateFrom: orderDateFrom,
      dateTo: orderDateTo
    });

    this.orderDateStringTag = orderDate.dateStringTag;
    this.orderDateTag = orderDate.dateTag;

    const deliveryDateFrom = dateStringToTagCriteria(this.criteriaObject.deliveryDateFrom);
    const deliveryDateTo = dateStringToTagCriteria(this.criteriaObject.deliveryDateTo);
    const deliveryDate = generateDateStringTag({
      dateName: 'Delivery Date',
      dateFrom: deliveryDateFrom,
      dateTo: deliveryDateTo
    });

    this.deliveryDateStringTag = deliveryDate.dateStringTag;
    this.deliveryDateTag = deliveryDate.dateTag;

    if (this.criteriaObject.storeType && this.criteriaObject.storeType.length) {
      const storeTypes = this.storeTypeList
        .filter(data => this.criteriaObject.storeType.includes(data.code))
        .map(data => data.nameTh)
        .join(', ');
      this.storeTypeTag = `"${storeTypes}"`;
    }

    if (this.criteriaObject.orderType && this.criteriaObject.orderType.length > 0) {
      const type = this.orderTypeList
        .filter(data => {
          return this.criteriaObject.orderType.indexOf(data.value) > -1;
        })
        .map(data => data.label);

      const orderType = type.join(', ');
      this.orderTypeTag = `"${orderType}"`;
    }

    if (this.criteriaObject.deliveryBy && this.criteriaObject.deliveryBy.length > 0) {
      const delivery = this.orderDeliveryList
        .filter(data => {
          return this.criteriaObject.deliveryBy.indexOf(data.value as DeliveryByTypeEnum) > -1;
        })
        .map(data => data.label);

      const deliveryBy = delivery.join(', ');
      this.deliveryTag = `"${deliveryBy}"`;
    }

    if (this.criteriaObject.warehouseCode && this.criteriaObject.warehouseCode.length) {
      this.warehouseStringTag = 'Warehouse';
      const types = this.warehouseList
        .filter(data => this.criteriaObject.warehouseCode.includes(data.code))
        .map(data => data.warehouseNameDisplay)
        .join(', ');
      this.warehouseTag = `"${types}"`;
    }
  }

  clearFilterOrderDate() {
    this.setFirstPage();
    this.searchForm.controls['orderDateFrom'].reset();
    this.searchForm.controls['orderDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      orderDateFrom: null,
      orderDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterDeliveryDate() {
    this.setFirstPage();
    this.searchForm.controls['deliveryDateFrom'].reset();
    this.searchForm.controls['deliveryDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      deliveryDateFrom: null,
      deliveryDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterOrderType() {
    this.setFirstPage();
    this.searchForm.controls['orderType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      orderType: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterStoreType() {
    this.setFirstPage();
    this.searchForm.controls['storeType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      storeType: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterDeliveryBy() {
    this.setFirstPage();
    this.searchForm.controls['deliveryBy'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      deliveryBy: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterWarehouse() {
    this.setFirstPage();
    this.searchForm.controls['warehouseCode'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      warehouseCode: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['orderDateFrom'].reset();
    this.searchForm.controls['orderDateTo'].reset();
    this.searchForm.controls['deliveryDateFrom'].reset();
    this.searchForm.controls['deliveryDateTo'].reset();
    this.searchForm.controls['storeType'].reset();
    this.searchForm.controls['orderType'].reset();
    this.searchForm.controls['deliveryBy'].reset();
    this.searchForm.controls['warehouseCode'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      orderDateFrom: null,
      orderDateTo: null,
      deliveryDateFrom: null,
      deliveryDateTo: null,
      storeType: null,
      orderType: null,
      deliveryBy: null,
      warehouseCode: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  setRouteTab() {
    const hasOrderListPagePermission = this.authGuardService.checkPermission([
      'so_v',
      'or_fl_ast_m',
      'or_st_equip_m',
      'or_fl_inv_m',
      'or_fl_qty_inv_m'
    ]);
    const hasOrderRequestListPagePermission = this.authGuardService.checkPermission([
      'or_v',
      'so_firstlot_m',
      'so_special_m',
      'so_app',
      'or_fl_ast_m',
      'or_fl_ast_app',
      'or_st_equip_m',
      'or_fl_inv_m',
      'or_fl_inv_app',
      'or_fl_qty_inv_m'
    ]);

    this.listRoute = [];

    if (hasOrderListPagePermission) {
      this.listRoute.push({
        tabName: 'ORDER.ORDER_LIST',
        url: '/order/order-list'
      });
    }

    if (hasOrderRequestListPagePermission) {
      this.listRoute.push({
        tabName: 'ORDER_REQUEST.ORDER_REQUEST',
        url: '/order/order-request-list'
      });
    }
  }

  search(criteriaObj) {
    this.store.dispatch(new OrderListSearchRequested(criteriaObj));
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  goToView(order: OrderContent) {
    const title = 'View Order';
    const orderNo = order.orderNo;
    const auditLogs = order.auditLogs;
    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState: {
        childItem: new ChildItem(
          OrderViewComponent,
          {
            orderNo,
            title,
            auditLogs
          },
          false
        )
      }
    });
  }

  onPrintPdf(order: OrderContent) {
    const param: OrderPrintCriteria = {
      id: order.id,
      format: 'pdf',
      locale: 'th'
    };

    this.orderService.printPdfOrder(param).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
        saveAs(blob, this.getGeneratedFileName(order.orderNo));
      },
      error => {
        this.alertErrorModal(error.error);
      }
    );
  }

  getGeneratedFileName(orderNo: string) {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.downloadPdf.timeFormat);
    const formattedName = orderNo ? orderNo.replace('/', '_') : '';
    return `${formattedName}_${formattedDate}`;
  }

  showHistory(order: OrderContent) {
    const initialState = {
      title: 'History',
      historyHeader: `Order No.: ${order.orderNo}`,
      auditLogs: order.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    if (errorResponse.translateKey) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };

      if (errorResponse.code !== '00001') {
        initialState.message = this.translate.instant(errorResponse.translateKey);
        this.modalService.show(AlertModalComponent, {
          initialState
        });
      }
    }
  }

  isCanPrintPDF(status: string) {
    return ['NEW_ORDER', 'PROCESSING', 'PARTIAL_DELIVERED', 'CLOSED', 'DELIVERED'].includes(status);
  }

  getColorStatus(status: string) {
    return OrderColorStatusEnum[status];
  }

  convertArrayFilter(value) {
    return value && value.length > 0 ? value.toString() : null;
  }

  onExport() {
    this.submittedExport = true;

    if (this.exportFormInvalid) {
      return;
    }

    const fileConfig = environment.fileName.exportOrder;
    const dateExport = moment().format(fileConfig.timeFormat);
    const formData = this.exportForm.getRawValue();
    const request = this.prepareExportRequestData(formData);

    this.orderService.exportOrder(request).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${fileConfig.prefix} ${dateExport}.xlsx`);
      },
      error => {
        this.errorExport = error.error.message;
      },
      () => this.exportModal.hide()
    );
  }

  onOpenExportModal() {
    this.submittedExport = false;
    this.exportForm.reset();
    this.errorExport = null;
    this.exportModal.show();
  }

  prepareExportRequestData(formData): OrderExportCriteria {
    const orderDateFrom = dateToStringCriteria(formData.orderDateFrom);
    const orderDateTo = dateToStringCriteria(formData.orderDateTo, false);

    return new OrderExportCriteria({
      ...(orderDateFrom && { orderDateFrom }),
      ...(orderDateTo && { orderDateTo }),
      orderStatus: this.convertArrayFilter(formData.orderStatus),
      orderType: this.convertArrayFilter(formData.orderType)
    });
  }

  get exportFormInvalid() {
    const formValues = this.exportForm.value;
    return !formValues.orderDateFrom && !formValues.orderDateTo;
  }

  doAfterVersionAlertModal() {}
}
