import { ProductTypeEnum } from '../enum/product-type.enum';
import { ShipToType } from '../enum/purchase-order.enum';
import { PurchaseRequestStatusEnum, PurchaseRequestTypeEnum } from '../enum/purchase-request.enum';
import { AuditLog } from './audit-log.model';
import { BaseReport } from './base-report.model';
import { Warehouse } from './request-assortment.model';

export class PurchaseRequest {
  id: string;
  type: PurchaseRequestTypeEnum;
  status: PurchaseRequestStatusEnum;
  advance?: boolean;
  articleType?: string;
  productType?: ProductTypeEnum;
  docNo?: string;
  orderRefNo?: string;
  docDate?: string;
  orderSchedule?: number;
  orderScheduleDate?: string;
  orderScheduleDayOfWeek: number;
  orderScheduleDayOfWeekDisplay?: string;
  requestBuyItems: number;
  requestFreeItems: number;
  requester?: string;
  requesterName: string;
  requestedDate: Date;
  shipToCode: string;
  shipToName: string;
  wmsCode: string;
  shipToType: ShipToType;
  shipToCodeName: string;
  lastModifiedBy?: string;
  lastModifiedByName?: string;
  totalAmount: number;
  vatAmount?: number;
  subTotalAmount?: number;
  items: PurchaseRequestItem[];
  version: number;
  productChanges: ProductChange[];
}

export interface PurchaseRequestItem {
  id: string;
  itemNo: number;
  productName: string;
  articleNo: string;
  barcode: string;
  supplierName: string;
  supplierCode: string;
  orderUnit: string;
  minimumOrder: number;
  unitPrice: number;
  quantity: number;
  subTotalAmount: number;
  totalAmount: number;
  selected?: boolean;
  vatAmount?: number;
  totalVatAmount?: number;
  vatPct?: number;
  isAddItem?: boolean;
  newUnitPrice?: number;
  productChanges?: ProductChange[];
  isFreeItem?: boolean;
  freeItems?: FreeItems[];
  deliveryDetail?: PurchaseRequestDeliveryDetail;
  isReject?: boolean;
  status?: string;
  segmentCode?: string;
  familyCode?: string;
  classCode?: string;
  subClassCode?: string;
  lotSize?: number;
  supplierVat?: boolean;
  productVat?: boolean;
  supplierArticleNo?: string;
  warehouse?: Warehouse;
  vendorDcCode?: string;
  vendorDcName?: string;
  supplierBranchNo?: string;
}

export class PurchaseRequestDeliveryDetail {
  requestDeliveryDate: string;
  deliveryNote: string;
  shipTo: ShipToBillToDetail;
  billTo: ShipToBillToDetail;
}

export class ShipToBillToDetail {
  id?: string;
  name: string;
  address: string;
  contactPersonName: string;
  contactPersonNumber: string;
  type: string;
  fullNameTh: string;
  fullNameEn: string;
}

/* Purchase Request list */

export class PurchaseRequestContent {
  id: string;
  version: number;
  docNo: string;
  type: PurchaseRequestTypeEnum;
  productType: ProductTypeEnum;
  purchaseTypeDisplay?: string;
  articleType: string;
  requester: string;
  requesterName: string;
  totalAmount: number;
  status: PurchaseRequestStatusEnum;
  advance?: boolean;
  orderSchedule?: string;
  createdDate: string;
  auditLogs: AuditLog[];
  shipToCode: string;
  shipToName: string;
  shipToCodeName: string;
  shipToType: ShipToType;
}

export class PurchaseRequestSearchCriteria {
  searchCriteria?: string;
  status?: PurchaseRequestStatusEnum;
  purchaseType?: string[];
  createdDateFrom?: string;
  createdDateTo?: string;
  page: number;
  size: number;
}

export class PurchaseRequestPagination {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: PurchaseRequestContent[];
}

export class TdAssortmentSearchCriteria {
  purchaseType?: string;
  searchCriteria?: string;
  productType?: string;
  shipToCode?: string;
  orderingCycle?: number;
  page?: number;
  size?: number;
}

export class TdAssortmentContent {
  no?: number;
  productName: string;
  articleNo: string;
  barcode: string;
  supplierCode: string;
  supplierName: string;
  supplierArticleNo?: string;
  orderUnit: string;
  unitPrice: number;
  quantity: number;
  minimumOrder?: number;
  vatPct?: number;
  vatAmount?: number;
  newUnitPrice?: number;
  lotSize: number;
  qty?: number;
}

export class TdAssortmentListPagination {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: TdAssortmentContent[];
}

export interface PurchaseRequestErrorResponse {
  code: string;
  message: string;
  items: PurchaseRequestItemDetails[];
}

export interface PurchaseRequestItemDetails {
  itemNo: number;
  changes?: ProductChange[];
  errors?: PurchaseRequestProductError[];
}

export interface ProductChange {
  field: string;
  before: any;
  after: any;
}

export interface PurchaseRequestCancelRequestData {
  id: string;
  comment: string;
}

export interface PurchaseRequestApproveRequestData {
  id: string;
  comment: string;
  approvedPrItem: PartialApproveItem[];
  subTotalAmount: number;
  vatAmount: number;
  totalAmount: number;
}

export interface PartialApproveItem {
  articleNo: string;
  supplierCode: string;
  barcode: string;
}

export interface PurchaseRequestRejectRequestData {
  id: string;
  comment: string;
}

export interface PurchaseRequestProductError {
  field: string;
  code: string;
  message: string;
  messageDisplay?: string;
}

export class FreeItems {
  refArticleNo: number;
  refBarcode: string;
  articleNo: string;
  barcode: string;
  productName: string;
  orderUnit: string;
  quantity: number;
  vatPct: number;
  supplierCode: string;
  supplierName: string;
  supplierArticleNo: string;
  remainingQuantity?: number;
  receivedQuantity?: number;
  unitFactor?: number;
}

export class PurchaseRequestDeliveryDetailCriteria {
  purchaseType: string;
}

export class PurchaseRequestExportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;
  statuses: PurchaseRequestStatusEnum[];
  purchaseTypes?: PurchaseRequestTypeEnum[];
  locations: string[];

  constructor(init?: Partial<PurchaseRequestExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
