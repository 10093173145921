import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { StockProductService } from '../../services/stock-product.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { StockProductActionTypes, StockProductListResponseAction } from '../actions/stock-product.actions';
import { selectAllStockProductSelected } from '../selectors/stock-product-selected.selectors';
export class StockProductEffects {
    constructor(actions$, stockProductService, store, logger) {
        this.actions$ = actions$;
        this.stockProductService = stockProductService;
        this.store = store;
        this.logger = logger;
        this.searchStockProductList$ = this.actions$.pipe(ofType(StockProductActionTypes.STOCK_PRODUCT_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StockProductActionTypes.STOCK_PRODUCT_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.stockProductService.searchByCriteria(payload).pipe(withLatestFrom(this.store.select(selectAllStockProductSelected)), map(([response, stockProductSelected]) => {
            stockProductSelected.forEach(selected => {
                const foundObjectIndex = response.content.findIndex(content => {
                    return content.articleNo === selected.articleNo;
                });
                if (response.content[foundObjectIndex]) {
                    response.content[foundObjectIndex].quantity = selected.quantity;
                }
            });
            return new StockProductListResponseAction(response);
        }), catchError(error => of(new LayoutActionLoadError(error))))));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StockProductEffects.prototype, "searchStockProductList$", void 0);
