import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { round } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OrderInventoryRequestService } from '../../services/order-inventory-request.service';
import { ShelfInventoryService } from '../../services/shelf-inventory.service';
import { ShelfService } from '../../services/shelf.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { OrderInventoryApproveResponseAction, OrderInventoryCreateSaveResponseAction, OrderInventoryCreateSubmitResponseAction, OrderInventoryCreateSubmitResponseErrorAction, OrderInventoryRequestActionType, OrderInventoryRequestByIdResponseAction } from '../actions/order-inventory-request.actions';
import { OrderInventorySelectItemOrderAddAllItem } from '../actions/order-select-item-inventory-order.actions';
import { ShelfSelectInventoryActionTypes, ShelfSelectInventoryListResponse } from '../actions/shelf-select-inventory.actions';
export class OrderInventoryRequestEffects {
    constructor(actions$, orderInventoryRequestService, shelfService, shelfInventoryService, logger, store) {
        this.actions$ = actions$;
        this.orderInventoryRequestService = orderInventoryRequestService;
        this.shelfService = shelfService;
        this.shelfInventoryService = shelfInventoryService;
        this.logger = logger;
        this.store = store;
        this.orderInventoryCreateSave$ = this.actions$.pipe(ofType(OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SAVE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SAVE_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.orderInventoryRequestService.saveRequest(payload).pipe(map(res => new OrderInventoryCreateSaveResponseAction(res)), catchError(error => of(new OrderInventoryCreateSubmitResponseErrorAction(error.error))));
        }));
        this.OrderInventoryCreateSubmit$ = this.actions$.pipe(ofType(OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SUBMIT_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SUBMIT_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.orderInventoryRequestService.submitRequest(payload).pipe(map(res => new OrderInventoryCreateSubmitResponseAction(res)), catchError(error => of(new OrderInventoryCreateSubmitResponseErrorAction(error.error))));
        }));
        this.getOrderInventoryRequestById$ = this.actions$.pipe(ofType(OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GET_BY_ID_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GET_BY_ID_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.orderInventoryRequestService.getOrderById(payload).pipe(map(res => {
                return new OrderInventoryRequestByIdResponseAction(res);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.orderInventoryApprove$ = this.actions$.pipe(ofType(OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_APPROVE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_APPROVE_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.orderInventoryRequestService.approveRequest(payload).pipe(map(() => new OrderInventoryApproveResponseAction({ isSuccess: true })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.orderInventoryRequestShelfListRequest$ = this.actions$.pipe(ofType(ShelfSelectInventoryActionTypes.SHELF_SELECT_INVENTORY_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfSelectInventoryActionTypes.SHELF_SELECT_INVENTORY_LIST_REQUEST}: ` + this.stringify(action));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfInventoryService.getShelfInventoryList(payload).pipe(map(response => {
                return new ShelfSelectInventoryListResponse(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.orderInventoryRequestGenerateShelfItemsRequest$ = this.actions$.pipe(ofType(OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GENERATE_SHELF_ITEM_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GENERATE_SHELF_ITEM_REQUEST}: ` +
                this.stringify(action));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfInventoryService.generateShelfItems(payload).pipe(map(response => {
                return new OrderInventorySelectItemOrderAddAllItem({ itemList: this.mappingShelfItemOrder(response) });
            }), catchError(err => of(new LayoutActionLoadError(err))));
        }), catchError(error => of(new LayoutActionLoadError(error))));
    }
    mappingShelfItemOrder(shelfItems) {
        shelfItems.forEach(item => {
            item.qty = item.quantity || 1;
            item.totalVat = {
                amount: (item.vatAmount && round(item.vatAmount.amount * item.qty, 2)) || 0,
                currency: (item.vatAmount && item.vatAmount.currency) || 'THB'
            };
            item.amountExcVat = {
                amount: (item.wholesalePriceExcVat && round(item.wholesalePriceExcVat.amount * item.qty, 2)) || 0,
                currency: (item.wholesalePriceExcVat && item.wholesalePriceExcVat.currency) || 'THB'
            };
            item.amount = {
                amount: round(item.amountExcVat.amount + item.totalVat.amount, 2) || 0,
                currency: (item.amountExcVat && item.amountExcVat.currency) || 'THB'
            };
            item.isAddItem = true;
        });
        return shelfItems;
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderInventoryRequestEffects.prototype, "orderInventoryCreateSave$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderInventoryRequestEffects.prototype, "OrderInventoryCreateSubmit$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderInventoryRequestEffects.prototype, "getOrderInventoryRequestById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderInventoryRequestEffects.prototype, "orderInventoryApprove$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderInventoryRequestEffects.prototype, "orderInventoryRequestShelfListRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderInventoryRequestEffects.prototype, "orderInventoryRequestGenerateShelfItemsRequest$", void 0);
