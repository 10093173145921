import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import {
  AlertModule,
  BsDatepickerModule,
  ButtonsModule,
  ModalModule,
  PaginationModule,
  PopoverModule
} from 'ngx-bootstrap';
import { MomentModule } from 'ngx-moment';
import { NumericTextboxModule } from 'ngx-numeric-textbox';
import { DropdownMultipleSelectComponent } from './components/dropdown-multiple-select/dropdown-multiple-select.component';
import { FileSubmitBeforeUploadComponent } from './components/file-submit-before-upload/file-submit-before-upload.component';
import { ImportExportButtonComponent } from './components/import-export-button/import-export-button.component';
import { NumericTextboxComponent } from './components/money-textbox/numeric-textbox.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import { ConfirmModalComponent } from './layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from './layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildDirective } from './layouts/modals/full-modal/child-directive';
import { FullModalComponent } from './layouts/modals/full-modal/full-modal.component';
import { SearchPaginationComponent } from './layouts/search-pagination/search-pagination.component';
import { TabComponent } from './layouts/tab/tab.component';
import { UiPaginationComponent } from './layouts/ui-pagination/ui-pagination.component';
import { DashDisplayPipe } from './pipes/dash-display.pipe';
import { DateDisplayPipe } from './pipes/date-display.pipe';
import { DaysDisplayModule } from './pipes/days-display/days-display.module';
import { MonthsDisplayPipe } from './pipes/months-display/months-display.pipe';
import { NumberFormatDisplayPipe } from './pipes/number-display.pipe';
import { PureFnModule } from './pipes/pure-fn/pure-fn.module';

@NgModule({
  declarations: [
    FileSubmitBeforeUploadComponent,
    TabComponent,
    NumericTextboxComponent,
    ImportExportButtonComponent,
    DropdownMultipleSelectComponent,
    NumberFormatDisplayPipe,
    ChildDirective,
    FullModalComponent,
    ConfirmModalComponent,
    DashDisplayPipe,
    DateDisplayPipe,
    ClickOutsideDirective,
    SearchPaginationComponent,
    ImportExportButtonComponent,
    FileSubmitBeforeUploadComponent,
    UiPaginationComponent,
    MonthsDisplayPipe,
    PreventDoubleClickDirective,
    ConfirmWithMessageModalComponent
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    NgOptionHighlightModule,
    MomentModule.forRoot(),
    ButtonsModule.forRoot(),
    RouterModule,
    PureFnModule,
    PopoverModule.forRoot(),
    PaginationModule.forRoot(),
    DaysDisplayModule,
    TranslateModule.forChild()
  ],
  exports: [
    // modules
    CommonModule,
    ModalModule,
    AlertModule,

    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    NgSelectModule,
    NgOptionHighlightModule,
    MomentModule,
    ButtonsModule,
    RouterModule,
    NumericTextboxModule,
    PureFnModule,
    PopoverModule,
    PaginationModule,

    // component
    NumericTextboxComponent,
    FileSubmitBeforeUploadComponent,
    ImportExportButtonComponent,
    TabComponent,
    DropdownMultipleSelectComponent,
    FullModalComponent,
    ConfirmModalComponent,
    SearchPaginationComponent,
    ImportExportButtonComponent,
    FileSubmitBeforeUploadComponent,
    UiPaginationComponent,
    PreventDoubleClickDirective,
    // PIPE
    DashDisplayPipe,
    DateDisplayPipe,
    NumberFormatDisplayPipe,
    DaysDisplayModule,

    // DIRECTIVE
    ClickOutsideDirective,
    ChildDirective,
    MonthsDisplayPipe
  ],
  entryComponents: [FullModalComponent, ConfirmModalComponent, ConfirmWithMessageModalComponent]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return { ngModule: SharedModule };
  }
}
