import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ShelfFixAssetService } from '../../services/shelf-fix-asset.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  ShelfFixAssetActionType,
  ShelfFixAssetByIdRequestAction,
  ShelfFixAssetByIdResponseAction,
  ShelfFixAssetListHistoryRequestAction,
  ShelfFixAssetListHistoryResponseAction,
  ShelfFixAssetListRequestAction,
  ShelfFixAssetListResponseAction,
  SubmitShelfFixAssetFirstLotErrorAction,
  SubmitShelfFixAssetFirstLotRequestAction,
  SubmitShelfFixAssetFirstLotResponseAction
} from '../actions/shelf-fix-asset.actions';
import { AppStates } from '../state/app.states';

@Injectable()
export class ShelfFixAssetEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly shelfFixAssetService: ShelfFixAssetService,
    private readonly store$: Store<AppStates>,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchShelf$ = this.actions$.pipe(
    ofType<ShelfFixAssetListRequestAction>(ShelfFixAssetActionType.SHELF_FIX_ASSET_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfFixAssetActionType.SHELF_FIX_ASSET_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfFixAssetService.searchByCriteria(payload).pipe(
        map(response => {
          return new ShelfFixAssetListResponseAction(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  shelfHistory$ = this.actions$.pipe(
    ofType<ShelfFixAssetListHistoryRequestAction>(ShelfFixAssetActionType.SHELF_FIX_ASSET_LIST_HISTORY_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfFixAssetActionType.SHELF_FIX_ASSET_LIST_HISTORY_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfFixAssetService.getHistoryLogs(payload).pipe(
        map(response => new ShelfFixAssetListHistoryResponseAction({ auditLogs: response.auditLogs })),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getMerchantById$ = this.actions$.pipe(
    ofType<ShelfFixAssetByIdRequestAction>(ShelfFixAssetActionType.SHELF_FIX_ASSET_GET_BY_ID_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfFixAssetActionType.SHELF_FIX_ASSET_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfFixAssetService.getShelfById(payload).pipe(
        map(res => {
          return new ShelfFixAssetByIdResponseAction(res);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  submitFirstLot$ = this.actions$.pipe(
    ofType<SubmitShelfFixAssetFirstLotRequestAction>(
      ShelfFixAssetActionType.SUBMIT_SHELF_FIX_ASSET_FIRST_LOT_REQUEST
    ),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfFixAssetActionType.SUBMIT_SHELF_FIX_ASSET_FIRST_LOT_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfFixAssetService.submitFirstLot(payload).pipe(
        map(res => new SubmitShelfFixAssetFirstLotResponseAction(res)),
        catchError(error => of(new SubmitShelfFixAssetFirstLotErrorAction(error.error)))
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
