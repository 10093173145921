<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-order"></em>
    {{ data.title }}
  </div>
  <button type="button" class="btn btn-header-button" *ngIf="hasCancelPermission()" (click)="cancelRequest()">
    <em class="icon-forbidden"></em>
    Cancel Request
  </button>
  <button type="button" class="btn btn-header-button" *ngIf="hasDeletePermission()" (click)="deleteRequest()">
    <em class="icon-delete"></em>
    Delete
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="data.mode === pageModeEnum.REQUEST_VIEW && (orderRequestView$ | async)?.status !== 'DRAFT'"
    (click)="showHistory()"
  >
    <em class="icon-history"></em>History
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="order-request" class="admin-form">
  <div class="info-header-container">
    <div class="info-header-item" *ngIf="(orderRequestView$ | async)?.requestNo">
      <div class="info-header-label">
        <span>Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (orderRequestView$ | async)?.requestNo }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Request Status:</span>
      </div>
      <div class="info-header-value">
        <span
          *ngIf="pageModeEnum.REQUEST_CREATE === data.mode; else getStatus"
          [ngClass]="getColorStatus('DRAFT')"
          class="request-status"
        >
          {{ 'ORDER_REQUEST.STATUS.DRAFT' | translate }}
        </span>
        <ng-template #getStatus>
          <span [ngClass]="getColorStatus((orderRequestView$ | async)?.status)" class="request-status">
            {{ 'ORDER_REQUEST.STATUS.' + (orderRequestView$ | async)?.status | translate }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="information-container">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="flex-column pl-2">
          <div class="info-store-type">
            <em [ngClass]="getStoreIcon((orderRequestView$ | async)?.store.storeType)"></em>
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Store</span>
          </div>
          <div class="info-header-value">
            {{ (orderRequestView$ | async)?.store.storeCodeName }}
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Order Type</span>
          </div>
          <div class="info-header-value">
            {{ 'ORDER_TYPE.' + orderRequest.orderType | translate }}
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Order Subtype</span>
          </div>
          <div class="info-header-value">
            {{ 'ORDER_SUBTYPE.' + orderRequest.orderSubType | translate }}
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Warehouse</span>
          </div>
          <div class="info-header-value">
            {{ data.warehouseDisplayName ? data.warehouseDisplayName : '-' }}
          </div>
        </div>
        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Requested Date</span>
          </div>
          <div class="info-header-value" *ngIf="orderRequest.requestedDate; else none">
            {{ orderRequest.requestedDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay }}
          </div>
          <ng-template #none>
            <div class="info-header-value">
              {{ (orderRequestView$ | async)?.requestedDate | dashDisplay: '(None)' }}
            </div>
          </ng-template>
        </div>

        <div class="col-lg-4">
          <div class="total-amount">
            <div class="text-right">
              <span class="info-total">
                {{ orderRequest.amount?.amount | numberFormatDisplay }}
              </span>
              <span class="info-total-currency">
                THB
              </span>
            </div>
            <div class="info-total-detail">
              <span>(Total Amount before VAT: {{ orderRequest.amountExcVat?.amount | numberFormatDisplay }} | </span
              ><span>VAT: {{ orderRequest.vatAmount?.amount | numberFormatDisplay }})</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-menu d-flex d-inline-flex">
    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{ active: selectedTab == shelfFixAssetTabs.FIX_ASSET }"
      (click)="onSelectTabs(shelfFixAssetTabs.FIX_ASSET)"
    >
      Fix Asset
    </a>
    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{
        active: selectedTab === shelfFixAssetTabs.STORE_USE
      }"
      (click)="onSelectTabs(shelfFixAssetTabs.STORE_USE)"
    >
      IT Equipment / Store Use
    </a>
  </div>

  <form [formGroup]="searchForm" (ngSubmit)="onSubmitSearch()">
    <div class="d-flex flex-row">
      <div
        class="form-group text-nowrap d-none d-md-block"
        *ngIf="data.mode === pageModeEnum.REQUEST_VIEW && selectedTab === shelfFixAssetTabs.FIX_ASSET"
      >
        <app-import-export-button
          [buttons]="buttons"
          (export)="onExportProductItemFixAsset()"
          class="d-flex align-items-center"
        ></app-import-export-button>
      </div>
      <div *ngIf="isShowFixAssetButton() && selectedTab === shelfFixAssetTabs.FIX_ASSET">
        <button type="button" id="selectShelf" class="btn btn-secondary mr-2" (click)="openSelectFixAssetShelf()">
          <em class="icon-plus"></em>
          Select Shelf Fix Asset
        </button>
        <button type="button" id="selectItem" class="btn btn-secondary mr-2" (click)="openSelectSpecialItem()">
          <em class="icon-plus"></em>
          Add Special Fix Asset
        </button>
      </div>

      <div *ngIf="isShowStoreUseButton() && selectedTab === shelfFixAssetTabs.STORE_USE">
        <button
          type="button"
          id="selectItemStoreUse"
          class="btn btn-secondary mr-2"
          (click)="openSelectStoreUseItem()"
        >
          <em class="icon-plus"></em>
          Select IT Equipment/Store Use
        </button>
      </div>
      <div *ngIf="data.mode !== pageModeEnum.REQUEST_CREATE && selectedTab === shelfFixAssetTabs.STORE_USE">
        <app-import-export-button
          [buttons]="buttons"
          (export)="onExportItem()"
          (import)="onClickImport()"
        ></app-import-export-button>
      </div>

      <div class="d-flex ml-auto">
        <div class="filter-search-box ml-2">
          <div class="search-box position-relative" *ngIf="false">
            <input
              type="text"
              formControlName="searchCriteria"
              class="form-control searchCriteria"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <button type="button" id="deliveryDetails" class="btn btn-standard ml-2" (click)="openDeliveryDetails()">
          <em
            class="fa nicon icon-truck mr-2"
            [ngClass]="{
              success: orderRequest.deliveryStatus === 'success',
              error: !baseShelfOrderFormArray.controls.length || orderRequest.deliveryStatus === 'error'
            }"
          ></em>
          Delivery Details
        </button>
      </div>
    </div>
  </form>

  <!-- Fix Asset Tab-->
  <div [ngClass]="{ 'd-none': selectedTab !== shelfFixAssetTabs.FIX_ASSET }">
    <ng-container
      *ngIf="
        !filteredShelfOrderFormArray.controls.length &&
        !filteredSpecialFormArray.controls.length &&
        !searchForm.controls['searchCriteria'].value
      "
    >
      <div class="align-middle">
        <div class="d-flex justify-content-center no-data">
          <img width="240" height="240" src="/assets/images/no-item.svg" alt="No item" />
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        !filteredShelfOrderFormArray.controls.length &&
        !filteredSpecialFormArray.controls.length &&
        searchForm.controls['searchCriteria'].value
      "
    >
      <div class="align-middle">
        <div class="d-flex justify-content-center no-data">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="No item" />
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="filteredShelfOrderFormArray.controls.length">
      <div class="row section-header justify-content-between mt-3">
        <span>Shelf Fix Asset</span>

        <div class="section-header-right px-2 mr-3">
          <a class=" my-1 btn-text-link" (click)="toggleExpandShelfItems(false)">Collapse All</a
          ><span class="px-1 pipe">|</span>
          <a class=" my-1 btn-text-link" (click)="toggleExpandShelfItems(true)">Expand All</a>
        </div>
      </div>

      <div
        class="table-responsive table-scroll fix-asset-table my-3"
        *ngIf="filteredShelfOrderFormArray.controls.length"
        [formGroup]="filteredShelfOrderFormGroup"
      >
        <table id="shelfItemsTable" class="table table-striped table-bordered w-100">
          <thead>
            <tr>
              <th class="text-center align-top w-5">No.</th>
              <th class="text-center align-top w-20">Product Name</th>
              <th class="text-center align-top w-10">Article</th>
              <th class="text-center align-top w-10">Barcode</th>
              <th class="text-center align-top w-10">Product Type</th>
              <th class="text-center align-top w-5">Unit</th>
              <th class="text-center align-top w-10">Unit Price</th>
              <th class="text-center align-top w-10">On Shelf QTY</th>
              <th class="text-center align-top w-10">Quantity</th>
              <th class="text-center align-top w-10">Amount</th>
            </tr>
          </thead>

          <tbody formArrayName="shelfOrderItems">
            <ng-container *ngFor="let shelf of shelfPaging.currentPageData; index as i">
              <ng-container [formGroupName]="shelfPaging.getIndexNo(i)">
                <tr
                  [id]="'shelf-' + shelfPaging.getIndexNo(i)"
                  class="shelf-row"
                  [ngClass]="{
                    'error-background': submitted && (isShelfError(shelf) || isInvalidShelf(shelf))
                  }"
                >
                  <td
                    class="text-center collapse-column"
                    (click)="toggleRowExpand(shelf.value.shelfNo, isShelfError(shelf) || isInvalidShelf(shelf))"
                  >
                    <em
                      class="icon-next"
                      [ngClass]="{ expanded: isExpandRow(shelf.value.shelfNo) }"
                      *ngIf="!isShelfError(shelf) && !isInvalidShelf(shelf)"
                    ></em>
                  </td>
                  <td
                    colspan="7"
                    class="text-left align-top shelf-name"
                    (click)="toggleRowExpand(shelf.value.shelfNo, isShelfError(shelf) || isInvalidShelf(shelf))"
                  >
                    {{ shelf.value.shelfName }}

                    <span class="d-block invalid-feedback text-danger" *ngIf="isShelfError(shelf)">
                      {{ shelf.errors?.isSubmitError ? shelf.errors?.isSubmitError : 'All Item Invalid.' }}
                    </span>

                    <span class="d-block invalid-feedback text-danger" *ngIf="submitted && isInvalidShelf(shelf)">
                      {{ 'All Item are unavailable.' }}
                    </span>
                  </td>
                  <td class="text-center qty-input">
                    <app-numeric-textbox
                      #shelfQty
                      id="{{ 'shelf-quantity-' + shelfPaging.getIndexNo(i) }}"
                      class="td-numeric-textbox-center"
                      formControlName="qty"
                      [negativeSign]="false"
                      placeholder="Quantity"
                      [format]="'0'"
                      [decimals]="0"
                      maxlength="5"
                      [minValue]="1"
                      [maxValue]="99999"
                      [allowPaste]="false"
                      [ngClass]="{
                        'is-invalid':
                          (submitted &&
                            (shelf?.get('qty').errors?.isZero ||
                              shelf?.errors?.isAllZero ||
                              shelf?.get('qty').errors?.required)) ||
                          shelfQty.value === 0 ||
                          shelfQty.value === null
                      }"
                    >
                    </app-numeric-textbox>
                    <div
                      *ngIf="
                        (submitted && shelf?.get('qty').errors) || shelfQty.value === 0 || shelfQty.value === null
                      "
                      class="invalid-display text-danger text-left"
                    >
                      <div *ngIf="shelf?.get('qty').errors?.isZero || shelfQty.value === 0">
                        {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                      </div>
                      <div *ngIf="shelf?.get('qty').errors.required || shelfQty.value === null">
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                    <div
                      *ngIf="submitted && shelf?.errors?.isAllZero && shelfQty.value !== 0 && shelfQty.value !== null"
                      class="invalid-display text-danger text-left"
                    >
                      {{ 'ERRORS.NOT_ALLOW_ALL_ZERO' | translate }}
                    </div>
                  </td>
                  <td class="text-center action">
                    <ng-container *ngIf="isShowFixAssetButton()">
                      <span *ngIf="!shelf.value.edit">
                        <em
                          class="icon-edit mr-2"
                          *ngIf="!isShelfError(shelf) && !isInvalidShelf(shelf)"
                          (click)="editShelfItem(shelf)"
                        ></em>
                        <em class="icon-delete" (click)="deleteShelfItem(shelf, shelfPaging.getIndexNo(i))"></em>
                      </span>
                      <span *ngIf="shelf.value.edit">
                        <em class="icon-correct mr-2" (click)="confirmEditShelfItem(shelf)"></em>
                        <em class="icon-close" (click)="cancelEditShelfItem(shelf)"></em>
                      </span>
                    </ng-container>
                  </td>
                </tr>

                <!-- Expandable Row-->
                <ng-container *ngFor="let item of shelf.get('items').controls; index as j">
                  <ng-container formGroupName="items">
                    <ng-container [formGroupName]="j">
                      <tr
                        [id]="'shelf-items-' + shelfPaging.getIndexNo(i) + '-' + j"
                        [@slide]="isExpandRow(shelf.value.shelfNo) ? 'down' : 'up'"
                        [ngClass]="{
                          'error-background': item.errors?.isSubmitError && data.mode !== pageModeEnum.REQUEST_VIEW
                        }"
                      >
                        <td class="text-center  w-5">{{ j + 1 }}</td>
                        <td class="text-left align-top w-20">
                          {{ item.value.productName }}

                          <span
                            class="d-block invalid-feedback text-danger"
                            *ngIf="item.errors?.isSubmitError && item.errors?.field === 'product'"
                          >
                            {{ item.errors?.isSubmitError }}
                          </span>
                        </td>
                        <td class="w-10">{{ item.value.articleNo }}</td>
                        <td class="w-10">
                          {{ item.value.barcode }}

                          <span
                            class="d-block invalid-feedback text-danger"
                            *ngIf="item.errors?.isSubmitError && item.errors?.field === 'barcode'"
                          >
                            {{ item.errors?.isSubmitError }}
                          </span>
                        </td>
                        <td class="w-10">
                          {{ 'PRODUCT_ASSORTMENT.PRODUCT_TYPE.' + item.value.productType | translate }}
                        </td>
                        <td class="text-center w-5">{{ item.value.unit }}</td>
                        <td class="text-right w-10">{{ item.value.unitPrice.amount | numberFormatDisplay }}</td>
                        <td class="text-center w-10">
                          {{ item.value.onShelfQty }}
                        </td>
                        <td class="text-center qty-input w-10">
                          <app-numeric-textbox
                            id="{{ 'shelf-quantity-item-' + shelfPaging.getIndexNo(i) + '-' + j }}"
                            class="td-numeric-textbox-center"
                            formControlName="qty"
                            [negativeSign]="false"
                            placeholder="Quantity"
                            [format]="'0'"
                            [decimals]="0"
                            maxlength="5"
                            [minValue]="0"
                            [maxValue]="99999"
                            [allowPaste]="false"
                            [ngClass]="{
                              'is-invalid': submitted && item?.get('qty').errors?.required
                            }"
                            (blur)="updateShelfItemValueChangesEvent($event, item, shelf)"
                          >
                          </app-numeric-textbox>
                          <div
                            *ngIf="submitted && item?.get('qty').errors"
                            class="invalid-display text-danger text-left"
                          >
                            <div *ngIf="item?.get('qty').errors.required">
                              {{ 'ERRORS.REQUIRED' | translate }}
                            </div>
                          </div>
                        </td>
                        <td class="text-right w-10 total-amount-bg">
                          {{ item.value.amount.amount | numberFormatDisplay }}
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <!-- Expandable Row-->
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>

      <!--pagination-->
      <app-ui-pagination
        #shelfPaging
        [(currentPage)]="currentShelfPage"
        [(pageSize)]="pageShelfSize"
        [pageSizeList]="pageShelfSizeList"
        [items]="filteredShelfOrderFormArray.controls"
        [valueChangeDetector]="filteredShelfOrderFormArray.valueChanges | async"
      >
      </app-ui-pagination>
      <!--pagination-->
    </ng-container>

    <ng-container *ngIf="filteredSpecialFormArray.controls.length">
      <div class="section-header justify-content-between clearfix mt-3">
        <span>Special Fix Asset</span>
      </div>

      <div
        class="table-responsive table-scroll my-3"
        *ngIf="filteredSpecialFormArray.controls.length"
        [formGroup]="filteredFormGroup"
      >
        <table id="itemsTable" class="table table-striped table-bordered w-100">
          <thead>
            <tr>
              <th class="text-center align-top">No.</th>
              <th class="text-center align-top">Product Name</th>
              <th class="text-center align-top">Article</th>
              <th class="text-center align-top">Barcode</th>
              <th class="text-center align-top">Product Type</th>
              <th class="text-center align-top">Unit</th>
              <th class="text-center align-top min-w-160px">Unit Price</th>
              <th class="text-center align-top max-w-150px">Quantity</th>
              <th class="text-center align-top min-w-160px">Amount (THB)</th>
              <th class="text-center align-top padding-x-0" *ngIf="isShowFixAssetButton()">
                <a (click)="deleteItem()" class="is-link">
                  <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
                </a>
              </th>
            </tr>
          </thead>

          <tbody formArrayName="specialOrderItems">
            <ng-container *ngFor="let item of specialPaging.currentPageData; index as i">
              <ng-container [formGroupName]="specialPaging.getIndexNo(i)">
                <tr
                  [id]="'special-' + specialPaging.getIndexNo(i)"
                  [ngClass]="{
                    'error-background': item.errors?.isSubmitError && data.mode !== pageModeEnum.REQUEST_VIEW
                  }"
                >
                  <td class="text-center">{{ specialPaging.getItemNo(i) }}</td>
                  <td class="text-left align-top">
                    {{ item.value.productName }}

                    <span
                      class="d-block invalid-feedback text-danger"
                      *ngIf="item.errors?.isSubmitError && item.errors?.field === 'product'"
                    >
                      {{ item.errors?.isSubmitError }}
                    </span>
                  </td>
                  <td>{{ item.value.articleNo }}</td>
                  <td>
                    {{ item.value.barcode }}

                    <span
                      class="d-block invalid-feedback text-danger"
                      *ngIf="item.errors?.isSubmitError && item.errors?.field === 'barcode'"
                    >
                      {{ item.errors?.isSubmitError }}
                    </span>
                  </td>
                  <td>{{ 'PRODUCT_ASSORTMENT.PRODUCT_TYPE.' + item.value.productType | translate }}</td>
                  <td class="text-center">{{ item.value.unit }}</td>
                  <td class="text-right">{{ item.value.unitPrice.amount | numberFormatDisplay }}</td>
                  <td class="text-center td-input-column-150">
                    <app-numeric-textbox
                      id="{{ 'special-quantity-item-' + specialPaging.getIndexNo(i) }}"
                      class="td-numeric-textbox-center"
                      formControlName="qty"
                      [negativeSign]="false"
                      placeholder="Quantity"
                      [format]="'0'"
                      [decimals]="0"
                      maxlength="5"
                      [minValue]="1"
                      [maxValue]="99999"
                      [allowPaste]="false"
                      [ngClass]="{
                        'is-invalid':
                          submitted && (item?.get('qty').errors?.isZero || item?.get('qty').errors?.required)
                      }"
                      [disabled]="item.errors?.isSubmitError && data.mode !== pageModeEnum.REQUEST_VIEW"
                      (blur)="updateValueChangesEvent($event, item.value)"
                    >
                    </app-numeric-textbox>
                    <div *ngIf="submitted && item?.get('qty').errors" class="invalid-display text-danger text-left">
                      <div *ngIf="item?.get('qty').errors?.isZero">
                        {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                      </div>
                      <div *ngIf="item?.get('qty').errors.required">
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </td>
                  <td class="text-right total-amount-bg">
                    {{ item.value.amount.amount | numberFormatDisplay }}
                  </td>
                  <td class="text-center padding-x-0 " *ngIf="isShowFixAssetButton()">
                    <a (click)="deleteItem(specialPaging.getIndexNo(i))">
                      <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
                    </a>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>

      <!--pagination-->
      <app-ui-pagination
        #specialPaging
        [(currentPage)]="currentSpecialPage"
        [(pageSize)]="pageSpecialSize"
        [items]="filteredSpecialFormArray.controls"
        [valueChangeDetector]="filteredSpecialFormArray.valueChanges | async"
      >
      </app-ui-pagination>
    </ng-container>
  </div>
  <!-- Fix Asset Tab-->

  <!-- Store Use Tab-->
  <div [ngClass]="{ 'd-none': selectedTab !== shelfFixAssetTabs.STORE_USE }">
    <ng-container
      *ngIf="
        !filteredShelfOrderFormArray.controls.length &&
        !filteredSpecialFormArray.controls.length &&
        !filteredStoreUseFormArray.controls.length &&
        !searchForm.controls['searchCriteria'].value
      "
    >
      <div class="align-middle">
        <div class="d-flex justify-content-center no-data">
          <img width="240" height="240" src="/assets/images/no-item.svg" alt="no item" />
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        !filteredShelfOrderFormArray.controls.length &&
        !filteredSpecialFormArray.controls.length &&
        !filteredStoreUseFormArray.controls.length &&
        searchForm.controls['searchCriteria'].value
      "
    >
      <div class="align-middle">
        <div class="d-flex justify-content-center no-data">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="no item" />
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="filteredStoreUseFormArray.controls.length">
      <div
        class="table-responsive table-scroll my-3"
        *ngIf="filteredStoreUseFormArray.controls.length"
        [formGroup]="filterStoreUseFormGroup"
      >
        <table id="storeUseOrderItemsTable" class="table table-striped table-bordered w-100">
          <thead>
            <tr>
              <th class="text-center align-top">No.</th>
              <th class="text-center align-top">Product Name</th>
              <th class="text-center align-top">Article</th>
              <th class="text-center align-top">Barcode</th>
              <th class="text-center align-top">Product Type</th>
              <th class="text-center align-top">Unit</th>
              <th class="text-center align-top min-w-160px">Unit Price</th>
              <th class="text-center align-top max-w-150px">Quantity</th>
              <th class="text-center align-top min-w-160px">Amount (THB)</th>
              <th class="text-center align-top padding-x-0" *ngIf="isShowStoreUseButton()">
                <a (click)="deleteItemStoreUse()" class="is-link">
                  <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
                </a>
              </th>
            </tr>
          </thead>

          <tbody formArrayName="storeUseOrderItems">
            <ng-container *ngFor="let item of storeUsePaging.currentPageData; index as i">
              <ng-container [formGroupName]="storeUsePaging.getIndexNo(i)">
                <tr
                  [id]="'store-use-' + storeUsePaging.getIndexNo(i)"
                  [ngClass]="{
                    'error-background': item.errors?.isSubmitError && data.mode !== pageModeEnum.REQUEST_VIEW
                  }"
                >
                  <td class="text-center">{{ storeUsePaging.getItemNo(i) }}</td>
                  <td class="text-left align-top">
                    {{ item.value.productName }}

                    <span
                      class="d-block invalid-feedback text-danger"
                      *ngIf="item.errors?.isSubmitError && item.errors?.field === 'product'"
                    >
                      {{ item.errors?.isSubmitError }}
                    </span>
                  </td>
                  <td>
                    {{ item.value.articleNo }}

                    <span
                      class="d-block invalid-feedback text-danger"
                      *ngIf="item.errors?.isSubmitError && item.errors?.field === 'article'"
                    >
                      {{ item.errors?.isSubmitError }}
                    </span>
                  </td>
                  <td>
                    {{ item.value.barcode }}

                    <span
                      class="d-block invalid-feedback text-danger"
                      *ngIf="item.errors?.isSubmitError && item.errors?.field === 'barcode'"
                    >
                      {{ item.errors?.isSubmitError }}
                    </span>
                  </td>
                  <td>{{ 'PRODUCT_ASSORTMENT.PRODUCT_TYPE.' + item.value.productType | translate }}</td>
                  <td class="text-center">{{ item.value.unit }}</td>
                  <td class="text-right">{{ item.value.unitPrice.amount | numberFormatDisplay }}</td>
                  <td class="text-center td-input-column-150">
                    <app-numeric-textbox
                      id="{{ 'store-use-quantity-item-' + storeUsePaging.getIndexNo(i) }}"
                      class="td-numeric-textbox-center"
                      formControlName="qty"
                      [negativeSign]="false"
                      placeholder="Quantity"
                      [format]="'0'"
                      [decimals]="0"
                      maxlength="5"
                      [minValue]="1"
                      [maxValue]="99999"
                      [allowPaste]="false"
                      [ngClass]="{
                        'is-invalid':
                          submitted && (item?.get('qty').errors?.isZero || item?.get('qty').errors?.required)
                      }"
                      [disabled]="item.errors?.isSubmitError && data.mode !== pageModeEnum.REQUEST_VIEW"
                      (blur)="updateValueStoreUseChangesEvent($event, item.value)"
                    >
                    </app-numeric-textbox>
                    <div *ngIf="submitted && item?.get('qty').errors" class="invalid-display text-danger text-left">
                      <div *ngIf="item?.get('qty').errors?.isZero">
                        {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                      </div>
                      <div *ngIf="item?.get('qty').errors.required">
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                  </td>
                  <td class="text-right total-amount-bg">
                    {{ item.value.amount.amount | numberFormatDisplay }}
                  </td>
                  <td class="text-center padding-x-0 " *ngIf="isShowStoreUseButton()">
                    <a (click)="deleteItemStoreUse(storeUsePaging.getIndexNo(i))">
                      <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
                    </a>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>

      <!--pagination-->
      <app-ui-pagination
        #storeUsePaging
        [(currentPage)]="currentStoreUsePage"
        [(pageSize)]="pageStoreUseSize"
        [items]="filteredStoreUseFormArray.controls"
        [valueChangeDetector]="filteredStoreUseFormArray.valueChanges | async"
      >
      </app-ui-pagination>
    </ng-container>
  </div>
  <!-- Store Use Tab-->

  <!--footer-->
  <ng-container *ngIf="hasAtLeastOnePermission()">
    <ng-container *ngIf="data.mode !== pageModeEnum.REQUEST_VIEW; else otherMode">
      <div class="fixed-row-bottom form-row">
        <div class="float-left" *ngIf="hasSavePermission()">
          <button type="button" id="btn-save" class="btn btn-secondary" (click)="onSave()" [disabled]="isLoading">
            Save
          </button>
        </div>
        <div class="float-right" *ngIf="hasSubmitPermission()">
          <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">Cancel</button>
          <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()" [disabled]="isLoading">
            Submit
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #otherMode>
      <ng-container>
        <div class="fixed-row-bottom form-row">
          <div class="float-left"></div>
          <div class="float-right">
            <span *ngIf="hasEditPermission()" class="mr-2">
              <button type="button" id="btn-edit" class="btn btn-primary" (click)="onTriggerEdit()">Edit</button>
            </span>
            <span *ngIf="hasApprovePermission()">
              <button
                type="button"
                id="btn-reject"
                class="btn btn-special-reject mr-2"
                (click)="doApproveRejectRequest(false)"
              >
                Reject
              </button>
              <button
                type="button"
                id="btn-approve"
                class="btn btn-special-approve"
                (click)="doApproveRejectRequest(true)"
              >
                Approve
              </button>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <!--End footer-->
</div>

<!-- Import Result -->
<div class="modal fade" bsModal #importResultModel="bs-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title pull-left">{{ importResult?.title }}</h6>
      </div>
      <div class="modal-body">
        <div
          *ngIf="importResult && importResult.table && importResult.table.length > 0"
          class="max-height-table table-responsive"
        >
          <table class="table table-striped table-bordered table-hover w-100">
            <thead>
              <tr class="error-background text-center">
                <th scope="col">Row</th>
                <th scope="col">Column</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of importResult.table; index as i">
                <td class="text-center">{{ item.row }}</td>
                <td>{{ item.column }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center" *ngIf="importResult && importResult.message">
          {{ importResult.message }}
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="importResultModel.hide()">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
