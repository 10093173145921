import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DestroyDocumentContent } from '../../../shared/models/destroy.model';
import { DestroyDocumentReportCriteria } from '../../../shared/models/report.model';
import { DestroyService } from '../../../shared/services/destroy.service';
import { AppStates } from '../../../shared/store/state/app.states';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-destroy-document-modal',
  templateUrl: './destroy-document-modal.component.html',
  styleUrls: ['./destroy-document-modal.component.scss']
})
export class DestroyDocumentModalComponent extends BaseReportModalComponent {
  @ViewChild('destroyReportModal', { static: false }) destroyReportModal: ModalDirective;
  public exportForm: FormGroup;
  public submitted: boolean;
  public exportFormInValid: boolean;
  public responseExportError: string;
  public dateTimeDisplay = environment.dateTimeDisplay;

  public destroyDocumentList: Observable<DestroyDocumentContent[]>;
  public destroyDocumentSearchLoading: boolean;
  public destroyDocumentSearchInput$ = new Subject<string>();

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly formBuilder: FormBuilder,
    protected readonly translate: TranslateService,
    protected readonly destroyService: DestroyService
  ) {
    super();
  }

  ngOnInit() {
    this.initControl();
    this.loadDestroyDocument('');
  }

  initControl() {
    this.exportForm = this.formBuilder.group({
      destroyDoc: [null]
    });
  }

  onChangeDestroyDocument($event) {
    this.exportFormInValid = $event === undefined;
  }

  loadDestroyDocument(initialTerm: string) {
    this.destroyDocumentList = this.destroyDocumentSearchInput$.pipe(
      startWith(initialTerm),
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.destroyDocumentSearchLoading = true)),
      switchMap(term =>
        this.destroyService
          .searchAllDestroyDocumentByCriteria({
            searchCriteria: term,
            page: 0,
            size: 20
          })
          .pipe(
            catchError(() => of([])), // empty list on error
            map(data => data.content),
            tap(() => {
              this.destroyDocumentSearchLoading = false;
            })
          )
      )
    );
  }

  get form() {
    return this.exportForm.controls;
  }

  closeExportModal() {
    this.exportForm.reset();
    this.destroyReportModal.hide();
  }

  onExport() {
    if (this.exportFormInValid) {
      return;
    }

    this.responseExportError = null;
    const rawData = this.exportForm.getRawValue();
    const destroyDocumentReportCriteria = new DestroyDocumentReportCriteria({
      docNo: rawData.destroyDoc
    });

    this.destroyService.exportDestroyDocument(destroyDocumentReportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportDestroyDocument.prefix} ${this.timeToExport}.pdf`);
      },
      error => {
        this.responseExportError = error.error.message || this.translate.instant(error.error.translateKey);
      },
      () => {
        this.submitted = false;
        this.closeExportModal();
      }
    );
  }

  openExportModal() {
    this.exportForm.reset();
    this.responseExportError = null;
    this.exportFormInValid = true;
    this.destroyReportModal.show();
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportDestroyDocument.timeFormat);
  }
}
