import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models';
import {
  OrderFixAssetCreateRequest,
  OrderFixAssetRequestViewResponse
} from '../../models/order-request-fix-asset.model';
import { ApproveOrderRequest, DeliveryDetails, OrderId } from '../../models/order-request.model';

export enum OrderFixAssetRequestActionType {
  ORDER_FIX_ASSET_REQUEST_GET_BY_STORE_RESPONSE = '[OrderFixAssetRequest] Get Order by Store Response',
  ORDER_FIX_ASSET_REQUEST_GET_BY_ID_REQUEST = '[OrderFixAssetRequest] Get Order by Id Request',
  ORDER_FIX_ASSET_REQUEST_GET_BY_ID_RESPONSE = '[OrderFixAssetRequest] Get Order by Id Response',
  ORDER_FIX_ASSET_REQUEST_GET_BY_ID_RESET = '[OrderFixAssetRequest] Get Order by Id Reset',
  ORDER_FIX_ASSET_CREATE_SAVE_REQUEST = '[OrderFixAssetRequest] Create Order Save Request',
  ORDER_FIX_ASSET_CREATE_SAVE_RESPONSE = '[OrderFixAssetRequest] Create Order Save Response',
  ORDER_FIX_ASSET_CREATE_SUBMIT_REQUEST = '[OrderFixAssetRequest] Create Order Submit Request',
  ORDER_FIX_ASSET_CREATE_SUBMIT_RESPONSE = '[OrderFixAssetRequest] Create Order Submit Response',
  ORDER_FIX_ASSET_CREATE_SUBMIT_ERROR = '[OrderFixAssetRequest] Create Order Submit Error',
  ORDER_FIX_ASSET_CREATE_RESET = '[OrderFixAssetRequest] Create Order Reset',
  ORDER_FIX_ASSET_REQUEST_APPROVE_REQUEST = '[OrderFixAssetRequest] Request Approve Order Request',
  ORDER_FIX_ASSET_REQUEST_APPROVE_RESPONSE = '[OrderFixAssetRequest] Request Approve Order Response',
  ORDER_FIX_ASSET_REQUEST_SAVE_DELIVERY_DETAILS = '[OrderFixAssetRequest] Save Delivery Details'
}

export class OrderFixAssetRequestByStoreResponseAction implements Action {
  readonly type = OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_GET_BY_STORE_RESPONSE;

  constructor(public payload: any) {}
}

export class OrderFixAssetRequestByIdRequestAction implements Action {
  readonly type = OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: OrderId) {}
}

export class OrderFixAssetRequestByIdResponseAction implements Action {
  readonly type = OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: OrderFixAssetRequestViewResponse) {}
}

export class ResetOrderFixAssetRequestSelected implements Action {
  readonly type = OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_GET_BY_ID_RESET;
}

export class OrderFixAssetCreateSaveRequestAction implements Action {
  readonly type = OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SAVE_REQUEST;

  constructor(public payload: OrderFixAssetCreateRequest) {}
}

export class OrderFixAssetCreateSaveResponseAction implements Action {
  readonly type = OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SAVE_RESPONSE;

  constructor(public payload: OrderFixAssetRequestViewResponse) {}
}

export class OrderFixAssetCreateSubmitRequestAction implements Action {
  readonly type = OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SUBMIT_REQUEST;

  constructor(public payload: OrderFixAssetCreateRequest) {}
}

export class OrderFixAssetCreateSubmitResponseAction implements Action {
  readonly type = OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SUBMIT_RESPONSE;

  constructor(public payload: OrderFixAssetRequestViewResponse) {}
}

export class OrderFixAssetCreateSubmitResponseErrorAction implements Action {
  readonly type = OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_SUBMIT_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class OrderFixAssetCreateResetAction implements Action {
  readonly type = OrderFixAssetRequestActionType.ORDER_FIX_ASSET_CREATE_RESET;
}

export class OrderFixAssetApproveRequestAction implements Action {
  readonly type = OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_APPROVE_REQUEST;

  constructor(public payload: ApproveOrderRequest) {}
}

export class OrderFixAssetApproveResponseAction implements Action {
  readonly type = OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_APPROVE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class OrderFixAssetRequestSaveDeliveryDetailsAction implements Action {
  readonly type = OrderFixAssetRequestActionType.ORDER_FIX_ASSET_REQUEST_SAVE_DELIVERY_DETAILS;

  constructor(public payload: DeliveryDetails) {}
}

export type OrderFixAssetRequestActions =
  | OrderFixAssetRequestByIdRequestAction
  | OrderFixAssetRequestByIdResponseAction
  | ResetOrderFixAssetRequestSelected
  | OrderFixAssetCreateSaveRequestAction
  | OrderFixAssetCreateSaveResponseAction
  | OrderFixAssetCreateSubmitRequestAction
  | OrderFixAssetCreateSubmitResponseAction
  | OrderFixAssetCreateSubmitResponseErrorAction
  | OrderFixAssetCreateResetAction
  | OrderFixAssetRequestByStoreResponseAction
  | OrderFixAssetApproveRequestAction
  | OrderFixAssetApproveResponseAction
  | OrderFixAssetRequestSaveDeliveryDetailsAction;
