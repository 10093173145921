import { Action } from '@ngrx/store';
import { ShelfSelectedItems } from '../../models/first-lot-order.model';
import {
  SelectShelfInventoryListResponse,
  ShelfInventoryListSearchCriteria
} from '../../models/shelf-inventory.model';

export enum ShelfSelectInventoryActionTypes {
  SHELF_SELECT_INVENTORY_LIST_REQUEST = '[Shelf Select Inventory] Shelf Select Inventory List Request',
  SHELF_SELECT_INVENTORY_LIST_RESPONSE = '[Shelf Select Inventory] Shelf Select Inventory List Response',
  SHELF_SELECT_INVENTORY_LIST_RESET = '[Shelf Select Inventory] Shelf Select Inventory List Reset',
  SHELF_SELECT_INVENTORY_GENERATE_SHELF_ITEM_REQUEST = '[Shelf Select Inventory] Generate Shelf items Request'
}

export class ShelfSelectInventoryListRequest implements Action {
  readonly type = ShelfSelectInventoryActionTypes.SHELF_SELECT_INVENTORY_LIST_REQUEST;

  constructor(public payload: ShelfInventoryListSearchCriteria) {}
}

export class ShelfSelectInventoryListResponse implements Action {
  readonly type = ShelfSelectInventoryActionTypes.SHELF_SELECT_INVENTORY_LIST_RESPONSE;

  constructor(public payload: SelectShelfInventoryListResponse) {}
}

export class ShelfSelectInventoryListReset implements Action {
  readonly type = ShelfSelectInventoryActionTypes.SHELF_SELECT_INVENTORY_LIST_RESET;

  constructor() {}
}

export class ShelfSelectInventoryGenerateShelfItemsRequestAction implements Action {
  readonly type = ShelfSelectInventoryActionTypes.SHELF_SELECT_INVENTORY_GENERATE_SHELF_ITEM_REQUEST;

  constructor(public payload: ShelfSelectedItems[]) {}
}

export type ShelfSelectInventoryActions =
  | ShelfSelectInventoryListRequest
  | ShelfSelectInventoryListResponse
  | ShelfSelectInventoryListReset
  | ShelfSelectInventoryGenerateShelfItemsRequestAction;
