<div class="popover-container">
  <div class="row section-header mb-3">
    <span
      >Order/Delivery Schedule
      <span class="info-header-value">
        {{ scheduleType ? ' : ' + PurchaseConditionScheduleSettingTitleEnum[scheduleType] : '' }}</span
      >
      <span
        *ngIf="
          purchaseConditionForm.get('scheduleEdit').value &&
          purchaseConditionForm.get('requestType').value === requestTypeEnum.EDIT &&
          [purchaseConditionPageMode.VIEW_REQUEST].includes(pageMode)
        "
        class="badge badge-warning text-white ml-1"
        >Updated</span
      >
    </span>
  </div>
  <div class="flex-item d-md-flex mb-3">
    <button
      type="button"
      class="btn btn-standard mr-2"
      (click)="onShowSettingDialog(templateDialog)"
      *ngIf="![purchaseConditionPageMode.VIEW_REQUEST, purchaseConditionPageMode.VIEW].includes(pageMode)"
    >
      <em class="icon-edit"></em>{{ scheduleType ? 'Change Setting' : 'Schedule Setting' }}
    </button>
    <button
      type="button"
      class="btn btn-standard"
      (click)="addNewWeeklyManual()"
      *ngIf="
        scheduleType === OrderDeliveryScheduleType.WEEKLY_MANUAL &&
        ![purchaseConditionPageMode.VIEW_REQUEST, purchaseConditionPageMode.VIEW].includes(pageMode)
      "
    >
      <em class="icon-plus"></em> Add Order/Delivery Schedule
    </button>
    <app-import-export-button
      class="ml-2"
      [buttons]="buttons"
      (import)="checkExistingItem()"
      (export)="onExportOrderDeliverySchedule()"
    ></app-import-export-button>
  </div>

  <!-- ploy here -->
  <ng-container *ngIf="scheduleType === OrderDeliveryScheduleType.WEEKLY_MANUAL">
    <div class="table-responsive" *ngIf="(purchaseConditionRequestWeeklyScheduleList$ | async).length > 0">
      <table id="data-table-manual" class="table table-striped table-bordered table-hover w-100" aria-hidden="true">
        <thead>
          <tr>
            <th class="text-center align-top">Product Level</th>
            <th class="text-center align-top">Product</th>
            <th class="text-center align-top">
              Order Schedule
            </th>
            <th class="text-center align-top">Delivery Schedule</th>
            <th class="text-center align-top">Lead Time</th>
            <th class="text-center align-top">Apply To DC</th>
            <th
              class="text-center align-top"
              *ngIf="![purchaseConditionPageMode.VIEW_REQUEST, purchaseConditionPageMode.VIEW].includes(pageMode)"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody *ngIf="paging">
          <tr *ngFor="let schedule of paging.currentPageData as weeklyScheduleList; let i = index">
            <td class="text-left align-top">{{ PurchaseConditionProductLevelEnum[schedule.productLevel] }}</td>
            <td class="text-left align-top">{{ PurchaseConditionProductLevelEnum[schedule.articleNo] }}</td>
            <td class="text-left align-top">{{ schedule.orderWeekDays | daysDisplay: false }}</td>
            <td class="text-left align-top">{{ schedule.deliveryWeekDays | daysDisplay: false }}</td>
            <td class="text-center align- ">{{ schedule.leadTime }}</td>
            <td
              class="text-left align-top white-space "
              [innerHTML]="wmsCodeNameForDisplay | pureFn: schedule.warehouses"
            ></td>
            <td
              class="text-center align-center"
              *ngIf="![purchaseConditionPageMode.VIEW_REQUEST, purchaseConditionPageMode.VIEW].includes(pageMode)"
            >
              <a
                href="javascript:void(0)"
                class="btn-more"
                [popover]="moreTemplate"
                placement="bottom"
                containerClass="fl"
                [outsideClick]="true"
                container=".popover-container"
                [adaptivePosition]="false"
              >
                <em class="icon-more"></em>
              </a>
              <ng-template #moreTemplate>
                <button type="button" class="btn btn-link" (click)="onEdit(schedule, paging.items)">
                  <em class="icon-edit"></em>Edit
                </button>
                <button
                  type="button"
                  class="btn btn-link"
                  (click)="onDelete(paging.getItemNo(i) - 1, weeklyScheduleList)"
                >
                  <em class="icon-delete"></em>Delete
                </button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
      <!--pagination-->
      <app-ui-pagination
        #paging
        [currentPage]="currentPage"
        [pageSize]="pageSize"
        [items]="purchaseConditionRequestWeeklyScheduleList$ | async"
        [valueChangeDetector]="(purchaseConditionRequestWeeklyScheduleList$ | async).length"
      >
      </app-ui-pagination>
    </div>
  </ng-container>

  <!-- ploy end -->

  <!-- Bee here -->
  <ng-container
    *ngIf="scheduleType === OrderDeliveryScheduleType.WEEKLY || scheduleType === OrderDeliveryScheduleType.MONTHLY"
  >
    <div class="table-responsive" *ngIf="(purchaseConditionRequestWeeklySchedule$ | async).totalElements > 0">
      <table
        [id]="scheduleType === OrderDeliveryScheduleType.WEEKLY ? 'data-table-weekly' : 'data-table-monthly'"
        class="table table-striped table-bordered table-hover w-100"
        aria-hidden="true"
      >
        <thead>
          <tr>
            <th class="text-center align-top">Product Level</th>
            <th class="text-center align-top">Product</th>
            <th class="text-center align-top" *ngIf="scheduleType === OrderDeliveryScheduleType.MONTHLY">
              Order Month
            </th>
            <th class="text-center align-top">
              Order Schedule
            </th>
            <th class="text-center align-top" *ngIf="scheduleType === OrderDeliveryScheduleType.MONTHLY">
              Delivery Month
            </th>
            <th class="text-center align-top">Delivery Schedule</th>
            <th class="text-center align-top">Lead Time</th>
            <th class="text-center align-top">Apply To DC</th>
            <th class="text-center align-top" *ngIf="scheduleType === OrderDeliveryScheduleType.WEEKLY_MANUAL">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let schedule of purchaseConditionRequestWeeklyScheduleList$ | async as weeklyScheduleList;
              let i = index
            "
          >
            <ng-container *ngIf="scheduleType === OrderDeliveryScheduleType.WEEKLY; else monthly">
              <td class="text-left align-top">{{ PurchaseConditionProductLevelEnum[schedule.productLevel] }}</td>
              <td class="text-left align-top" *ngIf="schedule.productLevel === 'ALL_PRODUCTS'; else byProduct">
                {{ 'PURCHASE_CONDITION_REQUEST.PRODUCT_LEVEL.' + schedule.productLevel | translate }}
              </td>
              <ng-template #byProduct>
                <td class="text-left align-top">
                  {{ schedule.productName }}
                  <div>
                    <span>{{ schedule.articleNo }} </span>
                  </div>
                </td>
              </ng-template>
              <td class="text-left align-top">{{ schedule.orderWeekDays | daysDisplay: false }}</td>
              <td class="text-left align-top">{{ schedule.deliveryWeekDays | daysDisplay: false }}</td>
              <td class="text-center align- ">{{ schedule.leadTime }}</td>
              <td
                class="text-left align-top white-space"
                [innerHTML]="wmsCodeNameForDisplay | pureFn: schedule.warehouses"
              ></td>
            </ng-container>
            <ng-template #monthly>
              <td class="text-left align-top">{{ PurchaseConditionProductLevelEnum[schedule.productLevel] }}</td>
              <td class="text-left align-top" *ngIf="schedule.productLevel === 'ALL_PRODUCTS'; else byProduct">
                {{ 'PURCHASE_CONDITION_REQUEST.PRODUCT_LEVEL.' + schedule.productLevel | translate }}
              </td>
              <ng-template #byProduct>
                <td class="text-left align-top">
                  {{ schedule.productName }}
                  <div>
                    <span>{{ schedule.articleNo }} </span>
                  </div>
                </td>
              </ng-template>
              <td class="text-left align-top">{{ schedule.orderMonths | monthsDisplay: true }}</td>
              <td class="text-left align-top">{{ schedule.orderSchedules }}</td>
              <td class="text-left align-top">{{ schedule.deliveryMonths | monthsDisplay: true }}</td>
              <td class="text-left align-top">{{ schedule.deliverySchedules }}</td>
              <td class="text-center align- ">{{ schedule.leadTime }}</td>
              <td
                class="text-left align-top white-space"
                [innerHTML]="wmsCodeNameForDisplay | pureFn: schedule.warehouses"
              ></td>
            </ng-template>
          </tr>
        </tbody>
      </table>
      <!--pagination-->
      <app-search-pagination
        [currentPage]="currentPage"
        [pageSize]="pageSize"
        [listState$]="purchaseConditionRequestWeeklySchedule$"
        (changePage)="onChangePage($event)"
        (changeRowPerPage)="onChangeRowPerPage($event)"
      >
      </app-search-pagination>
    </div>
  </ng-container>

  <!-- Bee end -->
</div>

<div
  class="modal fade"
  bsModal
  #templateDialog="bs-modal"
  [config]="{ backdrop: 'static' }"
  role="dialog"
  aria-labelledby="dialog-static-name"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Schedule Setting</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="onHideModal(templateDialog)">
          <em class="icon-close"></em>
        </button>
      </div>

      <div class="modal-body">
        <div class="col-md-6 offset-md-3">
          <div class="form-check form-check-inline pb-2">
            <input
              class="form-check-input mt-0"
              type="radio"
              id="weeklyManual"
              [formControl]="mode"
              [value]="OrderDeliveryScheduleType.WEEKLY_MANUAL"
            />
            <label class="form-check-label" for="weeklyManual">
              Set by Weekly, All Products (only)
            </label>
          </div>
          <div class="form-check form-check-inline pb-2">
            <input
              class="form-check-input mt-0"
              type="radio"
              id="weekly"
              [formControl]="mode"
              [value]="OrderDeliveryScheduleType.WEEKLY"
            />
            <label class="form-check-label" for="weekly">
              Set by Weekly, All / By Product
            </label>
          </div>
          <div class="form-check form-check-inline pb-2">
            <input
              class="form-check-input mt-0"
              type="radio"
              id="monthly"
              [formControl]="mode"
              [value]="OrderDeliveryScheduleType.MONTHLY"
            />
            <label class="form-check-label" for="monthly">
              Set by Calendar, All / By Product
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-standard" id="btCancel" (click)="onHideModal(templateDialog)">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" id="btnSubmit" (click)="onSubmit(templateDialog)">
          OK
        </button>
      </div>
    </div>
  </div>
</div>

<app-schedule-weekly-manual
  *ngIf="displayWeeklyModal && scheduleType === OrderDeliveryScheduleType.WEEKLY_MANUAL"
  [(displayModel)]="displayWeeklyModal"
  [weeklyManualValue]="weeklyManual"
  [purchaseConditionForm]="purchaseConditionForm"
  [pageMode]="pageMode"
  (dirtyOrderSchedule)="setDirtyOrderSchedule($event)"
></app-schedule-weekly-manual>
