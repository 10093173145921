<!-- Modal Section -->
<div
  id="supplierCouponReconcileModal"
  class="modal fade barcodeModal admin-form"
  bsModal
  #supplierCouponReconcileModal="bs-modal"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <h6 class="modal-title pull-left">
            Export Supplier Coupon Reconcile Report
          </h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeExportModal()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body my-2 pt-0">
          <div class="container" [formGroup]="exportForm">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <div class="form-row">
                        <div class="col-12">Transaction Date<span class="text-danger">*</span></div>
                        <div class="col-md-6">
                          <label>{{ 'FROM' | translate }}</label>
                          <input
                            id="supplierCouponReconcileTransactionDateFrom"
                            formControlName="transactionDateFrom"
                            type="text"
                            placeholder="{{ dateFormat }}"
                            autocomplete="off"
                            class="form-control calendar"
                            bsDatepicker
                            [bsConfig]="bsDateConfig"
                            [maxDate]="maxDate"
                            (bsValueChange)="onChangeExportTransactionDateFrom($event)"
                            readonly
                          />
                          <label
                            for="supplierCouponReconcileTransactionDateFrom"
                            class="icon-calendar btn-calendar"
                          ></label>
                        </div>
                        <div class="col-md-6">
                          <label>{{ 'TO' | translate }}</label>
                          <input
                            id="supplierCouponReconcileTransactionDateTo"
                            formControlName="transactionDateTo"
                            type="text"
                            placeholder="{{ dateFormat }}"
                            autocomplete="off"
                            class="form-control calendar"
                            bsDatepicker
                            [minDate]="minDate"
                            [bsConfig]="bsDateConfig"
                            (bsValueChange)="onChangeExportTransactionDateTo($event)"
                            readonly
                          />
                          <label
                            for="supplierCouponReconcileTransactionDateTo"
                            class="icon-calendar btn-calendar"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div class="form-group  col-md-12">
                      <div class="mb-2">
                        Store
                      </div>
                      <app-dropdown-multiple-select
                        [parentForm]="exportForm"
                        controlName="no"
                        [items]="storeList"
                        bindValue="no"
                        bindLabel="storeCodeName"
                      >
                      </app-dropdown-multiple-select>
                    </div>
                    <div *ngIf="responseExportError" class="alert-danger form-group mb-0 w-100">
                      {{ responseExportError }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            id="export_supplier_coupon_reconcile_report_btn"
            [disabled]="exportFormInValid"
            (click)="onExport()"
            appPreventDoubleClick
          >
            Export
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- End Modal Section -->
