import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../../base/base.component';
import { TDStoreValidatorTypeEnum } from '../../../../shared/enum/merchant-validator-type.enum';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { ProductTypeEnum } from '../../../../shared/enum/product-type.enum';
import { NewRequestStatusEnum } from '../../../../shared/enum/request-status.enum';
import {
  NewRequestTypeEnum,
  RequestPageModesEnum,
  RequestProductErrorEnum
} from '../../../../shared/enum/request-step.enum';
import { ShelfPages } from '../../../../shared/enum/td-store-page.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ConfirmModalComponent } from '../../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ErrorResponse } from '../../../../shared/models';
import { BarcodeListSearchCriteria, BarcodeResponse } from '../../../../shared/models/barcode.model';
import { ConfirmModal } from '../../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../../shared/models/notification-emit.model';
import {
  ShelfFixAssetListSearchCriteria,
  ShelfFixAssetViewResponse
} from '../../../../shared/models/shelf-fix-asset.model';
import { NonMerchandiseItem, ShelfFixAssetCreateRequest } from '../../../../shared/models/shelf-request.model';
import { AuthGuardService, BarcodeService } from '../../../../shared/services';
import { ShelfFixAssetRequestService } from '../../../../shared/services/shelf-fix-asset-request.service';
import { ShelfFixAssetService } from '../../../../shared/services/shelf-fix-asset.service';
import {
  EditShelfFixAssetAction,
  ResetShelfFixAssetSelected,
  ShelfFixAssetByIdRequestAction,
  ShelfFixAssetListRequestAction,
  SubmitShelfFixAssetFirstLotRequestAction
} from '../../../../shared/store/actions/shelf-fix-asset.actions';
import {
  ShelfFixAssetCreateResetAction,
  ShelfFixAssetCreateSubmitRequestAction
} from '../../../../shared/store/actions/shelf-request.actions';
import { ShelfFixAssetCreateResponseState } from '../../../../shared/store/reducers/shelf-fix-asset-create.reducers';
import { selectShelfFixAsset } from '../../../../shared/store/selectors/shelf-fix-asset-create.selectors';
import {
  selectShelfFixAssetById,
  selectShelfFixAssetListCriteria
} from '../../../../shared/store/selectors/shelf-fix-asset.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';
import { getFileId } from '../../../../shared/utils/get-fileId-util';
import { ShelfFixAssetComponent } from '../../shelf-components/shelf-fix-asset/shelf-fix-asset.component';
import { ShelfInfoComponent } from '../../shelf-components/shelf-info/shelf-info.component';
@Component({
  selector: 'app-shelf-fix-asset-view',
  templateUrl: './shelf-fix-asset-view.component.html',
  styleUrls: ['./shelf-fix-asset-view.component.scss']
})
export class ShelfFixAssetViewComponent extends BaseComponent {
  @ViewChild('shelfInfo', { static: false }) shelfInfo: ShelfInfoComponent;
  @ViewChild('fixAsset', { static: false }) fixAsset: ShelfFixAssetComponent;

  @Input() data: { title: string; mode: RequestPageModesEnum; shelfNo?: string };
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  public shelfFixAssetView$: Observable<ShelfFixAssetViewResponse>;
  public shelfForm: FormGroup;
  public ownerUsername: string;
  public status: NewRequestStatusEnum | string;
  public submitted: boolean;
  public page: ShelfPages;
  public pageFixAsset: ShelfPages;
  public shelfType: ProductTypeEnum = ProductTypeEnum.FIX_ASSET;

  private localStore: Observable<any>;
  private readonly type: NewRequestTypeEnum = NewRequestTypeEnum.EDIT;
  private listSearchCriteria: ShelfFixAssetListSearchCriteria;

  constructor(
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly store: Store<AppStates>,
    protected readonly translate: TranslateService,
    private readonly barcodeService: BarcodeService,
    protected shelfFixAssetRequestService: ShelfFixAssetRequestService,
    protected shelfFixAssetService: ShelfFixAssetService,
    private readonly authGuardService: AuthGuardService,
    protected readonly logger: NGXLogger
  ) {
    super(store, modalService, true);
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetShelfFixAssetSelected());
    this.store.dispatch(new ShelfFixAssetCreateResetAction());
    this.store.dispatch(
      new ShelfFixAssetListRequestAction({
        ...this.listSearchCriteria
      })
    );
  }

  ngOnInit() {
    this.page = ShelfPages.SHELF_FIX_ASSET_EDIT;
    this.pageFixAsset = ShelfPages.SHELF_REQUEST;
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.store.dispatch(new ShelfFixAssetByIdRequestAction({ shelfNo: this.data.shelfNo }));

    this.shelfFixAssetView$ = this.localStore.pipe(
      select(selectShelfFixAssetById),
      filter(data => Boolean(data))
    );

    this.shelfFixAssetView$.pipe(take(1)).subscribe(data => {
      this.status = data.status;

      if (this.data.mode === RequestPageModesEnum.REQUEST_EDIT) {
        this.onTriggerEdit();
      } else {
        this.store.dispatch(new EditShelfFixAssetAction({ type: NewRequestTypeEnum.EDIT }));
      }
    });

    this.localStore
      .pipe(select(selectShelfFixAssetListCriteria))
      .subscribe(criteriaObject => (this.listSearchCriteria = criteriaObject));

    this.initControl();
  }

  get pageModes() {
    return RequestPageModesEnum;
  }

  get requestStatus() {
    return NewRequestStatusEnum;
  }

  getColorStatus(status: string): string {
    return status && status.toLowerCase();
  }

  hasEditPermission(): boolean {
    return this.authGuardService.checkPermission(['sh_ast_m']);
  }

  hasSubmitPermission(): boolean {
    return this.authGuardService.checkPermission(['sh_ast_m']);
  }

  hasAtLeastOnePermission() {
    return this.hasEditPermission() || this.hasSubmitPermission();
  }

  initControl() {
    this.shelfForm = this.fb.group({});
  }

  onCancel() {
    if (this.shelfForm.touched) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.status === NewRequestStatusEnum.DRAFT) {
      const fixAssetBarcode = this.shelfForm.getRawValue().fixAssetItem.map(data => data.barcode);

      this.itemValidator(fixAssetBarcode);
    } else if (this.status === NewRequestStatusEnum.ACTIVE) {
      const errorMessageFixAsset = this.getFormGroupErrorMessage();

      if (errorMessageFixAsset) {
        this.showModalError(errorMessageFixAsset);
      } else if (this.fixAsset.form.invalid) {
        this.validateError(this.fixAsset);
      } else if (this.shelfForm.valid) {
        this.handleConfirm();
      }
    }
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.submitData();
        }
      });
  }

  submitData() {
    const reqData = this.prepareRequestData();

    if (this.status === NewRequestStatusEnum.ACTIVE) {
      this.store.dispatch(new SubmitShelfFixAssetFirstLotRequestAction(reqData));
    } else {
      this.store.dispatch(new ShelfFixAssetCreateSubmitRequestAction(reqData));
      this.listSearchCriteria.page = 0;
    }
  }

  itemValidator(fixAssetBarcode: string[]) {
    const fixAssetList$: Observable<BarcodeResponse[]> = this.barcodeValidator(
      fixAssetBarcode,
      ProductTypeEnum.FIX_ASSET
    );

    fixAssetList$
      .pipe(
        tap(fixAssetList => {
          this.updateFormGroup(fixAssetList);
        }),
        filter(() => this.submitted)
      )
      .subscribe(() => {
        const errorMessageFixAsset = this.getFormGroupErrorMessage();

        if (errorMessageFixAsset) {
          this.showModalError(errorMessageFixAsset);
        } else if (this.fixAsset.form.invalid) {
          this.validateError(this.fixAsset);
        } else if (this.shelfForm.valid) {
          this.handleConfirm();
        }
      });
  }

  validateError(form) {
    const invalidIndex = form.formControls.findIndex(item => item.invalid);
    form.paging.navigateToErrorIndex(invalidIndex);

    const error = form.formControls.find(item => !!item.errors);
    if (error) {
      this.showModalError('Please delete invalid data before submit.');
    }
  }

  barcodeValidator(barcodes: string[], productType: ProductTypeEnum) {
    const barcodeList = this.barcodeService.searchBarcodeByCriteria(
      barcodes,
      new BarcodeListSearchCriteria({
        allowRestrictItem: true,
        allowProductType: productType,
        size: barcodes.length
      })
    );

    return barcodes.length ? barcodeList : of([]);
  }

  updateFormGroup(barcode: BarcodeResponse[]) {
    if (!barcode.length) {
      return;
    }

    let formGroup;
    formGroup = this.shelfForm.get('fixAssetItem');

    let mappingBarcodeResponse;
    mappingBarcodeResponse = NonMerchandiseItem.mappingBarcodeResponseToNonMerchandiseItem;

    barcode.forEach((data, i) => {
      formGroup.controls[i].patchValue({
        ...mappingBarcodeResponse(data),
        ...formGroup.getRawValue()[i]
      });

      if (formGroup.controls[i].get('articleNo') && formGroup.controls[i].get('articleNo').value !== data.articleNo) {
        data.errorMessage = RequestProductErrorEnum.INVALID_BARCODE;
      }

      if (data.errorMessage) {
        this.validateForm(formGroup.controls[i], data.errorMessage);
      }
    });
  }

  validateForm(formItem: FormGroup, errorMessage: RequestProductErrorEnum) {
    let childItem;

    const newValidator = this.setValidator(errorMessage);

    switch (errorMessage) {
      case RequestProductErrorEnum.INVALID_BARCODE:
      case RequestProductErrorEnum.INACTIVE_BARCODE:
      case RequestProductErrorEnum.DUPLICATED_BARCODE_FIELD:
        childItem = formItem.get('barcode');
        break;
      case RequestProductErrorEnum.RESTRICT_ITEM:
      case RequestProductErrorEnum.STATUS_IS_DELISTED:
      case RequestProductErrorEnum.NOT_ALLOW_FIX_ASSET:
      case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
        childItem = formItem.get('productName');
        break;
      default:
        this.logger.error(`ErrorMessage: ${errorMessage} did not supported in barcode and productName.`);
        return;
    }

    childItem.setValidators(newValidator);
    childItem.updateValueAndValidity();

    formItem.setValidators(newValidator);
    formItem.updateValueAndValidity();
  }

  setValidator(errorMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      switch (errorMessage) {
        case RequestProductErrorEnum.INVALID_BARCODE:
          return { invalidBarcode: true };
        case RequestProductErrorEnum.INACTIVE_BARCODE:
          return { isInactive: true };
        case RequestProductErrorEnum.DUPLICATED_BARCODE_FIELD:
          return { duplicated: true };
        case RequestProductErrorEnum.RESTRICT_ITEM:
          return { isRestrictItem: true };
        case RequestProductErrorEnum.STATUS_IS_DELISTED:
          return { isStatusDelisted: true };
        case RequestProductErrorEnum.NOT_ALLOW_FIX_ASSET:
          return { isFixAssetItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
          return { isStoreUseItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_ZERO:
          return control.value !== null && control.value === 0 ? { isZero: true } : null;
        default:
          return null;
      }
    };
  }

  getFormGroupErrorMessage(): string | undefined {
    const formGroup = this.shelfForm.get('fixAssetItem') as FormArray;

    if (!formGroup.controls.length) {
      this.shelfForm.setErrors({ requiredItem: true });
      return 'Please select at least one item before submit.';
    }
  }

  showModalError(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message
      }
    });
  }

  prepareRequestData() {
    const formData = this.shelfForm.getRawValue();
    const template = new ShelfFixAssetCreateRequest();
    template.status = NewRequestStatusEnum.DRAFT;

    this.shelfFixAssetView$
      .pipe(
        untilComponentDestroyed(this),
        filter(value => Boolean(value))
      )
      .subscribe((value: ShelfFixAssetViewResponse) => {
        template.shelfNo = value.shelfNo;
        template.version = value.version;
        template.type = value.type;
        template.shelfCode = value.shelfCode;
      });

    template.shelfInfo = {
      ...formData.shelfInfo,
      shelfName: formData.shelfInfo.shelfName.trim(),
      shelfImage: getFileId(formData.shelfInfo.shelfImage)
    };

    template.items = formData.fixAssetItem;

    return template;
  }

  checkAllowToEdit(callback) {
    this.shelfFixAssetRequestService
      .getShelfValidate(TDStoreValidatorTypeEnum.REQUESTED, this.data.shelfNo)
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        () => {
          callback();
        },
        error => {
          const initialState = {
            title: 'Alert',
            message: this.translate.instant('ERROR_CODE.' + error.error.code)
          };
          this.modalService.show(AlertModalComponent, {
            initialState
          });
        }
      );
  }

  onTriggerEdit() {
    this.checkAllowToEdit(() => {
      const fixAssetBarcode = this.shelfForm.getRawValue().fixAssetItem.map(data => data.barcode);

      this.data.mode = RequestPageModesEnum.REQUEST_EDIT;
      this.data.title = 'Edit Shelf Fix Asset';
      this.status = NewRequestStatusEnum.DRAFT;
      this.store.dispatch(
        new EditShelfFixAssetAction({ type: NewRequestTypeEnum.EDIT, status: NewRequestStatusEnum.DRAFT })
      );
      this.initialAlertMessage();
      this.itemValidator(fixAssetBarcode);
    });
  }

  initialAlertMessage() {
    this.localStore
      .pipe(
        select(selectShelfFixAsset),
        filter(value => Boolean(value.result))
      )
      .subscribe((value: ShelfFixAssetCreateResponseState) => {
        const result = value.result;

        if (result.response) {
          this.alertSuccessModal(this.getSuccessMessage());
        } else {
          this.alertErrorModal(result.errorResponse);
        }
      });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.modalService.hide(1);
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  getSuccessMessage() {
    return this.status === NewRequestStatusEnum.DRAFT
      ? 'The request has been sent to approver.'
      : 'Min First Lot and Min Beauty Quantity are updated.';
  }

  onActivateShelf(toActivate) {
    const messages = this.getActivatedMessage(toActivate);
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: messages.confirmMessage,
        okText: 'Submit',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.shelfFixAssetService
            .activateShelfFixAsset({ shelfNo: this.data.shelfNo }, toActivate)
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal(messages.successMessage);
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  getActivatedMessage(toActivate) {
    return {
      confirmMessage: !toActivate ? 'Are you sure you want to deactivate?' : 'Are you sure you want to activate?',
      successMessage: !toActivate ? 'The shelf has been deactivated.' : 'The shelf has been activated.'
    };
  }

  doAfterVersionAlertModal() {}

  doAfterSuccessModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }
}
