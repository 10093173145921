import { Action } from '@ngrx/store';
import { OrderSelectItemSearchCriteria } from '../../models/order-request.model';

export enum OrderSelectItemActionTypes {
  ORDER_SELECT_ITEM_LIST_REQUEST = '[Order Select Item] Order Select Item List Request',
  ORDER_SELECT_ITEM_LIST_RESPONSE = '[Order Select Item] Order Select Item List Response',
  ORDER_SELECT_ITEM_LIST_RESET = '[Order Select Item] Order Select Item List Reset',
  FIRST_LOT_ORDER_SELECT_ITEM_LIST_REQUEST = '[First Order Select Item] First Order Select Item List Request',
  FIRST_LOT_ORDER_SELECT_ITEM_LIST_RESPONSE = '[First Order Select Item] First Order Select Item List Response',
  FIRST_LOT_ORDER_SELECT_ITEM_LIST_RESET = '[First Order Select Item] First Order Select Item List Reset',
  STORE_USE_SELECT_ITEM_LIST_REQUEST = '[Store Use Select Item] Store Use Select Item List Request',
  STORE_USE_SELECT_ITEM_LIST_RESPONSE = '[Store Use Select Item] Store Use Select Item List Response',
  STORE_USE_SELECT_ITEM_LIST_RESET = '[Store Use Select Item] Store Use Select Item List Reset',
  SELECTIVE_SELECT_ITEM_LIST_REQUEST = '[Selective Select Item] Selective Select Item List Request',
  SELECTIVE_SELECT_ITEM_LIST_RESPONSE = '[Selective Select Item] Selective Select Item List Response',
  SELECTIVE_SELECT_ITEM_LIST_RESET = '[Selective Select Item] Selective Select Item List Reset'
}

export class SelectiveSelectItemListRequest implements Action {
  readonly type = OrderSelectItemActionTypes.SELECTIVE_SELECT_ITEM_LIST_REQUEST;

  constructor(public payload: OrderSelectItemSearchCriteria) {}
}

export class SelectiveSelectItemListResponse implements Action {
  readonly type = OrderSelectItemActionTypes.SELECTIVE_SELECT_ITEM_LIST_RESPONSE;

  constructor(public payload: any) {}
}

export class SelectiveSelectItemListReset implements Action {
  readonly type = OrderSelectItemActionTypes.SELECTIVE_SELECT_ITEM_LIST_RESET;

  constructor() {}
}

export class StoreUseSelectItemListRequest implements Action {
  readonly type = OrderSelectItemActionTypes.STORE_USE_SELECT_ITEM_LIST_REQUEST;

  constructor(public payload: OrderSelectItemSearchCriteria) {}
}

export class StoreUseSelectItemListResponse implements Action {
  readonly type = OrderSelectItemActionTypes.STORE_USE_SELECT_ITEM_LIST_RESPONSE;

  constructor(public payload: any) {}
}

export class StoreUseSelectItemListReset implements Action {
  readonly type = OrderSelectItemActionTypes.STORE_USE_SELECT_ITEM_LIST_RESET;

  constructor() {}
}
export class FirstLotOrderSelectItemListRequest implements Action {
  readonly type = OrderSelectItemActionTypes.FIRST_LOT_ORDER_SELECT_ITEM_LIST_REQUEST;

  constructor(public payload: OrderSelectItemSearchCriteria) {}
}

export class FirstLotOrderSelectItemListResponse implements Action {
  readonly type = OrderSelectItemActionTypes.FIRST_LOT_ORDER_SELECT_ITEM_LIST_RESPONSE;

  constructor(public payload: any) {}
}

export class FirstLotOrderSelectItemListReset implements Action {
  readonly type = OrderSelectItemActionTypes.FIRST_LOT_ORDER_SELECT_ITEM_LIST_RESET;

  constructor() {}
}

export class OrderSelectItemListRequest implements Action {
  readonly type = OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_REQUEST;

  constructor(public payload: OrderSelectItemSearchCriteria) {}
}

export class OrderSelectItemListResponse implements Action {
  readonly type = OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_RESPONSE;

  constructor(public payload: any) {}
}

export class OrderSelectItemListReset implements Action {
  readonly type = OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_RESET;

  constructor() {}
}

export type OrderSelectItemActions =
  | OrderSelectItemListRequest
  | OrderSelectItemListResponse
  | OrderSelectItemListReset
  | FirstLotOrderSelectItemListRequest
  | FirstLotOrderSelectItemListResponse
  | FirstLotOrderSelectItemListReset
  | StoreUseSelectItemListRequest
  | StoreUseSelectItemListResponse
  | StoreUseSelectItemListReset
  | SelectiveSelectItemListRequest
  | SelectiveSelectItemListResponse
  | SelectiveSelectItemListReset;
