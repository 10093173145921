import { BaseReport } from './base-report.model';
import { Image } from './image.model';
import { ProductStatusEnum } from './request-assortment.model';

export class StockInformationSearchCriteria {
  searchCriteria?: string;
  warehouse?: string;
  locationType?: string;
  productStatus?: string;
  segment?: string;
  family?: string;
  classCode?: string;
  subClass?: string;
  productType?: string;
  deliveryMethod?: string;
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
}

export class StockInformationListPagination {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: StockInformationContent[];
}

export class StockInformationContent {
  id: string;
  productName: string;
  articleNo: string;
  segmentName: string;
  familyName: string;
  className: string;
  subClassName: string;
  productStatus: ProductStatusEnum;
  totalStock: string;
  articleType: string;
  productType: string;
  deliveryMethod: string;
  productImages: Image[];
}

export class StockInformationDto {
  id: string;
  productType: string;
  location: string;
  articleNo: string;
  productName: string;
  productStatus: string;
  warehouse?: number;
  hold?: number;
  reserved?: number;
  inTransit?: number;
  store?: number;
  toBeAdjusted?: number;
  quantity?: number;
  warehouseStock?: number;
  storeStock?: number;
}

export class StockMovementSearchCriteria {
  movementDateFrom?: string;
  movementDateTo?: string;
  location?: string[];
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
}

export class StockMovementPagination {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: StockMovementContent[];
}

export class StockMovementContent {
  id: string;
  barcode: string;
  unit: string;
  unitFactor: number;
  quantity: number;
  refNo: string;
  location: string;
  type: string;
  movementDate: string;
  createdByName: string;
  originalOnHandStock: number;
  adjust: number;
  currentOnHandStock: number;
  docNo: string;
  adjustType: string;
}

export class StockMovementDetail {
  warehouse: number;
  store: number;
  adjust: number;
  existingStock: number;
}

export class StockStoreSearchCriteria {
  searchCriteria?: string;
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
  locationType?: string;
}

export class StockStorePagination {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: StockStoreContent[];
}

export class StockWarehouseSearchCriteria {
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
}

export class StockWarehousePagination {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: StockWarehouseContent[];
}

export interface StockStoreContent {
  id: string;
  storeNo: string;
  storeCode: string;
  storeName: string;
  totalStock: number;
  toBeAdjusted: number;
  storeAvailable: number;
  lastModifiedDate: string;
}

export enum StockInformationTabsEnum {
  MOVEMENT = 'MOVEMENT',
  STORE = 'STORE',
  WAREHOUSE = 'WAREHOUSE'
}

export class ExportStockByProductCriteria extends BaseReport {
  location?: string;
  locationType?: string;
  productStatus?: string;
  segment?: string;
  family?: string;
  classCode?: string;
  subClass?: string;
  productType?: string;

  constructor(init?: Partial<ExportStockByProductCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ExportStockByLocationCriteria extends BaseReport {
  productStatus?: string[];
  productType?: string;
  location?: string;
  searchType?: string;

  constructor(init?: Partial<ExportStockByLocationCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export interface StockLocationItem {
  displayName: string;
  locationCode: string;
  stockLocation: StockLocation;
}

export enum StockLocation {
  ALL_LOCATIONS = 'ALL_LOCATIONS',
  ALL_STORES = 'ALL_STORES',
  DC = 'DC'
}

export enum StockInfoType {
  WAREHOUSE_AVAILABLE = 'Warehouse-Available',
  HOLD = 'Hold',
  RESERVED = 'Reserved',
  TOTAL_STOCK = 'Total Stock',
  ON_DELIVERY = 'In Transit',
  STORE_AVAILABLE = 'Store-Available',
  TO_BE_ADJUSTED = 'To be adjusted',
  WAREHOUSE_STOCK = 'Warehouse Stock',
  STORE_STOCK = 'Store Stock'
}

export interface StockWarehouseContent {
  id: string;
  articleNo: string;
  wmsCode: string;
  warehouseName: string;
  warehouse: string;
  hold: number;
  reserved: number;
  total: number;
  inTransit: number;
  lastModifiedDate: string;
}
