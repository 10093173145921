import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseSearchComponent } from '../../../../base/base-search.component';
import { HistoryComponent } from '../../../../shared/components/history/history.component';
import { ApproveStatusEnum } from '../../../../shared/enum/approve-status.enum';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { NewRequestStatusEnum } from '../../../../shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestTypeEnum } from '../../../../shared/enum/request-step.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ConfirmModalComponent } from '../../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../../shared/layouts/modals/full-modal/full-modal.component';
import { ErrorResponse, PortalModule, RouteLinkTab, TaskModuleUrl } from '../../../../shared/models';
import { AuditLog, HistoryType } from '../../../../shared/models/audit-log.model';
import * as filterDropdown from '../../../../shared/models/list-value/list-key-value.model';
import {
  ShelfFixAssetRequestList,
  ShelfFixAssetRequestListSearchCriteria
} from '../../../../shared/models/shelf-request.model';
import { AuthGuardService } from '../../../../shared/services';
import { ShelfFixAssetRequestService } from '../../../../shared/services/shelf-fix-asset-request.service';
import {
  ShelfFixAssetDeleteRequestAction,
  ShelfFixAssetDeleteResetAction,
  ShelfFixAssetRequestListHistoryRequestAction,
  ShelfFixAssetRequestListHistoryResponseAction,
  ShelfFixAssetRequestListRequestAction
} from '../../../../shared/store/actions/shelf-request.actions';
import { ShelfFixAssetRequestState } from '../../../../shared/store/reducers/shelf-fix-asset-request.reducers';
import {
  selectAllShelfRequestList,
  selectShelfDeleteStatus,
  selectShelfRequestHistory,
  selectShelfRequestList,
  selectShelfRequestListCriteria
} from '../../../../shared/store/selectors/shelf-fix-asset-request.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../../shared/utils/date-util';
import { ModuleUtil } from '../../../../shared/utils/module-util';
import { ShelfFixAssetRequestComponent } from '../shelf-fix-asset-request/shelf-fix-asset-request.component';

@Component({
  selector: 'app-shelf-fix-asset-request-list',
  templateUrl: './shelf-fix-asset-request-list.component.html',
  styleUrls: ['./shelf-fix-asset-request-list.component.scss']
})
export class ShelfFixAssetRequestListComponent extends BaseSearchComponent<
  ShelfFixAssetRequestListSearchCriteria,
  ShelfFixAssetRequestList,
  ShelfFixAssetRequestState
> {
  private bsModalRef: BsModalRef;
  private localStore: Observable<any>;

  public listRoute: Array<RouteLinkTab>;

  public minDate: Date;
  public maxDate: Date;
  public dateTag: string;
  public dateStringTag: string;
  public requestTypeTag: string;
  public requestTypeStringTag: string;
  public type: RequestTypeEnum;

  public shelfStatusList = filterDropdown.shelfFixAssetRequestStatusFilter;
  public requestTypeList = filterDropdown.newRequestTypeFilter;

  public auditLogs$: Observable<AuditLog[]>;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    protected readonly shelfRequestService: ShelfFixAssetRequestService
  ) {
    super(store, modalService, selectAllShelfRequestList, selectShelfRequestList);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectShelfRequestListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
    this.localStore.pipe(select(selectShelfDeleteStatus)).subscribe(isDeleteSuccess => {
      if (isDeleteSuccess) {
        this.alertSuccessModal('The request has been deleted.');
        this.doSearch(this.criteriaObject);
        this.store.dispatch(new ShelfFixAssetDeleteResetAction());
      }
    });

    this.auditLogs$ = this.localStore.pipe(select(selectShelfRequestHistory));
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.shelfStatusList[0].value],
      requestedDateFrom: [null],
      requestedDateTo: [null],
      requestType: [null]
    });
  }

  onchangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  clearFilterDate() {
    this.setFirstPage();
    this.searchForm.controls['requestedDateFrom'].reset();
    this.searchForm.controls['requestedDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      requestedDateFrom: null,
      requestedDateTo: null
    };
    this.search(this.criteriaObject);
  }

  clearFilterRequestType() {
    this.setFirstPage();
    this.searchForm.controls['requestType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      requestType: null
    };
    this.search(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['requestedDateFrom'].reset();
    this.searchForm.controls['requestedDateTo'].reset();
    this.searchForm.controls['requestType'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      requestedDateFrom: null,
      requestedDateTo: null,
      requestType: null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = value;
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = value;
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (!formValue.requestedDateFrom && !formValue.requestedDateTo && !formValue.requestType) {
      return;
    }

    let dateFrom = this.searchForm.value.requestedDateFrom;
    let dateTo = this.searchForm.value.requestedDateTo;

    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      requestedDateFrom: dateFrom,
      requestedDateTo: dateTo,
      requestType:
        formValue.requestType && formValue.requestType.length > 0 ? formValue.requestType.toString() : null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;
    this.requestTypeTag = null;
    this.requestTypeStringTag = null;

    const requestedDateFrom = dateStringToTagCriteria(this.criteriaObject.requestedDateFrom);
    const requestedDateTo = dateStringToTagCriteria(this.criteriaObject.requestedDateTo);
    const requestedDate = generateDateStringTag({
      dateName: 'Requested Date',
      dateFrom: requestedDateFrom,
      dateTo: requestedDateTo
    });

    this.dateStringTag = requestedDate.dateStringTag;
    this.dateTag = requestedDate.dateTag;

    if (this.criteriaObject.requestType && this.criteriaObject.requestType.length > 0) {
      this.requestTypeStringTag = 'Request Type';

      const types = this.requestTypeList
        .filter(data => {
          return this.criteriaObject.requestType.indexOf(data.value) > -1;
        })
        .map(status => status.label)
        .join(', ');

      this.requestTypeTag = `"${types}"`;
    }
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new ShelfFixAssetRequestListRequestAction(criteriaObj));
  }

  goToCreate() {
    const mode = RequestPageModesEnum.REQUEST_CREATE;
    const initialState = {
      title: null,
      childItem: new ChildItem(
        ShelfFixAssetRequestComponent,
        {
          title: ShelfFixAssetRequestListComponent.getShelfRequestTitle(mode),
          mode,
          requestId: null
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  goToView(item) {
    const mode = RequestPageModesEnum.REQUEST_VIEW;
    const initialState = ModuleUtil.InitialState(
      item,
      ShelfFixAssetRequestComponent,
      mode,
      ShelfFixAssetRequestListComponent.getShelfRequestTitle(mode),
      TaskModuleUrl.SHELF_FIX_ASSET_REQUEST,
      PortalModule.SHELF_FIX_ASSET_REQUEST
    );

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  goToEdit(item) {
    const mode = RequestPageModesEnum.REQUEST_EDIT;
    const initialState = {
      title: null,
      childItem: new ChildItem(
        ShelfFixAssetRequestComponent,
        {
          title: ShelfFixAssetRequestListComponent.getShelfRequestTitle(mode),
          mode,
          requestId: item.id
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission(['sh_ast_v', 'sh_ast_m']);
    const hasRequestPagePermission = this.authGuardService.checkPermission(['sh_ast_rq_v', 'sh_ast_m', 'sh_ast_app']);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Shelf Fix Asset List', url: '/shelf/shelf-fix-asset-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Shelf Fix Asset Request', url: '/shelf/shelf-fix-asset-request-list' });
    }
  }

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
  }

  cancelShelfRequest(requestNo: string) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number <strong>&quot;${requestNo}&quot;</strong>?`,
        label: 'Reason',
        okText: 'Yes, cancel',
        cancelText: 'Cancel',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.shelfRequestService
            .approveRequest({
              requestNo,
              status: ApproveStatusEnum.CANCELLED,
              comment: confirmModalRef.content.confirmMessage
            })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been cancelled.');
                this.doSearch(this.criteriaObject);
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  deleteShelfRequest(id) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        okText: 'Yes, delete',
        cancelText: 'Cancel',
        message: 'Are you sure you want to delete this request?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new ShelfFixAssetDeleteRequestAction({ requestId: id }));
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.store.dispatch(new ShelfFixAssetRequestListRequestAction(this.criteriaObject));
      if (alertModal.content.actions) {
        alertModal.content.actions.unsubscribe();
      }
    });
  }

  showHistory(requestId: string, requestNo: string) {
    this.store.dispatch(new ShelfFixAssetRequestListHistoryRequestAction({ requestId }));

    const initialState = {
      title: 'History',
      historyHeader: `Request No.: ${requestNo}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.SHELF_REQUEST,
      auditLogs$: this.auditLogs$
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new ShelfFixAssetRequestListHistoryResponseAction({ auditLogs: null }));
    });
  }

  getColorStatus(status: string): string {
    return status && status.toLocaleLowerCase();
  }

  hasEditPermission(status: NewRequestStatusEnum): boolean {
    return (
      [NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(status) &&
      this.authGuardService.checkPermission(['sh_ast_m'])
    );
  }

  hasDeletePermission(): boolean {
    return this.authGuardService.checkPermission(['sh_ast_m']);
  }

  hasCancelPermission(): boolean {
    return this.authGuardService.checkPermission(['sh_ast_m']);
  }

  hasViewHistoryPermission(): boolean {
    return true;
  }

  getShelfNo(shelfType: string, shelfNo: string, status: string): string {
    if (shelfType === 'EDIT' || status === NewRequestStatusEnum.APPROVED) {
      return shelfNo;
    } else {
      return (shelfNo = '(None)');
    }
  }

  static getShelfRequestTitle(mode: RequestPageModesEnum) {
    let title: string;
    switch (mode) {
      case RequestPageModesEnum.REQUEST_CREATE:
        title = 'Create Shelf Fix Asset Request';
        break;
      case RequestPageModesEnum.REQUEST_VIEW:
        title = 'View Shelf Fix Asset Request';
        break;
      case RequestPageModesEnum.REQUEST_EDIT:
        title = 'Edit Shelf Fix Asset Request';
        break;
      default:
    }
    return title;
  }
}
