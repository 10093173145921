import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PurchaseConditionRequestService } from '../../services/purchase-condition-request.service';
import { PurchaseConditionService } from '../../services/purchase-condition.service';
import {
  LayoutActionLoadError,
  LayoutActionSaveSuccess,
  LayoutForceCloseCurrentModal
} from '../actions/layout.action';
import {
  CancelPurchaseConditionRequest,
  DeletePurchaseConditionRequest,
  ItemConditionListLoaded,
  ItemConditionListRequested,
  PurchaseConditionActionTypes,
  PurchaseConditionContractAndDcUpdateErrorAction,
  PurchaseConditionContractLoaded,
  PurchaseConditionContractRequested,
  PurchaseConditionDcLoaded,
  PurchaseConditionDcRequested,
  PurchaseConditionDiscountRequested,
  PurchaseConditionListSearchLoaded,
  PurchaseConditionListSearchRequested,
  PurchaseConditionRequestApprove,
  PurchaseConditionRequestApproveRejectSuccess,
  PurchaseConditionRequestListLoaded,
  PurchaseConditionRequestListSearchRequested,
  PurchaseConditionRequestReject,
  PurchaseConditionRequestSave,
  PurchaseConditionRequestUpdateSaveById,
  PurchaseConditionRequestViewLoaded,
  PurchaseConditionRequestViewRequested,
  PurchaseConditionRequestWeeklyScheduleImportRequested,
  PurchaseConditionRequestWeeklyScheduleLoaded,
  PurchaseConditionSupplierAddContract,
  PurchaseConditionSupplierAddDc,
  PurchaseConditionSupplierDeletedContract,
  PurchaseConditionSupplierDeletedDc,
  PurchaseConditionSupplierUpdatedContract,
  PurchaseConditionSupplierUpdatedDc,
  PurchaseConditionViewLoaded,
  PurchaseConditionViewRequested,
  PurchaseConditionWeeklyScheduleRequested
} from '../actions/purchase-condition.actions';

@Injectable()
export class PurchaseConditionEffects {
  constructor(
    private actions$: Actions,
    private purchaseConditionRequestService: PurchaseConditionRequestService,
    private purchaseConditionService: PurchaseConditionService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchPurchaseConditionRequest$ = this.actions$.pipe(
    ofType<PurchaseConditionRequestListSearchRequested>(
      PurchaseConditionActionTypes.PurchaseConditionRequestListSearchRequested
    ),
    tap(action =>
      this.logger.debug(`@Effect Purchase Condition Request List Requested:` + JSON.stringify(action.payload))
    ),
    switchMap(action =>
      this.purchaseConditionRequestService.searchByCriteria(action.payload).pipe(
        map(response => new PurchaseConditionRequestListLoaded(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  getItemConditionList$ = this.actions$.pipe(
    ofType<ItemConditionListRequested>(PurchaseConditionActionTypes.ItemConditionListRequested),
    tap(action => this.logger.debug(`@Effect Item Condition List Requested:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionRequestService.getItemConditionList(action.payload).pipe(
        map(response => new ItemConditionListLoaded(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  getPurchaseConditionRequestWeeklyScheduleImportList$ = this.actions$.pipe(
    ofType<PurchaseConditionRequestWeeklyScheduleImportRequested>(
      PurchaseConditionActionTypes.PurchaseConditionRequestWeeklyScheduleImportRequested
    ),
    tap(action =>
      this.logger.debug(
        `@Effect Purchase Condition Request Weekly Schedule Import  List Requested:` + JSON.stringify(action.payload)
      )
    ),
    switchMap(action =>
      this.purchaseConditionRequestService.getPurchaseConditionRequestWeeklyScheduleImportList(action.payload).pipe(
        map(response => {
          response.content.forEach((res, index) => {
            res.rowId = index + 1;
          });
          return new PurchaseConditionRequestWeeklyScheduleLoaded(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  purchaseConditionSave$ = this.actions$.pipe(
    ofType<PurchaseConditionRequestSave>(PurchaseConditionActionTypes.PurchaseConditionRequestSave),
    map(action => {
      this.logger.debug(`@Effect Purchase Condition Request Save: ` + JSON.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.purchaseConditionRequestService.save(payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The data have been saved.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  purchaseConditionUpdateSaveById$ = this.actions$.pipe(
    ofType<PurchaseConditionRequestUpdateSaveById>(
      PurchaseConditionActionTypes.PurchaseConditionRequestUpdateSaveById
    ),
    map(action => {
      this.logger.debug(`@Effect Purchase Condition Request Update Save By Id: ` + JSON.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.purchaseConditionRequestService.edit(payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The data have been saved.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getPurchaseConditionList$ = this.actions$.pipe(
    ofType<PurchaseConditionListSearchRequested>(PurchaseConditionActionTypes.PurchaseConditionListSearchRequested),
    tap(action => this.logger.debug(`@Effect Purchase Condition List Requested:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionService.searchByCriteria(action.payload).pipe(
        map(response => new PurchaseConditionListSearchLoaded(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  purchaseConditionSubmit$ = this.actions$.pipe(
    ofType<PurchaseConditionRequestSave>(PurchaseConditionActionTypes.PurchaseConditionRequestSubmit),
    map(action => {
      this.logger.debug(`@Effect Purchase Condition Request Submit: ` + JSON.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.purchaseConditionRequestService.submit(payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been sent to approver.',
              routerLink: '/purchase/purchase-condition/purchase-condition-request-list'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error, true, true)))
      );
    })
  );

  @Effect()
  approveRequestPurchaseCondition$ = this.actions$.pipe(
    ofType<PurchaseConditionRequestApprove>(PurchaseConditionActionTypes.PurchaseConditionRequestApprove),
    map(action => {
      this.logger.debug(`@Effect Purchase Condition Request Approve: ` + JSON.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.purchaseConditionRequestService.approve(payload).pipe(
        map(() => new PurchaseConditionRequestApproveRejectSuccess({ isSuccess: true })),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  rejectRequestPurchaseCondition$ = this.actions$.pipe(
    ofType<PurchaseConditionRequestReject>(PurchaseConditionActionTypes.PurchaseConditionRequestReject),
    map(action => {
      this.logger.debug(`@Effect Purchase Condition Request Approve: ` + JSON.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.purchaseConditionRequestService.reject(payload).pipe(
        map(() => new PurchaseConditionRequestApproveRejectSuccess({ isSuccess: true })),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getPurchaseConditionRequestView = this.actions$.pipe(
    ofType<PurchaseConditionRequestViewRequested>(PurchaseConditionActionTypes.PurchaseConditionRequestViewRequested),
    tap(action => this.logger.debug(`@Effect Purchase Condition view Requested:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionRequestService.getPurchaseConditionById(action.payload).pipe(
        map(response => new PurchaseConditionRequestViewLoaded(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  getPurchaseConditionView = this.actions$.pipe(
    ofType<PurchaseConditionViewRequested>(PurchaseConditionActionTypes.PurchaseConditionViewRequested),
    tap(action => this.logger.debug(`@Effect Purchase Condition view Requested:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionService.getPurchaseConditionViewRequested(action.payload).pipe(
        map(response => {
          return new PurchaseConditionViewLoaded(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  getPurchaseConditionDcRequested = this.actions$.pipe(
    ofType<PurchaseConditionDcRequested>(PurchaseConditionActionTypes.PurchaseConditionDcRequested),
    tap(action => this.logger.debug(`@Effect Purchase Condition Dc Requested:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionService.getPurchaseConditionSupplierDcRequested(action.payload).pipe(
        map(response => new PurchaseConditionDcLoaded({ supplierDistributionCenters: response })),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  getPurchaseConditionContractRequested = this.actions$.pipe(
    ofType<PurchaseConditionContractRequested>(PurchaseConditionActionTypes.PurchaseConditionContractRequested),
    tap(action =>
      this.logger.debug(`@Effect Purchase Condition Contract Requested:` + JSON.stringify(action.payload))
    ),
    switchMap(action =>
      this.purchaseConditionService.getPurchaseConditionSupplierContactRequested(action.payload).pipe(
        map(response => new PurchaseConditionContractLoaded({ supplierContacts: response })),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  getPurchaseConditionDiscountRequested = this.actions$.pipe(
    ofType<PurchaseConditionDiscountRequested>(PurchaseConditionActionTypes.PurchaseConditionDiscountRequested),
    tap(action => this.logger.debug(`@Effect Purchase Condition view Requested:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionService.getPurchaseConditionDiscountRequested(action.payload).pipe(
        map(response => new ItemConditionListLoaded(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  purchaseConditionEditSubmit$ = this.actions$.pipe(
    ofType<PurchaseConditionRequestSave>(PurchaseConditionActionTypes.PurchaseConditionRequestEditSubmitRequested),
    map(action => {
      this.logger.debug(`@Effect Purchase Condition Request Edit Submit: ` + JSON.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.purchaseConditionRequestService.edit(payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been sent to approver.',
              routerLink: '/purchase/purchase-condition/purchase-condition-request-list'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error, true, true)))
      );
    })
  );

  @Effect()
  getPurchaseConditionWeeklyScheduleRequested = this.actions$.pipe(
    ofType<PurchaseConditionWeeklyScheduleRequested>(
      PurchaseConditionActionTypes.PurchaseConditionWeeklyScheduleRequested
    ),
    tap(action => this.logger.debug(`@Effect Purchase Condition view Requested:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionService.getPurchaseConditionWeeklyScheduleRequested(action.payload).pipe(
        map(response => {
          response.content.forEach((res, index) => {
            res.rowId = index + 1;
          });
          return new PurchaseConditionRequestWeeklyScheduleLoaded(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  addPurchaseConditionSupplierAddContract = this.actions$.pipe(
    ofType<PurchaseConditionSupplierAddContract>(PurchaseConditionActionTypes.PurchaseConditionSupplierAddContract),
    tap(action => this.logger.debug(`@Effect Purchase Condition add Contract:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionService.addPurchaseConditionSupplierContract(action.payload).pipe(
        switchMap(_ => {
          return this.purchaseConditionService.getPurchaseConditionSupplierContactRequested({
            supplierCode: action.payload.supplierCode
          });
        }),
        switchMap(response => [
          new PurchaseConditionContractLoaded({
            supplierContacts: response,
            appUpdateContactAndDCSuccess: {
              isSuccess: true,
              title: 'Success',
              message: 'Contact Information has been added.'
            }
          }),
          new PurchaseConditionContractAndDcUpdateErrorAction(null)
        ]),
        catchError(err =>
          err.error && err.error.code === '08031'
            ? of(new PurchaseConditionContractAndDcUpdateErrorAction({ ...err.error }))
            : of(new LayoutActionLoadError(err, true))
        )
      )
    )
  );

  @Effect()
  editPurchaseConditionSupplierUpdatedContract$ = this.actions$.pipe(
    ofType<PurchaseConditionSupplierUpdatedContract>(
      PurchaseConditionActionTypes.PurchaseConditionSupplierUpdatedContract
    ),
    tap(action => this.logger.debug(`@Effect Purchase Condition Updated Contract:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionService.editPurchaseConditionSupplierContract(action.payload).pipe(
        switchMap(_ => {
          return this.purchaseConditionService.getPurchaseConditionSupplierContactRequested({
            supplierCode: action.payload.supplierCode
          });
        }),
        switchMap(response => [
          new PurchaseConditionContractLoaded({
            supplierContacts: response,
            appUpdateContactAndDCSuccess: {
              isSuccess: true,
              title: 'Success',
              message: 'Contact Information has been updated.'
            }
          }),
          new PurchaseConditionContractAndDcUpdateErrorAction(null)
        ]),
        catchError(err =>
          (err.error && err.error.code === '08031') || err.error.code === '00001'
            ? of(new PurchaseConditionContractAndDcUpdateErrorAction({ ...err.error }))
            : of(new LayoutActionLoadError(err, true))
        )
      )
    )
  );

  @Effect()
  deletePurchaseConditionSupplierContract$ = this.actions$.pipe(
    ofType<PurchaseConditionSupplierDeletedContract>(
      PurchaseConditionActionTypes.PurchaseConditionSupplierDeletedContract
    ),
    tap(action => this.logger.debug(`@Effect Purchase Condition deleted Contract:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionService.deletePurchaseConditionSupplierContract(action.payload.id).pipe(
        switchMap(_ => {
          return this.purchaseConditionService.getPurchaseConditionSupplierContactRequested({
            supplierCode: action.payload.supplierCode
          });
        }),
        switchMap(response => [
          new PurchaseConditionContractLoaded({
            supplierContacts: response,
            appUpdateContactAndDCSuccess: {
              isSuccess: true,
              title: 'Success',
              message: 'Contact Information has been deleted.'
            }
          })
        ]),
        catchError(err =>
          err.error && err.error.code === '00001'
            ? of(new LayoutForceCloseCurrentModal(true))
            : of(new LayoutActionLoadError(err, true))
        )
      )
    )
  );

  @Effect()
  deletePurchaseConditionRequest$ = this.actions$.pipe(
    ofType<DeletePurchaseConditionRequest>(PurchaseConditionActionTypes.DeletePurchaseConditionRequest),
    tap(action => this.logger.debug(`@Effect Purchase Condition Delete:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionRequestService.deletePurchaseConditionRequest(action.payload.id).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been deleted.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error, true)))
      )
    )
  );

  @Effect()
  addPurchaseConditionSupplierDc = this.actions$.pipe(
    ofType<PurchaseConditionSupplierAddDc>(PurchaseConditionActionTypes.PurchaseConditionSupplierAddDc),
    tap(action => this.logger.debug(`@Effect Purchase Condition add Dc:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionService.addPurchaseConditionSupplierDc(action.payload).pipe(
        switchMap(_ => {
          return this.purchaseConditionService.getPurchaseConditionSupplierDcRequested({
            supplierCode: action.payload.supplierCode
          });
        }),
        switchMap(response => [
          new PurchaseConditionDcLoaded({
            supplierDistributionCenters: response,
            appUpdateContactAndDCSuccess: {
              isSuccess: true,
              title: 'Success',
              message: 'DC Information has been added.'
            }
          }),
          new PurchaseConditionContractAndDcUpdateErrorAction(null)
        ]),
        catchError(err =>
          err.error && err.error.code === '08032'
            ? of(new PurchaseConditionContractAndDcUpdateErrorAction({ ...err.error }))
            : of(new LayoutActionLoadError(err, true))
        )
      )
    )
  );

  @Effect()
  editPurchaseConditionSupplierUpdatedDc$ = this.actions$.pipe(
    ofType<PurchaseConditionSupplierUpdatedDc>(PurchaseConditionActionTypes.PurchaseConditionSupplierUpdatedDc),
    tap(action => this.logger.debug(`@Effect Purchase Condition Updated Dc:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionService.editPurchaseConditionSupplierDc(action.payload).pipe(
        switchMap(_ => {
          return this.purchaseConditionService.getPurchaseConditionSupplierDcRequested({
            supplierCode: action.payload.supplierCode
          });
        }),
        switchMap(response => [
          new PurchaseConditionDcLoaded({
            supplierDistributionCenters: response,
            appUpdateContactAndDCSuccess: {
              isSuccess: true,
              title: 'Success',
              message: 'DC Information has been updated.'
            }
          }),
          new PurchaseConditionContractAndDcUpdateErrorAction(null)
        ]),
        catchError(err =>
          (err.error && err.error.code === '08032') || err.error.code === '00001'
            ? of(new PurchaseConditionContractAndDcUpdateErrorAction({ ...err.error }))
            : of(new LayoutActionLoadError(err, true))
        )
      )
    )
  );

  @Effect()
  deletePurchaseConditionSupplierDc$ = this.actions$.pipe(
    ofType<PurchaseConditionSupplierDeletedDc>(PurchaseConditionActionTypes.PurchaseConditionSupplierDeletedDc),
    tap(action => this.logger.debug(`@Effect Purchase Condition deleted Dc:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionService.deletePurchaseConditionSupplierDc(action.payload.id).pipe(
        switchMap(_ => {
          return this.purchaseConditionService.getPurchaseConditionSupplierDcRequested({
            supplierCode: action.payload.supplierCode
          });
        }),
        switchMap(response => [
          new PurchaseConditionDcLoaded({
            supplierDistributionCenters: response,
            appUpdateContactAndDCSuccess: {
              isSuccess: true,
              title: 'Success',
              message: 'DC Information has been deleted.'
            }
          })
        ]),
        catchError(err =>
          err.error && err.error.code === '00001'
            ? of(new LayoutForceCloseCurrentModal(true))
            : of(new LayoutActionLoadError(err, true))
        )
      )
    )
  );

  @Effect()
  cancelPurchaseConditionRequest$ = this.actions$.pipe(
    ofType<CancelPurchaseConditionRequest>(PurchaseConditionActionTypes.CancelPurchaseConditionRequest),
    tap(action => this.logger.debug(`@Effect Cancel Purchase condition Request:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseConditionRequestService.cancel(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been cancelled.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error, true)))
      )
    )
  );
}
