import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { beforeTodayValidator } from '../../../shared/custom-validators/before-today-validator';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import {
  StockTransferPageModes,
  StockTransferRequestStatusEnum
} from '../../../shared/enum/stock-transfer-request.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { UiPaginationComponent } from '../../../shared/layouts/ui-pagination/ui-pagination.component';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  StockTransfer,
  StockTransferItem,
  StockTransferRequestErrorResponse,
  StockTransferRequestItemFieldChange,
  StockTransferRequestItemFieldError,
  StockTransferRequestItemProduct,
  StockTransferRequestProductError
} from '../../../shared/models/stock-transfer-request.model';
import { AuthGuardService } from '../../../shared/services';
import { StockTransferRequestService } from '../../../shared/services/stock-trasfer-request-service';
import {
  StockProductSelectedAddAllItem,
  StockProductSelectedRemoveItem,
  StockProductSelectedReset
} from '../../../shared/store/actions/stock-product-selected.actions';
import { StockProductListResetAction } from '../../../shared/store/actions/stock-product.actions';
import {
  StockTransferCancelRequestAction,
  StockTransferDeleteRequestAction,
  StockTransferRequestApproveRequest,
  StockTransferRequestByIdRequestAction,
  StockTransferRequestErrorResponseAction,
  StockTransferRequestRejectRequest,
  StockTransferRequestResetActions,
  StockTransferRequestSaveRequestAction,
  StockTransferRequestSubmitRequestAction
} from '../../../shared/store/actions/stock-transfer-request.actions';
import { selectAllStockProductSelected } from '../../../shared/store/selectors/stock-product-selected.selectors';
import {
  selectEditStockTransferRequest,
  selectStockTransferErrorResponse
} from '../../../shared/store/selectors/stock-transfer-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { dateToStringCriteria, getDateFromString } from '../../../shared/utils/date-util';
import { ProductChangeModalComponent } from '../../purchase/product-change-modal/product-change-modal.component';
import { StockProductListComponent } from '../stock-product-list/stock-product-list.component';
import { StockTransferDeliveryDetailModalComponent } from '../stock-transfer-delivery-detail-modal/stock-transfer-delivery-detail-modal.component';
import { StockTransferRequestImportModalComponent } from '../stock-transfer-request-import-modal/stock-transfer-request-import-modal.component';

@Component({
  selector: 'app-stock-transfer-request',
  templateUrl: './stock-transfer-request.component.html',
  styleUrls: ['./stock-transfer-request.component.scss']
})
export class StockTransferRequestComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() notifyLeaveFormOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() data: {
    id: string;
    title: string;
    status: StockTransferRequestStatusEnum;
    mode: StockTransferPageModes;
    shipFrom: any;
    shipTo: any;
  };

  @ViewChild('paging', { static: false }) paging: UiPaginationComponent;

  public localStore: Observable<any>;
  public stockTransferForm: FormGroup;
  public stockTransfer: StockTransfer;
  public isSubmit: boolean;
  public currentPage: number;
  public pageSize: number;

  public stockTransferPageMode = StockTransferPageModes;
  public stockTransferRequestStatusEnum: StockTransferRequestStatusEnum;
  public buttons: Array<ImportExportButton>;
  public dateTimeDisplay = env.dateTimeDisplay;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected readonly translate: TranslateService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected stockTransferRequestService: StockTransferRequestService
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.currentPage = 1;
    this.pageSize = 20;

    this.initialSubscription();
    this.initialStockTransferData();
    this.getButton();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.store.dispatch(new StockProductListResetAction());
    this.store.dispatch(new StockProductSelectedReset());
    this.store.dispatch(new StockTransferRequestResetActions());
    if (this.notifyLeaveFormOutput) {
      this.notifyLeaveFormOutput.unsubscribe();
    }
  }

  getButton() {
    this.buttons = [
      {
        type: ButtonType.IMPORT,
        name: 'Import'
      }
    ];
  }

  initialSubscription() {
    this.localStore
      .pipe(
        select(selectStockTransferErrorResponse),
        filter(data => Boolean(data))
      )
      .subscribe((error: StockTransferRequestErrorResponse) => {
        let isChange = false;
        let isInvalidData = false;
        let isInvalidDate = false;
        if (error && error.data) {
          if (error.data.invalidDelivery || error.data.invalidDeliverySubject) {
            isInvalidDate = true;
            this.setErrorDeliveryDate();
          }

          error.data.items.forEach((errorItemDetail: StockTransferRequestItemProduct) => {
            if (
              (errorItemDetail.errors && errorItemDetail.errors.length) ||
              (errorItemDetail.changes && errorItemDetail.changes.length)
            ) {
              const transferItemFormGroup = this.getCurrentTransferItemFromGroup('itemNo', errorItemDetail.itemNo);

              if (errorItemDetail.errors && errorItemDetail.errors.length) {
                isInvalidData = true;
                this.setErrorProducts(errorItemDetail.errors, transferItemFormGroup);
              }

              // update case change
              if (errorItemDetail.changes && errorItemDetail.changes.length) {
                isChange = true;
                this.setChangeProducts(errorItemDetail.changes, transferItemFormGroup);
              }
            }
          });

          this.modalService.show(ProductChangeModalComponent, {
            initialState: {
              isChange,
              changeMessage: error.data.dataChangeDescription,
              changeSubject: error.data.dataChangeSubject,
              isInvalidData,
              invalidMessage: error.data.dataInvalidDescription,
              invalidSubject: error.data.dataInvalidSubject,
              isInvalidDate
            }
          });

          this.store.dispatch(new StockTransferRequestErrorResponseAction(null));
        }
      });
  }

  createForm(stockTransfer: StockTransfer) {
    this.stockTransferForm = this.fb.group(
      {
        id: stockTransfer.id,
        status: stockTransfer.status,
        deliveryDate: [
          {
            value: stockTransfer.deliveryDate ? getDateFromString(stockTransfer.deliveryDate) : null,
            disabled: [StockTransferPageModes.VIEW].includes(this.data.mode)
          },
          [Validators.required]
        ],
        deliveryNote: [
          {
            value: stockTransfer.deliveryNote,
            disabled: [StockTransferPageModes.VIEW].includes(this.data.mode)
          }
        ],
        shipFromCodeName: stockTransfer.shipFromCodeName,
        shipToCodeName: stockTransfer.shipToCodeName,
        items: this.createItemForm(stockTransfer.items, null)
      },
      { validator: this.validateDeliveryDetail }
    );
  }

  validateDeliveryDetail: ValidatorFn = (group: FormGroup) => {
    const deliveryDate = group.get('deliveryDate').value;
    return !deliveryDate ? { isEmpty: true } : null;
  };

  createItemForm(stockTransferItem: StockTransferItem[], startIndex: number = null): FormArray {
    const stockItemForms = new FormArray([]);
    stockTransferItem.forEach((item, index) => {
      const stockTransferItemGroup = this.fb.group({
        id: item.id,
        itemNo: item.itemNo ? item.itemNo : startIndex ? ++startIndex : ++index,
        articleNo: item.articleNo,
        productName: item.productName,
        productDisplayName: item.productDisplayName,
        barcode: item.barcode,
        unitFactor: item.unitFactor,
        unit: item.unit,
        productType: item.productType,
        productStatus: item.productStatus,
        lastUpdated: item.lastUpdated,
        deliveryMethod: item.deliveryMethod,
        quantity: [
          {
            value: item.quantity,
            disabled: [StockTransferPageModes.VIEW].includes(this.data.mode)
          },
          [this.isZeroValidator, Validators.required]
        ],
        productChanges: null
      });
      stockItemForms.push(stockTransferItemGroup);
    });
    return stockItemForms;
  }

  initialStockTransferData() {
    this.stockTransfer = {
      docNo: null,
      deliveryDate: null,
      deliveryNote: null,
      items: null,
      shipFromCode: (this.data.shipFrom && this.data.shipFrom.code) || null,
      shipFromName: (this.data.shipFrom && this.data.shipFrom.Name) || null,
      shipFromCodeName: (this.data.shipFrom && this.data.shipFrom.warehouseNameDisplay) || null,
      shipToCode: (this.data.shipTo && this.data.shipTo.code) || null,
      shipToName: (this.data.shipTo && this.data.shipTo.Name) || null,
      shipToCodeName: (this.data.shipTo && this.data.shipTo.warehouseNameDisplay) || null,
      status: StockTransferRequestStatusEnum.DRAFT,
      createdDate: null,
      createdBy: null,
      createdByName: null,
      lastModifiedBy: null,
      lastModifiedByName: null,
      lastModifiedDate: null
    } as StockTransfer;

    if ([StockTransferPageModes.CREATE].includes(this.data.mode)) {
      this.initialStockProduct();
    } else if ([StockTransferPageModes.VIEW, StockTransferPageModes.EDIT].includes(this.data.mode)) {
      this.store.dispatch(new StockTransferRequestByIdRequestAction(this.data.id));
      this.subscriptionForViewEdit();
    }
  }

  subscriptionForViewEdit() {
    this.localStore
      .pipe(
        select(selectEditStockTransferRequest),
        filter(data => Boolean(data))
      )
      .subscribe(stockTransfer => {
        this.stockTransfer = { ...this.stockTransfer, ...stockTransfer };
        if (!this.form) {
          this.createForm(this.stockTransfer);
        }
        this.store.dispatch(new StockProductSelectedAddAllItem(this.stockTransfer.items));

        if ([StockTransferPageModes.EDIT].includes(this.data.mode)) {
          this.itemValidator(this.stockTransfer);
        }
        this.initialStockProduct();
      });
  }

  initialStockProduct() {
    this.localStore
      .pipe(
        select(selectAllStockProductSelected),
        filter(
          data =>
            (data && data['length'] > 0) ||
            [StockTransferPageModes.CREATE, StockTransferPageModes.VIEW, StockTransferPageModes.EDIT].includes(
              this.data.mode
            )
        )
      )
      .subscribe(stockProductSelected => {
        this.stockTransfer = {
          ...this.stockTransfer,
          items: stockProductSelected
        };

        if (!this.form) {
          this.createForm(this.stockTransfer);
        } else {
          const productArray = [];
          stockProductSelected.forEach(product => {
            const stockTransferItemFormGroup = this.getCurrentItemFromGroup('articleNo', product.articleNo);
            if (
              stockTransferItemFormGroup &&
              stockTransferItemFormGroup.controls.quantity.value !== product.quantity
            ) {
              stockTransferItemFormGroup.patchValue({ quantity: product.quantity });
              this.form.markAsTouched();
            } else if (!stockTransferItemFormGroup) {
              productArray.push(product);
            }
          });
          if (productArray.length > 0) {
            const newProductFormArray = this.createItemForm(
              productArray,
              this.stockTransfer.status === StockTransferRequestStatusEnum.DRAFT
                ? this.getItemForm.controls.length
                : null
            );
            newProductFormArray.controls.forEach(newProductFormGroup => {
              this.getItemForm.push(newProductFormGroup);
            });
            this.form.markAsTouched();
          }
        }
      });
  }

  itemValidator(stockTransfer: StockTransfer) {
    const validateList$: Observable<StockTransferRequestProductError> = this.stockTransferRequestService.validateStockTransferRequest(
      stockTransfer
    );
    validateList$
      .pipe(
        filter(data => Boolean(data)),
        tap(errorResponse => {
          if (errorResponse.invalidDelivery) {
            this.setErrorDeliveryDate();
          }

          errorResponse.items.forEach((errorItemDetail: StockTransferRequestItemProduct) => {
            if (
              (errorItemDetail.errors && errorItemDetail.errors.length) ||
              (errorItemDetail.changes && errorItemDetail.changes.length)
            ) {
              const transferItemFormGroup = this.getCurrentTransferItemFromGroup('itemNo', errorItemDetail.itemNo);

              if (errorItemDetail.errors && errorItemDetail.errors.length) {
                this.setErrorProducts(errorItemDetail.errors, transferItemFormGroup);
              }

              // update case change
              if (errorItemDetail.changes && errorItemDetail.changes.length) {
                this.setChangeProducts(errorItemDetail.changes, transferItemFormGroup);
              }
            }
          });
        }),
        untilComponentDestroyed(this)
      )
      .subscribe();
  }

  setErrorDeliveryDate() {
    this.form.get('deliveryDate').setValidators(beforeTodayValidator());
    this.form.get('deliveryDate').updateValueAndValidity();
  }

  setErrorProducts(errorItemDetail: StockTransferRequestItemFieldError[], currentFormGroup) {
    errorItemDetail.forEach((item: StockTransferRequestItemFieldError) => {
      setTimeout(() => {
        currentFormGroup.controls[item.field].setErrors({ [item.field]: item.message });
        currentFormGroup.controls[item.field].markAsDirty();
      });
      currentFormGroup.patchValue({
        submitError: true
      });
    });
  }

  setChangeProducts(changeItemDetail: StockTransferRequestItemFieldChange[], currentFormGroup) {
    currentFormGroup.patchValue({
      productChanges: this.transformProductChanges(changeItemDetail)
    });
    changeItemDetail.forEach((item: StockTransferRequestItemFieldChange) => {
      currentFormGroup.get(item.field).setValue(item.change);
    });
  }

  transformProductChanges(productChanges: StockTransferRequestItemFieldChange[]) {
    const ProductChangeObject = {};
    if (productChanges && productChanges instanceof Array && productChanges.length > 0) {
      productChanges.forEach(productChange => {
        ProductChangeObject[productChange.field] = productChange;
      });
      return ProductChangeObject;
    }
    return productChanges;
  }

  getCurrentTransferItemFromGroup(fieldName: string, currentValue: any): FormGroup {
    let transferItemFormGroup = null;
    if (this.getItemForm) {
      this.getItemForm.controls.forEach(itemForm => {
        const fieldValue = itemForm.get(fieldName).value;
        if (fieldValue === currentValue) {
          transferItemFormGroup = itemForm;
        }
      });
    }
    return transferItemFormGroup;
  }

  onCloseFullModal() {
    if (this.form && (this.form.touched || this.form.dirty)) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  toggleToEdit() {
    this.data.mode = StockTransferPageModes.EDIT;
    this.data.title = 'Edit Transfer Request';

    const stockTransferData = this.prepareData();
    this.itemValidator(stockTransferData);

    this.stockTransferForm.enable();
  }

  getColorStatus(status: string): string {
    return status ? status.toLowerCase() : '';
  }

  openSelectProductModal() {
    if (this.getItemForm) {
      this.store.dispatch(new StockProductSelectedAddAllItem(this.getItemForm.getRawValue()));
    }

    const initialState = {
      title: null,
      childItem: new ChildItem(StockProductListComponent, {}, true)
    };

    this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  get form(): FormGroup {
    return this.stockTransferForm;
  }

  get getItemForm(): FormArray {
    return this.form.get('items') as FormArray;
  }

  get isZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null) {
        return control.value === 0 ? { isZero: true } : null;
      }
      return null;
    };
  }

  getCurrentItemFromGroup(fieldName: string, currentItemValue: any): FormGroup {
    let stockItemFormGroup = null;
    if (this.getItemForm) {
      this.getItemForm.controls.forEach(itemForm => {
        const fieldValue = itemForm.get(fieldName).value;
        if (fieldValue === currentItemValue) {
          stockItemFormGroup = itemForm;
        }
      });
    }
    return stockItemFormGroup;
  }

  deleteItem(articleNo: string = null, itemIndex: number = null) {
    const msg = itemIndex !== null ? 'this' : 'all';
    const initialState = {
      title: 'Confirm',
      okText: 'Yes, delete',
      cancelText: 'Cancel',
      message: `Are you sure you want to delete ${msg} item?`
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          if (articleNo && itemIndex !== null) {
            this.store.dispatch(new StockProductSelectedRemoveItem(articleNo));
            this.getItemForm.removeAt(itemIndex);
            this.reArrangeIndex();
          } else {
            this.getItemForm.clear();
            this.store.dispatch(new StockProductSelectedReset());
          }
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  reArrangeIndex() {
    this.getItemForm.controls.forEach((itemForm, index) => {
      itemForm.patchValue({ itemNo: ++index });
    });
  }

  openDeliveryDetails() {
    this.modalService.show(StockTransferDeliveryDetailModalComponent, {
      class: 'modal-md',
      backdrop: 'static',
      keyboard: false,
      initialState: {
        parentForm: this.form
      }
    });
  }

  onSave() {
    this.isSubmit = false;
    const transferRequest = this.prepareData(true);
    this.store.dispatch(new StockTransferRequestSaveRequestAction(transferRequest));
  }

  deleteStockTransferRequest(id: string) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        okText: 'Yes, delete',
        cancelText: 'Cancel',
        message: 'Are you sure you want to delete this request?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new StockTransferDeleteRequestAction(id));
        }
      });
  }

  cancelStockTransferRequest(id: string, requestNo: string): void {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel TR Number <b>"${requestNo}"</b>?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new StockTransferCancelRequestAction({
              id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  onApproveRejectRequest(isApprove: boolean) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: isApprove ? 'Are you sure you want to "Approve"?' : 'Are you sure you want to "Reject"?',
        label: 'Comment',
        isRequiredConfirmMessage: !isApprove,
        okText: isApprove ? 'Approve' : 'Reject',
        okClass: isApprove ? 'btn btn-special-approve' : 'btn btn-special-reject'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          if (isApprove) {
            this.store.dispatch(
              new StockTransferRequestApproveRequest({
                id: this.data.id,
                comment: confirmModalRef.content.confirmMessage,
                status: 'APPROVED'
              })
            );
          } else {
            this.store.dispatch(
              new StockTransferRequestRejectRequest({
                id: this.data.id,
                comment: confirmModalRef.content.confirmMessage,
                status: 'REJECTED'
              })
            );
          }
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  onSubmit() {
    this.isSubmit = true;

    if (this.getItemForm.invalid) {
      const invalidIndex = this.getItemForm.controls.findIndex(item => item.invalid);
      this.paging.navigateToErrorIndex(invalidIndex);
      return;
    }

    if (this.form.invalid) {
      if (
        (this.form.controls.deliveryDate.errors && this.form.controls.deliveryDate.errors.beforeToday) ||
        (this.form.errors && this.form.errors.isEmpty)
      ) {
        this.alertInvalidDeliveryDateModal();
      }
      return;
    }

    this.handleConfirm();
  }

  hasEditPermission() {
    return (
      [StockTransferRequestStatusEnum.AWAITING_APPROVAL, StockTransferRequestStatusEnum.DRAFT].includes(
        this.data.status
      ) &&
      this.data.mode === StockTransferPageModes.VIEW &&
      this.authGuardService.checkPermission(['tr_m'])
    );
  }

  hasDeletePermission() {
    return (
      [StockTransferRequestStatusEnum.DRAFT].includes(this.data.status) &&
      this.data.mode === StockTransferPageModes.VIEW &&
      this.authGuardService.checkPermission(['tr_m'])
    );
  }

  hasCancelPermission() {
    return (
      [StockTransferRequestStatusEnum.AWAITING_APPROVAL].includes(this.data.status) &&
      this.data.mode === StockTransferPageModes.VIEW &&
      this.authGuardService.checkPermission(['tr_m'])
    );
  }

  hasApprovePermission() {
    return (
      [StockTransferRequestStatusEnum.AWAITING_APPROVAL].includes(this.data.status) &&
      this.data.mode === StockTransferPageModes.VIEW &&
      this.authGuardService.checkPermission(['tr_app'])
    );
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    const stockTransferData = this.prepareData();

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (ModalButtonResponseEnum.OK === result) {
          this.store.dispatch(new StockTransferRequestSubmitRequestAction(stockTransferData));
        }
      });
  }

  prepareData(_isSave = false) {
    return (this.stockTransfer = {
      ...this.stockTransfer,
      ...this.stockTransferForm.getRawValue(),
      deliveryDate: this.form.get('deliveryDate').value
        ? dateToStringCriteria(this.form.get('deliveryDate').value, false)
        : null
    } as StockTransfer);
  }

  canSave() {
    return (
      ![this.stockTransferPageMode.VIEW].includes(this.data.mode) &&
      [StockTransferRequestStatusEnum.DRAFT].includes(this.stockTransfer.status)
    );
  }

  doAfterVersionAlertModal() {}

  onOpenImportItemsModal() {
    const stocksItemImport = this.modalService.show(StockTransferRequestImportModalComponent, {
      backdrop: 'static',
      keyboard: false
    });
    stocksItemImport.content.submitUpload.subscribe(stockTransferItems => {
      this.getItemForm.clear();
      this.store.dispatch(new StockProductSelectedReset());
      const { items } = stockTransferItems;
      const itemFormArray = this.createItemForm(items, null);
      itemFormArray.controls.forEach(newProductFormGroup => {
        this.getItemForm.push(newProductFormGroup);
      });
      this.form.markAsTouched();
    });
  }

  alertConfirmUploadModal(
    initialState = {
      title: 'Confirm',
      message: 'Are you sure you want to import new file? All existing data will be lost.',
      okText: 'OK'
    }
  ) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.onOpenImportItemsModal();
        }
      });
  }

  checkExistingItem() {
    if (this.form.get('items').value.length > 0) {
      this.alertConfirmUploadModal();
    } else {
      this.onOpenImportItemsModal();
    }
  }

  alertInvalidDeliveryDateModal() {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message: 'Delivery details is required or delivery date is invalid.'
      }
    });
  }

  // Override empty function because list page already subscribe for version error
  subscribeForVersionError() {}
}
