import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductOrderItem, ShelfFixAssetOrderItem } from '../models/order-request.model';
import {
  ShelfFixAssetListResponse,
  ShelfFixAssetListSearchCriteria,
  ShelfFixAssetNo,
  ShelfFixAssetViewResponse
} from '../models/shelf-fix-asset.model';
import { ShelfFixAssetCreateRequest, ShelfFixAssetRequestViewResponse } from '../models/shelf-request.model';
import { ShelfNo } from '../models/shelf.model';
import { BaseService } from './base.service';

@Injectable()
export class ShelfFixAssetService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.shelfFixAsset;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: ShelfFixAssetListSearchCriteria): Observable<ShelfFixAssetListResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getHistoryLogs(shelfNo: ShelfFixAssetNo): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      shelfNo: shelfNo.shelfNo
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public getShelfById(shelfNo: ShelfNo): Observable<ShelfFixAssetViewResponse> {
    const url = this.getFullUrl(this.envService.view, {
      shelfNo: shelfNo.shelfNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public submitFirstLot(shelf: ShelfFixAssetCreateRequest): Observable<ShelfFixAssetRequestViewResponse> {
    const url = this.getFullUrl(this.envService.submitFirstLot);

    return this.http.post<ShelfFixAssetRequestViewResponse>(url, shelf, { headers: this.loaderHeaders(2000) });
  }

  public activateShelfFixAsset(shelfNo: ShelfNo, activate: boolean): Observable<any> {
    const url = this.getFullUrl(this.envService.activate, {
      shelfNo: shelfNo.shelfNo
    });
    const params = this.getParams({ activate });

    return this.http.post<ShelfFixAssetRequestViewResponse>(url, null, {
      headers: this.loaderHeaders(2000),
      observe: 'body',
      params
    });
  }

  public generateShelfItems(selected: string[], storeNo: string): Observable<ProductOrderItem[]> {
    const url = this.getFullUrl(this.envService.generateItems);
    const selectedShelf = {
      storeNo,
      shelfNo: selected
    };

    return this.http.post<ProductOrderItem[]>(url, selectedShelf, { headers: this.headers });
  }

  public generateShelfFixAssetItems(selected: string[]): Observable<ShelfFixAssetOrderItem[]> {
    const url = this.getFullUrl(this.envService.generateFixAssetItems);
    const selectedShelf = {
      shelfNo: selected.toString()
    };

    const params = this.getParams(selectedShelf);

    return this.http.get<ShelfFixAssetOrderItem[]>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public exportShelf(selected: Array<string>, status: Array<string>): Observable<any> {
    const url = this.getFullUrl(this.envService.export);

    return this.http.post<any>(
      url,
      { selected, status },
      {
        headers: this.loaderHeaders(),
        observe: 'body',
        responseType: 'blob' as 'json'
      }
    );
  }
}
