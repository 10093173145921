import { Pageable, Sort } from './pagination.model';

export interface PosAdvertisementListContent {
  id: string;
  version?: number;
  adsNo: string;
  images?: Image[];
  effectiveDate: any;
  expireDate: any;
  type: PosAdvertisementTypeEnum;
  isCancelled?: boolean;
  cancelledDate?: string;
  status?: any;
  createdDate: string;
  createdBy?: string;
  createdByName: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
  lastModifiedByName?: string;
  auditLogs: AuditLog[];
}

export interface AuditLog {
  activity: string;
  description: string;
  editBy: string;
  editByName: string;
  editDate: string;
}

export interface Image {
  sortNo: number;
  small?: string;
  medium?: string;
  fileName?: string;
  directory?: string;
  smallSizeFilePath?: string;
  mediumSizeFilePath?: string;
}

export class PosAdsSearchCriteria {
  searchCriteria?: string;
  status?: string;
  validDateFrom?: string;
  validDateTo?: string;
  effectiveDateFrom?: string;
  effectiveDateTo?: string;
  expireDateFrom?: string;
  expireDateTo?: string;
  page: number;
  size: number;
  sortOrder?: string;
  sortBy?: string;
}

export class PosAdsListResponse {
  id?: string;
  content: PosAdvertisementListContent[];
  pageable?: Pageable;
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number?: number;
  sort?: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
  hasContent: boolean;
}

export interface PosAdsCreate {
  type: string;
  images: Image[];
  effectiveDate?: string;
  expireDate?: string;
}

export interface PosAdsEdit {
  id: string;
  version?: number;
  adsNo: string;
  type: string;
  images: Image[];
  effectiveDate?: string;
  expireDate?: string;
}

export class PosAdsWithComment {
  id: string;
  comment: string;
}

export enum PosAdvertisementTypeEnum {
  DEFAULT = 'DEFAULT',
  SEASONAL = 'SEASONAL'
}
