<div class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-user"></em>
    <h4>Store Consultant</h4>
  </div>
  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap d-none d-md-block store-consultant">
        <app-import-export-button
          [buttons]="buttons"
          (export)="onOpenExportModal()"
          (import)="confirmImport()"
        ></app-import-export-button>
      </div>

      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="form-control searchCriteria"
              placeholder="Enter keyword search"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="statusList"
            [searchable]="false"
            [clearable]="false"
            (change)="onChangeStatus($event)"
            class="select-status"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
          ></ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">Region</div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="regionList"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="true"
                          bindLabel="nameTh"
                          bindValue="code"
                          formControlName="region"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">Province</div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="stateList"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="true"
                          bindLabel="nameTh"
                          bindValue="code"
                          formControlName="state"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="top-search-criteria space-search-criteria" *ngIf="regionTag || stateTag">
    <div class="search-criteria" *ngIf="regionTag">
      <a class="remove-criteria" (click)="clearFilterRegion()">
        <em class="icon-close"></em>
      </a>
      <span>{{ regionStringTag }}: {{ regionTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="stateTag">
      <a class="remove-criteria" (click)="clearFilterState()">
        <em class="icon-close"></em>
      </a>
      <span>{{ stateStringTag }}: {{ stateTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="table-responsive">
      <table id="data-table" class="table table-striped table-bordered table-hover w-100" aria-hidden="true">
        <thead>
          <tr>
            <th class="text-center w-10">User ID</th>
            <th class="text-center w-15">Name</th>
            <th class="text-center w-10">No. of Store</th>
            <th class="text-center w-10">Last Updated</th>
            <th class="text-center w-10">User Status</th>
            <th class="text-center w-5">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of resultList$ | async; index as i">
            <td (click)="goToView(item)">
              {{ item.userId }}
            </td>
            <td (click)="goToView(item)">
              <div class="fixedWidth">
                {{ item.fullName }}
              </div>
            </td>
            <td class="text-center" (click)="goToView(item)">
              <div class="fixedWidth">
                {{ item.noOfStore }}
              </div>
            </td>
            <td class="text-center" (click)="goToView(item)">
              <div class="fixedWidth">
                {{ item.lastModifiedDate | amFromUtc | amLocal | dateDisplay: dateFormat }}
              </div>
            </td>
            <td (click)="goToView(item)" [ngClass]="getStatus(item.active) | lowercase" class="status">
              <div class="fixedWidth">
                {{ getStatus(item.active) | titlecase }}
              </div>
            </td>
            <td class="text-center">
              <a
                href="javascript:void(0)"
                class="btn-more "
                [popover]="moreTemplate"
                placement="bottom"
                containerClass="fl"
                [outsideClick]="true"
                container=".popover-container"
              >
                <em class="icon-more"></em>
              </a>
              <ng-template #moreTemplate>
                <button
                  type="button"
                  class="btn btn-link"
                  (click)="goToEdit(item)"
                  *ngIf="item.active && this.hasPermissionManage"
                >
                  <em class="icon-edit"></em>Edit
                </button>
                <button type="button" class="btn btn-link d-block" (click)="showHistory(item)">
                  <em class="icon-history"></em>History
                </button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>

<div class="modal fade" bsModal #exportModal="bs-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Export Store Consultant</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="exportModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body admin-form" [formGroup]="exportForm" (ngSubmit)="onExport()">
        <div class="form-group">
          <label>User Status</label>
          <ng-select
            id="exportStatus"
            [items]="exportStatusList"
            [searchable]="false"
            [clearable]="true"
            [multiple]="true"
            class="select-status w-100"
            bindLabel="label"
            bindValue="value"
            placeholder="Please select..."
            formControlName="status"
          ></ng-select>
        </div>

        <div class="form-group">
          <div class="mb-2">Region</div>
          <ng-select
            placeholder="Please select..."
            [items]="regionList"
            [searchable]="false"
            [clearable]="true"
            [multiple]="true"
            bindLabel="nameTh"
            bindValue="code"
            formControlName="region"
          >
          </ng-select>
        </div>

        <div class="form-group">
          <div class="mb-2">Province</div>
          <ng-select
            placeholder="Please select..."
            [items]="stateList"
            [searchable]="false"
            [clearable]="true"
            [multiple]="true"
            bindLabel="nameTh"
            bindValue="code"
            formControlName="state"
          >
          </ng-select>
        </div>
        <div *ngIf="errorExport" class="alert-danger form-group mb-0 " role="alert">
          {{ errorExport }}
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onExport()" id="btnExport">
          Export
        </button>
      </div>
    </div>
  </div>
</div>
