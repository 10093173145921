import { createSelector } from '@ngrx/store';
import * as fromStoreConsultantState from '../reducers/store-consultant.reducers';
import { AppStates } from '../state/app.states';

const selectStoreConsultantState = (state: AppStates) => state.storeConsultant;

export const selectAllStoreConsultantList = createSelector(
  selectStoreConsultantState,
  fromStoreConsultantState.selectAll
);

export const selectStoreConsultantList = createSelector(
  selectStoreConsultantState,
  (state: fromStoreConsultantState.StoreConsultantState) => state
);

export const selectStoreConsultant = createSelector(
  selectStoreConsultantState,
  (state: fromStoreConsultantState.StoreConsultantState) => state.selected
);

export const selectStoreConsultantListCriteria = createSelector(selectStoreConsultantState, state => {
  return state.criteriaObject;
});
