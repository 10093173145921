import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse } from '../../models';
import { ShelfInventoryCreateRequest } from '../../models/shelf-inventory-request.model';
import {
  ShelfInventoryRequestActions,
  ShelfInventoryRequestActionType
} from '../actions/shelf-inventory-request.actions';
import { ShelfInventoryActions, ShelfInventoryActionType } from '../actions/shelf-inventory.actions';
import { BaseState } from '../state/base.state';

export interface ShelfInventoryCreateResponseState extends EntityState<ShelfInventoryCreateRequest>, BaseState {
  isLoading: boolean;
  isSave?: boolean | null;
  isApproveRejectSuccess?: boolean | null;
  result?: {
    response: ShelfInventoryCreateRequest | null;
    errorResponse: ErrorResponse | null;
  };
}

export const adapter: EntityAdapter<ShelfInventoryCreateRequest> = createEntityAdapter<ShelfInventoryCreateRequest>();

export const initialShelfInventoryCreateResponseState: ShelfInventoryCreateResponseState = adapter.getInitialState({
  isLoading: false,
  isSave: null,
  isApproveRejectSuccess: null
});

export function shelfInventoryCreateReducers(
  state = initialShelfInventoryCreateResponseState,
  action: ShelfInventoryRequestActions | ShelfInventoryActions
): ShelfInventoryCreateResponseState {
  switch (action.type) {
    case ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SAVE_RESPONSE:
    case ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SUBMIT_RESPONSE:
    case ShelfInventoryActionType.SUBMIT_SHELF_INVENTORY_FIRST_LOT_RESPONSE:
      return {
        ...state,
        isSave: ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SAVE_RESPONSE === action.type,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    case ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SAVE_ERROR:
    case ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SUBMIT_ERROR:
    case ShelfInventoryActionType.SUBMIT_SHELF_INVENTORY_FIRST_LOT_ERROR:
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    case ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_APPROVE_RESPONSE:
      return {
        ...state,
        isApproveRejectSuccess: action.payload.isSuccess
      };
    case ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_RESET:
      return initialShelfInventoryCreateResponseState;
    default: {
      return state;
    }
  }
}
