import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OrderSelectiveItemExportService extends BaseService {
    constructor(http) {
        super();
        this.http = http;
        this.envService = this.env.services.orderRequestSelective;
        this.headers = new HttpHeaders(this.envService.headers);
    }
    exportItemSelective(selected) {
        const params = this.getParams(selected);
        const url = this.getFullUrl(this.envService.export);
        return this.http.get(url, {
            headers: this.loaderHeaders(),
            observe: 'body',
            responseType: 'blob',
            params
        });
    }
}
OrderSelectiveItemExportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderSelectiveItemExportService_Factory() { return new OrderSelectiveItemExportService(i0.ɵɵinject(i1.HttpClient)); }, token: OrderSelectiveItemExportService, providedIn: "root" });
