/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./files-submit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../files-input/files-input.component.ngfactory";
import * as i6 from "../files-input/files-input.component";
import * as i7 from "ngx-logger";
import * as i8 from "ngx-intl-tel-input";
import * as i9 from "./files-submit.component";
import * as i10 from "ngx-bootstrap/modal";
var styles_FilesSubmitComponent = [i0.styles];
var RenderType_FilesSubmitComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilesSubmitComponent, data: {} });
export { RenderType_FilesSubmitComponent as RenderType_FilesSubmitComponent };
function View_FilesSubmitComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_FilesSubmitComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "alert-success form-group mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.confirmSubmitText; _ck(_v, 2, 0, currVal_0); }); }
function View_FilesSubmitComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn-link upload-error-file"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDownloadErrorExcel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["download"]))], null, null); }
function View_FilesSubmitComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "alert-danger form-group mb-0 error-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesSubmitComponent_5)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isFileError; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_FilesSubmitComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "form-group col-md"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesSubmitComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesSubmitComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUploadSuccess; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.errorMessage; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_FilesSubmitComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { fileInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 43, "div", [["class", "modal-content admin-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "pull-left text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesSubmitComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(11, 0, null, null, 28, "div", [["class", "modal-body"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(14, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 9, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 8, "div", [["class", "form-group col-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "label", [["for", "profitReport"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, ["", ""])), (_l()(), i1.ɵeld(19, 0, null, null, 5, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "input", [["class", "form-control mr-3"], ["disabled", ""], ["id", "profitReport"], ["type", "text"]], [[8, "placeholder", 0]], null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 3, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDownloadTemplate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "em", [["class", "icon-download"]], null, null, null, null, null)), (_l()(), i1.ɵted(23, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(25, 0, null, null, 12, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 11, "div", [["class", "form-group col-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "label", [["for", "fileInput"]], null, null, null, null, null)), (_l()(), i1.ɵted(28, null, ["", ""])), (_l()(), i1.ɵeld(29, 0, null, null, 8, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 7, "app-files-input", [["class", "flex-item"], ["formControlName", "files"], ["id", "fileInput"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "clearInput"], [null, "showErrorMessage"], [null, "submit"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 31).onChange($event.target.files) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 31).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("clearInput" === en)) {
        var pd_2 = (_co.reset() !== false);
        ad = (pd_2 && ad);
    } if (("showErrorMessage" === en)) {
        var pd_3 = (_co.setErrorMessage($event) !== false);
        ad = (pd_3 && ad);
    } if (("submit" === en)) {
        var pd_4 = (_co.onSubmit($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i5.View_FilesInputComponent_0, i5.RenderType_FilesInputComponent)), i1.ɵdid(31, 770048, [[1, 4], ["fileInput", 4]], 0, i6.FilesInputComponent, [[3, i3.ControlContainer], i7.NGXLogger], { size: [0, "size"], controlName: [1, "controlName"], disabled: [2, "disabled"] }, { clearInput: "clearInput", showErrorMessage: "showErrorMessage", submit: "submit" }), i1.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i6.FilesInputComponent]), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.FilesInputComponent]), i1.ɵdid(34, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_q]], { name: [0, "name"], isDisabled: [1, "isDisabled"] }, null), i1.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i1.ɵdid(36, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i1.ɵdid(37, 81920, null, 0, i8.NativeElementInjectorDirective, [i3.NgControl, i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesSubmitComponent_2)), i1.ɵdid(39, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(40, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 1, "button", [["class", "btn btn-standard"], ["id", "btCancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancel "])), (_l()(), i1.ɵeld(43, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["id", "btnSubmit"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Submit "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.subTitle; _ck(_v, 7, 0, currVal_1); var currVal_9 = _co.form; _ck(_v, 12, 0, currVal_9); var currVal_21 = 50000000; var currVal_22 = "files"; var currVal_23 = _co.loading; _ck(_v, 31, 0, currVal_21, currVal_22, currVal_23); var currVal_24 = "files"; var currVal_25 = _co.loading; _ck(_v, 34, 0, currVal_24, currVal_25); _ck(_v, 37, 0); var currVal_26 = (_co.errorMessage || _co.isUploadSuccess); _ck(_v, 39, 0, currVal_26); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 5, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 14).ngClassValid; var currVal_7 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = _co.templateName; _ck(_v, 18, 0, currVal_10); var currVal_11 = i1.ɵinlineInterpolate(1, "", _co.templateName, ""); _ck(_v, 20, 0, currVal_11); var currVal_12 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("REPORTS.DOWNLOAD")); _ck(_v, 23, 0, currVal_12); var currVal_13 = _co.fileTitle; _ck(_v, 28, 0, currVal_13); var currVal_14 = i1.ɵnov(_v, 36).ngClassUntouched; var currVal_15 = i1.ɵnov(_v, 36).ngClassTouched; var currVal_16 = i1.ɵnov(_v, 36).ngClassPristine; var currVal_17 = i1.ɵnov(_v, 36).ngClassDirty; var currVal_18 = i1.ɵnov(_v, 36).ngClassValid; var currVal_19 = i1.ɵnov(_v, 36).ngClassInvalid; var currVal_20 = i1.ɵnov(_v, 36).ngClassPending; _ck(_v, 30, 0, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20); var currVal_27 = ((!_co.form.value.files || _co.loading) || _co.errorMessage); _ck(_v, 43, 0, currVal_27); }); }
export function View_FilesSubmitComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-files-submit", [], null, null, null, View_FilesSubmitComponent_0, RenderType_FilesSubmitComponent)), i1.ɵdid(1, 245760, null, 0, i9.FilesSubmitComponent, [i3.FormBuilder, i10.BsModalRef, i7.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilesSubmitComponentNgFactory = i1.ɵccf("app-files-submit", i9.FilesSubmitComponent, View_FilesSubmitComponent_Host_0, { title: "title", subTitle: "subTitle", templateName: "templateName", fileTitle: "fileTitle", loading: "loading", isUploadSuccess: "isUploadSuccess", confirmSubmitText: "confirmSubmitText" }, { downloadTemplate: "downloadTemplate", downloadErrorExcel: "downloadErrorExcel", upload: "upload", submit: "submit" }, []);
export { FilesSubmitComponentNgFactory as FilesSubmitComponentNgFactory };
