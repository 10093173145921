import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ExportFileTypeEnum } from '../../../shared/enum/export-file-type.enum';
import { StoreList } from '../../../shared/models';
import { InventoryVarianceCriteria } from '../../../shared/models/report.model';
import { MerchantService } from '../../../shared/services/merchant.service';
import { StoreService } from '../../../shared/services/store.service';
import { AppStates } from '../../../shared/store/state/app.states';
import { b64toBlob } from '../../../shared/utils/b64toBlob-util';
import { dateToStringCriteria } from '../../../shared/utils/date-util';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-inventory-variance-modal',
  templateUrl: './inventory-variance-modal.component.html',
  styleUrls: ['./inventory-variance-modal.component.scss']
})
export class InventoryVarianceModalComponent extends BaseReportModalComponent implements OnInit {
  @ViewChild('inventoryVarianceModal', { static: false }) inventoryVarianceModal: ModalDirective;
  public exportForm: FormGroup;
  public submitted: boolean;
  public exportFormInValid: boolean;
  public responseExportError: string;

  public maxDate: Date;
  public minDate: Date;

  public storeList: Observable<StoreList[]>;
  public storeSearchLoading: boolean;
  public storeSearchInput$ = new Subject<string>();

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly formBuilder: FormBuilder,
    protected readonly translate: TranslateService,
    protected readonly merchantService: MerchantService,
    private readonly storeService: StoreService
  ) {
    super();
  }

  ngOnInit() {
    this.exportFormInValid = true;
    this.responseExportError = null;

    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.initControl();

    this.loadStore('');
  }

  initControl() {
    this.exportForm = this.formBuilder.group({
      createdDateFrom: [null],
      storeNo: [null]
    });
  }

  get form() {
    return this.exportForm.controls;
  }

  loadStore(initialTerm: string) {
    this.storeList = this.storeSearchInput$.pipe(
      startWith(initialTerm),
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.storeSearchLoading = true)),
      switchMap(term =>
        this.merchantService
          .searchStoreByCriteria({
            searchCriteria: term,
            sortBy: 'name',
            sortOrder: 'asc',
            page: 0,
            size: 20
          })
          .pipe(
            catchError(() => of([])), // empty list on error
            map(data => data.content),
            tap(() => {
              this.storeSearchLoading = false;
            })
          )
      )
    );
  }

  openExportModal() {
    this.exportForm.reset();
    this.responseExportError = null;
    this.exportFormInValid = true;
    this.inventoryVarianceModal.show();
  }

  closeExportModal() {
    this.exportForm.reset();
    this.inventoryVarianceModal.hide();
  }

  onExport() {
    this.submitted = true;
    if (this.exportFormInValid) {
      return;
    }
    this.responseExportError = null;
    const rawData = this.exportForm.getRawValue();
    const inventoryVarianceCriteria = new InventoryVarianceCriteria({
      createdDate: dateToStringCriteria(rawData.createdDateFrom, true),
      storeNo: rawData.storeNo
    });

    this.storeService.exportInventoryVarianceReport(inventoryVarianceCriteria).subscribe(
      response => {
        const blob = b64toBlob(response.fileBase64String, ExportFileTypeEnum.PDF);
        saveAs(blob, `${response.fileName}.pdf`);
      },
      error => {
        this.responseExportError = error.error.message || this.translate.instant(error.error.translateKey);
      },
      () => {
        this.submitted = false;
        this.closeExportModal();
      }
    );
  }

  onChangeExportCreatedDateFrom(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeExportCreatedDateTo(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportInventoryVarianceReport.timeFormat);
  }

  get onExportInvalid(): boolean {
    return this.exportForm.controls.createdDateFrom.value && this.exportForm.controls.storeNo.value;
  }
}
