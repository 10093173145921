import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ReceiveOrderExportCriteria,
  ReceiveOrderListResponse,
  ReceiveOrderListSearchCriteria,
  ReceiveOrderViewResponse
} from '../models/receive-order.model';
import { ReceiveOrderExceedReportExportCriteria, ToteTransactionReportCriteria } from '../models/report.model';
import { BaseService } from './base.service';

@Injectable()
export class ReceiveOrderService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.receiveOrder;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: ReceiveOrderListSearchCriteria): Observable<ReceiveOrderListResponse> {
    const params = this.getParams(criteria);
    return this.http.get<ReceiveOrderListResponse>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getReceiveOrderByNo(receiveOrderNo: string): Observable<ReceiveOrderViewResponse> {
    const url = this.getFullUrl(this.envService.getByNo, { receiveOrderNo });
    return this.http.get<ReceiveOrderViewResponse>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public export(criteria: ReceiveOrderExportCriteria): Observable<any> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.export);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public toteExport(criteria: ToteTransactionReportCriteria): Observable<any> {
    const params = this.getParams(criteria, true);
    const url = this.getFullUrl(this.envService.toteExport);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exceedExport(criteria: ReceiveOrderExceedReportExportCriteria): Observable<any> {
    const params = this.getParams(criteria, true);
    const url = this.getFullUrl(this.envService.exceedExport);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
