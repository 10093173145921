import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import {
  AdjustStockStatusEnum,
  LocationTypeEnum,
  ManageStockPageModes,
  MovementType
} from '../../../shared/enum/manage-stock.emun';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { RouteLinkTab } from '../../../shared/models';
import {
  ExportAdjustCriteria,
  StockAdjustContent,
  StockAdjustSearchCriteria
} from '../../../shared/models/adjust-stock.model';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { LocationDetail } from '../../../shared/models/manage-stock.model';
import { WarehouseListContent } from '../../../shared/models/warehouse.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { MerchantService } from '../../../shared/services/merchant.service';
import { StockInformationService } from '../../../shared/services/stock-information.service';
import { AdjustStockListRequestAction } from '../../../shared/store/actions/adjust-stock.actions';
import { WarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { AdjustStockState } from '../../../shared/store/reducers/adjust-stock.reducers';
import {
  selectAdjustList,
  selectAdjustListCriteria,
  selectAllAdjustList
} from '../../../shared/store/selectors/adjust-stock.selector';
import { selectUserInfoResult } from '../../../shared/store/selectors/auth-user-info.selector';
import { selectAllWarehouse } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { AdjustStockViewComponent } from '../adjust-stock-view/adjust-stock-view.component';
import { AdjustStockComponent } from '../adjust-stock/adjust-stock.component';
import { StockAdjustmentDownloadModalComponent } from '../stock-adjustment-download-modal/stock-adjustment-download-modal.component';

@Component({
  selector: 'app-manage-stock-list',
  templateUrl: './manage-stock-list.component.html',
  styleUrls: ['./manage-stock-list.component.scss']
})
export class ManageStockListComponent extends BaseSearchComponent<
  StockAdjustSearchCriteria,
  StockAdjustContent,
  AdjustStockState
> {
  listRoute: Array<RouteLinkTab>;
  locationType: LocationTypeEnum;
  location: LocationDetail;
  locationTypeList: NgOption[];
  locationTypeListByPermission: NgOption[];
  locationList$: Observable<NgOption[]>;
  wareHouseList: NgOption[];
  locationSearchInput$ = new Subject<string>();
  locationSearchLoading: boolean;
  public adjustTypeList = filterDropdown.adjustTypeList;
  public selectLocationForm: FormGroup;
  public allWarehouses: WarehouseListContent[] = [];
  public dateFormat = environment.dateTimeDisplay;
  public adjustStatus: NgOption[];

  public selectedlocationTypeTag: string;
  public selectedlocationTypeStringTag: string;
  public selectedAdjustTypeTag: string;
  public selectedAdjustTypeStringTag: string;
  public createDateTag: string;
  public createDateStringTag: string;
  public periodMaxDate = new Date();
  public periodMinDate: Date;
  public responseExportError: string;
  public bsModalRef: BsModalRef;
  public submitted: boolean;
  private localStore: Observable<any>;

  public exportForm: FormGroup;

  private readonly permittedWarehouse: Array<string>;
  private readonly permittedStore: Array<string>;
  importAdjustStockModal: BsModalRef;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    },
    {
      type: ButtonType.IMPORT,
      name: 'Import',
      hidden: !this.authGuardService.checkPermission(['^stkadj_m_[a-zA-Z]{2}[0-9]{1,3}$', '^stkadj_m_sto$'], true)
    }
  ];

  @ViewChild('modalCreateAdjustStock', { static: false }) modalCreateAdjustStock: ModalDirective;
  @ViewChild('exportModal', { static: false }) exportModal: ModalDirective;

  @ViewChild('importModal', { static: false }) importModal: ModalDirective;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    protected merchantService: MerchantService,
    private readonly masterService: MasterService,
    private readonly stockInformationService: StockInformationService
  ) {
    super(store, modalService, selectAllAdjustList, selectAdjustList);
    super.subscribeForSaveSuccess();
  }

  doInit() {
    this.getManageStockPermission();
    this.getListDropdownDefault();
    this.setRouteTab();
    this.initialData();
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [''],
      locationType: [null],
      adjustType: [null],
      createdDateFrom: [null],
      createdDateTo: [null]
    });

    this.selectLocationForm = this.fb.group({
      locationType: [{ value: null, disabled: false }, Validators.required],
      location: [{ value: null, disabled: true }, Validators.required]
    });

    this.exportForm = this.fb.group({
      locationType: [''],
      adjustType: [''],
      periodDateFrom: [],
      periodDateTo: []
    });
  }

  getListDropdownDefault() {
    this.adjustStatus = filterDropdown.adjustStockStatusList;
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;

    if (!formValue.locationType && !formValue.adjustType && !formValue.createdDateFrom && !formValue.createdDateTo) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      locationType: formValue.locationType,
      adjustType: formValue.adjustType,
      createdDateFrom: dateToStringCriteria(formValue.createdDateFrom, true),
      createdDateTo: dateToStringCriteria(formValue.createdDateTo, false),
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.prepareLocationTags();
    this.prepareAdjustTypeTags();
    this.prepareCreatedTags();
  }

  prepareLocationTags() {
    this.selectedlocationTypeTag = null;
    this.selectedlocationTypeStringTag = null;

    if (this.criteriaObject.locationType && this.criteriaObject.locationType.length > 0) {
      this.selectedlocationTypeStringTag = 'Location Type';
      const location = this.locationTypeList
        .filter(data => {
          return this.criteriaObject.locationType.indexOf(data.value.toString()) > -1;
        })
        .map(status => status.label);

      this.selectedlocationTypeTag = `"${location}"`;
    }
  }

  prepareAdjustTypeTags() {
    this.selectedAdjustTypeTag = null;
    this.selectedAdjustTypeStringTag = null;

    if (this.criteriaObject.adjustType && this.criteriaObject.adjustType.length > 0) {
      this.selectedAdjustTypeStringTag = 'Adjust Type';
      const type = this.adjustTypeList
        .filter(data => {
          return this.criteriaObject.adjustType.indexOf(data.value.toString()) > -1;
        })
        .map(status => status.label);

      this.selectedAdjustTypeTag = `"${type}"`;
    }
  }

  prepareCreatedTags() {
    this.createDateTag = null;
    this.createDateStringTag = null;

    const createdDateFrom = dateStringToTagCriteria(this.criteriaObject.createdDateFrom);
    const createdDateTo = dateStringToTagCriteria(this.criteriaObject.createdDateTo);
    const createdDate = generateDateStringTag({
      dateName: 'Created Date',
      dateFrom: createdDateFrom,
      dateTo: createdDateTo
    });

    this.createDateStringTag = createdDate.dateStringTag;
    this.createDateTag = createdDate.dateTag;
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  search(criteriaObj: any) {
    this.store.dispatch(new AdjustStockListRequestAction(criteriaObj));
  }

  clearAdvanceSearchAll() {
    this.setFirstPage();
    this.searchForm.patchValue({
      locationType: null,
      createdDateFrom: null,
      createdDateTo: null
    });

    this.criteriaObject = {
      ...this.criteriaObject,
      locationType: null,
      createdDateFrom: null,
      createdDateTo: null,
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  goToView(viewParams?: any) {
    if (viewParams === null) {
      return;
    }

    const initialState = {
      childItem: new ChildItem(
        AdjustStockViewComponent,
        {
          title: 'View Adjust Stock',
          mode: ManageStockPageModes.ADJUST_STOCK_VIEW,
          docNo: viewParams.requestNo,
          movementType: MovementType.ADJUST_STOCK,
          locationType: viewParams.locationType,
          location: { code: viewParams.locationCode, name: viewParams.locationName }
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  initialData() {
    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.locationTypeList = filterDropdown.locationType;
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectAdjustListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
    this.store.dispatch(new WarehouseListRequestAction('-'));

    this.localStore.pipe(select(selectAllWarehouse('Warehouse'))).subscribe(warehouseResult => {
      const warehouses = warehouseResult.filter(value => this.permittedWarehouse.includes(value.code));
      this.wareHouseList = warehouses.map(wh => {
        const value = { code: wh.code, name: wh.name, wmsCode: wh.wmsCode } as LocationDetail;
        return { value, label: wh.warehouseNameDisplay };
      });
    });
  }

  showModalCreateAdjustStock() {
    this.locationType = null;
    this.location = null;
    this.selectLocationForm.reset();
    this.selectLocationForm.controls['location'].disable();
    this.modalCreateAdjustStock.show();
  }

  hideModalCreateAdjustStock() {
    this.locationType = null;
    this.location = null;
    this.submitted = false;
    this.modalCreateAdjustStock.hide();
  }

  getManageStockPermission() {
    this.store.pipe(select(selectUserInfoResult)).subscribe(userInfo => {
      this.locationTypeListByPermission = filterDropdown.locationType.filter(data => {
        const list = userInfo.authorities.filter(v => v.match(data.regex)).map(v => data.regex.exec(v)[1]);
        this['permitted' + data.label] = list;
        return list.length;
      });
    });
  }

  onChangeLocationType() {
    this.locationType = this.selectLocationForm.get('locationType').value;
    this.selectLocationForm.get('location').reset();
    this.selectLocationForm.controls['location'].enable();
    this.loadLocation('');
  }

  onChangeLocation() {}

  clearAdvanceFilter() {
    this.setFirstPage();
    this.resetLocationType();
    this.resetAdjustType();
    this.resetCreatedDate();
    this.prepareSearchCriteriaTags();
    this.doSearch(this.criteriaObject);
  }

  clearLocationFilter() {
    this.setFirstPage();
    this.resetLocationType();
    this.doSearch(this.criteriaObject);
  }

  clearAdjustTypeFilter() {
    this.setFirstPage();
    this.resetAdjustType();
    this.doSearch(this.criteriaObject);
  }

  clearCreateDateFilter() {
    this.setFirstPage();
    this.resetCreatedDate();
    this.doSearch(this.criteriaObject);
  }

  resetLocationType() {
    this.searchForm.controls['locationType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      locationType: null,
      page: 0
    };
  }

  resetAdjustType() {
    this.searchForm.controls['adjustType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      adjustType: null,
      page: 0
    };
  }

  resetCreatedDate() {
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      createdDateFrom: null,
      createdDateTo: null,
      page: 0
    };
  }

  loadLocation(initialTerm: string) {
    this.locationList$ = concat(
      of([]),
      this.locationSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.locationSearchLoading = true)),
        switchMap(term => {
          return this.merchantService.searchStoreByName(term, this.permittedStore.join(','), true).pipe(
            map(res => {
              const items = [];
              if (res) {
                res.forEach(item => {
                  const value = { code: item.code, name: item.name } as LocationDetail;
                  items.push({ value, label: item.display });
                });
              }
              return items;
            }),
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.locationSearchLoading = false;
            })
          );
        })
      )
    );
  }

  goToAdjustStock() {
    this.submitted = true;
    if (this.selectLocationForm.invalid) {
      return;
    }

    const formData = this.selectLocationForm.getRawValue();
    const mode = ManageStockPageModes.ADJUST_STOCK;
    const title = 'Adjust Stock';
    const isRequiredLeaveForm = true;
    const initialState = {
      childItem: new ChildItem(
        AdjustStockComponent,
        {
          mode,
          title,
          movementType: MovementType.ADJUST_STOCK,
          locationType: formData.locationType,
          location: formData.location
        },
        isRequiredLeaveForm
      )
    };
    this.submitted = false;
    this.modalCreateAdjustStock.hide();
    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState
    });
    this.locationList$ = of([]);
  }

  setRouteTab() {
    this.listRoute = [];

    this.listRoute.push({ tabName: 'Manage Stock', url: '/stock/manage-stock' });
  }

  onChangeStatus(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  get locationTypeEnum() {
    return LocationTypeEnum;
  }

  getColorStatus(status: string) {
    return AdjustStockStatusEnum[status.toLocaleUpperCase()];
  }

  doAfterVersionAlertModal() {
    this.closeModal();
  }

  openExportModal() {
    this.responseExportError = null;
    this.exportForm.reset();
    this.exportModal.show();
  }

  closeExportModal() {
    this.exportModal.hide();
  }

  onExport() {
    const rawData = this.exportForm.getRawValue();

    const exportCriteria = new ExportAdjustCriteria({
      locationType: rawData.locationType,
      adjustType: rawData.adjustType,
      createdDateFrom: dateToStringCriteria(rawData.periodDateFrom, true),
      createdDateTo: dateToStringCriteria(rawData.periodDateTo, false),
      exportBy: 'adjust'
    });

    this.stockInformationService.exportStock<ExportAdjustCriteria>(exportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportStock.prefixAdjustment} ${this.timeToExport}.xlsx`);
      },
      error => (this.responseExportError = error.error.message),
      () => this.exportModal.hide()
    );
  }

  openImportModal() {
    this.importAdjustStockModal = this.modalService.show(StockAdjustmentDownloadModalComponent, {
      backdrop: 'static',
      keyboard: false
    });
  }

  onChangeExportPeriodDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.periodMinDate = new Date(value);
    } else {
      this.periodMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeExportPeriodDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.periodMaxDate = new Date(value);
    } else {
      this.periodMaxDate = new Date();
      this.periodMaxDate.setDate(this.periodMaxDate.getDate() + 365);
    }
  }

  get exportFormInValid() {
    const formValues = this.exportForm.value;
    return !formValues.periodDateFrom && !formValues.periodDateTo;
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportStock.timeFormat);
  }

  doAfterSuccessModal() {
    this.closeModal();
  }

  closeModal() {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  hasAdjustStockPermission() {
    return this.authGuardService.checkPermission(['^stkadj_m_[a-zA-Z]{2}[0-9]{1,3}$', '^stkadj_m_sto$'], true);
  }

  doDestroy() {}
}
