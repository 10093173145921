import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { BaseSearchComponent } from '../../../../base/base-search.component';
import { HistoryComponent } from '../../../../shared/components/history/history.component';
import { TDStoreValidatorTypeEnum } from '../../../../shared/enum/merchant-validator-type.enum';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { ProductTypeEnum } from '../../../../shared/enum/product-type.enum';
import { RequestPageModesEnum } from '../../../../shared/enum/request-step.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ConfirmModalComponent } from '../../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ChildItem } from '../../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../../shared/layouts/modals/full-modal/full-modal.component';
import { ErrorResponse, RouteLinkTab } from '../../../../shared/models';
import { AuditLog, HistoryType } from '../../../../shared/models/audit-log.model';
import { ButtonType, ImportExportButton } from '../../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../../shared/models/list-value/list-key-value.model';
import { ShelfFixAssetList, ShelfFixAssetListSearchCriteria } from '../../../../shared/models/shelf-fix-asset.model';
import { AuthGuardService } from '../../../../shared/services';
import { ShelfFixAssetRequestService } from '../../../../shared/services/shelf-fix-asset-request.service';
import { ShelfFixAssetService } from '../../../../shared/services/shelf-fix-asset.service';
import {
  ShelfFixAssetListHistoryRequestAction,
  ShelfFixAssetListHistoryResponseAction,
  ShelfFixAssetListRequestAction
} from '../../../../shared/store/actions/shelf-fix-asset.actions';
import { ShelfFixAssetState } from '../../../../shared/store/reducers/shelf-fix-asset.reducers';
import {
  selectAllShelfFixAssetList,
  selectShelfFixAssetList,
  selectShelfFixAssetListCriteria,
  selectShelfFixAssetListHistory
} from '../../../../shared/store/selectors/shelf-fix-asset.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../../shared/utils/date-util';
import { ExportShelfComponent } from '../../shelf-components/export-shelf/export-shelf.component';
import { ShelfFixAssetViewComponent } from '../shelf-fix-asset-view/shelf-fix-asset-view.component';

@Component({
  selector: 'app-shelf-fix-asset-list',
  templateUrl: './shelf-fix-asset-list.component.html',
  styleUrls: ['./shelf-fix-asset-list.component.scss']
})
export class ShelfFixAssetListComponent extends BaseSearchComponent<
  ShelfFixAssetListSearchCriteria,
  ShelfFixAssetList,
  ShelfFixAssetState
> {
  private bsModalRef: BsModalRef;
  private localStore: Observable<any>;

  public listRoute: Array<RouteLinkTab>;
  public dateFormat = environment.dateFormat;

  public minDate: Date;
  public maxDate: Date;
  public dateTag: string;
  public dateStringTag: string;
  public statusList = filterDropdown.listStatusFilter;
  public auditLogs$: Observable<AuditLog[]>;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    }
  ];

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected readonly translate: TranslateService,
    protected shelfFixAssetRequestService: ShelfFixAssetRequestService,
    protected shelfFixAssetService: ShelfFixAssetService,
    protected readonly authGuardService: AuthGuardService
  ) {
    super(store, modalService, selectAllShelfFixAssetList, selectShelfFixAssetList);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.localStore
      .pipe(select(selectShelfFixAssetListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.auditLogs$ = this.localStore.pipe(select(selectShelfFixAssetListHistory));
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.statusList[0].value],
      lastUpdatedDateFrom: [null],
      lastUpdatedDateTo: [null]
    });
  }

  onchangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  clearFilterDate() {
    this.setFirstPage();
    this.searchForm.controls['lastUpdatedDateFrom'].reset();
    this.searchForm.controls['lastUpdatedDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      lastUpdatedDateFrom: null,
      lastUpdatedDateTo: null
    };
    this.search(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['lastUpdatedDateFrom'].reset();
    this.searchForm.controls['lastUpdatedDateTo'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      lastUpdatedDateFrom: null,
      lastUpdatedDateTo: null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = value;
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = value;
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (!formValue.lastUpdatedDateFrom && !formValue.lastUpdatedDateTo) {
      return;
    }

    let dateFrom = this.searchForm.value.lastUpdatedDateFrom;
    let dateTo = this.searchForm.value.lastUpdatedDateTo;

    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      lastUpdatedDateFrom: dateFrom,
      lastUpdatedDateTo: dateTo,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;

    const lastUpdatedDateFrom = dateStringToTagCriteria(this.criteriaObject.lastUpdatedDateFrom);
    const lastUpdatedDateTo = dateStringToTagCriteria(this.criteriaObject.lastUpdatedDateTo);
    const lastUpdatedDate = generateDateStringTag({
      dateName: 'Last Updated Date',
      dateFrom: lastUpdatedDateFrom,
      dateTo: lastUpdatedDateTo
    });

    this.dateStringTag = lastUpdatedDate.dateStringTag;
    this.dateTag = lastUpdatedDate.dateTag;
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new ShelfFixAssetListRequestAction(criteriaObj));
  }

  handleEdit(shelfNo: string) {
    this.shelfFixAssetRequestService
      .getShelfValidate(TDStoreValidatorTypeEnum.REQUESTED, shelfNo)
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        () => {
          this.goToView(shelfNo, true);
        },
        error => {
          const initialState = {
            title: 'Alert',
            message: this.translate.instant('ERROR_CODE.' + error.error.code)
          };
          this.modalService.show(AlertModalComponent, {
            initialState
          });
        }
      );
  }

  goToView(id: string, editing?) {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        ShelfFixAssetViewComponent,
        {
          title: editing ? 'Edit Shelf Fix Asset' : 'View Shelf Fix Asset',
          mode: editing ? RequestPageModesEnum.REQUEST_EDIT : RequestPageModesEnum.REQUEST_VIEW,
          shelfNo: id
        },
        false
      )
    };
    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
  }

  exportShelf() {
    this.modalService.show(ExportShelfComponent, {
      backdrop: 'static',
      initialState: {
        shelfType: ProductTypeEnum.FIX_ASSET
      }
    });
  }

  showHistory(data: ShelfFixAssetList) {
    this.store.dispatch(new ShelfFixAssetListHistoryRequestAction({ shelfNo: data.shelfNo }));

    const initialState = {
      title: 'History',
      historyHeader: `Shelf: ${data.shelfCode}-${data.shelfInfo.shelfName}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.SHELF,
      auditLogs$: this.auditLogs$
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new ShelfFixAssetListHistoryResponseAction({ auditLogs: null }));
    });
  }

  onActivateShelf(shelfNo, toActivate) {
    const messages = this.getActivatedMessage(toActivate);
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: messages.confirmMessage,
        okText: 'Submit',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.shelfFixAssetService
            .activateShelfFixAsset({ shelfNo }, toActivate)
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal(messages.successMessage);
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  getActivatedMessage(toActivate) {
    return {
      confirmMessage: !toActivate ? 'Are you sure you want to deactivate?' : 'Are you sure you want to activate?',
      successMessage: !toActivate ? 'The shelf has been deactivated.' : 'The shelf has been activated.'
    };
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.modalService.hide(1);
        this.setFirstPage();
        this.search({ ...this.criteriaObject, page: 0 });
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  hasShelfEditPermission() {
    return this.authGuardService.checkPermission(['sh_ast_m']);
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission(['sh_ast_v', 'sh_ast_m']);
    const hasRequestPagePermission = this.authGuardService.checkPermission(['sh_ast_rq_v', 'sh_ast_m', 'sh_ast_app']);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Shelf Fix Asset List', url: '/shelf/shelf-fix-asset-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Shelf Fix Asset Request', url: '/shelf/shelf-fix-asset-request-list' });
    }
  }
}
