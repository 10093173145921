import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs/internal/Observable';
import { filter } from 'rxjs/operators';
import { SearchBarcodeModalComponent } from '../../../../shared/components/search-barcode-modal/search-barcode-modal.component';
import { SearchProductModalComponent } from '../../../../shared/components/search-product-modal/search-product-modal.component';
import { ProductTypeEnum } from '../../../../shared/enum/product-type.enum';
import { NewRequestStatusEnum } from '../../../../shared/enum/request-status.enum';
import {
  NewRequestTypeEnum,
  RequestPageModesEnum,
  RequestProductErrorEnum
} from '../../../../shared/enum/request-step.enum';
import { ShelfPages } from '../../../../shared/enum/td-store-page.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { SimpleInfoListModalComponent } from '../../../../shared/layouts/modals/simple-info-list-modal/simple-info-list-modal.component';
import { UiPaginationComponent } from '../../../../shared/layouts/ui-pagination/ui-pagination.component';
import { BarcodeResponse } from '../../../../shared/models/barcode.model';
import { ShelfFixAssetViewResponse } from '../../../../shared/models/shelf-fix-asset.model';
import {
  DeleteShelfItems,
  ListOfChanges,
  NonMerchandiseItem,
  ShelfFixAssetRequestViewResponse,
  ShelfRequestViewResponse
} from '../../../../shared/models/shelf-request.model';
import { ShelfViewResponse } from '../../../../shared/models/shelf.model';
import { AuthGuardService } from '../../../../shared/services';
import { AppStates } from '../../../../shared/store/state/app.states';
import { getSelectShelf, getSelectShelfPage } from '../../../../shared/utils/get-select-by-page-util';

@Component({
  selector: 'app-shelf-fix-asset',
  templateUrl: './shelf-fix-asset.component.html',
  styleUrls: ['./shelf-fix-asset.component.scss']
})
export class ShelfFixAssetComponent implements OnInit, OnDestroy, OnChanges {
  @Input() parentForm: FormGroup;
  @Input() submitted: boolean;
  @Input() mode: RequestPageModesEnum;
  @Input() page: ShelfPages;
  @ViewChild('searchProductModal', { static: false }) searchProductModal: SearchProductModalComponent;
  @ViewChild('searchBarcodeModal', { static: false }) searchBarcodeModal: SearchBarcodeModalComponent;
  @ViewChild('paging', { static: false }) paging: UiPaginationComponent;

  public productType: ProductTypeEnum = ProductTypeEnum.FIX_ASSET;
  public headerRow: string[];
  public localStore: Observable<any>;
  public shelfRequestView$: Observable<ShelfRequestViewResponse | ShelfViewResponse>;
  public shelfRequestFixAssetView$: Observable<ShelfFixAssetRequestViewResponse>;
  public type: NewRequestTypeEnum;
  public status: NewRequestStatusEnum;
  public listOfChange: ListOfChanges[] = [];
  public deleteNonMerchandiseItem: DeleteShelfItems[];

  public currentPage = 1;
  public pageSize = 20;

  constructor(
    private readonly fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    private readonly authGuardService: AuthGuardService
  ) {}

  ngOnInit() {
    this.headerRow = ['No.', 'Product Name', 'Barcode', 'Unit', 'Unit Factor', 'Quantity', 'Action', 'Change Status'];

    this.initState();
  }

  ngOnDestroy(): void {}

  initState() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    const formArray = this.fb.array([], this.barcodeValidator());
    this.parentForm.addControl('fixAssetItem', formArray);

    if (this.mode !== RequestPageModesEnum.REQUEST_CREATE) {
      if (this.page === ShelfPages.SHELF_REQUEST_FIX_ASSET || this.page === ShelfPages.SHELF_FIX_ASSET_EDIT) {
        this.shelfRequestFixAssetView$ = this.localStore.pipe(select(getSelectShelfPage(this.page)));
        this.setShelfItemsValue();
      } else {
        this.shelfRequestView$ = this.localStore.pipe(select(getSelectShelf(this.page)));
        this.setNonMerchandiseItemsValue();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['mode'] &&
      changes['mode'].currentValue &&
      changes['mode'].currentValue === RequestPageModesEnum.REQUEST_EDIT
    ) {
      this.toggleEditForm();
    }
  }

  get form(): FormArray {
    return this.parentForm.get('fixAssetItem') as FormArray;
  }

  get formControls(): AbstractControl[] {
    return (this.parentForm.get('fixAssetItem') as FormArray).controls;
  }

  setNonMerchandiseItemsValue() {
    this.shelfRequestView$
      .pipe(
        untilComponentDestroyed(this),
        filter(value => Boolean(value))
      )
      .subscribe(value => {
        this.type = value.type;
        this.status = value.status;
        if (!(value instanceof ShelfViewResponse)) {
          this.deleteNonMerchandiseItem = value.deleteNonMerchandiseItem || [];
        }

        if (value.nonMerchandiseItem && value.nonMerchandiseItem.length && !this.form.controls.length) {
          value.nonMerchandiseItem.forEach((item, i) => {
            this.setFixAssetItem(item, i);
          });

          this.form.setValidators(this.barcodeValidator());
        }

        if (this.mode === RequestPageModesEnum.REQUEST_EDIT) {
          this.toggleEditForm();
        }
      });
  }

  setShelfItemsValue() {
    this.shelfRequestFixAssetView$
      .pipe(
        untilComponentDestroyed(this),
        filter(value => Boolean(value))
      )
      .subscribe(value => {
        this.type = value.type;
        this.status = value.status;
        if (!(value instanceof ShelfFixAssetViewResponse)) {
          this.deleteNonMerchandiseItem = value.deleteItems || [];
        }

        if (value.items && value.items.length && !this.form.controls.length) {
          value.items.forEach((item, i) => {
            this.setFixAssetItem(item, i);
          });

          this.form.setValidators(this.barcodeValidator());
        }

        if (this.mode === RequestPageModesEnum.REQUEST_EDIT) {
          this.toggleEditForm();
        }
      });
  }

  setFixAssetItem(item: NonMerchandiseItem, i: number) {
    if (this.form.at(i)) {
      this.form.at(i).patchValue(item);
    } else {
      this.form.push(this.createForm(item));
    }

    if (item.changeStatus) {
      this.listOfChange.push({
        barcode: item.barcode,
        changeStatus: item.changeStatus,
        changes: item.changes ? item.changes.map(x => x.field) : []
      });
    }
  }

  createForm(item: NonMerchandiseItem, errorMessage?: RequestProductErrorEnum) {
    const isDisabled = this.mode === RequestPageModesEnum.REQUEST_VIEW;
    const isProductError = !!errorMessage;

    const formItem: FormGroup = this.fb.group({
      barcode: item.barcode,
      articleNo: item.articleNo,
      productName: item.productName,
      unit: item.unit,
      unitFactor: item.unitFactor,
      quantity: [
        { value: isProductError ? null : item.quantity, disabled: isDisabled },
        [Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]
      ]
    });

    if (errorMessage) {
      this.validateForm(formItem, errorMessage);
    }

    return formItem;
  }

  addNewItem() {
    this.searchProductModal.openSelectProductModal();
  }

  addNewBarcode() {
    this.searchBarcodeModal.selectBarcodeModal.show();
  }

  onAddItem(response: BarcodeResponse[]) {
    response.forEach(item => {
      if (!this.checkDuplicatedBarcode(item.barcode)) {
        this.form.push(
          this.createForm(NonMerchandiseItem.mappingBarcodeResponseToNonMerchandiseItem(item), item.errorMessage)
        );
        this.form.markAsTouched();
      } else {
        this.alertErrorModal('Not allow to add duplicated barcode.');
        return;
      }
    });
  }

  onAddBarcodeItem(response: BarcodeResponse[]) {
    response.forEach(item => {
      this.form.push(
        this.createForm(NonMerchandiseItem.mappingBarcodeResponseToNonMerchandiseItem(item), item.errorMessage)
      );
    });

    this.form.markAsTouched();
  }

  toggleEditForm() {
    this.mode = RequestPageModesEnum.REQUEST_EDIT;

    if (!this.form) {
      const formArray = this.fb.array([], this.barcodeValidator());
      this.parentForm.addControl('fixAssetItem', formArray);
    }

    this.form.disable();

    if (![NewRequestStatusEnum.AWAITING_FIRST_LOT, NewRequestStatusEnum.ACTIVE].includes(this.status)) {
      this.formControls.forEach(item => {
        item.get('quantity').enable();
      });
    }
  }

  validatorFormControls(field: string, i: number) {
    const index = i + (this.currentPage - 1) * this.pageSize;
    return this.formControls[index].get(field).errors;
  }

  deleteItem(i: number) {
    const index = i + (this.currentPage - 1) * this.pageSize;
    this.form.removeAt(index);
    this.form.markAsTouched();
  }

  checkDuplicatedBarcode(barcode: string): boolean {
    return !!(this.formControls && this.formControls.find(x => x.get('barcode').value === barcode));
  }

  barcodeValidator(): ValidatorFn {
    return (fa: FormArray) => {
      const array = fa.getRawValue();

      const availableBarcodes = [];
      const duplicateIndexes = array
        .map((item, index) => {
          if (!availableBarcodes.includes(item.barcode)) {
            availableBarcodes.push(item.barcode);
          } else {
            return index;
          }
        })
        .filter(duplicate => duplicate);

      duplicateIndexes.forEach(duplicateIndex => {
        if (!fa.controls[duplicateIndex].errors || fa.controls[duplicateIndex].getError('duplicated')) {
          fa.controls[duplicateIndex].setErrors({ duplicated: true });
        }
      });
      return null;
    };
  }

  validateForm(formItem: AbstractControl, errorMessage: RequestProductErrorEnum) {
    switch (errorMessage) {
      case RequestProductErrorEnum.INVALID_BARCODE:
      case RequestProductErrorEnum.INACTIVE_BARCODE:
        formItem.setValidators(this.setValidator(errorMessage));
        formItem.get('barcode').setValidators(this.setValidator(errorMessage));
        break;
      case RequestProductErrorEnum.STATUS_IS_DELISTED:
      case RequestProductErrorEnum.NOT_ALLOW_INVENTORY:
      case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
        formItem.setValidators(this.setValidator(errorMessage));
        formItem.get('productName').setValidators(this.setValidator(errorMessage));
        break;
      default:
        break;
    }
  }

  setValidator(errorMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      switch (errorMessage) {
        case RequestProductErrorEnum.INVALID_BARCODE:
          return { invalidBarcode: true };
        case RequestProductErrorEnum.INACTIVE_BARCODE:
          return { isInactive: true };
        case RequestProductErrorEnum.STATUS_IS_DELISTED:
          return { isStatusDelisted: true };
        case RequestProductErrorEnum.NOT_ALLOW_INVENTORY:
          return { isInventoryItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
          return { isStoreUseItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_ZERO:
          if (control.value !== null) {
            return control.value === 0 ? { isZero: true } : null;
          }

          return null;
        default:
          return null;
      }
    };
  }

  alertErrorModal(message: string) {
    const initialState = {
      title: 'Failed',
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  showDeletedItems() {
    const initialState = {
      title: `${this.deleteNonMerchandiseItem.length} Item Deleted`,
      data: this.deleteNonMerchandiseItem,
      columns: [
        { field: 'barcode' },
        { field: 'productName' },
        { field: 'unit', align: 'center' },
        { field: 'unitFactor', align: 'center' }
      ]
    };

    this.modalService.show(SimpleInfoListModalComponent, {
      initialState
    });
  }

  get isShowDeletedItemsButton() {
    return this.isShowChangesMode;
  }

  get isShowChangesMode() {
    return (
      (this.page === ShelfPages.SHELF_REQUEST || this.page === ShelfPages.SHELF_REQUEST_FIX_ASSET) &&
      this.type === NewRequestTypeEnum.EDIT &&
      this.mode === RequestPageModesEnum.REQUEST_VIEW
    );
  }

  changeStatus(barcode: string) {
    const changeStatus = this.listOfChange.find(item => {
      return item.barcode === barcode;
    });

    return changeStatus ? changeStatus.changeStatus : 'NONE';
  }

  hasChanges(barcode: string, field: string) {
    return (
      this.isShowChangesMode &&
      this.listOfChange.find(item => {
        return item.barcode === barcode && item.changes.includes(field);
      })
    );
  }

  get hasEditPermission() {
    return (
      [RequestPageModesEnum.REQUEST_CREATE, RequestPageModesEnum.REQUEST_EDIT].includes(this.mode) &&
      ![NewRequestStatusEnum.AWAITING_FIRST_LOT, NewRequestStatusEnum.APPROVED, NewRequestStatusEnum.ACTIVE].includes(
        this.status
      ) &&
      this.authGuardService.checkPermission(['shelf_m', 'sh_ast_m'])
    );
  }

  get requestPageModesEnum() {
    return RequestPageModesEnum;
  }
}
