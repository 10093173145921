import { createEntityAdapter } from '@ngrx/entity';
import { ShelfSelectInventoryActionTypes } from '../actions/shelf-select-inventory.actions';
const ɵ0 = (shelfItems) => shelfItems.shelfType;
export const adapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialShelfSelectInventoryResponseState = adapter.getInitialState({
    criteriaObject: {
        page: 0,
        size: 20
    },
    totalElements: 0,
    totalPages: 20,
    selected: []
});
export function shelfSelectInventoryReducers(state = initialShelfSelectInventoryResponseState, action) {
    switch (action.type) {
        case ShelfSelectInventoryActionTypes.SHELF_SELECT_INVENTORY_LIST_REQUEST:
            return Object.assign({}, state, { criteriaObject: action.payload });
        case ShelfSelectInventoryActionTypes.SHELF_SELECT_INVENTORY_LIST_RESPONSE:
            return adapter.addAll(action.payload.content, Object.assign({}, state, { criteriaObject: Object.assign({}, state.criteriaObject, { page: action.payload.page, size: action.payload.size }), totalElements: action.payload.totalElements, totalPages: action.payload.totalPages }));
        case ShelfSelectInventoryActionTypes.SHELF_SELECT_INVENTORY_LIST_RESET:
            return adapter.removeAll(Object.assign({}, state));
        default: {
            return state;
        }
    }
}
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export { ɵ0 };
