<div class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-report"></em>
    <h4>{{ 'REPORTS.TITLE' | translate }}</h4>
  </div>

  <hr class="header-divider" />

  <div class="container-fluid">
    <div class="report-container row">
      <ng-container *ngFor="let group of reportSectionList">
        <div class="section-list col-lg-3 col-md-6">
          <div class="row section-header">
            <span>{{ 'REPORTS.GROUP.' + group | translate }}</span>
          </div>

          <div class="report-section">
            <div class="report-item" *ngFor="let item of getReportBySection(group) | sort: 'title'">
              <span class="title">{{ item.title }}</span>
              <button
                type="button"
                class="btn btn-primary"
                [disabled]="!hasPermission(item)"
                (click)="onOpenModal(item.action)"
              >
                <em class="icon-export"></em>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<app-reward-transaction-modal #rewardTransactionModal></app-reward-transaction-modal>
<app-redeem-transaction-modal #redeemTransactionModal></app-redeem-transaction-modal>
<app-sales-transaction-modal #salesTransactionModal></app-sales-transaction-modal>
<app-sales-transaction-confidential #salesTransactionConfidentialModal></app-sales-transaction-confidential>
<app-store-confidential #storeConfidentialModal></app-store-confidential>
<app-td-assortment-confidential #tdAssortmentConfidentialModal></app-td-assortment-confidential>
<app-stock-card-modal #stockCardModal></app-stock-card-modal>
<app-voucher-modal #voucherModal></app-voucher-modal>
<app-delete-bill-modal #deletedBillModal></app-delete-bill-modal>
<app-sales-summary-modal #salesSummaryModal></app-sales-summary-modal>
<app-tote-transaction-modal #toteTransactionModal></app-tote-transaction-modal>
<app-tote-transaction-dc-modal #toteTransactionDCModal></app-tote-transaction-dc-modal>
<app-tote-transaction-from-store-modal #toteTransactionFromStoreModal></app-tote-transaction-from-store-modal>
<app-tote-asset-location-modal #toteAssetLocationModal></app-tote-asset-location-modal>
<app-promotion-compensate-modal #promotionCompensateModal></app-promotion-compensate-modal>
<app-inventory-count-transaction-temp-modal #inventoryCountTransactionTempModal>
</app-inventory-count-transaction-temp-modal>
<app-moving-average-modal #movingAverageModal></app-moving-average-modal>
<app-inventory-count-transaction-modal #inventoryCountTransactionModal></app-inventory-count-transaction-modal>
<app-money-in-modal #moneyInModal></app-money-in-modal>
<app-inventory-adjustment-request-modal #inventoryAdjustmentRequestModal></app-inventory-adjustment-request-modal>
<app-inventory-variance-modal #inventoryVarianceModal></app-inventory-variance-modal>
<app-dc-assortment-modal #dcAssortmentModal></app-dc-assortment-modal>
<app-bill-payment-modal #billPaymentModal></app-bill-payment-modal>
<app-supplier-coupon-reconcile-modal #supplierCouponReconcileModal></app-supplier-coupon-reconcile-modal>
<app-destroy-transaction-modal #destroyTransactionModal></app-destroy-transaction-modal>
<app-destroy-document-modal #destroyDocumentModal></app-destroy-document-modal>
<app-receive-order-exceed-modal #receiveOrderExceedModal></app-receive-order-exceed-modal>
<app-awaiting-first-lot-modal #awaitingFirstLotModal></app-awaiting-first-lot-modal>
<app-order-processing-hold-modal-modal #orderProcessingHoldModal></app-order-processing-hold-modal-modal>
