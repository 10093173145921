import { ClaimRequestStatusEnum } from '../enum/claim-status.enum';
import { StoreType } from '../enum/store.enum';
import { Amount } from './amount.model';
import { BaseReport } from './base-report.model';

export interface ClaimRequest {
  id: string;
  claimRequestNo: string;
  claimRequestStatus: ClaimRequestStatusEnum;
  orderNo: string;
  doNo: string;
  roNo: string;
  storeNo?: string;
  storeName: string;
  storeCode: string;
  storeType: StoreType;
  orderType: string;
  merchantNo?: string;
  shipmentNo?: string;
  requestedBy: string;
  requestedDate: string;
  totalApprovedAmount: number;
  totalApprovedExcVatAmount: number;
  totalApprovedVatAmount: number;
  amount?: number;
  totalClaimAmount?: number;
  items: ClaimRequestItem[];
  version: number;
  crDate?: string;
  crBy?: string;
  crByName?: string;
  crByLocalName?: string;
  createdWithRO?: boolean;
  warehouseCode?: string;
  warehouseName?: string;
  warehouseDisplayName?: string;
}

export interface ClaimRequestSubmit {
  id: string;
  claimRequestNo: string;
  claimRequestStatus: ClaimRequestStatusEnum;
  orderNo: string;
  doNo: string;
  roNo: string;
  storeNo?: string;
  storeName: string;
  storeCode: string;
  storeType: StoreType;
  orderType: string;
  merchantNo?: string;
  shipmentNo?: string;
  requestedBy: string;
  requestedDate: string;
  totalApprovedAmount: Amount;
  totalApprovedExcVatAmount: Amount;
  totalApprovedVatAmount: Amount;
  amount?: Amount;
  totalClaimAmount?: Amount;
  items: ClaimRequestItemSubmit[];
  version: number;
  crDate?: string;
  crBy?: string;
  crByName?: string;
  crByLocalName?: string;
}

export interface ClaimApproveRequest {
  version: number;
  comment: string;
  claimRequestNo: string;
  totalApprovedAmount: number;
  items: ClaimRequestItem[];
}

export interface ClaimRejectRequest {
  version: number;
  claimRequestNo: string;
  comment: string;
}

export interface ClaimRequestItem {
  itemNo: number;
  barcode: string;
  articleNo: string;
  productName: string;
  vatAmountPerUnit?: Amount;
  receiveQty?: number;
  reasons: Reason[];
  productType?: string;
  receiveAmount?: Amount;
  unit?: string;
  localUnit?: string;
  unitPrice?: Amount;
  unitFactor?: string;
}

export interface ClaimRequestItemSubmit {
  itemNo: number;
  barcode: string;
  articleNo: string;
  productName: string;
  vatAmountPerUnit?: Amount;
  receiveQty?: number;
  reasons: ReasonSubmit[];
  productType?: string;
  receiveAmount?: Amount;
  unit?: string;
  localUnit?: string;
  unitPrice?: Amount;
  unitFactor?: string;
}

export interface Reason {
  claimReasonNo: number;
  claimQty: number;
  claimAmount: number;
  claimReason: string;
  claimImageList: ClaimImage[];
  approvedQty: number;
  approvedAmount: number;
  claimFrom: string;
  unit: string;
  approvedExcVatAmount: number;
  approvedVatAmount: number;
}

export interface ClaimImage {
  path: string;
  tempPath: string;
}

export interface ReasonSubmit {
  claimReasonNo: number;
  claimQty: number;
  claimAmount: Amount;
  reason: string;
  claimImage: string;
  approvedQty: number;
  approvedAmount: Amount;
  claimFrom: string;
  unit: string;
  approvedExcVatAmount: Amount;
  approvedVatAmount: Amount;
}

/* Claim list */

export class ClaimRequestSearchCriteria {
  searchCriteria?: string;
  status?: ClaimRequestStatusEnum;
  requestedDateFrom?: string;
  requestedDateTo?: string;
  page: number;
  size: number;
}

export class ClaimRequestPagination {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: ClaimRequestContent[];
}

export class ClaimRequestContent {
  id: string;
  claimRequestNo: string;
  roNo: string;
  orderNo: string;
  storeCode: string;
  storeName: string;
  requestAmt: number;
  requestedDate: string;
  claimRequestStatus: ClaimRequestStatusEnum;
  histories: AuditLogDtoAuditLog[];
  requestedBy: string;
}

export class AuditLogDtoAuditLog {
  activity: string;
  description: string;
  editBy: string;
  editByName: string;
  editDate: string;
}

export class ClaimRequestExportCriteria extends BaseReport {
  createdDateFrom: string;
  createdDateTo: string;
  status: string[];

  constructor(init?: Partial<ClaimRequestExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
