import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { StockProductSearchCriteria, StockTransferItem } from '../../models/stock-transfer-request.model';
import { StockProductActions, StockProductActionTypes } from '../actions/stock-product.actions';
import { BaseState } from '../state/base.state';

export interface StockProductState extends EntityState<StockTransferItem>, BaseState {
  criteriaObject: StockProductSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<StockTransferItem> = createEntityAdapter<StockTransferItem>({
  selectId: (stockTransferItem: StockTransferItem) => `${stockTransferItem.articleNo}`
});

export const initialStockProductResponseState: StockProductState = adapter.getInitialState({
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20
});

export function stockProductReducers(
  state = initialStockProductResponseState,
  action: StockProductActions
): StockProductState {
  switch (action.type) {
    case StockProductActionTypes.STOCK_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case StockProductActionTypes.STOCK_PRODUCT_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
