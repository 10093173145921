import { createSelector } from '@ngrx/store';
import * as fromShelfCreateResponseState from '../reducers/shelf-fix-asset-create.reducers';
import { AppStates } from '../state/app.states';

const selectShelfFixAssetCreateState = (state: AppStates) => state.shelfFixAssetCreate;

export const selectShelfFixAsset = createSelector(
  selectShelfFixAssetCreateState,
  (state: fromShelfCreateResponseState.ShelfFixAssetCreateResponseState | null) => state
);

export const selectShelfApproveRejectStatus = createSelector(
  selectShelfFixAssetCreateState,
  (state: fromShelfCreateResponseState.ShelfFixAssetCreateResponseState) => {
    return state.isApproveRejectSuccess;
  }
);
