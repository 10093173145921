import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  ShelfFixAssetRequestList,
  ShelfFixAssetRequestListSearchCriteria,
  ShelfFixAssetRequestViewResponse
} from '../../models/shelf-request.model';
import { ShelfRequestActions, ShelfRequestActionType } from '../actions/shelf-request.actions';
import { BaseState } from '../state/base.state';

export interface ShelfFixAssetRequestState extends EntityState<ShelfFixAssetRequestList>, BaseState {
  selected: ShelfFixAssetRequestViewResponse;
  criteriaObject: ShelfFixAssetRequestListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
  isDeleteSuccess: boolean;
}

export const adapter: EntityAdapter<ShelfFixAssetRequestList> = createEntityAdapter<ShelfFixAssetRequestList>();

export const initialShelfResponseState: ShelfFixAssetRequestState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null,
  isDeleteSuccess: null
});

export function shelfFixAssetRequestReducers(
  state = initialShelfResponseState,
  action: ShelfRequestActions
): ShelfFixAssetRequestState {
  switch (action.type) {
    case ShelfRequestActionType.SHELF_REQUEST_LIST_REQUEST_FIX_ASSET:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case ShelfRequestActionType.SHELF_REQUEST_LIST_RESPONSE_FIX_ASSET:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_RESPONSE_FIX_ASSET:
      return {
        ...state,
        selected: action.payload
      };
    case ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_RESPONSE_FIX_ASSET:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    case ShelfRequestActionType.SHELF_REQUEST_DELETE_RESPONSE_FIX_ASSET:
      return {
        ...state,
        isDeleteSuccess: action.payload.isSuccess
      };
    case ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_RESET_FIX_ASSET:
      return {
        ...state,
        selected: null
      };
    case ShelfRequestActionType.SHELF_REQUEST_DELETE_RESET_FIX_ASSET:
      return {
        ...state,
        isDeleteSuccess: false
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
