import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NEVER, Observable } from 'rxjs';
import { filter, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../base/base.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import {
  OrderDeliveryScheduleType,
  PurchaseConditionPageModes,
  PurchaseConditionPageTab,
  PurchaseConditionRequestStatusEnum,
  PurchaseConditionTitleMode,
  SupplierInformationStatusEnum
} from '../../../shared/enum/purchase-condition.enum';
import { NewRequestTypeEnum } from '../../../shared/enum/request-step.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  PurchaseConditionRequest,
  PurchaseConditionRequestWeeklySchedule,
  PurchaseConditionTabResponse,
  SupplierContacts,
  SupplierDistributionCenters
} from '../../../shared/models/purchase-condition-request.model';
import {
  AddUpdateContactAndDCSuccess,
  PurchaseConditionViewResponse
} from '../../../shared/models/purchase-condition.model';
import { SupplierViewResponse } from '../../../shared/models/supplier.model';
import { AuthGuardService } from '../../../shared/services';
import { PurchaseConditionRequestService } from '../../../shared/services/purchase-condition-request.service';
import {
  CancelPurchaseConditionRequest,
  DeletePurchaseConditionRequest,
  PurchaseConditionContractRequested,
  PurchaseConditionDcRequested,
  PurchaseConditionRequestApprove,
  PurchaseConditionRequestApproveRejectSuccess,
  PurchaseConditionRequestEditSubmit,
  PurchaseConditionRequestListSearchRequested,
  PurchaseConditionRequestReject,
  PurchaseConditionRequestReset,
  PurchaseConditionRequestSave,
  PurchaseConditionRequestSubmit,
  PurchaseConditionRequestUpdateSaveById,
  PurchaseConditionRequestViewRequested,
  PurchaseConditionViewRequested,
  PurchaseConditionViewReset
} from '../../../shared/store/actions/purchase-condition.actions';
import {
  ResetSupplierBySupplierCodeRequestSelected,
  SupplierBySupplierCodeFromPurchaseRequestAction
} from '../../../shared/store/actions/supplier.actions';
import {
  selectAllPurchaseConditionRequestWeeklyScheduleList,
  selectImportId,
  selectPurchaseConditionAddUpdateContactDcStatus,
  selectPurchaseConditionReqMov,
  selectPurchaseConditionRequestApproveRejectStatus,
  selectPurchaseConditionRequestCriteria,
  selectPurchaseConditionRequestView,
  selectPurchaseConditionRequestWeeklyScheduleImportId,
  selectPurchaseConditionView
} from '../../../shared/store/selectors/purchase-condition.selectors';
import { selectSupplierBySupplierCode } from '../../../shared/store/selectors/supplier.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { PurchaseConditionPermission } from '../purchase-condition-permission';

@Component({
  selector: 'app-purchase-condition-view',
  templateUrl: './purchase-condition-view.component.html',
  styleUrls: ['./purchase-condition-view.component.scss']
})
export class PurchaseConditionViewComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Input() data: {
    title: string;
    id: string;
    mode: PurchaseConditionPageModes;
    supplierName: string;
    supplierCode: string;
    supplierNameDisplay: string;
    status: string;
  };

  public importId: string;
  public purchaseConditionRequest: PurchaseConditionRequest;
  public supplierInformationForm: FormGroup;
  public purchaseConditionForm: FormGroup;
  public purchaseConditionReqMOV: PurchaseConditionTabResponse[];
  public selectedTab: PurchaseConditionPageTab;
  public purchaseConditionTab = PurchaseConditionPageTab;
  public dirtyMinimumOrderForm = false;
  public isApprove = false;
  private localStore: Observable<any>;
  public scheduleImportId = null;
  public purchaseConditionRequestWeeklyScheduleList = [];
  public purchaseConditionPageMode = PurchaseConditionPageModes;
  public purchaseConditionRequestStatusEnum = PurchaseConditionRequestStatusEnum;
  public scheduleType: OrderDeliveryScheduleType = null;
  public purchaseConditionTitleMode = PurchaseConditionTitleMode;
  public purchaseConditionPermission = new PurchaseConditionPermission();
  public dirtyOrderSchedule = false;
  public dirtyInformation = false;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected readonly translate: TranslateService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected purchaseConditionRequestService: PurchaseConditionRequestService
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.selectedTab = PurchaseConditionPageTab.SUPPLIER_INFORMATION;

    this.purchaseConditionRequest = {
      id: this.data.id,
      version: null,
      supplierCode: this.data.supplierCode,
      supplierName: this.data.supplierName,
      requestType: null,
      requestStatus: this.data.status,
      purchaseConditionReqMOV: [],
      lastModifiedBy: null,
      lastModifiedByName: null,
      lastModifiedDate: null,
      requestNo: null,
      scheduleType: this.scheduleType,
      refScheduleImportId: null,
      refDiscountImportId: null,
      manualPurchaseConditionRequestWeeklyScheduleDto: [],
      supplierContacts: [],
      supplierDistributionCenters: [],
      auditLogs: [],
      movEdit: null,
      discountEdit: null,
      scheduleEdit: null
    } as PurchaseConditionRequest;

    this.scheduleType = this.purchaseConditionRequest.scheduleType;
    if ([PurchaseConditionPageModes.EDIT_REQUEST, PurchaseConditionPageModes.VIEW_REQUEST].includes(this.data.mode)) {
      this.store.dispatch(new PurchaseConditionRequestViewRequested(this.purchaseConditionRequest.id));
    } else if ([PurchaseConditionPageModes.VIEW, PurchaseConditionPageModes.EDIT].includes(this.data.mode)) {
      this.store.dispatch(new PurchaseConditionViewRequested(this.purchaseConditionRequest.supplierCode));
    }

    this.initialSubscription();
    this.initialSupplierInformation();
    this.initialForm();
    this.purchaseConditionPermission.checkPermission(this.authGuardService);
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetSupplierBySupplierCodeRequestSelected());
    this.store.dispatch(new PurchaseConditionRequestReset());
    this.store.dispatch(new PurchaseConditionViewReset());
    this.store.dispatch(new PurchaseConditionRequestApproveRejectSuccess({ isSuccess: null }));
  }

  createSupplierInformationForm(supplierInformation: SupplierViewResponse) {
    this.supplierInformationForm = this.fb.group({
      supplierCode: [{ value: supplierInformation.supplierCode, disabled: true }, Validators.required],
      supplierName: [{ value: supplierInformation.supplierName, disabled: true }, Validators.required],
      taxCode: [{ value: supplierInformation.taxCode, disabled: true }, Validators.required],
      branchNo: [{ value: supplierInformation.branchNo, disabled: true }],
      address: [{ value: supplierInformation.address, disabled: true }, Validators.required],
      city: [{ value: supplierInformation.city, disabled: true }, Validators.required],
      province: [{ value: supplierInformation.province, disabled: true }, Validators.required],
      postalCode: [{ value: supplierInformation.postalCode, disabled: true }, Validators.required],
      country: [{ value: supplierInformation.country, disabled: true }, Validators.required],
      contactName: [{ value: supplierInformation.contactName, disabled: true }],
      contactNumber: [{ value: supplierInformation.contactNumber, disabled: true }],
      paymentTerm: [{ value: supplierInformation.paymentTerm, disabled: true }],
      supplierVat: [{ value: supplierInformation.supplierVat, disabled: true }],
      supplierStatus: [{ value: SupplierInformationStatusEnum[supplierInformation.status], disabled: true }]
    });
  }

  initialForm(): void {
    this.purchaseConditionForm = this.fb.group({
      importId: null,
      contactInformation: this.fb.array([]),
      dcInformation: this.fb.array([]),
      requestType: null,
      movEdit: null,
      discountEdit: null,
      scheduleEdit: null
    });
  }

  onCloseFullModal() {
    if (
      (this.supplierInformationForm &&
        (this.supplierInformationForm.touched || this.supplierInformationForm.dirty)) ||
      this.dirtyMinimumOrderForm ||
      (this.purchaseConditionForm && (this.purchaseConditionForm.touched || this.purchaseConditionForm.dirty)) ||
      this.dirtyOrderSchedule ||
      this.dirtyInformation
    ) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  onSelectTab(tab: PurchaseConditionPageTab) {
    this.selectedTab = tab;
  }

  isActive(currentTab, tab: PurchaseConditionPageTab) {
    return currentTab === tab ? 'active' : '';
  }

  getColorStatus(status: string): string {
    return status ? status.toLowerCase() : '';
  }

  initialSupplierInformation() {
    this.store.dispatch(
      new SupplierBySupplierCodeFromPurchaseRequestAction(this.purchaseConditionRequest.supplierCode)
    );
  }

  initialPurchaseConditionRequestData(purchaseConditionRequest: PurchaseConditionRequest) {
    if (purchaseConditionRequest !== null) {
      this.purchaseConditionRequest = { ...this.purchaseConditionRequest, ...purchaseConditionRequest };

      this.purchaseConditionForm.patchValue({
        requestType: this.purchaseConditionRequest.requestType,
        movEdit: this.purchaseConditionRequest.movEdit,
        scheduleEdit: this.purchaseConditionRequest.scheduleEdit,
        discountEdit: this.purchaseConditionRequest.discountEdit
      });

      this.setScheduleType(this.purchaseConditionRequest.scheduleType);
    }
  }

  initialPurchaseConditionData(purchaseConditionResponse: PurchaseConditionViewResponse) {
    if (purchaseConditionResponse !== null) {
      this.purchaseConditionRequest = {
        ...this.purchaseConditionRequest,
        ...purchaseConditionResponse,
        supplierCode: purchaseConditionResponse.vendorNo
      };

      this.importId = this.purchaseConditionRequest.refDiscountImportId;

      this.purchaseConditionForm.patchValue({
        requestType: this.purchaseConditionRequest.requestType,
        movEdit: this.purchaseConditionRequest.movEdit,
        scheduleEdit: this.purchaseConditionRequest.scheduleEdit,
        discountEdit: this.purchaseConditionRequest.discountEdit
      });
      this.setScheduleType(this.purchaseConditionRequest.scheduleType);
    }

    this.store.dispatch(
      new PurchaseConditionDcRequested({ supplierCode: this.purchaseConditionRequest.supplierCode })
    );

    this.store.dispatch(
      new PurchaseConditionContractRequested({ supplierCode: this.purchaseConditionRequest.supplierCode })
    );
  }

  initialSubscription() {
    if ([PurchaseConditionPageModes.VIEW, PurchaseConditionPageModes.EDIT].includes(this.data.mode)) {
      this.localStore
        .pipe(
          select(selectPurchaseConditionView),
          filter(data => Boolean(data))
        )
        .subscribe(res => {
          this.initialPurchaseConditionData(res);
        });

      this.localStore
        .pipe(
          select(selectPurchaseConditionAddUpdateContactDcStatus),
          filter(data => Boolean(data))
        )
        .subscribe(res => {
          this.alertSuccessModal(res);
        });
    } else {
      this.localStore
        .pipe(
          select(selectPurchaseConditionRequestView),
          filter(data => Boolean(data))
        )
        .subscribe(res => {
          this.initialPurchaseConditionRequestData(res);
        });
    }

    this.localStore
      .pipe(
        select(selectSupplierBySupplierCode),
        filter(data => Boolean(data))
      )
      .subscribe(res => {
        this.purchaseConditionRequest = {
          ...this.purchaseConditionRequest,
          supplierName: res.supplierName
        };
        this.createSupplierInformationForm(res);
      });

    this.store
      .pipe(
        select(selectPurchaseConditionReqMov),
        filter(data => Boolean(data))
      )
      .subscribe(list => (this.purchaseConditionReqMOV = list));

    this.store
      .pipe(
        select(selectImportId),
        filter(data => Boolean(data))
      )
      .subscribe(importId => {
        this.purchaseConditionForm.patchValue({
          importId: importId
        });
        this.importId = importId;
      });

    this.localStore
      .pipe(
        select(selectAllPurchaseConditionRequestWeeklyScheduleList),
        filter(data => Boolean(data))
      )
      .subscribe(weeklyScheduleList => {
        const requestWeeklySchedule = this.prepareOrderScheduleForSave(weeklyScheduleList);
        // this.scheduleImportId = requestWeeklySchedule.refScheduleImportId;
        this.purchaseConditionRequestWeeklyScheduleList =
          requestWeeklySchedule.purchaseConditionRequestWeeklyScheduleList;
      });

    this.localStore
      .pipe(select(selectPurchaseConditionRequestWeeklyScheduleImportId))
      .subscribe(weeklyScheduleImportId => {
        this.scheduleImportId = weeklyScheduleImportId;
      });

    if (this.purchaseConditionRequestStatusEnum.AWAITING_APPROVAL) {
      this.localStore
        .pipe(
          select(selectPurchaseConditionRequestApproveRejectStatus),
          filter(data => Boolean(data)),
          switchMap(isSuccess => {
            if (isSuccess) {
              return this.approveOrRejectSuccess();
            }
            return NEVER;
          })
        )
        .subscribe();
    }
  }

  onSave() {
    const body = this.prepareRequestData();
    this.dirtyMinimumOrderForm = false;

    if (this.data.id) {
      this.store.dispatch(new PurchaseConditionRequestUpdateSaveById(body));
    } else {
      this.store.dispatch(new PurchaseConditionRequestSave(body));
    }
  }

  onSubmit() {
    const body = this.prepareRequestData();
    if (!this.validateAddedCondition(body)) {
      return;
    }

    this.handleConfirmSubmit(body);
  }

  handleConfirmSubmit(body) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (ModalButtonResponseEnum.OK === result) {
          if (
            [this.purchaseConditionPageMode.EDIT_REQUEST].includes(this.data.mode) &&
            [this.purchaseConditionRequest.requestStatus].includes(
              PurchaseConditionRequestStatusEnum.AWAITING_APPROVAL
            )
          ) {
            this.store.dispatch(new PurchaseConditionRequestEditSubmit(body));
          } else {
            this.store.dispatch(new PurchaseConditionRequestSubmit(body));
          }
        }
      });
  }

  validateAddedCondition(body: PurchaseConditionRequest) {
    if (
      body.purchaseConditionReqMOV.length === 0 &&
      !body.refScheduleImportId &&
      !body.refDiscountImportId &&
      body.manualPurchaseConditionRequestWeeklyScheduleDto.length === 0 &&
      !body.scheduleType
    ) {
      this.alertErrorModal(
        'Purchase Condition is required.<br/>Please add MOV or Discount or Order/Delivery Schedule.'
      );
      this.selectedTab = PurchaseConditionPageTab.SUPPLIER_CONDITION;
      return false;
    } else if (
      body.scheduleType &&
      body.manualPurchaseConditionRequestWeeklyScheduleDto.length === 0 &&
      !body.refScheduleImportId
    ) {
      this.alertErrorModal('Order/Delivery Schedule is required.');
      this.selectedTab = PurchaseConditionPageTab.ORDER_DELIVERY_SCHEDULE;
      return false;
    }
    return true;
  }

  alertErrorModal(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message: message
      }
    });
  }

  alertSuccessModal(successResponse: AddUpdateContactAndDCSuccess) {
    const initialState = {
      title: successResponse.title,
      message: successResponse.message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
      }
    });
  }
  prepareRequestData(): PurchaseConditionRequest {
    const rawSupplierData = this.supplierInformationForm.getRawValue();
    const rawPurchaseData = this.purchaseConditionForm.getRawValue();

    return {
      supplierCode: rawSupplierData.supplierCode,
      supplierName: rawSupplierData.supplierName,
      requestType:
        [PurchaseConditionPageModes.CREATE].includes(this.data.mode) ||
        ([PurchaseConditionPageModes.EDIT_REQUEST].includes(this.data.mode) &&
          this.purchaseConditionRequest.requestType &&
          this.purchaseConditionRequest.requestType === NewRequestTypeEnum.NEW)
          ? NewRequestTypeEnum.NEW
          : NewRequestTypeEnum.EDIT,
      requestStatus: PurchaseConditionRequestStatusEnum[this.purchaseConditionRequest.requestStatus],
      purchaseConditionReqMOV: this.purchaseConditionReqMOV,
      requestedDate: null,
      lastModifiedBy: null,
      lastModifiedByName: null,
      requestNo: null,
      scheduleType: this.scheduleType,
      refScheduleImportId:
        rawPurchaseData.scheduleEdit &&
        this.scheduleType === OrderDeliveryScheduleType.WEEKLY_MANUAL &&
        this.purchaseConditionRequestWeeklyScheduleList.length > 0
          ? null
          : this.scheduleImportId,
      refDiscountImportId: this.importId,
      manualPurchaseConditionRequestWeeklyScheduleDto: this.purchaseConditionRequestWeeklyScheduleList,
      supplierContacts: this.prepareSupplierContacts(rawPurchaseData.contactInformation),
      supplierDistributionCenters: this.prepareSupplierDistributionCenters(rawPurchaseData.dcInformation),
      id: [PurchaseConditionPageModes.EDIT].includes(this.data.mode) ? null : this.purchaseConditionRequest.id,
      version: this.purchaseConditionRequest.version,
      movEdit: rawPurchaseData.movEdit,
      discountEdit: rawPurchaseData.discountEdit,
      scheduleEdit: rawPurchaseData.scheduleEdit
    } as PurchaseConditionRequest;
  }

  prepareSupplierContacts(contactInformation): SupplierContacts[] {
    if (contactInformation.length) {
      return contactInformation.map(
        item =>
          ({
            name: item.name,
            phoneNo: item.detail,
            remark: item.remark
          } as SupplierContacts)
      );
    }
  }

  prepareSupplierDistributionCenters(dcInformation): SupplierDistributionCenters[] {
    if (dcInformation.length) {
      return dcInformation.map(
        item =>
          ({
            name: item.name,
            address: item.detail,
            remark: item.remark
          } as SupplierDistributionCenters)
      );
    }
  }

  prepareOrderScheduleForSave(requestWeeklyScheduleList: PurchaseConditionRequestWeeklySchedule[]) {
    let purchaseConditionScheduleSave = [];
    let refScheduleImportId = null;

    if (
      requestWeeklyScheduleList[0] &&
      this.purchaseConditionRequest.scheduleType === OrderDeliveryScheduleType.WEEKLY_MANUAL
    ) {
      requestWeeklyScheduleList.forEach(val => {
        val.warehouses.forEach(wms =>
          purchaseConditionScheduleSave.push({
            warehouse: {
              code: wms.code,
              wmsCode: wms.wmsCode,
              name: wms.name
            },
            orderSchedules: val.orderSchedules,
            deliverySchedules: val.deliverySchedules,
            leadTime: val.leadTime,
            articleNo: val.articleNo,
            productLevel: val.productLevel
          })
        );
      });
    } else if (
      requestWeeklyScheduleList[0] &&
      [OrderDeliveryScheduleType.WEEKLY, OrderDeliveryScheduleType.MONTHLY].includes(
        this.purchaseConditionRequest.scheduleType
      )
    ) {
      refScheduleImportId = requestWeeklyScheduleList[0].importId;
      purchaseConditionScheduleSave = [];
    }

    return {
      refScheduleImportId,
      purchaseConditionRequestWeeklyScheduleList: purchaseConditionScheduleSave
    };
  }

  approve() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to "Approve"?',
        label: 'Comment',
        isRequiredConfirmMessage: false,
        okText: 'Approve',
        okClass: 'btn btn-special-approve'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.isApprove = true;
          this.store.dispatch(
            new PurchaseConditionRequestApprove({
              id: this.purchaseConditionRequest.id,
              version: this.purchaseConditionRequest.version,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  reject() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to "Reject"?',
        label: 'Comment',
        isRequiredConfirmMessage: true,
        okText: 'Reject',
        okClass: 'btn btn-special-reject'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.isApprove = false;
          this.store.dispatch(
            new PurchaseConditionRequestReject({
              id: this.purchaseConditionRequest.id,
              version: this.purchaseConditionRequest.version,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  approveOrRejectSuccess(): Observable<any> {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: this.isApprove ? 'The request has been approved.' : 'The request has been rejected.'
      },
      backdrop: 'static'
    });

    return alertModal.content.action.pipe(
      untilComponentDestroyed(this),
      switchMap(alert => {
        return this.localStore.pipe(
          select(selectPurchaseConditionRequestCriteria),
          tap(criteria => {
            this.store.dispatch(new PurchaseConditionRequestListSearchRequested(criteria));
          }),
          take(1),
          mapTo(alert)
        );
      }),
      tap((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK || result === ModalButtonResponseEnum.CANCEL) {
          alertModal.hide();
          this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
        }
      })
    );
  }

  toggleToEdit() {
    if (this.data.mode === PurchaseConditionPageModes.VIEW_REQUEST) {
      this.data.mode = PurchaseConditionPageModes.EDIT_REQUEST;
    } else if (this.data.mode === PurchaseConditionPageModes.VIEW) {
      this.purchaseConditionRequestService
        .checkPurchaseConditionExists(this.purchaseConditionRequest.supplierCode)
        .pipe(
          untilComponentDestroyed(this),
          filter(res => res)
        )
        .subscribe(isAllowToggleEdit => {
          if (!isAllowToggleEdit.result) {
            this.data.mode = PurchaseConditionPageModes.EDIT;
          } else {
            this.modalService.show(AlertModalComponent, {
              initialState: {
                title: 'Failed',
                message: 'This purchase condition has been edited and waiting for approval.'
              }
            });
          }
        });
    }
  }

  setScheduleType(scheduleType: OrderDeliveryScheduleType) {
    this.purchaseConditionRequest.scheduleType = scheduleType;
    this.scheduleType = scheduleType;
  }

  onDelete(id: string) {
    if (this.purchaseConditionRequest.requestStatus !== PurchaseConditionRequestStatusEnum.DRAFT) {
      return;
    }
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete the request?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (ModalButtonResponseEnum.OK === result) {
          this.store.dispatch(new DeletePurchaseConditionRequest({ id }));
        }
      });
  }

  onCancelRequest() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number "${this.purchaseConditionRequest.requestNo}"?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new CancelPurchaseConditionRequest({
              id: this.data.id,
              comment: confirmModalRef.content.confirmMessage,
              version: this.purchaseConditionRequest.version
            })
          );
        }
      });
  }

  setDirtyOrderSchedule(event) {
    this.dirtyOrderSchedule = event;
  }

  setDirtyInformation(event) {
    this.dirtyInformation = event;
  }

  showHistory() {
    let historyHeader;
    if ([PurchaseConditionPageModes.VIEW, PurchaseConditionPageModes.EDIT].includes(this.data.mode)) {
      historyHeader = `Supplier Name: ${this.purchaseConditionRequest.supplierName}`;
    } else {
      historyHeader = `Request No.: ${this.purchaseConditionRequest.requestNo}`;
    }

    const initialState = {
      title: 'History',
      historyHeader,
      auditLogs: this.purchaseConditionRequest.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {}
  // Override since list page already subscribe
  subscribeForVersionError() {}
}
