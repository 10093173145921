import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import {
  InformationActionEnum,
  InformationTypeEnum,
  PurchaseConditionPageModes
} from '../../../../shared/enum/purchase-condition.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import {
  PurchaseConditionContractAndDcUpdateErrorAction,
  PurchaseConditionContractRequested,
  PurchaseConditionDcRequested
} from '../../../../shared/store/actions/purchase-condition.actions';
import { selectContractAndDcResponseError } from '../../../../shared/store/selectors/purchase-condition.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-modal-add-information',
  templateUrl: './modal-add-information.component.html',
  styleUrls: ['./modal-add-information.component.scss']
})
export class ModalAddInformationComponent implements OnInit, OnDestroy {
  @Output() addInformationForm: EventEmitter<any> = new EventEmitter();
  public isContractType: boolean;
  public title: string;
  public rowId: number;
  public labelInputTextFirst: string;
  public labelInputTextSecondary: string;
  public type: InformationTypeEnum;
  public action: InformationActionEnum;
  public informationFormIndex: FormGroup;
  public form: FormGroup;
  public isAdded = false;
  public isDuplicated = { isDuplicate: false, errMessage: '' };
  public informationActionEnum = InformationActionEnum;
  public itemList: FormArray;
  public pageMode: PurchaseConditionPageModes;
  public subscription = new Subscription();
  private supplierCode: string;
  public isSubmit = false;
  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly fb: FormBuilder,
    public readonly bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) {}

  ngOnInit() {
    this.createForm();
    if ([PurchaseConditionPageModes.VIEW, PurchaseConditionPageModes.EDIT].includes(this.pageMode)) {
      this.initCheckDuplicateForPurchaseCondition();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.store.dispatch(new PurchaseConditionContractAndDcUpdateErrorAction(null));
  }

  createForm(): FormGroup {
    return (this.form = this.fb.group({
      id: [this.informationFormIndex ? this.informationFormIndex.get('id').value : null],
      name: [
        { value: this.informationFormIndex ? this.informationFormIndex.get('name').value : null, disabled: false },
        Validators.required
      ],
      detail: [
        { value: this.informationFormIndex ? this.informationFormIndex.get('detail').value : null, disabled: false },
        Validators.required
      ],
      remark: [
        { value: this.informationFormIndex ? this.informationFormIndex.get('remark').value : null, disabled: false }
      ],
      version: [this.informationFormIndex ? this.informationFormIndex.get('version').value : null]
    }));
  }

  initCheckDuplicateForPurchaseCondition() {
    this.subscription.add(
      this.store
        .select(selectContractAndDcResponseError)
        .pipe(switchMap(error => this.errorCurrentSessionMessage(error)))
        .subscribe(err => {
          this.isSubmit = false;
          if (err) {
            if (err.message) {
              this.isDuplicated = { isDuplicate: true, errMessage: err.message };
            }
          } else {
            this.isDuplicated = { isDuplicate: false, errMessage: '' };

            if (this.bsModalRef) {
              this.bsModalRef.hide();
            }
          }
        })
    );
  }

  errorCurrentSessionMessage(error): Observable<any> {
    if (error && error.code === '00001') {
      const initialState = {
        title: 'Failed',
        message: 'The current session is no longer because data has been changed.',
        isRefresh: false
      };
      const alertModal = this.modalService.show(AlertModalComponent, {
        initialState
      });
      return alertModal.content.action.pipe(
        untilComponentDestroyed(this),
        tap((result: ModalButtonResponseEnum) => {
          if (result === ModalButtonResponseEnum.OK) {
            alertModal.hide();
            if (this.isContractType) {
              this.store.dispatch(new PurchaseConditionContractRequested({ supplierCode: this.supplierCode }));
            } else {
              this.store.dispatch(new PurchaseConditionDcRequested({ supplierCode: this.supplierCode }));
            }
            this.store.dispatch(new PurchaseConditionContractAndDcUpdateErrorAction(null));

            if (this.bsModalRef) {
              this.bsModalRef.hide();
            }
          }
        })
      );
    } else {
      return of(error);
    }
  }

  addInformation() {
    this.isAdded = true;
    if (this.form.invalid) {
      return;
    }

    if ([PurchaseConditionPageModes.VIEW, PurchaseConditionPageModes.EDIT].includes(this.pageMode)) {
      this.isSubmit = true;
      if (this.action === InformationActionEnum.ADD) {
        this.addInformationForm.emit({ form: this.form.getRawValue(), action: InformationActionEnum.ADD });
      } else {
        this.addInformationForm.emit({ form: this.form.getRawValue(), action: InformationActionEnum.EDIT });
      }
    } else {
      if (this.checkDuplicate) {
        return;
      }

      if (this.action === InformationActionEnum.ADD) {
        this.addInformationForm.emit({ form: this.form.getRawValue(), action: InformationActionEnum.ADD });
      } else {
        this.informationFormIndex.patchValue(this.form.getRawValue());
      }

      this.bsModalRef.hide();
    }
  }

  get checkDuplicate(): boolean {
    let isDuplicated = false;

    if (this.itemList.length) {
      this.itemList.controls.forEach((element, i) => {
        const nameFromList = (element.get('name').value as string).trim().toLocaleLowerCase();
        const detailFromList = (element.get('detail').value as string).trim().toLocaleLowerCase();

        const { name, detail } = this.form.getRawValue();
        const nameForm = name.trim().toLocaleLowerCase();
        const detailForm = detail.trim().toLocaleLowerCase();

        if (!this.rowId || this.rowId !== i + 1) {
          if (this.isContractType) {
            if (`${nameFromList}${detailFromList}` === `${nameForm}${detailForm}`) {
              isDuplicated = true;
              this.isDuplicated = { isDuplicate: isDuplicated, errMessage: 'Duplicated contact name and number.' };
            }
          } else {
            if (`${nameFromList}` === `${nameForm}`) {
              isDuplicated = true;
              this.isDuplicated = { isDuplicate: isDuplicated, errMessage: 'Duplicated DC Name.' };
            }
          }
        }
      });
    }

    return isDuplicated;
  }
}
