import { Action } from '@ngrx/store';
import { OrderDeliveryScheduleType } from '../../enum/purchase-condition.enum';
import { ErrorResponse } from '../../models/error.model';
import {
  ApproveRejectPurchaseConditionRequest,
  ItemConditionListCriteria,
  ItemConditionListResponse,
  PurchaseConditionListResponse,
  PurchaseConditionListSearchCriteria,
  PurchaseConditionRequest,
  PurchaseConditionRequestListResponse,
  PurchaseConditionRequestListSearchCriteria,
  PurchaseConditionRequestWeeklyScheduleImportCriteria,
  PurchaseConditionRequestWeeklyScheduleResponse,
  PurchaseConditionTabResponse,
  RequestWithComment
} from '../../models/purchase-condition-request.model';
import {
  PurchaseConditionSupplierContractModel,
  PurchaseConditionSupplierDcModel,
  PurchaseConditionViewResponse,
  SupplierInformationForPurchaseCondition
} from '../../models/purchase-condition.model';

export enum PurchaseConditionActionTypes {
  PurchaseConditionRequestListSearchRequested = '[PurchaseCondition] Purchase Condition Request Lis Search Requested',
  PurchaseConditionRequestListLoaded = '[PurchaseCondition] Purchase Conditions Request Lis Loaded',
  PurchaseConditionRequestReset = '[PurchaseCondition] Purchase Conditions Request Reset',
  PurchaseConditionScheduleSettingMode = '[PurchaseCondition] Purchase Conditions Schedule Setting Mode',
  PurchaseConditionReqMOVLoaded = '[PurchaseCondition] Purchase Conditions Minimum Order',
  ItemConditionListRequested = '[PurchaseCondition] get ItemCondition Requested',
  ItemConditionListLoaded = '[PurchaseCondition] get ItemCondition Loaded',
  PurchaseConditionRequestSave = '[PurchaseCondition] Purchase Condition Request Save',
  PurchaseConditionRequestUpdateSaveById = '[PurchaseCondition] Purchase Condition Request Update Save By Id',
  PurchaseConditionRequestWeeklyScheduleLoaded = '[PurchaseCondition] Purchase Condition Request Weekly Schedule List',
  PurchaseConditionRequestWeeklyScheduleImportRequested = '[PurchaseCondition] Purchase Condition Request Weekly Schedule Requested List',
  PurchaseConditionListSearchRequested = '[PurchaseCondition] Purchase Condition List Search Requested',
  PurchaseConditionListSearchLoaded = '[PurchaseCondition] Purchase Conditions List Loaded',
  PurchaseConditionRequestSubmit = '[PurchaseCondition] Purchase Condition Request Submit',
  PurchaseConditionRequestViewRequested = '[PurchaseCondition] Purchase Condition Request View Requested',
  PurchaseConditionRequestViewLoaded = '[PurchaseCondition] Purchase Condition Request View Loaded',
  PurchaseConditionRequestApprove = '[PurchaseCondition] Purchase Condition Request Approve Requested',
  PurchaseConditionRequestReject = '[PurchaseCondition] Purchase Condition Request Reject Requested',
  PurchaseConditionRequestApproveRejectSuccess = '[PurchaseCondition] Request PurchaseCondition Approve/Reject Success',
  PurchaseConditionViewRequested = '[PurchaseCondition] Purchase Condition View Requested',
  PurchaseConditionViewLoaded = '[PurchaseCondition] Purchase Condition View Loaded',
  PurchaseConditionViewReset = '[PurchaseCondition] Purchase Condition View Reset',
  PurchaseConditionDiscountRequested = '[PurchaseCondition] Purchase Condition Discount Loaded',
  PurchaseConditionRequestEditSubmitRequested = '[PurchaseCondition] Purchase Condition Request Edit Submit',
  PurchaseConditionRemoveOrderScheduleImportId = '[PurchaseCondition] Purchase Condition Remove Order Schedule ImportId',
  PurchaseConditionWeeklyScheduleRequested = '[PurchaseCondition] Purchase Condition weekly schedule Requested',

  PurchaseConditionContractRequested = '[PurchaseCondition] Contract Request',
  PurchaseConditionDcRequested = '[PurchaseCondition] Dc Request',
  PurchaseConditionContractLoaded = '[PurchaseCondition] Contract Loaded',
  PurchaseConditionDcLoaded = '[PurchaseCondition] Dc Loaded',

  PurchaseConditionSupplierAddContract = '[PurchaseCondition] Purchase condition add Contract',
  PurchaseConditionSupplierUpdatedContract = '[PurchaseCondition] Purchase condition Updated Contract',
  PurchaseConditionSupplierDeletedContract = '[PurchaseCondition] Purchase condition Deleted Contract',
  PurchaseConditionSupplierAddUpdateContractDCStatusReset = '[PurchaseCondition] Purchase condition Add Update Contract and Dc Reset',

  PurchaseConditionSupplierAddDc = '[PurchaseCondition] Purchase condition add Dc',
  PurchaseConditionSupplierUpdatedDc = '[PurchaseCondition] Purchase condition Updated Dc',
  PurchaseConditionSupplierDeletedDc = '[PurchaseCondition] Purchase condition Deleted Dc',
  PurchaseConditionContractAndDcUpdateErrorAction = '[PurchaseCondition] Purchase condition error action',

  DeletePurchaseConditionRequest = '[PurchaseCondition] Delete Purchase Condition Request',
  CancelPurchaseConditionRequest = '[PurchaseCondition] Cancel Purchase Condition Request'
}

export class PurchaseConditionRequestListSearchRequested implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRequestListSearchRequested;
  constructor(public payload: PurchaseConditionRequestListSearchCriteria) {}
}

export class PurchaseConditionRequestListLoaded implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRequestListLoaded;
  constructor(public payload: PurchaseConditionRequestListResponse) {}
}

export class PurchaseConditionScheduleSettingMode implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionScheduleSettingMode;
  constructor(public payload: OrderDeliveryScheduleType) {}
}

export class PurchaseConditionReqMOVLoaded implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionReqMOVLoaded;
  constructor(public payload: PurchaseConditionTabResponse[]) {}
}

export class PurchaseConditionRequestWeeklyScheduleLoaded implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRequestWeeklyScheduleLoaded;
  constructor(public payload: PurchaseConditionRequestWeeklyScheduleResponse) {}
}

export class ItemConditionListRequested implements Action {
  readonly type = PurchaseConditionActionTypes.ItemConditionListRequested;
  constructor(public payload: ItemConditionListCriteria) {}
}

export class ItemConditionListLoaded implements Action {
  readonly type = PurchaseConditionActionTypes.ItemConditionListLoaded;
  constructor(public payload: ItemConditionListResponse) {}
}

export class PurchaseConditionRequestSave implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRequestSave;
  constructor(public payload: PurchaseConditionRequest) {}
}

export class PurchaseConditionRequestUpdateSaveById implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRequestUpdateSaveById;
  constructor(public payload: PurchaseConditionRequest) {}
}

export class PurchaseConditionRequestReset implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRequestReset;
  constructor() {}
}

export class PurchaseConditionRequestWeeklyScheduleImportRequested implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRequestWeeklyScheduleImportRequested;
  constructor(public payload: PurchaseConditionRequestWeeklyScheduleImportCriteria) {}
}

export class PurchaseConditionListSearchRequested implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionListSearchRequested;
  constructor(public payload: PurchaseConditionListSearchCriteria) {}
}

export class PurchaseConditionListSearchLoaded implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionListSearchLoaded;
  constructor(public payload: PurchaseConditionListResponse) {}
}

export class PurchaseConditionRequestSubmit implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRequestSubmit;
  constructor(public payload: PurchaseConditionRequest) {}
}

export class PurchaseConditionRequestViewRequested implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRequestViewRequested;
  constructor(public payload: string) {}
}

export class PurchaseConditionRequestViewLoaded implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRequestViewLoaded;
  constructor(public payload: PurchaseConditionRequest) {}
}

export class PurchaseConditionRequestApprove implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRequestApprove;
  constructor(public payload: ApproveRejectPurchaseConditionRequest) {}
}

export class PurchaseConditionRequestReject implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRequestReject;
  constructor(public payload: ApproveRejectPurchaseConditionRequest) {}
}

export class PurchaseConditionRequestApproveRejectSuccess implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRequestApproveRejectSuccess;
  constructor(public payload: { isSuccess: boolean }) {}
}

export class PurchaseConditionViewRequested implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionViewRequested;
  constructor(public payload: string) {}
}

export class PurchaseConditionViewLoaded implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionViewLoaded;
  constructor(public payload: PurchaseConditionViewResponse) {}
}

export class PurchaseConditionViewReset implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionViewReset;
  constructor() {}
}

export class PurchaseConditionDiscountRequested implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionDiscountRequested;
  constructor(public payload: ItemConditionListCriteria) {}
}

export class PurchaseConditionRequestEditSubmit implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRequestEditSubmitRequested;
  constructor(public payload: PurchaseConditionRequest) {}
}

export class PurchaseConditionRemoveOrderScheduleImportId implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionRemoveOrderScheduleImportId;
  constructor() {}
}

export class PurchaseConditionWeeklyScheduleRequested implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionWeeklyScheduleRequested;
  constructor(public payload: PurchaseConditionRequestWeeklyScheduleImportCriteria) {}
}

export class PurchaseConditionDcRequested implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionDcRequested;
  constructor(public payload: { supplierCode: string }) {}
}

export class PurchaseConditionContractRequested implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionContractRequested;
  constructor(public payload: { supplierCode: string }) {}
}

export class PurchaseConditionContractLoaded implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionContractLoaded;
  constructor(public payload: SupplierInformationForPurchaseCondition) {}
}

export class PurchaseConditionDcLoaded implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionDcLoaded;
  constructor(public payload: SupplierInformationForPurchaseCondition) {}
}

export class PurchaseConditionSupplierAddContract implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionSupplierAddContract;
  constructor(public payload: PurchaseConditionSupplierContractModel) {}
}
export class PurchaseConditionSupplierUpdatedContract implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionSupplierUpdatedContract;
  constructor(public payload: PurchaseConditionSupplierContractModel) {}
}

export class PurchaseConditionSupplierDeletedContract implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionSupplierDeletedContract;
  constructor(public payload: { id: string; supplierCode: string }) {}
}

export class PurchaseConditionSupplierAddUpdateContractDCResetStatus implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionSupplierAddUpdateContractDCStatusReset;

  constructor() {}
}

export class PurchaseConditionSupplierAddDc implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionSupplierAddDc;
  constructor(public payload: PurchaseConditionSupplierDcModel) {}
}
export class PurchaseConditionSupplierUpdatedDc implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionSupplierUpdatedDc;
  constructor(public payload: PurchaseConditionSupplierDcModel) {}
}

export class PurchaseConditionSupplierDeletedDc implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionSupplierDeletedDc;
  constructor(public payload: { id: string; supplierCode: string }) {}
}

export class DeletePurchaseConditionRequest implements Action {
  readonly type = PurchaseConditionActionTypes.DeletePurchaseConditionRequest;
  constructor(public payload: { id: string }) {}
}

export class CancelPurchaseConditionRequest implements Action {
  readonly type = PurchaseConditionActionTypes.CancelPurchaseConditionRequest;
  constructor(public payload: RequestWithComment) {}
}

export class PurchaseConditionContractAndDcUpdateErrorAction implements Action {
  readonly type = PurchaseConditionActionTypes.PurchaseConditionContractAndDcUpdateErrorAction;
  constructor(public payload: ErrorResponse) {}
}

export type PurchaseConditionActions =
  | PurchaseConditionRequestListSearchRequested
  | PurchaseConditionRequestListLoaded
  | PurchaseConditionScheduleSettingMode
  | PurchaseConditionReqMOVLoaded
  | ItemConditionListRequested
  | ItemConditionListLoaded
  | PurchaseConditionRequestWeeklyScheduleLoaded
  | PurchaseConditionRequestReset
  | PurchaseConditionRequestWeeklyScheduleImportRequested
  | PurchaseConditionListSearchRequested
  | PurchaseConditionListSearchLoaded
  | PurchaseConditionRequestSubmit
  | PurchaseConditionRequestViewRequested
  | PurchaseConditionRequestViewLoaded
  | PurchaseConditionRequestApprove
  | PurchaseConditionRequestReject
  | PurchaseConditionRequestApproveRejectSuccess
  | PurchaseConditionViewRequested
  | PurchaseConditionViewLoaded
  | PurchaseConditionViewReset
  | PurchaseConditionRequestEditSubmit
  | PurchaseConditionRemoveOrderScheduleImportId
  | PurchaseConditionWeeklyScheduleRequested
  | PurchaseConditionContractRequested
  | PurchaseConditionDcRequested
  | PurchaseConditionContractLoaded
  | PurchaseConditionDcLoaded
  | PurchaseConditionSupplierAddContract
  | PurchaseConditionSupplierUpdatedContract
  | DeletePurchaseConditionRequest
  | CancelPurchaseConditionRequest
  | PurchaseConditionContractAndDcUpdateErrorAction
  | PurchaseConditionSupplierAddUpdateContractDCResetStatus;
