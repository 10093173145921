<div class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-stock"></em>
    <h4>Manage Stock</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap d-none d-md-block">
        <button
          type="button"
          class="btn btn-primary mr-1"
          *ngIf="hasAdjustStockPermission()"
          (click)="showModalCreateAdjustStock()"
        >
          <em class="icon-plus"></em>
          Adjust Stock
        </button>

        <app-import-export-button [buttons]="buttons" (export)="openExportModal()" (import)="openImportModal()">
        </app-import-export-button>
      </div>

      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              aria-hidden="true"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="adjustStatus"
            [searchable]="false"
            [clearable]="false"
            (change)="onChangeStatus($event)"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
          >
          </ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Location Type
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="locationTypeList"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="locationType"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Adjust Type
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="adjustTypeList"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="adjustType"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        Created Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="createdDateFrom"
                          formControlName="createdDateFrom"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          (bsValueChange)="onChangeDateFrom($event)"
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          readonly
                        />
                        <label for="createdDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="createdDateTo"
                          formControlName="createdDateTo"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          (bsValueChange)="onChangeDateTo($event)"
                          [minDate]="minDate"
                          readonly
                        />
                        <label for="createdDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div
    class="top-search-criteria space-search-criteria"
    *ngIf="selectedlocationTypeTag || selectedAdjustTypeTag || createDateTag"
  >
    <div class="search-criteria" *ngIf="selectedlocationTypeTag">
      <a class="remove-criteria" (click)="clearLocationFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ selectedlocationTypeStringTag }}: {{ selectedlocationTypeTag }}</span>
    </div>
    <div class="search-criteria" *ngIf="selectedAdjustTypeTag">
      <a class="remove-criteria" (click)="clearAdjustTypeFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ selectedAdjustTypeStringTag }}: {{ selectedAdjustTypeTag }}</span>
    </div>
    <div class="search-criteria" *ngIf="createDateTag">
      <a class="remove-criteria" (click)="clearCreateDateFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ createDateStringTag }}: {{ createDateTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>
  <ng-container *ngIf="(listState$ | async)?.totalElements == 0">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="table-responsive">
      <table id="data-table" class="table table-striped table-bordered table-hover w-100" aria-hidden="true">
        <thead>
          <tr>
            <th class="text-center w-10">Request No</th>
            <th class="text-center w-10">Location Code</th>
            <th class="text-center w-15">Location Name</th>
            <th class="text-center w-10">Location Type</th>
            <th class="text-center w-15">Adjust Type</th>
            <th class="text-center w-15">Created By</th>
            <th class="text-center w-10">Created Date</th>
            <th class="text-center w-10">Status</th>
            <th class="text-center w-5">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of resultList$ | async; index as i">
            <td (click)="goToView(item)">
              {{ item.requestNo }}
            </td>
            <td (click)="goToView(item)">
              <app-click-to-copy [text]="item.locationCode"></app-click-to-copy>
            </td>
            <td (click)="goToView(item)">
              <app-click-to-copy [text]="item.locationName"></app-click-to-copy>
            </td>
            <td (click)="goToView(item)" class="text-center">
              {{ item.locationType | titlecase }}
            </td>
            <td (click)="goToView(item)">
              {{ item.adjustType ? ('ADJUST_TYPE.' + item.adjustType | translate) : '-' }}
            </td>
            <td (click)="goToView(item)">
              {{ item.createdByName }}
            </td>
            <td (click)="goToView(item)" class="text-center">
              {{ item.createdDate | amFromUtc | amLocal | dateDisplay: environment.dateTimeDisplay }}
            </td>

            <td (click)="goToView(item)" class=" adjust-status" [ngClass]="item.status | lowercase">
              {{ item.status | titlecase }}
            </td>
            <td class="text-center position-relative"></td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>

<div class="modal fade" bsModal #modalCreateAdjustStock="bs-modal" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title pull-left">Select location</h6>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalCreateAdjustStock()">
          <em class="icon-close"></em>
        </button>
      </div>
      <div class="modal-body admin-form">
        <div class="container-fluid">
          <div [formGroup]="selectLocationForm">
            <div class="form-group">
              <div class="mb-2">Location Type<span class="text-danger">*</span></div>
              <ng-select
                bindLabel="label"
                bindValue="value"
                [searchable]="false"
                [clearable]="false"
                [items]="locationTypeListByPermission"
                placeholder="Please select..."
                (change)="onChangeLocationType()"
                formControlName="locationType"
                [ngClass]="{ 'is-invalid': submitted && selectLocationForm.controls.locationType.errors }"
              >
              </ng-select>
              <div
                *ngIf="submitted && selectLocationForm.controls.locationType.errors?.required"
                class="invalid-feedback"
              >
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
            <div class="form-group">
              <div class="mb-2">Location Name<span class="text-danger">*</span></div>
              <ng-select
                bindLabel="label"
                bindValue="value"
                [searchable]="this.locationType === locationTypeEnum.STORE"
                [clearable]="false"
                [items]="this.locationType === locationTypeEnum.STORE ? (locationList$ | async) : wareHouseList"
                [loading]="locationSearchLoading"
                (change)="onChangeLocation()"
                [typeahead]="locationSearchInput$"
                placeholder="Please select..."
                formControlName="location"
                [ngClass]="{ 'is-invalid': submitted && selectLocationForm.controls.location.errors }"
              >
              </ng-select>
              <div
                *ngIf="submitted && selectLocationForm.controls.location.errors?.required"
                class="invalid-feedback"
              >
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-standard" (click)="hideModalCreateAdjustStock()" appPreventDoubleClick>
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="goToAdjustStock()" appPreventDoubleClick>
          Continue
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #exportModal="bs-modal">
  <div id="modal-export" class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Export Stock Adjustment Report</h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeExportModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body admin-form" [formGroup]="exportForm" (ngSubmit)="onExport()">
        <div class="form-row">
          <div class="form-group col-md-12">
            <div class="mb-2">
              Location Type
            </div>
            <ng-select
              placeholder="Please select..."
              [items]="locationTypeList"
              [searchable]="false"
              [clearable]="true"
              [multiple]="true"
              bindLabel="label"
              bindValue="value"
              formControlName="locationType"
            >
            </ng-select>
          </div>

          <div class="form-group  col-md-12">
            <div class="mb-2">
              Adjust Type
            </div>
            <ng-select
              placeholder="Please select..."
              [items]="adjustTypeList"
              [searchable]="false"
              [clearable]="true"
              [multiple]="true"
              bindLabel="label"
              bindValue="value"
              formControlName="adjustType"
            >
            </ng-select>
          </div>

          <div class="col-12">Created Date<span class="text-danger">*</span></div>
          <div class="form-group col-md-6">
            <label>{{ 'FROM' | translate }}</label>
            <input
              id="exportPeriodDateFrom"
              formControlName="periodDateFrom"
              type="text"
              placeholder="{{ environment.dateFormat }}"
              autocomplete="off"
              class="form-control calendar"
              bsDatepicker
              [bsConfig]="bsDateConfig"
              [maxDate]="periodMaxDate"
              (bsValueChange)="onChangeExportPeriodDateFrom($event)"
              readonly
            />
            <label for="exportPeriodDateFrom" class="icon-calendar btn-calendar"></label>
          </div>
          <div class="form-group col-md-6">
            <label>{{ 'TO' | translate }}</label>
            <input
              id="exportPeriodDateTo"
              formControlName="periodDateTo"
              type="text"
              placeholder="{{ environment.dateFormat }}"
              autocomplete="off"
              class="form-control calendar"
              bsDatepicker
              [minDate]="periodMinDate"
              [bsConfig]="{
                containerClass: 'theme-dark-blue',
                dateInputFormat: environment.dateFormat,
                showWeekNumbers: false
              }"
              (bsValueChange)="onChangeExportPeriodDateTo($event)"
              readonly
            />
            <label for="exportPeriodDateTo" class="icon-calendar btn-calendar"></label>
          </div>
        </div>

        <div *ngIf="responseExportError" class="alert-danger form-group mb-0 " role="alert">
          {{ responseExportError }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          (click)="onExport()"
          [disabled]="exportFormInValid"
          id="btnExport"
        >
          Export
        </button>
      </div>
    </div>
  </div>
</div>
