import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { StockProductSearchCriteria, StockTransferItem } from '../../../shared/models/stock-transfer-request.model';
import { AuthGuardService } from '../../../shared/services';
import { TdAssortmentService } from '../../../shared/services/td-assortment-service';
import { StockProductSelectedAddItem } from '../../../shared/store/actions/stock-product-selected.actions';
import { StockProductListRequestAction } from '../../../shared/store/actions/stock-product.actions';
import { StockProductState } from '../../../shared/store/reducers/stock-product.reducers';
import {
  getCountStockProductSelected,
  getStockProductSelectedById
} from '../../../shared/store/selectors/stock-product-selected.selectors';
import {
  selectAllStockProductList,
  selectStockProductList,
  selectStockProductListCriteria
} from '../../../shared/store/selectors/stock-product.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-stock-product-list',
  templateUrl: './stock-product-list.component.html',
  styleUrls: ['./stock-product-list.component.scss']
})
export class StockProductListComponent extends BaseSearchComponent<
  StockProductSearchCriteria,
  StockTransferItem,
  StockProductState
> {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() notifyLeaveFormOutput: EventEmitter<boolean> = new EventEmitter<boolean>();

  public totalOrder$: Observable<number>;
  public localStore: Observable<any>;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    protected readonly logger: NGXLogger,
    protected tdAssortmentService: TdAssortmentService,
    protected spinner: NgxSpinnerService
  ) {
    super(store, modalService, selectAllStockProductList, selectStockProductList);
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });
  }

  doAfterVersionAlertModal() {}

  doDestroy() {}

  doInit() {
    this.setInitialSubscription();
  }

  setInitialSubscription() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.totalOrder$ = this.localStore.pipe(select(getCountStockProductSelected));
    this.localStore
      .pipe(select(selectStockProductListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
  }

  goToView(_viewParams?: any) {}

  search(criteriaObj) {
    this.store.dispatch(new StockProductListRequestAction(criteriaObj));
  }

  addItem(stockTransferItem: StockTransferItem, qty: number) {
    stockTransferItem.isAddItem = true;
    if (qty) {
      let previousQty = 0;
      const selectPreviousQty$ = this.localStore
        .pipe(select(getStockProductSelectedById(stockTransferItem.articleNo)))
        .subscribe(value => {
          if (value) {
            previousQty = value.quantity;
          }
        });

      stockTransferItem.quantity = Number(previousQty) + Number(qty);

      this.store.dispatch(new StockProductSelectedAddItem(stockTransferItem));
      selectPreviousQty$.unsubscribe();
      this.notifyLeaveFormOutput.emit(true);
    }
  }

  onAdvanceSubmit() {}

  prepareSearchCriteriaTags() {}

  setRouteTab() {}

  changeQuantityValue(stockTransferItem: StockTransferItem) {
    stockTransferItem.isAddItem = false;
    this.notifyLeaveFormOutput.emit(true);
  }

  onBack() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }
}
