import { Action } from '@ngrx/store';
import {
  SelectWarehouseList,
  WarehouseListResponse,
  WarehousesStoresSearchCriteria
} from '../../models/warehouse.model';

export enum WarehouseActionTypes {
  WAREHOUSE_LIST_REQUEST = '[Warehouse List] Warehouse List Request',
  WAREHOUSE_LIST_RESPONSE = '[Warehouse List] Warehouse List Response',
  SELECT_WAREHOUSES_LIST_REQUEST = '[Select Warehouse List] Select Warehouse List Request',
  SELECT_WAREHOUSES_LIST_RESPONSE = '[Select Warehouse List] Select Warehouse List Response'
}

export class WarehouseListRequestAction implements Action {
  readonly type = WarehouseActionTypes.WAREHOUSE_LIST_REQUEST;

  constructor(public delimiter: string = '-') {}
}

export class WarehouseListResponseAction implements Action {
  readonly type = WarehouseActionTypes.WAREHOUSE_LIST_RESPONSE;
  constructor(public payload: WarehouseListResponse) {}
}

export class SelectWarehouseListRequestAction implements Action {
  readonly type = WarehouseActionTypes.SELECT_WAREHOUSES_LIST_REQUEST;

  constructor(public payload: WarehousesStoresSearchCriteria) {}
}

export class SelectWarehouseListResponseAction implements Action {
  readonly type = WarehouseActionTypes.SELECT_WAREHOUSES_LIST_RESPONSE;
  constructor(public payload: SelectWarehouseList[]) {}
}

export type WarehouseAction =
  | WarehouseListRequestAction
  | WarehouseListResponseAction
  | SelectWarehouseListRequestAction
  | SelectWarehouseListResponseAction;
