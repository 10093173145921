import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import {
  StoreConsultantExportCriteria,
  StoreConsultantModel,
  StoreConsultantResponse,
  StoreConsultantSearchCriteria
} from '../models/store-consultant.model';
import { BaseService } from './base.service';

@Injectable()
export class StoreConsultantService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.storeConsultant;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getStoreConsultantByUserId(userId: string): Observable<StoreConsultantModel> {
    const url = this.getFullUrl(this.envService.get, {
      userId: userId
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public update(data: StoreConsultantModel): Observable<any> {
    const url = this.getFullUrl(this.envService.submit);

    return this.http.put<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public searchStoreConsultantByCriteria(
    criteria: StoreConsultantSearchCriteria
  ): Observable<StoreConsultantResponse> {
    const params = this.getParams(criteria);

    return this.http.get<StoreConsultantResponse>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public validateFile(file): Observable<any> {
    const url = this.getFullUrl(this.envService.validateFile);

    let formData = null;
    formData = new FormData();
    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(url, formData, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public importSubmit(data): Observable<any> {
    const url = this.getFullUrl(this.envService.importSubmit);

    return this.http.post<any>(url, data, { headers: this.loaderHeaders() });
  }

  downloadFileTemplate() {
    const url = this.getFullUrl(this.envService.downloadFileTemplate);
    return this.http.get<any>(url, { headers: this.headers, responseType: 'blob' as 'json' });
  }

  exportStoreConsultant(criteria: StoreConsultantExportCriteria): Observable<any> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.export);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
