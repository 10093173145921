import { createSelector } from '@ngrx/store';
import * as fromStockProductSelectedState from '../reducers/stock-product-selected.reducers';
import { AppStates } from '../state/app.states';

const selectStockProductSelectedState = (state: AppStates) => state.stockProductSelected;

export const getStockProductSelectedById = articleNo =>
  createSelector(selectStockProductSelectedState, orders => orders.entities[articleNo]);

export const getCountStockProductSelected = createSelector(
  selectStockProductSelectedState,
  fromStockProductSelectedState.selectTotal
);

export const selectAllStockProductSelected = createSelector(
  selectStockProductSelectedState,
  fromStockProductSelectedState.selectAll
);
