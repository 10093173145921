import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SearchBarcodeModalComponent } from '../../../shared/components/search-barcode-modal/search-barcode-modal.component';
import { SearchProductModalComponent } from '../../../shared/components/search-product-modal/search-product-modal.component';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import { PromotionItemTypeEnum } from '../../../shared/enum/promotion.enum';
import { RewardPageModes } from '../../../shared/enum/reward.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { PromotionTypeEnum } from '../../../shared/models';
import { ConditionPurchase, RewardRequestResponse } from '../../../shared/models/reward.model';
import { MasterService } from '../../../shared/services/master.service';
import { selectRewardRequest, selectRewardValidate } from '../../../shared/store/selectors/reward-request.selectors';
import { selectReward } from '../../../shared/store/selectors/reward.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { applyProductTableItemError } from '../../../shared/utils/product-items-util';

@Component({
  selector: 'app-purchase-quantity-product-item',
  templateUrl: './purchase-quantity-product-item.component.html',
  styleUrls: ['./purchase-quantity-product-item.component.scss']
})
export class PurchaseQuantityProductItemComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() mode: RewardPageModes;
  @Input() saved: boolean;
  @Input() submitted: boolean;
  @Input() conditionPurchase: ConditionPurchase;
  @Input() maximumRow: number;
  @Input() isEditFirst: boolean;

  @Input() promotionItemType: PromotionItemTypeEnum;

  @ViewChild('searchProductModal', { static: false }) searchProductModal: SearchProductModalComponent;
  @ViewChild('searchBarcodeModal', { static: false }) searchBarcodeModal: SearchBarcodeModalComponent;

  public productType: ProductTypeEnum = ProductTypeEnum.INVENTORY;
  headerRow: string[];
  promotionConRetailPrice: string;
  promotionType: PromotionTypeEnum;
  public rewardRequestView$: Observable<RewardRequestResponse>;
  private localStore: Observable<any>;

  constructor(
    public fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly store: Store<AppStates>,
    private readonly masterService: MasterService
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.headerRow = ['No.', 'Product Name', 'Article', 'Barcode', 'Unit', 'Action'];

    this.parentForm.addControl('purchaseQtyItems', this.fb.array([], this.barcodeValidator()));

    this.initSelectLocalStore();

    if ([RewardPageModes.REQUEST_VIEW, RewardPageModes.REQUEST_EDIT, RewardPageModes.VIEW].includes(this.mode)) {
      this.setValue();
    }
  }

  createFormProductItem() {
    const initialNull = [{ initialValue: { value: null, disabled: false } }];
    return this.fb.group({
      barcode: initialNull,
      articleNo: initialNull,
      productName: initialNull,
      unit: initialNull,
      unitFactor: initialNull,
      vat: initialNull
    });
  }

  setValue() {
    if (this.mode === RewardPageModes.VIEW) {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectReward),
        filter(data => data !== null)
      );
    } else {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectRewardRequest),
        filter(data => data !== null)
      );
    }

    this.rewardRequestView$
      .pipe(
        filter(value => Boolean(value)),
        map(response => response)
      )
      .subscribe(res => {
        if (res && res.purchaseQtyItems && res.purchaseQtyItems.length > 0) {
          res.purchaseQtyItems.forEach((value: any, i) => {
            this.formControlProductItem.push(this.createFormProductItem());
            this.formControlProductItem[i].patchValue(value);
          });
        }
      });
  }

  initSelectLocalStore() {
    this.localStore
      .pipe(
        select(selectRewardValidate),
        filter(() => this.submitted || this.isEditFirst)
      )
      .subscribe(data => {
        let items = [];
        if (data && data.items) {
          items = data.items;
        }

        if (this.purchaseQtyItems && this.purchaseQtyItems.length > 0) {
          this.purchaseQtyItems.controls.forEach(ctr => {
            const error = items.find(x => x.barcode && x.barcode === ctr.get('barcode').value);
            if (error && error.errorMessage) {
              applyProductTableItemError(ctr, error.errorMessage, 'barcode', 'productName');
            } else {
              ctr.clearValidators();
              ctr.get('barcode').clearValidators();
              ctr.get('productName').clearValidators();
            }
            ctr.updateValueAndValidity();
            ctr.get('barcode').updateValueAndValidity();
            ctr.get('productName').updateValueAndValidity();
          });
        } else {
        }
      });
  }
  onAddPromotionBarCode(productItems: any[]) {
    if (!productItems) {
      return;
    }

    productItems.forEach(productItem => {
      if (productItem.barcode && productItem.barcode !== '') {
        this.formControlProductItem.push(this.createFormProductItem());
        const controlItem = this.formControlProductItem[this.formControlProductItem.length - 1];
        controlItem.patchValue(productItem);

        if (productItem.errorMessage) {
          this.applyItemError(controlItem, productItem.errorMessage);
          controlItem.get('barcode').updateValueAndValidity();
          controlItem.get('productName').updateValueAndValidity();
        }
        this.purchaseQtyItems.updateValueAndValidity({ onlySelf: false });
      }
    });
  }

  onAddPromotionItem(productItems: any[]) {
    if (!productItems) {
      return;
    }

    productItems.forEach(productItem => {
      if (this.formControlProductItem.some(x => x.get('barcode').value === productItem.barcode)) {
        this.showAlert('Failed', 'Not allow to add duplicated barcode.');
        return;
      }

      this.formControlProductItem.push(this.createFormProductItem());
      const controlItem = this.formControlProductItem[this.formControlProductItem.length - 1];
      controlItem.patchValue(productItem);

      if (productItem.errorMessage) {
        this.applyItemError(controlItem, productItem.errorMessage);
        controlItem.get('barcode').updateValueAndValidity();
        controlItem.get('productName').updateValueAndValidity();
      }
      this.purchaseQtyItems.updateValueAndValidity({ onlySelf: false });
    });
  }

  showAlert(title: string, message: string) {
    const initialState = {
      title,
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  applyItemError(controlItem: AbstractControl, errorMessage) {
    applyProductTableItemError(controlItem, errorMessage, 'barcode', 'productName');
  }

  addNewProductItem() {
    this.searchProductModal.openSelectProductModal();
  }

  addNewBarcode() {
    this.searchBarcodeModal.selectBarcodeModal.show();
  }

  deletePromotionItem(id: any) {
    this.purchaseQtyItems.removeAt(id);
  }

  setEditMode() {}
  get purchaseQtyItems() {
    return this.parentForm.get('purchaseQtyItems') as FormArray;
  }

  get formControlProductItem(): AbstractControl[] {
    return (this.parentForm.get('purchaseQtyItems') as FormArray).controls;
  }

  barcodeValidator(): ValidatorFn {
    return (fa: FormArray) => {
      const array = fa.getRawValue();
      const availableBarcodes = [];
      for (let i = 0; i < array.length; i++) {
        const isDuplicated = availableBarcodes.indexOf(array[i].barcode) > -1;
        if (!isDuplicated) {
          availableBarcodes.push(array[i].barcode);
        }

        if (!fa.controls[i].errors || fa.controls[i].getError('duplicated')) {
          fa.controls[i].setErrors(isDuplicated && { duplicated: true });
        }
      }
      return null;
    };
  }

  get pageMode() {
    return RewardPageModes;
  }

  ngOnDestroy(): void {
    this.purchaseQtyItems.clear();
  }
}
