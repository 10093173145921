import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PurchaseConditionRequestService } from '../../services/purchase-condition-request.service';
import { PurchaseConditionService } from '../../services/purchase-condition.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutForceCloseCurrentModal } from '../actions/layout.action';
import { ItemConditionListLoaded, PurchaseConditionActionTypes, PurchaseConditionContractAndDcUpdateErrorAction, PurchaseConditionContractLoaded, PurchaseConditionDcLoaded, PurchaseConditionListSearchLoaded, PurchaseConditionRequestApproveRejectSuccess, PurchaseConditionRequestListLoaded, PurchaseConditionRequestViewLoaded, PurchaseConditionRequestWeeklyScheduleLoaded, PurchaseConditionViewLoaded } from '../actions/purchase-condition.actions';
export class PurchaseConditionEffects {
    constructor(actions$, purchaseConditionRequestService, purchaseConditionService, logger) {
        this.actions$ = actions$;
        this.purchaseConditionRequestService = purchaseConditionRequestService;
        this.purchaseConditionService = purchaseConditionService;
        this.logger = logger;
        this.searchPurchaseConditionRequest$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionRequestListSearchRequested), tap(action => this.logger.debug(`@Effect Purchase Condition Request List Requested:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionRequestService.searchByCriteria(action.payload).pipe(map(response => new PurchaseConditionRequestListLoaded(response)), catchError(error => of(new LayoutActionLoadError(error))))));
        this.getItemConditionList$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.ItemConditionListRequested), tap(action => this.logger.debug(`@Effect Item Condition List Requested:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionRequestService.getItemConditionList(action.payload).pipe(map(response => new ItemConditionListLoaded(response)), catchError(error => of(new LayoutActionLoadError(error))))));
        this.getPurchaseConditionRequestWeeklyScheduleImportList$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionRequestWeeklyScheduleImportRequested), tap(action => this.logger.debug(`@Effect Purchase Condition Request Weekly Schedule Import  List Requested:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionRequestService.getPurchaseConditionRequestWeeklyScheduleImportList(action.payload).pipe(map(response => {
            response.content.forEach((res, index) => {
                res.rowId = index + 1;
            });
            return new PurchaseConditionRequestWeeklyScheduleLoaded(response);
        }), catchError(error => of(new LayoutActionLoadError(error))))));
        this.purchaseConditionSave$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionRequestSave), map(action => {
            this.logger.debug(`@Effect Purchase Condition Request Save: ` + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.purchaseConditionRequestService.save(payload).pipe(map(() => new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The data have been saved.'
            })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.purchaseConditionUpdateSaveById$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionRequestUpdateSaveById), map(action => {
            this.logger.debug(`@Effect Purchase Condition Request Update Save By Id: ` + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.purchaseConditionRequestService.edit(payload).pipe(map(() => new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The data have been saved.'
            })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getPurchaseConditionList$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionListSearchRequested), tap(action => this.logger.debug(`@Effect Purchase Condition List Requested:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionService.searchByCriteria(action.payload).pipe(map(response => new PurchaseConditionListSearchLoaded(response)), catchError(error => of(new LayoutActionLoadError(error))))));
        this.purchaseConditionSubmit$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionRequestSubmit), map(action => {
            this.logger.debug(`@Effect Purchase Condition Request Submit: ` + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.purchaseConditionRequestService.submit(payload).pipe(map(() => new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The request has been sent to approver.',
                routerLink: '/purchase/purchase-condition/purchase-condition-request-list'
            })), catchError(error => of(new LayoutActionLoadError(error, true, true))));
        }));
        this.approveRequestPurchaseCondition$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionRequestApprove), map(action => {
            this.logger.debug(`@Effect Purchase Condition Request Approve: ` + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.purchaseConditionRequestService.approve(payload).pipe(map(() => new PurchaseConditionRequestApproveRejectSuccess({ isSuccess: true })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.rejectRequestPurchaseCondition$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionRequestReject), map(action => {
            this.logger.debug(`@Effect Purchase Condition Request Approve: ` + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.purchaseConditionRequestService.reject(payload).pipe(map(() => new PurchaseConditionRequestApproveRejectSuccess({ isSuccess: true })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getPurchaseConditionRequestView = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionRequestViewRequested), tap(action => this.logger.debug(`@Effect Purchase Condition view Requested:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionRequestService.getPurchaseConditionById(action.payload).pipe(map(response => new PurchaseConditionRequestViewLoaded(response)), catchError(error => of(new LayoutActionLoadError(error))))));
        this.getPurchaseConditionView = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionViewRequested), tap(action => this.logger.debug(`@Effect Purchase Condition view Requested:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionService.getPurchaseConditionViewRequested(action.payload).pipe(map(response => {
            return new PurchaseConditionViewLoaded(response);
        }), catchError(error => of(new LayoutActionLoadError(error))))));
        this.getPurchaseConditionDcRequested = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionDcRequested), tap(action => this.logger.debug(`@Effect Purchase Condition Dc Requested:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionService.getPurchaseConditionSupplierDcRequested(action.payload).pipe(map(response => new PurchaseConditionDcLoaded({ supplierDistributionCenters: response })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.getPurchaseConditionContractRequested = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionContractRequested), tap(action => this.logger.debug(`@Effect Purchase Condition Contract Requested:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionService.getPurchaseConditionSupplierContactRequested(action.payload).pipe(map(response => new PurchaseConditionContractLoaded({ supplierContacts: response })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.getPurchaseConditionDiscountRequested = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionDiscountRequested), tap(action => this.logger.debug(`@Effect Purchase Condition view Requested:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionService.getPurchaseConditionDiscountRequested(action.payload).pipe(map(response => new ItemConditionListLoaded(response)), catchError(error => of(new LayoutActionLoadError(error))))));
        this.purchaseConditionEditSubmit$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionRequestEditSubmitRequested), map(action => {
            this.logger.debug(`@Effect Purchase Condition Request Edit Submit: ` + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.purchaseConditionRequestService.edit(payload).pipe(map(() => new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The request has been sent to approver.',
                routerLink: '/purchase/purchase-condition/purchase-condition-request-list'
            })), catchError(error => of(new LayoutActionLoadError(error, true, true))));
        }));
        this.getPurchaseConditionWeeklyScheduleRequested = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionWeeklyScheduleRequested), tap(action => this.logger.debug(`@Effect Purchase Condition view Requested:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionService.getPurchaseConditionWeeklyScheduleRequested(action.payload).pipe(map(response => {
            response.content.forEach((res, index) => {
                res.rowId = index + 1;
            });
            return new PurchaseConditionRequestWeeklyScheduleLoaded(response);
        }), catchError(error => of(new LayoutActionLoadError(error))))));
        this.addPurchaseConditionSupplierAddContract = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionSupplierAddContract), tap(action => this.logger.debug(`@Effect Purchase Condition add Contract:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionService.addPurchaseConditionSupplierContract(action.payload).pipe(switchMap(_ => {
            return this.purchaseConditionService.getPurchaseConditionSupplierContactRequested({
                supplierCode: action.payload.supplierCode
            });
        }), switchMap(response => [
            new PurchaseConditionContractLoaded({
                supplierContacts: response,
                appUpdateContactAndDCSuccess: {
                    isSuccess: true,
                    title: 'Success',
                    message: 'Contact Information has been added.'
                }
            }),
            new PurchaseConditionContractAndDcUpdateErrorAction(null)
        ]), catchError(err => err.error && err.error.code === '08031'
            ? of(new PurchaseConditionContractAndDcUpdateErrorAction(Object.assign({}, err.error)))
            : of(new LayoutActionLoadError(err, true))))));
        this.editPurchaseConditionSupplierUpdatedContract$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionSupplierUpdatedContract), tap(action => this.logger.debug(`@Effect Purchase Condition Updated Contract:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionService.editPurchaseConditionSupplierContract(action.payload).pipe(switchMap(_ => {
            return this.purchaseConditionService.getPurchaseConditionSupplierContactRequested({
                supplierCode: action.payload.supplierCode
            });
        }), switchMap(response => [
            new PurchaseConditionContractLoaded({
                supplierContacts: response,
                appUpdateContactAndDCSuccess: {
                    isSuccess: true,
                    title: 'Success',
                    message: 'Contact Information has been updated.'
                }
            }),
            new PurchaseConditionContractAndDcUpdateErrorAction(null)
        ]), catchError(err => (err.error && err.error.code === '08031') || err.error.code === '00001'
            ? of(new PurchaseConditionContractAndDcUpdateErrorAction(Object.assign({}, err.error)))
            : of(new LayoutActionLoadError(err, true))))));
        this.deletePurchaseConditionSupplierContract$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionSupplierDeletedContract), tap(action => this.logger.debug(`@Effect Purchase Condition deleted Contract:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionService.deletePurchaseConditionSupplierContract(action.payload.id).pipe(switchMap(_ => {
            return this.purchaseConditionService.getPurchaseConditionSupplierContactRequested({
                supplierCode: action.payload.supplierCode
            });
        }), switchMap(response => [
            new PurchaseConditionContractLoaded({
                supplierContacts: response,
                appUpdateContactAndDCSuccess: {
                    isSuccess: true,
                    title: 'Success',
                    message: 'Contact Information has been deleted.'
                }
            })
        ]), catchError(err => err.error && err.error.code === '00001'
            ? of(new LayoutForceCloseCurrentModal(true))
            : of(new LayoutActionLoadError(err, true))))));
        this.deletePurchaseConditionRequest$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.DeletePurchaseConditionRequest), tap(action => this.logger.debug(`@Effect Purchase Condition Delete:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionRequestService.deletePurchaseConditionRequest(action.payload.id).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been deleted.'
        })), catchError(error => of(new LayoutActionLoadError(error, true))))));
        this.addPurchaseConditionSupplierDc = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionSupplierAddDc), tap(action => this.logger.debug(`@Effect Purchase Condition add Dc:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionService.addPurchaseConditionSupplierDc(action.payload).pipe(switchMap(_ => {
            return this.purchaseConditionService.getPurchaseConditionSupplierDcRequested({
                supplierCode: action.payload.supplierCode
            });
        }), switchMap(response => [
            new PurchaseConditionDcLoaded({
                supplierDistributionCenters: response,
                appUpdateContactAndDCSuccess: {
                    isSuccess: true,
                    title: 'Success',
                    message: 'DC Information has been added.'
                }
            }),
            new PurchaseConditionContractAndDcUpdateErrorAction(null)
        ]), catchError(err => err.error && err.error.code === '08032'
            ? of(new PurchaseConditionContractAndDcUpdateErrorAction(Object.assign({}, err.error)))
            : of(new LayoutActionLoadError(err, true))))));
        this.editPurchaseConditionSupplierUpdatedDc$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionSupplierUpdatedDc), tap(action => this.logger.debug(`@Effect Purchase Condition Updated Dc:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionService.editPurchaseConditionSupplierDc(action.payload).pipe(switchMap(_ => {
            return this.purchaseConditionService.getPurchaseConditionSupplierDcRequested({
                supplierCode: action.payload.supplierCode
            });
        }), switchMap(response => [
            new PurchaseConditionDcLoaded({
                supplierDistributionCenters: response,
                appUpdateContactAndDCSuccess: {
                    isSuccess: true,
                    title: 'Success',
                    message: 'DC Information has been updated.'
                }
            }),
            new PurchaseConditionContractAndDcUpdateErrorAction(null)
        ]), catchError(err => (err.error && err.error.code === '08032') || err.error.code === '00001'
            ? of(new PurchaseConditionContractAndDcUpdateErrorAction(Object.assign({}, err.error)))
            : of(new LayoutActionLoadError(err, true))))));
        this.deletePurchaseConditionSupplierDc$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.PurchaseConditionSupplierDeletedDc), tap(action => this.logger.debug(`@Effect Purchase Condition deleted Dc:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionService.deletePurchaseConditionSupplierDc(action.payload.id).pipe(switchMap(_ => {
            return this.purchaseConditionService.getPurchaseConditionSupplierDcRequested({
                supplierCode: action.payload.supplierCode
            });
        }), switchMap(response => [
            new PurchaseConditionDcLoaded({
                supplierDistributionCenters: response,
                appUpdateContactAndDCSuccess: {
                    isSuccess: true,
                    title: 'Success',
                    message: 'DC Information has been deleted.'
                }
            })
        ]), catchError(err => err.error && err.error.code === '00001'
            ? of(new LayoutForceCloseCurrentModal(true))
            : of(new LayoutActionLoadError(err, true))))));
        this.cancelPurchaseConditionRequest$ = this.actions$.pipe(ofType(PurchaseConditionActionTypes.CancelPurchaseConditionRequest), tap(action => this.logger.debug(`@Effect Cancel Purchase condition Request:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseConditionRequestService.cancel(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been cancelled.'
        })), catchError(error => of(new LayoutActionLoadError(error, true))))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "searchPurchaseConditionRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "getItemConditionList$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "getPurchaseConditionRequestWeeklyScheduleImportList$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "purchaseConditionSave$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "purchaseConditionUpdateSaveById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "getPurchaseConditionList$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "purchaseConditionSubmit$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "approveRequestPurchaseCondition$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "rejectRequestPurchaseCondition$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "getPurchaseConditionRequestView", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "getPurchaseConditionView", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "getPurchaseConditionDcRequested", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "getPurchaseConditionContractRequested", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "getPurchaseConditionDiscountRequested", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "purchaseConditionEditSubmit$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "getPurchaseConditionWeeklyScheduleRequested", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "addPurchaseConditionSupplierAddContract", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "editPurchaseConditionSupplierUpdatedContract$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "deletePurchaseConditionSupplierContract$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "deletePurchaseConditionRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "addPurchaseConditionSupplierDc", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "editPurchaseConditionSupplierUpdatedDc$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "deletePurchaseConditionSupplierDc$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseConditionEffects.prototype, "cancelPurchaseConditionRequest$", void 0);
