import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { round } from 'lodash';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import * as numeral from 'numeral';
import { Observable } from 'rxjs';
import { filter, finalize, map, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ApproveStatusEnum } from '../../../shared/enum/approve-status.enum';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { OrderSubTypeEnum, OrderTypeEnum } from '../../../shared/enum/ordering-method.enum';
import { NewRequestStatusEnum } from '../../../shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestProductErrorEnum } from '../../../shared/enum/request-step.enum';
import { StoreType } from '../../../shared/enum/store.enum';
import { GraphqlQueryObject } from '../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { UiPaginationComponent } from '../../../shared/layouts/ui-pagination/ui-pagination.component';
import {
  ErrorFirstLotItem,
  ErrorInventoryResult,
  ErrorOrderRequestResponse,
  ErrorResult,
  Money,
  ShelfDetailRequest,
  TaskModuleUrl
} from '../../../shared/models';
import { AuditLog, HistoryType } from '../../../shared/models/audit-log.model';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { OrderInventoryRequestViewResponse } from '../../../shared/models/order-request-inventory.model';
import {
  DeliveryDetail,
  DeliveryDetailsByTypeDictionary,
  ImportOrderRequest,
  InventoryTypeEnum,
  OrderRequestListSearchCriteria,
  OrderRequestProductItemsExport,
  ProductOrderItem
} from '../../../shared/models/order-request.model';
import { WarehouseListContent } from '../../../shared/models/warehouse.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { OrderInventoryRequestService } from '../../../shared/services/order-inventory-request.service';
import { OrderRequestService } from '../../../shared/services/order-request.service';
import { TasksByRoleListRequestAction } from '../../../shared/store/actions/dashboard.actions';
import {
  OrderInventoryApproveRequestAction,
  OrderInventoryCreateResetAction,
  OrderInventoryCreateSaveRequestAction,
  OrderInventoryCreateSubmitRequestAction,
  OrderInventoryRequestByIdRequestAction,
  OrderInventoryRequestSaveDeliveryDetailsAction,
  ResetOrderInventoryRequestSelected
} from '../../../shared/store/actions/order-inventory-request.actions';
import {
  OrderRequestHistoryRequestAction,
  OrderRequestHistoryResponseAction,
  OrderRequestListRequestAction,
  OrderRequestShelfSelectionResetAction
} from '../../../shared/store/actions/order-request.actions';
import {
  OrderInventorySelectItemOrderAddAllItem,
  OrderInventorySelectItemOrderRemoveItem,
  OrderInventorySelectItemOrderReset,
  OrderInventorySelectItemOrderUpdateAllItem,
  OrderInventorySelectItemOrderUpdateItem
} from '../../../shared/store/actions/order-select-item-inventory-order.actions';
import {
  OrderSelectiveSelectItemOrderAddAllItem,
  OrderSelectiveSelectItemOrderAddItem,
  OrderSelectiveSelectItemOrderRemoveItem,
  OrderSelectiveSelectItemOrderReset,
  OrderSelectiveSelectItemOrderUpdateAllItem,
  OrderSelectiveSelectItemOrderUpdateItem
} from '../../../shared/store/actions/order-selective-select-item.actions';
import {
  ShelfDetailsResetAction,
  ShelfDetailsResponseAction
} from '../../../shared/store/actions/shelf-details.actions';
import { OrderInventoryCreateResponseState } from '../../../shared/store/reducers/order-inventory-create.reducers';
import {
  selectOrderInventoryApproveRejectStatus,
  selectOrderInventoryRequest
} from '../../../shared/store/selectors/order-inventory-create.selectors';
import { selectOrderInventoryRequestById } from '../../../shared/store/selectors/order-inventory-request.selector';
import {
  selectAllOrderInventorySelectItemOrder,
  selectOrderInventoryUpdated
} from '../../../shared/store/selectors/order-inventory-select-item-order.selector';
import {
  selectOrderRequestHistory,
  selectOrderRequestListCriteria
} from '../../../shared/store/selectors/order-request.selector';
import {
  getOrderSelectiveSelectItemOrderById,
  selectAllOrderSelectiveSelectItemOrder,
  selectOrderSelectiveUpdated
} from '../../../shared/store/selectors/order-selective-select-item-order.selector';
import { selectAllShelfDetails } from '../../../shared/store/selectors/shelf-details.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { getPriceFormat, ItemPrice } from '../../../shared/utils/calculate-price-util';
import { DeliveryDetailsComponent } from '../order-components/delivery-details/delivery-details.component';
import { OrderModalUploadComponent } from '../order-components/order-modal-upload/order-modal-upload.component';
import { SelectSelectiveItemModalComponent } from '../order-components/select-selective-item-modal/select-selective-item-modal.component';
import { SelectShelfInventoryModalComponent } from '../order-components/select-shelf-inventory-modal/select-shelf-inventory-modal.component';
import { ViewShelfDetailsComponent } from '../order-components/view-shelf-details/view-shelf-details.component';

@Component({
  selector: 'app-order-request-inventory',
  templateUrl: './order-request-inventory.component.html',
  styleUrls: ['./order-request-inventory.component.scss']
})
export class OrderRequestInventoryComponent implements OnInit, OnDestroy {
  @ViewChild('shelfPaging', { static: false }) shelfPaging: UiPaginationComponent;
  @ViewChild('selectivePaging', { static: false }) selectivePaging: UiPaginationComponent;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Input() data: {
    title: string;
    mode: RequestPageModesEnum;
    orderType?: OrderTypeEnum;
    orderSubType?: OrderSubTypeEnum;
    warehouseCode?: string;
    warehouseDisplayName?: string;
    requestId?: string;
    originPage?: string;
  };

  private localStore: Observable<any>;
  private listSearchCriteria: OrderRequestListSearchCriteria;
  public requestNo: string;
  public status: NewRequestStatusEnum;
  private isDataUpdated: boolean;
  private shelfSkipUpdated: boolean;
  private selectiveSkipUpdated: boolean;
  private segmentList = [];
  private segments = {};

  public orderRequestView$: Observable<OrderInventoryRequestViewResponse>;
  public orderRequestForm: FormGroup;
  public orderRequest: OrderInventoryRequestViewResponse;
  public shelfDetails: ShelfDetailRequest[];
  public warehouseList: WarehouseListContent[];

  public searchForm: FormGroup;
  public saved: boolean;
  public submitted: boolean;
  public orderTypeEnum = OrderTypeEnum;
  public pageModeEnum = RequestPageModesEnum;
  public vatPct$: Observable<number>;
  public storeNo: string;
  public storeCode: string;

  public auditLogs$: Observable<AuditLog[]>;

  public dateTimeDisplay = environment.dateTimeDisplay;
  public isLoading = false;
  public isSkipSetFilteredForm = false;

  public numberFormat = '0,0.00';
  public buttons: Array<ImportExportButton>;
  public buttonsSelective: Array<ImportExportButton>;

  public currentShelfPage = 1;
  public currentSelectivePage = 1;
  public shelfPageSize = 20;
  public selectivePageSize = 20;
  public filteredShelfFormGroup: FormGroup;
  public filteredSelectiveFormGroup: FormGroup;
  public inventoryType = InventoryTypeEnum;
  public errorExport: string;
  public deliveryDetailsCondition;
  public importUrl = `${environment.services.orderRequestInventory.importUpdateQtyValidate}`;
  public importHeaders = environment.services.orderRequestInventory.headers;
  public importSelectiveUrl = `${environment.serverUrl}${environment.services.orderRequestInventory.url}${environment.services.orderRequestInventory.importAddSelectiveItem}`;
  public importSelectiveHeaders = environment.services.orderRequestFixAsset.headers;

  public orderModalUpload: BsModalRef;
  importResult: { title: string; table: any[]; message: string };
  @ViewChild('importResultModel', { static: false }) importResultModel: ModalDirective;

  constructor(
    public readonly store: Store<AppStates>,
    private readonly fb: FormBuilder,
    protected readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    private readonly orderRequestService: OrderRequestService,
    private readonly orderRequestInventoryService: OrderInventoryRequestService,
    private readonly authGuardService: AuthGuardService,
    protected masterService: MasterService,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getDeliveryDetailsCondition();
    this.initialControl();
    this.initState();
    this.getButton();
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  getButton() {
    this.getButtonImportExportSelective();
    this.getButtonImportSelective();
  }

  getButtonImportExportSelective() {
    this.buttons = [
      {
        type: ButtonType.EXPORT,
        name: 'Export',
        hidden: !this.isViewMode
      },
      {
        type: ButtonType.IMPORT,
        name: 'Import',
        hidden: this.getHiddenButtonImport()
      }
    ];
  }

  getButtonImportSelective() {
    this.buttonsSelective = [
      {
        type: ButtonType.IMPORT,
        name: 'Import Selective',
        hidden: this.getHiddenButtonImportSelective()
      }
    ];
  }

  getHiddenButtonImportSelective(): boolean {
    let result = true;
    if (!this.isViewMode) {
      if ([NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status)) {
        result = false;
      }
    }
    return result;
  }

  getHiddenButtonImport(): boolean {
    let result = true;
    if (!this.isViewMode) {
      if ([NewRequestStatusEnum.ASSIGN_QUANTITY].includes(this.status)) {
        result = false;
      }
    }
    return result;
  }

  getDeliveryDetailsCondition() {
    this.deliveryDetailsCondition = [
      {
        title: 'Inventory: Food',
        segment: InventoryTypeEnum.FOOD,
        properties: ['FOOD_WAREHOUSE', 'FOOD_SUPPLIER'],
        noOfDateBefore: 2
      },
      {
        title: 'Inventory: Non-Food',
        segment: InventoryTypeEnum.NON_FOOD,
        properties: ['NON_FOOD_WAREHOUSE', 'NON_FOOD_SUPPLIER'],
        noOfDateBefore: 2
      },
      {
        title: 'Inventory: Promotion & Other',
        segment: InventoryTypeEnum.PROMOTION_OTHERS,
        properties: ['PROMOTION_OTHERS_WAREHOUSE', 'PROMOTION_OTHERS_SUPPLIER'],
        noOfDateBefore: 2
      }
    ];
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetOrderInventoryRequestSelected());
    this.store.dispatch(new OrderInventoryCreateResetAction());
    this.store.dispatch(new OrderSelectiveSelectItemOrderReset());
    this.store.dispatch(new OrderInventorySelectItemOrderReset());
    this.store.dispatch(new OrderRequestShelfSelectionResetAction());
    this.store.dispatch(new ShelfDetailsResetAction());
    if (this.data.originPage && this.data.originPage === TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new TasksByRoleListRequestAction());
    } else {
      this.store.dispatch(new OrderRequestListRequestAction(this.listSearchCriteria));
    }
  }

  initialControl() {
    this.orderRequestForm = this.fb.group({
      shelfOrderItems: this.fb.array([]),
      selectiveOrderItems: this.fb.array([])
    });

    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });

    this.filteredShelfFormGroup = this.fb.group({
      shelfOrderItems: this.fb.array([])
    });

    this.filteredSelectiveFormGroup = this.fb.group({
      selectiveOrderItems: this.fb.array([])
    });
  }

  initState() {
    const countryQuery = new GraphqlQueryObject();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    countryQuery.name = MasterDataEnum.COUNTRY;
    countryQuery.fields = ['id', 'code', 'nameEn', 'vatPct'];

    this.vatPct$ = this.masterService
      .getMasterDataByNames([countryQuery])
      .pipe(map(result => result.data.countries.find(data => data.nameEn === environment.defaultCountry).vatPct));

    this.orderRequest = new OrderInventoryRequestViewResponse();
    this.orderRequest.amount = getPriceFormat();
    this.orderRequest.vatAmount = getPriceFormat();
    this.orderRequest.amountExcVat = getPriceFormat();

    this.orderRequestView$ = this.localStore.pipe(select(selectOrderInventoryRequestById));
    this.orderRequestView$.pipe(filter(data => Boolean(data && data.store))).subscribe(data => {
      this.storeNo = data.store.storeNo;
      this.storeCode = data.store.storeCode;
    });

    this.orderRequestService
      .getSegmentListData()
      .pipe(
        untilComponentDestroyed(this),
        filter(result => Boolean(result && result.data)),
        map(result => result.data)
      )
      .subscribe(data => {
        const segments = data[MasterDataEnum.SEGMENT];
        const regex = /[^\s]+$/;

        this.segments = segments.reduce((prev, obj) => {
          // Check if segment is 'OTHER' or 'PROMOTION' then set it to the section 'PROMOTION_OTHERS'
          prev[obj.code] = ['SEG00003', 'SEG00004'].includes(obj.code)
            ? this.inventoryType.PROMOTION_OTHERS
            : regex
                .exec(obj.nameEn)[0]
                .replace('-', '_')
                .toUpperCase();

          return prev;
        }, {});
      });

    this.localStore
      .pipe(select(selectOrderRequestListCriteria))
      .subscribe(criteriaObject => (this.listSearchCriteria = criteriaObject));

    this.localStore
      .pipe(
        select(selectOrderInventoryRequest),
        filter(value => Boolean(value && value.result))
      )
      .subscribe((value: OrderInventoryCreateResponseState) => {
        const result = value.result;

        if (result.response) {
          this.alertSuccessModal(this.getSubmitMessage(value.isSave));
        } else {
          if (value.result.errorResponse.code === '03005') {
            this.orderRequest.deliveryStatus = 'error';
          } else {
            this.alertErrorModal(value.result.errorResponse);
          }
        }
      });

    if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.data.mode)) {
      this.store.dispatch(new OrderInventoryRequestByIdRequestAction({ orderId: this.data.requestId }));
    }

    this.localStore.pipe(select(selectAllShelfDetails)).subscribe(shelfDetails => {
      this.shelfDetails = shelfDetails;
    });

    this.orderRequestView$.pipe(filter(value => Boolean(value))).subscribe(value => {
      this.orderRequest.deliveryDetails = value.deliveryDetails;

      this.orderRequest.deliveryStatus = this.getDeliveryDetailsStatus(
        value.deliveryDetails && value.deliveryDetails.deliveryDetailsByType
      );
    });

    this.localStore.pipe(select(selectOrderInventoryUpdated)).subscribe(skipUpdated => {
      this.shelfSkipUpdated = skipUpdated;
    });

    this.localStore.pipe(select(selectOrderSelectiveUpdated)).subscribe(skipUpdated => {
      this.selectiveSkipUpdated = skipUpdated;
    });

    this.auditLogs$ = this.localStore.pipe(select(selectOrderRequestHistory));

    this.setOrderRequestValue();
    this.initialSelectShelfInventoryItemOrder();
    this.initialSelectSelectiveItemOrder();
  }

  initialSelectShelfInventoryItemOrder() {
    this.localStore
      .pipe(select(selectAllOrderInventorySelectItemOrder), untilComponentDestroyed(this))
      .subscribe(shelfOrderItems => {
        const hasNewItem = shelfOrderItems.some(item => item.isAddItem || item.isAddItem === undefined);
        const addShelfOrderItems = shelfOrderItems.filter(item => item.isAddItem || item.isAddItem === undefined);

        if (hasNewItem) {
          this.currentShelfPage = 1;
          this.searchForm.controls['searchCriteria'].reset();

          this.baseShelfFormArray.clear();

          addShelfOrderItems.forEach(item => {
            item.isAddItem = false;
            this.baseShelfFormArray.push(this.createItemForm(item));
          });
        }

        this.baseShelfFormArray.controls.sort(this.sortList);

        if (this.isSkipSetFilteredForm) {
          this.isSkipSetFilteredForm = false;
        } else if (this.searchForm.controls['searchCriteria'].value) {
          this.search();
        } else {
          this.resetFilteredShelfFormArrayTo(this.baseShelfFormArray.controls);
        }

        this.getSegmentList();

        if (!this.shelfSkipUpdated && shelfOrderItems.length > 0) {
          this.calculateTotalAmount();
        }

        this.isLoading = false;
      });
  }

  initialSelectSelectiveItemOrder() {
    this.localStore
      .pipe(select(selectAllOrderSelectiveSelectItemOrder), untilComponentDestroyed(this))
      .subscribe(selectiveOrderItems => {
        const hasNewItem = selectiveOrderItems.some(item => item.isAddItem || item.isAddItem === undefined);
        const articleNoList = this.baseSelectiveFormArray.controls.map(control => control.get('articleNo').value);
        const addProductOrderItems = selectiveOrderItems.filter(
          item => item.isAddItem || item.isAddItem === undefined
        );

        addProductOrderItems
          .filter(item => articleNoList.length > 0 && articleNoList.indexOf(item.articleNo) >= 0)
          .forEach(item => {
            const ctrlIndex = articleNoList.indexOf(item.articleNo);
            const selectedItem = this.baseSelectiveFormArray.controls[ctrlIndex];

            item.isAddItem = false;

            item.unitPrice = item.wholesalePrice;
            selectedItem.patchValue(item);
          });

        addProductOrderItems
          .filter(item => articleNoList.indexOf(item.articleNo) === -1)
          .forEach(item => {
            item.isAddItem = false;

            const duplicatedIndex = (this.baseShelfFormArray.value as Array<any>).findIndex(
              x => x.barcode === item.barcode
            );
            this.baseSelectiveFormArray.push(this.createItemForm(item, duplicatedIndex > -1));
          });

        this.baseSelectiveFormArray.controls.sort(this.sortList);

        if (hasNewItem) {
          this.searchForm.controls['searchCriteria'].reset();
        }

        if (this.isSkipSetFilteredForm) {
          this.isSkipSetFilteredForm = false;
        } else if (this.searchForm.controls['searchCriteria'].value) {
          this.search();
        } else {
          this.resetFilteredSelectiveFormArrayTo(this.baseSelectiveFormArray.controls);
        }

        this.getSegmentList();

        if (!this.selectiveSkipUpdated && selectiveOrderItems.length > 0) {
          this.calculateTotalAmount();
        }

        this.isLoading = false;
      });
  }

  setOrderRequestValue() {
    this.orderRequestView$
      .pipe(
        filter(value => Boolean(value)),
        tap(value => {
          this.requestNo = value.requestNo;
          this.status = value.status || NewRequestStatusEnum.DRAFT;
          this.orderRequest.orderType = value.orderType || this.data.orderType;
          this.orderRequest.orderSubType = value.orderSubType || this.data.orderSubType;
          this.orderRequest.requestedDate = value.requestedDate;
          this.orderRequest.amountExcVat = value.amountExcVat || getPriceFormat();
          this.orderRequest.vatAmount = value.vatAmount || getPriceFormat();
          this.orderRequest.amount = value.amount || getPriceFormat();
          this.orderRequest.store = value.store;

          this.data.warehouseCode = value.warehouseCode || this.data.warehouseCode;
          this.data.warehouseDisplayName = value.warehouseDisplayName || this.data.warehouseDisplayName;

          if (value.shelfInventoryItems && value.shelfInventoryItems.length > 0) {
            this.store.dispatch(
              new OrderInventorySelectItemOrderAddAllItem({ itemList: value.shelfInventoryItems, skipUpdated: true })
            );
          }

          if (value.selectiveItems && value.selectiveItems.length > 0) {
            this.store.dispatch(
              new OrderSelectiveSelectItemOrderAddAllItem({ itemList: value.selectiveItems, skipUpdated: true })
            );
          }

          if (value.shelfDetails && value.shelfDetails.length > 0) {
            this.store.dispatch(new ShelfDetailsResponseAction(value.shelfDetails));
          }

          if (this.data.mode === RequestPageModesEnum.REQUEST_CREATE) {
            this.store.dispatch(
              new OrderInventoryRequestSaveDeliveryDetailsAction({
                storeOpenDate: value.store.openDate,
                deliveryDetailsByType: null
              })
            );
          }
        }),
        take(1),
        finalize(() => this.getButton())
      )
      .subscribe();
  }

  createItemForm(item: ProductOrderItem, isSelectiveDuplicated?: boolean) {
    const formItem: FormGroup = this.fb.group({
      productName: item.productName,
      productDisplayName: item.productDisplayName,
      articleNo: item.articleNo,
      barcode: item.barcode,
      productType: item.productType,
      segment: item.segment,
      unit: item.unit,
      unitFactor: item.unitFactor,
      unitPrice: item.unitPrice || item.wholesalePrice,
      wholesalePrice: item.wholesalePrice,
      wholesalePricePerUnit: item.wholesalePricePerUnit,
      qty: [
        { value: item.qty, disabled: this.data.mode === RequestPageModesEnum.REQUEST_VIEW },
        [Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]
      ],
      vatAmount: item.vatAmount,
      amountExcVat: item.amountExcVat,
      wholesalePriceExcVat: item.wholesalePriceExcVat,
      deliveryMethod: item.deliveryMethod,
      supplierCode: item.supplierCode,
      supplierName: item.supplierName,
      totalVat:
        item.totalVat ||
        getPriceFormat(round(item.vatAmount.amount * item.qty, 2), item.vatAmount && item.vatAmount.currency),
      amount: item.amount,
      allowToDelete: item.allowToDelete,
      vat: item.vat
    });

    if (isSelectiveDuplicated) {
      formItem.setValidators(this.setValidator(RequestProductErrorEnum.DUPLICATED_BARCODE_FIELD));
      formItem.get('productName').setValidators(this.setValidator(RequestProductErrorEnum.DUPLICATED_BARCODE_FIELD));
      formItem.get('qty').disable();
      formItem.setParent(this.baseSelectiveFormArray);
      formItem.updateValueAndValidity();
    }

    return formItem;
  }

  onSave() {
    this.saved = true;

    if (this.status === NewRequestStatusEnum.ASSIGN_QUANTITY) {
      this.submitted = true;

      this.baseShelfFormArray.updateValueAndValidity();
      this.baseSelectiveFormArray.updateValueAndValidity();

      if (this.baseShelfFormArray.valid && this.baseSelectiveFormArray.valid) {
        this.store.dispatch(new OrderInventoryCreateSaveRequestAction(this.prepareRequestData()));
      } else {
        this.validateError();
      }
    } else {
      this.store.dispatch(new OrderInventoryCreateSaveRequestAction(this.prepareRequestData()));
    }
  }

  onSubmit() {
    this.submitted = true;
    this.isLoading = true;

    this.clearSearchText();

    this.disableErrorItems(this.baseSelectiveFormArray);

    this.baseShelfFormArray.updateValueAndValidity();
    this.baseSelectiveFormArray.updateValueAndValidity();

    const errorMessage = this.getFormGroupErrorMessage();

    if (errorMessage) {
      this.showModalError(errorMessage);
      this.isLoading = false;
      return;
    }

    if (this.orderRequest.deliveryStatus === 'error') {
      this.showAlertModal('Delivery details is required or delivery date is invalid.');
      this.isLoading = false;
      return;
    }

    if (
      this.baseShelfFormArray.valid &&
      this.baseSelectiveFormArray.valid &&
      this.orderRequest.deliveryStatus !== 'error'
    ) {
      this.handleConfirm();
    } else {
      this.validateError();
    }
  }

  itemValidator(itemError: ErrorInventoryResult) {
    if ([NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status)) {
      if (itemError.shelfItems && itemError.shelfItems.length) {
        const items = itemError.shelfItems;
        this.validateFormArray(items, this.baseShelfFormArray);
      }

      if (itemError.selectiveItems && itemError.selectiveItems.length) {
        const items = itemError.selectiveItems;
        this.validateFormArray(items, this.baseSelectiveFormArray);
      }
    }

    if (this.baseShelfFormArray.invalid || this.baseSelectiveFormArray.invalid) {
      this.validateError();
    }
  }

  validateFormArray(items: ErrorFirstLotItem[], baseFormArray: FormArray) {
    items.forEach(item => {
      if (item.errors && item.errors.length) {
        this.validateSubmitForm(baseFormArray, baseFormArray.at(item.no - 1), item.errors[0]);
      } else {
        baseFormArray.at(item.no - 1).clearValidators();
        baseFormArray.at(item.no - 1).updateValueAndValidity();
      }
    });

    baseFormArray.updateValueAndValidity();
  }

  validateSubmitForm(parentForm: FormArray, formItem: AbstractControl, error: ErrorResult) {
    formItem.setValidators(this.setSubmitValidator(error));
    formItem.get('productName').setValidators(this.setSubmitValidator(error));
    formItem.get('qty').disable();
    formItem.setParent(parentForm);
    formItem.updateValueAndValidity();
  }

  disableErrorItems(parentForm: FormArray) {
    parentForm.controls.forEach(item => {
      if (item.errors && !item.errors.required && !item.errors.isZero) {
        item.get('qty').disable();
        item.updateValueAndValidity();
      }
    });
  }

  setValidator(errorMessage: string, value = ''): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      switch (errorMessage) {
        case RequestProductErrorEnum.NOT_ALLOW_ZERO:
          if (control.value !== null) {
            return control.value === 0 ? { isZero: true } : null;
          }
          return null;
        case RequestProductErrorEnum.NOT_ALLOW_ALL_ZERO:
          return { isAllZero: true };
        case RequestProductErrorEnum.NOT_ALLOW_ALL_INVALID:
          return { isAllInvalid: true };
        case RequestProductErrorEnum.MAXIMUM_PURCHASING_PRICE:
          if (value && value.length > 0) {
            return { maximumPurchasingPrice: { hasError: true, price: value } };
          }
          return null;
        case RequestProductErrorEnum.DUPLICATED_BARCODE_FIELD:
          return { duplicated: true };
        default:
          return null;
      }
    };
  }

  setSubmitValidator(error: ErrorResult): ValidatorFn {
    return (): { [key: string]: any } | null => {
      return { isSubmitError: error.message, field: error.field };
    };
  }

  validateError() {
    this.navigateToError(this.baseShelfFormArray, this.shelfPaging);
    this.navigateToError(this.baseSelectiveFormArray, this.selectivePaging);

    this.isLoading = false;
  }

  navigateToError(formArray: FormArray, paging: UiPaginationComponent) {
    if (formArray.controls && formArray.controls.length) {
      const invalidIndex = formArray.controls.findIndex(item => item.invalid);
      if (invalidIndex > -1) {
        paging.navigateToErrorIndex(invalidIndex);

        return invalidIndex;
      }
    }

    return -1;
  }

  getFormGroupErrorMessage(): string | undefined {
    const formGroup = this.orderRequestForm.get('shelfOrderItems') as FormArray;

    if (!this.shelfDetails.length) {
      this.orderRequestForm.setErrors({ requiredItem: true });
      return 'Please select at least one shelf before submit.';
    } else if (!formGroup.controls.length) {
      this.orderRequestForm.setErrors({ requiredItem: true });
      return 'Please select at least one item before submit.';
    }
  }

  prepareRequestData(): OrderInventoryRequestViewResponse {
    const formData = this.orderRequestForm.getRawValue();
    const template = this.orderRequest;
    template.status = this.status;
    template.orderType = this.data.orderType;
    template.shelfDetails = this.shelfDetails.map(item => {
      return {
        shelfNo: item.shelfNo,
        shelfTypeCode: item.shelfTypeCode,
        shelfType: item.shelfType,
        shelfCode: item.shelfCode,
        shelfOption: item.shelfOption
      };
    });
    template.warehouseCode = this.data.warehouseCode;

    this.orderRequestView$
      .pipe(
        untilComponentDestroyed(this),
        filter(value => Boolean(value))
      )
      .subscribe((value: OrderInventoryRequestViewResponse) => {
        template.id = value.id;
        template.version = value.version;
        template.requestNo = value.requestNo;
        template.store = value.store;
        template.deliveryDetails = value.deliveryDetails;
      });

    template.shelfInventoryItems = formData.shelfOrderItems;
    template.selectiveItems = formData.selectiveOrderItems;

    return template;
  }

  openSelectShelfInventory() {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        SelectShelfInventoryModalComponent,
        { storeNo: this.orderRequest.store.storeNo },
        false
      )
    };

    const modal = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    if (modal && modal.content) {
      const selectedShelf = modal.content.childItem.notifyParent
        .pipe(untilComponentDestroyed(this))
        .subscribe(updated => {
          if (updated && updated.notificationType === NotificationTypeEnum.NEXT) {
            this.isLoading = updated.result.response;
          }

          if (selectedShelf) {
            selectedShelf.unsubscribe();
          }
        });
    }
  }

  showShelfDetails() {
    const initialState = {
      title: null,
      childItem: new ChildItem(ViewShelfDetailsComponent, { backPageTitle: 'Back to Order Request' }, false)
    };

    const modal = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    if (modal && modal.content) {
      const shelfDetails = modal.content.childItem.notifyParent.pipe(untilComponentDestroyed(this)).subscribe(() => {
        shelfDetails.unsubscribe();
      });
    }
  }

  openSelectiveItem() {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        SelectSelectiveItemModalComponent,
        {
          title: 'Selective Item',
          orderType: OrderTypeEnum.SPECIAL_REQUEST,
          storeNo: this.storeNo
        },
        false
      )
    };

    this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    return;
  }

  onCancel() {
    if (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      (this.isDataUpdated || this.orderRequestForm.touched)
    ) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  showHistory() {
    this.store.dispatch(new OrderRequestHistoryRequestAction({ orderId: this.data.requestId }));
    const initialState = {
      title: 'History',
      historyHeader: `Request No.: ${this.requestNo}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.ORDER_REQUEST,
      auditLogs$: this.auditLogs$
    };

    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new OrderRequestHistoryResponseAction({ auditLogs: null }));
    });
  }

  deleteRequest() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this request?',
        okText: 'Yes, delete',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.orderRequestService
            .deleteByRequestId({ orderId: this.data.requestId })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been deleted.');
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  cancelRequest() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number <strong>&quot;${this.requestNo}&quot;</strong>?`,
        label: 'Reason',
        okText: 'Yes, cancel',
        cancelText: 'Cancel',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.orderRequestInventoryService
            .approveRequest({
              requestNo: this.requestNo,
              status: ApproveStatusEnum.CANCELLED,
              comment: confirmModalRef.content.confirmMessage
            })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been cancelled.');
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  doApproveRejectRequest(isApprove: boolean) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: isApprove ? 'Are you sure you want to "Approve"?' : 'Are you sure you want to "Reject"?',
        label: 'Comment',
        okText: isApprove ? 'Approve' : 'Reject',
        okClass: isApprove ? 'btn btn-special-approve' : 'btn btn-special-reject',
        isRequiredConfirmMessage: !isApprove
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.localStore
            .pipe(
              select(selectOrderInventoryApproveRejectStatus),
              filter(isApproveRejectSuccess => isApproveRejectSuccess)
            )
            .subscribe(() => {
              this.alertApproveRejectModalSuccess(
                {
                  result: {
                    response: 'success',
                    errorResponse: null
                  }
                },
                isApprove
              );
            });
          this.store.dispatch(
            new OrderInventoryApproveRequestAction({
              requestNo: this.requestNo,
              status: isApprove ? ApproveStatusEnum.APPROVED : ApproveStatusEnum.REJECTED,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  alertApproveRejectModalSuccess(resp, isApprove) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: isApprove ? 'The request has been approved.' : 'The request has been rejected.'
      },
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK || result === ModalButtonResponseEnum.CANCEL) {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.NEXT, result: resp });
      }
      if (alertModal.content.actions) {
        alertModal.content.actions.unsubscribe();
      }
    });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState,
      keyboard: false,
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.modalService.hide(1);
        if (this.status === NewRequestStatusEnum.DRAFT) {
          this.listSearchCriteria.page = 0;
        }
      }
    });
  }

  alertErrorModal(errorResponse: ErrorOrderRequestResponse) {
    let minOrder: Money;
    let initialState: { title: string; message: any };

    if (errorResponse.code === '03002') {
      const error = errorResponse.message.split(':');

      if (error.length > 1) {
        const minOrderObj = error[1].split(' ');

        minOrder = {
          amount: minOrderObj[1] ? Number(minOrderObj[1]) : 0,
          currency: minOrderObj[0] ? minOrderObj[0] : 'THB'
        };
      }

      initialState = {
        title: 'Failed',
        message: this.translate.instant(errorResponse.translateKey, {
          context: errorResponse.message,
          amount: (minOrder && numeral(minOrder.amount).format(this.numberFormat)) || 0,
          currency: (minOrder && minOrder.currency) || 'THB'
        })
      };
    } else if (errorResponse.code === '05008' || errorResponse.code === '03012') {
      initialState = {
        title: 'Failed',
        message: errorResponse.message
      };
    } else if (errorResponse.code === '03010') {
      const errorMessage =
        errorResponse.result && errorResponse.result.errors && errorResponse.result.errors.length > 0
          ? errorResponse.result.errors[0].message
          : 'Some item has invalid data. Please remove item from the list.';

      initialState = {
        title: 'Failed',
        message: errorMessage
      };

      if (errorResponse.result) {
        this.itemValidator(errorResponse.result);
      }
    } else {
      initialState = {
        title: 'Failed',
        message: this.translate.instant(errorResponse.translateKey, {
          context: errorResponse.message
        })
      };
    }

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        if (errorResponse.code === '03004') {
          this.modalService.hide(1);
        }
      }
    });
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        this.isLoading = false;
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new OrderInventoryCreateSubmitRequestAction(this.prepareRequestData()));
        }
      });
  }

  onTriggerEdit() {
    this.data.title = 'Edit Order Request';
    this.data.mode = RequestPageModesEnum.REQUEST_EDIT;

    this.orderRequest.deliveryStatus = this.getDeliveryDetailsStatus(
      this.orderRequest.deliveryDetails && this.orderRequest.deliveryDetails.deliveryDetailsByType
    );
    this.clearSearchText();

    if (
      [
        NewRequestStatusEnum.DRAFT,
        NewRequestStatusEnum.AWAITING_APPROVAL,
        NewRequestStatusEnum.ASSIGN_QUANTITY
      ].includes(this.status)
    ) {
      this.orderRequestForm.get('shelfOrderItems').enable();
      this.orderRequestForm.get('selectiveOrderItems').enable();

      this.getButton();
    }
  }

  search() {
    // const formValue = this.searchForm.value;
    // const searchTerm = formValue && formValue.searchCriteria && formValue.searchCriteria.toLowerCase();
    // const result = !searchTerm
    //   ? this.baseShelfFormArray.controls
    //   : this.baseShelfFormArray.controls.filter(
    //       item =>
    //         item
    //           .get('articleNo')
    //           .value.toLowerCase()
    //           .includes(searchTerm) ||
    //         item
    //           .get('barcode')
    //           .value.toLowerCase()
    //           .includes(searchTerm) ||
    //         item
    //           .get('productName')
    //           .value.toLowerCase()
    //           .includes(searchTerm)
    //     );
    // this.resetFilteredShelfFormArrayTo(result);

    this.resetFilteredShelfFormArrayTo(this.baseShelfFormArray.controls);
  }

  updateValueChangesEvent(data, item) {
    const control = this.baseShelfFormArray.controls.find(ctrl => ctrl.get('articleNo').value === item.articleNo);

    if (!control) {
      return;
    }

    const amountExcVat = round(control.get('wholesalePriceExcVat').value.amount, 2) * data || 0;
    const totalVat = round(control.get('vatAmount').value.amount * data, 2) || 0;
    const amount = round(amountExcVat + totalVat, 2) || 0;

    this.isSkipSetFilteredForm = true;

    control.get('amountExcVat').patchValue({
      amount: amountExcVat,
      currency: control.get('amountExcVat').value.currency
    });
    control.get('totalVat').patchValue({ amount: totalVat, currency: control.get('vatAmount').value.currency });
    control.get('amount').patchValue({ amount, currency: control.get('amount').value.currency });
    control.updateValueAndValidity();

    control.setParent(this.baseShelfFormArray);

    this.store.dispatch(new OrderInventorySelectItemOrderUpdateItem(control.value));
    this.calculateTotalAmount();
  }

  updateValueSelectiveChangesEvent(data, item) {
    const control = this.baseSelectiveFormArray.controls.find(ctrl => ctrl.get('articleNo').value === item.articleNo);

    if (!control) {
      return;
    }

    const amountExcVat = round(control.get('wholesalePriceExcVat').value.amount, 2) * data || 0;
    const totalVat = round(control.get('vatAmount').value.amount * data, 2) || 0;
    const amount = round(amountExcVat + totalVat, 2) || 0;

    this.isSkipSetFilteredForm = true;

    control.get('amountExcVat').patchValue({
      amount: amountExcVat,
      currency: control.get('amountExcVat').value.currency
    });
    control.get('totalVat').patchValue({ amount: totalVat, currency: control.get('vatAmount').value.currency });
    control.get('amount').patchValue({ amount, currency: control.get('amount').value.currency });
    control.updateValueAndValidity();

    control.setParent(this.baseSelectiveFormArray);

    this.store.dispatch(new OrderSelectiveSelectItemOrderUpdateItem(control.value));
    this.calculateTotalAmount();
  }

  calculateTotalAmount() {
    let excVatAmt = 0;
    let totalVatAmt = 0;
    let incVatAmt = 0;

    this.baseShelfFormArray.controls.forEach(itemForm => {
      const amountExcVat = itemForm.get('amountExcVat').value.amount || 0;
      const totalVat = itemForm.get('totalVat').value.amount || 0;
      const amount = itemForm.get('amount').value.amount || 0;

      excVatAmt = round(excVatAmt + amountExcVat, 2);
      totalVatAmt = totalVatAmt + totalVat;
      incVatAmt = round(incVatAmt + amount, 2);
    });

    this.baseSelectiveFormArray.controls.forEach(itemForm => {
      const amountExcVat = itemForm.get('amountExcVat').value.amount || 0;
      const totalVat = itemForm.get('totalVat').value.amount || 0;
      const amount = itemForm.get('amount').value.amount || 0;

      excVatAmt = round(excVatAmt + amountExcVat, 2);
      totalVatAmt = totalVatAmt + totalVat;
      incVatAmt = round(incVatAmt + amount, 2);
    });

    this.orderRequest.amountExcVat.amount = excVatAmt;
    this.orderRequest.vatAmount.amount = round(totalVatAmt, 2);
    this.orderRequest.amount.amount = incVatAmt;
    this.isDataUpdated = true;
  }

  deleteShelf(index?: number) {
    const deleteAllItem = index === undefined;
    const initialState = {
      title: 'Confirm',
      okText: 'Yes, delete',
      cancelText: 'Cancel',
      message: !deleteAllItem
        ? 'Are you sure you want to delete this item?'
        : 'Are you sure you want to delete all items?'
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          if (!deleteAllItem) {
            this.deleteShelfItem(index);
          } else {
            this.setFirstPage();
            this.baseShelfFormArray.clear();

            this.store.dispatch(new OrderInventorySelectItemOrderReset());
            this.store.dispatch(new ShelfDetailsResetAction());
            this.checkDeliveryDetailsSelectiveDeletion();
          }

          this.calculateTotalAmount();
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  deleteShelfItem(index: number) {
    if (index > -1) {
      const articleNo = this.baseShelfFormArray.at(index).get('articleNo').value;

      this.baseShelfFormArray.removeAt(index);
      this.store.dispatch(new OrderInventorySelectItemOrderRemoveItem(articleNo));
      this.checkDeliveryDetailsSelectiveDeletion();
    }
  }

  deleteItemSelective(index?: number) {
    const deleteAllItem = index === undefined;
    const initialState = {
      title: 'Confirm',
      okText: 'Yes, delete',
      cancelText: 'Cancel',
      message: !deleteAllItem
        ? 'Are you sure you want to delete this item?'
        : 'Are you sure you want to delete all items?'
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          if (!deleteAllItem) {
            this.deleteProductItemSelective(index);
          } else {
            this.setFirstPage();
            this.baseSelectiveFormArray.clear();

            this.store.dispatch(new OrderSelectiveSelectItemOrderReset());

            this.checkDeliveryDetailsSelectiveDeletion();
          }

          this.calculateTotalAmount();
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  deleteProductItemSelective(index: number) {
    if (index > -1) {
      const articleNo = this.baseSelectiveFormArray.at(index).get('articleNo').value;

      this.baseSelectiveFormArray.removeAt(index);
      this.store.dispatch(new OrderSelectiveSelectItemOrderRemoveItem(articleNo));
      this.checkDeliveryDetailsSelectiveDeletion();
    }
  }

  openDeliveryDetails() {
    if (!this.baseShelfFormArray.controls.length) {
      this.showAlertModal('Please select at least one item.');
      return;
    }

    const initialState = {
      orderType: this.data.orderType,
      mode: this.data.mode,
      ...{
        segmentList: this.segmentList,
        orderSubType: this.orderRequest.orderSubType
      }
    };

    const modal = this.modalService.show(DeliveryDetailsComponent, {
      class: 'modal-lg',
      initialState
    });

    const updateDeliveryDetails = modal.content.updateDeliveryDetails
      .pipe(untilComponentDestroyed(this))
      .subscribe(updated => {
        this.isDataUpdated = updated;

        if (updateDeliveryDetails) {
          updateDeliveryDetails.unsubscribe();
        }
      });
  }

  checkDeliveryDetailsSelectiveDeletion() {
    if (this.baseShelfFormArray.value.length === 0 && this.baseSelectiveFormArray.value.length === 0) {
      this.store.dispatch(
        new OrderInventoryRequestSaveDeliveryDetailsAction({
          storeOpenDate: this.orderRequest.deliveryDetails.storeOpenDate,
          deliveryDetailsByType: null
        })
      );
    }
  }

  getSubmitMessage(isSave): string | undefined {
    if (isSave) {
      return 'The request has been saved.';
    } else if (this.status === NewRequestStatusEnum.DRAFT) {
      return 'The request has been sent to approver.';
    } else if (this.status === NewRequestStatusEnum.AWAITING_APPROVAL) {
      return 'The request has been updated.';
    } else if (this.status === NewRequestStatusEnum.ASSIGN_QUANTITY) {
      return 'The order has been created.';
    }
  }

  setFirstPage() {
    this.currentShelfPage = 1;
    this.currentSelectivePage = 1;
  }

  onSubmitSearch() {
    this.setFirstPage();
    this.search();
  }

  clearSearchText() {
    this.searchForm.controls['searchCriteria'].reset();
    this.setFirstPage();
    this.search();
  }

  clearLastKeyUpSearchText(event) {
    if (!event.target.value) {
      this.setFirstPage();
      this.search();
    }
  }

  sortList(a: AbstractControl, b: AbstractControl) {
    const aProductType = a.value.productType;
    const aArticleNo = a.value.articleNo;
    const bProductType = b.value.productType;
    const bArticleNo = b.value.articleNo;

    if (aProductType < bProductType) {
      return -1;
    }
    if (aProductType > bProductType) {
      return 1;
    }
    if (aArticleNo < bArticleNo) {
      return -1;
    }
    if (aArticleNo > bArticleNo) {
      return 1;
    }
    return 0;
  }

  getColorStatus(status: string): string {
    return status && status.toLowerCase();
  }

  showAlertModal(message: string) {
    this.showModalMessage('Alert', message);
  }

  showModalError(message: string) {
    this.showModalMessage('Failed', message);
  }

  showModalMessage(title: string, message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title,
        message
      }
    });
  }

  getSegmentList() {
    const shelfItemsList: string[] = this.baseShelfFormArray.value.map(item => {
      return this.segments[item.segment];
    });

    const selectiveItemsList: string[] = this.baseSelectiveFormArray.value.map(item => {
      return this.segments[item.segment];
    });

    const segments = Array.from([...new Set([...shelfItemsList, ...selectiveItemsList])]);
    this.segmentList = this.deliveryDetailsCondition.filter(v => segments.includes(v.segment));

    this.updateDeliveryDetails();
  }

  updateDeliveryDetails() {
    if (this.orderRequest.deliveryDetails) {
      const deliveryDetailsByType = { ...this.orderRequest.deliveryDetails.deliveryDetailsByType };
      const newDeliveryDetailsByType = {};

      for (const segment of this.segmentList) {
        for (const prop of segment.properties) {
          newDeliveryDetailsByType[prop] = {
            ...deliveryDetailsByType[prop],
            deliveryDate:
              (deliveryDetailsByType[prop] && deliveryDetailsByType[prop].deliveryDate) ||
              this.getDefaultDate(segment.noOfDateBefore)
          };
        }
      }

      this.orderRequest.deliveryDetails.deliveryDetailsByType = { ...newDeliveryDetailsByType };
      this.orderRequest.deliveryStatus = this.getDeliveryDetailsStatus(newDeliveryDetailsByType);
    }
  }

  getDefaultDate(noOfDateBefore: number) {
    if (!this.orderRequest.deliveryDetails.storeOpenDate) {
      return;
    }

    const defaultDate = new Date(this.orderRequest.deliveryDetails.storeOpenDate);
    defaultDate.setDate(defaultDate.getDate() - noOfDateBefore);
    return defaultDate;
  }

  getDeliveryDetailsStatus(data: DeliveryDetailsByTypeDictionary<DeliveryDetail>) {
    const dataLength = data && Object.keys(data).length;
    const today = moment().startOf('day');
    const storeOpenDate =
      this.orderRequest.deliveryDetails && moment(this.orderRequest.deliveryDetails.storeOpenDate).startOf('day');
    let isDataInvalid = false;

    for (const segment in data) {
      if (data.hasOwnProperty(segment)) {
        const deliveryDate = data[segment].deliveryDate && moment(data[segment].deliveryDate).startOf('day');

        isDataInvalid =
          !deliveryDate ||
          (this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
            (deliveryDate < today ||
              (this.data.orderType === OrderTypeEnum.FIRST_LOT_ORDER && deliveryDate >= storeOpenDate)));

        if (isDataInvalid) {
          break;
        }
      }
    }

    if (!isDataInvalid && this.data.orderType === OrderTypeEnum.FIRST_LOT_ORDER) {
      isDataInvalid = dataLength !== this.segmentList.length * 2;
    }

    return dataLength && !isDataInvalid ? 'success' : 'error';
  }

  callServiceExportItem(selected: any, filename: any) {
    this.orderRequestInventoryService.exportItemProduct(selected).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, filename);
      },
      errorResponse => {
        this.errorExport = errorResponse.error.message || this.translate.instant(errorResponse.error.translateKey);
        this.showModalError(this.errorExport);
      }
    );
  }

  onExportProductItem() {
    const selected = this.data.requestId;
    this.callServiceExportItem(selected, this.exportFileName());
  }

  alertConfirmUploadModal(
    initialState = {
      title: 'Confirm',
      message: 'Are you sure you want to import new file? All existing data will be lost.',
      okText: 'OK'
    }
  ) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.showOrderSelectiveItemModal();
        }
      });
  }

  onImportSelectiveItem() {
    if (this.formSelectiveItemControls && this.formSelectiveItemControls.length > 0) {
      this.alertConfirmUploadModal();
    } else {
      this.showOrderSelectiveItemModal();
    }
  }

  showOrderSelectiveItemModal() {
    this.orderModalUpload = this.modalService.show(OrderModalUploadComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        uploadUrl: this.importSelectiveUrl,
        uploadHeaders: this.importSelectiveHeaders,
        orderSubTypeEnum: OrderSubTypeEnum.INVENTORY,
        titleLabel: 'Selective Item',
        isAddSelectiveItem: true
      }
    });

    this.orderModalUpload.content.submitUpload.pipe(untilComponentDestroyed(this)).subscribe(result => {
      this.onLoadOrderSelectiveItemDetail(result.file);
    });
  }

  onImportOrderSelectiveItemUpdateQty() {
    this.orderModalUpload = this.modalService.show(OrderModalUploadComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        uploadUrl: this.importUrl,
        uploadHeaders: this.importHeaders,
        orderRequestId: this.data.requestId,
        orderSubTypeEnum: OrderSubTypeEnum.INVENTORY,
        titleLabel: 'Inventory'
      }
    });

    this.orderModalUpload.content.submitUpload.pipe(untilComponentDestroyed(this)).subscribe(result => {
      this.onLoadOrderSelectiveItemUpdateQty(result.file);
    });
  }

  onLoadOrderSelectiveItemDetail(value: any) {
    if (Array.isArray(value.validations) && value.validations.length > 0) {
      this.importResult = {
        title: `${value.validations.length} Errors Found.`,
        table: value.validations,
        message: null
      };
    } else if (Array.isArray(value.tdCatalogs) && value.tdCatalogs.length > 0) {
      this.baseSelectiveFormArray.clear();
      this.store.dispatch(new OrderSelectiveSelectItemOrderReset());

      value.tdCatalogs.forEach(item => {
        this.addSelectiveItem(item);
      });
      this.resetFilteredSelectiveFormArrayTo(this.baseSelectiveFormArray.controls);

      this.importResult = {
        title: 'Success',
        table: null,
        message: `The data have been imported.`
      };
    }

    this.importResultModel.show();
  }

  onLoadOrderSelectiveItemUpdateQty(value: any) {
    if (Array.isArray(value.validations) && value.validations.length > 0) {
      this.importResult = {
        title: `${value.validations.length} Errors Found.`,
        table: value.validations,
        message: null
      };
    } else if (value.orderRequestInventory) {
      const shelfInventoryData: Array<ImportOrderRequest> = value.orderRequestInventory.shelfInventoryItems.map(
        (dataValue: ProductOrderItem) => {
          return { articleNo: dataValue.articleNo, qty: dataValue.qty };
        }
      );

      const selectiveData: Array<ImportOrderRequest> = value.orderRequestInventory.selectiveItems.map(
        (dataValue: ProductOrderItem) => {
          return { articleNo: dataValue.articleNo, qty: dataValue.qty };
        }
      );

      this.store.dispatch(new OrderInventorySelectItemOrderUpdateAllItem(shelfInventoryData));
      this.store.dispatch(new OrderSelectiveSelectItemOrderUpdateAllItem(selectiveData));

      this.importResult = {
        title: 'Success',
        table: null,
        message: `The data have been imported.`
      };
    }

    this.importResultModel.show();
  }

  addSelectiveItem(productOrderItem) {
    productOrderItem.isAddItem = true;
    if (productOrderItem.qty) {
      let previousQty = 0;
      const selectPreviousQty$ = this.localStore
        .pipe(select(getOrderSelectiveSelectItemOrderById(productOrderItem.articleNo)))
        .pipe(filter(value => Boolean(value)))
        .subscribe(value => {
          previousQty = value.qty;
        });

      productOrderItem.qty = Number(previousQty) + Number(productOrderItem.qty);

      const itemPrice = new ItemPrice<ProductOrderItem>(productOrderItem, 'qty');
      const amountExcVat = itemPrice.getTotalFrom('wholesalePriceExcVat');
      const vatAmount = itemPrice.getTotalFrom('vatAmount');
      const amount = getPriceFormat(amountExcVat.amount + vatAmount.amount);

      productOrderItem.amountExcVat = amountExcVat;
      productOrderItem.totalVat = vatAmount;
      productOrderItem.amount = amount;

      // If allowToDelete is undefined, set to true
      if (typeof productOrderItem.allowToDelete === 'undefined') {
        productOrderItem.allowToDelete = true;
      }

      this.store.dispatch(new OrderSelectiveSelectItemOrderAddItem(productOrderItem));
      selectPreviousQty$.unsubscribe();
    }
  }

  mappingProductItem(productItems: any[]) {
    if (productItems.length === 0) {
      return null;
    }

    return productItems.map((item, i) => {
      return {
        no: i + 1,
        articleNo: item.articleNo,
        productName: item.productName,
        barcode: item.barcode,
        productType: this.translate.instant(`PRODUCT_ASSORTMENT.PRODUCT_TYPE.${item.productType}`),
        unit: item.unit,
        unitPrice: item.unitPrice.amount,
        quantity: item.qty,
        amount: item.amount.amount
      } as OrderRequestProductItemsExport;
    });
  }

  getStoreIcon(storeType: StoreType) {
    return storeType === StoreType.STORE_MODEL ? 'icon-storemodel' : 'icon-partnermodel';
  }

  exportFileName(): string {
    if (this.status !== NewRequestStatusEnum.DRAFT && this.requestNo) {
      return `${this.requestNo} (Inventory) ${this.timeToExport}.xlsx`;
    }
    return `${this.storeCode} (Inventory) ${this.timeToExport}.xlsx`;
  }

  resetFilteredShelfFormArrayTo(controls: Array<AbstractControl>) {
    this.filteredShelfFormArray.clear();
    controls.forEach(ctrl => this.filteredShelfFormArray.push(ctrl));
  }

  resetFilteredSelectiveFormArrayTo(controls: Array<AbstractControl>) {
    this.filteredSelectiveFormArray.clear();
    controls.forEach(ctrl => this.filteredSelectiveFormArray.push(ctrl));
  }

  isShowDeleteColumn() {
    return (
      [NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status) &&
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW
    );
  }

  hasImportFilePermission() {
    return this.authGuardService.checkPermission(['or_fl_inv_m']);
  }

  hasSavePermission() {
    return (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      [NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.ASSIGN_QUANTITY].includes(this.status) &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m', 'or_fl_inv_m', 'or_fl_qty_inv_m'])
    );
  }

  hasSubmitPermission() {
    return (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m', 'or_fl_inv_m', 'or_fl_qty_inv_m'])
    );
  }

  hasEditPermission() {
    let permission: boolean;

    if ([NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status)) {
      permission = this.authGuardService.checkPermission(['or_fl_inv_m']);
    } else if (this.status === NewRequestStatusEnum.ASSIGN_QUANTITY) {
      permission = this.authGuardService.checkPermission(['or_fl_qty_inv_m']);
    }

    return this.data.mode === RequestPageModesEnum.REQUEST_VIEW && permission;
  }

  hasCancelPermission() {
    return (
      [NewRequestStatusEnum.AWAITING_APPROVAL, NewRequestStatusEnum.ASSIGN_QUANTITY].includes(this.status) &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m', 'or_fl_inv_m'])
    );
  }

  hasDeletePermission() {
    return (
      this.status === NewRequestStatusEnum.DRAFT &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m', 'or_fl_inv_m'])
    );
  }

  hasApprovePermission() {
    return (
      this.status === NewRequestStatusEnum.AWAITING_APPROVAL &&
      this.authGuardService.checkPermission(['so_app', 'or_fl_inv_app'])
    );
  }

  hasAtLeastOnePermission() {
    return (
      this.hasSavePermission() ||
      this.hasEditPermission() ||
      this.hasApprovePermission() ||
      this.hasSubmitPermission()
    );
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportOrderRequest.timeFormat);
  }

  get isViewMode() {
    return this.data.mode === RequestPageModesEnum.REQUEST_VIEW;
  }

  get filteredShelfFormArray() {
    return this.filteredShelfFormGroup.get('shelfOrderItems') as FormArray;
  }

  get baseShelfFormArray() {
    return this.orderRequestForm.get('shelfOrderItems') as FormArray;
  }

  get filteredSelectiveFormArray() {
    return this.filteredSelectiveFormGroup.get('selectiveOrderItems') as FormArray;
  }

  get baseSelectiveFormArray() {
    return this.orderRequestForm.get('selectiveOrderItems') as FormArray;
  }

  get formSelectiveItemControls(): AbstractControl[] {
    return (this.orderRequestForm.get('selectiveOrderItems') as FormArray).controls;
  }
}
