import { createSelector } from '@ngrx/store';
import * as fromOrderSelectiveSelectItemOrderState from '../reducers/order-selective-select-item-order.reducers';
import { AppStates } from '../state/app.states';

const selectOrderSelectiveSelectItemOrderState = (state: AppStates) => state.orderSelectiveSelectItemOrder;

export const getOrderSelectiveSelectItemOrderById = articleNo =>
  createSelector(selectOrderSelectiveSelectItemOrderState, orders => orders.entities[articleNo]);

export const getCountOrderSelectiveSelectItemOrder = createSelector(
  selectOrderSelectiveSelectItemOrderState,
  fromOrderSelectiveSelectItemOrderState.selectTotal
);

export const selectAllOrderSelectiveSelectItemOrder = createSelector(
  selectOrderSelectiveSelectItemOrderState,
  fromOrderSelectiveSelectItemOrderState.selectAll
);

export const selectOrderSelectiveUpdated = createSelector(
  selectOrderSelectiveSelectItemOrderState,
  orders => orders.skipUpdated
);
