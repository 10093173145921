import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../../../environments/environment';
import { FileSubmitBeforeUploadComponent } from '../../../shared/components/file-submit-before-upload/file-submit-before-upload.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import {
  OrderDeliveryScheduleType,
  PurchaseConditionOrderDeliveryScheduleImportStatusEnum
} from '../../../shared/enum/purchase-condition.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { PurchaseConditionRequestService } from '../../../shared/services/purchase-condition-request.service';
import { AppStates } from '../../../shared/store/state/app.states';
import { b64toBlob } from '../../../shared/utils/b64toBlob-util';

@Component({
  selector: 'app-order-delivery-schedule-import-modal',
  templateUrl: './order-delivery-schedule-import-modal.component.html',
  styleUrls: ['./order-delivery-schedule-import-modal.component.scss']
})
export class OrderDeliveryScheduleImportModalComponent implements OnInit, OnDestroy {
  @ViewChild(FileSubmitBeforeUploadComponent, { static: false })
  fileUpload: FileSubmitBeforeUploadComponent;

  @Output() submitUpload: EventEmitter<any> = new EventEmitter();

  @Input() loading: boolean;
  @Input() isUploadSuccess: boolean;
  @Input() supplierName: string;
  @Input() supplierCode: string;
  @Input() scheduleMode: OrderDeliveryScheduleType;

  public form: FormGroup;
  public purchaseConditionOrderDeliveryScheduleImportStatusEnum = PurchaseConditionOrderDeliveryScheduleImportStatusEnum;
  public importPlaceholder: string;

  public errorFile: Blob;
  public errorMessage: string;
  private fileName;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: FormBuilder,
    private readonly logger: NGXLogger,
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected readonly purchaseConditionRequestService: PurchaseConditionRequestService
  ) {}

  ngOnInit() {
    this.createForm();
    this.importPlaceholder =
      this.scheduleMode === OrderDeliveryScheduleType.WEEKLY
        ? 'Order/Delivery Schedule-Weekly Template'
        : 'Order/Delivery Schedule-Calendar Template';
  }

  ngOnDestroy(): void {}

  createForm() {
    this.form = this.fb.group({
      importItems: [null]
    });
  }

  downloadOrderDeliveryScheduleTemplate() {
    this.purchaseConditionRequestService
      .onExportOrderDeliveryScheduleTemplate(this.scheduleMode)
      .subscribe(response => {
        const blob = new Blob([response]);
        saveAs(blob, `${this.generateFileName()} ${this.timeToExport}.xlsx`);
      });
  }

  downloadErrorExcel() {
    if (this.scheduleMode === OrderDeliveryScheduleType.WEEKLY) {
      saveAs(this.errorFile, `${this.supplierCode} Order Delivery Schedule Weekly Error ${this.timeToExport}.xlsx`);
    } else if (this.scheduleMode === OrderDeliveryScheduleType.MONTHLY) {
      saveAs(this.errorFile, `${this.supplierCode} Order Delivery Schedule Calendar Error ${this.timeToExport}.xlsx`);
    }
  }

  generateFileName() {
    if (this.scheduleMode === OrderDeliveryScheduleType.WEEKLY) {
      return environment.fileName.exportOrderDeliveryScheduleWeeklyTemplate.prefix;
    } else if (this.scheduleMode === OrderDeliveryScheduleType.MONTHLY) {
      return environment.fileName.exportOrderDeliveryScheduleMonthlyTemplate.prefix;
    }
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportOrderDeliveryScheduleWeeklyTemplate.timeFormat);
  }

  onSubmit() {}

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
      }
    });
  }

  onUpload($event) {
    const fileList = $event.files;
    this.errorFile = null;
    this.purchaseConditionRequestService
      .importOrderDeliveryWeeklySchedule(fileList.value[0], this.scheduleMode)
      .subscribe(
        res => {
          this.submitUpload.emit(res);
          this.bsModalRef.hide();
        },
        errorResponse => {
          this.form.get('importItems').setErrors({ importFail: true });
          if (errorResponse.error.fileBase64String) {
            this.errorMessage = `${errorResponse.error.rowErrorNo} Errors Found. Download this file to edit or delete invalid row then re-import.`;
            this.errorFile = b64toBlob(errorResponse.error.fileBase64String);
          } else {
            this.errorMessage = errorResponse.error.message;
          }
        }
      );
  }
}
