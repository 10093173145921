<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-product"></em>
    {{ data.title }}
  </div>

  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="hasDeletePermission()"
    (click)="deleteStockTransferRequest(stockTransfer?.id)"
  >
    <em class="icon-delete"></em>
    Delete
  </button>

  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="hasCancelPermission()"
    (click)="cancelStockTransferRequest(stockTransfer?.id, stockTransfer?.docNo)"
  >
    <em class="icon-forbidden"></em>
    Cancel Request
  </button>

  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCloseFullModal()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="stockTransferRequest" class="admin-form" [formGroup]="stockTransferForm" *ngIf="stockTransferForm">
  <div class="info-header-container">
    <div class="info-header-item" *ngIf="stockTransfer?.docNo">
      <div class="info-header-label">
        <span>Transfer Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ stockTransfer?.docNo }}</span>
      </div>
    </div>

    <div class="info-header-item">
      <div class="info-header-label">
        <span>Request Status:</span>
      </div>
      <div class="info-header-value">
        <span [ngClass]="getColorStatus(stockTransfer?.status)" class="stock-transfer-status">{{
          'STOCK_TRANSFER_REQUEST.STATUS.' + stockTransfer?.status | translate
        }}</span>
      </div>
    </div>
  </div>

  <!-- Information Details -->
  <div class="information-container">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Ship From</span>
          </div>
          <div class="info-header-value">
            {{ stockTransfer?.shipFromCodeName | dashDisplay }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Ship To</span>
          </div>
          <div class="info-header-value">
            {{ stockTransfer?.shipToCodeName | dashDisplay }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Requested By</span>
          </div>
          <div class="info-header-value">
            {{ stockTransfer?.requesterName | dashDisplay: '(None)' }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Requested Date</span>
          </div>
          <div class="info-header-value">
            {{
              stockTransfer?.requestedDate
                ? (stockTransfer?.requestedDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay)
                : '(None)'
            }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-md-flex flex-row">
    <button
      type="button"
      class="btn btn-secondary mr-2 my-3"
      *ngIf="data.mode !== stockTransferPageMode.VIEW"
      (click)="openSelectProductModal()"
    >
      <em class="icon-plus"></em>
      Select item
    </button>
    <div class="my-3">
      <app-import-export-button
        *ngIf="data.mode !== stockTransferPageMode.VIEW"
        [buttons]="buttons"
        (import)="checkExistingItem()"
      ></app-import-export-button>
    </div>

    <div class="d-flex ml-auto">
      <button type="button" id="deliveryDetails" class="btn btn-standard ml-2 my-3" (click)="openDeliveryDetails()">
        <em
          class="fa nicon icon-truck mr-2"
          [ngClass]="{
            success: !stockTransferForm?.errors?.isEmpty,
            error: stockTransferForm?.errors?.isEmpty || stockTransferForm.get('deliveryDate').errors?.beforeToday
          }"
        ></em
        >Delivery Details
      </button>
    </div>
  </div>

  <ng-container *ngIf="!getItemForm.controls.length">
    <div class="align-middle">
      <div class="d-flex justify-content-center no-data">
        <img width="240" height="240" src="/assets/images/no-item.svg" alt="no item" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="stockTransferForm">
    <div class="mt-3 table-responsive">
      <table
        *ngIf="form && getItemForm && getItemForm.controls?.length"
        id="datatable"
        class="table table-striped table-bordered table-hover w-100"
      >
        <thead>
          <tr>
            <th class="text-center align-top" *ngIf="![stockTransferPageMode.VIEW].includes(data.mode)">
              <a (click)="deleteItem(null, null)">
                <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
              </a>
            </th>
            <th class="text-center align-top">No.</th>
            <th class="text-center align-top">Product Name</th>
            <th class="text-center align-top">Article No.</th>
            <th class="text-center align-top w-10">Barcode</th>
            <th class="text-center align-top w-10">Product Type</th>
            <th class="text-center align-top w-10">Unit</th>
            <th class="text-center align-top w-10">Quantity</th>
          </tr>
        </thead>
        <tbody formArrayName="items">
          <ng-container *ngFor="let stockTransferItem of paging.currentPageData; index as i">
            <ng-container [formGroupName]="i + (currentPage - 1) * pageSize">
              <tr
                [ngClass]="{
                  'error-background':
                    stockTransferItem?.get('barcode').errors?.barcode ||
                    stockTransferItem?.get('barcode').errors?.productName
                }"
              >
                <td class="text-center" *ngIf="![stockTransferPageMode.VIEW].includes(data.mode)">
                  <a
                    (click)="deleteItem(stockTransferItem?.get('articleNo').value, i + (currentPage - 1) * pageSize)"
                  >
                    <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
                  </a>
                </td>
                <td class="text-center">{{ stockTransferItem?.get('itemNo').value }}</td>
                <td>
                  {{ stockTransferItem?.get('productName').value }}
                  <div
                    class="minimum"
                    *ngIf="
                      stockTransferItem?.get('productChanges').value &&
                      (stockTransferItem?.get('productChanges').value)['productName']
                    "
                  >
                    Product Name has been updated.
                  </div>
                  <div *ngIf="stockTransferItem?.get('barcode').errors" class="invalid-display text-left">
                    <div *ngIf="stockTransferItem?.get('barcode').errors.barcode">
                      {{ stockTransferItem?.get('barcode').errors.barcode }}
                    </div>
                  </div>
                </td>
                <td>{{ stockTransferItem?.get('articleNo').value }}</td>
                <td>{{ stockTransferItem?.get('barcode').value }}</td>
                <td>
                  {{ 'PRODUCT_ASSORTMENT.PRODUCT_TYPE.' + stockTransferItem?.get('productType').value | translate }}
                </td>
                <td class="text-center">
                  {{ stockTransferItem?.get('unit').value }}
                  <div
                    class="minimum"
                    *ngIf="
                      stockTransferItem?.get('productChanges').value &&
                      (stockTransferItem?.get('productChanges').value)['unit']
                    "
                  >
                    Unit has been updated.
                  </div>
                </td>
                <td class="text-center td-input-column-150">
                  <app-numeric-textbox
                    id="itemQuantity"
                    formControlName="quantity"
                    [negativeSign]="false"
                    placeholder="Quantity"
                    [format]="'0,0'"
                    [decimals]="0"
                    maxlength="10"
                    ngClass="numeric-format quantity"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmit && stockTransferItem?.get('quantity').errors
                    }"
                  >
                  </app-numeric-textbox>
                  <div *ngIf="stockTransferItem?.get('quantity').errors" class="invalid-display text-left">
                    <div *ngIf="stockTransferItem?.get('quantity').errors?.isZero">
                      {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                    </div>
                    <div *ngIf="isSubmit && stockTransferItem?.get('quantity').errors.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>

    <app-ui-pagination
      #paging
      [(currentPage)]="currentPage"
      [(pageSize)]="pageSize"
      [items]="getItemForm.controls"
      [valueChangeDetector]="getItemForm.valueChanges | async"
    >
    </app-ui-pagination>
  </ng-container>

  <!--footer-->
  <ng-container>
    <ng-container *ngIf="![stockTransferPageMode.VIEW].includes(data.mode); else otherMode">
      <div class="fixed-row-bottom form-row">
        <div class="float-left">
          <button
            *ngIf="canSave()"
            type="button"
            id="btn-save"
            class="btn btn-secondary"
            (click)="onSave()"
            appPreventDoubleClick
          >
            Save
          </button>
        </div>
        <div class="float-right">
          <span>
            <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCloseFullModal()">
              Cancel
            </button>
            <button
              type="button"
              id="btn-submit"
              class="btn btn-primary"
              (click)="onSubmit()"
              appPreventDoubleClick
              [disabled]="getItemForm?.controls.length === 0"
            >
              Submit
            </button>
          </span>
        </div>
      </div>
    </ng-container>

    <ng-template #otherMode>
      <ng-container>
        <div class="fixed-row-bottom form-row">
          <div class="float-left"></div>
          <div class="float-right">
            <span class="mr-2" *ngIf="hasEditPermission()">
              <button type="button" id="btn-edit" class="btn btn-primary" (click)="toggleToEdit()">Edit</button>
            </span>
            <span *ngIf="hasApprovePermission()">
              <button
                type="button"
                id="btn-reject"
                class="btn btn-special-reject mr-2"
                (click)="onApproveRejectRequest(false)"
              >
                Reject
              </button>
              <button
                type="button"
                id="btn-approve"
                class="btn btn-special-approve"
                (click)="onApproveRejectRequest(true)"
              >
                Approve
              </button>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <!--End footer-->
</div>
