import { Injectable, Type } from '@angular/core';
import { ChildItem } from '../layouts/modals/full-modal/child-item';
import { PortalModule } from '../models';

@Injectable()
export class ModuleUtil {
  /**
   * @description Initial state for FullModalComponent
   */
  public static InitialState(
    item: any,
    component: Type<any>,
    mode: string,
    title: string,
    originPage: string,
    portalModule: string = null
  ) {
    if (portalModule == null || portalModule.length === 0) {
      portalModule = item.module;
    }

    const objValue = {
      title,
      mode,
      originPage
    };

    switch (portalModule) {
      case PortalModule.PURCHASE_REQUEST:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              ...objValue,
              prNo: item.id,
              status: item.status,
              type: item.type
            },
            true
          )
        };
      case PortalModule.PROMOTION:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              ...objValue,
              promotionRequestId: item.id
            },
            true
          )
        };
      case PortalModule.CLAIM:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              ...objValue,
              claimRequestNo: item.claimRequestNo,
              receiveOrderNo: item.receiveOrderNo,
              claimRequestStatus: item.claimRequestStatus
            },
            true
          )
        };
      case PortalModule.VOUCHER:
      case PortalModule.SHELF_REQUEST:
      case PortalModule.SHELF_FIX_ASSET_REQUEST:
      case PortalModule.SHELF_INVENTORY_REQUEST:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              ...objValue,
              requestId: item.id
            },
            true
          )
        };
      case PortalModule.MERCHANT:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              ...objValue,
              merchantRequestId: item.id
            },
            true
          )
        };
      case PortalModule.STORE:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              ...objValue,
              storeId: item.id,
              merchant: item.merchantNo
            },
            true
          )
        };
      case PortalModule.SUPPLIER_GROUP:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              ...objValue,
              id: item.id
            },
            true
          )
        };
      case PortalModule.ORDER:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              ...objValue,
              requestId: item.id,
              orderType: item.orderType,
              orderSubType: item.orderSubType
            },
            true
          )
        };
      case PortalModule.PRODUCT:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              ...objValue,
              id: item.id,
              articleNo: item.requestInfo.articleNo,
              articleType: item.requestInfo.articleType,
              productType: item.requestInfo.productType
            },
            true
          )
        };
      case PortalModule.REWARD:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              ...objValue,
              requestId: item.id
            },
            true
          )
        };
      case PortalModule.STOCK_TRANSFER:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              ...objValue,
              id: item.id,
              shipFrom: item.shipFromCodeName,
              shipTo: item.shipToCodeName,
              status: item.requestStatus
            },
            true
          )
        };
      case PortalModule.PURCHASE_CONDITION:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              ...objValue,
              id: item.id,
              supplierCode: item.supplierCode,
              supplierName: item.supplierName
            },
            true
          )
        };
      default:
        return null;
    }
  }
}
