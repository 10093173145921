import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StockTransferService } from '../../services/stock-trasfer-service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import {
  StockTransferActionType,
  StockTransferCloseRequestAction,
  StockTransferListRequestAction,
  StockTransferListResponseAction,
  StockTransferViewRequestAction,
  StockTransferViewResponseAction
} from '../actions/stock-transfer.actions';

@Injectable()
export class StockTransferEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly stockTransferService: StockTransferService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchStockTransferList$ = this.actions$.pipe(
    ofType<StockTransferListRequestAction>(StockTransferActionType.STOCK_TRANSFER_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StockTransferActionType.STOCK_TRANSFER_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action;
    }),
    switchMap(action => {
      return this.stockTransferService.searchByCriteria(action.payload).pipe(
        map(response => {
          return new StockTransferListResponseAction(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getStockTransferByDocNo$ = this.actions$.pipe(
    ofType<StockTransferViewRequestAction>(StockTransferActionType.STOCK_TRANSFER_VIEW_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StockTransferActionType.STOCK_TRANSFER_VIEW_REQUEST}: ` + this.stringify(action.payload)
      );
      return action;
    }),
    switchMap(action => {
      return this.stockTransferService.getStockTransferByDocNo(action.payload).pipe(
        map(response => {
          return new StockTransferViewResponseAction(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  closeStockTransferOrder$ = this.actions$.pipe(
    ofType<StockTransferCloseRequestAction>(StockTransferActionType.STOCK_TRANSFER_CLOSE_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StockTransferActionType.STOCK_TRANSFER_CLOSE_REQUEST}: ` + this.stringify(action.payload)
      );
      return action;
    }),
    switchMap(action =>
      this.stockTransferService.closeStockTransfer(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'This transfer order will be closed after WMS process has been done.'
            })
        ),
        catchError(err => {
          return this.errorHandling(err);
        })
      )
    )
  );

  errorHandling(err: any) {
    return err.error && err.error.code === '00005'
      ? of(new LayoutActionVersionError(true))
      : of(new LayoutActionLoadError(err));
  }

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
