import { Attribute, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { environment as env } from '../../../environments/environment';
import { AppSrcDirective } from './app-src.directive';

@Directive({
  selector: 'img[appAdsSrc]'
})
export class AppAdsSrcDirective extends AppSrcDirective implements OnInit, OnDestroy {
  @Attribute('img-error') public imgError: string;
  @Input() appAdsSrc: string;

  protected imgUrl = `${env.storageUrl}${env.services.advertisements.bucketName}`;

  constructor(protected readonly renderer: Renderer2, protected readonly el: ElementRef) {
    super(renderer, el);
  }

  get srcImage(): string {
    return this.appAdsSrc;
  }
}
