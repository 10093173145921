import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models';
import {
  DuplicatedSupplier,
  SupplierPagination,
  SupplierSearchCriteria,
  SupplierViewResponse
} from '../../models/supplier.model';

export enum SupplierActionTypes {
  SUPPLIER_LIST_REQUEST = '[Supplier List Page] Supplier List Request',
  SUPPLIER_LIST_RESPONSE = '[Supplier List API] Supplier List Response',
  SUPPLIER_RESET = '[Supplier View Page] Supplier Reset',

  SUPPLIER_GET_BY_CODE_REQUEST = '[Supplier] Get by Supplier Code Request',
  SUPPLIER_GET_BY_CODE_FROM_PURCHASE_REQUEST = '[Supplier] Get by Supplier Code From Purchase Request',
  SUPPLIER_GET_BY_CODE_RESPONSE = '[Supplier] Get by Supplier Code Response',
  SUPPLIER_GET_BY_CODE_RESET = '[Supplier] Get by Supplier Code Reset',

  SUPPLIER_VALIDATE_REQUEST = '[Supplier] Validate Request',
  SUPPLIER_VALIDATE_RESPONSE = '[Supplier] Validate Response',
  SUPPLIER_VALIDATE_RESET = '[Supplier] Validate Reset',

  SUPPLIER_SUBMIT_EDIT_REQUEST = '[Supplier] Supplier Submit edit Request',
  SUPPLIER_SUBMIT_EDIT_RESPONSE = '[Supplier] Supplier Submit edit Response',
  SUPPLIER_SUBMIT_EDIT_ERROR = '[Supplier] Supplier Submit edit Error',
  SUPPLIER_SUBMIT_EDIT_RESET = '[Supplier] Supplier Submit edit Reset',

  SUPPLIER_ACTIVATE_BY_ID_REQUEST = 'Supplier Activate ID Request',
  SUPPLIER_ACTIVATE_BY_ID_RESPONSE = 'Supplier Activate ID Response',
  SUPPLIER_ACTIVATE_BY_ID_RESET = 'Supplier Activate ID  Reset'
}

export class SupplierListRequestAction implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_LIST_REQUEST;
  constructor(public payload: SupplierSearchCriteria) {}
}

export class SupplierListResponseAction implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_LIST_RESPONSE;

  constructor(public payload?: SupplierPagination) {}
}

export class SupplierResetAction implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_RESET;
  constructor() {}
}

export class SupplierBySupplierCodeRequestAction implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_GET_BY_CODE_REQUEST;

  constructor(public payload: { supplierCode: string }) {}
}

export class SupplierBySupplierCodeFromPurchaseRequestAction implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_GET_BY_CODE_FROM_PURCHASE_REQUEST;

  constructor(public payload: string) {}
}

export class SupplierBySupplierCodeResponseAction implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_GET_BY_CODE_RESPONSE;

  constructor(public payload: { supplierView: SupplierViewResponse }) {}
}

export class ResetSupplierBySupplierCodeRequestSelected implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_GET_BY_CODE_RESET;
}

export class SupplierActivateRequestAction implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_ACTIVATE_BY_ID_REQUEST;

  constructor(public payload: { id: string; status: string; version: number }) {}
}

export class SupplierActivateResponseAction implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_ACTIVATE_BY_ID_RESPONSE;
  constructor(public payload: { status: string }) {}
}

export class ResetSupplierActivate implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_ACTIVATE_BY_ID_RESET;
}

export class SupplierValidateActionRequest implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_VALIDATE_REQUEST;
  constructor(public payload: DuplicatedSupplier) {}
}

export class SupplierValidateActionResponse implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_VALIDATE_RESPONSE;
  constructor(public payload: { isSuccess: boolean; error: string }) {}
}

export class SupplierValidateAction implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_VALIDATE_RESET;
}

export class SupplierSubmitEditRequestAction implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_SUBMIT_EDIT_REQUEST;

  constructor(public payload: SupplierViewResponse) {}
}

export class SupplierSubmitEditResponseAction implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_SUBMIT_EDIT_RESPONSE;

  constructor(public payload: SupplierViewResponse) {}
}

export class SupplierSubmitEditResetAction implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_SUBMIT_EDIT_RESET;
}

export class SupplierSubmitErrorAction implements Action {
  readonly type = SupplierActionTypes.SUPPLIER_SUBMIT_EDIT_ERROR;
  constructor(public payload: ErrorResponse) {}
}

export type SupplierActions =
  | SupplierListRequestAction
  | SupplierListResponseAction
  | SupplierResetAction
  | SupplierBySupplierCodeRequestAction
  | SupplierBySupplierCodeResponseAction
  | ResetSupplierBySupplierCodeRequestSelected
  | SupplierActivateRequestAction
  | SupplierActivateResponseAction
  | ResetSupplierActivate
  | SupplierValidateActionRequest
  | SupplierValidateActionResponse
  | SupplierValidateAction
  | SupplierSubmitEditRequestAction
  | SupplierSubmitEditResponseAction
  | SupplierSubmitEditResetAction
  | SupplierSubmitErrorAction
  | SupplierBySupplierCodeFromPurchaseRequestAction;
