import { DeliveryByTypeEnum } from '../enum/delivery-by-type.enum';
import { AuditLog } from './audit-log.model';
import { BaseReport } from './base-report.model';
import { Sort } from './pagination.model';
import { Price } from './price.model';

export class OrderSearchCriteria {
  searchCriteria?: string;
  orderStatus?: string;
  orderType?: string[];
  storeType?: string[];
  deliveryBy?: DeliveryByTypeEnum[];
  orderDateFrom?: string;
  orderDateTo?: string;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  warehouseCode?: string[];
  page: number;
  size: number;
}

export class OrderListResponse {
  id: number;
  content: OrderContent[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export class OrderContent {
  id?: string;
  orderNo: string;
  storeCodeName: string;
  storeType: string;
  orderType: string;
  orderBy: string;
  orderDate: string;
  deliveryDate?: string;
  requestedDeliveryDate?: string;
  createdDate?: string;
  total: number | Price;
  itemAmount: Price;
  vatAmount: Price;
  deliveryBy: DeliveryByTypeEnum;
  status: string;
  customerDetail?: OrderCustomerDetail;
  items?: OrderItem[];
  auditLogs?: AuditLog[];
  requestNo: string;
  warehouseCode?: string;
  warehouseName?: string;
  warehouseDisplayName?: string;
}

export class OrderItem {
  articleNo: string;
  productName: string;
  barcode: string;
  productType: string;
  unit: string;
  wholesalePrice: number;
  qty: number;
  assignedQty?: number;
  amount: number;
  vat: boolean;
  itemAmount: Price;
  vatAmount: Price;
  reason: string;
  confirmedStock: boolean;
  itemStatus: string;
  deliveredQty: number;
  orderedQty: number;
  receivedQty: number;
  claimedQty: number;
  approvedClaimQty: number;
  remainQty: number;
}

export class OrderCustomerDetail {
  storeCode: string;
  storeCodeName?: string;
  storeName: string;
  address: string;
  state: string;
  postCode: string;
  contactName: string;
  contactNumber: string;
  deliveryNote: string;
  deliveryByFourWheelsTruck: string;
  noEntry: string;
}

export class OrderPrintCriteria {
  id: string;
  format: string;
  locale: string;
}

export enum OrderTabs {
  ORDER_DETAILS = 'ORDER_DETAILS',
  RECEIVE_SUMMARY = 'RECEIVE_SUMMARY'
}

export class OrderExportCriteria extends BaseReport {
  orderDateFrom: string;
  orderDateTo: string;
  orderStatus: string;
  orderType: string;

  constructor(init?: Partial<OrderExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
