import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { GoodsReceiveModeEnum } from '../../../shared/enum/goods-receive.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { PermissionAction } from '../../../shared/enum/permission-action';
import {
  PurchaseOrderItemStatusEnum,
  PurchaseOrderModeEnum,
  PurchaseOrderStatusEnum,
  ShipToType
} from '../../../shared/enum/purchase-order.enum';
import {
  PurchaseRequestModeEnum,
  PurchaseRequestStatusEnum,
  PurchaseRequestTypeEnum
} from '../../../shared/enum/purchase-request.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { ErrorResponse } from '../../../shared/models';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderPageTabs,
  PurchaseOrderPrintCriteria,
  PurchaseOrderSearchCriteria
} from '../../../shared/models/purchase-order.model';
import { PurchaseRequestDeliveryDetail } from '../../../shared/models/purchase-request.model';
import { AuthGuardService } from '../../../shared/services';
import { PurchaseOrderService } from '../../../shared/services/purchase-order.service';
import {
  PurchaseOrderCancelRequest,
  PurchaseOrderCloseRequest,
  PurchaseOrderListRequest,
  PurchaseOrderReset,
  PurchaseOrderViewRequest
} from '../../../shared/store/actions/purchase-order.actions';
import { selectGoodsReceiveErrorResponse } from '../../../shared/store/selectors/goods-receive.selectors';
import {
  selectEditedPurchaseOrder,
  selectPurchaseOrderCancelError,
  selectPurchaseOrderCriteria
} from '../../../shared/store/selectors/purchaser-order.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { convertUtcToBkk, getDateFromString } from '../../../shared/utils/date-util';
import { generatedFilenamePdf } from '../../../shared/utils/generate-filename-util';
import { PermissionsUtil } from '../../../shared/utils/permissions-util';
import { GoodsReceiveViewComponent } from '../goods-receive-view/goods-receive-view.component';
import { PurchaseRequestDeliveryDetailsComponent } from '../purchase-request-delivery-details/purchase-request-delivery-details.component';
import { VatDetailsModalComponent } from '../vat-details-modal/vat-details-modal.component';

@Component({
  selector: 'app-purchase-order-view',
  templateUrl: './purchase-order-view.component.html',
  styleUrls: ['./purchase-order-view.component.scss']
})
export class PurchaseOrderViewComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    poNo: string;
    id: string;
    mode: PurchaseOrderModeEnum;
    title: string;
  };
  dateTimeDisplay = environment.dateTimeDisplay;
  purchaseOrderCriteria: PurchaseOrderSearchCriteria;
  bsModalRef: BsModalRef;
  localStore: Observable<any>;
  selectedTab: PurchaseOrderPageTabs;
  purchaseOrder: PurchaseOrder;
  purchaseOrderForm: FormGroup;
  shipToType = ShipToType;
  vatDetails: any[];
  permissionAction = PermissionAction;

  currentPage: number;
  pageSize: number;

  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly translate: TranslateService,
    protected authGuardService: AuthGuardService,
    private purchaseOrderService: PurchaseOrderService,
    private spinner: NgxSpinnerService,
    public permissionsUtil: PermissionsUtil
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.initialPoData();
    this.localStore.pipe(select(selectPurchaseOrderCancelError), take(1)).subscribe(error => {
      if (error) {
        const initialState = {
          title: 'Error',
          message: error.message,
          isRefresh: false
        };
        this.modalService.show(AlertModalComponent, {
          initialState
        });
      }
    });

    this.localStore.pipe(select(selectGoodsReceiveErrorResponse), debounceTime(500)).subscribe(error => {
      if (
        error &&
        error.code === '00001' &&
        (error.message === 'Purchase Order Status has been changed' ||
          error.message.includes('has already changed status'))
      ) {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
      }
    });

    // Current searchCriteria
    this.localStore.pipe(select(selectPurchaseOrderCriteria), distinctUntilChanged()).subscribe(criteria => {
      this.purchaseOrderCriteria = criteria;
    });

    this.currentPage = 1;
    this.pageSize = 20;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(new PurchaseOrderReset());
    this.store.dispatch(new PurchaseOrderListRequest(this.purchaseOrderCriteria));
  }

  // Override since list page already subscribe
  subscribeForVersionError() {}

  initialPoData() {
    this.selectedTab = PurchaseOrderPageTabs.PO_DETAILS;

    this.store
      .pipe(
        untilComponentDestroyed(this),
        select(selectEditedPurchaseOrder),
        filter(po => po !== null && po.id !== null)
      )
      .subscribe(po => {
        this.purchaseOrder = {
          ...po,
          orderScheduleDayOfWeekDisplay: [PurchaseRequestTypeEnum.Z9, PurchaseRequestTypeEnum.Z9_EDIT].includes(
            po.type
          )
            ? this.getDayOfWeekDisplay(po.orderSchedule)
            : null
        };

        if (this.purchaseOrder.items && this.purchaseOrder.items.length > 0) {
          // this form is only use for re-use to display delivery details
          this.createForm(this.purchaseOrder);
          this.generateVatDetails();
        }
      });

    this.store.dispatch(new PurchaseOrderViewRequest(this.data.id));
  }

  getDayOfWeekDisplay(dayIndex: number) {
    const dayOfWeekDisplay = this.translate.instant('DAY_INDEX.' + dayIndex);
    return dayOfWeekDisplay;
  }

  generateVatDetails() {
    this.vatDetails = [];
    if (this.purchaseOrder && this.purchaseOrder.items && this.purchaseOrder.items.length > 0) {
      this.purchaseOrder.items.forEach(item => {
        this.vatDetails.push({
          itemNo: item.itemNo,
          productName: item.productName,
          vat: item.vatPct,
          vatAmount: item.totalVatAmount,
          isInvalid: false,
          isReject: false,
          supplierVat: this.purchaseOrder && this.purchaseOrder.supplierVat,
          productVat: item.productVat
        });
      });
    }
  }

  showVatDetails() {
    this.modalService.show(VatDetailsModalComponent, {
      initialState: {
        title: 'VAT',
        vatDetails: this.vatDetails,
        vatAmountPrecision: 4
      }
    });
  }

  showDeliveryDetail(index: number) {
    this.modalService.show(PurchaseRequestDeliveryDetailsComponent, {
      class: 'delivery-details',
      backdrop: 'static',
      keyboard: false,
      initialState: {
        title: 'Delivery Details',
        data: {
          type: this.purchaseOrder.type,
          status: PurchaseRequestStatusEnum.COMPLETED,
          mode: PurchaseRequestModeEnum.VIEW
        },
        isSubmit: false,
        parentFormItem: this.getItemForm,
        parentFormItemIndex: this.getItemForm.at(index).get('deliveryDetail')
      }
    });
  }

  get form(): FormGroup {
    return this.purchaseOrderForm;
  }

  get getItemForm(): FormArray {
    return this.form.get('items') as FormArray;
  }

  createForm(purchaseOrder: PurchaseOrder) {
    this.purchaseOrderForm = this.fb.group({
      items: this.createItemForm(purchaseOrder.items)
    });
  }

  createItemForm(poItems: PurchaseOrderItem[]): FormArray {
    const poItemForms = new FormArray([]);
    poItems.forEach(item => {
      const prItemFormGroup = this.fb.group({
        deliveryDetail: this.createDeliveryDetailForm(item.deliveryDetail)
      });
      poItemForms.push(prItemFormGroup);
    });
    return poItemForms;
  }

  createDeliveryDetailForm(deliveryDetail: PurchaseRequestDeliveryDetail): FormGroup {
    const requestDeliveryDate = this.getValue(deliveryDetail.requestDeliveryDate);
    return this.fb.group({
      requestDeliveryDate: [
        {
          value: requestDeliveryDate ? getDateFromString(convertUtcToBkk(requestDeliveryDate)) : null,
          disabled: true
        }
      ],
      deliveryNote: [
        {
          value: this.getValue(deliveryDetail.deliveryNote),
          disabled: true
        }
      ],
      shipToId: deliveryDetail && deliveryDetail.shipTo && deliveryDetail.shipTo.id ? deliveryDetail.shipTo.id : null,
      shipToName: [
        {
          value:
            deliveryDetail && deliveryDetail.shipTo && deliveryDetail.shipTo.name ? deliveryDetail.shipTo.name : null,
          disabled: true
        }
      ],
      shipToDetail: [
        {
          value:
            deliveryDetail && deliveryDetail.shipTo && deliveryDetail.shipTo.address
              ? deliveryDetail.shipTo.address
              : null,
          disabled: true
        }
      ],
      shipToContactName: [
        {
          value:
            deliveryDetail && deliveryDetail.shipTo && deliveryDetail.shipTo.contactPersonName
              ? deliveryDetail.shipTo.contactPersonName
              : null,
          disabled: true
        }
      ],
      shipToContactNumber: [
        {
          value:
            deliveryDetail && deliveryDetail.shipTo && deliveryDetail.shipTo.contactPersonNumber
              ? deliveryDetail.shipTo.contactPersonNumber
              : null,
          disabled: true
        }
      ],
      billToId: [
        {
          value:
            deliveryDetail && deliveryDetail.billTo && deliveryDetail.billTo.id ? deliveryDetail.billTo.id : null,
          disabled: true
        }
      ],
      billToName: [
        {
          value:
            deliveryDetail && deliveryDetail.billTo && deliveryDetail.billTo.name ? deliveryDetail.billTo.name : null,
          disabled: true
        }
      ],
      billToDetail: [
        {
          value:
            deliveryDetail && deliveryDetail.billTo && deliveryDetail.billTo.address
              ? deliveryDetail.billTo.address
              : null,
          disabled: true
        }
      ],
      billToContactName: [
        {
          value:
            deliveryDetail && deliveryDetail.billTo && deliveryDetail.billTo.contactPersonName
              ? deliveryDetail.billTo.contactPersonName
              : null,
          disabled: true
        }
      ],
      billToContactNumber: [
        {
          value:
            deliveryDetail && deliveryDetail.billTo && deliveryDetail.billTo.contactPersonNumber
              ? deliveryDetail.billTo.contactPersonNumber
              : null,
          disabled: true
        }
      ]
    });
  }

  goToGr() {
    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: true,
      initialState: {
        childItem: new ChildItem(
          GoodsReceiveViewComponent,
          {
            poNo: this.data.poNo,
            mode: GoodsReceiveModeEnum.CREATE,
            title: 'Create GR'
          },
          true
        )
      }
    });
  }

  onSelectTabs(tab: PurchaseOrderPageTabs) {
    this.selectedTab = tab;
  }

  getColorStatus(status: PurchaseOrderStatusEnum) {
    return status ? status.toLowerCase() : '';
  }

  get purchaseOrderPageTabs() {
    return PurchaseOrderPageTabs;
  }

  get purchaseOrderStatusEnum() {
    return PurchaseOrderStatusEnum;
  }

  get purchaseRequestTypeEnum() {
    return PurchaseRequestTypeEnum;
  }

  get purchaseOrderItemStatusEnum() {
    return PurchaseOrderItemStatusEnum;
  }

  onCloseFullModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  doAfterVersionAlertModal() {}

  onCancelPo() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel PO Number "${this.purchaseOrder.docNo}"?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PurchaseOrderCancelRequest({
              id: this.purchaseOrder.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  onClosePo() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to Close PO Number "${this.purchaseOrder.docNo}"?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, close PO'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PurchaseOrderCloseRequest({
              id: this.purchaseOrder.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  onPrintPdf(purchaseOrder: PurchaseOrder) {
    if (!purchaseOrder) {
      return;
    }
    const param: PurchaseOrderPrintCriteria = {
      id: purchaseOrder.id,
      format: 'pdf',
      locale: 'th'
    };

    this.purchaseOrderService.printPdfOrder(param).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
        saveAs(blob, generatedFilenamePdf(purchaseOrder.docNo));
      },
      error => {
        this.alertErrorModal(error);
      }
    );
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    if (errorResponse.translateKey) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };

      if (errorResponse.code !== '00001') {
        initialState.message = this.translate.instant(errorResponse.translateKey);
        this.modalService.show(AlertModalComponent, {
          initialState
        });
      }
    }
  }

  isCanPrintPDF(status: string) {
    return ['AWAITING_SEND_PO', 'AWAITING_SUPPLIER_RESPONSE', 'AWAITING_GR', 'CLOSED', 'GR_COMPLETED'].includes(
      status
    );
  }
}
