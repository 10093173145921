export enum OrderingMethodEnum {
  FIRST_LOT_ORDER = 'FIRST_LOT_ORDER',
  SPECIAL_REQUEST = 'SPECIAL_REQUEST',
  STORE_REPLENISHMENT = 'STORE_REPLENISHMENT',
  FRESH_LITE = 'FRESH_LITE',
  STORE_USE = 'STORE_USE'
}

export enum OrderTypeEnum {
  DRAFT = 'DRAFT',
  Z9 = 'Z9',
  WAREHOUSE = 'WAREHOUSE',
  SUPPLIER = 'SUPPLIER',
  FIRST_LOT_ORDER = 'FIRST_LOT_ORDER',
  SPECIAL_REQUEST = 'SPECIAL_REQUEST',
  STORE_REPLENISHMENT = 'STORE_REPLENISHMENT',
  FRESH_LITE = 'FRESH_LITE',
  STOCK_TRANSFER = 'STOCK_TRANSFER'
}

export enum OrderSubTypeEnum {
  INVENTORY = 'INVENTORY',
  FIX_ASSET_AND_STORE_USE = 'FIX_ASSET_AND_STORE_USE',
  REPLENISH = 'REPLENISH',
  AD_HOC = 'AD_HOC'
}
