/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./validators-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./validators-message.component";
var styles_ValidatorsMessageComponent = [i0.styles];
var RenderType_ValidatorsMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ValidatorsMessageComponent, data: {} });
export { RenderType_ValidatorsMessageComponent as RenderType_ValidatorsMessageComponent };
function View_ValidatorsMessageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("ERRORS.REQUIRED")); _ck(_v, 1, 0, currVal_0); }); }
function View_ValidatorsMessageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("ERRORS.INVALID_EMAIL")); _ck(_v, 1, 0, currVal_0); }); }
function View_ValidatorsMessageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("ERRORS.NOT_ALLOW", "{ value: 0 }")); _ck(_v, 1, 0, currVal_0); }); }
function View_ValidatorsMessageComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("ERRORS.INVALID_LATITUDE")); _ck(_v, 1, 0, currVal_0); }); }
function View_ValidatorsMessageComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("ERRORS.REQUIRED_AT_LEAST_ONE_DAY_SCHEDULE")); _ck(_v, 1, 0, currVal_0); }); }
function View_ValidatorsMessageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidatorsMessageComponent_4)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidatorsMessageComponent_5)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidatorsMessageComponent_6)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidatorsMessageComponent_7)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentChild.hasError("email"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.contentChild.hasError("isZero"); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.contentChild.hasError("pattern"); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.contentChild.hasError("deliveryDateTo"); _ck(_v, 7, 0, currVal_3); }, null); }
function View_ValidatorsMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "invalid-feedback"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidatorsMessageComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["anyCase", 2]], null, 0, null, View_ValidatorsMessageComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentChild.hasError("required"); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ValidatorsMessageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidatorsMessageComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isSubmitted && _co.contentChild.errors); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ValidatorsMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-validators-message", [], null, null, null, View_ValidatorsMessageComponent_0, RenderType_ValidatorsMessageComponent)), i1.ɵdid(1, 1294336, null, 2, i4.ValidatorsMessageComponent, [i1.Renderer2], null, null), i1.ɵqud(335544320, 1, { contentChild: 0 }), i1.ɵqud(335544320, 2, { elementRef: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ValidatorsMessageComponentNgFactory = i1.ɵccf("app-validators-message", i4.ValidatorsMessageComponent, View_ValidatorsMessageComponent_Host_0, { submitted: "submitted" }, {}, ["*"]);
export { ValidatorsMessageComponentNgFactory as ValidatorsMessageComponentNgFactory };
