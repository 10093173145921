import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { BaseComponent } from '../../base/base.component';
import { ActionReportEnum, ReportItem, ReportSection } from '../../shared/models/report.model';
import { AuthGuardService } from '../../shared/services';
import { AppStates } from '../../shared/store/state/app.states';
import { AwaitingFirstLotModalComponent } from './awaiting-first-lot-modal/awaiting-first-lot-modal.component';
import { BillPaymentModalComponent } from './bill-payment-modal/bill-payment-modal.component';
import { DcAssortmentModalComponent } from './dc-assortment-modal/dc-assortment-modal.component';
import { DeleteBillModalComponent } from './delete-bill-modal/delete-bill-modal.component';
import { DestroyDocumentModalComponent } from './destroy-document-modal/destroy-document-modal.component';
import { DestroyTransactionModalComponent } from './destroy-transaction-modal/destroy-transaction-modal.component';
import { InventoryAdjustmentRequestModalComponent } from './inventory-adjustment-request-modal/inventory-adjustment-request-modal.component';
import { InventoryCountTransactionModalComponent } from './inventory-count-transaction-modal/inventory-count-transaction-modal.component';
import { InventoryCountTransactionTempModalComponent } from './inventory-count-transaction-temp-modal/inventory-count-transaction-temp-modal.component';
import { InventoryVarianceModalComponent } from './inventory-variance-modal/inventory-variance-modal.component';
import { MoneyInModalComponent } from './money-in-modal/money-in-modal.component';
import { MovingAverageModalComponent } from './moving-average-modal/moving-average-modal.component';
import { OrderProcessingHoldModalComponent } from './order-processing-hold-modal/order-processing-hold-modal.component';
import { PromotionCompensateModalComponent } from './promotion-compensate-modal/promotion-compensate-modal.component';
import { ReceiveOrderExceedModalComponent } from './receive-order-exceed-modal/receive-order-exceed-modal.component';
import { RedeemTransactionModalComponent } from './redeem-transaction-modal/redeem-transaction-modal.component';
import { RewardTransactionModalComponent } from './reward-transaction-modal/reward-transaction-modal.component';
import { SalesSummaryModalComponent } from './sales-summary-modal/sales-summary-modal.component';
import { SalesTransactionConfidentialComponent } from './sales-transaction-confidential/sales-transaction-confidential.component';
import { SalesTransactionModalComponent } from './sales-transaction-modal/sales-transaction-modal.component';
import { StockCardModalComponent } from './stock-card-modal/stock-card-modal.component';
import { StoreConfidentialComponent } from './store-confidential/store-confidential.component';
import { SupplierCouponReconcileModalComponent } from './supplier-coupon-reconcile-modal/supplier-coupon-reconcile-modal.component';
import { TdAssortmentConfidentialComponent } from './td-assortment-confidential/td-assortment-confidential.component';
import { ToteAssetLocationModalComponent } from './tote-asset-location-modal/tote-asset-location-modal.component';
import { ToteTransactionDCModalComponent } from './tote-transaction-dc-modal/tote-transaction-dc-modal.component';
import { ToteTransactionFromStoreModalComponent } from './tote-transaction-from-store-modal/tote-transaction-from-store-modal.component';
import { ToteTransactionModalComponent } from './tote-transaction-modal/tote-transaction-modal.component';
import { VoucherModalComponent } from './voucher-modal/voucher-modal.component';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent extends BaseComponent implements OnInit, OnDestroy {
  public hasViewPermission = false;
  public reportSectionList: ReportSection[];
  public reportItemList: ReportItem[];
  protected localStore: Observable<any>;

  @ViewChild('stockCardModal', { static: false }) stockCardModalComponent: StockCardModalComponent;
  @ViewChild('voucherModal', { static: false }) voucherModalComponent: VoucherModalComponent;
  @ViewChild('salesTransactionModal', { static: false }) salesTransactionModal: SalesTransactionModalComponent;
  @ViewChild('deletedBillModal', { static: false }) deletedBillModal: DeleteBillModalComponent;
  @ViewChild('salesSummaryModal', { static: false }) salesSummaryModal: SalesSummaryModalComponent;
  @ViewChild('toteTransactionModal', { static: false }) toteTransactionModal: ToteTransactionModalComponent;
  @ViewChild('inventoryCountTransactionTempModal', { static: false })
  inventoryCountTransactionTempModal: InventoryCountTransactionTempModalComponent;
  @ViewChild('promotionCompensateModal', { static: false })
  promotionCompensateModal: PromotionCompensateModalComponent;
  @ViewChild('movingAverageModal', { static: false }) movingAverageModal: MovingAverageModalComponent;
  @ViewChild('inventoryCountTransactionModal', { static: false })
  inventoryCountTransactionModal: InventoryCountTransactionModalComponent;
  @ViewChild('moneyInModal', { static: false }) moneyInModal: MoneyInModalComponent;
  @ViewChild('inventoryAdjustmentRequestModal', { static: false })
  inventoryAdjustmentRequestModal: InventoryAdjustmentRequestModalComponent;
  @ViewChild('inventoryVarianceModal', { static: false }) inventoryVarianceModal: InventoryVarianceModalComponent;
  @ViewChild('billPaymentModal', { static: false }) billPaymentModal: BillPaymentModalComponent;
  @ViewChild('dcAssortmentModal', { static: false }) dcAssortmentModal: DcAssortmentModalComponent;
  @ViewChild('toteTransactionDCModal', { static: false }) toteTransactionDCModal: ToteTransactionDCModalComponent;
  @ViewChild('toteTransactionFromStoreModal', { static: false })
  toteTransactionFromStoreModal: ToteTransactionFromStoreModalComponent;
  @ViewChild('toteAssetLocationModal', { static: false })
  toteAssetLocationModal: ToteAssetLocationModalComponent;
  @ViewChild('destroyTransactionModal', { static: false }) destroyTransactionModal: DestroyTransactionModalComponent;
  @ViewChild('destroyDocumentModal', { static: false }) destroyDocumentModal: DestroyDocumentModalComponent;
  @ViewChild('supplierCouponReconcileModal', { static: false })
  supplierCouponReconcileModal: SupplierCouponReconcileModalComponent;

  @ViewChild('salesTransactionConfidentialModal', { static: false })
  salesTransactionConfidentialModal: SalesTransactionConfidentialComponent;
  @ViewChild('storeConfidentialModal', { static: false }) storeConfidentialModal: StoreConfidentialComponent;
  @ViewChild('tdAssortmentConfidentialModal', { static: false })
  tdAssortmentConfidentialModal: TdAssortmentConfidentialComponent;
  @ViewChild('receiveOrderExceedModal', { static: false }) receiveOrderExceedModal: ReceiveOrderExceedModalComponent;

  @ViewChild('rewardTransactionModal', { static: false })
  rewardTransactionModal: RewardTransactionModalComponent;
  @ViewChild('redeemTransactionModal', { static: false })
  redeemTransactionModal: RedeemTransactionModalComponent;
  @ViewChild('awaitingFirstLotModal', { static: false })
  awaitingFirstLotModal: AwaitingFirstLotModalComponent;
  @ViewChild('orderProcessingHoldModal', { static: false })
  orderProcessingHoldModal: OrderProcessingHoldModalComponent;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.initReportItem();
  }

  initReportItem() {
    this.reportSectionList = [
      ReportSection.TD_NEST,
      ReportSection.TD_POS,
      ReportSection.TD_EYES,
      ReportSection.OTHERS
    ];

    this.reportItemList = [
      {
        title: 'TD Moving Average',
        section: ReportSection.TD_NEST,
        action: ActionReportEnum.MOVING_AVERAGE,
        permission: ['rep_tdmoving_avg_v']
      },
      {
        title: 'Stock Card',
        section: ReportSection.TD_NEST,
        action: ActionReportEnum.STOCK_CARD,
        permission: ['rep_stockcard_v']
      },
      {
        title: 'DC Assortment',
        section: ReportSection.TD_NEST,
        action: ActionReportEnum.DC_ASSORTMENT,
        permission: ['rep_dcassortment_v']
      },
      {
        title: 'Sales Summary',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.SALES_SUMMARY,
        permission: ['rep_sales_summary_v']
      },
      {
        title: 'Sales Transaction',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.SALES_TRANSACTION,
        permission: ['rep_saletx_v']
      },
      {
        title: 'Voucher',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.VOUCHER_REPORT,
        permission: ['rep_voucher_v']
      },
      {
        title: 'Deleted Bill Items',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.DELETED_BILL,
        permission: ['rep_deletesalesitem_v']
      },
      {
        title: 'Tote Transaction',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.TOTE_TRANSACTION,
        permission: ['rep_totetx_v']
      },
      {
        title: 'Tote Transaction (From DC)',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.TOTE_TRANSACTION_DC,
        permission: ['rep_tote_dc']
      },
      {
        title: 'Tote Transaction (From Store)',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.TOTE_TRANSACTION_FROM_STORE,
        permission: ['rep_tote_store']
      },
      {
        title: 'Tote Asset Location',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.TOTE_ASSET_LOCATION,
        permission: ['rep_tote_asset']
      },
      {
        title: 'Order Processing (Hold)',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.ORDER_PROCESSING_HOLD,
        permission: ['rep_hz8_v']
      },
      {
        title: 'Inventory Count Transaction (with price)',
        section: ReportSection.TD_EYES,
        action: ActionReportEnum.INVENTORY_COUNT_TRANSACTION_TEMP,
        permission: ['rep_inventorycount_tmp_v']
      },
      {
        title: 'Promotion Compensate',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.PROMOTION_COMPENSATE,
        permission: ['rep_promotion_compensate_v']
      },
      {
        title: 'Inventory Count Transaction',
        section: ReportSection.TD_EYES,
        action: ActionReportEnum.INVENTORY_COUNT_TRANSACTION,
        permission: ['rep_inventory_count_v']
      },
      {
        title: 'Money In',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.MONEY_IN,
        permission: ['rep_moneyin_v']
      },
      {
        title: 'Inventory Adjustment Request (with price)',
        section: ReportSection.TD_EYES,
        action: ActionReportEnum.INVENTORY_ADJUSTMENT_REQUEST,
        permission: ['rep_inventory_adj_req_wp_v']
      },
      {
        title: 'Bill Payment',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.BILL_PAYMENT,
        permission: ['rep_bill_payment_v']
      },
      {
        title: 'Inventory Variance',
        section: ReportSection.TD_EYES,
        action: ActionReportEnum.INVENTORY_VARIANCE,
        permission: ['rep_inventory_vr_v']
      },
      {
        title: 'Supplier Coupon Reconcile Report',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.SUPPLIER_COUPON_RECONCILE,
        permission: ['rep_sup_cp_rec_v']
      },
      {
        title: 'Sales Transaction (Confidential)',
        section: ReportSection.OTHERS,
        action: ActionReportEnum.SALES_TRANSACTION_CONFIDENTIAL,
        permission: ['rep_saletxc_v']
      },
      {
        title: 'Store (Confidential)',
        section: ReportSection.OTHERS,
        action: ActionReportEnum.STORE_CONFIDENTIAL,
        permission: ['rep_storec_v']
      },
      {
        title: 'TD Assortment (Confidential)',
        section: ReportSection.OTHERS,
        action: ActionReportEnum.TD_ASSORTMENT_CONFIDENTIAL,
        permission: ['rep_tdassortc_v']
      },
      {
        title: 'Destroy Transaction',
        section: ReportSection.TD_EYES,
        action: ActionReportEnum.DESTROY_TRANSACTION,
        permission: ['rep_dt_tns_v']
      },
      {
        title: 'Destroy Document',
        section: ReportSection.TD_EYES,
        action: ActionReportEnum.DESTROY_DOCUMENT,
        permission: ['rep_dt_v']
      },
      {
        title: 'Reward Transaction',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.REWARD_TRANSACTION,
        permission: ['rep_rewardtx_v']
      },
      {
        title: 'Redeem Transaction',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.REDEEM_TRANSACTION,
        permission: ['rep_redeemtx_v']
      },
      {
        title: 'Receive Order Exceed',
        section: ReportSection.TD_POS,
        action: ActionReportEnum.RECEIVE_ORDER_EXCEED,
        permission: ['rep_roexceed_v']
      },
      {
        title: 'Awaiting First Lot',
        section: ReportSection.TD_NEST,
        action: ActionReportEnum.AWAITING_FIRST_LOT,
        permission: ['rep_awating_firstlot_v']
      }
    ];
  }

  onOpenModal(action: ActionReportEnum) {
    switch (action) {
      case ActionReportEnum.SALES_TRANSACTION:
        this.salesTransactionModal.openExportModal();
        break;
      case ActionReportEnum.STOCK_CARD:
        this.stockCardModalComponent.openExportModal();
        break;
      case ActionReportEnum.VOUCHER_REPORT:
        this.voucherModalComponent.openExportModal();
        break;
      case ActionReportEnum.DELETED_BILL:
        this.deletedBillModal.openExportModal();
        break;
      case ActionReportEnum.SALES_SUMMARY:
        this.salesSummaryModal.openExportModal();
        break;
      case ActionReportEnum.TOTE_TRANSACTION:
        this.toteTransactionModal.openExportModal();
        break;
      case ActionReportEnum.TOTE_TRANSACTION_DC:
        this.toteTransactionDCModal.openExportModal();
        break;
      case ActionReportEnum.TOTE_TRANSACTION_FROM_STORE:
        this.toteTransactionFromStoreModal.openExportModal();
        break;
      case ActionReportEnum.TOTE_ASSET_LOCATION:
        this.toteAssetLocationModal.openExportModal();
        break;
      case ActionReportEnum.INVENTORY_COUNT_TRANSACTION_TEMP:
        this.inventoryCountTransactionTempModal.openExportModal();
        break;
      case ActionReportEnum.PROMOTION_COMPENSATE:
        this.promotionCompensateModal.openExportModal();
        break;
      case ActionReportEnum.MOVING_AVERAGE:
        this.movingAverageModal.openExportModal();
        break;
      case ActionReportEnum.INVENTORY_COUNT_TRANSACTION:
        this.inventoryCountTransactionModal.openExportModal();
        break;
      case ActionReportEnum.MONEY_IN:
        this.moneyInModal.openExportModal();
        break;
      case ActionReportEnum.INVENTORY_ADJUSTMENT_REQUEST:
        this.inventoryAdjustmentRequestModal.openExportModal();
        break;
      case ActionReportEnum.INVENTORY_VARIANCE:
        this.inventoryVarianceModal.openExportModal();
        break;
      case ActionReportEnum.BILL_PAYMENT:
        this.billPaymentModal.openExportModal();
        break;
      case ActionReportEnum.DC_ASSORTMENT:
        this.dcAssortmentModal.openExportModal();
        break;
      case ActionReportEnum.SUPPLIER_COUPON_RECONCILE:
        this.supplierCouponReconcileModal.openExportModal();
        break;
      case ActionReportEnum.SALES_TRANSACTION_CONFIDENTIAL:
        this.salesTransactionConfidentialModal.openExportModal();
        break;
      case ActionReportEnum.STORE_CONFIDENTIAL:
        this.storeConfidentialModal.openExportModal();
        break;
      case ActionReportEnum.TD_ASSORTMENT_CONFIDENTIAL:
        this.tdAssortmentConfidentialModal.openExportModal();
        break;
      case ActionReportEnum.DESTROY_TRANSACTION:
        this.destroyTransactionModal.openExportModal();
        break;
      case ActionReportEnum.DESTROY_DOCUMENT:
        this.destroyDocumentModal.openExportModal();
        break;
      case ActionReportEnum.REWARD_TRANSACTION:
        this.rewardTransactionModal.openExportModal();
        break;
      case ActionReportEnum.REDEEM_TRANSACTION:
        this.redeemTransactionModal.openExportModal();
        break;
      case ActionReportEnum.RECEIVE_ORDER_EXCEED:
        this.receiveOrderExceedModal.openExportModal();
        break;
      case ActionReportEnum.AWAITING_FIRST_LOT:
        this.awaitingFirstLotModal.openExportModal();
        break;
      case ActionReportEnum.ORDER_PROCESSING_HOLD:
        this.orderProcessingHoldModal.openExportModal();
        break;
      default:
        break;
    }
  }

  getReportBySection(group: ReportSection) {
    return this.reportItemList.filter(item => item.section === group);
  }

  hasPermission(item: ReportItem) {
    return this.authGuardService.checkPermission(item.permission);
  }

  doAfterVersionAlertModal() {}

  doAfterSuccessModal() {}

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
