import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { StoreType } from '../../../shared/enum/store.enum';
import { GraphqlQueryObject } from '../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ErrorResponse } from '../../../shared/models';
import { AuditLog } from '../../../shared/models/audit-log.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { OrderContent, OrderItem, OrderPrintCriteria, OrderTabs } from '../../../shared/models/order.model';
import { MasterService } from '../../../shared/services/master.service';
import { OrderService } from '../../../shared/services/order.service';
import { OrderViewLoaded, OrderViewRequested } from '../../../shared/store/actions/order.actions';
import { selectViewOrder } from '../../../shared/store/selectors/order.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { VatDetailsModalComponent } from '../../purchase/vat-details-modal/vat-details-modal.component';
import { StoreInfoComponent } from '../order-components/store-info/store-info.component';

enum OrderColorStatusEnum {
  NEW_ORDER = 'new',
  PROCESSING = 'processing',
  PARTIAL_DELIVERED = 'partial',
  DELIVERED = 'deliver',
  CANCELED = 'cancel',
  CANCELED_BY_SUPPLIER = 'cancel',
  REJECTED_BY_ERP = 'cancel',
  CLOSED = 'closed',
  AWAITING_ALLOCATION = 'awaiting-allocation'
}

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: { orderNo: string; title: string; auditLogs: AuditLog[] };

  private localStore: Observable<any>;

  public dateFormat = environment.dateFormat;
  public dateTimeDisplay = environment.dateTimeDisplay;
  public viewOrder$: Observable<OrderContent>;
  public productDetails: Array<OrderItem>;

  public orderTabs = OrderTabs;
  public selectedTab: OrderTabs;
  public vatPct$: Observable<number>;

  constructor(
    protected readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    protected orderService: OrderService,
    protected readonly modalService: BsModalService,
    protected masterService: MasterService
  ) {
    super(store, modalService);
  }

  ngOnInit() {
    const countryQuery = new GraphqlQueryObject();

    countryQuery.name = MasterDataEnum.COUNTRY;
    countryQuery.fields = ['id', 'code', 'nameEn', 'vatPct'];
    this.vatPct$ = this.masterService
      .getMasterDataByNames([countryQuery])
      .pipe(map(result => result.data.countries.find(data => data.nameEn === environment.defaultCountry).vatPct));

    this.selectedTab = OrderTabs.ORDER_DETAILS;
    this.store.dispatch(new OrderViewRequested(this.data.orderNo));
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.viewOrder$ = this.localStore.pipe(
      select(selectViewOrder),
      filter(data => Boolean(data && data.items))
    );
    this.viewOrder$.subscribe(data => {
      this.productDetails = data.items;
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new OrderViewLoaded(null));
  }

  getColorStatus(status: string) {
    return OrderColorStatusEnum[status];
  }

  onSelectTabs(tab: OrderTabs) {
    this.selectedTab = tab;
  }

  onPrintPdf(order: OrderContent) {
    if (!order) {
      return;
    }
    const param: OrderPrintCriteria = {
      id: order.id,
      format: 'pdf',
      locale: 'th'
    };

    this.orderService.printPdfOrder(param).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
        saveAs(blob, this.getGeneratedFileName(order.orderNo));
      },
      error => {
        this.alertErrorModal(error.error);
      }
    );
  }

  getGeneratedFileName(orderNo: string): string {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.downloadPdf.timeFormat);
    const formattedName = orderNo ? orderNo.replace('/', '_') : '';
    return `${formattedName}_${formattedDate}`;
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    if (errorResponse.translateKey) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };

      if (errorResponse.code !== '00001') {
        initialState.message = this.translate.instant(errorResponse.translateKey);
        this.modalService.show(AlertModalComponent, {
          initialState
        });
      }
    }
  }

  showVatDetails() {
    const vatDetails = [];

    this.productDetails.forEach((item, i) => {
      const data = {
        itemNo: i + 1,
        productName: item.productName,
        vatAmount: item.vatAmount && item.vatAmount.amount,
        vat: 0,
        isInvalid: false
      };

      if (item.vat) {
        this.vatPct$.subscribe(vatPct => (data.vat = vatPct));
      }

      vatDetails.push(data);
    });

    this.modalService.show(VatDetailsModalComponent, {
      initialState: {
        title: 'VAT',
        vatDetails
      }
    });
  }

  isCanPrintPDF(status: string) {
    return ['NEW_ORDER', 'PROCESSING', 'PARTIAL_DELIVERED', 'CLOSED', 'DELIVERED'].includes(status);
  }

  onClose() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  doAfterVersionAlertModal() {}

  isSelectedTab(tab: OrderTabs) {
    return this.selectedTab === tab;
  }

  getStoreIcon(storeType: StoreType) {
    return storeType === StoreType.STORE_MODEL ? 'icon-storemodel' : 'icon-partnermodel';
  }

  showHistory() {
    const initialState = {
      title: 'History',
      historyHeader: `Order No.: ${this.data.orderNo}`,
      auditLogs: this.data.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  showStoreInfo(order: OrderContent) {
    const initialState = {
      title: 'Store Information',
      historyHeader: `Store: ${order.customerDetail.storeCode}/${order.customerDetail.storeName}`,
      customerDetail: order.customerDetail
    };
    this.modalService.show(StoreInfoComponent, {
      initialState
    });
  }
}
