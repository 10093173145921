import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { BaseSearchComponent } from '../../../../base/base-search.component';
import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { OrderingMethodEnum } from '../../../../shared/enum/ordering-method.enum';
import { ProductTypeEnum } from '../../../../shared/enum/product-type.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ProductStatusEnum } from '../../../../shared/models';
import { ButtonType, ImportExportButton } from '../../../../shared/models/import-export-button.model';
import { NotificationEmit } from '../../../../shared/models/notification-emit.model';
import { OrderSelectItemSearchCriteria, ProductOrderItem } from '../../../../shared/models/order-request.model';
import { OrderSelectiveItemExportService } from '../../../../shared/services/order-selective-item-export.service';
import { SelectiveSelectItemListRequest } from '../../../../shared/store/actions/order-select-item.actions';
import { OrderSelectiveSelectItemOrderAddItem } from '../../../../shared/store/actions/order-selective-select-item.actions';
import { OrderSelectiveSelectItemState } from '../../../../shared/store/reducers/order-selective-select-item.reducers';
import {
  getCountOrderSelectiveSelectItemOrder,
  getOrderSelectiveSelectItemOrderById
} from '../../../../shared/store/selectors/order-selective-select-item-order.selector';
import {
  selectAllOrderSelectiveSelectItemList,
  selectOrderSelectiveSelectItemList
} from '../../../../shared/store/selectors/order-selective-select-item.selector';
import { AppStates } from '../../../../shared/store/state/app.states';
import { getPriceFormat, ItemPrice } from '../../../../shared/utils/calculate-price-util';
@Component({
  selector: 'app-select-selective-item-modal',
  templateUrl: './select-selective-item-modal.component.html',
  styleUrls: ['./select-selective-item-modal.component.scss']
})
export class SelectSelectiveItemModalComponent extends BaseSearchComponent<
  OrderSelectItemSearchCriteria,
  ProductOrderItem,
  OrderSelectiveSelectItemState
> {
  @Input() data: { title: string; orderType: OrderingMethodEnum; storeNo?: string; mode?: OrderingMethodEnum };
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  public totalOrder$: Observable<number>;
  private localStore: Observable<any>;
  public buttons: Array<ImportExportButton>;
  public errorExport: string;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    private readonly orderSelectiveItemExportService: OrderSelectiveItemExportService,
    private readonly translate: TranslateService,
    protected fb: FormBuilder
  ) {
    super(store, modalService, selectAllOrderSelectiveSelectItemList, selectOrderSelectiveSelectItemList);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.totalOrder$ = this.store.pipe(select(getCountOrderSelectiveSelectItemOrder));
    this.getButton();
  }

  getButton() {
    this.buttons = [
      {
        type: ButtonType.EXPORT,
        name: 'Export'
      }
    ];
  }

  doAfterVersionAlertModal() {}

  doDestroy() {}

  onBack() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });
  }

  goToView() {}

  onAdvanceSubmit() {}

  prepareSearchCriteriaTags() {}

  search(criteriaObj) {
    this.store.dispatch(new SelectiveSelectItemListRequest(criteriaObj));
  }

  setInitialCriteriaObject() {
    const config: any = {
      searchCriteria: null,
      productType: ProductTypeEnum.INVENTORY,
      orderingMethods: [
        OrderingMethodEnum.FIRST_LOT_ORDER,
        OrderingMethodEnum.SPECIAL_REQUEST,
        OrderingMethodEnum.STORE_REPLENISHMENT
      ].toString(),
      page: 0,
      size: 20
    };

    this.criteriaObject = config;
  }

  setRouteTab() {}

  addItem(productOrderItem: ProductOrderItem, qty: number) {
    productOrderItem.isAddItem = true;
    if (qty) {
      let previousQty = 0;
      const selectPreviousQty$ = this.localStore
        .pipe(select(getOrderSelectiveSelectItemOrderById(productOrderItem.articleNo)))
        .pipe(filter(value => Boolean(value)))
        .subscribe(value => {
          previousQty = value.qty;
        });

      productOrderItem.qty = Number(previousQty) + Number(qty);

      const itemPrice = new ItemPrice<ProductOrderItem>(productOrderItem, 'qty');
      const amountExcVat = itemPrice.getTotalFrom('wholesalePriceExcVat');
      const vatAmount = itemPrice.getTotalFrom('vatAmount');
      const amount = getPriceFormat(amountExcVat.amount + vatAmount.amount);

      productOrderItem.amountExcVat = amountExcVat;
      productOrderItem.totalVat = vatAmount;
      productOrderItem.amount = amount;

      // If allowToDelete is undefinded, set to true
      if (typeof productOrderItem.allowToDelete === 'undefined') {
        productOrderItem.allowToDelete = true;
      }

      this.store.dispatch(new OrderSelectiveSelectItemOrderAddItem(productOrderItem));
      selectPreviousQty$.unsubscribe();
    }
  }

  setInitialValue() {
    this.pageSize = 20;
  }

  onExportItem() {
    const selected = {
      productType: ProductTypeEnum.INVENTORY,
      orderingMethods: [
        OrderingMethodEnum.FIRST_LOT_ORDER,
        OrderingMethodEnum.SPECIAL_REQUEST,
        OrderingMethodEnum.STORE_REPLENISHMENT
      ].toString(),
      excStatus: [
        ProductStatusEnum.SELECT,
        ProductStatusEnum.DELETE,
        ProductStatusEnum.RECALL,
        ProductStatusEnum.HOLD_BUY_SELL
      ].toString(),
      sortBy: ['productName.sort', 'articleNo.sort'].toString(),
      sortOrder: 'asc'
    };
    this.callServiceExportItem(selected, this.exportFileName());
  }

  exportFileName(): string {
    return `Selective Item (Inventory) ${this.timeToExport}.xlsx`;
  }

  public callServiceExportItem(selected: any, filename: any) {
    this.orderSelectiveItemExportService.exportItemSelective(selected).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, filename);
      },
      errorResponse => {
        this.errorExport = errorResponse.error.message || this.translate.instant(errorResponse.error.translateKey);
        this.showModalError(this.errorExport);
      }
    );
  }

  showModalError(message: string) {
    this.showModalMessage('Failed', message);
  }

  showModalMessage(title: string, message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title,
        message
      }
    });
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportOrderRequest.timeFormat);
  }
}
