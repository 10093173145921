import { Action } from '@ngrx/store';
import { StockProductListResponse, StockProductSearchCriteria } from '../../models/stock-transfer-request.model';

export enum StockProductActionTypes {
  STOCK_PRODUCT_LIST_REQUEST = '[Stock Product] Stock Product List Request',
  STOCK_PRODUCT_LIST_RESPONSE = '[Stock Product] Stock Product List Response',
  STOCK_PRODUCT_LIST_RESET = '[Stock Product] Stock Product List Reset'
}

export class StockProductListRequestAction implements Action {
  readonly type = StockProductActionTypes.STOCK_PRODUCT_LIST_REQUEST;

  constructor(public payload: StockProductSearchCriteria) {}
}

export class StockProductListResponseAction implements Action {
  readonly type = StockProductActionTypes.STOCK_PRODUCT_LIST_RESPONSE;

  constructor(public payload: StockProductListResponse) {}
}

export class StockProductListResetAction implements Action {
  readonly type = StockProductActionTypes.STOCK_PRODUCT_LIST_RESET;

  constructor() {}
}

export type StockProductActions =
  | StockProductListRequestAction
  | StockProductListResponseAction
  | StockProductListResetAction;
