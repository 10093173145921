export enum PurchaseConditionRequestStatusEnum {
  DRAFT = 'DRAFT',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED'
}

export enum PurchaseConditionPageModes {
  CREATE = 'CREATE',
  VIEW_REQUEST = 'VIEW_REQUEST',
  EDIT_REQUEST = 'EDIT_REQUEST',
  VIEW = 'VIEW',
  EDIT = 'EDIT'
}

export enum SupplierInformationStatusEnum {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export enum PurchaseConditionPageTab {
  SUPPLIER_INFORMATION = 'Supplier Information',
  SUPPLIER_CONDITION = 'Supplier Condition',
  ITEM_CONDITION = 'Item Condition',
  ORDER_DELIVERY_SCHEDULE = 'Order/Delivery Schedule'
}

export enum OrderDeliveryScheduleType {
  WEEKLY_MANUAL = 'WEEKLY_MANUAL',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export enum PurchaseConditionScheduleSettingTitleEnum {
  WEEKLY_MANUAL = 'Set by Weekly, All Products (only)',
  WEEKLY = 'Set by Weekly, All / By Product',
  MONTHLY = 'Set by Calendar, All / By Product'
}

export enum PurchaseConditionOrderDeliveryScheduleImportStatusEnum {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

export enum PurchaseConditionProductLevelEnum {
  ALL_PRODUCTS = 'All Products',
  BY_PRODUCT = 'By Product'
}

export enum InformationTypeEnum {
  CONTACT = 'CONTACT',
  DC = 'DC'
}

export enum InformationActionEnum {
  ADD = 'ADD',
  EDIT = 'EDIT'
}

export enum PurchaseConditionTitleMode {
  CREATE = 'Create Purchase Condition Request',
  VIEW_REQUEST = 'View Purchase Condition Request',
  EDIT_REQUEST = 'Edit Purchase Condition  Request',
  VIEW = 'View Purchase Condition',
  EDIT = 'Edit Purchase Condition'
}
