import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { StoreConsultantService } from '../../services/store-consultant.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import { StoreConsultantActionTypes, StoreConsultantGetByIdResponseAction, StoreConsultantListResponseAction } from '../actions/store-consultant.actions';
export class StoreConsultantEffects {
    constructor(actions$, storeConsultantService, logger) {
        this.actions$ = actions$;
        this.storeConsultantService = storeConsultantService;
        this.logger = logger;
        this.searchStoreRequest$ = this.actions$.pipe(ofType(StoreConsultantActionTypes.STORE_CONSULTANT_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreConsultantActionTypes.STORE_CONSULTANT_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeConsultantService.searchStoreConsultantByCriteria(payload).pipe(map(response => {
                return new StoreConsultantListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getStoreConsultantByUserId$ = this.actions$.pipe(ofType(StoreConsultantActionTypes.STORE_CONSULTANT_GET_BY_ID_REQUEST), tap(action => this.logger.debug(`@Effect ${StoreConsultantActionTypes.STORE_CONSULTANT_LIST_REQUEST}: ` + this.stringify(action.payload))), mergeMap(action => this.storeConsultantService.getStoreConsultantByUserId(action.payload.userId).pipe(map(result => {
            return new StoreConsultantGetByIdResponseAction(result);
        }), catchError(err => of(new LayoutActionLoadError(err))))));
        this.submitStore$ = this.actions$.pipe(ofType(StoreConsultantActionTypes.STORE_CONSULTANT_SUBMIT_REQUEST), tap(action => this.logger.debug(`@Effect ${StoreConsultantActionTypes.STORE_CONSULTANT_SUBMIT_REQUEST}: ` + this.stringify(action.payload))), switchMap(action => {
            return this.storeConsultantService.update(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'Store Consultant has been updated.'
            })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreConsultantEffects.prototype, "searchStoreRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreConsultantEffects.prototype, "getStoreConsultantByUserId$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreConsultantEffects.prototype, "submitStore$", void 0);
