import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { filter, map, take } from 'rxjs/operators';
import { environment } from '../../../../environments/base.environment';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { NewMasterData } from '../../../shared/gql/common.gql';
import { StoreList } from '../../../shared/models';
import { DestroyTransactionReportCriteria } from '../../../shared/models/report.model';
import { DestroyService } from '../../../shared/services/destroy.service';
import { MasterService } from '../../../shared/services/master.service';
import { MerchantService } from '../../../shared/services/merchant.service';
import { AppStates } from '../../../shared/store/state/app.states';
import { dateToStringCriteria } from '../../../shared/utils/date-util';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-destroy-transaction-modal',
  templateUrl: './destroy-transaction-modal.component.html',
  styleUrls: ['./destroy-transaction-modal.component.scss']
})
export class DestroyTransactionModalComponent extends BaseReportModalComponent implements OnInit {
  @ViewChild('destroyTransactionModal', { static: false }) destroyTransactionModal: ModalDirective;
  public exportForm: FormGroup;
  public submitted: boolean;
  public exportFormInValid: boolean;
  public responseExportError: string;

  public maxDate: Date;
  public minDate: Date;
  public storeTypeList: Array<NewMasterData> | null;
  public storeList: StoreList[] = [];
  private readonly MAX_STORE = 100;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly formBuilder: FormBuilder,
    protected readonly translate: TranslateService,
    protected readonly merchantService: MerchantService,
    protected readonly destroyService: DestroyService,
    private readonly masterService: MasterService
  ) {
    super();
  }

  ngOnInit() {
    this.exportFormInValid = true;
    this.responseExportError = null;

    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.initControl();
    this.loadStore();
  }

  initControl() {
    this.exportForm = this.formBuilder.group({
      createdDateFrom: [null],
      createdDateTo: [null],
      storeType: [null],
      no: [null]
    });

    this.masterService
      .getMasterDataByNames([MasterDataEnum.MERCHANT])
      .pipe(
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.storeTypeList = result[MasterDataEnum.MERCHANT];
      });
  }

  loadStore() {
    this.merchantService
      .searchStoreByCriteria({
        sortBy: 'code',
        sortOrder: 'asc',
        page: 0,
        size: 10000
      })
      .pipe(
        filter(data => Boolean(data)),
        take(1)
      )
      .subscribe(data => {
        this.storeList = data.content;
      });
  }

  openExportModal() {
    this.exportForm.reset();
    this.responseExportError = null;
    this.exportFormInValid = true;
    this.destroyTransactionModal.show();
  }

  closeExportModal() {
    this.exportForm.reset();
    this.destroyTransactionModal.hide();
  }

  onExport() {
    this.submitted = true;
    if (this.exportFormInValid) {
      return;
    }

    if (this.exportForm.get('no').value && this.exportForm.get('no').value.length > this.MAX_STORE) {
      this.responseExportError = `Export limit exceeded (up to ${this.MAX_STORE} stores)`;
      return;
    }

    const rawData = this.exportForm.getRawValue();
    const destroyTransactionReportCriteria = new DestroyTransactionReportCriteria({
      createdDateFrom: dateToStringCriteria(rawData.createdDateFrom, true),
      createdDateTo: dateToStringCriteria(rawData.createdDateTo, false),
      storeType: rawData.storeType && rawData.storeType.length > 0 ? rawData.storeType.toString() : rawData.storeType,
      storeNo: rawData.no && rawData.no.length > 0 ? rawData.no.toString() : rawData.no
    });

    this.destroyService.exportDestroyTransaction(destroyTransactionReportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportDestroyTransaction.prefix} ${this.timeToExport}.xlsx`);
      },
      error => {
        this.responseExportError = error.error.message || this.translate.instant(error.error.translateKey);
      },
      () => {
        this.submitted = false;
        this.closeExportModal();
      }
    );
  }

  onChangeExportCreatedDateFrom(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeExportCreatedDateTo(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = new Date();
    }
  }

  get form() {
    return this.exportForm.controls;
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportDestroyTransaction.timeFormat);
  }
}
