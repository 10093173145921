import { Action } from '@ngrx/store';
import { ErrorResponse, ShelfItems } from '../../models';
import {
  OrderInventoryCreateRequest,
  OrderInventoryRequestViewResponse
} from '../../models/order-request-inventory.model';
import { ApproveOrderRequest, DeliveryDetails, OrderId } from '../../models/order-request.model';
import { ShelfInventoryListSearchCriteria } from '../../models/shelf-inventory.model';

export enum OrderInventoryRequestActionType {
  ORDER_INVENTORY_REQUEST_GET_BY_STORE_RESPONSE = '[OrderInventoryRequest] Get Order by Store Response',
  ORDER_INVENTORY_REQUEST_GET_BY_ID_REQUEST = '[OrderInventoryRequest] Get Order by Id Request',
  ORDER_INVENTORY_REQUEST_GET_BY_ID_RESPONSE = '[OrderInventoryRequest] Get Order by Id Response',
  ORDER_INVENTORY_REQUEST_GET_BY_ID_RESET = '[OrderInventoryRequest] Get Order by Id Reset',
  ORDER_INVENTORY_CREATE_SAVE_REQUEST = '[OrderInventoryRequest] Create Order Save Request',
  ORDER_INVENTORY_CREATE_SAVE_RESPONSE = '[OrderInventoryRequest] Create Order Save Response',
  ORDER_INVENTORY_CREATE_SUBMIT_REQUEST = '[OrderInventoryRequest] Create Order Submit Request',
  ORDER_INVENTORY_CREATE_SUBMIT_RESPONSE = '[OrderInventoryRequest] Create Order Submit Response',
  ORDER_INVENTORY_CREATE_SUBMIT_ERROR = '[OrderInventoryRequest] Create Order Submit Error',
  ORDER_INVENTORY_CREATE_RESET = '[OrderInventoryRequest] Create Order Reset',
  ORDER_INVENTORY_REQUEST_APPROVE_REQUEST = '[OrderInventoryRequest] Request Approve Order Request',
  ORDER_INVENTORY_REQUEST_APPROVE_RESPONSE = '[OrderInventoryRequest] Request Approve Order Response',
  ORDER_INVENTORY_REQUEST_SHELF_SELECTION_REQUEST = '[OrderInventoryRequest] Shelf selection Request',
  ORDER_INVENTORY_REQUEST_SHELF_SELECTION_RESPONSE = '[OrderInventoryRequest] Shelf selection Response',
  ORDER_INVENTORY_REQUEST_SHELF_SELECTION_RESET = '[OrderInventoryRequest] Shelf selection Reset',
  ORDER_INVENTORY_REQUEST_GENERATE_SHELF_ITEM_REQUEST = '[OrderInventoryRequest] Generate Shelf items Request',
  ORDER_INVENTORY_REQUEST_SAVE_DELIVERY_DETAILS = '[OrderInventoryRequest] Save Delivery Details'
}

export class OrderInventoryRequestByStoreResponseAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GET_BY_STORE_RESPONSE;

  constructor(public payload: any) {}
}

export class OrderInventoryRequestByIdRequestAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: OrderId) {}
}

export class OrderInventoryRequestByIdResponseAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: OrderInventoryRequestViewResponse) {}
}

export class ResetOrderInventoryRequestSelected implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GET_BY_ID_RESET;
}

export class OrderInventoryCreateSaveRequestAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SAVE_REQUEST;

  constructor(public payload: OrderInventoryCreateRequest) {}
}

export class OrderInventoryCreateSaveResponseAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SAVE_RESPONSE;

  constructor(public payload: OrderInventoryRequestViewResponse) {}
}

export class OrderInventoryCreateSubmitRequestAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SUBMIT_REQUEST;

  constructor(public payload: OrderInventoryCreateRequest) {}
}

export class OrderInventoryCreateSubmitResponseAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SUBMIT_RESPONSE;

  constructor(public payload: OrderInventoryRequestViewResponse) {}
}

export class OrderInventoryCreateSubmitResponseErrorAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_SUBMIT_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class OrderInventoryCreateResetAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_CREATE_RESET;
}

export class OrderInventoryApproveRequestAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_APPROVE_REQUEST;

  constructor(public payload: ApproveOrderRequest) {}
}

export class OrderInventoryApproveResponseAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_APPROVE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class OrderInventoryRequestShelfSelectionRequestAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_SHELF_SELECTION_REQUEST;

  constructor(public payload: ShelfInventoryListSearchCriteria) {}
}

export class OrderInventoryRequestShelfSelectionResponseAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_SHELF_SELECTION_RESPONSE;

  constructor(public payload: ShelfItems[]) {}
}

export class OrderInventoryRequestShelfSelectionResetAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_SHELF_SELECTION_RESET;
}

export class OrderInventoryRequestGenerateShelfItemsRequestAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_GENERATE_SHELF_ITEM_REQUEST;

  constructor(public payload: string) {}
}

export class OrderInventoryRequestSaveDeliveryDetailsAction implements Action {
  readonly type = OrderInventoryRequestActionType.ORDER_INVENTORY_REQUEST_SAVE_DELIVERY_DETAILS;

  constructor(public payload: DeliveryDetails) {}
}

export type OrderInventoryRequestActions =
  | OrderInventoryRequestByStoreResponseAction
  | OrderInventoryRequestByIdRequestAction
  | OrderInventoryRequestByIdResponseAction
  | ResetOrderInventoryRequestSelected
  | OrderInventoryCreateSaveRequestAction
  | OrderInventoryCreateSaveResponseAction
  | OrderInventoryCreateSubmitRequestAction
  | OrderInventoryCreateSubmitResponseAction
  | OrderInventoryCreateSubmitResponseErrorAction
  | OrderInventoryCreateResetAction
  | OrderInventoryApproveRequestAction
  | OrderInventoryApproveResponseAction
  | OrderInventoryRequestShelfSelectionRequestAction
  | OrderInventoryRequestShelfSelectionResponseAction
  | OrderInventoryRequestShelfSelectionResetAction
  | OrderInventoryRequestGenerateShelfItemsRequestAction
  | OrderInventoryRequestSaveDeliveryDetailsAction;
