import { createSelector } from '@ngrx/store';
import * as fromShelfInventoryRequestResponseState from '../reducers/shelf-inventory-request.reducers';
import { AppStates } from '../state/app.states';

const selectShelfInventoryRequestState = (state: AppStates) => state.shelfInventoryRequest;

export const selectAllShelfInventoryRequestList = createSelector(
  selectShelfInventoryRequestState,
  fromShelfInventoryRequestResponseState.selectAll
);

export const selectShelfInventoryRequestList = createSelector(
  selectShelfInventoryRequestState,
  (state: fromShelfInventoryRequestResponseState.ShelfInventoryRequestState) => state
);

export const selectShelfInventoryRequestListCriteria = createSelector(selectShelfInventoryRequestState, state => {
  return state.criteriaObject;
});

export const selectShelfInventoryRequestById = createSelector(
  selectShelfInventoryRequestState,
  (state: fromShelfInventoryRequestResponseState.ShelfInventoryRequestState) => state.selected
);

export const selectShelfInventoryRequestHistory = createSelector(
  selectShelfInventoryRequestState,
  (state: fromShelfInventoryRequestResponseState.ShelfInventoryRequestState) => state.auditLogs
);

export const selectShelfInventoryDeleteStatus = createSelector(
  selectShelfInventoryRequestState,
  (state: fromShelfInventoryRequestResponseState.ShelfInventoryRequestState) => {
    return state.isDeleteSuccess;
  }
);
