<div class="popover-container" [formGroup]="supplierInformationForm" *ngIf="supplierInformationForm">
  <div class="row section-header">
    <span>Supplier Information</span>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <label for="supplierName"> Supplier Name<span class="text-danger">*</span> </label>
      <input
        type="text"
        class="form-control"
        id="supplierName"
        placeholder="Supplier Name"
        trim="blur"
        formControlName="supplierName"
        appAlphanumericOnly
      />
    </div>

    <div class="col-md-3">
      <label for="taxId"> Tax ID<span class="text-danger">*</span> </label>
      <input
        type="text"
        class="form-control"
        maxlength="25"
        id="taxId"
        placeholder="Tax ID"
        trim="blur"
        formControlName="taxCode"
      />
    </div>

    <div class="col-md-3">
      <label for="branchNo">Branch No.</label>
      <input
        type="text"
        class="form-control"
        maxlength="25"
        id="branchNo"
        placeholder="Branch No"
        trim="blur"
        formControlName="branchNo"
      />
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <label for="address"> Address<span class="text-danger">*</span> </label>
      <input
        type="text"
        class="form-control"
        id="address"
        placeholder="Address"
        trim="blur"
        formControlName="address"
        appAlphanumericOnly
      />
    </div>

    <div class="col-md-3">
      <label for="city"> City<span class="text-danger">*</span> </label>
      <input
        type="text"
        class="form-control"
        maxlength="25"
        id="city"
        placeholder="City"
        trim="blur"
        formControlName="city"
      />
    </div>

    <div class="col-md-3">
      <label for="province">State/Province<span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        maxlength="25"
        id="province"
        placeholder="State/Province"
        trim="blur"
        formControlName="province"
      />
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-3">
      <label for="postCode">PostCode<span class="text-danger">*</span> </label>
      <input
        type="text"
        class="form-control"
        maxlength="25"
        id="postCode"
        placeholder="PostCode"
        trim="blur"
        formControlName="postalCode"
      />
    </div>

    <div class="col-md-3">
      <label for="country"> Country<span class="text-danger">*</span> </label>
      <input
        type="text"
        class="form-control"
        maxlength="25"
        id="country"
        placeholder="Country"
        trim="blur"
        formControlName="country"
      />
    </div>

    <div class="col-md-3">
      <label for="contactName">Contact Name</label>
      <input
        type="text"
        class="form-control"
        maxlength="25"
        id="contactName"
        placeholder="Contact Name"
        trim="blur"
        formControlName="contactName"
      />
    </div>

    <div class="col-md-3">
      <label for="branchNo">Contact Number</label>
      <input
        type="text"
        class="form-control"
        maxlength="25"
        id="contactNumber"
        placeholder="Contact Number"
        trim="blur"
        formControlName="contactNumber"
      />
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-3">
      <label for="paymentTerm">Payment Term</label>
      <input
        type="text"
        class="form-control"
        maxlength="25"
        id="paymentTerm"
        placeholder="Payment Term"
        trim="blur"
        formControlName="paymentTerm"
      />
    </div>

    <div class="col-md-3">
      <label for="vat">VAT</label>
      <ng-select
        id="vat"
        placeholder="Please select..."
        [items]="vatList"
        [searchable]="false"
        [clearable]="false"
        bindLabel="label"
        bindValue="value"
        formControlName="supplierVat"
      >
      </ng-select>
    </div>

    <div class="col-md-3">
      <label for="supplierStatus">Supplier Status</label>
      <input
        type="text"
        class="form-control"
        maxlength="25"
        id="supplierStatus"
        placeholder="Supplier Status"
        trim="blur"
        formControlName="supplierStatus"
      />
    </div>
  </div>

  <div
    *ngIf="
      !(
        [requestTypeEnum.EDIT].includes(requestType) &&
        [purchaseConditionPageMode.VIEW_REQUEST, purchaseConditionPageMode.EDIT_REQUEST].includes(mode)
      )
    "
  >
    <hr class="section-divider" />
    <!--End Supplier Information-->
    <div [formGroup]="purchaseConditionForm" *ngIf="purchaseConditionForm">
      <div class="row section-header">
        <span>Contact Information</span>
      </div>
      <div
        class="mt-2 mb-3"
        *ngIf="
          [purchaseConditionPageMode.VIEW, purchaseConditionPageMode.EDIT].includes(mode) &&
          purchaseConditionPermission.showButton &&
          purchaseConditionPermission.hasManagePurchaseCondition
        "
      >
        <button type="button" class="btn btn-standard" (click)="onClickAddInformation(informationType.CONTACT)">
          <em class="icon-plus"></em>Add Contact
        </button>
      </div>
      <div class="table-responsive">
        <table
          *ngIf="contactInformationItems.controls.length"
          id="contact-information-table"
          class="table table-striped table-bordered table-hover w-100"
          aria-hidden="true"
        >
          <thead>
            <tr>
              <th class="text-center w-5">No.</th>
              <th class="text-center w-10">Contact Name</th>
              <th class="text-center w-10">Contact Number</th>
              <th class="text-center w-10">Remark</th>
              <th
                class="text-center w-5"
                *ngIf="
                  ![purchaseConditionPageMode.VIEW_REQUEST].includes(mode) &&
                  purchaseConditionPermission.showButton &&
                  purchaseConditionPermission.hasManagePurchaseCondition
                "
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody formArrayName="contactInformation">
            <tr *ngFor="let item of contactInformationItems.controls; index as i" [formGroupName]="i">
              <td class="text-center">
                {{ i + 1 }}
              </td>
              <td class="text-left">
                {{ item.get('name').value }}
              </td>
              <td class="text-left">
                {{ item.get('detail').value }}
              </td>
              <td class="text-left">
                {{ item.get('remark').value }}
              </td>
              <td
                class="text-center"
                *ngIf="
                  ![purchaseConditionPageMode.VIEW_REQUEST].includes(mode) &&
                  purchaseConditionPermission.showButton &&
                  purchaseConditionPermission.hasManagePurchaseCondition
                "
              >
                <a
                  href="javascript:void(0)"
                  class="btn-more "
                  [popover]="moreTemplate"
                  placement="bottom"
                  containerClass="fl"
                  [outsideClick]="true"
                  container=".popover-container"
                  [adaptivePosition]="false"
                >
                  <em class="icon-more"></em>
                </a>
                <ng-template #moreTemplate>
                  <button
                    type="button"
                    class="btn btn-link"
                    (click)="onClickEditInformation(informationType.CONTACT, item, i)"
                  >
                    <em class="icon-edit"></em>Edit
                  </button>
                  <button
                    type="button"
                    (click)="alertModalConfirmDelete(informationType.CONTACT, i, item)"
                    class="btn btn-link"
                  >
                    <em class="icon-delete"></em>Delete
                  </button>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>

        <a
          class="btn-text-link d-inline-block ml-1 "
          (click)="onClickAddInformation(informationType.CONTACT)"
          *ngIf="
            ![
              purchaseConditionPageMode.VIEW_REQUEST,
              purchaseConditionPageMode.VIEW,
              purchaseConditionPageMode.EDIT
            ].includes(mode)
          "
          >+Add Contact</a
        >
      </div>
      <hr class="section-divider" />
      <!--End Table Contact Information-->

      <div class="row section-header">
        <span>DC Information</span>
      </div>
      <div
        class="mt-2 mb-3 dc"
        *ngIf="
          [purchaseConditionPageMode.VIEW, purchaseConditionPageMode.EDIT].includes(mode) &&
          purchaseConditionPermission.showButton &&
          purchaseConditionPermission.hasManagePurchaseCondition
        "
      >
        <button type="button" class="btn btn-standard text-left" (click)="onClickAddInformation(informationType.DC)">
          <em class="icon-plus"></em>Add DC
        </button>
      </div>
      <div class="table-responsive">
        <table
          *ngIf="dcInformationItems.controls.length"
          id="dc-information-table"
          class="table table-striped table-bordered table-hover w-100"
          aria-hidden="true"
        >
          <thead>
            <tr>
              <th class="text-center w-5">No.</th>
              <th class="text-center w-10">DC Name</th>
              <th class="text-center w-10">DC Address</th>
              <th class="text-center w-10">Remark</th>
              <th
                class="text-center w-5"
                *ngIf="
                  ![purchaseConditionPageMode.VIEW_REQUEST].includes(mode) &&
                  purchaseConditionPermission.showButton &&
                  purchaseConditionPermission.hasManagePurchaseCondition
                "
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody formArrayName="dcInformation">
            <tr *ngFor="let item of dcInformationItems.controls; index as i" [formGroupName]="i">
              <td class="text-center">
                {{ i + 1 }}
              </td>
              <td class="text-left">
                {{ item.get('name').value }}
              </td>
              <td class="text-left">
                {{ item.get('detail').value }}
              </td>
              <td class="text-left">
                {{ item.get('remark').value }}
              </td>
              <td
                class="text-center"
                *ngIf="
                  ![purchaseConditionPageMode.VIEW_REQUEST].includes(mode) &&
                  purchaseConditionPermission.showButton &&
                  purchaseConditionPermission.hasManagePurchaseCondition
                "
              >
                <a
                  href="javascript:void(0)"
                  class="btn-more "
                  [popover]="moreTemplate"
                  placement="bottom"
                  containerClass="fl"
                  [outsideClick]="true"
                  container=".popover-container"
                  [adaptivePosition]="false"
                >
                  <em class="icon-more"></em>
                </a>
                <ng-template #moreTemplate>
                  <button
                    type="button"
                    class="btn btn-link"
                    (click)="onClickEditInformation(informationType.DC, item, i)"
                  >
                    <em class="icon-edit"></em>Edit
                  </button>
                  <button
                    (click)="alertModalConfirmDelete(informationType.DC, i, item)"
                    type="button"
                    class="btn btn-link"
                  >
                    <em class="icon-delete"></em>Delete
                  </button>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>

        <a
          class="btn-text-link d-inline-block ml-1 "
          (click)="onClickAddInformation(informationType.DC)"
          *ngIf="
            ![
              purchaseConditionPageMode.VIEW_REQUEST,
              purchaseConditionPageMode.VIEW,
              purchaseConditionPageMode.EDIT
            ].includes(mode)
          "
          >+Add DC</a
        >
      </div>
      <hr class="section-divider" />
    </div>
  </div>
  <!--End Table DC Information-->
</div>
