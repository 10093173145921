import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ShelfInventoryRequestService } from '../../services/shelf-inventory-request.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { ShelfInventoryApproveResponseAction, ShelfInventoryCreateSaveErrorAction, ShelfInventoryCreateSaveResponseAction, ShelfInventoryCreateSubmitErrorAction, ShelfInventoryCreateSubmitResponseAction, ShelfInventoryDeleteResponseAction, ShelfInventoryRequestActionType, ShelfInventoryRequestByIdResponseAction, ShelfInventoryRequestListHistoryResponseAction, ShelfInventoryRequestListResponseAction } from '../actions/shelf-inventory-request.actions';
export class ShelfInventoryRequestEffects {
    constructor(actions$, shelfInventoryRequestService, logger) {
        this.actions$ = actions$;
        this.shelfInventoryRequestService = shelfInventoryRequestService;
        this.logger = logger;
        this.searchShelfRequest$ = this.actions$.pipe(ofType(ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfInventoryRequestService.searchByCriteria(payload).pipe(map(response => {
                return new ShelfInventoryRequestListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.shelfCreateSave$ = this.actions$.pipe(ofType(ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SAVE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SAVE_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfInventoryRequestService.saveRequest(payload).pipe(map(res => new ShelfInventoryCreateSaveResponseAction(res)), catchError(error => of(new ShelfInventoryCreateSaveErrorAction(error))));
        }));
        this.shelfCreateSubmit$ = this.actions$.pipe(ofType(ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SUBMIT_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfInventoryRequestActionType.SHELF_INVENTORY_CREATE_SUBMIT_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfInventoryRequestService.submitRequest(payload).pipe(map(res => new ShelfInventoryCreateSubmitResponseAction(res)), catchError(error => of(new ShelfInventoryCreateSubmitErrorAction(error.error))));
        }));
        this.getShelfRequestById$ = this.actions$.pipe(ofType(ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_GET_BY_ID_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_GET_BY_ID_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfInventoryRequestService.getShelfRequestById(payload).pipe(map(res => {
                return new ShelfInventoryRequestByIdResponseAction(res);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.shelfApprove$ = this.actions$.pipe(ofType(ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_APPROVE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_APPROVE_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.shelfInventoryRequestService.approveRequest(payload).pipe(map(() => new ShelfInventoryApproveResponseAction({ isSuccess: true })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.shelfRequestHistory$ = this.actions$.pipe(ofType(ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_HISTORY_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_LIST_HISTORY_REQUEST}: ` +
                JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfInventoryRequestService.getHistoryLogs(payload).pipe(map(response => new ShelfInventoryRequestListHistoryResponseAction({ auditLogs: response.auditLogs })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.shelfRequestDelete$ = this.actions$.pipe(ofType(ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_DELETE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfInventoryRequestActionType.SHELF_INVENTORY_REQUEST_DELETE_REQUEST}: ` +
                JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.shelfInventoryRequestService.deleteByRequestId(payload).pipe(map(() => new ShelfInventoryDeleteResponseAction({ isSuccess: true })), catchError(error => of(new LayoutActionLoadError(error))))));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfInventoryRequestEffects.prototype, "searchShelfRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfInventoryRequestEffects.prototype, "shelfCreateSave$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfInventoryRequestEffects.prototype, "shelfCreateSubmit$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfInventoryRequestEffects.prototype, "getShelfRequestById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfInventoryRequestEffects.prototype, "shelfApprove$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfInventoryRequestEffects.prototype, "shelfRequestHistory$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfInventoryRequestEffects.prototype, "shelfRequestDelete$", void 0);
