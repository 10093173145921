<div>
  <div class="row section-header">
    <span
      >Discount
      <span
        *ngIf="
          purchaseConditionParentForm.get('discountEdit').value &&
          purchaseConditionParentForm.get('requestType').value === requestTypeEnum.EDIT &&
          [purchaseConditionPageMode.VIEW_REQUEST].includes(mode)
        "
        class="badge badge-warning text-white"
        >Updated</span
      >
    </span>
  </div>

  <div class="form-group text-nowrap">
    <app-import-export-button
      [buttons]="buttons"
      (import)="checkExistingItem()"
      (export)="onExportDiscount()"
    ></app-import-export-button>
  </div>
  <div *ngIf="(listState$ | async).totalElements > 0">
    <div class="mt-3 table-responsive">
      <table id="itemConditionTable" class="table table-striped table-bordered table-hover border-none">
        <thead>
          <tr>
            <th class="text-center">Product Level</th>
            <th class="text-center">Product</th>
            <th class="text-center">Discount</th>
            <th class="text-center">Effective Date</th>
            <th class="text-center">Expire Date</th>
            <th class="text-center">Apply To DC</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of itemConditionList; index as i">
            <td class="text-left">
              {{ 'PURCHASE_CONDITION.PRODUCT_LEVEL.' + result.productLevel | translate }}
            </td>
            <td class="text-left">
              {{ result.productName ? result.productName : result.articleNo }}
              <div *ngIf="result.productName">
                <span>{{ result.articleNo }} </span>
              </div>
            </td>
            <td class="text-center">
              {{ result.discountByPercent + '%' }}
            </td>
            <td class="text-center">
              {{ result.effectiveDate | amFromUtc | amLocal | dateDisplay: dateFormat }}
            </td>
            <td class="text-center">
              {{ result.expiredDate | amFromUtc | amLocal | dateDisplay: dateFormat }}
            </td>
            <td class="text-left">
              <div *ngFor="let warehouse of result.warehouses" class="with-newlines">
                {{ warehouse.wmsCodeName }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!--pagination-->
      <app-search-pagination
        [currentPage]="currentPage"
        [pageSize]="pageSize"
        [listState$]="listState$"
        (changePage)="onChangePage($event)"
        (changeRowPerPage)="onChangeRowPerPage($event)"
      >
      </app-search-pagination>
    </div>
  </div>
</div>
