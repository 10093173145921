<div class="modal-content admin-form">
  <div class="modal-header">
    <h5 class="modal-title pull-left">Import</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" [formGroup]="form">
    <div class="form-row">
      <div class="form-group col-md">
        <label for="orderDeliveryScheduleTemplate">Order/Delivery Schedule Template</label>
        <div class="d-flex">
          <input
            type="text"
            class="form-control mr-3"
            id="orderDeliveryScheduleTemplate"
            placeholder="{{ importPlaceholder }}"
            disabled
          />
          <button type="button" class="btn btn-primary" (click)="downloadOrderDeliveryScheduleTemplate()">
            <em class="icon-download"></em>
            {{ 'REPORTS.DOWNLOAD' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md">
        <label for="importItems">Order/Delivery Schedule</label>
        <div class="d-flex">
          <app-file-submit-before-upload
            id="importItems"
            formControlName="importItems"
            class="form-control border-0 p-0"
            [hasError]="form.controls.importItems.errors"
            [allowedTypes]="'sheet'"
            [withMeta]="true"
            [controlName]="'importItems'"
            [allowedExt]="'xlsx'"
            [fileModule]=""
            (uploadFileContent)="onUpload($event)"
          ></app-file-submit-before-upload>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md">
        <div class="alert-danger form-group mb-0" *ngIf="form.controls.importItems.errors">
          <div *ngIf="form.controls.importItems.errors?.fileExt">
            Incorrect Format (allow only format file .xlsx).
          </div>
          <div class="error-message" *ngIf="errorMessage && form.controls.importItems.errors?.importFail">
            {{ errorMessage }}
            <a *ngIf="errorFile" class="btn btn-link upload-error-file" (click)="downloadErrorExcel()">download</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="bsModalRef.hide()" id="btCancel">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="
        form.controls.importItems.errors ||
        !form.controls.importItems.value ||
        form.controls.importItems.value.length === 0 ||
        (form.controls.importItems.value &&
          form.controls.importItems.value[0] &&
          form.controls.importItems.value[0].status === purchaseConditionOrderDeliveryScheduleImportStatusEnum.FAILED)
      "
      id="btnSubmit"
      (click)="fileUpload.uploadFile()"
    >
      OK
    </button>
  </div>
</div>
