import { ShelfDetailRequest } from '.';
import { ApproveStatusEnum } from '../enum/approve-status.enum';
import { OrderingMethodEnum, OrderSubTypeEnum } from '../enum/ordering-method.enum';
import { ProductTypeEnum } from '../enum/product-type.enum';
import { NewRequestStatusEnum } from '../enum/request-status.enum';
import { NewRequestTypeEnum, RequestProductErrorEnum } from '../enum/request-step.enum';
import { StoreType } from '../enum/store.enum';
import { BasePageList } from './base-pagelist';
import { Price } from './price.model';
import { Money } from './promotion.model';
import { ProductStatusEnum } from './request-assortment.model';

export class OrderId {
  orderId: string;
}

export class OrderRequestNo {
  orderRequestNo: string;
}

export class OrderRequestListSearchCriteria {
  searchCriteria?: string;
  storeType?: string;
  orderType?: string;
  orderSubType?: string;
  requestedDateFrom?: string;
  requestedDateTo?: string;
  status?: string;
  warehouseCode?: string[];
  page: number;
  size: number;
}

export class OrderRequestListResponse {
  content: OrderRequestList[];
  empty: boolean;
  first: boolean;
  hasContent: boolean;
  last: boolean;
  numberOfElements: number;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class ProductOrderRequest {
  content?: ProductOrderItem[];
  page: number;
  size: number;
}

export class OrderRequestList {
  id: string;
  requestNo: string;
  storeName: string;
  storeType: string;
  orderType: string;
  amount: Price;
  requestedBy: string;
  requestedDate: string;
  status: NewRequestStatusEnum;
}

export class OrderStore {
  merchantNo: string;
  storeNo: string;
  storeCode: string;
  storeName: string;
  storeCodeName: string;
  storeType: StoreType;
  openDate?: string;
  minOrder?: Money;
}

export class OrderCreateRequest {
  id?: string;
  version?: number;
  requestNo?: string;
  type: NewRequestTypeEnum;
  status: NewRequestStatusEnum;
  orderNo?: string;
  orderType: OrderingMethodEnum;
  orderSubType?: OrderSubTypeEnum;
  store: OrderStore;
  productOrderItems: ProductOrderItem[];
  amount: Price;
  vatAmount: Price;
  amountExcVat: Price;
  deliveryStatus?: string;
  shelfDetails: ShelfDetailRequest[];
  deliveryDetails: DeliveryDetails;
  warehouseCode: string;
  warehouseName?: string;
  warehouseDisplayName?: string;
  z8OrderRequestRefNo?: string;
  z8OrderRequestRefId?: string;
}

export class OrderRequestViewResponse extends OrderCreateRequest {
  requestedBy: string;
  requestedByName: string;
  requestedDate: string;
}

export class ProductOrderItem {
  barcode: string;
  articleNo: string;
  productName: string;
  productDisplayName: string;
  productType: ProductTypeEnum;
  productStatus: ProductStatusEnum;
  unit: string;
  unitFactor: number;
  unitPrice?: Price;
  segment: string;
  deliveryMethod: string;
  supplierCode: string;
  supplierName: string;
  orderingMethods: OrderingMethodEnum[];
  qty: number;
  quantity?: number;
  amount?: Price;
  vatAmount?: Price;
  vatAmountPerUnit?: Price;
  vatAmountWithQty?: Price;
  amountExcVat?: Price;
  totalVat?: Price;
  wholesalePrice?: Price;
  wholesalePricePerUnit?: Price;
  wholesalePriceExcVat?: Price;
  allowToDelete?: boolean;
  vat: boolean;
  vatPct?: number;
  isAddItem?: boolean;
  errorMessage?: RequestProductErrorEnum;
  errorMessageValue?: string;
  retailPrice?: number;
  retailPricePerUnit?: number;
  supplierPricePerOrderUnit?: Price;
  orderUnit: string;
  supplierUnitFactor: number;
  itemStatus?: string;
  reason?: string;
  baseUnit: string;
}

export class FixAssetProductOrderItem {
  itemNo: number;
  barcode: string;
  articleNo: string;
  productName: string;
  productDisplayName: string;
  productType: ProductTypeEnum;
  segment: string;
  deliveryMethod: string;
  baseUnit: string;
  unit: string;
  unitFactor: number;
  quantity: number;
  onShelfQty?: number;
  vat: boolean;
  unitPrice?: Price;
  wholesalePrice?: Price;
  wholesalePricePerUnit?: Price;
  wholesalePriceExcVat?: Price;
  vatAmount?: Price;
  totalVat?: Price;
  amount?: Price;
  amountExcVat?: Price;
  qty?: number;
}

export class ShelfFixAssetOrderItem {
  id: string;
  shelfNo: string;
  shelfCode: string;
  shelfName: string;
  qty?: number;
  status: NewRequestStatusEnum;
  items: FixAssetProductOrderItem[];
  isAddItem?: boolean;
}

export class OrderRequestValidate {
  articleNo: string;
  barcode: string;
}

export class DeliveryDetails {
  storeOpenDate?: string;
  deliveryDetailsByType: DeliveryDetailsByTypeDictionary<DeliveryDetail>;
  errorStatus?: boolean;
  estimatedDeliveryDate?: string;
  estimatedOrderDate?: string;
}

export class DeliveryDetail {
  deliveryDate: string;
  deliveryNote: string;
}

export class ProductOrderList extends BasePageList<ProductOrderItem> {}

export class ApproveOrderRequest {
  requestNo: string;
  status: ApproveStatusEnum;
  comment: string;
}

export class OrderSelectItemSearchCriteria {
  size?: number;
  searchCriteria?: string;
  page?: number;
  productType?: ProductTypeEnum[];
  orderingMethods?: OrderMethodEnum;
  excStatus?: string;
  storeNo?: string;
}
export enum OrderMethodEnum {
  ALL = 'FIRST_LOT_ORDER,SPECIAL_REQUEST,STORE_REPLENISHMENT',
  FIRST_LOT_ORDER = 'FIRST_LOT_ORDER',
  SPECIAL_REQUEST = 'SPECIAL_REQUEST',
  STORE_REPLENISHMENT = 'STORE_REPLENISHMENT'
}

export enum InventoryTypeEnum {
  FOOD = 'FOOD',
  NON_FOOD = 'NON_FOOD',
  PROMOTION_OTHERS = 'PROMOTION_OTHERS',
  FIX_ASSET = 'FIX_ASSET',
  STORE_USE = 'STORE_USE'
}

export interface DeliveryDetailsByTypeDictionary<T> {
  [key: string]: T;
}

export interface ImportOrderRequest {
  articleNo: string;
  qty: number;
}

export enum OrderRequestDataSheet {
  no = 'A',
  articleNo = 'B',
  productName = 'C',
  barcode = 'D',
  productType = 'E',
  unit = 'F',
  unitPrice = 'G',
  qty = 'H',
  amount = 'I'
}
export interface OrderRequestProductItemsExport {
  no: number;
  articleNo: string;
  productName: string;
  barcode: string;
  productType: string;
  unit: string;
  unitPrice: number;
  quantity: number;
  amount: number;
}

export interface ImportFileRequest {
  guid: string;
  file?: File;
}
