import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ShelfInventoryItems, ShelfSelectedItems } from '../../models';
import { ShelfInventoryListSearchCriteria } from '../../models/shelf-inventory.model';
import {
  ShelfSelectInventoryActions,
  ShelfSelectInventoryActionTypes
} from '../actions/shelf-select-inventory.actions';
import { BaseState } from '../state/base.state';

export interface ShelfSelectInventoryState extends EntityState<ShelfInventoryItems>, BaseState {
  criteriaObject: ShelfInventoryListSearchCriteria;
  totalElements: number;
  totalPages: number;
  selected: ShelfSelectedItems[];
}

export const adapter: EntityAdapter<ShelfInventoryItems> = createEntityAdapter<ShelfInventoryItems>({
  selectId: (shelfItems: ShelfInventoryItems) => shelfItems.shelfType
});

export const initialShelfSelectInventoryResponseState: ShelfSelectInventoryState = adapter.getInitialState({
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  selected: []
});

export function shelfSelectInventoryReducers(
  state = initialShelfSelectInventoryResponseState,
  action: ShelfSelectInventoryActions
): ShelfSelectInventoryState {
  switch (action.type) {
    case ShelfSelectInventoryActionTypes.SHELF_SELECT_INVENTORY_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case ShelfSelectInventoryActionTypes.SHELF_SELECT_INVENTORY_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case ShelfSelectInventoryActionTypes.SHELF_SELECT_INVENTORY_LIST_RESET:
      return adapter.removeAll({
        ...state
      });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
