import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { StoreConsultantService } from '../../services/store-consultant.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import {
  StoreConsultantActionTypes,
  StoreConsultantGetByIdRequestAction,
  StoreConsultantGetByIdResponseAction,
  StoreConsultantListRequestAction,
  StoreConsultantListResponseAction,
  StoreConsultantSubmitRequestAction
} from '../actions/store-consultant.actions';

@Injectable()
export class StoreConsultantEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly storeConsultantService: StoreConsultantService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchStoreRequest$ = this.actions$.pipe(
    ofType<StoreConsultantListRequestAction>(StoreConsultantActionTypes.STORE_CONSULTANT_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StoreConsultantActionTypes.STORE_CONSULTANT_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.storeConsultantService.searchStoreConsultantByCriteria(payload).pipe(
        map(response => {
          return new StoreConsultantListResponseAction(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getStoreConsultantByUserId$ = this.actions$.pipe(
    ofType<StoreConsultantGetByIdRequestAction>(StoreConsultantActionTypes.STORE_CONSULTANT_GET_BY_ID_REQUEST),
    tap(action =>
      this.logger.debug(
        `@Effect ${StoreConsultantActionTypes.STORE_CONSULTANT_LIST_REQUEST}: ` + this.stringify(action.payload)
      )
    ),
    mergeMap(action =>
      this.storeConsultantService.getStoreConsultantByUserId(action.payload.userId).pipe(
        map(result => {
          return new StoreConsultantGetByIdResponseAction(result);
        }),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  submitStore$ = this.actions$.pipe(
    ofType<StoreConsultantSubmitRequestAction>(StoreConsultantActionTypes.STORE_CONSULTANT_SUBMIT_REQUEST),
    tap(action =>
      this.logger.debug(
        `@Effect ${StoreConsultantActionTypes.STORE_CONSULTANT_SUBMIT_REQUEST}: ` + this.stringify(action.payload)
      )
    ),
    switchMap(action => {
      return this.storeConsultantService.update(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'Store Consultant has been updated.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
