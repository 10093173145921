import { Action } from '@ngrx/store';
import { ImportOrderRequest, ProductOrderItem } from '../../models/order-request.model';

export enum OrderInventorySelectItemOrderActionTypes {
  ORDER_INVENTORY_SELECT_ITEM_ORDER_ADD_ITEM = '[Order Inventory Select Item Order] Add Item',
  ORDER_INVENTORY_SELECT_ITEM_ORDER_UPDATE_ITEM = '[Order Inventory Select Item Order] Update Item',
  ORDER_INVENTORY_SELECT_ITEM_ORDER_UPDATE_ALL_ITEM = '[Order Inventory Select Item Order] Update ALL Item',
  ORDER_INVENTORY_SELECT_ITEM_ORDER_REMOVE_ITEM = '[Order Inventory Select Item Order] Remove Item',
  ORDER_INVENTORY_SELECT_ITEM_ORDER_RESET = '[Order Inventory Select Item Order] Reset',
  ORDER_INVENTORY_SELECT_ITEM_ORDER_ADD_ALL_ITEM = '[Order Inventory Select Item Order] Add All Item'
}

export class OrderInventorySelectItemOrderAddItem implements Action {
  readonly type = OrderInventorySelectItemOrderActionTypes.ORDER_INVENTORY_SELECT_ITEM_ORDER_ADD_ITEM;

  constructor(public payload: ProductOrderItem) {}
}

export class OrderInventorySelectItemOrderUpdateItem implements Action {
  readonly type = OrderInventorySelectItemOrderActionTypes.ORDER_INVENTORY_SELECT_ITEM_ORDER_UPDATE_ITEM;

  constructor(public payload: ProductOrderItem) {}
}

export class OrderInventorySelectItemOrderUpdateAllItem implements Action {
  readonly type = OrderInventorySelectItemOrderActionTypes.ORDER_INVENTORY_SELECT_ITEM_ORDER_UPDATE_ALL_ITEM;

  constructor(public payload: Array<ImportOrderRequest>) {}
}

export class OrderInventorySelectItemOrderAddAllItem implements Action {
  readonly type = OrderInventorySelectItemOrderActionTypes.ORDER_INVENTORY_SELECT_ITEM_ORDER_ADD_ALL_ITEM;

  constructor(public payload: { itemList: ProductOrderItem[]; skipUpdated?: boolean }) {}
}

export class OrderInventorySelectItemOrderRemoveItem implements Action {
  readonly type = OrderInventorySelectItemOrderActionTypes.ORDER_INVENTORY_SELECT_ITEM_ORDER_REMOVE_ITEM;

  constructor(public payload: string) {}
}

export class OrderInventorySelectItemOrderReset implements Action {
  readonly type = OrderInventorySelectItemOrderActionTypes.ORDER_INVENTORY_SELECT_ITEM_ORDER_RESET;

  constructor() {}
}

export type OrderInventorySelectItemOrderActions =
  | OrderInventorySelectItemOrderAddItem
  | OrderInventorySelectItemOrderRemoveItem
  | OrderInventorySelectItemOrderReset
  | OrderInventorySelectItemOrderAddAllItem
  | OrderInventorySelectItemOrderUpdateItem
  | OrderInventorySelectItemOrderUpdateAllItem;
