export enum StockMovementTypeEnum {
  INITIAL_STOCK = 'INITIAL_STOCK',
  ADJUST_STOCK = 'ADJUST_STOCK',
  DELIVERY_DO = 'DELIVERY_DO',
  RECEIVED_RO = 'RECEIVED_RO',
  RECEIVED_GR = 'RECEIVED_GR',
  GOOD_SOLD_RCPT = 'GOOD_SOLD_RCPT',
  GOOD_CLAIMED_CR = 'GOOD_CLAIMED_CR',
  CANCELLED_GR = 'CANCELLED_GR',
  RECEIVED_TO = 'RECEIVED_TO'
}
