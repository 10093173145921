<div class="select-item-modal full-popup-head">
  <a aria-label="Back" class="clickable" (click)="onBack()">
    <div class="full-popup-close float-left">
      <span aria-label="Close" class="close">
        <em class="icon-prev ml-2"></em>
      </span>
    </div>
    <div class="full-popup-title">Back to Transfer Request</div>
  </a>
  <div class="full-popup-title ml-auto">
    <em class="icon-cart md-size cursor-pointer" (click)="onBack()"></em>
    <span class="badge badge-pill badge-success">{{ totalOrder$ | async }}</span>
  </div>
</div>

<div class="admin-form modal-table-wrap">
  <div class="row section-header-item mb-3">
    <span>Item List</span>
  </div>
  <div class="d-flex flex-row">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="ml-auto">
      <div class="clearfix">
        <div class="form-group float-right mx-1">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="form-control searchCriteria"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="align-middle">
      <div class="d-flex justify-content-center no-data">
        <img width="240" height="240" src="/assets/images/no-item.svg" alt="no item" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="table-responsive table-scroll mt-3 mb-2">
      <table id="data-table" class="table table-striped table-bordered table-hover w-100">
        <thead>
          <tr>
            <th class="text-center w-5">No.</th>
            <th class="text-center w-20">Product Name</th>
            <th class="text-center w-10">Article No.</th>
            <th class="text-center w-5">Barcode</th>
            <th class="text-center w-20">Product Type</th>
            <th class="text-center w-10">Unit</th>
            <th class="text-center w-10">Quantity</th>
            <th class="text-center w-10">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let stockProduct of resultList$ | async; index as i">
            <td class="text-center" (click)="goToView(stockProduct.articleNo)">
              {{ (currentPage - 1) * pageSize + i + 1 }}
            </td>
            <td (click)="goToView(stockProduct.articleNo)">
              {{ stockProduct.productName | dashDisplay: '(None)' }}
            </td>
            <td (click)="goToView(stockProduct.articleNo)">
              {{ stockProduct.articleNo | dashDisplay }}
            </td>
            <td (click)="goToView(stockProduct.articleNo)">
              {{ stockProduct.barcode | dashDisplay }}
            </td>
            <td (click)="goToView(stockProduct.articleNo)">
              {{ 'PRODUCT_ASSORTMENT.PRODUCT_TYPE.' + stockProduct.productType | translate }}
            </td>
            <td class="text-center" (click)="goToView(stockProduct.articleNo)">
              {{ stockProduct.unit | dashDisplay }}
            </td>
            <td class="text-center p-10px" (click)="goToView(stockProduct.articleNo)">
              <app-numeric-textbox
                #quantity
                [format]="'0,0'"
                ngClass="numeric-format quantity"
                placeholder="Quantity"
                maxlength="10"
                value="1"
                class="form-control td-numeric-textbox"
                minValue="0"
                [ngClass]="{
                  'is-invalid': stockProduct.isAddItem && quantity.value === 0
                }"
                (keyup)="changeQuantityValue(stockProduct)"
              ></app-numeric-textbox>
              <div class="invalid-feedback text-left">
                <div *ngIf="stockProduct.isAddItem && quantity.value === 0">
                  <span translate="ERRORS.NOT_ALLOW" [translateParams]="{ value: '0' }"></span>
                </div>
              </div>
            </td>

            <td class="text-center">
              <a class="mr-1" (click)="addItem(stockProduct, quantity.value)" href="javascript:void(0)">
                <em class="icon-cart md-size" title="Add" appTippy [tippyOptions]="{ arrow: true }"> </em>
              </a>

              <div class="text-success" *ngIf="stockProduct.quantity > 0">
                <span class="text-success">Added = {{ stockProduct.quantity | numberFormatDisplay: 0 }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>
