export enum RequestStepEnum {
  A = 'APPROVED',
  F = 'FIRST_LOT_ORDER',
  L = 'LEGAL_DOCUMENT',
  W = 'WALLET',
  O = 'ORDER_POLICY',
  P = 'PROFILE',
  EP = 'EDIT_PROFILE',
  APPROVED = 'A',
  FIRST_LOT_ORDER = 'F',
  LEGAL_DOCUMENT = 'L',
  WALLET = 'W',
  ORDER_POLICY = 'O',
  PROFILE = 'P',
  EDIT_PROFILE = 'EP'
}

export enum RequestPageModesEnum {
  REQUEST_VIEW = 'REQUEST_VIEW',
  REQUEST_EDIT = 'REQUEST_EDIT',
  REQUEST_CREATE = 'REQUEST_CREATE'
}

export enum RequestTypeEnum {
  NEW = 'N',
  EDIT = 'E'
}

export enum RequestProductErrorEnum {
  INVALID_BARCODE = 'INVALID_BARCODE',
  INACTIVE_BARCODE = 'INACTIVE_BARCODE',
  STATUS_IS_DELISTED = 'STATUS_IS_DELISTED',
  RESTRICT_ITEM = 'RESTRICT_ITEM',
  NOT_ALLOW_INVENTORY = 'NOT_ALLOW_INVENTORY',
  NOT_ALLOW_FIX_ASSET = 'NOT_ALLOW_FIX_ASSET',
  NOT_ALLOW_STORE_USE = 'NOT_ALLOW_STORE_USE',
  DUPLICATED_BARCODE_FIELD = 'DUPLICATED_BARCODE_FIELD',
  NOT_ALLOW_ZERO = 'NOT_ALLOW_ZERO',
  NOT_ALLOW_ALL_ZERO = 'NOT_ALLOW_ALL_ZERO',
  NOT_ALLOW_ALL_INVALID = 'NOT_ALLOW_ALL_INVALID',
  HOLD_BUY_SELL = 'HOLD_BUY_SELL',
  DELETE = 'DELETE',
  RECALL = 'RECALL',
  FRESH_LITE = 'FRESH_LITE',
  ASSORTMENT = 'ASSORTMENT',
  INVALID_SUPPLIER_PRICE = 'INVALID_SUPPLIER_PRICE',
  INVALID_SUPPLIER = 'INVALID_SUPPLIER',
  MAXIMUM_PURCHASING_PRICE = 'MAXIMUM_PURCHASING_PRICE',
  UNAVAILABLE_SHELF = 'UNAVAILABLE_SHELF'
}

export enum NewRequestTypeEnum {
  NEW = 'NEW',
  EDIT = 'EDIT'
}
