const formatNumber = (numb, precision = 2) => {
  if (!numb && numb !== 0) {
    return null;
  }
  if (typeof numb === 'string' || numb instanceof String) {
    return numb;
  }
  if (precision === 0) {
    const realNumber = String(Number(numb).toLocaleString()).match(/^([0-9,]+)/);
    return realNumber[1];
  }

  const floatNumber = Number(numb).toFixed(precision);
  const groupNumber = String(floatNumber).match(/^(\d+).(\d+)$/);
  const leadingNumber = String(Number(groupNumber[1]).toLocaleString()).match(/^([0-9,]+)/);
  return `${leadingNumber[0]}.${groupNumber[2]}`;
};

const alwaysRoundUp = (num, precision) => {
  /**
   * @param num The number to round
   * @param precision The number of decimal places to preserve
   */
  precision = Math.pow(10, precision);
  return Math.ceil(num * precision) / precision;
};

const roundToDecimalPlace = (num, scale) => {
  if (!('' + num).includes('e')) {
    // @ts-ignore
    return +(Math.round(num + 'e+' + scale) + 'e-' + scale);
  } else {
    const arr = ('' + num).split('e');
    let sig = '';
    if (+arr[1] + scale > 0) {
      sig = '+';
    }
    const i = +arr[0] + 'e' + sig + (+arr[1] + scale);
    // @ts-ignore
    const j = Math.round(i);
    const k = +(j + 'e-' + scale);
    return k;
  }
};

const roundTo2DecimalPlace = num => {
  return roundToDecimalPlace(roundToDecimalPlace(num, 6), 2);
};
const amountToNumber = amount => {
  if (amount && typeof amount.amount === 'number') {
    return amount.amount;
  } else if (typeof amount === 'number') {
    return amount;
  }
  return null;
};

const numberMoreThanOrEqual = (num, check) => {
  return typeof num === 'number' && num >= check;
};

export {
  formatNumber,
  alwaysRoundUp,
  roundToDecimalPlace,
  roundTo2DecimalPlace,
  amountToNumber,
  numberMoreThanOrEqual
};
