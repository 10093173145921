import { createSelector } from '@ngrx/store';
import * as fromPosAdvertisementState from '../reducers/pos-advertisement.reducers';
import { AppStates } from '../state/app.states';

const selectPosAdvertisementState = (state: AppStates) => state.posAdvertisement;

export const selectAllPosAdvertisementList = createSelector(
  selectPosAdvertisementState,
  fromPosAdvertisementState.selectAll
);

export const selectPosAdvertisementList = createSelector(
  selectPosAdvertisementState,
  (state: fromPosAdvertisementState.PosAdvertisementState) => state
);

export const selectPosAdvertisementDefaultAds = createSelector(
  selectPosAdvertisementState,
  (state: fromPosAdvertisementState.PosAdvertisementState) => state.defaultAds
);

export const selectPosAdvertisementListCriteria = createSelector(selectPosAdvertisementState, state => {
  return state.criteriaObject;
});

export const selectPosAdvertisementSubmitError = createSelector(
  selectPosAdvertisementState,
  (state: fromPosAdvertisementState.PosAdvertisementState) => state.submitError
);

export const selectPosAdvertisementSelectById = createSelector(
  selectPosAdvertisementState,
  (state: fromPosAdvertisementState.PosAdvertisementState) => state.selected
);
