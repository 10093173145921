<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-purchase"></em>
    {{ purchaseConditionTitleMode[data.mode] }}
  </div>

  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="
      [purchaseConditionPageMode.VIEW_REQUEST].includes(data.mode) &&
      purchaseConditionRequest?.requestStatus === purchaseConditionRequestStatusEnum.DRAFT &&
      purchaseConditionPermission.hasManagePurchaseCondition
    "
    (click)="onDelete(data.id)"
  >
    <em class="icon-delete"></em>
    Delete
  </button>
  <button
    type="button"
    *ngIf="
      [purchaseConditionPageMode.VIEW_REQUEST].includes(data.mode) &&
      [purchaseConditionRequestStatusEnum.AWAITING_APPROVAL].includes(purchaseConditionRequest?.requestStatus) &&
      purchaseConditionPermission.hasManagePurchaseCondition
    "
    class="btn btn-header-button"
    (click)="onCancelRequest()"
  >
    <em class="icon-forbidden"></em>Cancel Request
  </button>
  <button
    type="button"
    *ngIf="
      [purchaseConditionPageMode.VIEW_REQUEST, purchaseConditionPageMode.VIEW].includes(data.mode) &&
      ![purchaseConditionRequestStatusEnum.DRAFT].includes(purchaseConditionRequest?.requestStatus)
    "
    class="btn btn-header-button"
    (click)="showHistory()"
  >
    <em class="icon-history"></em>History
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCloseFullModal()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="purchaseConditionRequest" class="admin-form">
  <div class="info-header-container">
    <div
      class="info-header-item"
      *ngIf="
        purchaseConditionRequest.requestNo &&
        [purchaseConditionPageMode.VIEW_REQUEST, purchaseConditionPageMode.EDIT_REQUEST].includes(data.mode)
      "
    >
      <div class="info-header-label">
        <span>Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ purchaseConditionRequest.requestNo }}</span>
      </div>
    </div>

    <div class="info-header-item">
      <div class="info-header-label">
        <span>Supplier Name:</span>
      </div>
      <div class="info-header-value">
        <span>{{ purchaseConditionRequest.supplierCode + ': ' + purchaseConditionRequest.supplierName }}</span>
      </div>
    </div>

    <div class="info-header-item" *ngIf="purchaseConditionRequest.requestStatus">
      <div class="info-header-label">
        <span>Request Status:</span>
      </div>
      <div class="info-header-value">
        <span
          [ngClass]="
            getColorStatus
              | pureFn: (purchaseConditionRequest.requestStatus ? purchaseConditionRequest.requestStatus : 'DRAFT')
          "
          class="purchase-condition-status"
          >{{
            'PURCHASE_CONDITION_REQUEST.STATUS.' +
              (purchaseConditionRequest.requestStatus ? purchaseConditionRequest.requestStatus : 'DRAFT') | translate
          }}</span
        >
      </div>
    </div>
  </div>

  <div class="tab-menu d-flex d-block-flex">
    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="isActive | pureFn: selectedTab:purchaseConditionTab.SUPPLIER_INFORMATION"
      (click)="onSelectTab(purchaseConditionTab.SUPPLIER_INFORMATION)"
    >
      Supplier Information
    </a>
    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="isActive | pureFn: selectedTab:purchaseConditionTab.SUPPLIER_CONDITION"
      (click)="onSelectTab(purchaseConditionTab.SUPPLIER_CONDITION)"
    >
      Supplier Condition
    </a>
    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="isActive | pureFn: selectedTab:purchaseConditionTab.ITEM_CONDITION"
      (click)="onSelectTab(purchaseConditionTab.ITEM_CONDITION)"
    >
      Item Condition
    </a>
    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="isActive | pureFn: selectedTab:purchaseConditionTab.ORDER_DELIVERY_SCHEDULE"
      (click)="onSelectTab(purchaseConditionTab.ORDER_DELIVERY_SCHEDULE)"
    >
      Order/Delivery Schedule
    </a>
  </div>

  <div id="supplier-information-tab" class="pb-5" *ngIf="selectedTab === purchaseConditionTab.SUPPLIER_INFORMATION">
    <app-supplier-information
      [supplierInformationForm]="supplierInformationForm"
      [purchaseConditionForm]="purchaseConditionForm"
      [mode]="data.mode"
      [requestType]="purchaseConditionRequest.requestType"
      (dirtyInformation)="setDirtyInformation($event)"
    ></app-supplier-information>
  </div>

  <div id="supplier-condition-tab" class="pb-5" *ngIf="selectedTab === purchaseConditionTab.SUPPLIER_CONDITION">
    <app-supplier-condition-tab
      (dirtyMinimumOrderFormChange)="dirtyMinimumOrderForm = $event"
      [purchaseConditionForm]="purchaseConditionForm"
      [mode]="data.mode"
    ></app-supplier-condition-tab>
  </div>

  <div id="item-condition-tab" class="pb-5" *ngIf="selectedTab === purchaseConditionTab.ITEM_CONDITION">
    <app-item-condition
      [supplierInformationParentForm]="supplierInformationForm"
      [purchaseConditionParentForm]="purchaseConditionForm"
      [mode]="data.mode"
    ></app-item-condition>
  </div>

  <div
    id="order-delivery-schedule-tab"
    class="pb-5"
    *ngIf="selectedTab === purchaseConditionTab.ORDER_DELIVERY_SCHEDULE"
  >
    <app-order-delivery-schedule
      [supplierInformationForm]="supplierInformationForm"
      [purchaseConditionForm]="purchaseConditionForm"
      [scheduleType]="purchaseConditionRequest.scheduleType"
      (scheduleTypeChange)="setScheduleType($event)"
      [pageMode]="data.mode"
      (dirtyOrderSchedule)="setDirtyOrderSchedule($event)"
    >
    </app-order-delivery-schedule>
  </div>

  <!--footer-->
  <ng-container>
    <ng-container
      *ngIf="
        ![purchaseConditionPageMode.VIEW_REQUEST, purchaseConditionPageMode.VIEW].includes(data.mode);
        else otherMode
      "
    >
      <div class="fixed-row-bottom form-row">
        <div
          class="float-left"
          *ngIf="
            ![purchaseConditionRequestStatusEnum.AWAITING_APPROVAL].includes(
              purchaseConditionRequest?.requestStatus
            ) && [purchaseConditionPageMode.EDIT_REQUEST, purchaseConditionPageMode.CREATE].includes(data.mode)
          "
        >
          <button type="button" id="btn-save" class="btn btn-secondary" (click)="onSave()" appPreventDoubleClick>
            Save
          </button>
        </div>
        <div class="float-right">
          <span
            *ngIf="
              [
                purchaseConditionRequestStatusEnum.DRAFT,
                purchaseConditionRequestStatusEnum.AWAITING_APPROVAL
              ].includes(purchaseConditionRequest?.requestStatus) ||
              [purchaseConditionPageMode.EDIT, purchaseConditionPageMode.EDIT_REQUEST].includes(data.mode)
            "
          >
            <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCloseFullModal()">
              Cancel
            </button>
            <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()" appPreventDoubleClick>
              Submit
            </button>
          </span>
        </div>
      </div>
    </ng-container>

    <ng-template #otherMode>
      <ng-container>
        <div class="fixed-row-bottom form-row">
          <div class="float-left"></div>
          <div
            class="float-right"
            *ngIf="
              [
                purchaseConditionRequestStatusEnum.AWAITING_APPROVAL,
                purchaseConditionRequestStatusEnum.DRAFT
              ].includes(purchaseConditionRequest?.requestStatus)
            "
          >
            <span class="mr-2">
              <button
                type="button"
                id="btn-edit-request"
                class="btn btn-primary"
                (click)="toggleToEdit()"
                *ngIf="
                  [
                    purchaseConditionRequestStatusEnum.AWAITING_APPROVAL,
                    purchaseConditionRequestStatusEnum.DRAFT
                  ].includes(purchaseConditionRequest?.requestStatus) &&
                  purchaseConditionPermission.hasManagePurchaseCondition
                "
              >
                Edit
              </button>
            </span>
            <span *ngIf="purchaseConditionPermission.hasApproverPurchaseCondition">
              <button
                type="button"
                id="btn-reject"
                class="btn btn-special-reject mr-2"
                (click)="reject()"
                *ngIf="
                  [purchaseConditionRequestStatusEnum.AWAITING_APPROVAL].includes(
                    purchaseConditionRequest?.requestStatus
                  )
                "
              >
                Reject
              </button>
              <button
                type="button"
                id="btn-approve"
                class="btn btn-special-approve"
                (click)="approve()"
                *ngIf="
                  [purchaseConditionRequestStatusEnum.AWAITING_APPROVAL].includes(
                    purchaseConditionRequest?.requestStatus
                  )
                "
              >
                Approve
              </button>
            </span>
          </div>
          <div
            class="float-right"
            *ngIf="
              purchaseConditionPermission.showButton &&
              [purchaseConditionPageMode.VIEW].includes(data.mode) &&
              purchaseConditionPermission.hasManagePurchaseCondition
            "
          >
            <span class="mr-2">
              <button type="button" id="btn-edit" class="btn btn-primary" (click)="toggleToEdit()">Edit</button>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <!--End footer-->
</div>
