import { Attribute, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ShelfTypesService } from '../services/shelf-types.service';

@Directive({
  selector: 'img[appSecureSrc]'
})
export class AppSecureSrcDirective implements OnInit, OnDestroy {
  @Attribute('img-error') public imgError: string;
  @Input() appSecureSrc: string;

  private readonly nativeElement: HTMLElement;
  private fullSrcPath: string;

  constructor(
    private readonly renderer: Renderer2,
    private readonly el: ElementRef,
    private readonly shelfTypeService: ShelfTypesService
  ) {
    this.nativeElement = this.el.nativeElement;
  }

  ngOnInit() {
    this.initAttribute();
  }

  ngOnDestroy() {}

  private initAttribute() {
    this.nativeElement.onerror = () => this.onError();
    this.nativeElement.setAttribute('loading', 'auto');

    this.fullSrcPath = './assets/images/no-image.png';
    this.nativeElement.setAttribute('src', this.fullSrcPath);

    if (this.appSecureSrc) {
      this.shelfTypeService
        .getFileUrl(this.appSecureSrc)
        .pipe(filter(data => Boolean(data)))
        .subscribe(signedUrlObject => {
          if (signedUrlObject.signedUrl) {
            this.nativeElement.setAttribute('src', signedUrlObject.signedUrl);
          }
        });
    }
  }

  private onError() {
    const fullImagePath = this.imgError || './assets/images/no-image.png';

    this.nativeElement.setAttribute('src', fullImagePath);
  }

  private onLoad() {
    if (this.nativeElement.getAttribute('src') !== this.appSecureSrc) {
      this.nativeElement.setAttribute('src', this.fullSrcPath);
    }
  }
}
