<div class="select-item-modal full-popup-head">
  <a aria-label="Back" class="clickable" (click)="onBack()">
    <div class="full-popup-close float-left">
      <span aria-label="Close" class="close">
        <em class="icon-prev ml-2"></em>
      </span>
    </div>
    <div class="full-popup-title">Back to Order Request</div>
  </a>
</div>

<div class="admin-form modal-table-wrap">
  <div class="row section-header-item">
    <span>Select Shelf Inventory</span>
  </div>
  <div class="d-flex flex-row">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="ml-auto">
      <div class="form-group ml-auto filter-search-box">
        <div class="search-box position-relative">
          <input
            type="text"
            formControlName="searchCriteria"
            class="form-control searchCriteria"
            placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
            required
            maxlength="50"
            (keyup.backspace)="clearLastKeyUpSearchText($event)"
          />
          <em
            class="icon-close-mini"
            [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
            (click)="clearSearchText()"
          ></em>
          <button class="btn-search" type="submit">
            <em class="icon-search"></em>
          </button>
        </div>
      </div>
    </form>
  </div>

  <ng-container *ngIf="formShelfItems.controls && formShelfItems.controls.length === 0">
    <div class="align-middle" *ngIf="true">
      <div class="d-flex justify-content-center no-data">
        <img width="240" height="240" src="/assets/images/no-item.svg" alt="no item" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="formShelfItems.controls && formShelfItems.controls.length > 0">
    <div class="table-responsive table-scroll mt-3 mb-3" [formGroup]="shelfListForm">
      <table id="shelf-table" class="table table-striped table-bordered w-100">
        <thead>
          <tr>
            <th class="text-center w-5">No.</th>
            <th class="text-center w-40">Shelf Type</th>
            <th class="text-center w-40">Shelf Option</th>
            <th class="text-center w-15">Action</th>
          </tr>
        </thead>
        <tbody formArrayName="shelfItems">
          <tr
            *ngFor="let item of formShelfItems.controls; index as i"
            [formGroupName]="i"
            [ngClass]="{
              'error-row': item.get('shelfCode').errors
            }"
          >
            <td class="text-center">
              <div class="mt-2">{{ getItemNo(i) }}</div>
            </td>
            <td>
              <div class="mt-2">{{ item.get('shelfType').value }}</div>
            </td>
            <td>
              <ng-select
                formControlName="shelfCode"
                id="shelfOptions"
                name="shelfOptions"
                class="select-default-width"
                placeholder="Please select..."
                [items]="getShelfOption(i)"
                *ngIf="getShelfOption(i) && getShelfOption(i).length > 1"
                [searchable]="false"
                bindLabel="shelfName"
                bindValue="shelfCode"
                appendTo="body"
                (open)="disableTableScroll(true)"
                (close)="disableTableScroll(false)"
                [ngClass]="{
                  'is-invalid': submitted && item.get('shelfCode').errors
                }"
                (change)="onChangeSelectShelfOption(i)"
              >
              </ng-select>
              <div class="mt-2" *ngIf="getShelfOption(i) && getShelfOption(i).length === 1">
                {{ getShelfOption(i)[0].shelfName }}
              </div>
            </td>
            <td class="text-center">
              <button
                type="button"
                class="btn btn-standard btn-block"
                [ngClass]="{
                  'btn-standard': !isSelectedShelf(item),
                  'btn-special-approve': isSelectedShelf(item)
                }"
                (click)="selectShelf(i)"
              >
                {{ isSelectedShelf(item) ? 'Selected' : 'Select' }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <!--footer-->
  <div class="fixed-row-bottom form-row">
    <div class="d-flex flex-row justify-content-end">
      <div class="flex-column mr-4">
        <div class="info-header-label">
          <span>Total Shelf Inventory</span>
        </div>
        <div class="info-header-value">{{ this.shelfSelected.length }}</div>
      </div>
      <div class="flex-column">
        <button
          type="button"
          id="btnGenereate"
          class="btn btn-primary"
          [disabled]="shelfSelected.length === 0"
          (click)="onGenerateShelfInventory()"
        >
          Generate Shelf Inventory
        </button>
      </div>
    </div>
  </div>
  <!--End footer-->
</div>
