import { createSelector } from '@ngrx/store';
import * as fromOrderInventoryCreateResponseState from '../reducers/order-inventory-create.reducers';
import { AppStates } from '../state/app.states';

const selectOrderInventoryCreate = (state: AppStates) => state.orderInventoryCreate;

export const selectOrderInventoryRequest = createSelector(
  selectOrderInventoryCreate,
  (state: fromOrderInventoryCreateResponseState.OrderInventoryCreateResponseState | null) => state
);

export const selectOrderInventoryApproveRejectStatus = createSelector(
  selectOrderInventoryCreate,
  (state: fromOrderInventoryCreateResponseState.OrderInventoryCreateResponseState) => {
    return state.isApproveRejectSuccess;
  }
);
