import { DeliveryByTypeEnum } from '../enum/delivery-by-type.enum';
import { DeliveryOrderStatusEnum } from '../enum/delivery-order-status.enum';
import { StoreType } from '../enum/store.enum';
import { AuditLog } from './audit-log.model';
import { BaseReport } from './base-report.model';

export interface DeliveryOrderList {
  id: string;
  deliveryOrderNo: string;
  orderNo: string;
}

export interface DeliveryOrder {
  id: string;
  doNo: string;
  doStatus: DeliveryOrderStatusEnum;
  orderNo?: string;
  poNo: string;
  shipmentNo: string;
  storeCode: string;
  storeName: string;
  storeCodeName: string;
  storeType: StoreType;
  orderType: string;
  shipTo: ShippingAddress;
  deliveryNote: string;
  deliveryByFourWheelsTruck: string;
  noEntry: string;
  createdDate?: string;
  pickDate: string;
  requestedDeliveryDate?: string;
  lastModifiedDate?: string;
  items: DeliveryOrderItem[];
  contactName: string;
  contactNumber: string;
  version: number;
  warehouseCode: string;
  warehouseName: string;
  warehouseDisplayName: string;
}

export class ShippingAddress {
  address: string;
  country: string;
  countryCode: string;
  mobileNo: string;
  postCode: string;
  state: string;
}

export class DeliveryOrderItem {
  splitNo: number;
  itemNo: number;
  productName: string;
  articleNo: string;
  barcode: string;
  unit: string;
  unitPrice?: number;
  qty: number;
  assignedQty: number;
  amount?: number;
  pickedQty: number;
  blur?: boolean;
}

export class SplitNoItem {
  itemNo: number;
  splitNo: number;
}

/* DO list */

export class DeliveryOrderSearchCriteria {
  searchCriteria?: string;
  status?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  requestedDeliveryDateFrom?: string;
  requestedDeliveryDateTo?: string;
  warehouseCode?: string[];
  page: number;
  size: number;
}

export class DeliveryOrderPagination {
  content: DeliveryOrderContent[];
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
}

export class DeliveryOrderContent {
  id?: string;
  version?: number;
  doNo: string;
  doStatus: DeliveryOrderStatusEnum;
  poNo: string;
  storeCode: string;
  storeName: string;
  storeCodeName: string;
  shipmentNo: string;
  licensePlate?: string;
  deliveryBy: DeliveryByTypeEnum;
  requestedDeliveryDate: string;
  createdDate?: string;
  lastModifiedDate?: string;
  pickedDate?: string;
  items?: DeliveryOrderItem[];
  courierName: string;
  plateNo: string;
  driverName: string;
  deliveryOrderHistories?: AuditLog[];
  warehouseDisplayName?: string;
  warehouseCode?: string;
  warehouseName?: string;
  platNoDriverNameDisplay?: string;
}

export class DeliveryOrderExportPickingCriteria extends BaseReport {
  requestedDeliveryDateFrom?: string;
  requestedDeliveryDateTo?: string;
  status: DeliveryOrderStatusEnum[];
  orderDateFrom?: string;
  orderDateTo?: string;
  pickDateFrom?: string;
  pickDateTo?: string;
  changeStatus: boolean;
  warehouseCode: string[];

  constructor(init?: Partial<DeliveryOrderExportPickingCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class DeliveryOrderPrintCriteria {
  id: string;
  format: string;
  locale: string;
}

export class CancelDeliveryOrder {
  doNo: string;
  comment: string;
}
