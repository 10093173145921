import { createEntityAdapter } from '@ngrx/entity';
import { StockProductSelectedActionTypes } from '../actions/stock-product-selected.actions';
const ɵ0 = (stockTransferItem) => `${stockTransferItem.articleNo}`;
export const adapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialStockProductSelectedState = adapter.getInitialState({
    isLoading: false
});
export function stockProductSelectedReducers(state = initialStockProductSelectedState, action) {
    switch (action.type) {
        case StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_ADD_ITEM:
            return adapter.upsertOne(action.payload, Object.assign({}, state));
        case StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_UPDATE_ITEM:
            return adapter.updateOne({
                id: action.payload.articleNo,
                changes: Object.assign({}, action.payload)
            }, Object.assign({}, state));
        case StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_ADD_ALL_ITEM:
        case StockProductSelectedActionTypes.PURCHASE_REQUEST_ITEM_SELECTED_LIST:
            return adapter.upsertMany(action.payload, Object.assign({}, state));
        case StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_INSERT_ALL_ITEM:
            return adapter.addAll(action.payload, Object.assign({}, state));
        case StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_REMOVE_ITEM:
            return adapter.removeOne(action.payload, Object.assign({}, state));
        case StockProductSelectedActionTypes.STOCK_PRODUCT_SELECTED_RESET:
            return adapter.removeAll(Object.assign({}, state));
        default: {
            return state;
        }
    }
}
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export { ɵ0 };
