import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import { uniq } from 'lodash';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { DeliveryOrderModeEnum, DeliveryOrderStatusEnum } from '../../../shared/enum/delivery-order-status.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { PermissionAction } from '../../../shared/enum/permission-action';
import { ShipmentTypeEnum } from '../../../shared/enum/shipment-type.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { ErrorResponse } from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import {
  DeliveryOrderContent,
  DeliveryOrderExportPickingCriteria,
  DeliveryOrderPrintCriteria,
  DeliveryOrderSearchCriteria
} from '../../../shared/models/delivery-order.model';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { DeliveryOrderShipment } from '../../../shared/models/shipment.model';
import { AuthGuardService } from '../../../shared/services';
import { DeliveryOrderService } from '../../../shared/services/delivery-order.service';
import { MasterService } from '../../../shared/services/master.service';
import { DeliveryOrderListRequestAction } from '../../../shared/store/actions/delivery-order.actions';
import {
  ShipmentResetSelectDeliveryOrder,
  ShipmentSelectDeliveryOrder,
  ShipmentUnselectDeliveryOrder
} from '../../../shared/store/actions/shipment.actions';
import { WarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { DeliveryOrderState } from '../../../shared/store/reducers/delivery-order.reducers';
import {
  selectDeliveryOrder,
  selectDeliveryOrderList
} from '../../../shared/store/selectors/delivery-order.selectors';
import {
  selectShipmentCheckList,
  selectShipmentDeliveryOrdersCreate
} from '../../../shared/store/selectors/shipment.selectors';
import { selectAllWarehouse } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  formatDate,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { generatedFilenamePdf } from '../../../shared/utils/generate-filename-util';
import { PermissionsUtil } from '../../../shared/utils/permissions-util';
import { ShipmentRequestComponent } from '../../shipment/shipment-request/shipment-request.component';
import { DeliveryOrderEditComponent } from '../delivery-order-edit/delivery-order-edit.component';
import { DeliveryOrderImportModalComponent } from '../delivery-order-import-modal/delivery-order-import-modal.component';

@Component({
  selector: 'app-delivery-order-list',
  templateUrl: './delivery-order-list.component.html',
  styleUrls: ['./delivery-order-list.component.scss']
})
export class DeliveryOrderListComponent
  extends BaseSearchComponent<DeliveryOrderSearchCriteria, DeliveryOrderContent, DeliveryOrderState>
  implements OnInit, OnDestroy {
  public bsModalRef: BsModalRef;
  public modalShipment: BsModalRef;
  public currentPage: number;
  public createdDateTag: string;
  public createdDateStringTag: string;
  public requestedDeliveryDateTag: string;
  public requestedDeliveryDateStringTag: string;
  public warehouseTypeTag: string;
  public warehouseTypeStringTag: string;
  public deliveryOrderStatusList: Array<{ value: string; label: string }>;
  public deliveryOrderStatusExportList: Array<{ value: string; label: string }>;
  public submittedExport: boolean;
  public exportForm: FormGroup;
  public checkedList: { [key: string]: boolean };
  public validateWarehouseCode: DeliveryOrderShipment[];
  public isDisabled: boolean;
  public shipmentTypeEnum = ShipmentTypeEnum;
  public requestDeliveryMinDate: Date;
  public requestDeliveryMaxDate: Date;
  public orderMinDate: Date;
  public orderMaxDate: Date;
  public pickMinDate: Date;
  public pickMaxDate: Date;
  public reqDeliveryMinDate: Date;
  public reqDeliveryMaxDate: Date;
  public errorExport: string;
  public warehouseList: NgOption[];
  public exportWarehouseList: NgOption[];
  public importWarehouseList: NgOption[];
  public hasManageDeliveryOrder: boolean;
  public hasViewDeliveryOrder: boolean;
  public hasManageLogistic: boolean;
  public hasViewLogistic: boolean;

  private localStore: Observable<any>;
  importInvalid: { title: string; table: any[]; message: string };

  public buttons: Array<ImportExportButton>;

  public importDoModal: BsModalRef;
  public permissionAction = PermissionAction;

  @ViewChild('exportModal', { static: false }) exportModal: ModalDirective;
  @ViewChild('importInvalidModel', { static: false }) importInvalidModel: ModalDirective;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected masterService: MasterService,
    private readonly translate: TranslateService,
    private deliveryOrderService: DeliveryOrderService,
    private spinner: NgxSpinnerService,
    private authGuardService: AuthGuardService,
    protected readonly permissionUtil: PermissionsUtil
  ) {
    super(store, modalService, selectDeliveryOrderList, selectDeliveryOrder);
    this.subscribeForSaveSuccess();
    this.deliveryOrderStatusList = filterDropdown.deliveryOrderStatusFilter;
    this.deliveryOrderStatusExportList = filterDropdown.deliveryOrderStatusFilter.filter(
      data =>
        ![DeliveryOrderStatusEnum.DRAFT, DeliveryOrderStatusEnum.COMPLETED].includes(
          data.value as DeliveryOrderStatusEnum
        ) && Object.values(DeliveryOrderStatusEnum).includes(data.value as DeliveryOrderStatusEnum)
    );
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.createExportForm();
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new WarehouseListRequestAction('-'));

    this.localStore.pipe(select(selectShipmentCheckList)).subscribe(selectedState => {
      this.checkedList = {
        ...(selectedState && Object.keys(selectedState).length && selectedState)
      };
    });

    this.localStore.pipe(select(selectShipmentDeliveryOrdersCreate)).subscribe(result => {
      this.validateWarehouseCode = result;
    });

    this.determinePermissions();
    this.generateWarehouse();
    this.getButton();
  }

  generateWarehouse() {
    this.localStore.pipe(select(selectAllWarehouse('Warehouse'))).subscribe(whs => {
      this.warehouseList = whs.filter(wh =>
        this.permissionUtil.checkPermissionByLocation(['do_logis_m_$1', 'do_v_$1', 'do_m_$1'], wh.code)
      );
      this.exportWarehouseList = whs.filter(wh =>
        this.permissionUtil.checkPermissionByLocation(['do_logis_m_$1', 'do_m_$1'], wh.code)
      );

      this.importWarehouseList = whs.filter(wh =>
        this.permissionUtil.checkPermissionByLocation(['do_m_$1'], wh.code)
      );
    });
  }

  getButton() {
    this.buttons = [
      {
        type: ButtonType.EXPORT,
        name: 'Export',
        hidden: !this.hasManageDeliveryOrder && !this.hasManageLogistic
      },
      {
        type: ButtonType.IMPORT,
        name: 'Import',
        hidden: !this.hasManageDeliveryOrder
      }
    ];
  }

  doDestroy() {
    this.store.dispatch(new ShipmentResetSelectDeliveryOrder());
  }

  doAfterSuccessModal() {
    if (this.modalShipment) {
      this.modalShipment.hide();
      this.doSearch(this.criteriaObject);
    }
  }

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
    if (this.modalShipment) {
      this.modalShipment.hide();
    }
  }

  determinePermissions() {
    this.hasManageDeliveryOrder = this.authGuardService.checkPermission('^do_m_[a-zA-Z]{2}[0-9]{1,3}$', true);
    this.hasViewDeliveryOrder = this.authGuardService.checkPermission('^do_v_[a-zA-Z]{2}[0-9]{1,3}$', true);
    this.hasManageLogistic = this.authGuardService.checkPermission('^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$', true);
    this.hasViewLogistic = this.authGuardService.checkPermission('^do_logis_v_[a-zA-Z]{2}[0-9]{1,3}$', true);
  }

  createForm() {
    this.submittedExport = false;
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.deliveryOrderStatusList[0].value],
      createdDateFrom: [null],
      createdDateTo: [null],
      requestedDeliveryDateFrom: [null],
      requestedDeliveryDateTo: [null],
      warehouseCode: [null]
    });
  }

  createExportForm() {
    this.exportForm = this.fb.group({
      status: [[DeliveryOrderStatusEnum.NEW], Validators.required],
      orderDateFrom: [null],
      orderDateTo: [null],
      pickDateFrom: [null],
      pickDateTo: [null],
      requestedDeliveryDateFrom: [null],
      requestedDeliveryDateTo: [null],
      changeStatusToPicking: [false],
      code: [null]
    });
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (
      !formValue.createdDateFrom &&
      !formValue.createdDateTo &&
      !formValue.requestedDeliveryDateFrom &&
      !formValue.requestedDeliveryDateTo &&
      !formValue.warehouseCode
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    const dateFormat = environment.dateFormat;

    this.criteriaObject = {
      ...this.criteriaObject,
      createdDateFrom: dateToStringCriteria(formValue.createdDateFrom),
      createdDateTo: dateToStringCriteria(formValue.createdDateTo, false),
      requestedDeliveryDateFrom: formatDate(formValue.requestedDeliveryDateFrom, dateFormat),
      requestedDeliveryDateTo: formatDate(formValue.requestedDeliveryDateTo, dateFormat),
      warehouseCode: formValue.warehouseCode,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterCreatedDate() {
    this.setFirstPage();
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      createdDateFrom: null,
      createdDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterRequestedDeliveryDate() {
    this.setFirstPage();
    this.searchForm.controls['requestedDeliveryDateFrom'].reset();
    this.searchForm.controls['requestedDeliveryDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      requestedDeliveryDateFrom: null,
      requestedDeliveryDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterWarehouse() {
    this.setFirstPage();
    this.searchForm.controls['warehouseCode'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      warehouseCode: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.searchForm.controls['requestedDeliveryDateFrom'].reset();
    this.searchForm.controls['requestedDeliveryDateTo'].reset();
    this.searchForm.controls['warehouseCode'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      createdDateFrom: null,
      createdDateTo: null,
      requestedDeliveryDateFrom: null,
      requestedDeliveryDateTo: null,
      warehouseCode: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onChangeOrderDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.orderMinDate = new Date(value);
    } else {
      this.orderMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeOrderDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.orderMaxDate = new Date(value);
    } else {
      this.orderMaxDate = new Date();
      this.orderMaxDate.setDate(this.orderMaxDate.getDate() + 365);
    }
  }

  onChangePickDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.pickMinDate = new Date(value);
    } else {
      this.pickMinDate = new Date(2019, 0, 1);
    }
  }

  onChangePickDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.pickMaxDate = new Date(value);
    } else {
      this.pickMaxDate = new Date();
      this.pickMaxDate.setDate(this.pickMaxDate.getDate() + 365);
    }
  }

  onChangeRequestDeliveryDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.requestDeliveryMinDate = new Date(value);
    } else {
      this.requestDeliveryMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeRequestDeliveryDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.requestDeliveryMaxDate = new Date(value);
    } else {
      this.requestDeliveryMaxDate = new Date();
      this.requestDeliveryMaxDate.setDate(this.requestDeliveryMaxDate.getDate() + 365);
    }
  }

  onChangeReqDeliveryDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.reqDeliveryMinDate = new Date(value);
    } else {
      this.reqDeliveryMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeReqDeliveryDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.reqDeliveryMaxDate = new Date(value);
    } else {
      this.reqDeliveryMaxDate = new Date();
      this.reqDeliveryMaxDate.setDate(this.reqDeliveryMaxDate.getDate() + 365);
    }
  }

  prepareSearchCriteriaTags() {
    this.createdDateTag = null;
    this.createdDateStringTag = null;
    this.requestedDeliveryDateTag = null;
    this.requestedDeliveryDateStringTag = null;
    this.warehouseTypeTag = null;
    this.warehouseTypeStringTag = null;

    const createdDateFrom = dateStringToTagCriteria(this.criteriaObject.createdDateFrom);
    const createdDateTo = dateStringToTagCriteria(this.criteriaObject.createdDateTo);
    const createdDate = generateDateStringTag({
      dateName: 'Created Date',
      dateFrom: createdDateFrom,
      dateTo: createdDateTo
    });

    this.createdDateStringTag = createdDate.dateStringTag;
    this.createdDateTag = createdDate.dateTag;

    const requestedDeliveryDateFrom = dateStringToTagCriteria(this.criteriaObject.requestedDeliveryDateFrom);
    const requestedDeliveryDateTo = dateStringToTagCriteria(this.criteriaObject.requestedDeliveryDateTo);
    const requestedDeliveryDate = generateDateStringTag({
      dateName: 'Requested Delivery Date',
      dateFrom: requestedDeliveryDateFrom,
      dateTo: requestedDeliveryDateTo
    });

    this.requestedDeliveryDateStringTag = requestedDeliveryDate.dateStringTag;
    this.requestedDeliveryDateTag = requestedDeliveryDate.dateTag;

    if (this.criteriaObject.warehouseCode && this.criteriaObject.warehouseCode.length > 0) {
      this.warehouseTypeStringTag = 'Ship From';
      const warehouseType = this.warehouseList
        .filter(data => {
          return this.criteriaObject.warehouseCode.indexOf(data.code.toString()) > -1;
        })
        .map(status => status.warehouseNameDisplay);
      const warehouseTypes = warehouseType.join(', ');
      this.warehouseTypeTag = `"${warehouseTypes}"`;
    }
  }

  prepareExportRequestData(formData): DeliveryOrderExportPickingCriteria {
    const dateFormat = environment.dateFormat;

    return new DeliveryOrderExportPickingCriteria({
      requestedDeliveryDateFrom: formatDate(formData.requestedDeliveryDateFrom, dateFormat),
      requestedDeliveryDateTo: formatDate(formData.requestedDeliveryDateTo, dateFormat),
      orderDateFrom: dateToStringCriteria(formData.orderDateFrom),
      orderDateTo: dateToStringCriteria(formData.orderDateTo, false),
      pickDateFrom: formatDate(formData.pickDateFrom, dateFormat),
      pickDateTo: formatDate(formData.pickDateTo, dateFormat),
      changeStatus: this.getValue(formData.changeStatusToPicking),
      status: formData.status,
      warehouseCode: formData.code
    });
  }

  getColorStatus(status: DeliveryOrderStatusEnum): string {
    return status ? `${DeliveryOrderStatusEnum[status].toLowerCase()}` : '';
  }

  search(criteriaObj) {
    this.store.dispatch(new DeliveryOrderListRequestAction(criteriaObj));
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  setInitialValue() {
    this.checkedList = {};
    this.pageSize = 20;
    this.currentPage = 1;
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.orderMaxDate = new Date();
    this.orderMaxDate.setDate(this.orderMaxDate.getDate() + 365);

    this.requestDeliveryMaxDate = new Date();
    this.requestDeliveryMaxDate.setDate(this.requestDeliveryMaxDate.getDate() + 365);

    this.pickMaxDate = new Date();
    this.pickMaxDate.setDate(this.pickMaxDate.getDate() + 365);

    this.reqDeliveryMaxDate = new Date();
    this.reqDeliveryMaxDate.setDate(this.reqDeliveryMaxDate.getDate() + 365);
  }

  setRouteTab() {
    const hasDeliveryOrderListPagePermission = this.authGuardService.checkPermission(
      ['^do_m_[a-zA-Z]{2}[0-9]{1,3}$', '^do_v_[a-zA-Z]{2}[0-9]{1,3}$', '^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$'],
      true
    );
    const hasShipmentListPagePermission = this.authGuardService.checkPermission(
      ['^do_logis_v_[a-zA-Z]{2}[0-9]{1,3}$', '^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$', '^do_m_[a-zA-Z]{2}[0-9]{1,3}$'],
      true
    );

    this.listRoute = [];

    if (hasDeliveryOrderListPagePermission) {
      this.listRoute.push({
        tabName: 'DELIVERY_ORDER.DELIVERY_ORDER_LIST',
        url: '/order/delivery-order-list'
      });
    }

    if (hasShipmentListPagePermission) {
      this.listRoute.push({ tabName: 'SHIPMENT.SHIPMENT_LIST', url: '/order/shipment-list' });
    }
  }

  goToEdit(id: string) {
    const title = 'Edit Delivery Order';

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState: {
        childItem: new ChildItem(
          DeliveryOrderEditComponent,
          { id, title, mode: DeliveryOrderModeEnum.EDIT_MODE },
          true
        )
      }
    });
  }

  goToView(viewParams?: DeliveryOrderContent) {
    const title = 'View Delivery Order';
    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState: {
        childItem: new ChildItem(DeliveryOrderEditComponent, { id: viewParams.id, title }, true)
      }
    });
  }

  showHistory(orderDelivery: DeliveryOrderContent) {
    this.modalService.show(HistoryComponent, {
      initialState: {
        title: 'History',
        historyHeader: `Delivery Order No.: ${orderDelivery.doNo}`,
        historyType: HistoryType.PRICE_SETTING_CLASS,
        auditLogs: orderDelivery.deliveryOrderHistories
      }
    });
  }

  cancelDeliveryOrder(doNo: string) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel delivery order number <strong>&quot;${doNo}&quot;</strong>?`,
        label: 'Reason',
        okText: 'Yes, cancel',
        cancelText: 'Cancel',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.deliveryOrderService
            .cancelDeliveryOrder({
              doNo: doNo,
              comment: confirmModalRef.content.confirmMessage
            })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The delivery order has been cancelled.');
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );

          if (confirmModalRef.content.actions) {
            confirmModalRef.content.actions.unsubscribe();
          }
        }
      });
  }

  onOpenExport() {
    this.resetExportForm();
    this.exportModal.show();
  }

  onExport() {
    this.submittedExport = true;

    if (this.exportFormInValid) {
      return;
    }

    const formData = this.exportForm.getRawValue();
    const request = this.prepareExportRequestData(formData);

    this.clearError();

    this.deliveryOrderService.exportPicking(request).subscribe(
      response => {
        const blob = new Blob([response]);

        saveAs(blob, this.getGeneratedFileName());
        this.resetExportForm();
        this.exportModal.hide();
        this.doSearch(this.criteriaObject);
      },
      error => {
        this.alertErrorModal(error.error);
      }
    );
  }

  onLoadItemDetail(value: any) {
    if (Array.isArray(value) && value.length > 0) {
      this.importInvalid = {
        title: `${value.length} Errors Found.`,
        table: value,
        message: null
      };
    } else {
      this.importInvalid = {
        title: 'Success',
        table: null,
        message: 'The data have been saved.'
      };
    }

    this.importInvalidModel.show();
  }

  resetExportForm() {
    this.exportForm.reset();
    this.exportForm.controls['status'].setValue([DeliveryOrderStatusEnum.NEW]);
    this.submittedExport = false;
    this.errorExport = null;
  }

  getGeneratedFileName() {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.exportDo.timeFormat);

    return `${environment.fileName.exportDo.prefix}${formattedDate}.xlsx`;
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const successModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    successModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.search(this.criteriaObject);
        successModal.content.actions.unsubscribe();
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    if (errorResponse.code || errorResponse.message) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };

      if (['00001', '00006'].includes(errorResponse.code)) {
        this.errorExport = initialState.message;
      } else {
        const alertModal = this.modalService.show(AlertModalComponent, {
          initialState
        });

        alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(() => {
          if (alertModal.content.actions) {
            alertModal.content.actions.unsubscribe();
          }
        });
      }
    }
  }

  clearError() {
    this.exportForm.controls['requestedDeliveryDateFrom'].setErrors(null);
    this.exportForm.controls['requestedDeliveryDateTo'].setErrors(null);
    this.exportForm.controls['orderDateFrom'].setErrors(null);
    this.exportForm.controls['orderDateTo'].setErrors(null);
    this.exportForm.controls['pickDateFrom'].setErrors(null);
    this.exportForm.controls['pickDateTo'].setErrors(null);
    this.errorExport = null;
  }

  onSelect($event, order: DeliveryOrderContent) {
    if ($event.target.checked) {
      this.store.dispatch(new ShipmentSelectDeliveryOrder(order as DeliveryOrderShipment));
    } else {
      this.store.dispatch(new ShipmentUnselectDeliveryOrder(order.id));
    }
  }

  showModalShipment(shipmentNo: string = null, mode: string, doNo: string) {
    let title = '';
    if (mode === this.shipmentTypeEnum.CREATE) {
      if (!this.checkedList || Object.keys(this.checkedList).length === 0) {
        const msg = 'Please select at least 1 delivery order to create shipment.';
        this.showAlertModal(msg);
        return;
      } else if (this.validateDuplicateWarehouse()) {
        const msg = 'Not allow to create, delivery order from different location';
        this.showAlertModal(msg);
        return;
      }

      title = 'Create Shipment';
      this.showShipment(shipmentNo, title, mode, null);
    } else {
      if (!shipmentNo) {
        const msg =
          'This Delivery Order does not have shipment information.<br/><br/>Please create shipment and try again.';
        this.showAlertModal(msg);
        return;
      } else {
        title = 'Confirm Load';
        this.showShipment(shipmentNo, title, mode, doNo);
      }
    }
  }

  validateDuplicateWarehouse() {
    return uniq(this.validateWarehouseCode.map(result => result.warehouseCode)).length !== 1;
  }

  showAlertModal(message) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message
      }
    });
  }

  showShipment(shipmentNo: string, title: string, mode: string, doNo: string) {
    this.modalShipment = this.modalService.show(ShipmentRequestComponent, {
      class: 'delivery-details',
      backdrop: 'static',
      keyboard: false,
      initialState: {
        title,
        shipmentNo,
        mode,
        doNo
      }
    });
  }

  canLoad(currentStatus: DeliveryOrderStatusEnum) {
    return DeliveryOrderStatusEnum.PICKED === currentStatus;
  }

  canSelectCheckbox(currentStatus: DeliveryOrderStatusEnum, warehouseCode: string) {
    return (
      [DeliveryOrderStatusEnum.NEW, DeliveryOrderStatusEnum.PICKED, DeliveryOrderStatusEnum.PICKING].includes(
        currentStatus
      ) && this.permissionUtil.checkPermissionByLocation(['do_logis_m_$1'], warehouseCode)
    );
  }

  showEditButton(doStatus: DeliveryOrderStatusEnum) {
    return this.hasManageDeliveryOrder && ['NEW', 'PICKING', 'PICKED', 'DRAFT'].includes(doStatus);
  }

  showCancelButton(status: DeliveryOrderStatusEnum) {
    return (
      [
        DeliveryOrderStatusEnum.DRAFT,
        DeliveryOrderStatusEnum.NEW,
        DeliveryOrderStatusEnum.PICKING,
        DeliveryOrderStatusEnum.PICKED
      ].includes(status) && this.hasManageDeliveryOrder
    );
  }

  showHistoryButton(doStatus: DeliveryOrderStatusEnum) {
    return (
      (this.hasManageDeliveryOrder || this.hasManageLogistic || this.hasViewDeliveryOrder || this.hasViewLogistic) &&
      !['DRAFT'].includes(doStatus)
    );
  }

  get exportFormInValid() {
    const formValues = this.exportForm.value;
    return (
      !(
        formValues.orderDateFrom ||
        formValues.orderDateTo ||
        formValues.pickDateFrom ||
        formValues.pickDateTo ||
        formValues.requestedDeliveryDateFrom ||
        formValues.requestedDeliveryDateTo
      ) || !formValues.status.length
    );
  }

  onPrintPdf(orderDelivery: DeliveryOrderContent) {
    if (!orderDelivery) {
      return;
    }
    const param: DeliveryOrderPrintCriteria = {
      id: orderDelivery.id,
      format: 'pdf',
      locale: 'th'
    };

    this.deliveryOrderService.printPdfDeliveryOrder(param).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
        saveAs(blob, generatedFilenamePdf(orderDelivery.doNo));
      },
      error => {
        this.alertErrorModal(error.error);
      }
    );
  }

  showImportDoModal() {
    this.importDoModal = this.modalService.show(DeliveryOrderImportModalComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        uploadUrl: this.deliveryOrderService.getUrlImport(),
        warehouseList: this.importWarehouseList
      }
    });

    this.importDoModal.content.submitUpload.pipe(untilComponentDestroyed(this)).subscribe(result => {
      this.onLoadItemDetail(result);
    });
  }

  isCanPrintPDF(status: string) {
    return ['PICKED', 'LOADED', 'DISPATCHED', 'DELIVERED'].includes(status);
  }
}
