import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { PosAdvertisementPageModes } from '../../../shared/enum/pos-advertisement.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  PosAdsCreate,
  PosAdsEdit,
  PosAdsSearchCriteria,
  PosAdvertisementListContent
} from '../../../shared/models/pos-advertisement.model';
import { AuthGuardService } from '../../../shared/services';
import {
  PosAdvertisementCancelRequest,
  PosAdvertisementCreateDefaultAction,
  PosAdvertisementCreateErrorResetAction,
  PosAdvertisementCreateSeasonalAction,
  PosAdvertisementEditDefaultAction,
  PosAdvertisementGetByIdRequestAction,
  PosAdvertisementGetByIdResetAction,
  PosAdvertisementListRequestAction,
  PosDefaultAdvertisementListRequestAction,
  PosDefaultAdvertisementResetAction
} from '../../../shared/store/actions/pos-advertisement.actions';
import {
  selectPosAdvertisementListCriteria,
  selectPosAdvertisementSelectById,
  selectPosAdvertisementSubmitError
} from '../../../shared/store/selectors/pos-advertisement.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { convertUtcToBkk, dateToStringCriteria, getDateFromString } from '../../../shared/utils/date-util';
import { ImagesPanelComponent } from '../images-panel/images-panel.component';

@Component({
  selector: 'app-advertisement-create',
  templateUrl: './advertisement-create.component.html',
  styleUrls: ['./advertisement-create.component.scss']
})
export class AdvertisementCreateComponent implements OnInit, OnDestroy {
  @ViewChild('imagesPanel', { static: false }) imagePanel: ImagesPanelComponent;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: PosAdvertisementPageModes;
    adsNo: string;
  };
  isFormDirty: boolean;
  public hasManagePermission = false;
  public form: FormGroup;
  public submitted: boolean;
  dateFormat = env.dateFormat;

  public uploadUrl: string;
  public uploadHeaders: any;
  public baseStorageUrl: string;
  public errorImageSize: string;
  public errorIncorrectFormat: string;
  private localStore: Observable<any>;
  private effectiveDate = null;
  private effectiveMaxDate = null;
  private expireDate = null;
  public expireMinDate: Date;
  public currentDate: Date;
  public criteriaObject: PosAdsSearchCriteria;
  public pageMode = PosAdvertisementPageModes;
  public posAdvertisementView$: Observable<PosAdvertisementListContent>;
  public posAdvertisementContent: PosAdvertisementListContent;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly translate: TranslateService,
    protected readonly fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService
  ) {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
  }

  ngOnInit() {
    this.hasManagePermission = this.authGuardService.checkPermission(['pos_ads_m']);
    this.currentDate = new Date();
    this.initImages();
    this.initControl();
    this.initState();
  }

  initState() {
    if (this.data.adsNo && (this.viewMode || this.editMode)) {
      this.store.dispatch(new PosAdvertisementGetByIdRequestAction({ adsNo: this.data.adsNo }));
    }

    this.posAdvertisementView$ = this.localStore.pipe(
      select(selectPosAdvertisementSelectById),
      filter(data => data !== null)
    );

    this.posAdvertisementView$
      .pipe(
        filter(value => Boolean(value)),
        map(response => response),
        take(1)
      )
      .subscribe(req => {
        this.posAdvertisementContent = req;
        this.setFromValue(req);
      });

    this.localStore
      .pipe(select(selectPosAdvertisementListCriteria))
      .subscribe(criteriaObject => (this.criteriaObject = criteriaObject));

    this.localStore.pipe(select(selectPosAdvertisementSubmitError)).subscribe(submitError => {
      if (submitError && submitError.message) {
        this.alertErrorModal(submitError.message);
      }
    });
  }

  initImages() {
    this.uploadUrl = `${env.serverUrl}${env.services.advertisements.url}${env.services.advertisements.uploadUrl}`;
    this.uploadHeaders = env.services.advertisements.headers;
    this.baseStorageUrl = `${env.storageUrl}${env.services.advertisements.bucketName}`;
    this.errorImageSize =
      'File size limit exceeded. <br/><br/> Size up to 3 MB (Recommended upload size of 800 * 1080 pixels).';
    this.errorIncorrectFormat = 'Incorrect Format (allow only format file .jpg, .jpeg, .png).';
  }

  initControl() {
    const initialNull = [{ value: null, disabled: false }];
    const initialNullRequired = [
      { value: null, disabled: false },
      [Validators.required, this.afterEffectiveDateValidator]
    ];

    this.form = this.fb.group({
      effectiveDate: this.data.mode === PosAdvertisementPageModes.CREATE_SEASONAL ? initialNullRequired : initialNull,
      expireDate: this.data.mode === PosAdvertisementPageModes.CREATE_SEASONAL ? initialNullRequired : initialNull,
      images: this.fb.array([])
    });

    if (this.createMode) {
      this.imagesArray.push(this.fb.group({ image: [{ value: null, disabled: false }] }));
    }
  }

  get imagesArray() {
    return this.form.get('images') as FormArray;
  }

  cancelSeasonalAds() {
    const item = this.posAdvertisementContent;
    if (item && item.type !== 'SEASONAL') {
      return;
    }

    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel Seasonal Ads No. <strong>&quot;${item.adsNo}&quot;</strong>?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PosAdvertisementCancelRequest({
              id: item.adsNo,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  showCancelButton(): boolean {
    const item = this.posAdvertisementContent;
    if (
      item &&
      item.type === 'SEASONAL' &&
      item.status &&
      ['awaiting_schedule', 'active'].includes(item.status.toLocaleLowerCase())
    ) {
      return true;
    }

    return false;
  }

  setFromValue(data: PosAdvertisementListContent) {
    data.images.forEach(v => {
      const image = [
        {
          filePath: v.directory,
          directory: v.directory,
          medium: v.medium,
          small: v.small,
          fileName: v.fileName,
          original: `${v.directory}/${v.fileName}`,
          imgSrc: `${this.baseStorageUrl}/${v.directory}/${v.fileName}`
        }
      ];

      this.imagesArray.push(
        this.fb.group({
          image: [{ value: image, disabled: true }]
        })
      );
    });

    const detail = {
      effectiveDate: this.setStringToDate(data.effectiveDate),
      expireDate: this.setStringToDate(data.expireDate)
    };

    this.form.patchValue(detail);

    if (this.viewMode) {
      this.form.disable();
    }
    if (this.editMode) {
      this.toggleToEditMode();
    }
  }

  setStringToDate(str: string) {
    return str ? getDateFromString(convertUtcToBkk(str, env.dateFormat), env.dateFormat) : null;
  }

  onExit() {
    this.isFormDirty = this.form.dirty || this.isFormDirty;

    if (this.isFormDirty) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
    }
  }

  onChangeEffectiveDate(value): void {
    if (value && !isNaN(value.getTime())) {
      this.expireMinDate = new Date(value);
      this.effectiveDate = new Date(value);
      this.form.controls['expireDate'].updateValueAndValidity({ onlySelf: true });
    } else {
      this.expireMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeExpireDate(value): void {
    if (value && !isNaN(value.getTime())) {
      this.effectiveMaxDate = new Date(value);
      this.form.controls['effectiveDate'].updateValueAndValidity({ onlySelf: true });
    } else {
      this.effectiveMaxDate = new Date();
      this.effectiveMaxDate.setDate(this.effectiveMaxDate.getDate() + 365);
    }
  }

  get afterEffectiveDateValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      const effectiveDate = this.effectiveDate;
      if (effectiveDate && moment(control.value).isBefore(effectiveDate, 'day')) {
        return { afterEffective: true };
      }

      return null;
    };
  }

  onSubmit() {
    this.submitted = true;

    const value = this.form.getRawValue();
    if (value.images && value.images[0].image === null) {
      const message = 'Please upload at least one image before submit.';
      this.alertErrorModal(message);
      return;
    }

    if (this.form.invalid) {
      return;
    }

    this.handleConfirm();
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          let reqData = null;

          switch (this.data.mode) {
            case PosAdvertisementPageModes.CREATE_DEFAULT:
              reqData = this.prepareData();
              this.store.dispatch(new PosAdvertisementCreateDefaultAction(reqData));
              break;
            case PosAdvertisementPageModes.CREATE_SEASONAL:
              reqData = this.prepareData();
              this.store.dispatch(new PosAdvertisementCreateSeasonalAction(reqData));
              break;
            case PosAdvertisementPageModes.EDIT_DEFAULT:
              reqData = this.prepareEditData();
              this.store.dispatch(new PosAdvertisementEditDefaultAction(reqData));
              break;
            default:
              break;
          }
        }
      });
  }

  prepareData(): PosAdsCreate {
    const value = this.form.getRawValue();

    let effectiveDate;
    let expireDate;
    if (value.effectiveDate) {
      effectiveDate = dateToStringCriteria(value.effectiveDate, true);
    }
    if (value.expireDate) {
      expireDate = dateToStringCriteria(value.expireDate, false);
    }
    const images = [];
    value.images
      .filter(v => v.image != null)
      .forEach((data, index) => {
        const image = Object.values(data.image);
        images.push({ ...image[0], sortNo: index });
      });

    return {
      type: this.getTypeSave,
      effectiveDate,
      expireDate,
      images
    };
  }

  prepareEditData(): PosAdsEdit {
    const value = this.form.getRawValue();

    const images = [];
    value.images
      .filter(v => v.image != null)
      .forEach((data, index) => {
        const image = Object.values(data.image);
        images.push({ ...image[0], sortNo: index });
      });

    return {
      id: this.posAdvertisementContent.id,
      version: this.posAdvertisementContent.version,
      adsNo: this.posAdvertisementContent.adsNo,
      type: this.getTypeSave,
      images
    };
  }

  alertErrorModal(message) {
    const initialState = {
      title: 'Failed',
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  get images(): FormArray {
    return this.form.controls['images'] as FormArray;
  }

  get getTypeSave() {
    switch (this.data.mode) {
      case PosAdvertisementPageModes.CREATE_DEFAULT:
      case PosAdvertisementPageModes.EDIT_DEFAULT:
        return 'DEFAULT';
      case PosAdvertisementPageModes.CREATE_SEASONAL:
        return 'SEASONAL';
      default:
        return '';
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new PosDefaultAdvertisementResetAction());

    if (
      [PosAdvertisementPageModes.CREATE_SEASONAL, PosAdvertisementPageModes.CREATE_DEFAULT].includes(this.data.mode)
    ) {
      this.criteriaObject.page = 0;
    }

    this.store.dispatch(new PosAdvertisementListRequestAction(this.criteriaObject));
    this.store.dispatch(new PosDefaultAdvertisementListRequestAction());
    this.submitted = false;
    this.store.dispatch(new PosAdvertisementCreateErrorResetAction());
    this.store.dispatch(new PosAdvertisementGetByIdResetAction());
    if (this.notifyParent) {
      this.notifyParent.unsubscribe();
    }
  }

  toggleToEditMode() {
    this.data.title = 'Edit Default Ads';
    this.data.mode = PosAdvertisementPageModes.EDIT_DEFAULT;
    this.imagePanel.setDisableImage(false);

    if (this.posAdvertisementContent && this.posAdvertisementContent.images.length < 10) {
      this.imagesArray.push(this.fb.group({ image: [{ value: null, disabled: false }] }));
    }

    this.form.enable();
  }

  get viewMode() {
    return [PosAdvertisementPageModes.VIEW_DEFAULT, PosAdvertisementPageModes.VIEW_SEASONAL].includes(this.data.mode);
  }

  get showEditMode() {
    return PosAdvertisementPageModes.VIEW_DEFAULT === this.data.mode;
  }

  get editMode() {
    return PosAdvertisementPageModes.EDIT_DEFAULT === this.data.mode;
  }

  get createMode() {
    return [PosAdvertisementPageModes.CREATE_SEASONAL, PosAdvertisementPageModes.CREATE_DEFAULT].includes(
      this.data.mode
    );
  }

  getColorStatus(status: string): string {
    return status ? status.toLowerCase() : '';
  }
}
