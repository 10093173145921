import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import * as numeral from 'numeral';
import { filter } from 'rxjs/operators';
import { StockTransferItemImportStatusEnum } from 'src/app/shared/enum/stock-transfer-request.enum';
import { environment } from '../../../../environments/environment';
import { FileSubmitBeforeUploadComponent } from '../../../shared/components/file-submit-before-upload/file-submit-before-upload.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { UploadStatusEnum } from '../../../shared/enum/upload-status.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { StockTransferRequestService } from '../../../shared/services/stock-trasfer-request-service';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-stock-transfer-request-import-modal',
  templateUrl: './stock-transfer-request-import-modal.component.html',
  styleUrls: ['./stock-transfer-request-import-modal.component.scss']
})
export class StockTransferRequestImportModalComponent implements OnInit, OnDestroy {
  @ViewChild(FileSubmitBeforeUploadComponent, { static: false })
  fileUpload: FileSubmitBeforeUploadComponent;
  @Output() submitUpload: EventEmitter<any> = new EventEmitter();
  public form: FormGroup;
  public stockTransferItemImportStatusEnum = StockTransferItemImportStatusEnum;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: FormBuilder,
    private readonly logger: NGXLogger,
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected readonly stockTransferRequestService: StockTransferRequestService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy(): void {}

  createForm() {
    this.form = this.fb.group({
      importItems: [null]
    });
  }

  downloadTransferRequestTemplate() {
    this.stockTransferRequestService.onExportTemplate().subscribe(response => {
      const blob = new Blob([response]);
      saveAs(blob, `${this.generateFileName()} ${this.timeToExport}.xlsx`);
    });
  }

  generateFileName() {
    return environment.fileName.exportTransferRequestTemplate.prefix;
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportTransferRequestTemplate.timeFormat);
  }

  onSubmit() {}

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
      }
    });
  }

  onUpload($event) {
    const fileList = $event.files;
    this.stockTransferRequestService.importItems(fileList.value).forEach((allRes, index: number) =>
      allRes
        .pipe(
          filter(Boolean),
          filter(res => res['status'])
        )
        .subscribe(
          res => {
            if (res['status'] === 'progress') {
              return JSON.stringify(res);
            } else if (res['productItemList']) {
              fileList.value[index].status = UploadStatusEnum.COMPLETED;
              this.bsModalRef.hide();
              fileList.markAsTouched();
              const uploadedItems = res['productItemList'];
              uploadedItems.map(item => {
                item.itemNo = null;
                item.quantity = numeral(item.quantity).value();
              });
              this.submitUpload.emit({ items: uploadedItems });
              this.alertSuccessModal('The data have been imported.');
            } else if (res['validateList'] && res['validateList'].length) {
              fileList.value[index].errorMessage = `${res['validateList'].length} Error Found.`;
              fileList.value[index].validateList = res['validateList'];
              fileList.value[index].status = UploadStatusEnum.FAILED;
            } else {
              this.logger.info(`FileIndex(${index})-Unhandled`, res);
              fileList.value[index].errorMessage = res['error'] && res['error'].message;
              fileList.value[index].status = UploadStatusEnum.FAILED;
            }
          },
          err => this.logger.error(`FileIndex(${index})-Error`, err)
        )
    );
  }
}
